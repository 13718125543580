import SectionBox from 'components/Container/SectionBox'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import CrushVolume from './CrushVolume'
import Utilization from './Utilization'
import { Products } from './Products'
import { useLocalization } from 'providers/LocalizationProvider'

const Crushing = ({ data, selectedSubProducts }) => {
  const { t } = useLocalization()

  return (
    <SectionBox>
      <TabsComponent>
        <Tab value='crush_volume' label={t('optimizationScreen.crushVolume')}>
          <CrushVolume selectedSubProducts={selectedSubProducts} data={data} />
        </Tab>
        <Tab value='utilization' label={t('optimizationScreen.utilization')}>
          <Utilization selectedSubProducts={selectedSubProducts} data={data} />
        </Tab>
        <Tab value='products' label={t('optimizationScreen.products')}>
          <Products selectedSubProducts={selectedSubProducts} data={data} />
        </Tab>
      </TabsComponent>
    </SectionBox>
  )
}

export default Crushing
