import Button from 'components/Inputs/Button'
import { DocumentsIllustration, Plus } from 'components/Icons'
import { useNavigate } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'

const EmptyState = () => {
  const navigate = useNavigate()
  const { t } = useLocalization()

  return (
    <div className='inline-flex w-full space-y-8 items-center justify-start flex-col mt-12'>
      <div className='flex flex-col items-center justify-start'>
        <DocumentsIllustration />
        <div className='flex flex-col space-y-2 items-center justify-start mt-5'>
          <p className='text-xl font-bold leading-7 text-center'>
            {t('homeScreen.optimizationsTable.emptyState.title')}
          </p>
          <p className='text-sm font-medium leading-tight text-center text-gray-500'>
            {t('homeScreen.optimizationsTable.emptyState.description')}
          </p>
        </div>
      </div>
      <div className='inline-flex space-x-3 items-center justify-center w-84'>
        <Button
          variant='filled'
          size='l'
          onClick={() => navigate('/new-optimization')}
        >
          <Plus stroke='white' />
          {t('homeScreen.optimizationsTable.emptyState.runFirstOptimization')}
        </Button>
      </div>
    </div>
  )
}

export default EmptyState
