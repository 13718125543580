import { kgToTons } from '.'
import dayjs from 'dayjs'

export const NO_DATA_LABEL = 'N/A'

export const labelFormatter = (labelValue) =>
  labelValue === NO_DATA_LABEL
    ? labelValue
    : `${(labelValue / kgToTons).toFixed(2)} kTon`

export const valueFormatter = (labelValue) =>
  labelValue === NO_DATA_LABEL
    ? labelValue
    : `${(labelValue / kgToTons).toFixed(2)}`

export const yAxisFormatter = (labelValue) => `${labelValue / kgToTons} kTons`

export const getMonthsForPeriod = (start, end) => {
  const months = []

  for (
    let currentDate = dayjs(start);
    currentDate.diff(dayjs(end)) <= 0;
    currentDate = currentDate.add(1, 'M')
  ) {
    months.push(currentDate.format('YYYY-MM-DD'))
  }

  return months
}

export function transformToPeriods(dateArray) {
  if (!dateArray) {
    return []
  }
  // Convert date strings to Date objects
  const dates = dateArray.map((date) => dayjs(date))

  // Sort dates in ascending order
  dates.sort((a, b) => {
    return a.isBefore(b)
  })

  if (!dateArray?.length) {
    return []
  }

  if (dateArray.length === 1) {
    return [
      {
        startDate: dayjs(dates[0]).startOf('M').format('YYYY-MM-DD'),
        endDate: dayjs(dates[0]).endOf('M').format('YYYY-MM-DD')
      }
    ]
  }

  const periods = []
  let startDate = dates[0]
  let endDate = dates[0]

  for (let i = 1; i < dates.length; i++) {
    const currentDate = dates[i]

    const diffToEnd = Math.abs(dayjs(currentDate).diff(dayjs(endDate), 'M'))

    if (diffToEnd === 1) {
      endDate = currentDate
    } else if (diffToEnd > 1) {
      periods.push({
        startDate: dayjs(startDate).startOf('M').format('YYYY-MM-DD'),
        endDate: dayjs(endDate).endOf('M').format('YYYY-MM-DD')
      })

      startDate = currentDate
      endDate = currentDate
    }

    if (i === dates.length - 1) {
      periods.push({
        startDate: dayjs(startDate).startOf('M').format('YYYY-MM-DD'),
        endDate: dayjs(endDate).endOf('M').format('YYYY-MM-DD')
      })
    }
  }

  return periods
}

export const productColorMap = {
  BM: {
    default: '#1950CA',
    soy: '#3668D5',
    corn: '#F6853E',
    wheat: '#5F50C7',
    oil: '#C7660D',
    meal: '#727C89',
    sunflower: '#519CA1',
    'sunflower meal': '#7DC341',
    'sunflower oil': '#C0872E',
    soybean: '#3668D5',
    'soybean meal': '#3A84B0',
    'soybean oil': '#C7660D',
    'soybean at': '#BD45C8',
    lecithin: '#7D81F3',
    biodiesel: '#727C89'
  },
  COMP: {
    default: '#C1D5FF',
    soy: '#C1D5FF',
    corn: '#FCD6BF',
    wheat: '#CAC5EC',
    oil: '#EFB27A',
    meal: '#E4E8ED',
    sunflower: '#92CCCF',
    'sunflower meal': '#9FD770',
    'sunflower oil': '#E7BE7F',
    soybean: '#C1D5FF',
    'soybean meal': '#8DC2E1',
    'soybean oil': '#EFB27A',
    'soybean at': '#E18CE9',
    lecithin: '#B8BBF9',
    biodiesel: '#E4E8ED'
  }
}

export const getProductColor = (isBaseModel, product) => {
  if (product === null) {
    console.error(new Error(`Product was null`))
  }
  const key = typeof product === 'string' ? product.toLowerCase() : 'default'
  return (
    productColorMap[isBaseModel ? 'BM' : 'COMP'][key] ||
    productColorMap.BM.default
  )
}

export const getDefaultProductColor = productColorMap.BM.default
