import classNames from 'classnames'

export const CardBase = ({ children, className }) => (
  <div
    className={classNames(
      'flex border border-gray-200 rounded-lg p-6 w-full gap-10',
      className
    )}
  >
    {children}
  </div>
)
