import { useMemo, useCallback } from 'react'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { NO_DATA_LABEL } from 'screens/Optimization/utils'
import { createMonthObject } from 'screens/Optimization/utils'

const useHeatMap = ({ data, mainRowKey, valueKey }) => {
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    selectedProducts,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const filteredData = useMemo(
    () =>
      data.filter((v) =>
        selectedProducts.length > 0
          ? selectedProducts.includes(v.product_ui_type)
          : true
      ),
    [data, selectedProducts]
  )

  const getEmptyStructure = useCallback(() => {
    const monthObject = createMonthObject(start_date, end_date, {
      [comparisonID]: NO_DATA_LABEL,
      [baseModelId]: NO_DATA_LABEL
    })

    return filteredData.reduce(
      (acc, { asset_name, ...item }) => ({
        ...acc,
        [item[mainRowKey]]: {
          ...acc[item[mainRowKey]],
          title: item[mainRowKey],
          data: monthObject,
          subRows: {
            ...(acc[item[mainRowKey]]?.subRows || {}),
            [asset_name]: monthObject
          }
        }
      }),
      {}
    )
  }, [
    baseModelId,
    comparisonID,
    end_date,
    start_date,
    filteredData,
    mainRowKey
  ])

  const heatmapData = useMemo(() => {
    const calcExecutionIdValue = (currentData, incoming) =>
      currentData === NO_DATA_LABEL ? incoming : currentData + incoming

    const result = filteredData.reduce(
      (acc, { month, execution_id, asset_name, ...item }) => ({
        ...acc,
        [item[mainRowKey]]: {
          ...acc[item[mainRowKey]],
          data: {
            ...acc[item[mainRowKey]].data,
            [month]: {
              ...acc[item[mainRowKey]].data[month],
              [execution_id]: calcExecutionIdValue(
                acc[item[mainRowKey]].data[month][execution_id],
                item[valueKey]
              )
            }
          },
          subRows: {
            ...acc[item[mainRowKey]].subRows,
            [asset_name]: {
              ...acc[item[mainRowKey]].subRows[asset_name],
              [month]: {
                ...acc[item[mainRowKey]].subRows[asset_name][month],
                [execution_id]: calcExecutionIdValue(
                  acc[item[mainRowKey]].subRows[asset_name][month][
                    execution_id
                  ],
                  item[valueKey]
                )
              }
            }
          }
        }
      }),
      getEmptyStructure()
    )
    return Object.values(result).map((i) => ({
      ...i,
      subRows: Object.entries(i.subRows)
        .map(([asset_name, data]) => ({
          title: asset_name,
          data
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
    }))
  }, [filteredData, getEmptyStructure, mainRowKey, valueKey])
  return { heatmapData }
}

export default useHeatMap
