import TableChart from 'components/Graphs/TableChart'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import useOutputGraph from './useOutputGraph'
import useOutputTable from './useOutputTable'
import { capitalize } from 'utils/string'
import NoData from 'components/Feedback/NoData'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const AllSilos = ({ data }) => {
  const { t } = useLocalization()
  const { summaryItems, bars, volumeChartData } = useOutputGraph(data)
  const { tableData } = useOutputTable(data)

  return !hasNoData(volumeChartData) ? (
    <TableChart
      title={t('optimizationScreen.storage.allSilos.titleBr')}
      summaryItems={summaryItems}
      data={volumeChartData}
      tooltipFormatter={labelFormatter}
      yAxisFormatter={yAxisFormatter}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      bars={bars}
      tableData={tableData}
      tableProps={{
        referenceProps: { title: t('optimizationScreen.comparison') },
        comparedProps: { title: t('optimizationScreen.baseModel') },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: valueFormatter,
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.storage')} />
  )
}

export default AllSilos
