import React from 'react'
import InAndOutboundChart from './InAndOutboundChart'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import { transformDateToShortMonth } from 'screens/Optimization/utils'
import { valueFormatter } from 'screens/Optimization/utils/output'
import { capitalize } from 'utils/string'
import useHeatmap from './useHeatmap'
import { useLocalization } from 'providers/LocalizationProvider'
import { useOptimizationContext } from 'providers/OptimizationProvider'

const Inbound = ({ inboundData, originationData }) => {
  const { t } = useLocalization()
  const { comparisonID } = useOptimizationContext()
  const { heatmapData } = useHeatmap({
    data: originationData,
    mainRowKey: 'mesoregion',
    valueKey: 'inbound_volume'
  })

  return (
    <>
      <h3 className='text-lg font-bold mb-8'>{ t('optimizationScreen.storage.inbound.title') }</h3>
      {inboundData.map(({ transportation_mode, data: volumes }) => (
        <React.Fragment key={transportation_mode}>
          <InAndOutboundChart
            transportationMode={transportation_mode}
            volumes={volumes}
          />
        </React.Fragment>
      ))}
      <div>
        <h3 className='text-base font-bold mb-6'>{ t('optimizationScreen.storage.inbound.origination') }</h3>
        <MultiColumnsBoxTable
          showTitles
          expandable
          data={heatmapData}
          referenceProps={{ title: t('optimizationScreen.comparison') }}
          comparedProps={{ title: t('optimizationScreen.baseModel') }}
          groupLabelFormatter={transformDateToShortMonth}
          rowTitleFormatter={(v) => (v ? capitalize(v) : '-')}
          columnValueFormatter={valueFormatter}
          referencePropKey={comparisonID}
        />
      </div>
    </>
  )
}

export default Inbound
