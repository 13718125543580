import dayjs from 'dayjs'
import { getMonthsForPeriod } from './output'

export const NO_DATA_LABEL = 'N/A'

export const hasNoData = (data, key = 'volumes') => {
  return data.every(item => {

    if (!item || !item[key]) {
      return true
    }

    const volumes = item[key];
    return Object.values(volumes).every(value => {
      if (typeof value === 'object') {
        return Object.values(value).every(subValue => subValue === NO_DATA_LABEL);
      }
      return value === NO_DATA_LABEL;
    });
  });
}

export const createMonthObject = (start, end, defaultValue) => {
  return getMonthsForPeriod(start, end).reduce(
    (acc, cur) => ({ ...acc, [cur]: defaultValue }),
    {}
  )
}

export const kgToTons = 1000

export function transformDateToShortMonth(dateStr) {
  return dayjs(dateStr).format('MMM')
}

export const getMaxAxisValue = (data, selectedTab) => {
  if (selectedTab === 'Utilization %') {
    return 100
  }
  let maxValue = -Infinity
  data.forEach((e) => {
    e.monthlyData.forEach((dataPoint) => {
      if (dataPoint.BE > maxValue) {
        maxValue = dataPoint.BE
      }
    })
  })
  return maxValue < 100 ? 100 : maxValue
}

export function calculateTrend(val, refVal) {
  return Math.abs(val - refVal) < 0.001
    ? 0
    : val < refVal
    ? -(1 - val / refVal)
    : 1 - refVal / val
}
