import Tooltip from 'components/Tooltip'
import { formatter } from 'utils/string'
const OMIT = ['coordinates', 'location', 'stroke', 'fill', 'id']

export const SvgMapTooltip = ({ data, coordinates = { x: 0, y: 0 }, show }) => {
  const keyValues = Object.keys(data)
    ?.filter((item) => item && !OMIT.includes(item))
    ?.map((key) => `${formatter(key?.toString())}: ${data[key]}`)

  return (
    <Tooltip
      show={show}
      className='fixed'
      style={{ top: coordinates.y, left: coordinates.x }}
    >
      {keyValues.map((item, index) => {
        return (
          <p key={index} style={{ color: 'white' }}>
            {item}
          </p>
        )
      })}
    </Tooltip>
  )
}

export default SvgMapTooltip
