import { useMemo, useState, useCallback } from 'react'
import { SingleVolumeCard } from 'components/Cards'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import BarChart from 'components/Graphs/BarChart'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { NO_DATA_LABEL } from 'screens/Optimization/utils'
import { createMonthObject } from 'screens/Optimization/utils'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { formatNumber } from 'utils/number'
import { formatter } from 'utils/string'
import { labelFormatter } from 'screens/Optimization/utils/output'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'

export default function VolumeAndUtilization({ data }) {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    selectedProducts,
    executionData: { start_date, end_date },
    comparisonID
  } = useOptimizationContext()
  const tabs = [
    { value: 'volume', label: t('optimizationScreen.volume') },
    { value: 'utilization', label: t('optimizationScreen.utilization') }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)

  const emptyGraph = useMemo(
    () =>
      createMonthObject(start_date, end_date, {
        baseModel: NO_DATA_LABEL,
        capacity: NO_DATA_LABEL
      }),
    [start_date, end_date]
  )

  const calcCurrentValue = useCallback(
    ({
      currentValue,
      month,
      volume,
      execution_id,
      contracted_and_spot_vol
    }) => {
      if (execution_id !== baseModelId) {
        if (!currentValue) {
          return { ...emptyGraph }
        } else {
          return currentValue
        }
      } else {
        if (!currentValue) {
          return {
            ...emptyGraph,
            [month]: {
              baseModel: volume,
              capacity: contracted_and_spot_vol || NO_DATA_LABEL
            }
          }
        } else {
          return {
            ...currentValue,
            [month]: {
              ...currentValue[month],
              capacity:
                currentValue[month].capacity === NO_DATA_LABEL
                  ? contracted_and_spot_vol || NO_DATA_LABEL
                  : currentValue[month].capacity + contracted_and_spot_vol,
              baseModel:
                currentValue[month].baseModel === NO_DATA_LABEL
                  ? volume
                  : currentValue[month].baseModel + volume
            }
          }
        }
      }
    },
    [baseModelId, emptyGraph]
  )

  const cardData = useMemo(() => {
    const filteredData = data.filter((v) =>
      selectedProducts.length > 0
        ? selectedProducts.includes(v.product_ui_type)
        : true
    )

    const aggregationKey = 'asset'

    const calculatedData = filteredData.reduce(
      (
        acc,
        {
          month,
          execution_id,
          transported_volume: volume,
          max_contracted_vol: contracted_and_spot_vol,
          ...item
        }
      ) => ({
        ...acc,
        [item[aggregationKey]]: {
          ...acc[[item[aggregationKey]]],
          ...item,
          capacity:
            execution_id === baseModelId && contracted_and_spot_vol
              ? (acc[[item[aggregationKey]]]?.capacity || 0) +
                contracted_and_spot_vol
              : acc[[item[aggregationKey]]]?.capacity,
          comparisonCapacity:
            execution_id === comparisonID && contracted_and_spot_vol
              ? (acc[[item[aggregationKey]]]?.comparisonCapacity || 0) +
                contracted_and_spot_vol
              : acc[[item[aggregationKey]]]?.comparisonCapacity,
          allVolume:
            execution_id === baseModelId
              ? (acc[[item[aggregationKey]]]?.allVolume || 0) + volume
              : acc[[item[aggregationKey]]]?.allVolume,
          comparisonAllVolume:
            execution_id === comparisonID
              ? (acc[[item[aggregationKey]]]?.comparisonAllVolume || 0) + volume
              : acc[[item[aggregationKey]]]?.comparisonAllVolume,
          volumes: calcCurrentValue({
            currentValue: acc[item[aggregationKey]]?.volumes,
            month,
            volume,
            execution_id,
            contracted_and_spot_vol
          })
        }
      }),
      {}
    )

    return Object.entries(calculatedData)
      .map(([aggregatedAsset, { volumes, ...rest }]) => {
        return {
          aggregatedAsset,
          volumes: Object.entries(volumes).map(
            ([month, { baseModel, capacity }]) => ({
              month,
              volume:
                selectedTab === 'utilization'
                  ? baseModel === NO_DATA_LABEL || capacity === NO_DATA_LABEL
                    ? NO_DATA_LABEL
                    : ((baseModel / capacity) * 100).toFixed(0)
                  : baseModel
            })
          ),
          ...rest
        }
      })
      .sort((a, b) => a.aggregatedAsset.localeCompare(b.aggregatedAsset))
  }, [
    data,
    selectedProducts,
    calcCurrentValue,
    baseModelId,
    comparisonID,
    selectedTab
  ])

  const calcNearCapacity = useCallback((capacity, allVolume) => {
    if (capacity && (allVolume / capacity) * 100 >= 90) {
      return t('optimizationScreen.nearCapacityLimit')
    }
  }, [t])

  const calcPercentage = useCallback(
    ({ capacity, allVolume, comparisonCapacity, comparisonAllVolume }) => {
      if (selectedTab === 'utilization') {
        return (
          +(
            (comparisonAllVolume /
              comparisonCapacity /
              (allVolume / capacity)) *
            100
          ).toFixed() - 100
        )
      }
      return +((comparisonAllVolume / allVolume) * 100).toFixed() - 100
    },
    [selectedTab]
  )

  return (
    <SectionBox>
      <div>
        <div className='flex items-center w-fit'>
          <h3 className='text-xl font-bold'>
            {t(
              'optimizationScreen.transportation.volumeAndUtilization.titleArg'
            )}
          </h3>
        </div>

        <p className='text-grey-700 mb-6'></p>
        <TabsComponent value={selectedTab} onChange={setSelectedTab}>
          {tabs.map((tab, index) => {
            return (
              <Tab value={tab.value} label={tab.label} key={tab.value}>
                <div className='grid grid-cols-3 gap-3'>
                  {cardData
                    .filter((data) => data.allVolume !== 0)
                    .map(
                      ({
                        aggregatedAsset,
                        volumes,
                        allVolume,
                        capacity,
                        comparisonAllVolume,
                        comparisonCapacity
                      }) => {
                        return (
                          <SingleVolumeCard
                            key={aggregatedAsset}
                            value={
                              tab.value === 'utilization'
                                ? `${((allVolume / capacity) * 100).toFixed(
                                    0
                                  )}%`
                                : `${formatNumber(allVolume / kgToTons, {
                                    maximumFractionDigits: 0
                                  })} kTons`
                            }
                            percentage={calcPercentage({
                              comparisonAllVolume,
                              comparisonCapacity,
                              capacity,
                              allVolume
                            })}
                            title={formatter(aggregatedAsset)}
                            warning={calcNearCapacity(capacity, allVolume)}
                            disabled
                          >
                            <BarChart
                              data={volumes}
                              variant='small'
                              bars={[
                                {
                                  dataKey: 'volume',
                                  name:
                                    tab.value === 'utilization'
                                      ? t('optimizationScreen.utilization')
                                      : t('optimizationScreen.volume')
                                }
                              ]}
                              tooltipFormatter={
                                tab.value === 'utilization'
                                  ? (v) => `${v}%`
                                  : labelFormatter
                              }
                              xAxisProps={{
                                dataKey: 'month',
                                tickFormatter: (v) =>
                                  transformDateToShortMonth(v)[0]
                              }}
                              yAxisProps={{
                                domain:
                                  tab.value === 'utilization'
                                    ? [0, () => 100]
                                    : [0],
                                tickFormatter: (v) =>
                                  tab.value === 'utilization'
                                    ? `${v}%`
                                    : `${(v / kgToTons).toFixed(0)} kTons`
                              }}
                            />
                          </SingleVolumeCard>
                        )
                      }
                    )}
                </div>
              </Tab>
            )
          })}
        </TabsComponent>
      </div>
    </SectionBox>
  )
}
