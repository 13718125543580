import TableChart from 'components/Graphs/TableChart'
import useProductFilter from 'hooks/useProductFilter'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import useSummaryItems from 'hooks/useSummaryItems'
import useGraph from 'hooks/useGraph'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, storage_volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? storage_volume
          : currentData[product_ui_type] + storage_volume
    }
  }
  return currentData === NO_DATA_LABEL
    ? storage_volume
    : currentData + storage_volume
}

const calcCapacity = ({
  currentData,
  record: { execution_id, storage_type, ...record },
  capacityKey
}) => {
  return currentData === NO_DATA_LABEL
    ? { [storage_type]: record[capacityKey] }
    : { ...currentData, [storage_type]: record[capacityKey] }
}

const Stock = ({ data, selectedSubProducts }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID, selectedProducts, selectedProductTypes } =
    useOptimizationContext()
  const { t } = useLocalization()

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'storage_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  const { filteredData } = useProductFilter({
    data,
    selectedSubProducts
  })

  const { summaryItems } = useSummaryItems({
    data: filteredData,
    volumeKey: 'storage_volume'
  })

  const { volumeGraphData, bars } = useGraph({
    data: filteredData,
    monthContextData: {
      capacity: calcCapacity
    },
    capacityKey,
    calcExecutionIdData
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'storage_volume',
    data: filteredData,
    filterProducts: true
  })

  return (
    <TableChart
      title={t('optimizationScreen.export.storage.title')}
      summaryItems={summaryItems}
      data={volumeGraphData}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      yAxisProps={{ tickFormatter: yAxisFormatter }}
      bars={bars}
      tooltipFormatter={labelFormatter}
      scatters={[
        {
          dataKey: 'capacity.fixed',
          fill: '#000',
          name: t('optimizationScreen.fixedCapacity'),
          thickness: 2
        },
        {
          dataKey: 'capacity.variable',
          fill: '#2BAC82',
          name: t('optimizationScreen.variableCapacity')
        }
      ]}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: valueFormatter,
        rowTitleFormatter: capitalize
      }}
    />
  )
}

export default Stock
