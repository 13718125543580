import classNames from 'classnames'
/**
 * @param {JSX.Element} props.sidebar
 * @param {JSX.Element} props.main
 * @param {string} props.variant
 * @returns {JSX.Element}
 */

const LayoutWithSidebar = ({ sidebar, main, variant = 'default' }) => {
  return (
    <div className='h-[calc(100vh-64px)] flex-auto flex'>
      <div className='mx-auto inline-flex w-full flex-auto'>
        <div
          className={classNames(
            'bg-background overflow-auto pt-10 pr-4 xl:pr-10 relative pl-4 2xl:ml-20',
            {
              'min-w-[300px] w-[300px] border-r': variant === 'default',
              'w-[620px]': variant === 'wide'
            }
          )}
        >
          {sidebar}
        </div>
        {main}
      </div>
    </div>
  )
}

export default LayoutWithSidebar
