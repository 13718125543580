import { useMemo, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ReviewTable from 'components/Tables/ReviewTable'
import { useNewOptContext } from 'screens/NewOptimization/NewOptContext'
import Button from 'components/Inputs/Button'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { putDatasetFiles } from 'utils/request'
import { useLocalization } from 'providers/LocalizationProvider'
import { formatter, stripDataTypePrefix } from 'utils/string'
import ProgressBar from 'components/Feedback/Loader/ProgressBar'
import Alert from 'components/Feedback/Alert'

const BatchUploadModal = ({ showBatchUpload, setShowBatchUpload }) => {
  const {
    setUpdatedFileIds,
    mappedFileList,
    templateDistances,
    showAllFiles,
    setShowAllFiles,
    allFilesValid,
    hiddenFileCount,
    fileCount,
    invalidFiles,
    duplicateTemplates
  } = useNewOptContext()

  const { t } = useLocalization()

  const [uploadProgress, setUploadProgress] = useState(undefined)

  const differentNameFileCount = useMemo(
    () => mappedFileList.filter((file) => !file.matched).length,
    [mappedFileList]
  )

  return (
    <Modal open={showBatchUpload}>
      {uploadProgress ? (
        <div className='flex flex-col min-w-[70vw]'>
          <div>
            <h2 className='font-bold text-lg mb-4'>
              {t('newOptimizationScreen.batchUpload.uploadProgressTitle')}
            </h2>
          </div>
          <ProgressBar
            loaded={uploadProgress.loaded}
            total={uploadProgress.total}
            variant='transfer'
          />
        </div>
      ) : (
        <div className='flex flex-col min-w-[70vw]'>
          <div className='flex items-start justify-between p-1 w-full text-600'>
            <div className='flex w-full'>
              <div>
                <h2 className='text-2xl font-bold'>Files validation</h2>
                <p className='text-base text-grey-400 mt-4'>
                  {differentNameFileCount === 1
                    ? t('newOptimizationScreen.batchUpload.titleSingular')
                    : t('newOptimizationScreen.batchUpload.titlePlural', {
                        count: differentNameFileCount
                      })}
                </p>
              </div>
            </div>
            <div>
              <XMarkIcon
                className={`w-6 h-6 text-grey-400 cursor-pointer`}
                strokeWidth={3}
                onClick={() => setShowBatchUpload(false)}
              />
            </div>
          </div>
          <div>
            <div className='flex flex-col gap-2 pb-4'>
              {!!invalidFiles && (
                <Alert
                  message={
                    invalidFiles === 1
                      ? t(
                          'newOptimizationScreen.batchUpload.failedValidationMessageSingular'
                        )
                      : t(
                          'newOptimizationScreen.batchUpload.failedValidationMessagePlural',
                          {
                            count: invalidFiles
                          }
                        )
                  }
                  status='error'
                />
              )}
              {duplicateTemplates.length > 0 && (
                <Alert
                  message={t(
                    'newOptimizationScreen.batchUpload.duplicateTemplateError',
                    {
                      templates: duplicateTemplates
                        .map((x) => `"${formatter(stripDataTypePrefix(x))}"`)
                        .join(', ')
                    }
                  )}
                  status='error'
                />
              )}
              {hiddenFileCount > 0 &&
                hiddenFileCount !== fileCount &&
                !showAllFiles && (
                  <Alert status='info'>
                    <div className='flex-1'>
                      {t(
                        'newOptimizationScreen.batchUpload.hiddenFilesMessage',
                        {
                          count: hiddenFileCount
                        }
                      )}
                    </div>
                    <Button
                      className={'ml-2'}
                      onClick={() => setShowAllFiles(true)}
                    >
                      {t('newOptimizationScreen.batchUpload.showAllFiles')}
                    </Button>
                  </Alert>
                )}
              {!showAllFiles && hiddenFileCount === fileCount && (
                <Alert status='success'>
                  <div className='flex-1'>
                    {t(
                      'newOptimizationScreen.batchUpload.hiddenFilesSuccessMessage',
                      {
                        count: hiddenFileCount
                      }
                    )}
                  </div>
                  <Button
                    className={'ml-2'}
                    onClick={() => setShowAllFiles(true)}
                  >
                    {t('newOptimizationScreen.batchUpload.showAllFiles')}
                  </Button>
                </Alert>
              )}
            </div>
          </div>
          <div className='flex flex-1 overflow-y-auto w-full'>
            {(showAllFiles || hiddenFileCount !== fileCount) && (
              <ReviewTable
                mappedFileList={mappedFileList}
                templateDistances={templateDistances}
              />
            )}
          </div>
          <div className='pt-8 border-t-2 border-slate-300 flex w-full justify-end'>
            <Button
              variant='filled'
              disabled={!allFilesValid}
              className='w-40'
              onClick={async (e) => {
                e.preventDefault()
                try {
                  const result = await putDatasetFiles(
                    '/data/upload',
                    mappedFileList.map((file) => ({
                      type: file.type || 'UNKNOWN',
                      file: file.rawFile
                    })),
                    false,
                    (progressEvent) => {
                      if (progressEvent.loaded >= progressEvent.total) {
                        return
                      }

                      setUploadProgress((prev) =>
                        prev && progressEvent.loaded <= prev.loaded
                          ? prev
                          : progressEvent
                      )
                    }
                  )

                  if (result?.data) {
                    setUpdatedFileIds((prev) => {
                      return [...prev, ...result.data.map((x) => x.id)]
                    })
                  }
                } catch (error) {
                  console.error('upload failed', error)
                }

                setShowBatchUpload(false)
                setTimeout(() => setUploadProgress(undefined), 1000)
              }}
            >
              {t('newOptimizationScreen.batchUpload.confirm')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default BatchUploadModal

function Modal({ children, open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment} className='w-screen'>
      <Dialog as='div' className='relative z-10 w-screen' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          className='w-screen'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-screen' />
        </Transition.Child>

        <div className='flex w-full fixed inset-0 z-10 overflow-y-auto w-screen'>
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 w-screen'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform flex rounded-lg bg-white py-10  text-left shadow-xl transition-all px-12 max-h-[80vh]'>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
