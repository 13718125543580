import { useEffect, useState } from 'react'
import { useLocalization } from 'providers/LocalizationProvider'
import TemporaryLoginModal from './temporaryLogin'

/**
 *
 * @param login
 * @param authenticationState
 * @param setUser
 * @param {IAccountInfo} accountInfo
 * @return {JSX.Element}
 * @constructor
 */
export default function LoginSSO({
  login,
  authenticationState,
  setUser,
  accountInfo
}) {
  const [openModal, setOpenModal] = useState(false)
  const { t } = useLocalization()

  useEffect(() => {
    setUser(accountInfo?.account?.userName, accountInfo?.jwtAccessToken)
  }, [
    accountInfo?.account?.userName,
    accountInfo?.jwtAccessToken,
    authenticationState,
    setUser
  ])

  return (
    <>
      <TemporaryLoginModal
        open={openModal}
        setOpen={() => setOpenModal(false)}
        setUser={setUser}
      />
      <div className='flex flex-row w-screen h-screen'>
        <div className='flex flex-col lg:w-1/2 w-full'>
          <div className='relative'>
            <img
              className='hidden lg:block w-24 h-10 absolute top-4 lg:left-12'
              src='/img/logo-vector.svg'
              alt='Logo_Vector'
            />
          </div>
          <div className='flex flex-col w-full h-screen items-center justify-center relative'>
            <img
              className='felx lg:hidden w-24'
              src='/img/logo-vector.svg'
              alt='Logo_Vector'
            />
            <div className='flex flex-col items-center justify-center '>
              <div className='mb-4'>
                <h3 className='font-bold text-3xl text-blue-700 w-80 text-center'>
                  {t('loginScreen.welcome')}
                </h3>
              </div>
              <button
                onClick={login}
                className='mt-2 transition-colors inline-flex items-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 w-full justify-center'
              >
                {t('loginScreen.ssoSignIn')}
              </button>
            </div>
            <button
              onClick={() => setOpenModal(true)}
              className='absolute bottom-12 text-blue-400 underline font-medium text-sm'
            >
              {t('loginScreen.helpLogin')}
            </button>
          </div>
        </div>
        <img
          className='hidden lg:flex object-cover w-1/2'
          srcSet='img/image-bunge.png 480w, img/image-bunge_1.5x.jpg 800w'
          src='img/image-bunge_2x.jpg'
          alt='Image BG Bunge'
        />
      </div>
    </>
  )
}
