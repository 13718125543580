import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { swrConfig } from '../utils'
import { get } from '../utils/request'

export const useLogs = () => {
  const { id } = useParams()
  const { data: logs } = useSWR(
    `execution/${id}/logs`,
    async (url) => {
      const { data } = await get(url)
      return data
    },
    { ...swrConfig }
  )

  return {
    logs
  }
}
