import { Font } from 'vis'
import { colorScaleByVolume } from './index'
import {
  EdgeDefaultColor,
  EdgeStyle,
  NodeDefaultColor,
  NodeStyle,
  StyleConfig,
  edgeColors,
  nodeColors
} from '../types'

export const useGraphStyleBehavior = () => {
  const nodeDefaultStyle: NodeStyle = {
    opacity: 1,
    font: {
      color: 'rgba(52, 52, 52, 1)',
      size: 16, // px
      strokeWidth: 0, // px
      strokeColor: '#ffffff',
      vadjust: 0
    }
  }

  const edgeDefaultStyle: EdgeStyle = {
    color: {
      opacity: 1
    }
  }

  const nodeStyles: StyleConfig<NodeStyle, NodeDefaultColor> = {
    default: (level) => ({
      ...nodeDefaultStyle,
      color: nodeColors[level as number]
    }),
    onSelect: {
      ...nodeDefaultStyle,
      font: {
        ...(nodeDefaultStyle.font as Font),
        size: 18, // px
        strokeWidth: 1 // px
      } // px
    },
    onFade: {
      font: {
        ...(nodeDefaultStyle.font as Font),
        color: 'rgba(0, 0, 0, 0.0)'
      },
      opacity: 0.2
    },
    onHeatMap: (percent: number, total: number) => ({
      color: colorScaleByVolume(percent, total)
    })
  }

  const edgeStyles: StyleConfig<EdgeStyle, EdgeDefaultColor> = {
    default: (title) => ({
      ...edgeDefaultStyle,
      color: { ...edgeDefaultStyle.color, color: edgeColors[title] }
    }),
    onSelect: { ...edgeDefaultStyle, color: { ...edgeDefaultStyle.color } },
    onFade: {
      ...edgeDefaultStyle,
      color: { ...edgeDefaultStyle.color, opacity: 0.0 }
    },
    onHeatMap: () => ({ ...edgeDefaultStyle })
  }

  return {
    nodeStyles,
    edgeStyles
  }
}
