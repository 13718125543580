import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import PortDetailsVolumeAndUtilization from './PortDetailsVolumeAndUtilization'
import InboundRoutes from './InboundRoutes'
import TransshipmentDetails from './TransshipmentDetails'
import MesoregionDetails from './MesoregionDetails'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'

export const PortDetails = ({
  portAsset,
  displayedProducts,
  capacityKey: capacityPropName
}) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { t } = useLocalization()

  const {
    data: { port }
  } = useSWR([
    `/execution/outputs/${baseModelId}/export_details`,
    {
      asset: portAsset,
      bestEstimateId: comparisonID
    }
  ])

  return (
    <>
      <SectionBox>
        <PortDetailsVolumeAndUtilization
          data={port.exportSummary}
          selectedSubProducts={displayedProducts}
          capacityKey={capacityPropName}
        />
      </SectionBox>
      <SectionBox>
        <h3 className='text-lg font-bold mb-8'>{ t('optimizationScreen.export.portInboundRoutes') }</h3>
        {port.inbound
          .filter((i) => i.transportation_mode === 'rail')
          .map(({ transportation_mode, data }) => {
            return (
              <InboundRoutes
                key={transportation_mode}
                transportationMode={transportation_mode}
                volumes={data}
                selectedSubProducts={displayedProducts}
              />
            )
          })}
        <TransshipmentDetails
          volumes={port.transshipmentDetails}
          selectedSubProducts={displayedProducts}
        />
        {port.inbound
          .filter((i) => i.transportation_mode === 'truck')
          .map(({ transportation_mode, data }) => {
            return (
              <InboundRoutes
                key={transportation_mode}
                transportationMode={transportation_mode}
                volumes={data}
                selectedSubProducts={displayedProducts}
              />
            )
          })}
        {port.inbound
          .filter(
            (i) =>
              i.transportation_mode !== 'truck' &&
              i.transportation_mode !== 'rail'
          )
          .map(({ transportation_mode, data }) => {
            return (
              <InboundRoutes
                key={transportation_mode}
                transportationMode={transportation_mode}
                volumes={data}
                selectedSubProducts={displayedProducts}
              />
            )
          })}
        <MesoregionDetails
          volumes={port.regionDetails}
          selectedSubProducts={displayedProducts}
        />
      </SectionBox>
    </>
  )
}
