import { useMemo, useState, useCallback, useEffect } from 'react'
import { SingleVolumeCard } from 'components/Cards'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import BarChart from 'components/Graphs/BarChart'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { NO_DATA_LABEL, calculateTrend } from 'screens/Optimization/utils'
import { createMonthObject } from 'screens/Optimization/utils'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { formatNumber } from 'utils/number'
import { formatter } from 'utils/string'
import Select from 'components/Inputs/Select'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import dayjs from 'dayjs'
import VariationSection from './VariationSection'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'

const tooltipFormatter = (labelValue) =>
  labelValue === NO_DATA_LABEL
    ? labelValue
    : `${(labelValue / kgToTons).toFixed(0)} kTons`

export default function VolumeAndUtilization({
  data,
  onTerminalSelected,
  capacityKey
}) {
  const { id: baseModelId } = useParams()
  const [variationsMonth, setVariationsMonth] = useState()
  const {
    executionData: { start_date, end_date },
    comparisonID
  } = useOptimizationContext()
  const { t } = useLocalization()
  const tabs = [
    { value: 'volume', label: t('optimizationScreen.volume') },
    { value: 'utilization', label: t('optimizationScreen.utilization') }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)

  const emptyGraph = useMemo(
    () =>
      createMonthObject(start_date, end_date, {
        baseModel: NO_DATA_LABEL,
        comparison: NO_DATA_LABEL,
        capacity: NO_DATA_LABEL
      }),
    [start_date, end_date]
  )

  const calcCurrentValue = useCallback(
    ({ currentValue, month, volume, execution_id, ...item }) => {
      if (!currentValue) {
        return {
          ...emptyGraph,
          [month]: {
            comparison: execution_id === comparisonID ? volume : NO_DATA_LABEL,
            baseModel: execution_id === baseModelId ? volume : NO_DATA_LABEL,
            capacity:
              execution_id === baseModelId ? item[capacityKey] : NO_DATA_LABEL
          }
        }
      } else {
        return {
          ...currentValue,
          [month]: {
            ...currentValue[month],
            capacity:
              execution_id === baseModelId
                ? item[capacityKey]
                : currentValue[month].capacity,
            baseModel:
              execution_id === baseModelId
                ? currentValue[month].baseModel === NO_DATA_LABEL
                  ? volume
                  : currentValue[month].baseModel + volume
                : currentValue[month].baseModel,
            comparison:
              execution_id === comparisonID
                ? currentValue[month].comparison === NO_DATA_LABEL
                  ? volume
                  : currentValue[month].comparison + volume
                : currentValue[month].comparison
          }
        }
      }
    },
    [baseModelId, emptyGraph, capacityKey, comparisonID]
  )

  const cardData = useMemo(() => {
    const aggregationKey = 'port_terminal'
    const calculatedData = data.reduce(
      (acc, { month, execution_id, volume, ...item }) => ({
        ...acc,
        [item[aggregationKey]]: {
          ...acc[item[aggregationKey]],
          ...item,
          capacity: {
            ...(acc[item[aggregationKey]]?.capacity || {}),
            ...(baseModelId === execution_id
              ? { [month]: item[capacityKey] }
              : {})
          },
          comparisonCapacity: {
            ...(acc[item[aggregationKey]]?.comparisonCapacity || {}),
            ...(comparisonID === execution_id
              ? { [month]: item[capacityKey] }
              : {})
          },
          allVolume:
            execution_id === baseModelId
              ? (acc[item[aggregationKey]]?.allVolume || 0) + volume
              : acc[item[aggregationKey]]?.allVolume,
          comparisonAllVolume:
            execution_id === comparisonID
              ? (acc[item[aggregationKey]]?.comparisonAllVolume || 0) + volume
              : acc[item[aggregationKey]]?.comparisonAllVolume,
          volumes: calcCurrentValue({
            currentValue: acc[item[aggregationKey]]?.volumes,
            month,
            volume,
            execution_id,
            ...item
          })
        }
      }),
      {}
    )

    return Object.entries(calculatedData)
      .map(
        ([
          aggregatedAsset,
          { volumes, capacity, comparisonCapacity, ...rest }
        ]) => {
          return {
            aggregatedAsset,
            volumes: Object.entries(volumes).map(
              ([month, { baseModel, comparison, capacity }]) => ({
                month,
                volume: {
                  baseModel:
                    selectedTab === 'utilization'
                      ? baseModel === NO_DATA_LABEL ||
                        capacity === NO_DATA_LABEL
                        ? NO_DATA_LABEL
                        : ((baseModel / capacity) * 100).toFixed(0)
                      : baseModel,
                  comparison
                }
              })
            ),
            capacity: Object.values(capacity).reduce(
              (acc, cur) => (acc === NO_DATA_LABEL ? cur : acc + cur),
              NO_DATA_LABEL
            ),
            comparisonCapacity: Object.values(comparisonCapacity).reduce(
              (acc, cur) => (acc === NO_DATA_LABEL ? cur : acc + cur),
              NO_DATA_LABEL
            ),
            ...rest
          }
        }
      )
      .sort((a, b) => a.aggregatedAsset.localeCompare(b.aggregatedAsset))
  }, [
    data,
    selectedTab,
    baseModelId,
    comparisonID,
    calcCurrentValue,
    capacityKey
  ])

  const monthDropdown = useMemo(() => {
    const months = getMonthsForPeriod(start_date, end_date)
    return months.map((x) => ({
      label: dayjs(x).format('MMMM YYYY'),
      value: x
    }))
  }, [start_date, end_date])

  const calcNearCapacity = useCallback(
    (capacity, allVolume) => {
      if (capacity && (allVolume / capacity) * 100 >= 90) {
        return t('optimizationScreen.nearCapacityLimit')
      }
    },
    [t]
  )

  const calcPercentage = useCallback(
    ({ capacity, allVolume, comparisonCapacity, comparisonAllVolume }) => {
      if (selectedTab === 'utilization') {
        return (
          +(
            (comparisonAllVolume /
              comparisonCapacity /
              (allVolume / capacity)) *
            100
          ).toFixed() - 100
        )
      }
      return +((comparisonAllVolume / allVolume) * 100).toFixed() - 100
    },
    [selectedTab]
  )

  const variationCards = cardData.map(({ aggregatedAsset, volumes }) => {
    const filteredVolumes = variationsMonth
      ? volumes.filter(
          (x) =>
            new Date(Date.parse(x.month)).getFullYear() ===
              new Date(Date.parse(variationsMonth)).getFullYear() &&
            new Date(Date.parse(x.month)).getMonth() ===
              new Date(Date.parse(variationsMonth)).getMonth()
        )
      : []

    const baseModel = filteredVolumes
      .map((x) => x.volume.baseModel)
      .reduce((acc, cur) => (cur === NO_DATA_LABEL ? acc : acc + cur), 0)
    const comparison = filteredVolumes
      .map((x) => x.volume.comparison)
      .reduce((acc, cur) => (cur === NO_DATA_LABEL ? acc : acc + cur), 0)

    return {
      aggregatedAsset,
      baseModel,
      comparison,
      trend: calculateTrend(baseModel, comparison)
    }
  })

  useEffect(() => {
    setVariationsMonth(monthDropdown[0]?.value)
  }, [monthDropdown])

  return (
    <SectionBox>
      <div className='flex justify-between w-full items-center'>
        <h3 className='text-xl font-bold text-grey-700'>
          {t('optimizationScreen.export.volumeAndUtilization.title')}
        </h3>
      </div>
      <TabsComponent value={selectedTab} onChange={setSelectedTab}>
        {tabs.map((tab) => {
          return (
            <Tab value={tab.value} label={tab.label} key={tab.value}>
              <div className='grid grid-cols-3 gap-3'>
                {cardData.map(
                  ({
                    aggregatedAsset,
                    volumes,
                    allVolume,
                    capacity,
                    comparisonAllVolume,
                    comparisonCapacity,
                    asset_name,
                    port_terminal
                  }) => {
                    return (
                      <SingleVolumeCard
                        key={aggregatedAsset}
                        value={
                          tab.value === 'utilization'
                            ? `${((allVolume / capacity) * 100).toFixed(0)}%`
                            : `${formatNumber(allVolume / kgToTons, {
                                maximumFractionDigits: 0
                              })} kTon`
                        }
                        percentage={calcPercentage({
                          comparisonAllVolume,
                          comparisonCapacity,
                          capacity,
                          allVolume
                        })}
                        title={formatter(asset_name)}
                        warning={calcNearCapacity(capacity, allVolume)}
                        onClick={() => onTerminalSelected(port_terminal)}
                      >
                        <BarChart
                          data={volumes}
                          variant='small'
                          bars={[
                            {
                              dataKey: 'volume.baseModel',
                              name:
                                tab.value === 'utilization'
                                  ? t('optimizationScreen.utilization')
                                  : t('optimizationScreen.volume')
                            }
                          ]}
                          tooltipFormatter={
                            tab.value === 'utilization'
                              ? (v) => `${v}%`
                              : tooltipFormatter
                          }
                          xAxisProps={{
                            dataKey: 'month',
                            tickFormatter: (v) =>
                              transformDateToShortMonth(v)[0]
                          }}
                          yAxisProps={{
                            domain: tab.value === 'utilization' && [
                              0,
                              () => 100
                            ],
                            tickFormatter: (v) =>
                              tab.value === 'utilization'
                                ? `${v}%`
                                : `${(v / kgToTons).toFixed(0)} kTons`
                          }}
                        />
                      </SingleVolumeCard>
                    )
                  }
                )}
              </div>
            </Tab>
          )
        })}
      </TabsComponent>
      <div>
        <div className='flex mt-14 mb-8'>
          <h3 className='text-lg font-bold text-grey-500 mr-2'>
            {t('optimizationScreen.export.volumeAndUtilization.variations')}
          </h3>
          <Select
            variant='underlined'
            value={variationsMonth}
            options={monthDropdown}
            onValueChange={setVariationsMonth}
          />
        </div>
        <h4 className='text-md font-bold  mt-8 mb-4'>
          {t('optimizationScreen.export.volumeAndUtilization.increaseVolume')}
        </h4>
        <VariationSection
          cards={variationCards.filter(({ trend }) => trend > 0)}
        />
        <h4 className='text-md font-bold  mt-8 mb-4'>
          {t('optimizationScreen.export.volumeAndUtilization.stableVolume')}
        </h4>
        <VariationSection
          cards={variationCards.filter(({ trend }) => trend === 0)}
        />
        <h4 className='text-md font-bold  mt-8 mb-4'>
          {t('optimizationScreen.export.volumeAndUtilization.volumeReduction')}
        </h4>
        <VariationSection
          cards={variationCards.filter(({ trend }) => trend < 0)}
        />
      </div>
    </SectionBox>
  )
}
