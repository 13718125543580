import { useMemo, useCallback, useState } from 'react'
import TableActions from './TableActions'
import { get, put } from 'utils/request'
import { useNavigate } from 'react-router-dom'
import {
  stringMapperFactory,
  propertyMapperFactory
} from 'components/Tables/GridTable'
import useSWR from 'swr'
import EditButton from './EditButton'
import AssetsConfigurationsTable from './AssetsConfigurationsTable'
import { useCity } from 'providers/CityProvider'
import useSWRMutation from 'swr/mutation'
import Status from './Status'
import { useLocalization } from 'providers/LocalizationProvider'

const Transshipments = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()
  const { t } = useLocalization()
  const { cities } = useCity()

  const TABLE_HEADERS = [
    t('assetConfigurationScreen.transshipments.tableHeaders.transshipmentID'),
    t('assetConfigurationScreen.transshipments.tableHeaders.status'),
    t('assetConfigurationScreen.transshipments.tableHeaders.mesoregion'),
    t('assetConfigurationScreen.transshipments.tableHeaders.type'),
    t('assetConfigurationScreen.transshipments.tableHeaders.city'),
    null
  ]

  const transshipmentTypeFormatter = useCallback(
    (ts_type) => t(`transshipmentTypes.${ts_type.trim()}`),
    [t]
  )

  const mappers = [
    stringMapperFactory('transshipment_id'),
    Status,
    stringMapperFactory('city.meso'),
    propertyMapperFactory('transshipment_type', transshipmentTypeFormatter),
    stringMapperFactory('city.city'),
    {
      className: 'justify-end pr-4',
      content: EditButton
    }
  ]

  const { data, mutate } = useSWR('/transshipment', get)

  const { trigger } = useSWRMutation(
    `/transshipment`,
    async (url, { arg: { include, id } }) => {
      await put(`${url}/${id}`, { include })
    }
  )

  const handleOnEdit = useCallback(
    (item) => {
      navigate(`/assets/transshipments/${item.id}`)
    },
    [navigate]
  )

  const transshipmentsWithCities = useMemo(() => {
    return data.map((transshipment) => ({
      ...transshipment,
      city: cities.find((city) => city.id === transshipment.city_id)
    }))
  }, [data, cities])

  const filteredTransshipments = useMemo(
    () =>
      transshipmentsWithCities
        ?.filter(({ transshipment_id, include, transshipment_type, city }) =>
          [
            transshipment_id,
            include ? 'active' : 'inactive',
            city.meso,
            transshipment_type,
            city.city
          ]
            .join(' ')
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        )
        .sort((a, b) =>
          a.transshipment_id?.toLocaleLowerCase() >
          b.transshipment_id?.toLocaleLowerCase()
            ? 1
            : -1
        ),
    [transshipmentsWithCities, searchTerm]
  )

  return (
    <>
      <TableActions
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        placeholder={t(
          'assetConfigurationScreen.transshipments.searchbarPlaceholder'
        )}
        registerButtonLabel={t(
          'assetConfigurationScreen.transshipments.registerButtonLabel'
        )}
        onRegisterClick={() =>
          navigate('/assets/transshipments')
        }
      />
      <AssetsConfigurationsTable
        data={filteredTransshipments}
        columnMappers={mappers}
        columnHeaders={TABLE_HEADERS}
        rowContext={{ mutate, trigger, data, handleOnEdit }}
        columnSizes='20rem auto auto auto auto auto'
      />
    </>
  )
}

export default Transshipments
