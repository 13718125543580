import { useLocalization } from 'providers/LocalizationProvider'
import { useScenariosContext } from 'providers/ScenariosProvider'
import { useCallback } from 'react'

const SCENARIO_TEMPLATE_MAP = {
  'origination-volume': {
    BR: `WHATIF_BR_ORIGINATION_CURVE`,
    ARG: 'WHATIF_ARG_ORIGINATION_CURVE'
  },
  'silos-inbound_outbound_storage_capacity': {
    BR: 'TEMPLATES_BR_SILOS_CAPACITY',
    ARG: 'TEMPLATES_ARG_ACOPIOS_CAPACITY'
  },
  'silos-assets': {
    BR: 'WHATIF_BR_SILOS',
    ARG: 'WHATIF_ARG_ACOPIOS_MASTER'
  },
  'silos-products': {
    BR: 'WHATIF_BR_SILOS_PLANNED_PRODUCTS',
    ARG: 'TEMPLATES_ARG_PRODUCTS_ASSETS_MATRIX'
  },
  'crush_plants-inbound_outbound_storage_capacity': {
    BR: 'TEMPLATES_BR_CRUSH_CAPACITY',
    ARG: 'TEMPLATES_ARG_CRUSH_CAPACITY'
  },
  'crush_plants-maintenance_plan': {
    BR: 'WHATIF_BR_CRUSH_OPERATION',
    ARG: 'WHATIF_ARG_CRUSH_OPERATION'
  },
  'crush_plants-crushing_plan': {
    ARG: 'TEMPLATES_ARG_CRUSH_PLANNED_PRODUCTION'
  },
  'crush_plants-oil_export_demand': {
    ARG: 'TEMPLATES_ARG_PORT_PLANNED_EXPORT_VOLUME'
  },
  'crush_plants-internal_demand': {
    ARG: 'TEMPLATES_ARG_DOMESTIC_DEMAND_VOLUME'
  },
  'exports-inbound_outbound_storage_capacity': {
    BR: 'TEMPLATES_BR_PORT_CAPACITY',
    ARG: 'TEMPLATES_ARG_PORT_CAPACITY'
  },
  'exports-terminal_costs': {
    BR: 'TEMPLATES_BR_PORT_ELEVATION_COST',
    ARG: 'TEMPLATES_ARG_PORT_ELEVATION_COST'
  },
  'exports-demand': {
    BR: 'WHATIF_BR_PORTS_EXPORT_CURVE'
  },
  'exports-product_restriction': {
    ARG: 'TEMPLATES_ARG_PRODUCTS_ASSETS_MATRIX'
  },
  'exports-export_plan': {
    ARG: 'WHATIF_ARG_PORTS_EXPORT_CURVE'
  },
  'financial-market_structure': {
    BR: 'TEMPLATES_BR_PORT_PRICE'
  },
  'financial-fx': {
    ARG: 'TEMPLATES_ARG_FX_RATE'
  },
  'financial-export_prices': {
    ARG: 'WHATIF_ARG_PORT_EXPORT_PRICE'
  },
  'financial-crush_pickups': {
    BR: 'TEMPLATES_BR_CRUSH_PICKUPS',
    ARG: 'TEMPLATES_ARG_CRUSH_PICKUPS'
  },
  'financial-interest_rates': {
    BR: 'ASSETS_BR_FINANCIAL_RATES',
    ARG: 'TEMPLATES_ARG_INTEREST_RATE'
  }
}

export const useTemplate = () => {
  const { currentSection } = useScenariosContext()
  const { countryCode } = useLocalization()

  return useCallback(() => {
    return SCENARIO_TEMPLATE_MAP[currentSection][countryCode]
  }, [currentSection, countryCode])
}
