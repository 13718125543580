import classNames from 'classnames'

const CheckboxComponent = ({
  label,
  isChecked,
  onChange,
  className,
  disabled,
  ...props
}) => (
  <div
    className={classNames(
      {
        'opacity-50': disabled
      },
      className
    )}
  >
    <input
      disabled={disabled}
      name={label}
      className={'rounded border-gray-300 text-blue-400 focus:ring-blue-400'}
      type='checkbox'
      checked={isChecked}
      onChange={onChange}
      {...props}
    />
    {label && (
      <label className='pl-2 text-sm' htmlFor={label}>
        {label}
      </label>
    )}
  </div>
)

export default CheckboxComponent
