import { useMemo } from 'react'
import classNames from 'classnames'
import { ControlledCheckbox } from '../Inputs/Checkbox'
import { useUserInputsContext } from 'providers/UserInputsProvider'
import ControlledSwitcher from 'components/Inputs/Switchers/ControlledSwitcher'
import ControlledRangeSlider from 'components/Inputs/RangeSlider/ControlledRangeSlider'
import isUndefined from 'lodash/isUndefined'
import { Trans } from 'react-i18next'

/**
 * @param {string} controlName
 * @param {string} title
 * @param {string} description
 * @param {string} className
 * @returns {JSX.Element}
 */
const SettingCard = ({
  controlName,
  title,
  description,
  description_supporting,
  className,
  ...props
}) => {
  const { control, watch, getValues } = useUserInputsContext()

  const switcherControl = `advancedSettings.${controlName}.checked`
  const sliderControl = `advancedSettings.${controlName}.slider`
  const allowUnmetControl = `advancedSettings.${controlName}.allowUnmetValue`

  const hasSlider = !isUndefined(getValues(sliderControl))
  const hasAllowUnmet = !isUndefined(getValues(allowUnmetControl))
  const isSwitcherActive = watch(switcherControl)

  const showSlider = useMemo(() => {
    return hasSlider && isSwitcherActive
  }, [isSwitcherActive, hasSlider])

  const sliderValues = useMemo(() => {
    if (hasSlider) {
      const sliderProps = getValues(sliderControl)
      return {
        min: sliderProps.min,
        isUnlimited: sliderProps.isUnlimited,
        max: sliderProps.max,
        default: sliderProps.default
      }
    }
  }, [hasSlider, getValues, sliderControl])

  const cardDescription =
    isSwitcherActive && hasAllowUnmet ? description_supporting : description

  return (
    <div
      className={classNames(
        'w-full border rounded-md flex flex-col justify-between pt-10 h-full',
        className,
        showSlider ? 'row-span-2' : ''
      )}
      {...props}
    >
      <div className='px-4'>
        <div className='flex justify-between mb-4'>
          <h3 className='font-bold text-md'>{title}</h3>
          <ControlledSwitcher
            controllerProps={{
              name: switcherControl,
              control
            }}
            disabled={true}
          />
        </div>
        {hasAllowUnmet && (
          <ControlledCheckbox
            valueKey='value'
            controllerProps={{
              name: allowUnmetControl,
              control
            }}
            disabled={true}
            label='Allow unmet value'
          />
        )}
        <p className='mt-3 text-grey-400 text-sm mb-10'>
          <Trans>{cardDescription}</Trans>
        </p>
        {showSlider && (
          <>
            <p className='font-semibold text-md'>Allowed range variation:</p>
            <ControlledRangeSlider
              controllerProps={{
                name: `${sliderControl}.value`,
                control
              }}
              min={sliderValues.min}
              max={sliderValues.max}
              defaultValue={sliderValues.default}
              isUnlimited={sliderValues.isUnlimited}
              disabled={true}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default SettingCard

