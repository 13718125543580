import { XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import Button from 'components/Inputs/Button'
import { useCallback, useEffect, useState } from 'react'
import { useNewOptContext } from 'screens/NewOptimization/NewOptContext'
import useSWR from 'swr'
import { capitalize, formatter, stripDataTypePrefix } from 'utils/string'
import { timeSince } from 'utils'
import Drawer from 'components/Drawer/Drawer'
import { get } from 'utils/request'
import { GridLoader } from 'react-spinners'
import { useLocalization } from 'providers/LocalizationProvider'

const FileHistoryDrawer = ({ open, setOpen }) => {
  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <FileHistory />
    </Drawer>
  )
}

const FileHistory = () => {
  const [selectedVersionId, setSelectedVersionId] = useState()
  const [selectedFileName, setSelectedFileName] = useState()

  const { t } = useLocalization()

  const { fileType, setShowHistoryDrawer, setFileVersion, fileVersions } =
    useNewOptContext()

  const { data, error, isLoading } = useSWR(
    `/data/query?type=${fileType}&latestOnly=false`,
    async (url) =>
      (await get(url))?.data?.map((item, idx, { length }) => ({
        ...item,
        versionNumber: (length - idx).toString().padStart(3, '0')
      })),
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (data?.length > 0) {
      if (!selectedVersionId || !data.find((x) => x.id === selectedVersionId)) {
        const currentlySetOverride = fileVersions[fileType]
        setSelectedVersionId(currentlySetOverride?.id ?? data[0].id)
        setSelectedFileName(currentlySetOverride?.name ?? data[0].name)
        return
      }
    }
  }, [
    data,
    setSelectedVersionId,
    selectedVersionId,
    setSelectedFileName,
    fileType,
    fileVersions
  ])

  const onSelect = useCallback(
    (id) => {
      setSelectedVersionId(id)
      setSelectedFileName(data?.find((x) => x.id === id)?.name)
    },
    [data]
  )

  const [latestVersion, ...olderVersions] = data || []

  return (
    <div className='flex flex-col w-100 h-full'>
      <div className='flex flex-col pt-14 pl-14 pr-8 pb-8 border-b'>
        <div className='inline-flex flex-col space-y-1 items-start justify-start'>
          <div className='inline-flex items-center justify-start'>
            <p className='w-96 text-xl font-bold leading-7 text-gray-800'>
              {capitalize(
                formatter(stripDataTypePrefix(fileType))?.toLowerCase()
              )}
            </p>
            <div>
              <XMarkIcon
                strokeWidth='2'
                className='w-6 h-6 text-gray-800 cursor-pointer'
                aria-hidden='true'
                onClick={() => setShowHistoryDrawer(false)}
              />
            </div>
          </div>
          <p className='w-full text-md font-medium leading-tight text-gray-500'>
            {t('newOptimizationScreen.sections.files.fileHistory.subtitle')}
          </p>
        </div>
      </div>
      <div className='overflow-y-scroll h-full pb-14 w-[32rem] flex-1'>
        <div className='flex flex-col pl-14 pr-8'>
          <div className='inline-flex flex-col space-y-1 items-start justify-start'>
            <div className='w-full'>
              {isLoading ? (
                <div className='pt-12 w-full flex flex-col items-center justify-center'>
                  <div>
                    {t(
                      'newOptimizationScreen.sections.files.fileHistory.loading'
                    )}
                  </div>
                  <GridLoader
                    className='pt-10'
                    color='#7EA2F4'
                    loading={true}
                    size={15}
                  />
                </div>
              ) : error || !latestVersion ? (
                <div className='pt-6'>
                  <div className='rounded-md px-2 py-1 w-full bg-red-100 text-error-300'>
                    {t(
                      'newOptimizationScreen.sections.files.fileHistory.error'
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <p className='w-72 text-base font-bold leading-normal text-gray-800 mt-6'>
                    {t(
                      'newOptimizationScreen.sections.files.fileHistory.latest'
                    )}
                  </p>
                  <FileHistoryCard
                    fileId={latestVersion.id}
                    version={latestVersion.versionNumber}
                    updatedAt={latestVersion.updatedAt}
                    createdBy={latestVersion.createdBy}
                    description={latestVersion.description}
                    isSelected={latestVersion.id === selectedVersionId}
                    onSelect={onSelect}
                  />

                  <p className='w-72 text-base font-bold leading-normal text-gray-800 pt-10'>
                    {t(
                      'newOptimizationScreen.sections.files.fileHistory.previous'
                    )}
                  </p>
                  {olderVersions?.length > 0
                    ? olderVersions?.map((file) => (
                        <FileHistoryCard
                          key={file.id}
                          fileId={file.id}
                          version={file.versionNumber}
                          updatedAt={file.updatedAt}
                          createdBy={file.createdBy}
                          description={file.description}
                          isSelected={file?.id === selectedVersionId}
                          onSelect={onSelect}
                        />
                      ))
                    : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center justify-end px-14 py-8 border-t'>
        <Button
          variant='filled'
          className='w-48'
          onClick={() => {
            setShowHistoryDrawer(false)
            setFileVersion(fileType, {
              name: selectedFileName,
              id: selectedVersionId
            })
          }}
        >
          {t('newOptimizationScreen.sections.files.fileHistory.confirm')}
        </Button>
      </div>
    </div>
  )
}

const FileHistoryCard = ({
  fileId,
  version,
  updatedAt,
  createdBy,
  description,
  isSelected,
  onSelect
}) => {
  const { t } = useLocalization()

  return (
    <div className='flex flex-col space-y-4 w-full pr-4'>
      <button
        className={classNames(
          'inline-flex items-start justify-start p-4 rounded-lg mt-2 cursor-pointer border-solid border-2',
          {
            'bg-blue-100 border-blue-600 shadow': isSelected,
            'border-grey-200': !isSelected
          }
        )}
        onClick={() => onSelect(fileId)}
      >
        <div>
          <input
            type='radio'
            className='h-4 w-4 mr-2 transition duration-200 ease-in-out mb-auto border-gray-300 text-blue-400 focus:ring-blue-400'
            checked={isSelected}
            readOnly
          />
        </div>
        <div className='flex space-x-3 items-start justify-start'>
          <div className='flex h-5 items-center'></div>

          <div className='inline-flex flex-col items-start justify-start'>
            <p className='w-full text-left text-sm font-bold leading-tight text-gray-800'>
              {t(
                'newOptimizationScreen.sections.files.fileHistory.card.version',
                { version: version ?? fileId }
              )}
            </p>
            <div className='flex flex-col space-y-2 items-start justify-start'>
              <p className='text-xs leading-none text-gray-500 mt-1'>
                {t(
                  'newOptimizationScreen.sections.files.fileHistory.card.updated',
                  { time: timeSince(updatedAt), user: createdBy }
                )}
              </p>
              {description && (
                <p className='text-sm font-medium leading-tight text-gray-700'>
                  <b>
                    {t(
                      'newOptimizationScreen.sections.files.fileHistory.card.details'
                    )}
                  </b>{' '}
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>
      </button>
    </div>
  )
}

export default FileHistoryDrawer
