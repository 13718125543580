import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'

const useProductFilter = ({ data, selectedSubProducts }) => {
  const { selectedProducts } = useOptimizationContext()

  const filteredData = useMemo(() => {
    return selectedProducts.length > 0
      ? data.filter((p) =>
          selectedSubProducts
            ? selectedSubProducts.includes(p.product)
            : selectedProducts.includes(p.product_ui_type)
        )
      : data
  }, [data, selectedSubProducts, selectedProducts])

  return { filteredData }
}

export default useProductFilter
