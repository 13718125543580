import TableChart from 'components/Graphs/TableChart'
import {
  NO_DATA_LABEL,
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import NoData from 'components/Feedback/NoData'
import useGraph from 'hooks/useGraph'
import useProductFilter from 'hooks/useProductFilter'
import useSummaryItems from 'hooks/useSummaryItems'
import { useLocalization } from 'providers/LocalizationProvider'

const calcTopContractsData = ({
  record: { contracted_vol, execution_id },
  currentData,
  baseModelId
}) => {
  return typeof contracted_vol !== 'undefined' &&
    contracted_vol !== null &&
    execution_id === baseModelId
    ? currentData === NO_DATA_LABEL
      ? contracted_vol
      : currentData + contracted_vol
    : currentData
}

const calcAvailableCapacity = ({
  record: { execution_id, contracted_and_spot_vol },
  currentData,
  baseModelId
}) => {
  return typeof contracted_and_spot_vol !== 'undefined' &&
    contracted_and_spot_vol !== null &&
    execution_id === baseModelId
    ? currentData === NO_DATA_LABEL
      ? contracted_and_spot_vol
      : currentData + contracted_and_spot_vol
    : currentData
}

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const AllTransshipments = ({ data }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { filteredData } = useProductFilter({ data })
  const { summaryItems } = useSummaryItems({ data, volumeKey: 'volume' })
  const { volumeGraphData, bars } = useGraph({
    data: filteredData,
    monthContextData: {
      topContracts: calcTopContractsData,
      availableCapacity: calcAvailableCapacity
    },
    calcExecutionIdData
  })
  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: data,
    filterProducts: true
  })

  return !hasNoData(volumeGraphData) ? (
    <TableChart
      title={t('optimizationScreen.transportation.all.title')}
      summaryItems={summaryItems}
      data={volumeGraphData}
      tooltipFormatter={labelFormatter}
      yAxisFormatter={yAxisFormatter}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      bars={bars}
      scatters={[
        {
          dataKey: 'topContracts',
          fill: '#000',
          name: t('optimizationScreen.toPContracts'),
          thickness: 2
        },
        {
          dataKey: 'availableCapacity',
          fill: '#2BAC82',
          name: t('optimizationScreen.transportation.all.availableCapacity')
        }
      ]}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: (val) =>
          val === NO_DATA_LABEL || !val ? NO_DATA_LABEL : valueFormatter(val),
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.transportation')} />
  )
}

export default AllTransshipments
