import Tooltip from 'components/Tooltip'
import { useState } from 'react'

/**
 * @param {string} title    The title of the graphs and table
 * @param {Array} items    The data to be displayed
 * @param {boolean} loading Loads the loading buffer div
 * @returns {JSX.Element}
 * @example
 * <SummaryCard title='Soy' items={[
 * {
 *  title: 'Year total',
 *  value: '7,000,000'
 * }]} />
 */
const SummaryCard = ({ title, items }) => {
  return (
    <div className='border rounded-lg pl-6 p-3 bg-white'>
      <h4 className='tex-xxs font-bold'>{title}</h4>
      <div className='flex flex-row'>
        {items.map((props, idx) => (
          <SummaryCardItem {...props} key={idx} />
        ))}
      </div>
    </div>
  )
}

const SummaryCardItem = ({ description, value, valueTooltip, valueUnit }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div
      className='flex-1 w-36 relative justify-end flex flex-col'
      onMouseLeave={() => setShowTooltip(false)}
    >
      <h5 className='text-grey-400 text-sm'>{description}</h5>
      <p
        className='text-xl font-bold'
        onMouseOver={() => setShowTooltip(!!valueTooltip)}
      >
        {value}
        {valueUnit && (
          <span className='text-sm text-grey-300 ml-2'>{valueUnit}</span>
        )}
      </p>
      <Tooltip
        light
        show={showTooltip}
        className='max-w-2xl w-max z-20 absolute top-12 left-1 p-2'
      >
        {valueTooltip}
      </Tooltip>
    </div>
  )
}

export default SummaryCard
