import { ARGENTINA_DEFAULT } from 'constants'
import { ADVANCED_SETTINGS, BASELINE_RUN, BRAZIL_DEFAULT } from 'constants'

export const TYPE_MAP = {
  [BRAZIL_DEFAULT]: 'BR_Optimized',
  [BASELINE_RUN]: 'BR_Baseline',
  [ADVANCED_SETTINGS]: 'BR_Advanced'
}

export const TYPE_MAP_ARG = {
  [BASELINE_RUN]: 'ARG_Baseline',
  [ADVANCED_SETTINGS]: 'ARG_Advanced',
  [ARGENTINA_DEFAULT]: 'ARG_Optimized'
}

export const getNewOptimizationName = (
  type,
  startMonth,
  startYear,
  optData,
  countryCode
) => {
  const startDate = `${startMonth.toLocaleString('en-US', {
    minimumIntegerDigits: 2
  })}_${startYear}`
  const number = (
    optData.filter((data) => data.name.includes(startDate)).length + 1
  ).toLocaleString('en-US', { minimumIntegerDigits: 3 })

  const COUNTRY_TYPE_MAPS = {
    BR: TYPE_MAP,
    ARG: TYPE_MAP_ARG
  }

  return `${
    COUNTRY_TYPE_MAPS[countryCode][type] || 'optimization'
  }_${startDate}_${number}`
}
