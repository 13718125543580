import { TruckIcon } from '@heroicons/react/24/outline'
import BreadCrumbComponent from 'components/Navigation/Breadcrumb'
import ButtonNavigation from 'components/Navigation/ButtonNavigation'
import CurrencySelector from 'components/Inputs/CurrencySelector'
import { CrushingPlantIcon, ShipIcon, SiloIcon } from 'components/Icons'
import { SwitcherButtons } from 'components/Inputs/Switchers'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { useState } from 'react'
import DesignSystemSegment from './DesignSystemSegment'
import Loader from 'components/Feedback/Loader/Loader'
import ProgressBar from 'components/Feedback/Loader/ProgressBar'

const buttons = [
  {
    title: 'Ports',
    icon: ShipIcon
  },
  {
    title: 'Silos',
    icon: SiloIcon
  },
  {
    title: 'Transshipments',
    icon: TruckIcon
  },
  {
    title: 'Crushers',
    icon: CrushingPlantIcon
  }
]

function Navigation() {
  const [selectedNavButton, setSelectedNavButton] = useState(buttons[0].title)
  const [currency, setCurrency] = useState('')

  return (
    <DesignSystemSegment title='Navigation'>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-center'>
          <ButtonNavigation
            buttons={buttons}
            selected={selectedNavButton}
            setSelected={setSelectedNavButton}
          />
        </div>
        <div className='flex justify-center'>
          <SwitcherButtons
            items={[
              {
                label: 'Foo',
                value: 'foo'
              },
              {
                label: 'Bar',
                value: 'bar'
              }
            ]}
            onChange={(newValue) => console.log(newValue)}
          />
        </div>
        <div className='flex justify-center'>
          <div className='mx-auto flex items-center'>
            <CurrencySelector onChange={setCurrency} />
            <p className='ml-2'>{currency}</p>
          </div>
        </div>
        <div className='flex justify-center'>
          <BreadCrumbComponent
            crumbs={[
              { label: 'Root', onClick: () => {} },
              { label: 'Crumb 1', onClick: () => {} },
              { label: 'Crumb 2', onClick: () => {} }
            ]}
          />
        </div>
        <div className='flex justify-center'>
          <TabsComponent defaultValue='tab2'>
            <Tab value='tab1' label='Tab 1 '>
              <div>Tab 1 content</div>
            </Tab>
            <Tab value='tab2' label='Tab 2'>
              <div>Tab 2 content</div>
            </Tab>
            <Tab value='tab3' label='Disabled tab' disabled>
              <div>Tab 3 content</div>
            </Tab>
          </TabsComponent>
        </div>
        <div className='flex flex-col items-center gap-2'>
          <h2 className='font-bold text-lg'>Loader:</h2>
          <Loader variant='small' />
          <h2 className='font-bold text-lg'>Progress bar:</h2>
          <div className='w-1/2'>
            <ProgressBar loaded={50} total={100} />
            <ProgressBar loaded={50} total={100} variant='plain'>
              Custom label text
            </ProgressBar>
            <ProgressBar loaded={7545248} total={54787851} variant='transfer' />
          </div>
        </div>
      </div>
    </DesignSystemSegment>
  )
}

export default Navigation
