import { CardBase } from './CardBase'
import { TitleAndDescription } from './TitleAndDescription'
import { UploadDownloadSection } from './UploadDownloadSection'

export const WhatIfCard = ({
  title,
  description,
  onDownload,
  onUpload,
  disabled,
  progress
}) => (
  <CardBase>
    <TitleAndDescription title={title} description={description} />
    <UploadDownloadSection
      onUpload={onUpload}
      onDownload={onDownload}
      disabled={disabled}
      progress={progress}
    />
  </CardBase>
)
