import { createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useSWR from 'swr'
import { get } from 'utils/request'

const OptimizationDataContext = createContext()
OptimizationDataContext.displayName = 'OptimizationDataContext'
export const useOptDataContext = () => useContext(OptimizationDataContext)

export const OptDataProvider = ({ children }) => {
  const location = useLocation()

  const {
    data: { optData, executionIdLookup },
    mutate
  } = useSWR('/execution/summary', async (url) => {
    const data = await get(url)
    const executionIdLookup = data.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    )
    data.forEach((opt) => {
      if (!opt.parent_execution_id) {
        return
      }

      let rootExecution = executionIdLookup[opt.parent_execution_id]
      while (rootExecution?.parent_execution_id) {
        rootExecution = executionIdLookup[rootExecution.parent_execution_id]
      }

      if (!rootExecution) {
        console.error(
          'Unable to find root execution',
          opt?.execution_id,
          opt?.parent_execution_id,
          executionIdLookup
        )
        return
      }

      if (!rootExecution.scenarios) {
        rootExecution.scenarios = [rootExecution]
      }

      rootExecution.scenarios.push(opt)
    })

    const optData = data
      .map((opt) => ({
        ...opt,
        checked: false
      }))
      .filter((opt) => !opt.parent_execution_id)
    return { optData, executionIdLookup }
  })

  useEffect(() => {
    mutate()
  }, [location, mutate])

  return (
    <OptimizationDataContext.Provider value={{ optData, executionIdLookup }}>
      {children}
    </OptimizationDataContext.Provider>
  )
}
