import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import Button from 'components/Inputs/Button'
import { ModalComponent } from 'components/Modal/ModalComponent'
import { useLocalization } from 'providers/LocalizationProvider'

const ConfirmModal = ({
  open,
  setOpen,
  onAccept,
  onCancel,
  title,
  children
}) => {
  const { t } = useLocalization()

  return (
    <ModalComponent open={open} onOpenChange={setOpen}>
      <div className='m-4 mt-0 w-[600px]'>
        <div className='flex items-start justify-between p-1 mb-3 rounded-lg w-full text-600'>
          <div className='flex items-start'>
            <div>
              <div
                className={`flex w-12 h-12 p-2 rounded-full text-error-400 m-4 bg-red-100`}
              >
                <ExclamationCircleIcon aria-hidden='true' />
              </div>
            </div>
            <div className='mr-4 mt-4'>
              <h2 className={`font-bold mb-4`}>{title}</h2>
              <p className='text-m text-grey-400'>{children}</p>
            </div>
          </div>
        </div>

        <div className='flex justify-end mt-4 space-x-2'>
          <Button
            variant='light'
            className='w-40'
            onClick={() => {
              setOpen(false)
              onCancel?.()
            }}
          >
            {t('general.cancel')}
          </Button>
          <Button
            variant='filled'
            className='w-40'
            onClick={() => {
              setOpen(false)
              onAccept?.()
            }}
          >
            {t('general.confirm')}
          </Button>
        </div>
      </div>
    </ModalComponent>
  )
}

export default ConfirmModal
