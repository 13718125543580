import { NO_DATA_LABEL } from 'screens/Optimization/utils'

export const calcPercentageDiff = (bmVolume, beVolume) => {
  const difference = Math.round(bmVolume - beVolume)
  if (!bmVolume && Math.abs(difference) > 0) {
    const sign = difference < 0 ? -1 : 1
    return sign * 100
  }
  return (difference / bmVolume) * 100
}

export const calculateComparisonDiff = (bmVolume, beVolume) => {
  const percentageDifference = calcPercentageDiff(bmVolume, beVolume)
  if (percentageDifference < 0.0) {
    return `${percentageDifference.toFixed(1)}% than CF`
  } else if (percentageDifference > 0.0) {
    return `+ ${percentageDifference.toFixed(1)}% than CF`
  } else {
    return 'Same as CF'
  }
}

export const groupByArray = (xs, key) => {
  return xs.reduce(function (rv, x) {
    const v = key instanceof Function ? key(x) : x[key]
    const el = rv.find((r) => r && r.key === v)
    if (el) {
      el.values.push(x)
    } else {
      rv.push({ key: v, values: [x] })
    }
    return rv
  }, [])
}

export const calculateTotalVolumes = (volumes) => {
  return volumes.reduce((acc, { volume }) => {
    let temp = { ...acc }
    const v = Object.entries(volume)
    for (const [id, volume] of v) {
      const current = temp[id] || NO_DATA_LABEL
      if (current === NO_DATA_LABEL) {
        temp = { ...temp, [id]: volume }
      } else {
        temp = {
          ...temp,
          [id]: volume === NO_DATA_LABEL ? current : current + volume
        }
      }
    }
    return temp
  }, {})
}

export const transformGroupedData = (grouped = [], keys = []) => {
  return keys.map(
    (key) =>
      grouped.find((item) => item.key === key) || {
        key,
        values: []
      }
  )
}
