import { capitalize } from 'lodash'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { formatNumber } from 'utils/number'
import { useLocalization } from 'providers/LocalizationProvider'

const useSummaryItems = ({ data, volumeKey, capacityKey, isUtilization }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { t } = useLocalization()

  const allSilosSummaryByProduct = useMemo(() => {
    return data.reduce(
      (acc, { product_ui_type, execution_id, ...item }) => ({
        ...acc,
        [product_ui_type]: {
          ...(acc[product_ui_type] || {}),
          volume: {
            ...(execution_id === baseModelId
              ? {
                  [baseModelId]:
                    (acc[product_ui_type]?.volume[baseModelId] || 0) +
                    item[volumeKey]
                }
              : { [baseModelId]: acc[product_ui_type]?.volume[baseModelId] }),
            ...(execution_id === comparisonID
              ? {
                  [comparisonID]:
                    (acc[product_ui_type]?.volume[comparisonID] || 0) +
                    item[volumeKey]
                }
              : { [comparisonID]: acc[product_ui_type]?.volume[comparisonID] })
          },
          ...(capacityKey
            ? {
                capacity: {
                  ...(execution_id === baseModelId
                    ? {
                        [baseModelId]:
                          (acc[product_ui_type]?.capacity[baseModelId] || 0) +
                          item[capacityKey]
                      }
                    : {
                        [baseModelId]:
                          acc[product_ui_type]?.capacity[baseModelId] || 0
                      }),
                  ...(execution_id === comparisonID
                    ? {
                        [comparisonID]:
                          (acc[product_ui_type]?.capacity[comparisonID] || 0) +
                          item[capacityKey]
                      }
                    : {
                        [comparisonID]:
                          acc[product_ui_type]?.capacity[comparisonID]
                      })
                }
              }
            : {})
        }
      }),
      {}
    )
  }, [data, baseModelId, comparisonID, volumeKey, capacityKey])

  const summaryItems = useMemo(
    () =>
      Object.entries(allSilosSummaryByProduct)
        .map(([product, { volume, capacity }]) => ({
          title: capitalize(product === 'undefined' ? 'Overall' : product),
          items: [
            {
              description: t('optimizationScreen.periodTotal'),
              value: isUtilization
                ? `${formatNumber(
                    (volume[baseModelId] / capacity[baseModelId]) * 100,
                    {
                      maximumFractionDigits: 0
                    }
                  )}%`
                : formatNumber(volume[baseModelId], {
                    maximumFractionDigits: 0
                  })
            },
            {
              description: t('optimizationScreen.comparisonPeriodTotal'),
              value: isUtilization
                ? `${formatNumber(
                    (volume[comparisonID] / capacity[comparisonID]) * 100,
                    {
                      maximumFractionDigits: 0
                    }
                  )}%`
                : formatNumber(volume[comparisonID], {
                    maximumFractionDigits: 0
                  })
            }
          ]
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [allSilosSummaryByProduct, baseModelId, comparisonID, isUtilization, t]
  )

  return {
    summaryItems
  }
}

export default useSummaryItems
