import { useState, useCallback, useMemo } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  useZoomPan
} from 'react-simple-maps'
import SvgMapTooltip from './SvgMapTooltip'
import LocationMarker from './LocationMarker'
import { useLocalization } from 'providers/LocalizationProvider'

const brazilianStates = require('./bra_states.json')
const argentineanStates = require('./arg_states.json')
// other sources:
// 'https://raw.githubusercontent.com/fititnt/gis-dataset-brasil/master/mesorregiao/geojson/mesorregiao.json'
/// https://github.com/luizpedone/municipal-brazilian-geodata/blob/master/minified/Brasil.min.json

const mapChartInitialValuesBra = {
  markers: [],
  projectionConfig: {
    rotate: [58, 14, 0],
    scale: 850
  }
}

const mapChartInitialValuesArg = {
  markers: [],
  projectionConfig: {
    rotate: [65, 32, 0],
    scale: 1500
  }
}

const CustomZoomableGroup = ({ children, ...restProps }) => {
  const { mapRef, transformString, position } = useZoomPan(restProps)
  const { disableZoom } = restProps

  return (
    <g ref={mapRef}>
      <rect fill='transparent' />
      <g transform={disableZoom ? '' : transformString}>{children(position)}</g>
    </g>
  )
}

const getTooltipCoordinates = (e) => {
  const clientRect = e.target.getBoundingClientRect()
  return {
    x: clientRect.x + clientRect.width + 5,
    y: clientRect.y + clientRect.height
  }
}

const MapChart = ({ markers, projectionConfig, fillData, disableZoom }) => {
  const { countryCode } = useLocalization()
  const mapChartInitialValues = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return mapChartInitialValuesArg
      default:
        return mapChartInitialValuesBra
    }
  }, [countryCode])

  const geoUrl = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return argentineanStates
      default:
        return brazilianStates
    }
  }, [countryCode])

  const parsedMarkers = markers || mapChartInitialValues.markers
  const parsedProjectionConfig =
    projectionConfig || mapChartInitialValues.projectionConfig
  const [toolTipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 })
  const [toolTipData, setTooltipData] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)
  const [selectedGeography, setSelectedGeography] = useState(undefined)
  const [hoveredGeography, setHoveredGeography] = useState(undefined)

  const onMouseEnterHandler = useCallback((e, item) => {
    setTooltipCoordinates(getTooltipCoordinates(e))
    setShowTooltip(true)
    setTooltipData(item)
  }, [])

  const handleGeographyHover = (e, geo) => {
    const stateKey = countryCode === 'ARG' ? 'submesoregion' : 'state'
    if (fillData && geo) {
      setShowTooltip(true)
      setTooltipData({
        [stateKey]: geo.properties.UF,
        variation: (fillData[geo.properties.UF] - 100).toFixed() + '%'
      })
      setTooltipCoordinates(getTooltipCoordinates(e))
    } else {
      setShowTooltip(false)
    }

    setHoveredGeography(geo?.rsmKey)
  }

  const getFillColor = (state) => {
    if (!fillData) return '#E4E8ED'
    if (!fillData[state]) return '#E4E8ED'
    if (fillData[state] >= 110) return '#C60710'
    if (fillData[state] >= 105) return '#F17278'
    if (fillData[state] < 105 && fillData[state] > 95) return '#E4E8ED'
    if (fillData[state] <= 95 && fillData[state] >= 90) return '#7EA2F4'
    return '#113587'
  }

  return (
    <>
      <ComposableMap
        projection='geoAzimuthalEqualArea'
        projectionConfig={parsedProjectionConfig}
        className='overflow-visible'
      >
        <CustomZoomableGroup center={[0, 0]} zoom={1} disableZoom={disableZoom}>
          {(position) => (
            <>
              <Geographies geography={geoUrl}>
                {({ geographies }) => {
                  geographies.push(
                    geographies.splice(
                      geographies.findIndex(
                        (geo) => geo.rsmKey === hoveredGeography
                      ),
                      1
                    )[0]
                  )
                  geographies.push(
                    geographies.splice(
                      geographies.findIndex(
                        (geo) => geo.rsmKey === selectedGeography
                      ),
                      1
                    )[0]
                  )

                  return geographies.map((geo, index) => {
                    return (
                      <Geography
                        key={`${geo.rsmKey}-${index}`}
                        geography={geo}
                        fill={getFillColor(geo.properties.UF)}
                        stroke='#AAB1BA'
                        onClick={() => setSelectedGeography(geo.rsmKey)}
                        onMouseEnter={(e) => handleGeographyHover(e, geo)}
                        onMouseLeave={() => handleGeographyHover(undefined)}
                        style={{
                          default: {
                            strokeWidth: 1,
                            outline: 'none',
                            ...(selectedGeography === geo.rsmKey
                              ? { fill: '#DCE7FD' }
                              : {}),
                            stroke:
                              selectedGeography === geo.rsmKey
                                ? '#3668D5'
                                : '#AAB1BA'
                          },
                          hover: {
                            stroke: '#3668D5',
                            ...(selectedGeography === geo.rsmKey
                              ? { fill: '#DCE7FD' }
                              : {}),
                            outline: 'none'
                          }
                        }}
                      />
                    )
                  })
                }}
              </Geographies>
              {parsedMarkers.map((item, index) => (
                <Marker
                  className='cursor-pointer'
                  key={`${item.title}-${index}`}
                  coordinates={item.coordinates}
                  onMouseEnter={(e) => onMouseEnterHandler(e, item)}
                  onMouseLeave={() => {
                    setShowTooltip(false)
                  }}
                >
                  <LocationMarker
                    stroke={item.stroke}
                    fill={item.fill}
                    scale={position.k}
                    coordinates={item.coordinates}
                  />
                </Marker>
              ))}
            </>
          )}
        </CustomZoomableGroup>
      </ComposableMap>
      <SvgMapTooltip
        data={toolTipData}
        coordinates={toolTipCoordinates}
        show={showTooltip}
      />
    </>
  )
}

export default MapChart
