import { MinMaxChart } from '../../../../../components/Graphs/MinMaxChart'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'
import SectionBox from 'components/Container/SectionBox'
import { LineBarChart } from '../../../../../components/Graphs/LineBarChart'
import { LineAreaChart } from '../../../../../components/Graphs/AreaChart'
import { HeatMapColoredTable } from '../../../../../components/Tables/HeatMapColoredTable'
import { OwnershipTable } from '../../../../../components/Tables/OwnershipTable'
import { SimpleBarChart } from '../../../../../components/Graphs/SimpleBarChart'
import SummaryCards from '../../SummaryCards'
import { useOwnershipData } from './useOwnershipData'

export const Ownership = () => {
  const { t } = useLocalization()
  const {
    hasSelectedsProduct,
    summaryCardsData,
    merchandiseData,
    curvePurchasedChartData,
    minMaxOwnership,
    crushingPlainData,
    exportationPlainData,
    heatMapProjectedOwnershipData
  } = useOwnershipData()

  const RenderSummaryCards = () => (
    <SummaryCards
      data={summaryCardsData.map((card) => ({
        ...card,
        description: t('optimizationScreen.ownership.summaryCardDescription')
      }))}
    />
  )

  return (
    <OutputScreen
      title={t('optimizationScreen.pageNames.ownership')}
      information
    >
      <SectionBox>
        <p className='font-bold text-lg mb-5'>
          {t('optimizationScreen.ownership.merchandise.title')}
        </p>
        <RenderSummaryCards />
        <LineBarChart
          data={merchandiseData.chartData}
          xAxisDataKey={merchandiseData.xAxisDataKey}
          lineDataKey={merchandiseData.lineDataKey}
          barDataKey={merchandiseData.barDataKey}
          legendGroup={merchandiseData.legendGroup}
        />
      </SectionBox>

      {hasSelectedsProduct && (
        <SectionBox>
          <p className='font-bold text-lg mb-1'>
            {t('optimizationScreen.ownership.curvePurchased.title')}
          </p>
          <p className='text-gray-400 mb-2'>
            {t('optimizationScreen.ownership.curvePurchased.description')}
          </p>
          <LineAreaChart
            data={curvePurchasedChartData.chartData}
            xAxisDataKey={curvePurchasedChartData.xAxisDataKey}
            lineDataKey={curvePurchasedChartData.lineDataKey}
          />
          <p className='text-gray-400 mb-6 mt-12'>
            {t('optimizationScreen.ownership.heatmap.title')}
          </p>
          <div className='w-full'>
            <HeatMapColoredTable
              data={heatMapProjectedOwnershipData.tableDataValues}
              valueDataKey={heatMapProjectedOwnershipData.tableHeaderMonths}
              maxValue={heatMapProjectedOwnershipData.maxValue}
              minValue={heatMapProjectedOwnershipData.minValue}
              headers={heatMapProjectedOwnershipData.tableHeaderMonths}
              sideHeaders={[
                t('optimizationScreen.ownership.heatmap.table.kton')
              ]}
              unit={t('optimizationScreen.ownership.heatmap.table.kton')}
            />
          </div>
        </SectionBox>
      )}

      {hasSelectedsProduct && (
        <SectionBox>
          <p className='font-bold text-lg mb-1'>
            {t('optimizationScreen.ownership.minMax.title')}
          </p>
          <p className='text-gray-400 mb-2'>
            {t('optimizationScreen.ownership.minMax.description')}
          </p>
          <MinMaxChart
            containerClass='mb-12'
            data={minMaxOwnership.chartData}
            XAxisDataKey={minMaxOwnership.xAxisDataKey}
            minMaxLegendGroup={minMaxOwnership.minMaxLegendGroup}
            minMaxGroup={minMaxOwnership.minMaxGroup}
            barLegendGroup={minMaxOwnership.barLegendGroup}
            barGroup={minMaxOwnership.barGroup}
            minMaxToogleText={t('optimizationScreen.ownership.minMax.switcher')}
          />
          <div className='mb-16'>
            <OwnershipTable
              data={minMaxOwnership.tableDataValues}
              headers={minMaxOwnership.tableHeaderMonths}
              sideHeaders={minMaxOwnership.tableSideHeaders}
              hasTotal
              totalDataKey={'total'}
              unit={'kTon'}
            />
          </div>

          <p className='text-gray-400'>
            {t('optimizationScreen.ownership.crushingPlan.title')}
          </p>
          <SimpleBarChart
            containerClass='mb-16'
            data={crushingPlainData.chartData}
            xAxisDataKey={crushingPlainData.xAxisDataKey}
            barGroup={crushingPlainData.barGroup}
            legendGroup={crushingPlainData.legendGroup}
          />
          <div className='mb-12'>
            <OwnershipTable
              hasTotal
              totalDataKey={'total'}
              data={crushingPlainData.tableDataValues}
              headers={crushingPlainData.tableHeaderMonths}
              sideHeaders={crushingPlainData.tableSideHeaders}
              unit={'kTon'}
            />
          </div>

          <p className='text-gray-400'>
            {t('optimizationScreen.ownership.exportationPlan.title')}
          </p>
          <SimpleBarChart
            containerClass='mb-16'
            data={exportationPlainData.chartData}
            xAxisDataKey={exportationPlainData.xAxisDataKey}
            barGroup={exportationPlainData.barGroup}
            legendGroup={exportationPlainData.legendGroup}
          />
          <div className='mb-12'>
            <OwnershipTable
              data={exportationPlainData.tableDataValues}
              headers={exportationPlainData.tableHeaderMonths}
              sideHeaders={exportationPlainData.tableSideHeaders}
              hasTotal
              totalDataKey={'total'}
              unit={'kTon'}
            />
          </div>
        </SectionBox>
      )}
    </OutputScreen>
  )
}
