import React from 'react'
import InAndOutboundChart from './InAndOutboundChart'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import { transformDateToShortMonth } from 'screens/Optimization/utils'
import { valueFormatter } from 'screens/Optimization/utils/output'
import useHeatmap from './useHeatmap'
import { useLocalization } from 'providers/LocalizationProvider'
import { useOptimizationContext } from 'providers/OptimizationProvider'

const Outbound = ({ outboundData, destinationData }) => {
  const { t } = useLocalization()
  const { comparisonID } = useOptimizationContext()
  const { heatmapData } = useHeatmap({
    data: destinationData,
    mainRowKey: 'destination_type',
    valueKey: 'outbound_volume'
  })

  return (
    <>
      <h3 className='text-lg font-bold mb-8'>
        {t('optimizationScreen.storage.outbound.title')}
      </h3>
      {outboundData.map(({ transportation_mode, data: volumes }) => (
        <React.Fragment key={transportation_mode}>
          <InAndOutboundChart
            transportationMode={transportation_mode}
            volumes={volumes}
          />
        </React.Fragment>
      ))}
      <div>
        <h3 className='text-base font-bold mb-6'>
          {t('optimizationScreen.storage.outbound.destinations')}
        </h3>
        <MultiColumnsBoxTable
          showTitles
          expandable
          data={heatmapData}
          referenceProps={{ title: t('optimizationScreen.comparison') }}
          comparedProps={{ title: t('optimizationScreen.baseModel') }}
          groupLabelFormatter={transformDateToShortMonth}
          rowTitleFormatter={(title) => title.toUpperCase()}
          columnValueFormatter={valueFormatter}
          referencePropKey={comparisonID}
        />
      </div>
    </>
  )
}

export default Outbound
