import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { NO_DATA_LABEL } from 'screens/Optimization/utils/output'
import { createMonthObject } from 'screens/Optimization/utils'

const useCrushingTable = (data) => {
  const { id: baseModelId } = useParams()

  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const createEmptyHeatMapStructure = useCallback(() => {
    const months = createMonthObject(start_date, end_date, {
      [baseModelId]: NO_DATA_LABEL,
      [comparisonID]: NO_DATA_LABEL
    })

    if (selectedProducts.length > 0) {
      return selectedProducts.reduce(
        (acc, product) => ({
          ...acc,
          [product]: {
            title: product,
            data: months
          }
        }),
        {}
      )
    }

    return months
  }, [baseModelId, comparisonID, selectedProducts, end_date, start_date])

  const tableData = useMemo(() => {
    if (!selectedProducts.length) {
      return [
        {
          data: data.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.month]: curr.utilization
            }
          }, createEmptyHeatMapStructure())
        }
      ]
    }

    return selectedProducts.reduce((acc, product) => {
      return [
        ...acc,
        {
          title: product,
          data: data.reduce((acc, d) => {
            return {
              ...acc,
              [d.month]: {
                [baseModelId]: d.utilization[baseModelId][product],
                [comparisonID]: d.utilization[comparisonID][product]
              }
            }
          }, {})
        }
      ]
    }, [])
  }, [
    data,
    createEmptyHeatMapStructure,
    selectedProducts,
    baseModelId,
    comparisonID
  ])

  return {
    tableData
  }
}

export default useCrushingTable
