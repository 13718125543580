import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'
import { useTemplate } from 'screens/Scenarios/useTemplate'

export const Silos = () => {
  const getTemplate = useTemplate()
  const { countryCode, t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.silos.assets.'

  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}${countryCode}.title`)}
      title={t(`${defaultContentPath}${countryCode}.title`)}
      description={t(`${defaultContentPath}${countryCode}.description`)}
      templateType={getTemplate()}
      modalTitle={t(`${defaultContentPath}${countryCode}.modalTitle`)}
    />
  )
}
