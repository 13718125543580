import useSWRMutation from 'swr/mutation'
import { post } from 'utils/request'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'providers/ToastProvider'
import { useLocalization } from 'providers/LocalizationProvider'

export const useCreateExecution = () => {
  const navigate = useNavigate()
  const { showToast } = useToast()
  const { t } = useLocalization()

  const { trigger: createExecution, isMutating: isExecuting } = useSWRMutation(
    `/execution`,
    (url, { arg }) => post(url, arg),
    {
      onSuccess: ({ data }) => {
        if (data.id) {
          navigate('/execution/' + data.id)
        }
      },
      onError: (error) => {
        // If the page is opened multiple times with different country codes things can be messed up
        if (error?.response?.data?.message === 'countryCodeMissmatch') {
          showToast({
            message: t('statusMessages.genericErrorRefresh'),
            status: 'error',
            placement: 'top'
          })
        } else {
          showToast({
            message: t('statusMessages.genericError'),
            status: 'error',
            placement: 'top'
          })
        }
        console.error(error)
      }
    }
  )

  return { createExecution, isExecuting }
}
