import { DocumentIcon, EyeIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import GridTable, { dateMapperFactory } from './GridTable'
import Button from 'components/Inputs/Button'
import { useNewOptContext } from 'screens/NewOptimization/NewOptContext'
import { useLocalization } from 'providers/LocalizationProvider'
import { formatter, stripDataTypePrefix } from 'utils/string'
import SorterColumnTitle from './GridTable/sorting/SorterColumnTitle'

const TABLE_HEADERS = [
  { title: null },
  { title: 'file', sortKey: 'type' },
  { title: 'lastUpdate', sortKey: 'updatedAtTs' },
  { title: 'actions' }
]

/**
 * @param {({ type: string; id: string; file: string; last_update: Date })[]} files
 * List of files to display
 *
 * @returns {JSX.Element}
 */
export const UpdateListTable = ({ files, sorter }) => {
  const {
    setSelectedFile,
    setShowUpdateModal,
    setShowHistoryDrawer,
    setFileType
  } = useNewOptContext()

  const { t } = useLocalization()

  const UpdateButton = ({ file, onUpdate }) => {
    const handlerUpdate = (event) => {
      event.preventDefault()
      setSelectedFile(file)
      setFileType(file.type)
      onUpdate(event.target.name)
    }

    return (
      <Button
        variant='default'
        onClick={handlerUpdate}
        name='update'
        className='ml-2'
      >
        {t('general.update')}
      </Button>
    )
  }

  const PreviewButton = ({ file }) => {
    const handlerPreview = (event) => {
      event.preventDefault()
      setSelectedFile(file)
      setFileType(file.type)
      setShowHistoryDrawer(true)
    }

    return (
      <Button variant='blank' onClick={handlerPreview} name='preview' icon>
        <EyeIcon />
      </Button>
    )
  }

  const mappers = useMemo(
    () => [
      () => <DocumentIcon className='h-6 text-gray-600' aria-hidden='true' />,
      ({ item }) => (
        <div>
          {/* <span className='trucate w-48'>{item.file}</span> */}
          <span className='trucate w-48'>
            {formatter(stripDataTypePrefix(item.type))}
          </span>
          <StatusBadge item={item} />
        </div>
      ),
      dateMapperFactory('last_update'),
      ({ item }) => (
        <div className='flex flex-row gap-2 pr-4'>
          <PreviewButton file={item} />
          <UpdateButton
            file={item}
            onUpdate={() => {
              setSelectedFile(item)
              setShowUpdateModal(true)
            }}
          />
        </div>
      )
    ],
    [setSelectedFile, setShowUpdateModal]
  )

  const tableHeaders = useMemo(() => {
    return TABLE_HEADERS.map(({ title, sortKey }) => {
      if (!title) return title
      const translatedTitle = t(
        `newOptimizationScreen.sections.files.tableHeaders.${title}`
      )
      if (!sortKey || !sorter) {
        return translatedTitle
      }

      return (
        <SorterColumnTitle
          key={`${title}-${sortKey}`}
          title={translatedTitle}
          sortKey={sortKey}
        />
      )
    })
  }, [sorter, t])

  return (
    <GridTable
      data={files}
      columnMappers={mappers}
      columnHeaders={tableHeaders}
      keyMapper={(item) => item.id}
      borders={true}
      roundCorners={'all'}
      stickyHeaders={true}
      cellClassNames={
        'flex pl-4 items-center bg-white py-4 border-grey-200 text-grey-600'
      }
      headerClassNames={
        'text-grey-700 py-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-grey-100'
      }
      columnSizes={'3rem auto auto'}
    />
  )
}

const StatusBadge = ({ item }) => {
  const { t } = useLocalization()
  switch (item.status) {
    case 'warning':
      return (
        <span className='text-yellow-700 bg-warning-100 rounded-sm ml-2 py-1 px-2'>
          {t('newOptimizationScreen.sections.files.statuses.notUpdated')}
        </span>
      )
    case 'success':
      return (
        <span className='text-green-700 bg-success-100 rounded-sm ml-2 py-1 px-2'>
          {t('newOptimizationScreen.sections.files.statuses.updated')}
        </span>
      )
    case 'missing':
      return (
        <span className='text-red-700 bg-error-100 rounded-sm ml-2 py-1 px-2'>
          {t('newOptimizationScreen.sections.files.statuses.missing')}
        </span>
      )
    default:
      return null
  }
}

export default UpdateListTable
