import Select from 'components/Inputs/Select'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { capitalize } from 'lodash'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { labelFormatter } from 'screens/Optimization/utils/output'
import BarChart from 'components/Graphs/BarChart'
import { kgToTons } from 'screens/Optimization/utils'
import useInternalMarketData from './useInternalMarketData'
import MultiGraphsTable from 'components/Tables/MultiGrapshTable.jsx'
import { formatNumber } from 'utils/number'
import BreadCrumbComponent from 'components/Navigation/Breadcrumb'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

const yAxisFormatter = (labelValue) => `${labelValue / kgToTons}k`

const ProductDetails = ({
  selectedProduct,
  setSelectedProduct,
  products,
  data
}) => {
  const { id } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { getGraphData, getCrushData } = useInternalMarketData(data)
  const { t } = useLocalization()

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col justify-start w-full space-y-4'>
        <BreadCrumbComponent
          crumbs={[
            {
              label: 'Internal Market',
              onClick: () => setSelectedProduct(undefined)
            },
            { label: 'Product Details' }
          ]}
        />
        <div className='flex pt-6 items-center'>
          <h2 className='text-xl mr-1'>
            {t('optimizationScreen.internalMarket.details')}
          </h2>
          <Select
            options={products.map((item) => ({
              value: item,
              label: capitalize(item)
            }))}
            variant='underlined'
            value={selectedProduct}
            onValueChange={setSelectedProduct}
          />
        </div>
      </div>
      <div className='bg-white border rounded-lg p-9 w-full mt-6'>
        <h2 className='text-xl mb-7 font-semibold'>
          {t('optimizationScreen.internalMarket.demand')}
        </h2>
        {getGraphData(selectedProduct) !== undefined ? (
          <div className='h-48 cursor-pointer'>
            <BarChart
              variant='small'
              data={getGraphData(selectedProduct)}
              bars={[
                {
                  dataKey: `volumes.${id}`,
                  fill: '#1950CA',
                  name: t('optimizationScreen.baseModel')
                },
                {
                  dataKey: `volumes.${comparisonID}`,
                  fill: '#C1D5FF',
                  name: t('optimizationScreen.comparison')
                }
              ]}
              barProps={{
                barSize: 8
              }}
              yAxisProps={{
                dataKey: `volumes.${id}`,
                width: 15,
                tickCount: 3,
                tickFormatter: (val) => val / kgToTons
              }}
              cartesianGridProps={{
                horizontal: true,
                vertical: false
              }}
              xAxisProps={{
                dataKey: 'month',
                strokeWidth: 0,
                tickFormatter: (val) => transformDateToShortMonth(val)[0]
              }}
              tooltipFormatter={labelFormatter}
              showLegend
            />
          </div>
        ) : (
          <NoData page={t('optimizationScreen.pageNames.internalMarket')} />
        )}
      </div>
      <div className='bg-white border rounded-lg p-9 w-full mt-6'>
        <h2 className='text-xl mb-7 font-semibold'>
          {t('optimizationScreen.internalMarket.byCrush')}
        </h2>
        {Object.entries(getCrushData(selectedProduct)).map(
          ([crush, crushData]) => (
            <MultiGraphsTable
              data={crushData}
              key={crush}
              title={capitalize(crush) || ''}
              total={formatNumber(
                crushData.reduce((acc, curr) => {
                  return curr.volumes[id] === NO_DATA_LABEL
                    ? acc
                    : acc + curr.volumes[id]
                }, 0),
                {
                  maximumFractionDigits: 0
                }
              )}
              bars={[
                {
                  dataKey: `volumes.${id}`,
                  fill: '#1950CA',
                  name: t('optimizationScreen.baseModel')
                },
                {
                  dataKey: `volumes.${comparisonID}`,
                  fill: '#C1D5FF',
                  name: t('optimizationScreen.comparison')
                }
              ]}
              tooltipFormatter={labelFormatter}
              yAxisFormatter={yAxisFormatter}
              xAxisProps={{
                dataKey: 'month',
                tickFormatter: transformDateToShortMonth
              }}
            />
          )
        )}
      </div>
    </div>
  )
}

export default ProductDetails
