import Overview from './sections/Overview'
import Origination from './sections/Origination/Origination'
import { useScenariosContext } from 'providers/ScenariosProvider'
import { useMemo } from 'react'
import { InboundOutbound } from 'screens/Scenarios/Main/sections/Silos/InboundOutbound'
import { Silos } from 'screens/Scenarios/Main/sections/Silos/Silos'
import { Products } from 'screens/Scenarios/Main/sections/Silos/Products'
import { SiloReceptionPlan } from 'screens/Scenarios/Main/sections/Silos/SiloReceptionPlan'
import { TransshipmentInboundOutbound } from 'screens/Scenarios/Main/sections/Transportation/TransshipmentInboundOutbound'
import { Contracts } from 'screens/Scenarios/Main/sections/Transportation/Contracts'
import { CrushingInboundOutbound } from 'screens/Scenarios/Main/sections/Crushing/InboundOutbound'
import { CrushingMaintenance } from 'screens/Scenarios/Main/sections/Crushing/Maintenance'
import { SoybeanMealDemand } from 'screens/Scenarios/Main/sections/Crushing/SoybeanMealDemand'
import { ExportsInboundOutbound } from 'screens/Scenarios/Main/sections/Exports/InboundOutbound'
import { MarketStructure } from 'screens/Scenarios/Main/sections/Financial/MarketStructures'
import { CrushPickups } from 'screens/Scenarios/Main/sections/Financial/CrushPickups'
import Footer from './Footer'
import { AlcancePlan } from './sections/Crushing/AlcancePlan'
import { TerminalCosts } from './sections/Exports/TerminalCosts'
import { Demand } from './sections/Exports/Demand'
import { InterestRates } from './sections/Financial/InterestRates'
import { ReductionOfCapacity } from 'screens/Scenarios/Main/sections/Silos/ReductionOfCapacity'
import { CrushingPlan } from 'screens/Scenarios/Main/sections/Crushing/CrushingPlan'
import { OilExportDemand } from 'screens/Scenarios/Main/sections/Crushing/OilExportDemand'
import { InternalDemand } from 'screens/Scenarios/Main/sections/Crushing/InternalDemand'
import { TransportInboundOutbound } from 'screens/Scenarios/Main/sections/Transportation/TransportInboundOutbound'
import { DealsAndContracts } from 'screens/Scenarios/Main/sections/Transportation/DealsAndContracts'
import { ProductRestriction } from 'screens/Scenarios/Main/sections/Exports/ProductRestriction'
import { ExportPrices } from 'screens/Scenarios/Main/sections/Financial/ExportPrices'
import { FX } from 'screens/Scenarios/Main/sections/Financial/Fx'
import { ExportPlan } from './sections/Exports/ExportPlan'

const ScenariosMain = () => {
  const { currentSection } = useScenariosContext()

  const CurrentSectionComponent = useMemo(() => {
    switch (currentSection) {
      case 'origination-volume':
        return <Origination />
      case 'silos-inbound_outbound_storage_capacity':
        return <InboundOutbound />
      case 'silos-assets':
        return <Silos />
      case 'silos-products':
        return <Products />
      case 'silos-silo_reception_plan':
        return <SiloReceptionPlan />
      case 'silos-reduction_of_capacity':
        return <ReductionOfCapacity />
      case 'crush_plants-inbound_outbound_storage_capacity':
        return <CrushingInboundOutbound />
      case 'crush_plants-maintenance_plan':
        return <CrushingMaintenance />
      case 'crush_plants-soybean_meal_internal_demand':
        return <SoybeanMealDemand />
      case 'crush_plants-alcance_plan':
        return <AlcancePlan />
      case 'crush_plants-crushing_plan':
        return <CrushingPlan />
      case 'crush_plants-oil_export_demand':
        return <OilExportDemand />
      case 'crush_plants-internal_demand':
        return <InternalDemand />
      case 'transport-transshipment_inbound_outbound_storage_capacity':
        return <TransshipmentInboundOutbound />
      case 'transport-contracts':
        return <Contracts />
      case 'transport-inbound_outbound_capacity_arg':
        return <TransportInboundOutbound />
      case 'transport-deals_contracts':
        return <DealsAndContracts />
      case 'exports-inbound_outbound_storage_capacity':
        return <ExportsInboundOutbound />
      case 'exports-terminal_costs':
        return <TerminalCosts />
      case 'exports-demand':
        return <Demand />
      case 'exports-product_restriction':
        return <ProductRestriction />
      case 'exports-export_plan':
        return <ExportPlan />
      case 'financial-market_structure':
        return <MarketStructure />
      case 'financial-crush_pickups':
        return <CrushPickups />
      case 'financial-interest_rates':
        return <InterestRates />
      case 'financial-export_prices':
        return <ExportPrices />
      case 'financial-fx':
        return <FX />
      default:
        return <Overview />
    }
  }, [currentSection])

  return (
    <div className='w-full flex items-start justify-center bg-white relative'>
      <div className='w-full h-[calc(100%-80px)] flex overflow-auto'>
        <div className='ml-14 mr-auto pr-4 2xl:mr-20 w-full'>
          {CurrentSectionComponent}
        </div>
      </div>
      <div className='absolute bottom-0 py-5 bg-white border-t w-full'>
        <div className='flex mr-auto pr-4 2xl:mr-20 justify-end'>
          <Footer />
        </div>
      </div>
    </div>
  )
}
export default ScenariosMain
