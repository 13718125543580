import { useNewOptContext } from '../NewOptContext'
import Button from 'components/Inputs/Button'
import classNames from 'classnames'
import { useUserInputsContext } from 'providers/UserInputsProvider'
import ProceedModal from './ProceedModal'
import { useMemo } from 'react'
import { useOptDataContext } from 'providers/OptDataProvider'
import { getNewOptimizationName } from 'utils/optimization-name'
import { useLocalization } from 'providers/LocalizationProvider'
import { FILES, SETTINGS, sectionsMap } from './Header'
import { BarsSpinnerIcon } from 'components/Icons'

const Footer = () => {
  const { setPage, page, searchTerm, templates } = useNewOptContext()
  const { state, setProceedWarningOpen, submitForm, setName, getValues, isExecuting } =
    useUserInputsContext()
  const { optData } = useOptDataContext()
  const { countryCode, t } = useLocalization()

  const hide = searchTerm === ''

  const notUpdatedItemCount = useMemo(() => {
    return templates.filter((template) => template.status !== 'success').length
  }, [templates])

  const hasMissingTemplate = useMemo(() => {
    return (
      templates.filter((template) => template.status === 'missing').length > 0
    )
  }, [templates])

  return (
    <div
      className={classNames({
        hidden: hide
      })}
    >
      <ProceedModal
        open={state.proceedWarningOpen}
        setOpen={setProceedWarningOpen}
        numFiles={notUpdatedItemCount}
        onProceed={submitForm}
      />
      <Button
        variant='light'
        className={classNames('w-48 mr-2.5', {
          hidden: page === SETTINGS
        })}
        disabled={isExecuting}
        onClick={() => {
          if (page === SETTINGS) return
          setPage(page - 1)
        }}
      >
        {t(`newOptimizationScreen.footerPrevBtn.${sectionsMap[page - 1]}`)}
      </Button>
      <Button
        variant='filled'
        className='w-48'
        disabled={isExecuting || hasMissingTemplate && page === FILES}
        onClick={() => {
          if (page === FILES) {
            if (notUpdatedItemCount === 0) {
              submitForm()
            } else {
              setProceedWarningOpen(true)
            }
            return
          }

          if (page === SETTINGS) {
            const name = getNewOptimizationName(
              getValues('type'),
              getValues('startMonth'),
              getValues('startYear'),
              optData,
              countryCode
            )
            setName(name)
          }

          setPage(page + 1)
        }}
      >
        {isExecuting ?
          <>
            {t('general.loading')}
            <span className='animate-spin animate-pulse'>
              <BarsSpinnerIcon className='h-6 w-6 fill-white' />
            </span>
          </> : (
          page === FILES
          ? t(`newOptimizationScreen.runOptimization`)
          : t(`newOptimizationScreen.footerNextBtn.${sectionsMap[page + 1]}`)
        )}
      </Button>
    </div>
  )
}

export default Footer
