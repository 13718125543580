import { Ports, Silos, Transshipments, Crushers } from './forms'
import { useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { useLocalization } from 'providers/LocalizationProvider'
import NotFound from 'screens/NotFound'

const FormMap = {
  ports: <Ports />,
  silos: <Silos />,
  transshipments: <Transshipments />,
  crushers: <Crushers />
}

const Configuration = () => {
  const { t, countryCode } = useLocalization()
  const navigate = useNavigate()
  const { id, asset } = useParams()
  const FormTitleMap = {
    ports: t('assetConfigurationScreen.configurationForm.formTitles.port'),
    silos: t(
      `assetConfigurationScreen.configurationForm.formTitles.${
        countryCode === 'ARG' ? 'arg.' : ''
      }silo`
    ),
    transshipments: t(
      'assetConfigurationScreen.configurationForm.formTitles.transhipment'
    ),
    crushers: t('assetConfigurationScreen.configurationForm.formTitles.crusher')
  }

  const title = `${
    id
      ? t('assetConfigurationScreen.configurationForm.actions.update')
      : t('assetConfigurationScreen.configurationForm.actions.register')
  } ${FormTitleMap[asset]}`

  if (!(asset in FormMap)) {
    return <NotFound />
  }

  return (
    <div className='overflow-auto'>
      <div className='mx-auto px-4 mb-20 2xl:mx-20'>
        <div>
          <button
            className='cursor-pointer flex items-center text-gray-500 hover:text-gray-700 mt-4 font-bold'
            onClick={() => {
              navigate(-1)
            }}
          >
            <ChevronLeftIcon className='w-5 h-5' />
            <span>
              {t('assetConfigurationScreen.configurationForm.backBtn')}
            </span>
          </button>
          <div className='mb-14'>
            <h1 className='font-bold text-bold text-2xl mt-4'>{title}</h1>
            <p className='text-grey-500 mt-3'>
              {t('assetConfigurationScreen.configurationForm.description')}
            </p>
          </div>
          {FormMap[asset]}
        </div>
      </div>
    </div>
  )
}

export default Configuration
