import { useCallback } from "react";
import {
  EdgeMock,
  FilterOptions,
  HeatMapValidOptions,
  NodeDataKeys,
  NodeDataLabel,
  NodeMock,
  SelectOption,
} from "../../types";

export const useFiltersOptions = () => {
  const getPeriodOptions = useCallback((periods: number[]) => {
    return periods.map<SelectOption>((period) => ({
      value: period,
      label: period,
    }));
  }, []);

  const getProductOptions = useCallback((edges: EdgeMock[]) => {
    const productsSet = new Set<string>();
    edges.forEach((edge) => {
      productsSet.add(edge.title);
    });
    return Array.from(productsSet).map<SelectOption>((product) => ({
      value: product,
      label: product,
    }));
  }, []);

  const getHeatMapOptions = useCallback(() => {
    return HeatMapValidOptions.map<SelectOption>((option) => ({
      value: NodeDataKeys[option],
      label: NodeDataLabel[option],
    }));
  }, []);

  const getCorridorOptions = useCallback(
    (nodes: NodeMock[], corridors: string[]) => {
      const corridorsSet = new Set<string>();

      for (const node of nodes) {
        if (!corridors.includes(node.data.Corridor)) {
          corridorsSet.add(node.data.Corridor);
        }
      }
      for (const edge of nodes) {
        if (!corridors.includes(edge.data.Corridor)) {
          corridorsSet.add(edge.data.Corridor);
        }
      }

      return Array.from(corridorsSet).map<SelectOption>((corridor) => ({
        value: corridor,
        label: corridor,
      }));
    },
    []
  );

  const filterBy = {
    [FilterOptions.PeriodOptions]: getPeriodOptions,
    [FilterOptions.ProductOption]: getProductOptions,
    [FilterOptions.HeatMapOptions]: getHeatMapOptions,
    [FilterOptions.CorridorsOptions]: getCorridorOptions,
  };

  return {
    filterBy,
  };
};
