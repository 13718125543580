import classNames from 'classnames'

export const TableHeaders = ({
  checkableRows,
  headerClassNames,
  borders,
  rounding: { roundTop },
  stickyHeaders,
  columnHeaders,
  renderExpandedRow,
  expandArrowPosition
}) => {
  return (
    <>
      {checkableRows && (
        <div
          key={'chk'}
          className={classNames({
            [headerClassNames]: !!borders,
            'rounded-tl-lg': roundTop,
            'border-l border-b border-gray-300 border-t border-grey-200':
              !!borders,
            'sticky top-0 z-10': !!stickyHeaders
          })}
          style={{
            gridColumn: 1
          }}
        ></div>
      )}
      {columnHeaders.map((Body, idx) => (
        <div
          key={idx}
          className={classNames({
            [headerClassNames]: Body !== undefined,
            'sticky top-0 z-10': !!stickyHeaders,
            'rounded-tl-lg': roundTop && !checkableRows && idx === 0,
            'rounded-tr-lg':
              roundTop &&
              !renderExpandedRow &&
              idx === columnHeaders.length - 1,
            'border-l': borders && !checkableRows && idx === 0,
            'border-r':
              borders && !renderExpandedRow && idx === columnHeaders.length - 1,
            'border-b border-gray-300 border-t border-grey-200': !!borders
          })}
          style={{
            gridColumn: idx + 1 + !!checkableRows
          }}
        >
          {typeof Body === 'function' ? <Body /> : Body}
        </div>
      ))}
      {expandArrowPosition === 'right' && renderExpandedRow && (
        <div
          key={'expander'}
          className={classNames({
            'sticky top-0 z-10': !!stickyHeaders,
            'rounded-tr-lg': roundTop,
            'border-r border-b border-gray-300 border-t border-grey-200':
              !!borders,
            [headerClassNames]: !!borders
          })}
          style={{ gridColumn: columnHeaders.length + 1 + !!checkableRows }}
        ></div>
      )}
    </>
  )
}
