import AssetsConfigurations from './AssetsConfigurations'
import ErrorBoundary from 'components/ErrorBoundary'
import { Suspense } from 'react'
import Loader from 'components/Feedback/Loader/Loader'
import { useLocalization } from 'providers/LocalizationProvider'

export default function AssetsConfigurationsWrapper() {
  const { t } = useLocalization()
  return (
    <ErrorBoundary
      fallback={
        <div>
          {t('assetConfigurationScreen.assetsConfigurationsErrorBoundary')}
        </div>
      }
    >
      <Suspense fallback={<Loader />}>
        <AssetsConfigurations />
      </Suspense>
    </ErrorBoundary>
  )
}
