import { useState, useMemo } from 'react'
import { useNewOptContext } from '../../NewOptContext'
import DropZone from './DropZone'
import SearchInput from 'components/Inputs/SearchInput'
import UpdateListTable from 'components/Tables/UpdateListTable'
import MainHeader from '../Header'
import { useLocalization } from 'providers/LocalizationProvider'
import { useColumnSorterContext } from 'components/Tables/GridTable/sorting/ColumnSorterProvider'

const Files = () => {
  const { onDrop } = useNewOptContext()
  const { orderData } = useColumnSorterContext()

  const { t } = useLocalization()

  const { templates } = useNewOptContext()
  const [searchTerm, setSearchTerm] = useState('')

  const items = useMemo(() => {
    return orderData(
      templates
        ?.map((template) => {
          return {
            id: template.id,
            file: template.name,
            type: template.type,
            last_update: template.updatedAt,
            ...template
          }
        })
        ?.filter(
          (item) =>
            item.type
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase().replaceAll(/\s+/g, '_')) > -1
          //item.file.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        ) || []
    )
  }, [templates, searchTerm, orderData])

  return (
    <div className='ml-6 mr-auto pr-4 2xl:mr-20'>
      <div className=''>
        <MainHeader />
      </div>
      <section className='mt-8'>
        <h3 className='text-blue-700 text-lg'>
          {t('newOptimizationScreen.sections.files.fileCount', {
            count: items.length
          })}
        </h3>
        <DropZone onDrop={onDrop} className='mt-2' />
      </section>
      <div className='my-4'>
        <SearchInput
          placeholder='Search'
          onChange={(newValue) => setSearchTerm(newValue)}
          value={searchTerm}
        />
      </div>
      <section className='overflow-y-auto h-[calc(100vh-600px)] rounded-t-lg'>
        <UpdateListTable files={items} sorter />
      </section>
    </div>
  )
}
export default Files
