import RunningModelSteps from './RunningModelSteps'
import { BarsSpinnerIcon } from 'components/Icons'
import { useLocalization } from 'providers/LocalizationProvider'

const RunningModel = ({ optimization, optimizationId }) => {
  const { t } = useLocalization()

  return (
    <div className='h-screen mx-auto flex flex-col justify-center items-center w-4/6'>
      <span className='animate-spin animate-pulse mb-2'>
        <BarsSpinnerIcon className='h-6 w-6' />
      </span>
      <h2 className='text-2xl font-bold text-center animate-pulse'>
        {optimization.progress < 100
          ? t('optimizationScreen.runningModel.preparingData')
          : t('optimizationScreen.runningModel.preparingModel')}
      </h2>
      <p className='text-sm text-gray-600 text-center mt-6 mb-8'>
        {t('optimizationScreen.runningModel.preparingDataDesc')}
      </p>
      <RunningModelSteps optimization={optimization} />
    </div>
  )
}

export default RunningModel
