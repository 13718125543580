import Select from 'components/Inputs/Select'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import TransshipmentVolumeAndUtilization from './TransshipmentVolumeAndUtilization'
import Inbound from './Inbound'
import Outbound from './Outbound'
import MultiSelectComponent from 'components/Inputs/Select/MultiSelect'
import useSubProductSelector from 'hooks/useSubProductSelector'
import SectionBox from 'components/Container/SectionBox'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

const TransportationDetails = ({
  setSelectedCorridor,
  selectedTransshipment,
  setSelectedTransshipment,
  transshipmentSelectionOptions
}) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID, selectedProducts } = useOptimizationContext()
  const { options, onChange, selectedSubProducts } = useSubProductSelector()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/transportation_details`,
    { bestEstimateId: comparisonID, ts: selectedTransshipment }
  ])

  return (
    <OutputScreen
      title={t('optimizationScreen.transportation.details.title')}
      titleExtra={
        <>
          <Select
            options={transshipmentSelectionOptions}
            variant='underlined'
            value={selectedTransshipment}
            onValueChange={setSelectedTransshipment}
          />
          <h2 className='text-xl ml-4 mr-2 font-bold'>{t('optimizationScreen.transportation.details.showing')}</h2>
          <MultiSelectComponent
            variant='underlined'
            options={options}
            placeholder={t('optimizationScreen.transportation.details.filter')}
            onChange={onChange}
            disabled={selectedProducts.length === 0}
          />
        </>
      }
      crumbs={[
        {
          label: t('optimizationScreen.transportation.details.transportation'),
          onClick: () => {
            setSelectedCorridor(undefined)
            setSelectedTransshipment(undefined)
          }
        },
        {
          label: t('optimizationScreen.transportation.details.corridor'),
          onClick: () => {
            setSelectedTransshipment(undefined)
          }
        },
        { label: t('optimizationScreen.transportation.details.transshipment') }
      ]}
    >
      <SectionBox>
        <TransshipmentVolumeAndUtilization
          data={data.volumes}
          selectedSubProducts={selectedSubProducts}
        />
      </SectionBox>
      <SectionBox>
        <Inbound
          inboundData={data.in}
          originationData={data.origination}
          selectedSubProducts={selectedSubProducts}
        />
      </SectionBox>
      <SectionBox>
        <Outbound
          outboundData={data.out}
          destinationData={data.destination}
          selectedSubProducts={selectedSubProducts}
        />
      </SectionBox>
    </OutputScreen>
  )
}

export default TransportationDetails
