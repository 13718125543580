import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import Input from 'components/Inputs'

/**
 *
 * @param {object} props
 * @param {string} props.placeholder
 * @param {string} props.value
 * @param {function} props.onChange
 * @returns {JSX.Element}
 */
const SearchInput = (props) => <Input Icon={MagnifyingGlassIcon} {...props} />

export default SearchInput
