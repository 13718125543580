import { useMemo } from 'react'
import classNames from 'classnames'
import { useTableContext } from './TableContext'

export const TableGroupHeaders = ({
  columnHeaderGroups,
  groupHeaderClassNames
}) => {
  const { mappers } = useTableContext()

  const headerGroups = useMemo(() => {
    return [
      ...[...new Array(mappers.length).keys()]
        .map((idx) => ({ span: 1, start: idx + 1 }))
        .filter(
          (item) =>
            columnHeaderGroups.filter(
              (grp) =>
                grp.start <= item.start &&
                grp.start + grp.span >= item.start + item.span
            ).length === 0
        ),
      ...columnHeaderGroups
    ].sort((a, b) => a.start - b.start)
  }, [columnHeaderGroups, mappers.length])

  return headerGroups.map((header) => (
    <div
      key={header.start}
      style={{
        gridColumn:
          header.span < 2
            ? header.start
            : `${header.start} / span ${header.span}`
      }}
      className={
        classNames({
          [groupHeaderClassNames]: header.title !== undefined,
        }, header?.className)
      }
    >
      {header.title}
    </div>
  ))
}
