import TableChart from 'components/Graphs/TableChart'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import useGraph from 'hooks/useGraph'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import useProductFilter from 'hooks/useProductFilter'
import useSummaryItems from 'hooks/useSummaryItems'
import NoData from 'components/Feedback/NoData'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'
import { useCapacityData } from 'screens/Optimization/utils/useCapacityData'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const Inbound = ({ data }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    selectedProducts,
    selectedProductTypes,
    selectedSubProducts
  } = useOptimizationContext()

  let ungroupedInbound = []
  data.forEach((e) => (ungroupedInbound = ungroupedInbound.concat(e.data)))

  const { filteredData } = useProductFilter({
    data: ungroupedInbound,
    selectedSubProducts
  })

  const { summaryItems } = useSummaryItems({
    data: filteredData,
    volumeKey: 'volume'
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: filteredData,
    filterProducts: true
  })

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  const capacityData = useCapacityData(capacityKey, filteredData, baseModelId)

  const { volumeGraphData, bars } = useGraph({
    data: filteredData,
    monthContextData: {
      capacity: ({ record: { month } }) => {
        return capacityData[month]
      }
    },
    capacityKey,
    calcExecutionIdData
  })

  return !hasNoData(volumeGraphData) ? (
    <TableChart
      title={t('optimizationScreen.storage.allSilos.titleArg')}
      summaryItems={summaryItems}
      data={volumeGraphData}
      boxTableValueFormatter={(value) => `${value} Tons`}
      yAxisFormatter={yAxisFormatter}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      bars={bars}
      tooltipFormatter={labelFormatter}
      scatters={[
        {
          dataKey: 'capacity',
          fill: '#8E5602',
          name: t('optimizationScreen.totalCapacity'),
          thickness: 2
        }
      ]}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: valueFormatter,
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.storage')} />
  )
}

export default Inbound
