import {
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'

/**
 *
 * @param {object} props
 * @param {string} props.status
 * @param {string} props.message
 * @param {function} props.onClose
 * @returns {JSX.Element}
 */
const Alert = ({ status, message, onClose, children }) => {
  return (
    <div className='flex justify-center'>
      <div className='w-full'>
        <div
          className={classNames(
            'flex items-center justify-between p-1 rounded-lg  w-full',
            {
              'text-error-600 bg-red-100': status === 'error',
              'text-success-600 bg-success-100': status === 'success',
              'text-warning-600 bg-warning-100': status === 'warning',
              'text-black bg-grey-100': status === 'info'
            }
          )}
        >
          <div className='flex items-center gap-1 w-full'>
            <div>
              <div className={`flex p-2 rounded-lg w-10 h-10`}>
                {(status === 'error' || status === 'info') && (
                  <ExclamationCircleIcon aria-hidden='true' />
                )}
                {status === 'success' && <CheckCircleIcon aria-hidden='true' />}
                {status === 'warning' && (
                  <ExclamationTriangleIcon aria-hidden='true' />
                )}
              </div>
            </div>
            <div className='font-bold mr-5 w-full flex flex-row items-center'>
              {message}
              {children}
            </div>
          </div>
          {onClose && (
            <XMarkIcon
              className={`w-4 h-4 mr-4`}
              strokeWidth={3}
              onClick={onClose}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Alert
