import SectionBox from 'components/Container/SectionBox'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import Stock from './Stock'
import Ratio from './Ratio'
import { useLocalization } from 'providers/LocalizationProvider'

const Storage = ({
  data: { inbound, initial_stock, rotation_ratio },
  selectedSubProducts
}) => {
  const { t } = useLocalization()
  return (
    <SectionBox>
      <TabsComponent>
        {/* <Tab
          value='inbound_volume'
          label={t('optimizationScreen.export.storage.inboundVolume')}
        >
          <Inbound selectedSubProducts={selectedSubProducts} data={inbound} />
        </Tab> */}
        <Tab
          value='initial_stock'
          label={t('optimizationScreen.export.storage.finalStock')}
        >
          <Stock
            selectedSubProducts={selectedSubProducts}
            data={initial_stock}
          />
        </Tab>
        <Tab
          value='rotation_ratio'
          label={t('optimizationScreen.export.storage.rotationRatio')}
        >
          <Ratio data={rotation_ratio} />
        </Tab>
      </TabsComponent>
    </SectionBox>
  )
}

export default Storage
