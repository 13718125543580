import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import DownloadOutputModal from './DownloadOutputModal'
import DownloadInputModal from './DownloadInputModal'
import OptmizationSidebar from './Sidebar'
import OptmizationHeader from './Header'
import OptimizationMain from './Main'

const ONE_MINUTE = 1000 * 60

/**
 *
 * @returns {JSX.Element}
 */
export default function Optimization() {
  const { id } = useParams()

  const { outputs, inprogress, setInprogress } = useOptimizationContext()
  const { data: optimization } = useSWR(
    () => (id ? `/execution/${id}` : null),
    {
      refreshInterval: outputs.length === 0 ? ONE_MINUTE : 0,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      enabled: id && inprogress,
      onSuccess: async (data) => {
        if (data.status !== 'In progress') {
          setInprogress(false)
        }
      }
    }
  )

  return (
    <div className='flex w-screen flex-col overflow-x-hidden'>
      <OptmizationHeader optimization={optimization} />
      <div className='flex flex-row items-start justify-start bg-background'>
        <OptmizationSidebar optimization={optimization} />
        <div className='flex-1 overflow-hidden'>
          <OptimizationMain />
        </div>
      </div>
      <DownloadOutputModal optimizationId={id} />
      <DownloadInputModal optimizationId={id} />
    </div>
  )
}
