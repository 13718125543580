import * as ToastPrimitive from '@radix-ui/react-toast'
import classNames from 'classnames'
import './Toast.css'
import {
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'

/**
 *
 * @param {boolean} open
 * @param {number} duration in milliseconds
 * @param {string} status 'error' | 'warning' | 'success'
 * @param {string} message
 * @param {function} onOpenChange
 * @param {function} onClose
 * @param {string} placement  'bottom' (default) | 'top'
 * @returns {JSX.Element}
 */
export const Toast = ({
  open,
  duration,
  status,
  message,
  onOpenChange,
  onClose,
  placement = 'bottom'
}) => {
  return (
    <>
      <ToastPrimitive.Root
        className='w-full ToastRootElement'
        open={open}
        onOpenChange={onOpenChange}
        duration={duration}
        onClose={onClose}
      >
        <ToastPrimitive.Description className='flex mx-auto w-[450px]'>
          <div className='w-full'>
            <div
              className={classNames(
                'flex items-center justify-between p-2 rounded-lg  w-full',
                {
                  'text-error-600 bg-red-100': status === 'error',
                  'text-success-600 bg-success-100': status === 'success',
                  'text-warning-600 bg-warning-100': status === 'warning'
                }
              )}
            >
              <div className='flex items-center'>
                <div className={`flex pr-2 rounded-lg w-10 h-10 mr-1`}>
                  {status === 'error' && (
                    <ExclamationCircleIcon aria-hidden='true' />
                  )}
                  {status === 'success' && (
                    <CheckCircleIcon aria-hidden='true' />
                  )}
                  {status === 'warning' && (
                    <ExclamationTriangleIcon aria-hidden='true' />
                  )}
                </div>
                <div className={`text-center font-bold mr-4`}>{message}</div>
              </div>
              <ToastPrimitive.Close asChild>
                <XMarkIcon width={20} height={20} />
              </ToastPrimitive.Close>
            </div>
          </div>
        </ToastPrimitive.Description>
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport
        className={classNames('fixed left-4 right-4 w-full z-20', {
          'bottom-4': placement === 'bottom',
          'top-10': placement === 'top'
        })}
      />
    </>
  )
}
