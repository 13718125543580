import TableChart from 'components/Graphs/TableChart'
import useGraph from 'hooks/useGraph'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import useProductFilter from 'hooks/useProductFilter'
import useSummaryItems from 'hooks/useSummaryItems'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, crushed_volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? crushed_volume
          : currentData[product_ui_type] + crushed_volume
    }
  }
  return currentData === NO_DATA_LABEL
    ? crushed_volume
    : currentData + crushed_volume
}

const CrushVolume = ({ data, selectedSubProducts }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { t } = useLocalization()

  const { filteredData } = useProductFilter({
    data,
    selectedSubProducts
  })

  const { summaryItems } = useSummaryItems({
    data: filteredData,
    volumeKey: 'crushed_volume'
  })

  const { volumeGraphData, bars } = useGraph({
    data: filteredData,
    calcExecutionIdData
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'crushed_volume',
    data: filteredData,
    filterProducts: true
  })

  return (
    <TableChart
      title={t('optimizationScreen.export.crushVolume')}
      summaryItems={summaryItems}
      data={volumeGraphData}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      yAxisProps={{ tickFormatter: yAxisFormatter }}
      bars={bars}
      tableData={heatmapData}
      tooltipFormatter={labelFormatter}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: valueFormatter,
        rowTitleFormatter: capitalize
      }}
    />
  )
}

export default CrushVolume
