import classNames from 'classnames'
import { Trans } from 'react-i18next'

export const SectionContainer = ({
  children,
  sectionTitle,
  sectionDescription
}) => (
  <div>
    <h2
      className={classNames('text-2xl font-bold mt-10', {
        'mb-10': !sectionDescription
      })}
    >
      <Trans>{sectionTitle}</Trans>
    </h2>
    {sectionDescription && (
      <p className='text-base text-grey-400 mt-4 mb-10'>
        <Trans>{sectionDescription}</Trans>
      </p>
    )}
    {children}
  </div>
)
