import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  CheckCircleIcon,
  DocumentMagnifyingGlassIcon,
  MapIcon,
  TruckIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import Button from 'components/Inputs/Button'
import ButtonNavigation from 'components/Navigation/ButtonNavigation'
import { CrushingPlantIcon, ShipIcon, SiloIcon } from 'components/Icons'
import {
  CRUSHERS,
  GENERAL_ASSETS_VIEW,
  PORTS,
  ROUTES,
  SILOS,
  TRANSSHIPMENTS
} from 'constants'
import useSWR from 'swr'
import { getFileNameFromTemplateType, swrConfig } from 'utils'
import { useNewOptContext } from '../../NewOptContext'
import MainHeader from '../Header'
import Maps from './Maps'
import { useMemo, useState } from 'react'
import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { GridLoader } from 'react-spinners'
import { Toast } from 'components/Feedback/Toast/Toast'
import { useDatasetDownload } from 'hooks/useDatasetDownload'
import { get } from 'utils/request'
import { useUserInputsContext } from 'providers/UserInputsProvider'
import { useLocalization } from 'providers/LocalizationProvider'

const tabLanguageKeys = {
  [GENERAL_ASSETS_VIEW]: 'generalAssetsView',
  [SILOS]: 'silos',
  [PORTS]: 'ports',
  [CRUSHERS]: 'crushers',
  [TRANSSHIPMENTS]: 'transshipments',
  [ROUTES]: 'routes'
}

const dataTypes = {
  [SILOS]: 'TEMPLATES_BR_SILOS_CAPACITY',
  [PORTS]: 'TEMPLATES_BR_PORT_CAPACITY',
  [CRUSHERS]: 'TEMPLATES_BR_CRUSH_CAPACITY',
  [TRANSSHIPMENTS]: 'TEMPLATES_BR_TRANSSHIP_CAPACITY',
  [ROUTES]: 'ASSETS_BR_MESO_CORRIDORS'
}

const dataTypesArg = {
  [SILOS]: 'TEMPLATES_ARG_ACOPIOS_CAPACITY',
  [PORTS]: 'TEMPLATES_ARG_PORT_CAPACITY',
  [CRUSHERS]: 'TEMPLATES_ARG_CRUSH_CAPACITY',
  [ROUTES]: ['TEMPLATES_ARG_ASSETS_MATRIX', 'TEMPLATES_ARG_CORREDORS_TEMPLATE']
}

const pagesWithDownloadButton = [SILOS, PORTS, CRUSHERS, TRANSSHIPMENTS]

const tabs = [
  {
    value: GENERAL_ASSETS_VIEW,
    icon: DocumentMagnifyingGlassIcon
  },
  {
    value: PORTS,
    icon: ShipIcon
  },
  {
    value: SILOS,
    icon: SiloIcon
  },
  {
    value: TRANSSHIPMENTS,
    icon: TruckIcon
  },
  {
    value: CRUSHERS,
    icon: CrushingPlantIcon
  },
  {
    value: ROUTES,
    icon: MapIcon
  }
]

const AssetsLegend = () => {
  const { countryCode } = useLocalization()

  const getAssetsByCountry = (countryCode) => {
    switch (countryCode) {
      case 'ARG':
        return [PORTS, SILOS, CRUSHERS]
      default:
        return [PORTS, SILOS, TRANSSHIPMENTS, CRUSHERS]
    }
  }

  const assets = useMemo(() => {
    return getAssetsByCountry(countryCode)
  }, [countryCode])

  return (
    <ul className='flex space-x-4'>
      {assets.map((title) => (
        <li key={title} className='flex items-center justify-center'>
          <div
            className={classNames('h-2 w-2 rounded-full border mr-1', {
              'border-blue-500 bg-blue-300': title === PORTS,
              'border-success-500 bg-success-200': title === SILOS,
              'border-purple-400 bg-purple-200': title === TRANSSHIPMENTS,
              'border-orange-400 bg-orange-200': title === CRUSHERS
            })}
          ></div>
          <span>{title}</span>
        </li>
      ))}
    </ul>
  )
}

const Assets = () => {
  const { setSelectedAsset, selectedAsset, setUpdatedFileIds } =
    useNewOptContext()
  const { setAssetFiles } = useUserInputsContext()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState()
  const { countryCode, t } = useLocalization()

  const tabItems = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return tabs
          .filter((tab) => tab.value !== TRANSSHIPMENTS)
          .map((tab) => ({
            ...tab,
            title: t(
              `newOptimizationScreen.sections.assets.tabs.${countryCode}.${
                tabLanguageKeys[tab.value]
              }.title`
            )
          }))
      default:
        return tabs.map((tab) => ({
          ...tab,
          title: t(
            `newOptimizationScreen.sections.assets.tabs.${countryCode}.${
              tabLanguageKeys[tab.value]
            }.title`
          )
        }))
    }
  }, [countryCode, t])

  const types = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return dataTypesArg
      default:
        return dataTypes
    }
  }, [countryCode])

  const {
    data: templateData,
    isLoading,
    mutate
  } = useSWR(
    `/data/query?type=${types[selectedAsset]}`,
    async (url) => {
      const { data } = await get(url)
      if (selectedAsset === ROUTES) {
        setAssetFiles(data.map(({ id, type }) => ({ id, type })))
        if (countryCode === 'ARG') {
          return data
        }
      }
      return data?.[0]
    },
    {
      ...swrConfig,
      suspense: false
    }
  )

  const downloadTemplate = useDatasetDownload()

  const handleDownload = async () => {
    const outputFileName = `${getFileNameFromTemplateType(
      templateData.type
    )}.xlsx`
    downloadTemplate(templateData.id, outputFileName)
  }

  const handleUpload = () => {
    setUploadModalOpen(true)
    clearTimeout(uploadSuccess)
    setUploadSuccess(-1)
  }

  return (
    <div className='bg-white'>
      <ValidatedFileSelectModal
        title={t(
          `newOptimizationScreen.sections.assets.tabs.${countryCode}.${tabLanguageKeys[selectedAsset]}.uploadModalTitle`
        )}
        type={types[selectedAsset]}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onSuccess={(files) => {
          setUpdatedFileIds((fileIds) => [
            ...fileIds,
            ...files.map((x) => x.id)
          ])
          mutate()
          setUploadSuccess(setTimeout(() => setUploadSuccess(-1), 4000))
        }}
      />

      <div className='ml-6 mr-auto pr-4 2xl:mr-20'>
        <MainHeader />
      </div>
      <ButtonNavigation
        buttons={tabItems}
        selected={selectedAsset}
        setSelected={setSelectedAsset}
        className='mx-6 mb-6'
      />

      <div className='border-t pb-16'>
        <div className='mr-auto pr-4 2xl:mr-20'>
          <div className='flex justify-between items-center ml-6 sm:flex-wrap lg:flex-nowrap pt-6 mb-8'>
            <div className='flex flex-col'>
              <h2 className='text-xl font-bold'>
                {t(
                  `newOptimizationScreen.sections.assets.tabs.${countryCode}.${tabLanguageKeys[selectedAsset]}.title`
                )}
              </h2>
              <p className='text-grey-500 text-base mt-2'>
                {t(
                  `newOptimizationScreen.sections.assets.tabs.${countryCode}.${tabLanguageKeys[selectedAsset]}.description`
                )}
              </p>
              {selectedAsset === GENERAL_ASSETS_VIEW && <AssetsLegend />}
            </div>
            {pagesWithDownloadButton.includes(selectedAsset) && (
              <FilePanel
                onDownload={handleDownload}
                onUpload={handleUpload}
                updateInfo={templateData}
                success={uploadSuccess > 0}
                loading={isLoading}
                className='lg:ml-4'
              />
            )}
          </div>
          {selectedAsset === ROUTES ? (
            <>
              {countryCode === 'ARG' ? (
                <RoutesPanelArg
                  templateData={templateData}
                  onSuccess={(files) => {
                    setUpdatedFileIds((fileIds) => [
                      ...fileIds,
                      ...files.map((x) => x.id)
                    ])
                    mutate()
                  }}
                  loading={isLoading}
                />
              ) : (
                <RoutesFilePanel
                  onDownload={handleDownload}
                  onUpload={handleUpload}
                  updateInfo={templateData}
                  loading={isLoading}
                  success={uploadSuccess > 0}
                />
              )}
            </>
          ) : (
            <Maps />
          )}
        </div>
      </div>
    </div>
  )
}

const FilePanel = ({
  onDownload,
  onUpload,
  updateInfo,
  isRoutes,
  success,
  loading,
  className
}) => {
  const { t } = useLocalization()

  return (
    <div className={classNames('flex flex-col my-2', className)}>
      {loading ? (
        <div className='px-36 flex items-center py-4 rounded-md bg-grey-200'>
          <GridLoader color='#7EA2F4' loading={true} size={10} />
        </div>
      ) : (
        <>
          <div className='flex gap-1 align-center w-full'>
            {updateInfo && (
              <Button onClick={onDownload} className='flex-1 min-w-max'>
                <ArrowDownTrayIcon className='block h-5 w-5 text-grey-500' />
                {t('newOptimizationScreen.sections.assets.download')}
              </Button>
            )}

            <Button onClick={onUpload} className='flex-1 min-w-max'>
              <ArrowUpTrayIcon className='h-5 w-5 text-grey-500' />
              {t(
                `newOptimizationScreen.sections.assets.${
                  isRoutes ? 'upload' : 'capacityUpload'
                }`
              )}
            </Button>
          </div>
          <LastUpdatedCard
            date={updateInfo?.updatedAt}
            user={updateInfo?.createdBy}
            success={success}
          />
        </>
      )}
    </div>
  )
}

const RoutesFilePanel = (props) => {
  const { t, countryCode } = useLocalization()
  const baseKey = `newOptimizationScreen.sections.assets.tabs.${countryCode}.routes.assetsMatrix`

  return (
    <div className='flex flex-row items-center rounded-lg border border-solid border-2 ml-5 p-8'>
      <div className='flex flex-col flex-1'>
        <div className='text-l font-bold text-gray-900'>
          {t(`${baseKey}.title`)}
        </div>
        <div className='text-grey-400 bg-white text-sm'>
          {t(`${baseKey}.description`)}
        </div>
      </div>
      <FilePanel {...props} isRoutes />
    </div>
  )
}

const RoutesPanelArg = ({ templateData, onSuccess, loading }) => {
  const downloadTemplate = useDatasetDownload()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [templateType, setTemplateType] = useState(undefined)
  const [assetSuccess, setAssetSuccess] = useState(false)
  const [transportSuccess, setTransportSuccess] = useState(false)
  const { t, countryCode } = useLocalization()
  const baseKey = `newOptimizationScreen.sections.assets.tabs.${countryCode}.routes`

  const assetMatrixTemplate = 'TEMPLATES_ARG_ASSETS_MATRIX'
  const corredorsTemplate = 'TEMPLATES_ARG_CORREDORS_TEMPLATE'
  const titleMap = {
    [assetMatrixTemplate]: 'assetsMatrix',
    [corredorsTemplate]: 'transportMatrix'
  }

  const handleDownload = async (type) => {
    const outputFileName = `${getFileNameFromTemplateType(type)}.xlsx`
    downloadTemplate(
      templateData.find((t) => t.type === type)?.id,
      outputFileName
    )
  }

  const handleUpload = (type) => {
    setTemplateType(type)
    setUploadModalOpen(true)
    setAssetSuccess(false)
    setTransportSuccess(false)
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row items-center rounded-lg border border-solid border-2 ml-5 p-8'>
        <div className='flex flex-col flex-1'>
          <div className='text-l font-bold text-gray-900'>
            {t(`${baseKey}.assetsMatrix.title`)}
          </div>
          <div className='text-grey-400 bg-white text-sm'>
            {t(`${baseKey}.assetsMatrix.description`)}
          </div>
        </div>
        <FilePanel
          onDownload={() => handleDownload(assetMatrixTemplate)}
          onUpload={() => handleUpload(assetMatrixTemplate)}
          updateInfo={templateData?.find((t) => t.type === assetMatrixTemplate)}
          loading={loading}
          success={assetSuccess}
        />
      </div>
      <div className='flex flex-row items-center rounded-lg border border-solid border-2 ml-5 p-8'>
        <div className='flex flex-col flex-1'>
          <div className='text-l font-bold text-gray-900'>
            {t(`${baseKey}.transportMatrix.title`)}
          </div>
          <div className='text-grey-400 bg-white text-sm'>
            {t(`${baseKey}.transportMatrix.description`)}
          </div>
        </div>
        <FilePanel
          onDownload={() => handleDownload(corredorsTemplate)}
          onUpload={() => handleUpload(corredorsTemplate)}
          updateInfo={templateData?.find(
            ({ type }) => type === corredorsTemplate
          )}
          loading={loading}
          success={transportSuccess}
        />
      </div>

      <ValidatedFileSelectModal
        title={t(`${baseKey}.${titleMap[templateType]}.uploadModalTitle`)}
        type={templateType}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onSuccess={(files) => {
          onSuccess(files)
          if (templateType === assetMatrixTemplate) {
            setAssetSuccess(true)
          } else {
            setTransportSuccess(true)
          }
        }}
      />
    </div>
  )
}

const LastUpdatedCard = ({ date, user, success }) => {
  const { t } = useLocalization()
  return (
    <div
      className={classNames(
        'flex justify-center border border-full bg-grey-200 px-4 py-2 text-grey-500 text-sm mt-1 rounded-md transition',
        { 'bg-success-200': success }
      )}
    >
      {!date || !user ? (
        t('newOptimizationScreen.sections.assets.neverUpdated')
      ) : (
        <span className='flex flex-row w-max'>
          {success && <CheckCircleIcon className='h-5 w-5 mr-2 -ml-2' />}
          {t('newOptimizationScreen.sections.assets.lastUpdated', {
            date: new Date(date).toLocaleDateString('default', {
              year: 'numeric',
              month: 'short'
            }),
            user
          })}
        </span>
      )}

      <Toast
        open={success}
        message={t('statusMessages.fileUploadSuccess')}
        status='success'
      />
    </div>
  )
}

export default Assets
