import { useLocalization } from 'providers/LocalizationProvider'
import Configuration from './Configuration'
import ErrorBoundary from 'components/ErrorBoundary'

export default function ConfigurationWrapper() {
  const { t } = useLocalization()
  return (
    <ErrorBoundary
      fallback={
        <div>{t('assetConfigurationScreen.configurationErrorBoundary')}</div>
      }
    >
      <Configuration />
    </ErrorBoundary>
  )
}
