import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon
} from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import SingleVolumeCardSkeleton from './Skeletons/SingleVolumeCardSkeleton'
import classNames from 'classnames'
import { useLocalization } from 'providers/LocalizationProvider'

/**
 * @param {string} title The title of card
 * @param {string} value The value of card
 * @param {number} percentage
 * @param {JSX.Element} children Any component to be displayed
 * @param {?string} direction up || down || {''} Percentage status
 * @param {string} warning Warning text
 * @param {boolean} thirdParty Third party silo info
 * @param {boolean} loading Loads the loading buffer div
 * @returns {JSX.Element}
 * @example
 <SingleVolumeCard
  title='Rio de Janeiro'
  value={'1234'}
  percentage={11}
  warning={'Near capacity'}
  thirdParty={true}
            />
 */
const SingleVolumeCard = ({
  title,
  value,
  percentage,
  children,
  warning,
  thirdParty,
  onClick,
  loading,
  disabled
}) => {
  const { t } = useLocalization()

  return loading ? (
    <SingleVolumeCardSkeleton />
  ) : (
    <div
      className={classNames('border border-gray-300 rounded-lg bg-white', {
        'hover:cursor-pointer hover:border-blue-500 hover:shadow-blue-300/50 hover:shadow-md': !disabled
      })}
      onClick={disabled ? undefined : onClick}
    >
      <div className='flex flex-row p-3 justify-between border-b'>
        <div className='flex flex-col gap-2 '>
          <h4 className='text-base font-bold'>{title}</h4>
          <div className='flex flex-row gap-2'>
            <h5 className='text-base font-bold text-grey-600'>{value}</h5>
            {percentage !== undefined && (
              <div className='font-bold text-base w-min '>
                {percentage === 0 ? (
                  <span className='text-grey-500'>{`${percentage}%`}</span>
                ) : (
                  <span
                    className={classNames(
                      'flex flex-row gap-1',
                      percentage > 0 ? 'text-green-600' : 'text-red-600'
                    )}
                  >
                    {percentage > 0 && (
                      <ArrowTrendingUpIcon
                        className='block h-6 w-5'
                        aria-hidden='true'
                      />
                    )}
                    {percentage < 0 && (
                      <ArrowTrendingDownIcon
                        className='block h-6 w-5'
                        aria-hidden='true'
                      />
                    )}
                    {isNaN(percentage) || percentage === Infinity
                      ? 'N/A'
                      : `${percentage}%`}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-col justify-center'>
          <div className='flex flex-col justify-center items-end gap-1'>
            <div className='font-bold text-base w-min text-yellow-700 bg-warning-100 rounded sm'>
              {warning && warning.length > 0 && (
                <span className='flex flex-row gap-1 px-1.5 py-0.5 text-sm whitespace-nowrap'>
                  <ExclamationCircleIcon
                    className='block h-5 w-5 text-yellow-500'
                    aria-hidden='true'
                  />
                  {warning}
                </span>
              )}
            </div>
            <div className='font-bold text-base w-min text-grey-400 bg-grey-100 rounded-sm'>
              {thirdParty && (
                <span className='px-1 text-sm whitespace-nowrap'>{t('optimizationScreen.thirdParty')}</span>
              )}
            </div>
          </div>
        </div>{' '}
      </div>

      <div className='flex justify-center mx-5 mt-5 h-36'>{children}</div>
    </div>
  )
}

export default SingleVolumeCard
