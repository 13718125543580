import { ModalComponent } from 'components/Modal/ModalComponent'
import { useLocalization } from 'providers/LocalizationProvider'

// This is a temporary login modal. It will be removed once the login page is implemented.
const TemporaryLoginModal = ({ open, setOpen, setUser }) => {
  const { t } = useLocalization()
  const match = window.btoa('bunge:admin')

  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.value === match) {
      setUser('temp@bunge.com', 'none')
      setOpen(false)
    }
  }

  return (
    <ModalComponent
      open={open}
      onOpenChange={setOpen}
      title={t('loginScreen.temporaryLogin.title')}
      content={t('loginScreen.temporaryLogin.content')}
    >
      <input
        onChange={handleChange}
        className='mx-2 rounded border-blue-400 border w-72'
        type='text'
      />
    </ModalComponent>
  )
}

export default TemporaryLoginModal
