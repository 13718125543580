import useSWR from 'swr'
import TransshipmentForm from './TransshipmentForm'
import { useParams } from 'react-router-dom'
import { getProductsWithPrecheckedValues } from 'screens/Configuration/utils'
import { useMemo } from 'react'
import { get } from 'utils/request'
import { useCity } from 'providers/CityProvider'

const Transshipments = () => {
  const { id } = useParams()
  const { getCityById } = useCity()

  const { data } = useSWR(id ? `/transshipment/${id}` : null, get)

  const currentData = useMemo(() => {
    if (!data) {
      return
    }

    const {
      transshipment_name,
      city_id,
      ownership,
      transshipment_type,
      products,
      rail_system_name,
      include
    } = data

    const { country, state } = getCityById(city_id)

    return {
      transshipment_name,
      country,
      state,
      city_id,
      ownership,
      transshipment_type,
      products: getProductsWithPrecheckedValues(products),
      rail_system_name,
      include
    }
  }, [data, getCityById])

  return <TransshipmentForm id={id} currentData={currentData} />
}

export default Transshipments
