import { useOptimizationContext } from 'providers/OptimizationProvider'
import { count } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'

const useSubProductSelector = () => {
  const { availableSubProducts } = useOptimizationContext()

  const [options, setOptions] = useState(
    availableSubProducts.map((p) => ({
      label: p.label,
      value: p.value,
      checked: true
    }))
  )

  const onChange = useCallback((value, state) => {
    setOptions((prev) => {
      // Disallow changing when there is only one filter is checked
      if (count((x) => x.checked, prev) === 1 && !state) {
        return prev
      }

      return prev.map((x) => (x.value === value ? { ...x, checked: state } : x))
    })
  }, [])

  useEffect(() => {
    setOptions((prev) => {
      const filteredPreviousProductOptions = prev.filter((p) =>
        availableSubProducts.some((prod) => prod.value === p.value)
      )

      const prevProducts = filteredPreviousProductOptions.map((p) => p.value)

      const newlyAddedProducts = availableSubProducts.filter(
        (p) => !prevProducts.includes(p.value)
      )

      const newOptions = newlyAddedProducts.map((p) => ({
        value: p.value,
        label: p.label,
        checked: true
      }))
      return [...filteredPreviousProductOptions, ...newOptions]
    })
  }, [availableSubProducts])

  const selectedSubProducts = useMemo(() => {
    return options.reduce(
      (acc, { value, checked }) => (checked ? [...acc, value] : [...acc]),
      []
    )
  }, [options])

  return { selectedSubProducts, options, onChange }
}

export default useSubProductSelector
