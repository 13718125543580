import { PencilSquareIcon } from '@heroicons/react/24/outline'
import Button from 'components/Inputs/Button'

const EditButton = ({ item, ctx: { handleOnEdit } }) => {
  return (
    <Button variant='filled' icon onClick={() => handleOnEdit(item)}>
      <PencilSquareIcon className='w-5 h-5' />
    </Button>
  )
}

export default EditButton
