import { capitalize, groupBy, sumBy } from 'lodash'
import {
  NO_DATA_LABEL,
  getMonthsForPeriod,
  transformToPeriods,
  getProductColor
} from 'screens/Optimization/utils/output'
import { formatNumber } from 'utils/number'
import { createMonthObject } from 'screens/Optimization/utils'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo, useCallback } from 'react'
import { useParams } from 'react-router'
import { useLocalization } from 'providers/LocalizationProvider'

const useCrushingOutput = (data) => {
  const { id: baseModelId } = useParams()
  const { t } = useLocalization()

  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const summaryItems = useMemo(() => {
    if (!data.allVolumes) {
      return {}
    }

    const volumeByProduct = data.allVolumes.reduce((acc, curr) => {
      if (!acc[curr.product_ui_type]) {
        acc[curr.product_ui_type] = {}
      }

      if (acc[curr.product_ui_type][curr.execution_id]) {
        acc[curr.product_ui_type][curr.execution_id] += curr.volume
      } else {
        acc[curr.product_ui_type][curr.execution_id] = curr.volume
      }

      return acc
    }, {})

    return Object.keys(volumeByProduct)
      .filter((p) =>
        selectedProducts > 0 ? selectedProducts.includes(p) : true
      )
      .map((p) => ({
        title: capitalize(p),
        items: [
          {
            description: t('optimizationScreen.periodTotal'),
            value: formatNumber(volumeByProduct[p][baseModelId] || 0, {
              maximumFractionDigits: 0
            })
          },
          {
            description: t('optimizationScreen.comparisonPeriodTotal'),
            value: formatNumber(volumeByProduct[p][comparisonID] || 0, {
              maximumFractionDigits: 0
            })
          }
        ]
      }))
  }, [data, selectedProducts, baseModelId, comparisonID, t])

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData,
        totalCapacity: executionData
      })
    },
    [baseModelId, comparisonID, end_date, start_date]
  )

  const calcExecutionIdData = ({ currentData, product_ui_type, volume }) => {
    if (typeof currentData === 'object') {
      return { ...currentData, [product_ui_type]: volume }
    }
    return currentData === NO_DATA_LABEL ? volume : currentData + volume
  }

  const allVolumeChartData = useMemo(() => {
    const filteredData =
      selectedProducts.length > 0
        ? data.allVolumes.filter((v) =>
            selectedProducts.includes(v.product_ui_type)
          )
        : data.allVolumes
    const result = filteredData.reduce(
      (
        acc,
        { month, execution_id, product_ui_type, volume, product_type_capacity }
      ) => {
        let monthCapacity = acc[month]['totalCapacity']
        if (execution_id === baseModelId && product_type_capacity) {
          const monthCapacityIsNumber =
            typeof acc[month]['totalCapacity'] === 'number'
          monthCapacity = monthCapacityIsNumber
            ? acc[month]['totalCapacity'] + product_type_capacity
            : product_type_capacity
        }
        return {
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume
            }),
            totalCapacity: monthCapacity
          }
        }
      },
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, curr) => ({ ...acc, [curr]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )
    return Object.entries(result).map(
      ([month, { totalCapacity, ...volumes }]) => ({
        month,
        volumes,
        totalCapacity
      })
    )
  }, [data, selectedProducts, getMonthsWithDefaultData, baseModelId])

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const allVolumeBars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths, t]
  )

  const productCards = useMemo(() => {
    const filteredProducts = data.products.filter((p) =>
      selectedProducts.length > 0
        ? selectedProducts.includes(p.product_ui_type)
        : true
    )

    const groupedData = groupBy(
      filteredProducts,
      filteredProducts?.[0]?.product_name ? 'product_name' : 'product'
    )
    const result = {}

    for (const product in groupedData) {
      const optPeriod = getMonthsForPeriod(start_date, end_date)
      const months = groupBy(groupedData[product], 'month')
      const monthData = optPeriod.map((month) => ({
        month,
        volume: {
          [baseModelId]: months[month]
            ? sumBy(
                months[month].filter((m) => m.execution_id === baseModelId),
                'volume'
              )
            : NO_DATA_LABEL,
          [comparisonID]: months[month]
            ? sumBy(
                months[month].filter((m) => m.execution_id === comparisonID),
                'volume'
              )
            : NO_DATA_LABEL
        }
      }))

      result[product] = monthData
    }

    return result
  }, [data, selectedProducts, start_date, end_date, comparisonID, baseModelId])

  const maintenanceData = useMemo(() => {
    const groupByAsset = groupBy(data.maintenance, 'asset_name')

    for (const asset in groupByAsset) {
      const bm = transformToPeriods(
        groupByAsset[asset]
          .filter((m) => m.execution_id === baseModelId)
          .map((m) => m.month)
      )
      const cmp = transformToPeriods(
        groupByAsset[asset]
          .filter((m) => m.execution_id === comparisonID)
          .map((m) => m.month)
      )

      groupByAsset[asset] = {
        bm,
        cmp
      }
    }

    const result = []

    for (const asset in groupByAsset) {
      result.push({
        name: asset,
        ...groupByAsset[asset]
      })
    }

    return result
  }, [data, baseModelId, comparisonID])

  const crushedVolumeCards = useMemo(() => {
    const filteredData = data.crushVolumes.filter((item) =>
      selectedProducts?.length > 0
        ? selectedProducts.includes(item.product_ui_type)
        : true
    )
    const crushByAsset = groupBy(filteredData, 'crush')
    const result = []

    for (const crush in crushByAsset) {
      const volumeData = crushByAsset[crush].filter(
        (v) => v.execution_id === baseModelId
      )
      const aggregatedValue = sumBy(volumeData, 'volume')
      const months = getMonthsForPeriod(start_date, end_date)
      const groupByMonth = groupBy(crushByAsset[crush], 'month')
      const atLimit = []
      const aggregatedCompareVolume = sumBy(
        crushByAsset[crush].filter((v) => v.execution_id === comparisonID),
        'volume'
      )
      const trend =
        Math.round(
          100 * ((aggregatedValue - aggregatedCompareVolume) / aggregatedValue)
        ) || 0

      const aggregatedCapacityByProduct = groupBy(volumeData, 'product_ui_type')

      for (const product in aggregatedCapacityByProduct) {
        const capacitySum = sumBy(
          [...aggregatedCapacityByProduct[product]],
          'capacity'
        )
        const volumeSum = sumBy(
          [...aggregatedCapacityByProduct[product]],
          'volume'
        )

        atLimit.push(volumeSum / capacitySum > 0.9)
      }

      const monthlyData = months.map((month) => {
        const data = groupByMonth[month]

        if (!data) {
          return {
            month,
            volume: NO_DATA_LABEL,
            utilization: NO_DATA_LABEL
          }
        } else {
          const sumVolume = sumBy(
            data.filter((d) => d.execution_id === baseModelId),
            'volume'
          )
          const sumCapacity = sumBy(
            data.filter((d) => d.execution_id === baseModelId),
            'capacity'
          )

          return {
            month,
            volume: sumVolume,
            utilization: Math.round((sumVolume / sumCapacity) * 100) || 0
          }
        }
      })

      result.push({
        asset: crush,
        assetName: volumeData[0]?.asset_name || '',
        aggregatedValue,
        months: monthlyData,
        isAtCapacityLimit: atLimit.every((l) => l),
        trend
      })
    }

    return result
  }, [data, selectedProducts, start_date, end_date, baseModelId, comparisonID])

  const assets = useMemo(() => {
    return data.crushVolumes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.crush]: curr.asset_name
      }
    }, {})
  }, [data])

  return {
    summaryItems,
    allVolumeChartData,
    allVolumeBars,
    productCards,
    maintenanceData,
    crushedVolumeCards,
    assets
  }
}

export default useCrushingOutput
