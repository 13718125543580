import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useRowClassName } from './useRowClassName'
import { useMappers } from './useMappers'
import { cellComponentFactory } from './CellComponents'

const TableContext = createContext()
TableContext.displayName = 'TableContext'

export const TableContextProvider = ({ children, extendedProps }) => {
  const [expandedRows, setExpandedRows] = useState({})

  const setRowExpanded = (id, state) =>
    setExpandedRows((prev) => ({ ...prev, [id]: state }))

  const toggleRowExpanded = (id) =>
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }))

  const isRowExpanded = useCallback((id) => !!expandedRows[id], [expandedRows])

  const components = useMemo(
    () => cellComponentFactory(extendedProps),
    [extendedProps]
  )

  const mappers = useMappers(extendedProps, components)
  const rowClassName = useRowClassName(extendedProps, mappers)

  return (
    <TableContext.Provider
      value={{
        isRowExpanded,
        setRowExpanded,
        toggleRowExpanded,
        rowClassName,
        mappers,
        components
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

export const useTableContext = () => {
  const context = useContext(TableContext)
  if (context === undefined) {
    throw new Error(
      'Internal GridTable components should not be used outside of it'
    )
  }
  return context
}
