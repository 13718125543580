import {
  NodeOptions,
  EdgeOptions,
  DataSet,
  Node,
  Edge,
  IdType
} from 'vis'
import { generateRandomColor } from '../utils'

export interface EdgeDefaultColor {
  (title: string): EdgeStyle
}

export interface NodeDefaultColor {
  (level: number): NodeStyle
}
export interface StyleConfig<
  T extends NodeStyle | EdgeStyle,
  U extends NodeDefaultColor | EdgeDefaultColor
> {
  default: U
  onSelect: T
  onFade: T
  onHeatMap: (percent: number, total: number) => T
}

export interface NodeData {
  Flow_volume: number
  Revenue_per_ton: number
  Total_cost_per_ton: number
  total_revenue: number
  total_volume: number
  TimePeriod: number
  Corridor: string
  State: string
  Region: string
  MesoRegion: string
}

export type NodeDataType = keyof NodeData

export interface NodeStyle extends NodeOptions {
  color?: string | undefined
  highlight?: string | undefined
  hover?: string | undefined
  inherit?: string | boolean | undefined
  opacity?: number | undefined
}

export interface NodeMock extends NodeStyle {
  id: number
  level: number
  label: string
  data: NodeData
}

export interface EdgeColor {
  opacity?: number
  color?: string
  highlight?: string
  hover?: string
  inherit?: boolean | string
}
export interface EdgeStyle extends EdgeOptions {
  color?: EdgeColor
  highlight?: string
  hover?: string
  title?: string
}

export interface EdgeData {
  Corridor: string
  Total_cost_per_ton: number
  value: number
}

export type EdgeDataType = keyof EdgeData

export interface EdgeMock extends EdgeStyle {
  id?: string
  from: number
  to: number
  value: number
  title: string
  data: EdgeData
}

export interface SelectOption {
  label: string | number
  value: IdType
}

export interface Data<
  T extends Node[] | DataSet<Node>,
  U extends Edge[] | DataSet<Edge>
> {
  nodes: T
  edges: U
}

export interface GraphFilterForm {
  nodes: NodeMock[]
  edges: EdgeMock[]
  periods: number[]
}

// export type FilterType =
//   | "period"
//   | "product"
//   | "heatViewPerProperty"
//   | "corridors";

export enum FilterType {
  Period = 'period',
  Product = 'product',
  HeatMap = 'heatViewPerProperty',
  Corridor = 'corridors'
}

export type GraphValue = number | string | string[] | NodeDataType

export interface GraphFilter {
  filterType: FilterType
  value: GraphValue
}

export interface GraphClickEvent {
  nodes: number[]
  edges: string[]
  previousSelection?: {
    nodes: number[]
    edges: string[]
  }
}

export interface GraphHoverEvent {
  node?: number
  edge?: string
}

export interface Loading {
  percentage: number
  isDone: boolean
}

export interface GraphStabilizeEvent {
  iterations: number
  total: number
}

export enum FilterOptions {
  PeriodOptions,
  ProductOption,
  HeatMapOptions,
  CorridorsOptions
}

export const FilterTypeString: { [filter in FilterType]: FilterType } = {
  period: FilterType.Period,
  product: FilterType.Product,
  heatViewPerProperty: FilterType.HeatMap,
  corridors: FilterType.Corridor
}

export const EventType = {
  selectNode: 'onSelect',
  deselectNode: 'onDeselect'
}

export const NodeDataKeys: { [filterType in NodeDataType]: NodeDataType } = {
  Corridor: 'Corridor',
  Flow_volume: 'Flow_volume',
  MesoRegion: 'MesoRegion',
  Region: 'Region',
  Revenue_per_ton: 'Revenue_per_ton',
  State: 'State',
  TimePeriod: 'TimePeriod',
  Total_cost_per_ton: 'Total_cost_per_ton',
  total_revenue: 'total_revenue',
  total_volume: 'total_volume'
}

export const NodeDataLabel: { [filterType in NodeDataType]: string } = {
  Corridor: 'Corridor',
  Flow_volume: 'Flow volume',
  MesoRegion: 'Meso region',
  Region: 'Region',
  Revenue_per_ton: 'Revenue per ton',
  State: 'State',
  TimePeriod: 'Time period',
  Total_cost_per_ton: 'Total cost per ton',
  total_revenue: 'Total revenue',
  total_volume: 'Total volume'
}

export const EdgeDataKeys: { [filterType in EdgeDataType]: EdgeDataType } = {
  Corridor: 'Corridor',
  Total_cost_per_ton: 'Total_cost_per_ton',
  value: 'value'
}

export const EdgeDataLabel: { [filterType in EdgeDataType]: string } = {
  Corridor: 'Corridor',
  Total_cost_per_ton: 'Total cost per ton',
  value: 'Value'
}

export const HeatMapValidOptions: NodeDataType[] = ['Flow_volume']
export const NodeInfoToOmit: NodeDataType[] = [
  'Region',
  'State',
  'Total_cost_per_ton'
]
export const EdgeInfoToOmit: EdgeDataType[] = []

export const nodeShapes: { [level: number]: string } = {
  1: 'square',
  2: 'hexagon',
  3: 'dot',
  4: 'triangle'
}

export const nodeColors: { [level: number]: string } = {
  1: generateRandomColor(),
  2: generateRandomColor(),
  3: generateRandomColor(),
  4: generateRandomColor()
}

export const edgeColors: { [product: string]: string } = {
  corn: generateRandomColor(),
  soy: generateRandomColor(),
  'soybean meal 48': generateRandomColor(),
  'soybean meal 46': generateRandomColor(),
  'soybean meal SPC 62': generateRandomColor(),
  'soybean meal SPC 60': generateRandomColor()
}
