const GraphSkeletonOS = () => {
  return (
    <div className='relative border-gray-200 rounded-lg animate-pulse w-full h-full'>
      <div className='p-4 absolute inset-x-0 bottom-0'>
        <div className='flex items-baseline mt-4 space-x-2'>
          <div className='w-full bg-gray-200 rounded-t-lg h-16'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-6'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-4'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-14'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-10'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-3'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-8'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-6'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-10'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-8'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-10'></div>
        </div>
        <div className='flex mt-2.5 space-x-3 inset-x-0 bottom-0'>
          <div className='w-2 h-2 bg-gray-200 rounded-full'></div>
          <div className='w-10 h-2 bg-gray-200 rounded-full'></div>
          <div className='w-2 h-2 bg-gray-200 rounded-full'></div>
          <div className='w-10 h-2 bg-gray-200 rounded-full '></div>
        </div>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}
export default GraphSkeletonOS
