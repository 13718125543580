import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useState } from 'react'
import AllTransshipments from './AllTransshipments'
import VolumeAndUtilization from './VolumeAndUtilization'
import { TabsComponent, Tab } from 'components/Navigation/Tabs/Tabs'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

function TransportationARG() {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/transportation`,
    { bestEstimateId: comparisonID }
  ])

  const tabs = [
    { value: 'rail', label: t('optimizationScreen.transportation.main.railway') },
    { value: 'barges', label: t('optimizationScreen.transportation.main.waterway') },
    { value: 'truck', label: t('optimizationScreen.transportation.main.truck') }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)

  return (
    <OutputScreen title={t('optimizationScreen.pageNames.transportation')} information>
      <TabsComponent value={selectedTab} onChange={setSelectedTab}>
        {tabs.map((tab) => {
          return (
            <Tab value={tab.value} label={tab.label} key={tab.value}>
              <AllTransshipments
                tab={tab}
                data={data.argTransportationVolumes.filter(
                  (transport) => transport.transport_mode === tab.value
                )}
              />
              <VolumeAndUtilization
                data={data.argTransportationVolumes.filter(
                  (transport) => transport.transport_mode === tab.value
                )}
              />
            </Tab>
          )
        })}
      </TabsComponent>
    </OutputScreen>
  )
}

export default TransportationARG
