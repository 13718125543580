import { RadioGroup as RadioGroupComponent } from '@headlessui/react'
import classNames from 'classnames'
import { RadioButton } from './RadioButton'

const DefaultRadioBody = ({ label, checked, value, disabled }) => (
  <div
    className={classNames('inline-flex items-center', {
      'cursor-pointer': !disabled,
      'opacity-50': disabled
    })}
  >
    <RadioButton checked={checked} value={value} />
    <span className='ml-2 text-sm'>{label}</span>
  </div>
)

const RadioGroup = ({
  label,
  options,
  componentClassName,
  optionClassName,
  className,
  RadioBody = DefaultRadioBody,
  error,
  ...props
}) => (
  <RadioGroupComponent className={componentClassName} {...props}>
    <RadioGroupComponent.Label>
      <h3 className='text-base text-grey-600 mb-1'>{label}</h3>
    </RadioGroupComponent.Label>
    <ul className={classNames('flex gap-4', className)}>
      {options.map((option) => (
        <RadioGroupComponent.Option
          value={option.value}
          key={option.value}
          disabled={option.disabled}
          as='li'
          className={optionClassName}
        >
          {({ checked }) =>
            option.body ? (
              <option.body checked={checked} {...option} />
            ) : (
              <RadioBody checked={checked} {...option} />
            )
          }
        </RadioGroupComponent.Option>
      ))}
    </ul>
    {error && <div className='text-error-400'>{error}</div>}
  </RadioGroupComponent>
)

export default RadioGroup
