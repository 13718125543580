const MAX_VALIDATION_ERRORS = 15

export const capitalize = (str) =>
  typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str

export const getCapitalFirstLetter = (str) => str?.charAt(0)?.toUpperCase()

const formatter_exceptions = [
  'and',
  'or',
  'not',
  'is',
  'are',
  'was',
  'were',
  'been',
  'being',
  'have'
]

const formatter_abbreviations = ['be', 'bm']

export function formatter(text) {
  const lowercaseText = text?.toLowerCase()

  if (formatter_abbreviations.includes(lowercaseText)) {
    return lowercaseText.toUpperCase()
  }

  const withSpaces = lowercaseText?.replaceAll(/_|-/gi, ' ')
  return withSpaces
    ?.split(' ')
    ?.map((string) => {
      if (formatter_exceptions.includes(string)) {
        return string
      }
      return capitalize(string)
    })
    ?.join(' ')
}

export const brazilian_states = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO'
]

/**
 * @param {string} str
 * @returns boolean
 */
export function validatePortName(str, base = 'Port_') {
  const regex = new RegExp(
    `^${base}(AC|AL|AM|AP|BA|CE|DF|ES|GO|MA|MG|MS|MT|PA|PB|PE|PI|PR|RJ|RN|RO|RR|RS|SC|SE|SP|TO)_[a-zA-Z0-9_-]{3,}$`
  )

  return regex.test(str)
}

export const stripDataTypePrefix = (type) =>
  type?.replace(/^[^_]+_[A-Z]{2,3}_/, '')

export const translateValidationErrors = (t, errors) => {
  if (!Array.isArray(errors)) return []
  if (errors.length > MAX_VALIDATION_ERRORS) {
    return [
      ...errors
        .slice(0, MAX_VALIDATION_ERRORS)
        .map(({ message, params }) => t(`excelValidation.${message}`, params)),
      t('excelValidation.tooManyErrors', {
        count: errors.length - MAX_VALIDATION_ERRORS
      })
    ]
  }
  return errors.map(({ message, params }) =>
    t(`excelValidation.${message}`, params)
  )
}

export const templateTypeFromFilename = (country, fileName) => {
  let ucFileName = fileName.replace('.xlsx', '').toUpperCase()
  if (ucFileName.startsWith(`${country}_`)) {
    ucFileName = ucFileName.substring(country.length + 1)
  }
  return `TEMPLATES_${country}_${ucFileName}`
}

export const FILE_UPLOAD_SUCCESSFULLY = 'File uploaded successfully'
