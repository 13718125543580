import classNames from 'classnames'

const Tooltip = ({ children, className, style, show, light }) => {
  return (
    <div
      className={classNames(
        ' rounded-[10px] w-fit p-2.5 transition-all ease-in-out pointer-events-none',
        {
          'opacity-0': !show,
          'opacity-100': show,
          'bg-[#081B43] text-white': !light,
          'bg-white text-black border-gray-200 shadow-md': light
        },
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export default Tooltip
