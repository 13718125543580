import { useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import { post } from 'utils/request'
import { useToast } from 'providers/ToastProvider'
import { useLocalization } from 'providers/LocalizationProvider'
import Button from 'components/Inputs/Button'

const Footer = (props) => {
  const navigate = useNavigate()
  const { t, countryCode } = useLocalization()
  const { showToast } = useToast()

  const body = {
    name: props.data.name,
    description: props.data.description,
    advancedSettings: props.data.advancedSettings,
    files: props.data.files,
    parent_execution_id: props.id,
    createdBy: props.data.createdBy,
    country_code: countryCode,
    start_date: props.data.start_date,
    end_date: props.data.end_date
  }

  const { trigger: createExecution } = useSWRMutation(
    `/execution/${props.id}/copy`,
    (url, { arg }) => post(url, arg),
    {
      onSuccess: ({ data }) => {
        if (data.id) {
          navigate('/execution/' + data.id)
        }
      },
      onError: (error) => {
        if (error?.response?.data?.message === 'countryCodeMissmatch') {
          showToast({
            message: t('statusMessages.genericErrorRefresh'),
            status: 'error',
            placement: 'top'
          })
        } else {
          showToast({
            message: t('statusMessages.genericError'),
            status: 'error',
            placement: 'top'
          })
        }
        console.error(error)
      }
    }
  )

  return (
    <Button
      variant='filled'
      className='w-48 2xl:mr-20'
      onClick={() => createExecution(body)}
      // disabled={!enabled}
    >
      Run
    </Button>
  )
}

export default Footer
