import { BrowserRouter } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'
import Header from 'components/Header'
import Button from 'components/Inputs/Button'

function ErrorFallback({ error, onReset }) {
    const { t } = useLocalization()

    const handleNavigateHome = () => {
        window.location.href = '/'
    }

    return (
        <>
            <Header showUserMenu={false} showTabs={false} />
            <div className="flex flex-col items-center justify-center h-[calc(100%-48px)] text-center">
                <h1 className="text-2xl font-bold mb-4">{t('general.fallbackErrorTitle')}</h1>
                {error ? <p className="mb-4">{error.message}</p> : null}
                {typeof window !== 'undefined' && (
                    <Button variant='filled' onClick={handleNavigateHome}>
                        {t('general.fallbackErrorBackToHomeButton')}
                    </Button>
                )}
            </div>
        </>
    )
}

function ErrorFallbackWrapper(props) {
    return (
        <BrowserRouter>
            <ErrorFallback {...props} />
        </BrowserRouter>
    )
}

export default ErrorFallbackWrapper
