import classNames from 'classnames'
import BarChart from 'components/Graphs/BarChart'
import { capitalize, getCapitalFirstLetter } from 'utils/string'
import { formatAsMillions, formatCurrency, formatNumber } from 'utils/number'
import Slider from '../TabsSliderComponent'
import useOverviewData from './useOverviewData'
import {
  NO_DATA_LABEL,
  createMonthObject,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import {
  calcPercentageDiff,
  calculateComparisonDiff,
  transformGroupedData,
  groupByArray,
  calculateTotalVolumes
} from './overviewUtils'
import {
  getDefaultProductColor,
  getProductColor
} from 'screens/Optimization/utils/output'
import { labelFormatter } from 'screens/Optimization/utils/output'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'
import OutputScreen from 'components/Container/OutputScreen'
import Tooltip from 'components/Tooltip'
import { useState } from 'react'

/**
 * @param {string} title    The title of the graphs
 * @param {string} color    The color of the graphs
 * @param {array} items     The data of the graphs
 * @returns {JSX.Element}
 */
const GraphsCard = ({ title, color, items, type, keys }) => {
  const { t } = useLocalization()
  const { id } = useParams()
  const {
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()
  const [visibleTooltip, setVisibleTooltip] = useState(undefined)

  const grouped = transformGroupedData(groupByArray(items, type), keys)

  const barGraphData = grouped.map(({ key, values }) => {
    const aggregatedVolumes = values.reduce(
      (acc, { month, volume, execution_id }) => ({
        ...acc,
        [month]: {
          ...acc[month],
          [execution_id]:
            acc[month][execution_id] === NO_DATA_LABEL
              ? volume
              : acc[month][execution_id] + volume
        }
      }),
      createMonthObject(start_date, end_date, {
        [id]: NO_DATA_LABEL,
        ...(comparisonID ? { [comparisonID]: NO_DATA_LABEL } : {})
      })
    )
    return {
      key,
      values: Object.entries(aggregatedVolumes).map(([month, volume]) => ({
        month,
        volume
      }))
    }
  })

  return (
    <section className='flex w-full rounded-lg border shadow-md flex-col bg-white'>
      <h3 className='border-b px-4 py-2 flex items-center text-grey-700'>
        {title}
      </h3>

      {barGraphData &&
        barGraphData?.map(({ key, values }) => {
          if (
            Object.values(values).every(
              ({ volume }) => volume[id] === NO_DATA_LABEL
            )
          ) {
            return (
              <div
                key={key}
                className='h-[130px] items-center justify-center flex text-center px-4'
              >
                {t('optimizationScreen.overview.notExist', {
                  key: capitalize(key),
                  title: title
                })}
              </div>
            )
          }

          const totalVolumes = calculateTotalVolumes(values)

          return (
            <div className='grid grid-cols-3 px-4 h-[130px]' key={key + title}>
              <div className='col-span-2 p-2'>
                <BarChart
                  variant='small'
                  disableYAxis
                  data={values}
                  bars={[
                    {
                      dataKey: `volume.${id}`,
                      fill: color,
                      name: key
                    }
                  ]}
                  barProps={{
                    barSize: 5
                  }}
                  cartesianGridProps={{ horizontal: false, vertical: false }}
                  xAxisProps={{
                    dataKey: 'month',
                    strokeWidth: 0,
                    tickFormatter: (val) =>
                      getCapitalFirstLetter(transformDateToShortMonth(val))
                  }}
                  tooltipFormatter={(v) =>
                    type === 'type_financial'
                      ? formatCurrency(v)
                      : labelFormatter(v)
                  }
                />
              </div>
              <div className='m-2 flex flex-col justify-center'>
                <h4 className='text-xs flex justify-between items-center'>
                  {capitalize(key)}
                </h4>
                <div className='relative'>
                  <p
                    className='text-lg font-bold select-all mt-2'
                    onMouseEnter={() => setVisibleTooltip(key)}
                    onMouseLeave={() => setVisibleTooltip(undefined)}
                  >
                    {type === 'type_financial'
                      ? formatAsMillions(totalVolumes[id] || 0)
                      : formatNumber(totalVolumes[id] || 0, {
                          maximumFractionDigits: 0
                        })}
                  </p>
                  <Tooltip
                    light
                    show={type === 'type_financial' && visibleTooltip === key}
                    className='max-w-2xl w-max z-20 absolute top-8 left-1 p-2'
                  >
                    {formatNumber(totalVolumes[id] || 0, {
                      maximumFractionDigits: 0
                    })}
                  </Tooltip>
                </div>
                {comparisonID && (
                  <p
                    className={classNames(
                      'flex text-xs py-[2px] px-[10px] w-max justify-center rounded mt-2 select-all',
                      {
                        'bg-success-100 text-success-600 font-bold':
                          calcPercentageDiff(
                            totalVolumes[id],
                            totalVolumes[comparisonID]
                          ) > 0,
                        'bg-warning-100 text-warning-600 font-bold':
                          calcPercentageDiff(
                            totalVolumes[id],
                            totalVolumes[comparisonID]
                          ) < 0,
                        'bg-grey-100 text-grey-600 font-bold':
                          calcPercentageDiff(
                            totalVolumes[id],
                            totalVolumes[comparisonID]
                          ) === 0.0
                      }
                    )}
                  >
                    {calculateComparisonDiff(
                      totalVolumes[id],
                      totalVolumes[comparisonID]
                    )}
                  </p>
                )}
              </div>
            </div>
          )
        })}
    </section>
  )
}

export default function Overview() {
  const { selectedProducts } = useOptimizationContext()
  const { aggregatedVolume, aggregatedFinancial } = useOverviewData()
  const { countryCode, t } = useLocalization()

  const volumeKeys = aggregatedVolume?.[0]
    ?.map((item) => item.type_volume)
    .filter((item, index, array) => array.indexOf(item) === index)

  const financialKeys = aggregatedFinancial?.[0]
    ?.map((item) => item.type_financial)
    .filter((item, index, array) => array.indexOf(item) === index)

  return (
    <>
      {selectedProducts.length === 1 ? (
        <OutputScreen>
          <div className='grid grid-cols-2 mt-2 gap-4 mb-6 w-[66.3%]'>
            <div className='flex flex-col'>
              <h3 className='text-xl font-bold text-grey-700 mt-[30px] mb-[14px]'>
                {t('optimizationScreen.overview.volumeTitle')}
              </h3>
              <GraphsCard
                key={1}
                type='type_volume'
                title={capitalize(
                  aggregatedVolume[0]?.[0]?.product_ui_type || ''
                )}
                color={getDefaultProductColor}
                items={aggregatedVolume[0] || []}
                keys={volumeKeys}
              />
            </div>
            <div className='flex flex-col'>
              <h3 className='text-xl font-bold text-grey-700 mt-8 mb-3'>
                {t('optimizationScreen.overview.financialOverview')}
              </h3>
              <GraphsCard
                key={2}
                type='type_financial'
                title={capitalize(
                  aggregatedFinancial[0][0].product_ui_type || ''
                )}
                color={getDefaultProductColor}
                items={aggregatedFinancial[0]}
                keys={financialKeys}
              />
            </div>
          </div>
        </OutputScreen>
      ) : (
        <>
          <OutputScreen>
            <Slider
              title={t('optimizationScreen.overview.volumeTitle')}
              items={aggregatedVolume.map((product, index) => {
                return (
                  <GraphsCard
                    key={index}
                    type='type_volume'
                    title={
                      product[0].product_ui_type === 'total'
                        ? t('optimizationScreen.overview.overall')
                        : product[0].product_ui_type === 'meal'
                        ? t('optimizationScreen.overview.mealProduction')
                        : product[0].product_ui_type === 'oil'
                        ? t('optimizationScreen.overview.oilProduction')
                        : capitalize(product[0].product_ui_type)
                    }
                    color={getProductColor(true, product[0].product_ui_type)}
                    items={product}
                    keys={volumeKeys}
                  />
                )
              })}
            />
          </OutputScreen>
          <OutputScreen>
            <Slider
              title={
                countryCode === 'ARG'
                  ? t('optimizationScreen.overview.financialArg')
                  : t('optimizationScreen.overview.financialBr')
              }
              items={aggregatedFinancial.map((product, index) => {
                return (
                  <GraphsCard
                    key={index}
                    type='type_financial'
                    title={
                      aggregatedFinancial[0] === product
                        ? t('optimizationScreen.overview.overall')
                        : capitalize(product[0].product_ui_type || '')
                    }
                    color={getProductColor(true, product[0].product_ui_type)}
                    items={product}
                    keys={financialKeys}
                  />
                )
              })}
            />
          </OutputScreen>
        </>
      )}
    </>
  )
}
