import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'
import { Leafs, ShipIcon, SiloIcon, CrushingPlantIcon } from 'components/Icons'
import { TruckIcon, ChartBarIcon } from '@heroicons/react/24/outline'
import Accordion, {
  AccordionItem,
  AccordionHeader,
  AccordionBody
} from 'components/Accordion/Accordion'
import { useScenariosContext } from 'providers/ScenariosProvider'
import classNames from 'classnames'
import { useLocalization } from 'providers/LocalizationProvider'
import { useMemo } from 'react'

const ScenariosSidebar = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { currentSection } = useScenariosContext()
  const { countryCode, t } = useLocalization()
  const baseContentPath = 'scenarios.pages.'

  const accordionItems = useMemo(
    () => [
      {
        key: 'origination',
        title: t(`${baseContentPath}origination.title`),
        Icon: Leafs,
        items: [
          {
            key: 'volume',
            label: t(`${baseContentPath}origination.originationVolume.label`),
            includeIn: ['BR', 'ARG']
          }
        ]
      },
      {
        key: 'silos',
        title: t(`${baseContentPath}silos.title`),
        Icon: SiloIcon,
        items: [
          {
            key: 'inbound_outbound_storage_capacity',
            label: t(`${baseContentPath}silos.inboundOutbound.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'assets',
            label: t(`${baseContentPath}silos.assets.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'products',
            label: t(`${baseContentPath}silos.products.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'silo_reception_plan',
            label: t(`${baseContentPath}silos.siloReceptionPlan.label`),
            includeIn: ['BR']
          },
          {
            key: 'reduction_of_capacity',
            label: t(`${baseContentPath}silos.reductionOfCapacity.label`),
            includeIn: ['ARG']
          }
        ]
      },
      {
        key: 'crush_plants',
        title: t(`${baseContentPath}crushing.title`),
        Icon: CrushingPlantIcon,
        items: [
          {
            key: 'inbound_outbound_storage_capacity',
            label: t(`${baseContentPath}crushing.inboundOutbound.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'maintenance_plan',
            label: t(`${baseContentPath}crushing.maintenancePlan.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'soybean_meal_internal_demand',
            label: t(
              `${baseContentPath}crushing.soybeanMealInternalDemand.label`
            ),
            includeIn: ['BR']
          },
          {
            key: 'alcance_plan',
            label: t(`${baseContentPath}crushing.alcancePlan.label`),
            includeIn: ['BR']
          },
          {
            key: 'crushing_plan',
            label: t(`${baseContentPath}crushing.crushingPlan.label`),
            includeIn: ['ARG']
          },
          {
            key: 'oil_export_demand',
            label: t(`${baseContentPath}crushing.oilExportDemand.label`),
            includeIn: ['ARG']
          },
          {
            key: 'internal_demand',
            label: t(`${baseContentPath}crushing.internalDemand.label`),
            includeIn: ['ARG']
          }
        ]
      },
      {
        key: 'transport',
        title: t(`${baseContentPath}transport.title`),
        Icon: TruckIcon,
        items: [
          {
            key: 'transshipment_inbound_outbound_storage_capacity',
            label: t(`${baseContentPath}transport.tsInboundOutbound.label`),
            includeIn: ['BR']
          },
          {
            key: 'contracts',
            label: t(`${baseContentPath}transport.contracts.label`),
            includeIn: ['BR']
          },
          {
            key: 'inbound_outbound_capacity_arg',
            label: t(`${baseContentPath}transport.truckCapacity.label`),
            includeIn: ['ARG']
          },
          {
            key: 'deals_contracts',
            label: t(`${baseContentPath}transport.dealsAndContracts.label`),
            includeIn: ['ARG']
          }
        ]
      },
      {
        key: 'exports',
        title: t(`${baseContentPath}exports.title`),
        Icon: ShipIcon,
        items: [
          {
            key: 'inbound_outbound_storage_capacity',
            label: t(`${baseContentPath}exports.inboundOutbound.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'terminal_costs',
            label: t(`${baseContentPath}exports.terminalCost.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'demand',
            label: t(`${baseContentPath}exports.demand.label`),
            includeIn: ['BR']
          },
          {
            key: 'export_plan',
            label: t(`${baseContentPath}exports.exportPlan.label`),
            includeIn: ['ARG']
          }
        ]
      },
      {
        key: 'financial',
        title: t(`${baseContentPath}financial.title`),
        Icon: ChartBarIcon,
        items: [
          {
            key: 'market_structure',
            label: t(`${baseContentPath}financial.marketStructure.label`),
            includeIn: ['BR']
          },
          {
            key: 'fx',
            label: t(`${baseContentPath}financial.fx.label`),
            includeIn: ['ARG']
          },
          {
            key: 'crush_pickups',
            label: t(`${baseContentPath}financial.crushPickups.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'interest_rates',
            label: t(`${baseContentPath}financial.interestRates.label`),
            includeIn: ['BR', 'ARG']
          },
          {
            key: 'export_prices',
            label: t(`${baseContentPath}financial.exportPrices.label`),
            includeIn: ['ARG']
          }
        ]
      }
    ],
    [t]
  )

  const shouldShow = (includeIn) => {
    return includeIn.includes(countryCode)
  }

  return (
    <div className='w-full text-blue-700'>
      <button
        className='flex w-full text-grey-500'
        onClick={(e) => {
          e.preventDefault()
          navigate(-1)
        }}
      >
        <ChevronLeftIcon className='w-5 h-6' />
        <span>{t(`${baseContentPath}sidebar.backBtnLabel`)}</span>
      </button>
      <h1 className='text-2xl font-bold my-5'>
        {t(`${baseContentPath}sidebar.createNewScenarioLabel`)}
      </h1>
      <p className='text-sm text-grey-400'>
        {t(`${baseContentPath}sidebar.selectAScenario`)}
      </p>
      <Accordion
        defaultValue={currentSection?.split('-')?.[0] || undefined}
        className='flex flex-col gap-3 mt-6 mb-10'
      >
        {accordionItems.map(({ key: accordionItemKey, title, Icon, items }) => (
          <AccordionItem
            className='border border-transparent !shadow-none data-[state=open]:border-blue-400'
            value={accordionItemKey}
            key={accordionItemKey}
          >
            <AccordionHeader className='py-3 px-4'>
              <div className='w-full flex items-center gap-4'>
                <span className='bg-blue-100 rounded-md w-8 h-8 p-1 flex items-center justify-center'>
                  <Icon className='text-blue-400' />
                </span>
                <h2 className='font-bold text-base text-start'>{title}</h2>
              </div>
            </AccordionHeader>
            <AccordionBody>
              {items
                .filter(({ includeIn }) => shouldShow(includeIn))
                .map(({ label, key }) => (
                  <button
                    key={key}
                    onClick={() =>
                      navigate(
                        `/execution/${id}/scenario?section=${accordionItemKey}-${key}`
                      )
                    }
                    className={classNames(
                      'w-full box-border pl-11 py-3.5 pr-3.5 border-t border-gray-200 text-left hover:bg-blue-100',
                      {
                        'bg-blue-100':
                          currentSection === `${accordionItemKey}-${key}`
                      }
                    )}
                  >
                    <p className='font-bold text-sm'>{label}</p>
                  </button>
                ))}
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default ScenariosSidebar
