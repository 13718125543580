import { useCallback } from 'react'
import GridTable from 'components/Tables/GridTable'
import classNames from 'classnames'
import { ControlledInput } from 'components/Inputs'
import { useLocalization } from 'providers/LocalizationProvider'

export default function NewParamsTable({
  rows = [],
  control,
  readOnly = [],
  columnsToValidate,
  index,
  rules
}) {
  const { t } = useLocalization()

  const headers = rows.length
    ? Object.keys(rows[0]).map((c) =>
        t(
          `assetConfigurationScreen.parameters.tableHeaders.${c
            .toLowerCase()
            .trim()}`
        )
      )
    : []

  const keyMapper = useCallback((row) => row.id ?? Object.values(row)[0], [])

  return (
    <GridTable
      data={rows}
      keyMapper={keyMapper}
      rowContext={{
        readOnly,
        control,
        index,
        columnsToValidate,
        rules
      }}
      columnMappers={ColumnMapper}
      columnHeaders={headers}
      borders={true}
      roundCorners='all'
      cellClassNames='py-4 pl-4 pr-3 text-sm font-medium text-gray-500 bg-white'
      headerClassNames='text-grey-500 py-3.5 pl-6 px-4 text-left text-sm font-semibold text-gray-900 bg-grey-100 text-center capitalize border-b-0'
    />
  )
}

const ColumnMapper = ({
  item,
  ctx: { readOnly, control, index, columnsToValidate, rules },
  columnIndex,
  rowIdx
}) => {
  const column = Object.keys(item)[columnIndex]
  const name = `tables.${index}.rows.${rowIdx}.${column}`
  const isReadOnly = readOnly.includes(column)

  return (
    <div className='flex justify-center'>
      <ControlledInput
        type='text'
        name={name}
        readOnly={isReadOnly}
        controllerProps={{
          valueAsNumber: true,
          control,
          name,
          rules: columnsToValidate.includes(column) && rules
        }}
        className={classNames(
          'flex text-sm',
          'rounded-md fit-content',
          'text-center mx-auto',
          {
            '!shadow-none focus:outline-none read-only:border-0 read-only:ring-0 focus:border-0 focus:!ring-0 focus:!ring-offset-0':
              isReadOnly
          }
        )}
      />
    </div>
  )
}
