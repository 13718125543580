import ModelDetailsCard from 'components/Cards/ModelDetailsCard'
import { useOptDataContext } from 'providers/OptDataProvider'
import { useLocalization } from 'providers/LocalizationProvider'

function LastModelRun() {
  const { t } = useLocalization()
  const { optData } = useOptDataContext()
  const successRuns = optData
    .filter((data) => data.status.toLowerCase() === 'success')
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })

  return (
    <>
      {successRuns[0] && (
        <div className='mt-20'>
          <div className='flex justify-between items-center'>
            <p className='text-2xl font-bold leading-loose text-gray-900'>
              {t('homeScreen.lastModelRun.sectionTitle')}
            </p>
          </div>
          <div className='mt-2 w-1/2'>
            <ModelDetailsCard model={successRuns[0]} />
          </div>
        </div>
      )}
    </>
  )
}

export default LastModelRun
