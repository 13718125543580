const SingleVolumeCardSkeleton = () => {
  return (
    <div className='p-4 border border-gray-200 rounded-lg shadow animate-pulse md:p-6 w-full h-full'>
      <div className='h-2.5 bg-gray-200 rounded-full w-32 mb-2.5'></div>
      <div className='w-48 h-2 mb-10 bg-gray-200 rounded-full'></div>
      <div className='flex items-baseline mt-4 space-x-6'>
        <div className='w-full bg-gray-200 rounded-t-lg h-32'></div>
        <div className='w-full h-20 bg-gray-200 rounded-t-lg'></div>
        <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
        <div className='w-full h-32 bg-gray-200 rounded-t-lg'></div>
        <div className='w-full bg-gray-200 rounded-t-lg h-24'></div>
        <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
        <div className='w-full bg-gray-200 rounded-t-lg h-12'></div>
        <div className='w-full bg-gray-200 rounded-t-lg h-32'></div>
        <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
      </div>
      <span className='sr-only'>Loading...</span>
    </div>
  )
}
export default SingleVolumeCardSkeleton
