import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'

export const Contracts = () => {
  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.transport.contracts.'
  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}sectionTitle`)}
      sectionDescription={t(`${defaultContentPath}sectionDescription`)}
      title={t(`${defaultContentPath}title`)}
      templateType='TEMPLATES_BR_TAKE_OR_PAY'
      params={{
        rail_transportation_plan: false
      }}
      modalTitle={t(`${defaultContentPath}modalTitle`)}
    />
  )
}
