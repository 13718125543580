import { useOptimizationContext } from 'providers/OptimizationProvider'
import { capitalize } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { createMonthObject } from 'screens/Optimization/utils'
import {
  getProductColor,
  NO_DATA_LABEL
} from 'screens/Optimization/utils/output'

const useOutputCrushingProductDetailsOutbound = (data, selectedProduct) => {
  const {
    comparisonID,
    rawProducts,
    executionData: { start_date, end_date }
  } = useOptimizationContext()
  const { id: baseModelId } = useParams()

  const getMonthsWithDefaultData = useCallback(
    ({ executionData }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData
      })
    },
    [baseModelId, comparisonID, start_date, end_date]
  )

  const productVolumesByPlantChartData = useCallback(
    (crush) => {
      const filteredProduct = data.volumes.filter((product) => {
        return product.crush === crush
      })

      const result = filteredProduct.reduce(
        (prevValues, { month, execution_id, product_ui_type, volume }) => ({
          ...prevValues,
          [month]: {
            ...prevValues[month],
            [execution_id]: calcExecutionIdData({
              currentData: prevValues[month][execution_id],
              product_ui_type,
              volume
            })
          }
        }),
        getMonthsWithDefaultData({ executionData: NO_DATA_LABEL })
      )

      return Object.entries(result).map(([month, volumes]) => ({
        month,
        volumes
      }))
    },
    [data, getMonthsWithDefaultData]
  )

  const calcExecutionIdData = ({ currentData, product, volume }) => {
    if (typeof currentData === 'object') {
      return { ...currentData, [product]: volume }
    }
    return currentData === NO_DATA_LABEL ? volume : currentData + volume
  }

  const productVolumesChartData = useMemo(() => {
    const result = data.volumes.reduce(
      (prevValues, { month, execution_id, product, volume }) => ({
        ...prevValues,
        [month]: {
          ...prevValues[month],
          [execution_id]: calcExecutionIdData({
            currentData: prevValues[month][execution_id],
            product,
            volume
          })
        }
      }),
      getMonthsWithDefaultData({ executionData: NO_DATA_LABEL })
    )
    return Object.entries(result).map(([month, volumes]) => ({
      month,
      volumes
    }))
  }, [data, getMonthsWithDefaultData])

  const getPossiblePaths = useCallback(
    () => [baseModelId, comparisonID].map((id) => `volumes.${id}`).flat(),
    [baseModelId, comparisonID]
  )

  const productVolumeBars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId ? 'Base Model' : 'Comparison'
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths]
  )

  const productsSelectOptions = useMemo(
    () =>
      rawProducts.map(({ product }) => ({
        label: capitalize(product),
        value: product
      })),
    [rawProducts]
  )

  const productByPlants = useMemo(() => {
    const plantsGroup = data.volumes.reduce((acc, product) => {
      const productVolumes = productVolumesByPlantChartData(product.crush)

      let total = 0
      for (const product of productVolumes) {
        if (product.volumes[baseModelId] !== NO_DATA_LABEL) {
          total += product.volumes[baseModelId]
        }
      }

      const newPlant = {
        crush: product.crush,
        data: productVolumes,
        total
      }
      return { ...acc, [product.asset_name]: { ...newPlant } }
    }, {})
    return Object.entries(plantsGroup).map(([asset_name, plantData]) => ({
      asset_name,
      ...plantData
    }))
  }, [data, baseModelId, productVolumesByPlantChartData])

  return {
    productVolumesChartData,
    productVolumeBars,
    productsSelectOptions,
    productByPlants,
    loading: false
  }
}

export default useOutputCrushingProductDetailsOutbound
