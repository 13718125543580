import { useDropzone } from 'react-dropzone'
import { ExcelIllustration } from 'components/Icons'
import classNames from 'classnames'
import { useLocalization } from 'providers/LocalizationProvider'
import TextWithComponents from 'components/Typography/TextWithComponents'

const DropZone = ({ onDrop, className }) => {
  const { t } = useLocalization()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      '*': ['.xlsx', '.xls', '.csv']
    }
  })

  const rootKey = 'newOptimizationScreen.sections.files.batchDropzone'

  return (
    <div
      {...getRootProps()}
      className={classNames(
        'bg-background border-dotted border-2 rounded-md flex flex-col items-center justify-center p-4 pt-6 cursor-pointer',
        className
      )}
    >
      <input {...getInputProps()} />
      <ExcelIllustration />
      <div className='mt-4'>
        {isDragActive ? (
          <p className='text-lg text-center'>{t(`${rootKey}.dragActive`)}</p>
        ) : (
          <p className='text-lg text-center'>
            <TextWithComponents
              text={t(`${rootKey}.dragHint`)}
              substitutions={[{ className: 'font-bold' }]}
            />
          </p>
        )}

        <p className='text-grey-400 text-lg'>
          <a className='text-blue-400'>{t(`${rootKey}.bulkHint`)}</a>
        </p>
      </div>
    </div>
  )
}

export default DropZone
