import classNames from 'classnames'
import { TableCell } from './TableCell'
import { useTableContext } from './TableContext'

export const TableRow = ({
  rowData,
  rowIdx,
  rowContext,
  mapSubRowsRecursive,
  checkableRows,
  cellClassNames,
  borders,
  clickHandler,
  keyMapper,
  rowClickMode,
  renderExpandedRow,
  expandArrowPosition
}) => {
  const { isRowExpanded, mappers } = useTableContext()

  const context =
    (typeof rowContext === 'function'
      ? rowContext(rowData, rowIdx)
      : rowContext) ?? {}

  const rowKey = keyMapper?.(rowData) || rowIdx

  const rowClickable =
    typeof context.onRowClick === 'function' ||
    (rowClickMode === 'select' && checkableRows) ||
    (rowClickMode === 'expand' && context.expandable !== false)

  const rowClickHandler =
    typeof context.onRowClick === 'function' ? context.onRowClick : clickHandler

  const subRowClickHandler =
    typeof context.onSubRowClick === 'function'
      ? context.onSubRowClick
      : undefined

  const expandedData =
    renderExpandedRow &&
    isRowExpanded(rowKey) &&
    renderExpandedRow(rowData, context)

  return (
    <>
      {[
        ...mappers.map((mapper, columnIdx) => {
          let mapperClassName = typeof mapper === 'object' && mapper.className
          if (typeof mapperClassName === 'function') {
            mapperClassName = mapperClassName(rowData)
          }

          return (
            <TableCell
              key={columnIdx}
              bodyComponent={
                (typeof mapper === 'object' && mapper.content) || mapper
              }
              rowKey={rowKey}
              columnIdx={columnIdx}
              rowIdx={rowIdx}
              cellClassNames={cellClassNames}
              mapperClassName={mapperClassName}
              rowClickable={rowClickable}
              context={context}
              rowData={rowData}
              checkableRows={checkableRows}
              onClick={() => rowClickHandler(rowData, context, rowKey)}
              renderExpandedRow={renderExpandedRow}
              expandArrowPosition={expandArrowPosition}
            />
          )
        }),
        ...((Array.isArray(expandedData)
          ? mapSubRowsRecursive({
              rows: expandedData,
              rowKey,
              subRowClickHandler,
              rowData,
              context
            })
          : typeof expandedData === 'object' && [
              <div
                key={`${rowKey}-expander`}
                className={classNames(
                  'flex items-center pl-4',
                  cellClassNames,
                  borders && 'border-l border-r border-b'
                )}
                style={{
                  gridColumn: `1 / span ${mappers.length}`
                }}
              >
                {expandedData}
              </div>
            ]) || [])
      ]}
    </>
  )
}
