import TableChart from 'components/Graphs/TableChart'
import {
  NO_DATA_LABEL,
  kgToTons,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import SectionBox from 'components/Container/SectionBox'
import useSummaryItems from 'hooks/useSummaryItems'
import useGraph from 'hooks/useGraph'
import { useLocalization } from 'providers/LocalizationProvider'

const yAxisFormatter = (labelValue) => `${labelValue / kgToTons} kTons`

const labelFormatter = (labelValue) =>
  labelValue === NO_DATA_LABEL
    ? labelValue
    : `${(labelValue / kgToTons).toFixed(0)} kTons`

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

function AllPorts({ data }) {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { summaryItems } = useSummaryItems({ data, volumeKey: 'volume' })
  const { bars, volumeGraphData } = useGraph({
    data,
    calcExecutionIdData
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: data,
    filterProducts: true
  })

  return (
    <SectionBox
      pageLangKey='optimizationScreen.pageNames.export'
      hasData={data.length !== 0}
    >
      <TableChart
        title={ t('optimizationScreen.export.allPortsTitle') }
        summaryItems={summaryItems}
        data={volumeGraphData}
        tooltipFormatter={labelFormatter}
        yAxisFormatter={yAxisFormatter}
        xAxisProps={{
          dataKey: 'month',
          tickFormatter: transformDateToShortMonth
        }}
        bars={bars}
        tableData={heatmapData}
        tableProps={{
          referenceProps: { title: t('optimizationScreen.comparison'), objKey: comparisonID },
          comparedProps: { title: t('optimizationScreen.baseModel'), objKey: baseModelId },
          groupLabelFormatter: transformDateToShortMonth,
          columnValueFormatter: (x) =>
            x
              ? x === NO_DATA_LABEL
                ? x
                : `${(x / kgToTons).toFixed(2)}`
              : NO_DATA_LABEL
        }}
      />
    </SectionBox>
  )
}

export default AllPorts
