import { useCallback, useMemo } from 'react'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { createMonthObject } from 'screens/Optimization/utils'
import { NO_DATA_LABEL } from 'screens/Optimization/utils'

const useHeatmapDataTransformer = ({
  valueKey,
  data,
  rowLevelKeys: rLK = [],
  aggregatorFn,
  filterProducts = false
}) => {
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    selectedProducts,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const rowLevelKeys = useMemo(() => {
    return filterProducts && selectedProducts.length > 0 && rLK.length === 0
      ? ['product_ui_type']
      : rLK
  }, [filterProducts, selectedProducts, rLK])

  const emptyMonthObject = useMemo(
    () =>
      createMonthObject(start_date, end_date, {
        [comparisonID]: NO_DATA_LABEL,
        [baseModelId]: NO_DATA_LABEL
      }),
    [start_date, end_date, comparisonID, baseModelId]
  )

  const defaultAggregatorFn = useCallback(
    (current, { month, execution_id, ...item }) => {
      if (!current) {
        return {
          ...emptyMonthObject,
          [month]: {
            ...emptyMonthObject[month],
            [execution_id]: item[valueKey]
          }
        }
      }
      return {
        ...current,
        [month]: {
          ...current[month],
          [execution_id]:
            current[month][execution_id] === NO_DATA_LABEL
              ? item[valueKey]
              : current[month][execution_id] + item[valueKey]
        }
      }
    },
    [valueKey, emptyMonthObject]
  )

  const selectedProductsDefaultStructureForReduce = useMemo(() => {
    return selectedProducts.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: {
          title: cur,
          data: createMonthObject(start_date, end_date, {
            [comparisonID]: NO_DATA_LABEL,
            [baseModelId]: NO_DATA_LABEL
          })
        }
      }),
      {}
    )
  }, [baseModelId, comparisonID, start_date, end_date, selectedProducts])

  const heatmapDataTransformer = useCallback(
    (data, aggregationLevels, aggregatorFunc = defaultAggregatorFn) => {
      if (aggregationLevels.length === 0 && rowLevelKeys.length > 0) {
        return undefined
      }

      const { dataKey, titleKey, title } =
        typeof aggregationLevels[0] === 'object'
          ? aggregationLevels[0]
          : { dataKey: aggregationLevels[0] }

      const dataFilterHelperObj = {}

      const res = data.reduce(
        (acc, cur) => {
          if (dataFilterHelperObj[cur[dataKey]]) {
            dataFilterHelperObj[cur[dataKey]].push(cur)
          } else {
            dataFilterHelperObj[cur[dataKey]] = [cur]
          }
          return {
            ...acc,
            ...(rowLevelKeys.length === 0
              ? {
                  data: aggregatorFunc(acc.data, cur)
                }
              : {
                  [cur[dataKey]]: {
                    title: title ?? cur[titleKey ?? dataKey],
                    dataKeyValue: cur[dataKey],
                    data: aggregatorFunc(acc[cur[dataKey]]?.data, cur)
                  }
                })
          }
        },
        dataKey === 'product_ui_type'
          ? { ...selectedProductsDefaultStructureForReduce }
          : {}
      )

      if (rowLevelKeys.length === 0) {
        return [res]
      }

      return Object.values(res).map((v) => ({
        ...v,
        subRows:
          aggregationLevels.length > 1
            ? heatmapDataTransformer(
                dataFilterHelperObj[v.dataKeyValue],
                aggregationLevels.slice(1),
                aggregatorFunc
              )
            : undefined
      }))
    },
    [
      defaultAggregatorFn,
      rowLevelKeys,
      selectedProductsDefaultStructureForReduce
    ]
  )

  const filteredData = useMemo(() => {
    return selectedProducts.length > 0 && filterProducts
      ? data.filter((i) => selectedProducts.includes(i.product_ui_type))
      : data
  }, [data, selectedProducts, filterProducts])

  const heatmapData = useMemo(
    () => heatmapDataTransformer(filteredData, rowLevelKeys, aggregatorFn),
    [heatmapDataTransformer, filteredData, rowLevelKeys, aggregatorFn]
  )

  return { heatmapData }
}

export default useHeatmapDataTransformer
