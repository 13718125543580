import Checkbox from './Checkbox'
import { useController } from 'react-hook-form'

const ControlledCheckbox = ({
  controllerProps,
  onChange,
  disabled,
  valueKey,
  ...props
}) => {
  const { field } = useController(controllerProps)

  return (
    <Checkbox
      {...props}
      isChecked={field[valueKey || 'isChecked']}
      onChange={(value) => {
        field.onChange(value)
        onChange && onChange(value)
      }}
      disabled={disabled}
    />
  )
}

export default ControlledCheckbox
