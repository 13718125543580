import RangeSlider from 'components/Inputs/RangeSlider/RangeSlider'
import { useController } from 'react-hook-form'

const ControlledRangeSlider = ({ controllerProps, onChange, disabled, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps)

  return (
    <RangeSlider
      {...props}
      value={field.value}
      onChange={(value) => {
        field.onChange(value)
        onChange && onChange(value)
      }}
      error={error && error.message}
      disabled={disabled}
    />
  )
}

export default ControlledRangeSlider
