import * as FileSaver from 'file-saver'
import { stripDataTypePrefix } from 'utils/string'
import * as XLSX from 'xlsx'

export const DOWNLOADED_EXCEL_MIME =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

export const getCurrentCountryCode = () => {
  try {
    const cc = window.localStorage.getItem('countryCode') || 'BR'
    return cc
  } catch (e) {
    console.error('Failed to get country code from storge')
    return 'BR'
  }
}

// default config for swr
export const swrConfig = {
  refreshInterval: 0,
  revalidateOnFocus: false,
  revalidateOnMount: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false
}

/**
 * Method to convert date to time ago
 * @param {*} date
 * @returns
 */
export function timeSince(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years ago'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months ago'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days ago'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes ago'
  }
  return Math.floor(seconds) + ' seconds ago'
}

export function downloadDataAsXlsx(csvData, fileName) {
  const keys = Object.keys(csvData)
  const wb = XLSX.utils.book_new()

  for (let i = 0; i < keys.length; i++) {
    const ws = XLSX.utils.json_to_sheet(csvData[keys[i]])
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      keys[i].replace('OUTPUT_', ' ').replace('INPUTS_', '')
    )
  }

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: DOWNLOADED_EXCEL_MIME })
  FileSaver.saveAs(data, fileName)
}

/**
 *
 * @param {*} str1
 * @param {*} str2
 * @returns
 */
export function wordDistance(str1 = '', str2 = '') {
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null))
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
      )
    }
  }
  return track[str2.length][str1.length]
}

/**
 * Resolves an object path key to a value
 *
 * @param {object} obj The object to look for the key on
 * @param {string} key The key to resolve like "foo.bar.x.y.z"
 * @returns The value for the given path (key) or undefined if it's not resolvable
 */
export const resolveObjectKey = (obj, key) =>
  key?.split('.')?.reduce((acc, currKey) => acc && acc[currKey], obj)

export const wait = (miliseconds) =>
  new Promise((resolve) => setTimeout(resolve, miliseconds))

export const getFileNameFromTemplateType = (templateType) => {
  const exceptions = ['WHATIF_ARG_ACOPIOS_MASTER', 'WHATIF_BR_SILOS']
  const stripped = stripDataTypePrefix(templateType).toLowerCase().trim()

  if (templateType.includes('WHATIF') && !exceptions.includes(templateType)) {
    return `whatif_${stripped}`
  }
  return stripped
}

export const areAllRangesIdentical = (values) => values.every(([min, max]) =>
  min === values[0][0] && max === values[0][1]
);