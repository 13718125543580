import WhatIfCard from 'components/Cards/WhatIf'
import { SectionContainer } from 'screens/Scenarios/Main/sections/SectionContainer'

export const MultipleFileUploadSection = ({
  sectionTitle,
  sectionDescription,
  onDownload,
  onUpload,
  items,
  progress
}) => (
  <SectionContainer
    sectionTitle={sectionTitle}
    sectionDescription={sectionDescription}
  >
    <div className='flex flex-col gap-4'>
      {items.map((item, index) => (
        <WhatIfCard
          key={`${item.title}-${index}`}
          title={item.title}
          description={item.description}
          onUpload={() => onUpload(item.fileType)}
          onDownload={() => onDownload(item.fileType)}
          progress={progress?.[item.fileType]}
        />
      ))}
    </div>
  </SectionContainer>
)
