import AllPorts from './AllPorts'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useSWR from 'swr'
import { useCallback, useMemo, useState } from 'react'
import { uniq } from 'ramda'
import Select from 'components/Inputs/Select'
import { formatter } from 'utils/string'
import MultiSelectComponent from 'components/Inputs/Select/MultiSelect'
import VolumeAndUtilization from './VolumeAndUtilization'
import useSubProductSelector from 'hooks/useSubProductSelector'
import useProductFilter from 'hooks/useProductFilter'
import { PortDetails } from './Details/PortDetails'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

export default function Export() {
  const { id: baseModelId } = useParams()
  const [selectedAsset, setSelectedAsset] = useState(undefined)
  const [selectedPort, setSelectedPort] = useState(undefined)
  const { options, onChange, selectedSubProducts } = useSubProductSelector()
  const { comparisonID, selectedProducts, selectedProductTypes } =
    useOptimizationContext()
  const { t } = useLocalization()

  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/export`,
    {
      bestEstimateId: comparisonID
    }
  ])

  const { data: terminals } = useSWR([
    `/execution/outputs/${baseModelId}/terminals`,
    {
      bestEstimateId: comparisonID
    }
  ])
  const { filteredData } = useProductFilter({
    data: data.detailedVolumes,
    selectedSubProducts
  })

  const terminalOptionsForPort = useMemo(
    () =>
      terminals
        .filter((x) => x.port === selectedPort)
        .map(({ terminal }) => ({
          label: formatter(terminal.replace(/^[^_]+_/, '')),
          value: terminal
        })),
    [terminals, selectedPort]
  )

  const handlePortChange = useCallback(
    (port) => {
      if (!port) {
        return
      }
      const terminalItem = terminals.find((x) => x.port === port)
      setSelectedPort(port)
      setSelectedAsset(terminalItem?.terminal)
      if (!terminalItem) {
        setSelectedPort(terminals[0].port)
      } else if (terminalItem.port !== selectedPort) {
        setSelectedPort(terminalItem.port)
      }
    },
    [terminals, selectedPort]
  )

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'outbound_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  if (selectedAsset) {
    return (
      <OutputScreen
        title={ t('optimizationScreen.export.main.detailsForPort') }
        titleExtra={
          <>
            <Select
              variant='underlined'
              options={uniq(terminals.map((x) => x.port)).map((port) => ({
                label: formatter(port),
                value: port
              }))}
              value={selectedPort}
              onValueChange={handlePortChange}
            />
            <span className='text-grey-400'>{ t('optimizationScreen.export.main.showing') }</span>
            {terminalOptionsForPort.length ? (
              <Select
                variant='underlined'
                value={selectedAsset}
                onValueChange={setSelectedAsset}
                options={terminalOptionsForPort}
              />
            ) : (
              <span className='text-lg font-bold'>
                {terminalOptionsForPort[0].label}
              </span>
            )}
          </>
        }
        crumbs={[
          {
            onClick: () => setSelectedAsset(undefined),
            label: t('optimizationScreen.export.main.export')
          },
          {
            label:  t('optimizationScreen.export.main.portDetails')
          }
        ]}
      >
        <PortDetails
          portAsset={selectedAsset}
          displayedProducts={selectedSubProducts}
          capacityKey={capacityKey}
        />
      </OutputScreen>
    )
  }

  return (
    <OutputScreen
      title={ t('optimizationScreen.export.main.export') }
      titleExtra={
        <>
          <span className='text-grey-400'>{ t('optimizationScreen.export.main.for') }</span>
          {options.length === 0 ? (
            <span className='text-lg font-bold'>{ t('optimizationScreen.export.main.allProducts') }</span>
          ) : (
            <MultiSelectComponent
              variant='underlined'
              options={options}
              placeholder={ t('optimizationScreen.export.main.filter') }
              onChange={onChange}
              disabled={selectedProducts.length === 0}
            />
          )}
        </>
      }
    >
      <AllPorts data={filteredData} />
      <VolumeAndUtilization
        data={filteredData}
        onPortSelected={handlePortChange}
        capacityKey={capacityKey}
      />
    </OutputScreen>
  )
}
