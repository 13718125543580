import BRExport from './BR'
import ARGExport from './ARG'
import { useLocalization } from 'providers/LocalizationProvider'

const Export = () => {
  const { countryCode } = useLocalization()
  return (
    <>
      {countryCode === 'BR' && <BRExport />}
      {countryCode === 'ARG' && <ARGExport />}
    </>
  )
}

export default Export
