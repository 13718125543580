import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import MainHeader from '../Header'
import Footer from '../Footer'
import AdvancedSettings from './AdvancedSettings'
import RadioComponent from './RadioComponent'
import ControlledRadioGroup from 'components/Inputs/RadioGroup/ControlledRadioGroup'
import { BRAZIL_DEFAULT, ADVANCED_SETTINGS, ARGENTINA_DEFAULT } from 'constants'
import { useUserInputsContext } from 'providers/UserInputsProvider'
import { useLocalization } from 'providers/LocalizationProvider'

const Settings = () => {
  const { state } = useLocation()

  const { t } = useLocalization()
  const { control, errors, watch } = useUserInputsContext()
  const { countryCode } = useLocalization()

  const type = watch('type')
  const optimizationRun = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return ARGENTINA_DEFAULT
      default:
        return BRAZIL_DEFAULT
    }
  }, [countryCode])

  const typeOptions = [
    {
      value: optimizationRun,
      label: t(
        'newOptimizationScreen.sections.settings.runTypes.optimized.label'
      ),
      description: t(
        'newOptimizationScreen.sections.settings.runTypes.optimized.description'
      )
    },
    {
      value: ADVANCED_SETTINGS,
      label: t(
        'newOptimizationScreen.sections.settings.runTypes.advanced.label'
      ),
      description: t(
        'newOptimizationScreen.sections.settings.runTypes.advanced.description'
      )
    }
  ]

  return (
    <div className='w-full relative bg-white'>
      <div className='h-[calc(100%-80px)] overflow-auto'>
        <div className='mr-auto pr-4 2xl:mr-20'>
          <div className='ml-6'>
            <MainHeader scenarioInfo={state} />

            <div className='mb-11'>
              {Array.from(
                new Set(Object.values(errors)?.map(({ message }) => message))
              ).map((message, index) => {
                return (
                  <p
                    key={`error-message-${index}`}
                    className='text-error-400 mb-2'
                  >
                    {message}
                  </p>
                )
              })}
            </div>

            <h3 className='font-bold mb-3 text-base'>
              {t('newOptimizationScreen.sections.settings.selectSettings')}
            </h3>
            <ControlledRadioGroup
              optionClassName='w-full'
              RadioBody={RadioComponent}
              controllerProps={{
                name: 'type',
                control
              }}
              options={typeOptions}
            />
            <div className='mt-12'>
              {type === ADVANCED_SETTINGS && <AdvancedSettings />}
            </div>
          </div>
        </div>
      </div>
      <div className='absolute bottom-0 py-5 bg-white border-t w-full'>
        <div className='flex mr-auto pr-4 2xl:mr-20 justify-end'>
          <Footer data={state.data} id={state.id} />
        </div>
      </div>
    </div>
  )
}
export default Settings
