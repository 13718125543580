import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/globals.css'
import './styles/styleguide.css'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import i18next from 'i18next'
import en from 'locales/en/translation'
import pt from 'locales/pt/translation'
import esp from 'locales/esp/translation'
import { initReactI18next } from 'react-i18next'
import { availableLanguages } from 'providers/LocalizationProvider'

const getDefaultLanguage = () => {
  try {
    const lang = window.localStorage.getItem('lang')

    if (!lang || !availableLanguages.includes(lang)) {
      return 'en'
    }

    return lang
  } catch (e) {
    console.error('error getting language from storage:', e)
    return 'en'
  }
}

const defaultLanguage = getDefaultLanguage()

i18next.use(initReactI18next).init({
  lng: defaultLanguage,
  resources: {
    en: {
      translation: en
    },
    pt: {
      translation: pt
    },

    esp: {
      translation: esp
    }
  },
  compatibilityJSON: 'v3',
  debug: false,
  interpolation: {
    escapeValue: false
  },
  fallbackLng: {
    pt: ['en'],
    esp: ['en']
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
