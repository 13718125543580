import { useOptimizationContext } from 'providers/OptimizationProvider'
import { capitalize, groupBy, sumBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  getMonthsForPeriod,
  getProductColor
} from 'screens/Optimization/utils/output'
import { createMonthObject } from 'screens/Optimization/utils'
import { formatNumber } from 'utils/number'
import { useLocalization } from 'providers/LocalizationProvider'

const useCrushingDetails = (data, capacityTypeToUse) => {
  const { id: baseModelId } = useParams()
  const { t } = useLocalization()
  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData
      })
    },
    [baseModelId, comparisonID, end_date, start_date]
  )

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const calcExecutionIdData = ({ currentData, product_ui_type, volume }) => {
    if (typeof currentData === 'object') {
      return { ...currentData, [product_ui_type]: volume }
    }
    return currentData === NO_DATA_LABEL ? volume : currentData + volume
  }

  const calcExecutionIdUtilData = ({
    currentData,
    product_ui_type,
    volume,
    capacity
  }) => {
    if (typeof currentData === 'object') {
      return { ...currentData, [product_ui_type]: { volume, capacity } }
    }
    return currentData === NO_DATA_LABEL
      ? { volume, capacity }
      : {
          volume: currentData.volume + volume,
          capacity: currentData.capacity + capacity
        }
  }

  const summaryItems = useMemo(() => {
    if (!data.volumes) {
      return {}
    }

    const volumeByProduct = data.volumes
      .filter((vol) =>
        selectedProducts.length > 0
          ? selectedProducts.includes(vol.product_ui_type)
          : true
      )
      .reduce((acc, curr) => {
        if (acc[curr.product_ui_type]) {
          if (acc[curr.product_ui_type][curr.execution_id]) {
            acc[curr.product_ui_type][curr.execution_id] += curr.volume
          } else {
            acc[curr.product_ui_type][curr.execution_id] = curr.volume
          }
        } else {
          acc[curr.product_ui_type] = {}
        }
        return acc
      }, {})

    return Object.keys(volumeByProduct)
      .filter((p) =>
        selectedProducts > 0 ? selectedProducts.includes(p) : true
      )
      .map((p) => ({
        title: capitalize(p),
        items: [
          {
            description: t('optimizationScreen.periodTotal'),
            value: formatNumber(volumeByProduct[p][baseModelId], {
              maximumFractionDigits: 0
            })
          },
          {
            description: t('optimizationScreen.comparisonPeriodTotal'),
            value: formatNumber(volumeByProduct[p][comparisonID], {
              maximumFractionDigits: 0
            })
          }
        ]
      }))
  }, [data.volumes, selectedProducts, t, baseModelId, comparisonID])

  const volumeChartData = useMemo(() => {
    const filteredData =
      selectedProducts.length > 0
        ? data.volumes.filter((v) =>
            selectedProducts.includes(v.product_ui_type)
          )
        : data.volumes

    const result = filteredData.reduce(
      (acc, { month, execution_id, product_ui_type, volume, ...record }) => {
        return {
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume
            }),
            capacity: record[capacityTypeToUse] ?? NO_DATA_LABEL
          }
        }
      },
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, curr) => ({ ...acc, [curr]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )

    return Object.entries(result).map(([month, { capacity, ...volumes }]) => ({
      month,
      volumes,
      capacity
    }))
  }, [data, selectedProducts, getMonthsWithDefaultData, capacityTypeToUse])

  const volumeChartBars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths, t]
  )

  const utilizationChartData = useMemo(() => {
    const filteredData =
      selectedProducts.length > 0
        ? data.volumes.filter((v) =>
            selectedProducts.includes(v.product_ui_type)
          )
        : data.volumes

    const result = filteredData.reduce(
      (acc, { month, execution_id, product_ui_type, volume, ...rest }) => {
        return {
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdUtilData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume,
              capacity: rest[capacityTypeToUse]
            })
          }
        }
      },
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, curr) => ({ ...acc, [curr]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )

    const mapData = (data) => {
      return Object.keys(data).reduce((acc, curr) => {
        const currentItem = data[curr]

        if (currentItem === NO_DATA_LABEL) {
          return { ...acc, [curr]: currentItem }
        }

        if (Object.keys(currentItem).includes('volume')) {
          return {
            ...acc,
            [curr]: Math.round(
              (currentItem.volume / currentItem.capacity) * 100
            )
          }
        }

        return {
          ...acc,
          [curr]: Object.keys(currentItem).reduce((acc, prod) => {
            return {
              ...acc,
              [prod]:
                currentItem[prod] === NO_DATA_LABEL
                  ? currentItem[prod]
                  : Math.round(
                      (currentItem[prod].volume / currentItem[prod].capacity) *
                        100
                    )
            }
          }, {})
        }
      }, {})
    }

    return Object.keys(result).map((month) => ({
      month,
      utilization: mapData(result[month])
    }))
  }, [
    selectedProducts,
    data.volumes,
    getMonthsWithDefaultData,
    capacityTypeToUse
  ])

  const getPossibleUtilPaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `utilization.${id}.${product}`)
            : `utilization.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const utilChartBars = useMemo(
    () =>
      getPossibleUtilPaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossibleUtilPaths, t]
  )

  const productCards = useMemo(() => {
    const filteredProducts = data.products.filter((p) =>
      selectedProducts.length > 0
        ? selectedProducts.includes(p.product_ui_type)
        : true
    )

    const groupedData = groupBy(
      filteredProducts,
      filteredProducts?.[0]?.product_name ? 'product_name' : 'product'
    )
    const result = {}

    for (const product in groupedData) {
      const optPeriod = getMonthsForPeriod(start_date, end_date)
      const months = groupBy(groupedData[product], 'month')
      const monthData = optPeriod.map((month) => ({
        month,
        volume: {
          [baseModelId]: months[month]
            ? sumBy(
                months[month].filter((m) => m.execution_id === baseModelId),
                'volume'
              )
            : NO_DATA_LABEL,
          [comparisonID]: months[month]
            ? sumBy(
                months[month].filter((m) => m.execution_id === comparisonID),
                'volume'
              )
            : NO_DATA_LABEL
        }
      }))

      result[product] = monthData
    }

    return result
  }, [data, selectedProducts, start_date, end_date, baseModelId, comparisonID])

  return {
    volumeChartData,
    volumeChartBars,
    summaryItems,
    utilizationChartData,
    utilChartBars,
    productCards
  }
}

export default useCrushingDetails
