import classNames from 'classnames'

const RadioComponent = ({ label, value, description, checked }) => (
  <div
    className={classNames(
      'flex border rounded-lg p-4 text-grey-600 w-full h-full hover:cursor-pointer',
      {
        'border-blue-300 shadow': checked
      }
    )}
  >
    <input
      name={value}
      type='radio'
      className='h-4 w-4 mr-2 transition duration-200 ease-in-out mb-auto mt-1.5 border-gray-300 text-blue-400 focus:ring-blue-400'
      checked={checked}
      readOnly
    />
    <div className='flex-col'>
      <h4 className='text-grey-600'>{label}</h4>
      <p className='text-sm text-grey-400'>{description}</p>
    </div>
  </div>
)

export default RadioComponent
