import TableChart from 'components/Graphs/TableChart'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import useGraph from 'hooks/useGraph'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useLocalization } from 'providers/LocalizationProvider'

const calcExecutionIdData = ({ record }) => record.rotation_ratio

const Ratio = ({ data }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { t } = useLocalization()

  const { volumeGraphData, bars } = useGraph({
    data,
    calcExecutionIdData
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'rotation_ratio',
    data
  })

  return (
    <TableChart
      title={t('optimizationScreen.export.storage.title')}
      data={volumeGraphData}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      bars={bars}
      tooltipFormatter={(v) => (v ? v.toFixed(4) : NO_DATA_LABEL)}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        rowTitleFormatter: capitalize,
        columnValueFormatter: (x) => x.toFixed(2)
      }}
    />
  )
}

export default Ratio
