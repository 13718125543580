import { useState } from 'react'
import { SectionContainer } from '../SectionContainer'
import RadioGroup from 'components/Inputs/RadioGroup'
import {
  CardBase,
  TitleAndDescription,
  UploadDownloadSection
} from 'components/Cards/WhatIf'
import { RadioButton } from 'components/Inputs/RadioGroup/RadioButton'
import RangeSlider from 'components/Inputs/RangeSlider'
import { useScenariosContext } from 'providers/ScenariosProvider'
import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { useOutputDownload } from 'hooks/useOutputDownload'
import { useToast } from 'providers/ToastProvider'
import { useTemplate } from 'screens/Scenarios/useTemplate'
import { getFileNameFromTemplateType } from 'utils'
import { FILE_UPLOAD_SUCCESSFULLY } from 'utils/string'
import { useLocalization } from 'providers/LocalizationProvider'
import { Trans } from 'react-i18next'

const ENABLE_VARIATION_SLIDER = false

const Origination = () => {
  const [donwloadProgress, setDownloadProgess] = useState(undefined)
  const { setValue, executionData } = useScenariosContext()
  const downloadBucket = useOutputDownload(setDownloadProgess)
  const defaultSliderValue = 5
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [sliderVal, setSliderVal] = useState(defaultSliderValue)
  const { showToast } = useToast()
  const getTemplate = useTemplate()
  const { countryCode, t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.origination.originationVolume.'

  const templateType = getTemplate()
  const handleDownload = () => {
    const filename = `${getFileNameFromTemplateType(templateType)}.xlsx`
    const folderStructureOption = 'output'

    downloadBucket(filename, executionData.id, folderStructureOption)
  }

  const onUploadSuccess = (files) => {
    setValue({
      files: files.reduce(
        (acc, curr) => ({ ...acc, [curr.type]: curr.id }),
        {}
      ),
      advancedSettings: {
        origination_plan: false
      }
    })
    showToast({
      message: FILE_UPLOAD_SUCCESSFULLY,
      placement: 'top',
      status: 'success'
    })
  }

  const handleSliderChange = (e) => {
    setSliderVal(Number(e.target.value))
    setValue({
      advancedSettings: {
        origination_plan: true,
      }
    })
  }

  return (
    <SectionContainer sectionTitle={t(`${defaultContentPath}title`)}>
      {ENABLE_VARIATION_SLIDER ? (
        <RadioGroup
          className='flex-col'
          options={[
            {
              value: 'Min and max origination per municipality',
              body: ({ checked }) => (
                <CardBase className='hover:cursor-pointer'>
                  <RadioButton checked={checked} />
                  <TitleAndDescription
                    title={t(`${defaultContentPath}minMaxOrigination`)}
                    description={
                      <Trans>
                        {t(
                          `${defaultContentPath}${countryCode}.minMaxDescription`
                        )}
                      </Trans>
                    }
                  />
                  <UploadDownloadSection
                    onDownload={(e) => handleDownload()}
                    onUpload={(e) => setUploadModalOpen(true)}
                  />
                </CardBase>
              )
            },
            {
              value: 'Allowed variation',
              body: ({ checked, value }) => (
                <CardBase className='hover:cursor-pointer'>
                  <RadioButton checked={checked} value={value} />
                  <TitleAndDescription
                    title={t(`${defaultContentPath}allowedVariation`)}
                    description={t(
                      `${defaultContentPath}${countryCode}.choosePercentage`
                    )}
                  />
                  <div className='w-1/3'>
                    <RangeSlider
                      min={0}
                      max={20}
                      defaultValue={defaultSliderValue}
                      value={sliderVal}
                      onChange={handleSliderChange}
                      disabled={!checked}
                    />
                  </div>
                </CardBase>
              )
            }
          ]}
        />
      ) : (
        <CardBase>
          <TitleAndDescription
            title={t(`${defaultContentPath}minMaxOrigination`)}
            description={
              <Trans>
                {t(`${defaultContentPath}${countryCode}.minMaxDescription`)}
              </Trans>
            }
          />
          <UploadDownloadSection
            onDownload={(e) => handleDownload()}
            onUpload={(e) => setUploadModalOpen(true)}
            progress={donwloadProgress}
          />
        </CardBase>
      )}
      <ValidatedFileSelectModal
        title='Upload Min and max origination for Origination volume'
        type={templateType}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onSuccess={onUploadSuccess}
        whatif={true}
      />
    </SectionContainer>
  )
}

export default Origination
