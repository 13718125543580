import classNames from 'classnames'
import React from 'react'

export const RenderTooltip = ({
  active,
  payload,
  label,
  labelStyle,
  contentStyle,
  itemStyle,
  separator,
  formatter,
  stacks
}) => {
  if (active && payload && payload.length) {
    const data = stacks
      ? stacks.map((stack) => ({
          ...stack,
          items: payload.filter((item) =>
            item.dataKey.startsWith(stack.stackId)
          )
        }))
      : [{ items: payload }]
    return (
      <div style={contentStyle} className='p-2.5'>
        <p style={labelStyle}>{`${label}`}</p>
        {data.map(({ stackId, label, items }, index) => (
          <React.Fragment key={index}>
            {stackId && (
              <p className='mt-4' style={itemStyle}>
                {label ? label : stackId}:
              </p>
            )}
            <div
              className={classNames({
                'ml-2': stackId
              })}
            >
              {items.map(
                ({ name, value, unit, formatter: itemFormatter }, index) => (
                  <p key={index} style={itemStyle}>
                    {name}
                    {separator}
                    {itemFormatter
                      ? itemFormatter(value)
                      : formatter
                      ? formatter(value)
                      : value}
                    {unit}
                  </p>
                )
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  return null
}
