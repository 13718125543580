import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import HeaderLogo from './HeaderLogo'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import useAuth from 'hooks/useAuth'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Select from 'components/Inputs/Select'
import {
  useLocalization,
  availableLanguages,
  countryOptions
} from 'providers/LocalizationProvider'
import Button from 'components/Inputs/Button'
import { useTranslation } from 'react-i18next'
import { FeedbackSurvey } from './FeedbackSurvey'
import { FEEDBACK_SURVEY_LINKS } from '../../constants'

const tabItems = [
  {
    name: 'Home',
    pathname: '/'
  },
  {
    name: 'Assets',
    pathname: '/assets'
  },
  {
    name: 'Design System',
    pathname: '/design-system'
  }
].filter(
  (item) =>
    process.env.NODE_ENV === 'development' || item.name !== 'Design System'
)

export default function Header({ logout, showTabs = true, showUserMenu = true }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { i18n } = useTranslation()
  const { onLanguageChange, countryCode, onCountryChange } = useLocalization()

  const [user] = useAuth()

  const languageOptions = availableLanguages.map((lang) => ({
    label: (
      <span>
        <strong>{lang.toUpperCase()}</strong>
      </span>
    ),
    value: lang
  }))

  const countrySelectOptions = countryOptions.map((country) => ({
    value: country.value,
    label: (
      <span>
        <strong>{country.label.substring(0, 3).toUpperCase()}</strong>
      </span>
    )
  }))

  const feedbackSurveyLink = FEEDBACK_SURVEY_LINKS[countryCode ?? 'BR']

  return (
    <Disclosure as='nav' className='bg-blue-400'>
      {({ open }) => (
        <>
          <div className='mx-auto'>
            <div className='relative flex h-12 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start h-full'>
                <div className='flex items-center'>
                  <div className='mb-1.5 pl-3'>
                    <HeaderLogo />
                  </div>
                </div>
                {showTabs ? (
                  <ul className='flex h-full ml-4'>
                    {tabItems.map((item) => {
                      return (
                        <div
                          key={item.name}
                          className={classNames(
                            'hidden sm:block text-white transition-colors ease-in-out',
                            {
                              'bg-blue-700': pathname === item.pathname
                            }
                          )}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(item.pathname)
                            }}
                            className='flex items-center justify-center h-full px-5 hover:bg-blue-500'
                          >
                            {item.name}
                          </button>
                        </div>
                      )
                    })}
                  </ul>
                ) : null}
              </div>
              <a
                className='flex justify-center items-center h-full w-9 ml-2 cursor-pointer hover:bg-blue-500'
                href={feedbackSurveyLink}
                target='_blank'
                rel="noreferrer"
              >
                <FeedbackSurvey />
              </a>

              {(logout && showUserMenu) ? (
                <>
                  <div className='absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                    <Menu as='div'>
                      <Menu.Button className='flex items-center text-white'>
                        <span>{user.username}</span>
                        <ChevronDownIcon className='h-[16px] w-[16px]' />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='absolute bottom-0 translate-x-5 translate-y-full z-10 mt-2 w-32 rounded-md bg-white focus:outline-none border-0 shadow-[inset_0_2px_1px_rgb(0,0,0,0.2),_0_3px_10px_rgb(0,0,0,0.2)]'>
                          <Menu.Item>
                            <Button
                              onClick={logout}
                              alt='Sign out'
                              className='w-full'
                            >
                              <ArrowRightOnRectangleIcon className='h-6 w-6' />
                              Log Out
                            </Button>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <Select
                    onValueChange={onLanguageChange}
                    value={i18n.language}
                    options={languageOptions}
                    variant='transparent-light'
                    triggerClassName='hover:bg-blue-500 h-full text-sm px-3'
                    className='h-full'
                  />
                  <Select
                    onValueChange={onCountryChange}
                    value={countryCode}
                    options={countrySelectOptions}
                    variant='transparent-light'
                    triggerClassName='hover:bg-blue-500 h-full text-sm px-3'
                    className='h-full '
                  />
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}
