import axios from 'axios'
import queryString from 'query-string'
import { getCurrentCountryCode, wait } from 'utils'

const RETRY_COUNT = 4
export const COUNTRY_CODE_HEADER = 'X-Country-Code'

// Todo return 401 to list. Check first request after login
const statusCodesNotToRetry = [400, 403, 404, 501]

export const baseURL =
  process.env.REACT_APP_API_URL || 'http://localhost:8000/api/v1'

export const baseHeaders = {
  'Content-type': 'application/json; charset=UTF-8',
  Accept: 'application/json'
}

const axiosInstance = axios.create({
  baseURL,
  headers: baseHeaders,
  timeout: 300_000
})

axiosInstance.interceptors.response.use(null, async (error) => {
  const config = error.config

  if (
    !config ||
    config.retryCount >= RETRY_COUNT ||
    statusCodesNotToRetry.includes(error?.response?.status)
  ) {
    throw error
  }

  const retryDelay = Math.round(Math.random() * 2_000 + 4_000)
  await wait(retryDelay)
  config.retryCount = (config.retryCount || 0) + 1

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }

  return await axiosInstance(config)
})

export const get = async (path, params) => {
  const res = await axiosInstance.get(path, {
    params,
    paramsSerializer: queryString.stringify
  })
  return res.data
}

export const getBlob = async (path, params, onProgress) => {
  const res = await axiosInstance.get(path, {
    params,
    paramsSerializer: queryString.stringify,
    responseType: 'blob',
    onDownloadProgress:
      typeof onProgress === 'function' &&
      ((evt) => {
        const ts = Number(evt.event.target.getResponseHeader('x-transfer-size'))
        if (ts && !isNaN(ts)) {
          onProgress({
            ...evt,
            total: ts
          })
        } else {
          onProgress(evt)
        }
      })
  })
  return res.data
}

export const put = async (path, body) => {
  const res = await axiosInstance.put(path, body)
  return res.data
}

/*
data = [
  {
    type: "TEMPLATES_BR_...",
    file: <BLOB>,
    description: "Some description"
  }
]
*/
export const putDatasetFiles = async (
  path,
  data,
  validate = false,
  onProgress = undefined
) => {
  const formData = new FormData()
  const meta = []
  data.forEach(({ file, ...rest }, idx) => {
    formData.append('files', file)
    meta.push({ idx, ...rest })
  })
  formData.append(
    'meta',
    new Blob([JSON.stringify(meta)], { type: 'application/json' })
  )
  const res = await axiosInstance.put(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      validate
    },
    onUploadProgress: onProgress
  })
  return res.data
}

export const postDatasetFile = async (
  path,
  data,
  onProgress = undefined,
  cancelSource = undefined
) => {
  const formData = new FormData()
  const { file, ...rest } = data
  const meta = [{ idx: 0, ...rest }]
  formData.append('files', file)
  formData.append(
    'meta',
    new Blob([JSON.stringify(meta)], { type: 'application/json' })
  )
  const res = await axiosInstance.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    cancelToken: cancelSource?.token,
    onUploadProgress: onProgress
  })
  return res.data
}

export const post = async (path, body) => {
  const res = await axiosInstance.post(path, body)
  return res.data
}

axiosInstance.interceptors.request.use(
  (config) => {
    const userKey = 'bg-user'
    const tokenKey = 'accessToken'
    const apiKey = 'apiKey'
    config.headers['Authorization'] = 'bearer ' + localStorage.getItem(tokenKey)
    config.headers['user'] = localStorage.getItem(userKey) || 'admin@bunge.com'
    config.headers['x-api-key'] = localStorage.getItem(apiKey) || 'null'
    // config.headers['x-api-key'] = process.env.REACT_APP_API_KEY
    config.headers[COUNTRY_CODE_HEADER] = getCurrentCountryCode()
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
