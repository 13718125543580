import { getMonthsForPeriod } from 'screens/Optimization/utils/output'

export const calendar = [
  {
    name: 'BA Lem',
    bm: [
      {
        startDate: '2023-04-01',
        endDate: '2023-04-30'
      }
    ],
    cmp: [
      {
        startDate: '2023-04-01',
        endDate: '2023-04-30'
      }
    ]
  },
  {
    name: 'GO Luz',
    bm: [
      {
        startDate: '2023-03-01',
        endDate: '2023-03-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-03-01',
        endDate: '2023-03-31'
      }
    ]
  },
  {
    name: 'MS Dou',
    bm: [
      {
        startDate: '2023-05-01',
        endDate: '2023-05-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-05-01',
        endDate: '2023-05-31'
      }
    ]
  },
  {
    name: 'MT Nmu',
    bm: [
      {
        startDate: '2023-06-01',
        endDate: '2023-06-30'
      }
    ],
    cmp: [
      {
        startDate: '2023-06-01',
        endDate: '2023-06-30'
      }
    ]
  },
  {
    name: 'MT Ron',
    bm: [
      {
        startDate: '2023-09-01',
        endDate: '2023-09-30'
      }
    ],
    cmp: [
      {
        startDate: '2023-09-01',
        endDate: '2023-09-30'
      }
    ]
  },
  {
    name: 'PI Uru',
    bm: [
      {
        startDate: '2023-03-01',
        endDate: '2023-03-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-03-01',
        endDate: '2023-03-31'
      }
    ]
  },
  {
    name: 'PR Acr',
    bm: [
      {
        startDate: '2023-01-01',
        endDate: '2023-01-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-01-01',
        endDate: '2023-01-31'
      }
    ]
  },
  {
    name: 'PR Cmb',
    bm: [
      {
        startDate: '2023-01-01',
        endDate: '2023-01-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-01-01',
        endDate: '2023-01-31'
      }
    ]
  },
  {
    name: 'PR Pgr',
    bm: [
      {
        startDate: '2023-07-01',
        endDate: '2023-07-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-07-01',
        endDate: '2023-07-31'
      }
    ]
  },
  {
    name: 'RS Rgd',
    bm: [
      {
        startDate: '2023-12-01',
        endDate: '2023-12-31'
      }
    ],
    cmp: [
      {
        startDate: '2023-12-01',
        endDate: '2023-12-31'
      }
    ]
  }
]

export const months = getMonthsForPeriod('2023-03-01', '2023-10-31').map(
  (month, idx) => ({
    month: month,
    value: idx
  })
)
