import useInternalMarketData from './useInternalMarketData'
import { capitalize } from 'utils/string'
import useSWR from 'swr'
import { useParams } from 'react-router'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { SingleVolumeCard } from 'components/Cards'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { labelFormatter } from 'screens/Optimization/utils/output'
import BarChart from 'components/Graphs/BarChart'
import classNames from 'classnames'
import { useState } from 'react'
import ProductDetails from './ProductDetails'
import NoData from 'components/Feedback/NoData'
import OutputScreen from 'components/Container/OutputScreen'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'

export default function InternalMarket() {
  const { t } = useLocalization()
  const { id } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { data } = useSWR([
    `/execution/outputs/${id}/internal-market`,
    { bestEstimateId: comparisonID }
  ])
  const { getUniqueProducts, getUniqueSubProducts, getGraphData, products } =
    useInternalMarketData(data)

  const [selectedProduct, setSelectedProduct] = useState(undefined)

  return (
    <OutputScreen>
      {selectedProduct ? (
        <ProductDetails
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          products={products}
          data={data}
        />
      ) : getUniqueProducts().length > 0 ? (
        <>
          {getUniqueProducts().map((product) => (
            <SectionBox key={product}>
              <p className='font-bold'>{capitalize(product)}</p>
              <div
                className={classNames(
                  'gap-4 mt-7 grid w-full',
                  getUniqueSubProducts(product).length === 1
                    ? 'grid-cols-1'
                    : 'grid-cols-3'
                )}
              >
                {getUniqueSubProducts(product).map((subProduct, _, arr) =>
                  arr.length === 1 ? (
                    <div
                      onClick={() => setSelectedProduct(subProduct)}
                      className='h-48 cursor-pointer'
                      key={subProduct}
                    >
                      <BarChart
                        variant='small'
                        data={getGraphData(subProduct)}
                        bars={[
                          {
                            dataKey: `volumes.${id}`,
                            fill: '#1950CA',
                            name: t('optimizationScreen.baseModel')
                          },
                          {
                            dataKey: `volumes.${comparisonID}`,
                            fill: '#C1D5FF',
                            name: t('optimizationScreen.comparison')
                          }
                        ]}
                        barProps={{
                          barSize: 8
                        }}
                        yAxisProps={{
                          dataKey: `volumes.${id}`,
                          width: 15,
                          tickCount: 3,
                          tickFormatter: (val) => val / kgToTons
                        }}
                        cartesianGridProps={{
                          horizontal: true,
                          vertical: false
                        }}
                        xAxisProps={{
                          dataKey: 'month',
                          strokeWidth: 0,
                          tickFormatter: (val) =>
                            transformDateToShortMonth(val)[0]
                        }}
                        tooltipFormatter={labelFormatter}
                        showLegend
                      />
                    </div>
                  ) : (
                    <SingleVolumeCard
                      onClick={() => setSelectedProduct(subProduct)}
                      key={subProduct}
                      title={capitalize(subProduct)}
                    >
                      <BarChart
                        variant='small'
                        data={getGraphData(subProduct)}
                        bars={[
                          {
                            dataKey: `volumes.${id}`,
                            fill: '#1950CA',
                            name: t('optimizationScreen.baseModel')
                          }
                        ]}
                        barProps={{
                          barSize: 5
                        }}
                        yAxisProps={{
                          dataKey: `volumes.${id}`,
                          width: 15,
                          tickCount: 3,
                          tickFormatter: (val) => val / kgToTons
                        }}
                        cartesianGridProps={{
                          horizontal: true,
                          vertical: false
                        }}
                        xAxisProps={{
                          dataKey: 'month',
                          strokeWidth: 0,
                          tickFormatter: (val) =>
                            transformDateToShortMonth(val)[0]
                        }}
                        tooltipFormatter={labelFormatter}
                      />
                    </SingleVolumeCard>
                  )
                )}
              </div>
            </SectionBox>
          ))}
        </>
      ) : (
        <NoData page={t('optimizationScreen.pageNames.internalMarket')} />
      )}
    </OutputScreen>
  )
}
