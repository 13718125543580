import { useState, useMemo } from 'react'
import * as Select from '@radix-ui/react-select'
import classnames from 'classnames'
import './select.css'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import Checkbox from 'components/Inputs/Checkbox'
import classNames from 'classnames'

const RenderSelectItems = ({ options, setItemChecked }) => (
  <>
    {options.map(({ value, label, checked }) => (
      <Checkbox
        key={value}
        label={label}
        className='flex items-center min-h-9 text-base text-[#3D444D] pr-9 pl-6 py-2 select-none cursor-pointer'
        isChecked={checked}
        onChange={(state) => setItemChecked(value, state)}
      />
    ))}
  </>
)

const RenderOptions = ({ options, setItemChecked }) => {
  if (Array.isArray(options)) {
    return (
      <RenderSelectItems options={options} setItemChecked={setItemChecked} />
    )
  }
  return (
    <>
      {Object.values(options).map(({ label, options }) => (
        <Select.Group key={label}>
          <Select.Label className='px-6 h-12 inline-flex items-center text-base text-[#3D444D] font-bold'>
            {label}
          </Select.Label>
          <RenderSelectItems
            options={options}
            setItemChecked={setItemChecked}
          />
        </Select.Group>
      ))}
    </>
  )
}

const MultiSelectComponent = ({
  placeholder,
  onChange,
  options,
  label,
  fullWidth,
  icon,
  variant = 'default',
  disabled,
  error,
  showErrors = true,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const displayText = useMemo(() => {
    const checkedOptionLabels = options.reduce(
      (acc, { label, checked }) => (checked ? [...acc, label] : [...acc]),
      []
    )
    if (checkedOptionLabels.length === 0) {
      return undefined
    } else if (checkedOptionLabels.length === 1) {
      return checkedOptionLabels[0]
    } else if (checkedOptionLabels.length <= 3) {
      return (
        checkedOptionLabels
          .slice(0, checkedOptionLabels.length - 1)
          .join(', ') +
        ` and ${checkedOptionLabels[checkedOptionLabels.length - 1]}`
      )
    } else {
      return (
        checkedOptionLabels.slice(0, 2).join(', ') +
        ` and ${checkedOptionLabels.length - 2} more`
      )
    }
  }, [options])

  return (
    <div {...props}>
      {label && <label className='block text-grey-600'>{label}</label>}
      <Select.Root disabled={disabled} open={open} onOpenChange={setOpen}>
        <Select.Trigger />
        <button
          className={classnames(
            'SelectTrigger inline-flex justify-between items-center gap-2 w-auto box-border',
            {
              'border border-solid border-[#d2d5da] text-[#3D444D] bg-white h-10 py-2 px-3 shadow-sm rounded-md box-border':
                variant === 'default',
              'text-[#1E2226] underline underline-offset-8 text-lg font-bold':
                variant === 'underlined',
              'w-full': fullWidth,
              'cursor-pointer': !disabled,
              'opacity-50 cursor-default': disabled,
              'border-error-400 text-error-400 focus:border-error-400': error
            }
          )}
          disabled={disabled}
          onClick={() => setOpen((prev) => !prev)}
        >
          {icon && <span className='h-5 w-5'>{icon}</span>}
          <span className={classNames(!displayText && 'text-grey-300')}>
            {displayText || placeholder}
          </span>
          <ChevronDownIcon
            className={classNames('arrow-icon h-6 w-6 transition-transform', {
              'rotate-180': open
            })}
          />
        </button>
        <Select.Portal>
          <Select.Content
            className='SelectContent inline-block	border border-solid border-[#E4E8ED] rounded-md mt-2 bg-white'
            position='popper'
            collisionPadding={25}
          >
            <Select.ScrollUpButton className='SelectScrollButton'>
              <ChevronUpIcon className='arrow-icon h-6 w-6 transition-transform' />
            </Select.ScrollUpButton>
            <Select.Viewport className='SelectViewport py-1'>
              <RenderOptions options={options} setItemChecked={onChange} />
            </Select.Viewport>
            <Select.ScrollDownButton className='SelectScrollButton'>
              <ChevronDownIcon className='arrow-icon h-6 w-6 transition-transform' />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      {showErrors && error && <div className='text-error-400'>{error}</div>}
    </div>
  )
}

export default MultiSelectComponent
