import { createContext, useReducer, useContext, useMemo } from 'react'
import { PORTS, SILOS, TRANSSHIPMENTS, CRUSHERS, PARAMETERS } from 'constants'

const initialState = {
  page: PORTS
}

const AssetsConfigurationsContext = createContext(initialState)
AssetsConfigurationsContext.displayName = 'ConfigurationsContext'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, page: action.payload }

    default:
      return state
  }
}

export const AssetsConfigurationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setPage = (value) => {
    if (![PORTS, SILOS, TRANSSHIPMENTS, CRUSHERS, PARAMETERS].includes(value)) {
      throw new Error(`Invalid page: ${value}`)
    }
    dispatch({ type: 'SET_PAGE', payload: value })
  }

  const value = useMemo(() => {
    return {
      ...state,
      setPage
    }
  }, [state])

  return (
    <AssetsConfigurationsContext.Provider value={value}>
      {children}
    </AssetsConfigurationsContext.Provider>
  )
}

export const useAssetsConfigurationsContext = () => {
  const context = useContext(AssetsConfigurationsContext)
  if (context === undefined) {
    throw new Error(
      'useAssetsConfigurations must be used within a AssetsConfigurationsProvider'
    )
  }
  return context
}
