import { NodeMock } from "../types";

export const nodesMock: NodeMock[] = [
  {
    id: 73,
    level: 3,
    label: "TRANSB_TO_TIPN_7",
    data: {
      Corridor: "CN",
      Flow_volume: 60000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 110.996390498852,
      total_revenue: 71133667.56,
      total_volume: 60000,
    },
  },
  {
    id: 35,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_7",
    data: {
      Corridor: "CN",
      Flow_volume: 237000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1864.878894089215,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 190.43845153853297,
      total_revenue: 441976297.89914393,
      total_volume: 237000,
    },
  },
  {
    id: 121,
    level: 3,
    label: "TRANSB_PR_MARINGA_8",
    data: {
      Corridor: "SW",
      Flow_volume: 342410.96366684756,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1165.7519482634375,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 121.82981704917788,
      total_revenue: 399166248.00138867,
      total_volume: 342410.96366684756,
    },
  },
  {
    id: 49,
    level: 4,
    label: "PORT_SAOFR_BUNGE_8",
    data: {
      Corridor: "SW",
      Flow_volume: 200999.95769668318,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.2021235088298,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 151.4520095611,
      total_revenue: 240838576.13735077,
      total_volume: 200999.95769668318,
    },
  },
  {
    id: 42,
    level: 4,
    label: "PORT_SAOFR_LITORAL_8",
    data: {
      Corridor: "SW",
      Flow_volume: 2599.9989594632916,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.202121,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 150.8991861685923,
      total_revenue: 3115324.267826709,
      total_volume: 2599.9989594632916,
    },
  },
  {
    id: 122,
    level: 3,
    label: "TRANSB_PR_MARINGA_7",
    data: {
      Corridor: "SW",
      Flow_volume: 198754.66,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1087.8049287319425,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 100.11043607434138,
      total_revenue: 216206298.75644147,
      total_volume: 198754.66,
    },
  },
  {
    id: 50,
    level: 4,
    label: "PORT_SAOFR_BUNGE_7",
    data: {
      Corridor: "SW",
      Flow_volume: 108040.22493897169,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.5616734665068,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 139.849825641325,
      total_revenue: 128088349.88034509,
      total_volume: 108040.22493897169,
    },
  },
  {
    id: 123,
    level: 3,
    label: "TRANSB_PR_MARINGA_6",
    data: {
      Corridor: "SW",
      Flow_volume: 323382.10924038856,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1115.6360740314276,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 92.901322743243,
      total_revenue: 360776746.76494926,
      total_volume: 323382.1092403885,
    },
  },
  {
    id: 51,
    level: 4,
    label: "PORT_SAOFR_BUNGE_6",
    data: {
      Corridor: "SW",
      Flow_volume: 251959.78724709924,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575437766905,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 146.45035283889536,
      total_revenue: 295441857.8309236,
      total_volume: 251959.78724709924,
    },
  },
  {
    id: 44,
    level: 4,
    label: "PORT_SAOFR_LITORAL_6",
    data: {
      Corridor: "SW",
      Flow_volume: 0.0010405367207948514,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575248,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 93.4723562902,
      total_revenue: 1.2201076034391298,
      total_volume: 0.0010405367207948514,
    },
  },
  {
    id: 124,
    level: 3,
    label: "TRANSB_PR_MARINGA_5",
    data: {
      Corridor: "SW",
      Flow_volume: 314215.15385172336,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1123.0074176282892,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 118.5528984925788,
      total_revenue: 352865948.50669944,
      total_volume: 314215.15385172336,
    },
  },
  {
    id: 52,
    level: 4,
    label: "PORT_SAOFR_BUNGE_5",
    data: {
      Corridor: "SW",
      Flow_volume: 145999.99999999997,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1405.3695086849314,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 238.2359861733147,
      total_revenue: 205183948.26799995,
      total_volume: 145999.99999999997,
    },
  },
  {
    id: 57,
    level: 4,
    label: "PORT_SAOFR_LITORAL_5",
    data: {
      Corridor: "SW",
      Flow_volume: 67000.00000000083,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1222.4283252417908,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 206.0905141418077,
      total_revenue: 81902697.791201,
      total_volume: 67000.00000000083,
    },
  },
  {
    id: 125,
    level: 3,
    label: "TRANSB_PR_MARINGA_4",
    data: {
      Corridor: "SW",
      Flow_volume: 410632.84766570333,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1148.4512635889846,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 131.83819203691715,
      total_revenue: 471591812.77282,
      total_volume: 410632.84766570333,
    },
  },
  {
    id: 103,
    level: 4,
    label: "PORT_PARAN_ROCHA_4",
    data: {
      Corridor: "SW",
      Flow_volume: 130374.22900000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1167.8164966153531,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 145.47465403738653,
      total_revenue: 152253175.3597078,
      total_volume: 130374.22900000002,
    },
  },
  {
    id: 53,
    level: 4,
    label: "PORT_SAOFR_BUNGE_4",
    data: {
      Corridor: "SW",
      Flow_volume: 61403.6415,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1297.3511197406278,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 233.53573210635386,
      total_revenue: 79662083.05617708,
      total_volume: 61403.6415,
    },
  },
  {
    id: 45,
    level: 4,
    label: "PORT_SAOFR_LITORAL_4",
    data: {
      Corridor: "SW",
      Flow_volume: 100613.585,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1164.087744,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 164.04691669775625,
      total_revenue: 117123041.17840223,
      total_volume: 100613.585,
    },
  },
  {
    id: 126,
    level: 3,
    label: "TRANSB_PR_MARINGA_3",
    data: {
      Corridor: "SW",
      Flow_volume: 592000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1757.214595806634,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 128.29342096659147,
      total_revenue: 1040271040.7175273,
      total_volume: 592000,
    },
  },
  {
    id: 89,
    level: 4,
    label: "PORT_PARAN_ROCHA_3",
    data: {
      Corridor: "SW",
      Flow_volume: 146125.771,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1311.1479981206612,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 183.7584894935712,
      total_revenue: 191592512.1204882,
      total_volume: 146125.771,
    },
  },
  {
    id: 54,
    level: 4,
    label: "PORT_SAOFR_BUNGE_3",
    data: {
      Corridor: "SW",
      Flow_volume: 219967.71850000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2440.908048,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 291.01747920332355,
      total_revenue: 536920974.3868484,
      total_volume: 219967.71850000002,
    },
  },
  {
    id: 46,
    level: 4,
    label: "PORT_SAOFR_LITORAL_3",
    data: {
      Corridor: "SW",
      Flow_volume: 107677.10743046133,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1434.4534221869953,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 243.49153879834498,
      total_revenue: 154457795.244822,
      total_volume: 107677.10743046133,
    },
  },
  {
    id: 127,
    level: 3,
    label: "TRANSB_PR_MARINGA_2",
    data: {
      Corridor: "SW",
      Flow_volume: 417549.12,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2454.1893456225603,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 115.65238350854051,
      total_revenue: 1024744601.5780759,
      total_volume: 417549.12,
    },
  },
  {
    id: 55,
    level: 4,
    label: "PORT_SAOFR_BUNGE_2",
    data: {
      Corridor: "SW",
      Flow_volume: 62500,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2475.849472,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 225,
      total_revenue: 154740592,
      total_volume: 62500,
    },
  },
  {
    id: 47,
    level: 4,
    label: "PORT_SAOFR_LITORAL_2",
    data: {
      Corridor: "SW",
      Flow_volume: 198840.71250000014,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2473.798322006528,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 258.57243789977827,
      total_revenue: 491891820.9290827,
      total_volume: 198840.7125000001,
    },
  },
  {
    id: 129,
    level: 3,
    label: "TRANSB_PR_MARINGA_1",
    data: {
      Corridor: "SW",
      Flow_volume: 535981.1500001681,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.6278645051757,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 116.12868384925525,
      total_revenue: 1329569775.5399451,
      total_volume: 535981.150000168,
    },
  },
  {
    id: 56,
    level: 4,
    label: "PORT_SAOFR_BUNGE_1",
    data: {
      Corridor: "SW",
      Flow_volume: 144510.86600001802,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.841104,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 379.14,
      total_revenue: 358508496.3474808,
      total_volume: 144510.86600001802,
    },
  },
  {
    id: 48,
    level: 4,
    label: "PORT_SAOFR_LITORAL_1",
    data: {
      Corridor: "SW",
      Flow_volume: 181472.33868990984,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.841104,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 305.0825960468,
      total_revenue: 450204037.0609378,
      total_volume: 181472.33868990984,
    },
  },
  {
    id: 133,
    level: 3,
    label: "TRANSB_RO_PORTOVE_1",
    data: {
      Corridor: "N",
      Flow_volume: 210431.87950000007,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2371.272705698003,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 263.2944732721896,
      total_revenue: 498991372.26708126,
      total_volume: 210431.87950000007,
    },
  },
  {
    id: 24,
    level: 4,
    label: "PORT_SANTA_CDP_1",
    data: {
      Corridor: "N",
      Flow_volume: 90097.45800000009,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2449.795999,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 276,
      total_revenue: 220720392.12847075,
      total_volume: 90097.45800000009,
    },
  },
  {
    id: 135,
    level: 3,
    label: "TRANSB_PA_ITAITUB_7",
    data: {
      Corridor: "N",
      Flow_volume: 34082.79000000034,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1017.2235574601783,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 318.4694263726931,
      total_revenue: 34669816.89196854,
      total_volume: 34082.79000000034,
    },
  },
  {
    id: 82,
    level: 4,
    label: "PORT_BARCA_7",
    data: {
      Corridor: "N",
      Flow_volume: 104609.76150000036,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 334.4328481621,
      total_revenue: 124021266.63453189,
      total_volume: 104609.76150000036,
    },
  },
  {
    id: 136,
    level: 3,
    label: "TRANSB_PA_ITAITUB_6",
    data: {
      Corridor: "N",
      Flow_volume: 169982.07199999975,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1982.2912800074234,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 311.17369866609727,
      total_revenue: 336953979.08319354,
      total_volume: 169982.07199999975,
    },
  },
  {
    id: 83,
    level: 4,
    label: "PORT_BARCA_6",
    data: {
      Corridor: "N",
      Flow_volume: 125228.95715634219,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1897.8862071146982,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 213.28802714470555,
      total_revenue: 237670310.51837933,
      total_volume: 125228.95715634219,
    },
  },
  {
    id: 137,
    level: 3,
    label: "TRANSB_PA_ITAITUB_5",
    data: {
      Corridor: "N",
      Flow_volume: 211417.403,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1510.7807770875058,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 378.44114486566224,
      total_revenue: 319405348.39416236,
      total_volume: 211417.403,
    },
  },
  {
    id: 84,
    level: 4,
    label: "PORT_BARCA_5",
    data: {
      Corridor: "N",
      Flow_volume: 160867.764,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1582.9661991520968,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 279.23039047598076,
      total_revenue: 254648232.9451765,
      total_volume: 160867.764,
    },
  },
  {
    id: 138,
    level: 3,
    label: "TRANSB_PA_ITAITUB_4",
    data: {
      Corridor: "N",
      Flow_volume: 330000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1169.4299430832245,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 353.3721635073622,
      total_revenue: 385911881.2174641,
      total_volume: 330000,
    },
  },
  {
    id: 85,
    level: 4,
    label: "PORT_BARCA_4",
    data: {
      Corridor: "N",
      Flow_volume: 242540.76083444752,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1187.8374211325283,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 292.1426946815758,
      total_revenue: 288098991.8691115,
      total_volume: 242540.76083444754,
    },
  },
  {
    id: 139,
    level: 3,
    label: "TRANSB_PA_ITAITUB_3",
    data: {
      Corridor: "N",
      Flow_volume: 329795.99999999994,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1246.2281845172104,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 311.40775300066315,
      total_revenue: 411001070.3410378,
      total_volume: 329795.99999999994,
    },
  },
  {
    id: 86,
    level: 4,
    label: "PORT_BARCA_3",
    data: {
      Corridor: "N",
      Flow_volume: 244800,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1525.9748843268505,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 296.09767629798046,
      total_revenue: 373558651.683213,
      total_volume: 244800,
    },
  },
  {
    id: 140,
    level: 3,
    label: "TRANSB_PA_ITAITUB_2",
    data: {
      Corridor: "N",
      Flow_volume: 321115.36599999684,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2222.681545795183,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 336.69195205249656,
      total_revenue: 713737198.079459,
      total_volume: 321115.36599999684,
    },
  },
  {
    id: 178,
    level: 4,
    label: "PORT_BARCA_2",
    data: {
      Corridor: "N",
      Flow_volume: 231060.47999999998,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2133.835264785061,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 306.2581086724529,
      total_revenue: 493045000.5221633,
      total_volume: 231060.47999999998,
    },
  },
  {
    id: 141,
    level: 3,
    label: "TRANSB_PA_ITAITUB_1",
    data: {
      Corridor: "N",
      Flow_volume: 329999.20000000007,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2466.3975819187485,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 335.56802753221933,
      total_revenue: 813909228.9151216,
      total_volume: 329999.2,
    },
  },
  {
    id: 238,
    level: 4,
    label: "PORT_BARCA_1",
    data: {
      Corridor: "N",
      Flow_volume: 225623.84,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2404.53292893262,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 210.78118487134,
      total_revenue: 542519952.8322248,
      total_volume: 225623.84,
    },
  },
  {
    id: 142,
    level: 3,
    label: "TRANSB_MT_TRO_9",
    data: {
      Corridor: "CS",
      Flow_volume: 377197.0806666668,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1516.3262415509075,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 232.230667966155,
      total_revenue: 571953831.6512613,
      total_volume: 377197.0806666668,
    },
  },
  {
    id: 8,
    level: 4,
    label: "PORT_SANTO_TGG_9",
    data: {
      Corridor: "CS",
      Flow_volume: 276250.00000000023,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1666.2776874740277,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 183.9257142857143,
      total_revenue: 460309211.16470057,
      total_volume: 276250.00000000023,
    },
  },
  {
    id: 143,
    level: 3,
    label: "TRANSB_MT_TRO_8",
    data: {
      Corridor: "CS",
      Flow_volume: 1100000.0000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1370.9084869979263,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 160.83635218949405,
      total_revenue: 1507999335.697719,
      total_volume: 1100000.0000000002,
    },
  },
  {
    id: 9,
    level: 4,
    label: "PORT_SANTO_TGG_8",
    data: {
      Corridor: "CS",
      Flow_volume: 481862.3629734628,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.202121,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 288.65530567625035,
      total_revenue: 577368505.344875,
      total_volume: 481862.3629734628,
    },
  },
  {
    id: 30,
    level: 4,
    label: "PORT_SANTO_TXXXIX_8",
    data: {
      Corridor: "CS",
      Flow_volume: 79379.88202653745,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2459.204091,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 456.1983471074,
      total_revenue: 195211330.62275827,
      total_volume: 79379.88202653745,
    },
  },
  {
    id: 144,
    level: 3,
    label: "TRANSB_MT_TRO_7",
    data: {
      Corridor: "CS",
      Flow_volume: 1098000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1316.7780105737704,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 161.84141535443797,
      total_revenue: 1445822255.61,
      total_volume: 1098000,
    },
  },
  {
    id: 10,
    level: 4,
    label: "PORT_SANTO_TGG_7",
    data: {
      Corridor: "CS",
      Flow_volume: 608000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.5611259999998,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 171.32034683709477,
      total_revenue: 720821164.6079999,
      total_volume: 608000,
    },
  },
  {
    id: 29,
    level: 4,
    label: "PORT_SANTO_TXXXIX_7",
    data: {
      Corridor: "CS",
      Flow_volume: 57000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2449.386909,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 247.9338842975,
      total_revenue: 139615053.813,
      total_volume: 57000,
    },
  },
  {
    id: 145,
    level: 3,
    label: "TRANSB_MT_TRO_6",
    data: {
      Corridor: "CS",
      Flow_volume: 1013000.0000000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1334.3015375749678,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 167.5923799312636,
      total_revenue: 1351647457.5634425,
      total_volume: 1013000.0000000001,
    },
  },
  {
    id: 11,
    level: 4,
    label: "PORT_SANTO_TGG_6",
    data: {
      Corridor: "CS",
      Flow_volume: 658169.4012867205,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1352.2945699952668,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 155.70501241733714,
      total_revenue: 890038907.4970679,
      total_volume: 658169.4012867205,
    },
  },
  {
    id: 28,
    level: 4,
    label: "PORT_SANTO_TXXXIX_6",
    data: {
      Corridor: "CS",
      Flow_volume: 65073.099713279495,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2431.380592,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 504.6831955922,
      total_revenue: 158217471.70414853,
      total_volume: 65073.099713279495,
    },
  },
  {
    id: 146,
    level: 3,
    label: "TRANSB_MT_TRO_5",
    data: {
      Corridor: "CS",
      Flow_volume: 982786.9918069822,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1496.1764645015235,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 180.4547672368883,
      total_revenue: 1470422766.7598584,
      total_volume: 982786.9918069822,
    },
  },
  {
    id: 12,
    level: 4,
    label: "PORT_SANTO_TGG_5",
    data: {
      Corridor: "CS",
      Flow_volume: 604737.4665062167,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1307.7138199217056,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 251.29850135217478,
      total_revenue: 790823542.3746191,
      total_volume: 604737.4665062167,
    },
  },
  {
    id: 27,
    level: 4,
    label: "PORT_SANTO_TXXXIX_5",
    data: {
      Corridor: "CS",
      Flow_volume: 128741.3219904476,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2418.309322,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 417.1071874315305,
      total_revenue: 311336339.096103,
      total_volume: 128741.3219904476,
    },
  },
  {
    id: 147,
    level: 3,
    label: "TRANSB_PA_ITAITUB_8",
    data: {
      Corridor: "N",
      Flow_volume: 248257.73500000022,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1800.2515758985066,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 347.2868955221592,
      total_revenue: 446926378.6627442,
      total_volume: 248257.73500000022,
    },
  },
  {
    id: 81,
    level: 4,
    label: "PORT_BARCA_8",
    data: {
      Corridor: "N",
      Flow_volume: 231628.8675000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1587.5015203332887,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 365.29395973542853,
      total_revenue: 367711179.3093281,
      total_volume: 231628.8675000001,
    },
  },
  {
    id: 148,
    level: 3,
    label: "TRANSB_MT_TRO_4",
    data: {
      Corridor: "CS",
      Flow_volume: 676053.1460668559,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1619.2768574335737,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 179.5812742733095,
      total_revenue: 1094717213.8212192,
      total_volume: 676053.1460668559,
    },
  },
  {
    id: 13,
    level: 4,
    label: "PORT_SANTO_TGG_4",
    data: {
      Corridor: "CS",
      Flow_volume: 620454.5454545455,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1544.6893699520379,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 282.79767517414723,
      total_revenue: 958409540.90206,
      total_volume: 620454.5454545455,
    },
  },
  {
    id: 2,
    level: 4,
    label: "PORT_SANTO_TXXXIX_4",
    data: {
      Corridor: "CS",
      Flow_volume: 124022.02757888245,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2404.722324,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 440.38971655592655,
      total_revenue: 298238538.3866823,
      total_volume: 124022.02757888245,
    },
  },
  {
    id: 149,
    level: 3,
    label: "TRANSB_RO_PORTOVE_2",
    data: {
      Corridor: "N",
      Flow_volume: 185463.6365000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2285.1997715494126,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 254.83156035538912,
      total_revenue: 423821459.76052356,
      total_volume: 185463.6365000001,
    },
  },
  {
    id: 23,
    level: 4,
    label: "PORT_SANTA_CDP_2",
    data: {
      Corridor: "N",
      Flow_volume: 110000.00000000006,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2444.866832,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 138,
      total_revenue: 268935351.52000016,
      total_volume: 110000.00000000006,
    },
  },
  {
    id: 150,
    level: 3,
    label: "TRANSB_RO_PORTOVE_4",
    data: {
      Corridor: "N",
      Flow_volume: 160000.00000000038,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1163.6665916053169,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 240.43639403696412,
      total_revenue: 186186654.65685114,
      total_volume: 160000.00000000038,
    },
  },
  {
    id: 21,
    level: 4,
    label: "PORT_SANTA_CDP_4",
    data: {
      Corridor: "N",
      Flow_volume: 80000.00000000019,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1164.087744,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 138,
      total_revenue: 93127019.52000022,
      total_volume: 80000.00000000019,
    },
  },
  {
    id: 151,
    level: 3,
    label: "TRANSB_TO_TIPN_6",
    data: {
      Corridor: "CN",
      Flow_volume: 133599.99999999997,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.5752479999999,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 120.16909197410853,
      total_revenue: 156656053.13279998,
      total_volume: 133600,
    },
  },
  {
    id: 36,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_6",
    data: {
      Corridor: "CN",
      Flow_volume: 290572.6934999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1720.5187489959453,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 188.7104477247111,
      total_revenue: 499935767.11300206,
      total_volume: 290572.6934999999,
    },
  },
  {
    id: 120,
    level: 4,
    label: "PORT_SÃOLU_VLI_6",
    data: {
      Corridor: "CN",
      Flow_volume: 10000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575248,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 229.10541314090003,
      total_revenue: 11725752.48,
      total_volume: 10000,
    },
  },
  {
    id: 152,
    level: 3,
    label: "TRANSB_TO_TIPN_5",
    data: {
      Corridor: "CN",
      Flow_volume: 199054.6130000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1562.8754973752655,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 161.2418472199432,
      total_revenue: 311097577.2972163,
      total_volume: 199054.6130000002,
    },
  },
  {
    id: 37,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_5",
    data: {
      Corridor: "CN",
      Flow_volume: 258227.30650000006,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2010.6178758438598,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 207.34316504339716,
      total_revenue: 519196438.47991145,
      total_volume: 258227.30650000006,
    },
  },
  {
    id: 119,
    level: 4,
    label: "PORT_SÃOLU_VLI_5",
    data: {
      Corridor: "CN",
      Flow_volume: 128000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1384.3877458124998,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 214.3028351611731,
      total_revenue: 177201631.464,
      total_volume: 128000,
    },
  },
  {
    id: 153,
    level: 3,
    label: "TRANSB_TO_TIPN_4",
    data: {
      Corridor: "CN",
      Flow_volume: 94000.00000000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1983.4616443404252,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 144.3330257129714,
      total_revenue: 186445394.568,
      total_volume: 94000.00000000001,
    },
  },
  {
    id: 38,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_4",
    data: {
      Corridor: "CN",
      Flow_volume: 186760.43,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1503.6859202190508,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 216.7604080474975,
      total_revenue: 280829029.0450556,
      total_volume: 186760.43,
    },
  },
  {
    id: 118,
    level: 4,
    label: "PORT_SÃOLU_VLI_4",
    data: {
      Corridor: "CN",
      Flow_volume: 92934.65550000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2492.038548,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 318.66110314493676,
      total_revenue: 231596743.95110023,
      total_volume: 92934.65550000001,
    },
  },
  {
    id: 154,
    level: 3,
    label: "TRANSB_TO_TIPN_3",
    data: {
      Corridor: "CN",
      Flow_volume: 94761.381,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1775.1904032420243,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 144.51036879707715,
      total_revenue: 168219494.1491611,
      total_volume: 94761.381,
    },
  },
  {
    id: 88,
    level: 4,
    label: "PORT_SÃOLU_VLI_3",
    data: {
      Corridor: "CN",
      Flow_volume: 138000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1440.4858388695652,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 207.89308121788,
      total_revenue: 198787045.764,
      total_volume: 138000,
    },
  },
  {
    id: 155,
    level: 3,
    label: "TRANSB_TO_TIPN_2",
    data: {
      Corridor: "CN",
      Flow_volume: 144763.12899999926,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2182.7360415830285,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 129.49476038157258,
      total_revenue: 315979699.1606317,
      total_volume: 144763.12899999926,
    },
  },
  {
    id: 40,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_2",
    data: {
      Corridor: "CN",
      Flow_volume: 208671.5753892872,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2470.116160349069,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 213.04549381099932,
      total_revenue: 515443030.5745774,
      total_volume: 208671.5753892872,
    },
  },
  {
    id: 31,
    level: 4,
    label: "PORT_SÃOLU_VLI_2",
    data: {
      Corridor: "CN",
      Flow_volume: 138000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2290.9997247536235,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 140.2704131409,
      total_revenue: 316157962.01600003,
      total_volume: 138000,
    },
  },
  {
    id: 156,
    level: 3,
    label: "TRANSB_TO_TIPN_1",
    data: {
      Corridor: "CN",
      Flow_volume: 175150.65999999776,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2433.8039912173117,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 166.19074484934114,
      total_revenue: 426282375.3723409,
      total_volume: 175150.65999999776,
    },
  },
  {
    id: 41,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_1",
    data: {
      Corridor: "CN",
      Flow_volume: 202691.89699999802,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2479.014629,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 228.141418588214,
      total_revenue: 502476177.8427563,
      total_volume: 202691.89699999802,
    },
  },
  {
    id: 32,
    level: 4,
    label: "PORT_SÃOLU_VLI_1",
    data: {
      Corridor: "CN",
      Flow_volume: 138000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2479.014629,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 130.97136103931362,
      total_revenue: 342104018.802,
      total_volume: 138000,
    },
  },
  {
    id: 157,
    level: 3,
    label: "TRANSB_TO_TIPA_8",
    data: {
      Corridor: "CN",
      Flow_volume: 54000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.2021210000003,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 233.5403998419678,
      total_revenue: 64702914.53400001,
      total_volume: 54000,
    },
  },
  {
    id: 34,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_8",
    data: {
      Corridor: "CN",
      Flow_volume: 208720.46116109533,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1902.5041451008537,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 188.90708473757599,
      total_revenue: 397091542.5263457,
      total_volume: 208720.46116109536,
    },
  },
  {
    id: 158,
    level: 3,
    label: "TRANSB_TO_TIPA_7",
    data: {
      Corridor: "CN",
      Flow_volume: 28191.813,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 192.47130069191743,
      total_revenue: 33423117.56426144,
      total_volume: 28191.813000000002,
    },
  },
  {
    id: 159,
    level: 3,
    label: "TRANSB_TO_TIPA_6",
    data: {
      Corridor: "CN",
      Flow_volume: 63808.187,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.5752479999999,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 257.81176683556663,
      total_revenue: 74819900.69595538,
      total_volume: 63808.187000000005,
    },
  },
  {
    id: 160,
    level: 3,
    label: "TRANSB_TO_TIPA_5",
    data: {
      Corridor: "CN",
      Flow_volume: 146800,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1644.3587440567997,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 268.49725479389394,
      total_revenue: 241391863.6275382,
      total_volume: 146800,
    },
  },
  {
    id: 161,
    level: 3,
    label: "TRANSB_TO_TIPA_4",
    data: {
      Corridor: "CN",
      Flow_volume: 172800,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1405.94528241548,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 220.71310065198634,
      total_revenue: 242947344.80139497,
      total_volume: 172800,
    },
  },
  {
    id: 162,
    level: 3,
    label: "TRANSB_TO_TIPA_3",
    data: {
      Corridor: "CN",
      Flow_volume: 167513.93384331503,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1438.4201198992894,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 221.61775185116227,
      total_revenue: 240955412.80370277,
      total_volume: 167513.933843315,
    },
  },
  {
    id: 39,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_3",
    data: {
      Corridor: "CN",
      Flow_volume: 47162.37461071281,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2379.124443445312,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 208.5969623593564,
      total_revenue: 112205158.24727142,
      total_volume: 47162.37461071281,
    },
  },
  {
    id: 163,
    level: 3,
    label: "TRANSB_RO_PORTOVE_3",
    data: {
      Corridor: "N",
      Flow_volume: 169980.00000000026,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1044.8683521538028,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 230.5957532992348,
      total_revenue: 177606722.49910367,
      total_volume: 169980.00000000026,
    },
  },
  {
    id: 22,
    level: 4,
    label: "PORT_SANTA_CDP_3",
    data: {
      Corridor: "N",
      Flow_volume: 90000.00000000013,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1174.796583,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 138,
      total_revenue: 105731692.47000016,
      total_volume: 90000.00000000013,
    },
  },
  {
    id: 164,
    level: 3,
    label: "TRANSB_TO_TIPA_2",
    data: {
      Corridor: "CN",
      Flow_volume: 235473.94057859754,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2180.812697367397,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 240.58283094566565,
      total_revenue: 513524559.5129415,
      total_volume: 235473.94057859754,
    },
  },
  {
    id: 165,
    level: 3,
    label: "TRANSB_RS_CANOAS_8",
    data: {
      Corridor: "S",
      Flow_volume: 25302.43040608555,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2547.749328,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 78.83972708473333,
      total_revenue: 64464250.06387122,
      total_volume: 25302.43040608555,
    },
  },
  {
    id: 97,
    level: 4,
    label: "PORT_RIOGR_8",
    data: {
      Corridor: "S",
      Flow_volume: 81999.99999999219,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2547.7493279999994,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 153.77601153777587,
      total_revenue: 208915444.89598006,
      total_volume: 81999.99999999219,
    },
  },
  {
    id: 166,
    level: 3,
    label: "TRANSB_RS_CANOAS_7",
    data: {
      Corridor: "S",
      Flow_volume: 15360.865456813879,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2547.204881,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 72.89803689030589,
      total_revenue: 39127271.46798061,
      total_volume: 15360.865456813879,
    },
  },
  {
    id: 98,
    level: 4,
    label: "PORT_RIOGR_7",
    data: {
      Corridor: "S",
      Flow_volume: 113333.82435028089,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2547.204881,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 138.65209303999566,
      total_revenue: 288684470.56743217,
      total_volume: 113333.82435028089,
    },
  },
  {
    id: 168,
    level: 3,
    label: "TRANSB_RS_CANOAS_5",
    data: {
      Corridor: "S",
      Flow_volume: 58999.99999999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2550.2700599999994,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 93.20566906432593,
      total_revenue: 150465933.53999996,
      total_volume: 59000,
    },
  },
  {
    id: 100,
    level: 4,
    label: "PORT_RIOGR_5",
    data: {
      Corridor: "S",
      Flow_volume: 180675.7376850271,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2550.27006,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 173.01658093909808,
      total_revenue: 460771924.38653827,
      total_volume: 180675.7376850271,
    },
  },
  {
    id: 169,
    level: 3,
    label: "TRANSB_RS_CANOAS_4",
    data: {
      Corridor: "S",
      Flow_volume: 90000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2493.846156,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 92.99097308465714,
      total_revenue: 224446154.04000002,
      total_volume: 90000,
    },
  },
  {
    id: 101,
    level: 4,
    label: "PORT_RIOGR_4",
    data: {
      Corridor: "S",
      Flow_volume: 161863.32450000005,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2493.8461559999996,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 169.59167141708332,
      total_revenue: 403662229.6017057,
      total_volume: 161863.32450000005,
    },
  },
  {
    id: 170,
    level: 3,
    label: "TRANSB_RS_CANOAS_3",
    data: {
      Corridor: "S",
      Flow_volume: 76068.35206855027,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2440.908048,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 84.55147748642942,
      total_revenue: 185675852.7622218,
      total_volume: 76068.35206855029,
    },
  },
  {
    id: 102,
    level: 4,
    label: "PORT_RIOGR_3",
    data: {
      Corridor: "S",
      Flow_volume: 110618.81103844996,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2440.9080479999993,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 167.10764837442414,
      total_revenue: 270010346.1239437,
      total_volume: 110618.81103844996,
    },
  },
  {
    id: 171,
    level: 3,
    label: "TRANSB_RS_CANOAS_2",
    data: {
      Corridor: "S",
      Flow_volume: 90000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2475.8494720000003,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 85.85469563872222,
      total_revenue: 222826452.48000002,
      total_volume: 90000,
    },
  },
  {
    id: 104,
    level: 4,
    label: "PORT_RIOGR_2",
    data: {
      Corridor: "S",
      Flow_volume: 271277.4424999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2475.849472,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 167.66179768718249,
      total_revenue: 671642112.7791351,
      total_volume: 271277.4424999999,
    },
  },
  {
    id: 172,
    level: 3,
    label: "TRANSB_RS_CANOAS_1",
    data: {
      Corridor: "S",
      Flow_volume: 85206.49706855329,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.8411039999996,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 86.68042639969048,
      total_revenue: 211383780.2555225,
      total_volume: 85206.49706855329,
    },
  },
  {
    id: 105,
    level: 4,
    label: "PORT_RIOGR_1",
    data: {
      Corridor: "S",
      Flow_volume: 198800,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.841104,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 170.12046769856562,
      total_revenue: 493191211.47520006,
      total_volume: 198800,
    },
  },
  {
    id: 173,
    level: 3,
    label: "TRANSB_RO_PORTOVE_8",
    data: {
      Corridor: "N",
      Flow_volume: 37069.809,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.202121,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 213.9987436964,
      total_revenue: 44417123.76886489,
      total_volume: 37069.809,
    },
  },
  {
    id: 17,
    level: 4,
    label: "PORT_SANTA_CDP_8",
    data: {
      Corridor: "N",
      Flow_volume: 31177.251,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.202121,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 138,
      total_revenue: 37356648.275149375,
      total_volume: 31177.251,
    },
  },
  {
    id: 174,
    level: 3,
    label: "TRANSB_RO_PORTOVE_7",
    data: {
      Corridor: "N",
      Flow_volume: 105284.693,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1932.693277644833,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 183.29803602711766,
      total_revenue: 203483018.4,
      total_volume: 105284.693,
    },
  },
  {
    id: 18,
    level: 4,
    label: "PORT_SANTA_CDP_7",
    data: {
      Corridor: "N",
      Flow_volume: 55000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2543.53773,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 138,
      total_revenue: 139894575.15,
      total_volume: 55000,
    },
  },
  {
    id: 175,
    level: 3,
    label: "TRANSB_RO_PORTOVE_6",
    data: {
      Corridor: "N",
      Flow_volume: 40563.671999999555,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.5752480000099,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 167.29361812723667,
      total_revenue: 47563957.75519054,
      total_volume: 40563.671999999555,
    },
  },
  {
    id: 19,
    level: 4,
    label: "PORT_SANTA_CDP_6",
    data: {
      Corridor: "N",
      Flow_volume: 20281.835999999777,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.5752480000197,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 138,
      total_revenue: 23781978.877595466,
      total_volume: 20281.835999999777,
    },
  },
  {
    id: 176,
    level: 3,
    label: "TRANSB_RO_PORTOVE_5",
    data: {
      Corridor: "N",
      Flow_volume: 139999.99999999956,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1168.8198080000013,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 220.89452284486856,
      total_revenue: 163634773.11999968,
      total_volume: 139999.99999999956,
    },
  },
  {
    id: 20,
    level: 4,
    label: "PORT_SANTA_CDP_5",
    data: {
      Corridor: "N",
      Flow_volume: 69999.99999999978,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1168.819808000003,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 138,
      total_revenue: 81817386.55999994,
      total_volume: 69999.99999999978,
    },
  },
  {
    id: 177,
    level: 3,
    label: "TRANSB_TO_TIPA_1",
    data: {
      Corridor: "CN",
      Flow_volume: 155592.14657808945,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2466.041144076357,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 238.49870565202852,
      total_revenue: 383696635.1567279,
      total_volume: 155592.14657808945,
    },
  },
  {
    id: 179,
    level: 3,
    label: "TRANSB_MT_TRO_3",
    data: {
      Corridor: "CS",
      Flow_volume: 788216.0000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1552.0593390551421,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 215.5623337546462,
      total_revenue: 1223358003.9926882,
      total_volume: 788216.0000000002,
    },
  },
  {
    id: 14,
    level: 4,
    label: "PORT_SANTO_TGG_3",
    data: {
      Corridor: "CS",
      Flow_volume: 615100,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1448.822700216517,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 306.2328697948067,
      total_revenue: 891170842.9031796,
      total_volume: 615100,
    },
  },
  {
    id: 3,
    level: 4,
    label: "PORT_SANTO_TXXXIX_3",
    data: {
      Corridor: "CS",
      Flow_volume: 121269.99999999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2400.839877,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 458.7621261258038,
      total_revenue: 291149851.88378996,
      total_volume: 121269.99999999999,
    },
  },
  {
    id: 180,
    level: 3,
    label: "TRANSB_MT_TRO_1",
    data: {
      Corridor: "CS",
      Flow_volume: 842802.3379866991,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2449.0313206166206,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 197.98811632444952,
      total_revenue: 2064049322.818341,
      total_volume: 842802.3379866991,
    },
  },
  {
    id: 16,
    level: 4,
    label: "PORT_SANTO_TGG_1",
    data: {
      Corridor: "CS",
      Flow_volume: 627826.3999999997,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2485.9515820595066,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 255.0000882493378,
      total_revenue: 1560746032.3387237,
      total_volume: 627826.3999999997,
    },
  },
  {
    id: 5,
    level: 4,
    label: "PORT_SANTO_TXXXIX_1",
    data: {
      Corridor: "CS",
      Flow_volume: 130000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2440.117421,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 483.8454030998977,
      total_revenue: 317215264.73,
      total_volume: 130000,
    },
  },
  {
    id: 181,
    level: 3,
    label: "TRANSB_MA_PPF_2",
    data: {
      Corridor: "CN",
      Flow_volume: 170559.94,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2321.465824103649,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 175.69486028176877,
      total_revenue: 395949071.6711689,
      total_volume: 170559.94,
    },
  },
  {
    id: 182,
    level: 3,
    label: "TRANSB_MA_PPF_1",
    data: {
      Corridor: "CN",
      Flow_volume: 159804.16,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2255.9404563080784,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 209.89032481151608,
      total_revenue: 360508669.6303292,
      total_volume: 159804.16,
    },
  },
  {
    id: 183,
    level: 3,
    label: "TRANSB_GO_TRV_9",
    data: {
      Corridor: "CS",
      Flow_volume: 57218.28066666682,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2196.3955673300943,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 216.7302540844286,
      total_revenue: 125673978.02651623,
      total_volume: 57218.28066666682,
    },
  },
  {
    id: 185,
    level: 3,
    label: "TRANSB_GO_TRV_7",
    data: {
      Corridor: "CS",
      Flow_volume: 162329.8487501418,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 188.70048519318814,
      total_revenue: 192451958.2676278,
      total_volume: 162329.8487501418,
    },
  },
  {
    id: 186,
    level: 3,
    label: "TRANSB_GO_TRV_6",
    data: {
      Corridor: "CS",
      Flow_volume: 129999.99999999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575248,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 188.47409518925286,
      total_revenue: 152434782.24,
      total_volume: 129999.99999999999,
    },
  },
  {
    id: 187,
    level: 3,
    label: "TRANSB_GO_TRV_5",
    data: {
      Corridor: "CS",
      Flow_volume: 270000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1102.1616078306524,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 177.11018932431745,
      total_revenue: 297583634.1142762,
      total_volume: 270000,
    },
  },
  {
    id: 188,
    level: 3,
    label: "TRANSB_GO_TRV_4",
    data: {
      Corridor: "CS",
      Flow_volume: 270000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1164.0309753210176,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 211.20374271940494,
      total_revenue: 314288363.33667475,
      total_volume: 270000,
    },
  },
  {
    id: 189,
    level: 3,
    label: "TRANSB_GO_TRV_3",
    data: {
      Corridor: "CS",
      Flow_volume: 142940.372656685,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1171.9346408409676,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 224.06488715944002,
      total_revenue: 167516774.29108623,
      total_volume: 142940.372656685,
    },
  },
  {
    id: 190,
    level: 3,
    label: "TRANSB_GO_TRV_2",
    data: {
      Corridor: "CS",
      Flow_volume: 121232.06539167209,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2267.745747838997,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 236.27849042869624,
      total_revenue: 274923500.7937036,
      total_volume: 121232.06539167209,
    },
  },
  {
    id: 15,
    level: 4,
    label: "PORT_SANTO_TGG_2",
    data: {
      Corridor: "CS",
      Flow_volume: 625846.78,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2476.802823471595,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 288.8433475210348,
      total_revenue: 1550099071.7646062,
      total_volume: 625846.78,
    },
  },
  {
    id: 191,
    level: 3,
    label: "TRANSB_GO_TRV_1",
    data: {
      Corridor: "CS",
      Flow_volume: 259719.2959516429,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2485.7957857559513,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 204.90299260639443,
      total_revenue: 645609131.3560966,
      total_volume: 259719.2959516429,
    },
  },
  {
    id: 193,
    level: 3,
    label: "TRANSB_MA_PPF_3",
    data: {
      Corridor: "CN",
      Flow_volume: 119317.52400000005,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1331.6823633207387,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 165.13199744724048,
      total_revenue: 158893042.34589902,
      total_volume: 119317.52400000005,
    },
  },
  {
    id: 208,
    level: 3,
    label: "TRANSB_MT_TRO_2",
    data: {
      Corridor: "CS",
      Flow_volume: 665560.1270133001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2409.3980450761655,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 220.28376756003053,
      total_revenue: 1603599268.9064896,
      total_volume: 665560.1270133001,
    },
  },
  {
    id: 4,
    level: 4,
    label: "PORT_SANTO_TXXXIX_2",
    data: {
      Corridor: "CS",
      Flow_volume: 73730,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2435.207728,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 278.4894929143246,
      total_revenue: 179547865.78544,
      total_volume: 73730,
    },
  },
  {
    id: 209,
    level: 3,
    label: "TRANSB_MA_PPF_4",
    data: {
      Corridor: "CN",
      Flow_volume: 174031.40699999995,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1802.5797416300575,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 141.34438861171733,
      total_revenue: 313705488.66557527,
      total_volume: 174031.40699999995,
    },
  },
  {
    id: 210,
    level: 3,
    label: "TRANSB_MA_PPF_6",
    data: {
      Corridor: "CN",
      Flow_volume: 132638.513,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.5752479999999,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 114.9489035513248,
      total_revenue: 155528637.27532622,
      total_volume: 132638.513,
    },
  },
  {
    id: 211,
    level: 3,
    label: "TRANSB_MS_CHAPADA_9",
    data: {
      Corridor: "CS",
      Flow_volume: 4480.926499999966,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2382.9875363474384,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 144.53294798844001,
      total_revenue: 10677992.00078887,
      total_volume: 4480.926499999966,
    },
  },
  {
    id: 213,
    level: 3,
    label: "TRANSB_MS_CHAPADA_7",
    data: {
      Corridor: "CS",
      Flow_volume: 60000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1177.6916676358935,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 127.15746545231758,
      total_revenue: 70661500.05815361,
      total_volume: 60000,
    },
  },
  {
    id: 214,
    level: 3,
    label: "TRANSB_MS_CHAPADA_6",
    data: {
      Corridor: "CS",
      Flow_volume: 33124.662500000006,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1160.4912988934652,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 131.80288765699447,
      total_revenue: 38440882.61003266,
      total_volume: 33124.662500000006,
    },
  },
  {
    id: 215,
    level: 3,
    label: "TRANSB_MS_CHAPADA_5",
    data: {
      Corridor: "CS",
      Flow_volume: 10000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1168.8198079999997,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 170.7338979695,
      total_revenue: 11688198.079999998,
      total_volume: 10000,
    },
  },
  {
    id: 216,
    level: 3,
    label: "TRANSB_MS_CHAPADA_4",
    data: {
      Corridor: "CS",
      Flow_volume: 11187.196,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1164.087744,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 263.47642687786004,
      total_revenue: 13022877.753325824,
      total_volume: 11187.196,
    },
  },
  {
    id: 217,
    level: 3,
    label: "TRANSB_MS_CHAPADA_3",
    data: {
      Corridor: "CS",
      Flow_volume: 8812.804,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1174.7965829999998,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 288.7734772493428,
      total_revenue: 10353252.025848731,
      total_volume: 8812.804,
    },
  },
  {
    id: 218,
    level: 3,
    label: "TRANSB_MS_CHAPADA_2",
    data: {
      Corridor: "CS",
      Flow_volume: 9363.433,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1590.0045720410449,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 201.06591197656667,
      total_revenue: 14887901.28,
      total_volume: 9363.433,
    },
  },
  {
    id: 219,
    level: 3,
    label: "TRANSB_MS_CHAPADA_1",
    data: {
      Corridor: "CS",
      Flow_volume: 24251.535,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2050.4430214417357,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 203.52262930746414,
      total_revenue: 49726390.7,
      total_volume: 24251.535,
    },
  },
  {
    id: 220,
    level: 3,
    label: "TRANSB_MG_TIUB_7",
    data: {
      Corridor: "E",
      Flow_volume: 76091.465,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.5611260000003,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 119.51863632965001,
      total_revenue: 90211082.9243896,
      total_volume: 76091.465,
    },
  },
  {
    id: 6,
    level: 4,
    label: "PORT_SANTO_TIP_7",
    data: {
      Corridor: "CS",
      Flow_volume: 91000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.5611260000003,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 135.4187469482,
      total_revenue: 107886062.46600002,
      total_volume: 91000,
    },
  },
  {
    id: 192,
    level: 4,
    label: "PORT_VITOR_VLI_7",
    data: {
      Corridor: "E",
      Flow_volume: 53134.71149999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 135.4187469482,
      total_revenue: 62994448.395625144,
      total_volume: 53134.71149999999,
    },
  },
  {
    id: 221,
    level: 3,
    label: "TRANSB_MG_TIUB_6",
    data: {
      Corridor: "E",
      Flow_volume: 9509.472000000005,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575248,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 116.44392781638001,
      total_revenue: 11150571.488749063,
      total_volume: 9509.472000000005,
    },
  },
  {
    id: 194,
    level: 4,
    label: "PORT_VITOR_VLI_6",
    data: {
      Corridor: "E",
      Flow_volume: 10867.18800000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575248,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 148.3187561035,
      total_revenue: 12742595.664162636,
      total_volume: 10867.18800000001,
    },
  },
  {
    id: 222,
    level: 3,
    label: "TRANSB_MG_TIUB_5",
    data: {
      Corridor: "E",
      Flow_volume: 12807.94399999967,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1168.819808,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 154.10509701716668,
      total_revenue: 14970178.646954367,
      total_volume: 12807.943999999672,
    },
  },
  {
    id: 207,
    level: 4,
    label: "PORT_VITOR_VLI_5",
    data: {
      Corridor: "E",
      Flow_volume: 37492.63049999966,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1168.819808,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 166.28125,
      total_revenue: 43822129.182424545,
      total_volume: 37492.63049999966,
    },
  },
  {
    id: 223,
    level: 3,
    label: "TRANSB_MA_PPF_5",
    data: {
      Corridor: "CN",
      Flow_volume: 171401.06550000003,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1651.769478578331,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 140.91325809440434,
      total_revenue: 283115048.5887054,
      total_volume: 171401.06550000003,
    },
  },
  {
    id: 225,
    level: 3,
    label: "TRANSB_MG_TIUB_2",
    data: {
      Corridor: "E",
      Flow_volume: 27593.14716666566,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.7633010036993,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 206.21405647379228,
      total_revenue: 68452066.8502584,
      total_volume: 27593.147166665665,
    },
  },
  {
    id: 195,
    level: 4,
    label: "PORT_VITOR_VLI_2",
    data: {
      Corridor: "E",
      Flow_volume: 98999.99999999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2481.31688,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 119.8104367584192,
      total_revenue: 245650371.11999995,
      total_volume: 98999.99999999999,
    },
  },
  {
    id: 226,
    level: 3,
    label: "TRANSB_MG_TIUB_1",
    data: {
      Corridor: "E",
      Flow_volume: 66269.35133333121,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2435.997934762449,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 204.01347224385077,
      total_revenue: 161432002.98604193,
      total_volume: 66269.3513333312,
    },
  },
  {
    id: 7,
    level: 4,
    label: "PORT_SANTO_TIP_1",
    data: {
      Corridor: "CS",
      Flow_volume: 62000.000000000095,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2486.3195350000005,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 116.55696777544868,
      total_revenue: 154151811.17000026,
      total_volume: 62000.000000000095,
    },
  },
  {
    id: 196,
    level: 4,
    label: "PORT_VITOR_VLI_1",
    data: {
      Corridor: "E",
      Flow_volume: 67999.9999999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2486.319535,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 117.09553995773516,
      total_revenue: 169069728.37999976,
      total_volume: 67999.9999999999,
    },
  },
  {
    id: 227,
    level: 3,
    label: "TRANSB_MG_TIA_7",
    data: {
      Corridor: "E",
      Flow_volume: 82177.958,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 104.08564297340742,
      total_revenue: 97426992.41886072,
      total_volume: 82177.958,
    },
  },
  {
    id: 228,
    level: 3,
    label: "TRANSB_MG_TIA_6",
    data: {
      Corridor: "E",
      Flow_volume: 12224.904000000006,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1172.575248,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 98.011206278895,
      total_revenue: 14334619.8395762,
      total_volume: 12224.904000000006,
    },
  },
  {
    id: 229,
    level: 3,
    label: "TRANSB_MG_TIA_5",
    data: {
      Corridor: "E",
      Flow_volume: 62177.31699999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1168.8198079999997,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 143.07079658860408,
      total_revenue: 72674079.71789512,
      total_volume: 62177.316999999995,
    },
  },
  {
    id: 232,
    level: 3,
    label: "TRANSB_MG_TIA_2",
    data: {
      Corridor: "E",
      Flow_volume: 157192.0773333343,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1971.420387257597,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 241.72434943872648,
      total_revenue: 309891665.97030807,
      total_volume: 157192.0773333343,
    },
  },
  {
    id: 233,
    level: 3,
    label: "TRANSB_MG_TIA_1",
    data: {
      Corridor: "E",
      Flow_volume: 219144.66550003516,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2451.4060411238897,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 203.84189206909326,
      total_revenue: 537212556.8868603,
      total_volume: 219144.66550003516,
    },
  },
  {
    id: 234,
    level: 3,
    label: "TRANSB_MA_PPF_9",
    data: {
      Corridor: "CN",
      Flow_volume: 35000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1217.392168,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 157.5500030518,
      total_revenue: 42608725.88,
      total_volume: 35000,
    },
  },
  {
    id: 33,
    level: 4,
    label: "PORT_SÃOLU_TEGRAM_9",
    data: {
      Corridor: "CN",
      Flow_volume: 35000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1217.392168,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 157.5500030518,
      total_revenue: 42608725.88,
      total_volume: 35000,
    },
  },
  {
    id: 235,
    level: 3,
    label: "TRANSB_MA_PPF_8",
    data: {
      Corridor: "CN",
      Flow_volume: 81289.548,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.2021209999998,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 111.471896997856,
      total_revenue: 97401308.8287313,
      total_volume: 81289.54800000001,
    },
  },
  {
    id: 236,
    level: 3,
    label: "TRANSB_MA_PPF_7",
    data: {
      Corridor: "CN",
      Flow_volume: 87579.0645,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1185.561126,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 109.59840992832083,
      total_revenue: 103830334.32264663,
      total_volume: 87579.06450000001,
    },
  },
  {
    id: 239,
    level: 3,
    label: "TRANSB_TO_TIPN_8",
    data: {
      Corridor: "CN",
      Flow_volume: 90000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.2021210000003,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 245.52427111133332,
      total_revenue: 107838190.89000002,
      total_volume: 90000,
    },
  },
  {
    id: 206,
    level: 4,
    label: "PORT_SÃOLU_VLI_8",
    data: {
      Corridor: "CN",
      Flow_volume: 17999.99999999994,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1198.202121,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 219.6963969824,
      total_revenue: 21567638.177999932,
      total_volume: 17999.99999999994,
    },
  },
  {
    id: 241,
    level: 2,
    label: "CRUSH_PR_ACR_1",
    data: {
      Corridor: "SW",
      Flow_volume: 101400.94860128518,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1690.9385550849347,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 163.18377523177872,
      total_revenue: 215954648.34224647,
      total_volume: 127712.8892074965,
    },
  },
  {
    id: 72,
    level: 4,
    label: "PORT_PARAN_BUNGE_1",
    data: {
      Corridor: "SW",
      Flow_volume: 71939.47050825041,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2384.478159229273,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 145.04143071783332,
      total_revenue: 171538096.2134415,
      total_volume: 71939.47050825041,
    },
  },
  {
    id: 245,
    level: 2,
    label: "CRUSH_PI_URU_6",
    data: {
      Corridor: "CN",
      Flow_volume: 59199.719189846146,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1875.5996509880756,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 151.89802849363463,
      total_revenue: 344359569.2339841,
      total_volume: 183599.71918984613,
    },
  },
  {
    id: 58,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_6",
    data: {
      Corridor: "NE",
      Flow_volume: 102830.08468984615,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2338.433664,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 307.15234015935,
      total_revenue: 240461331.71070725,
      total_volume: 102830.08468984615,
    },
  },
  {
    id: 250,
    level: 2,
    label: "CRUSH_BA_LEM_8",
    data: {
      Corridor: "NE",
      Flow_volume: 177376.56750000303,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2048.2030165672245,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 102.71584761675625,
      total_revenue: 520272584.12431836,
      total_volume: 254014.167500003,
    },
  },
  {
    id: 26,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_8",
    data: {
      Corridor: "NE",
      Flow_volume: 93449.6,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2365.320198,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 281.1154138352,
      total_revenue: 221038226.3750208,
      total_volume: 93449.6,
    },
  },
  {
    id: 252,
    level: 2,
    label: "CRUSH_GO_LUZ_7",
    data: {
      Corridor: "E",
      Flow_volume: 36760.3989385573,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1776.5944524704407,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 129.7175398644534,
      total_revenue: 233089339.34102398,
      total_volume: 131200.0828421489,
    },
  },
  {
    id: 66,
    level: 4,
    label: "PORT_PARAN_BUNGE_7",
    data: {
      Corridor: "SW",
      Flow_volume: 70900,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2681.4264225218617,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 172.07655972035,
      total_revenue: 190113133.3568,
      total_volume: 70900,
    },
  },
  {
    id: 253,
    level: 2,
    label: "CRUSH_GO_LUZ_8",
    data: {
      Corridor: "E",
      Flow_volume: 44200.46444112047,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1695.42751132182,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 142.2144553650671,
      total_revenue: 236847386.25516915,
      total_volume: 139697.73680887948,
    },
  },
  {
    id: 65,
    level: 4,
    label: "PORT_PARAN_BUNGE_8",
    data: {
      Corridor: "SW",
      Flow_volume: 90000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2619.29205619,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 167.64086115525998,
      total_revenue: 235736285.0571,
      total_volume: 90000,
    },
  },
  {
    id: 255,
    level: 2,
    label: "CRUSH_MS_DOU_1",
    data: {
      Corridor: "SW",
      Flow_volume: 94419.55209357031,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1550.484527402295,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 125.068773081475,
      total_revenue: 351558730.0776109,
      total_volume: 226741.20500036053,
    },
  },
  {
    id: 256,
    level: 2,
    label: "CRUSH_MS_DOU_2",
    data: {
      Corridor: "SW",
      Flow_volume: 55232.039149892415,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1803.2177270278264,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 124.29353092545001,
      total_revenue: 318853415.14453375,
      total_volume: 176824.6897561768,
    },
  },
  {
    id: 113,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_2",
    data: {
      Corridor: "SW",
      Flow_volume: 82711.9416968578,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2325.858576,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 178.33210611923334,
      total_revenue: 192376278.9332487,
      total_volume: 82711.9416968578,
    },
  },
  {
    id: 260,
    level: 2,
    label: "CRUSH_PR_ACR_2",
    data: {
      Corridor: "SW",
      Flow_volume: 124915.01439830616,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2271.7114436084885,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 218.12647142189778,
      total_revenue: 369485827.0527248,
      total_volume: 162646.46114817157,
    },
  },
  {
    id: 71,
    level: 4,
    label: "PORT_PARAN_BUNGE_2",
    data: {
      Corridor: "SW",
      Flow_volume: 55351.000012000004,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 3325.793796864275,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 78.4021738532,
      total_revenue: 184086012.490144,
      total_volume: 55351.000012000004,
    },
  },
  {
    id: 261,
    level: 2,
    label: "CRUSH_PR_ACR_3",
    data: {
      Corridor: "SW",
      Flow_volume: 102499.85828159303,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1631.3239606338545,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 193.0231451858975,
      total_revenue: 209702895.0964693,
      total_volume: 128547.67057733204,
    },
  },
  {
    id: 70,
    level: 4,
    label: "PORT_PARAN_BUNGE_3",
    data: {
      Corridor: "SW",
      Flow_volume: 34800,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2400.839877,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 78.4021738532,
      total_revenue: 83549227.71959999,
      total_volume: 34800,
    },
  },
  {
    id: 262,
    level: 2,
    label: "CRUSH_PR_ACR_4",
    data: {
      Corridor: "SW",
      Flow_volume: 57265.87538888244,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2535.278156394969,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 204.69406272306088,
      total_revenue: 187564290.29393473,
      total_volume: 73981.74035492864,
    },
  },
  {
    id: 69,
    level: 4,
    label: "PORT_PARAN_BUNGE_4",
    data: {
      Corridor: "SW",
      Flow_volume: 18200,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 4674,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 78.4021738532,
      total_revenue: 85066800,
      total_volume: 18200,
    },
  },
  {
    id: 111,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_4",
    data: {
      Corridor: "SW",
      Flow_volume: 95820.4,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2296.25502,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 114.92728355333334,
      total_revenue: 220028074.518408,
      total_volume: 95820.4,
    },
  },
  {
    id: 263,
    level: 2,
    label: "CRUSH_PR_ACR_5",
    data: {
      Corridor: "SW",
      Flow_volume: 89242.36666678461,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1884.6612782005832,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 146.9919092816292,
      total_revenue: 235292749.1177859,
      total_volume: 124846.17360125115,
    },
  },
  {
    id: 68,
    level: 4,
    label: "PORT_PARAN_BUNGE_5",
    data: {
      Corridor: "SW",
      Flow_volume: 50000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2309.401094,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 83.2538253013,
      total_revenue: 115470054.69999999,
      total_volume: 50000,
    },
  },
  {
    id: 63,
    level: 4,
    label: "PORT_PARAN_CORREDOR_EXPORTACAO_5",
    data: {
      Corridor: "SW",
      Flow_volume: 299.99999999999886,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2418.309322,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 78.4021738532,
      total_revenue: 725492.7965999973,
      total_volume: 299.99999999999886,
    },
  },
  {
    id: 264,
    level: 2,
    label: "CRUSH_BA_LEM_7",
    data: {
      Corridor: "NE",
      Flow_volume: 180451.86059999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2253.9709035064902,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 115.448273395775,
      total_revenue: 575263821.1484418,
      total_volume: 255222.38119999997,
    },
  },
  {
    id: 43,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_7",
    data: {
      Corridor: "NE",
      Flow_volume: 120022.11359999998,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2355.877802,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 281.1154138352,
      total_revenue: 282757433.17936224,
      total_volume: 120022.11359999998,
    },
  },
  {
    id: 265,
    level: 2,
    label: "CRUSH_BA_LEM_6",
    data: {
      Corridor: "NE",
      Flow_volume: 168986.789,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2109.1241925575887,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 114.93456995091667,
      total_revenue: 518039686.37692076,
      total_volume: 245618.389,
    },
  },
  {
    id: 266,
    level: 2,
    label: "CRUSH_BA_LEM_5",
    data: {
      Corridor: "NE",
      Flow_volume: 101462.01172000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1494.966225856195,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 130.3421753555714,
      total_revenue: 229722261.16093922,
      total_volume: 153663.84684,
    },
  },
  {
    id: 59,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_5",
    data: {
      Corridor: "NE",
      Flow_volume: 176557.35872,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2526.6701532682578,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 346.33085514811665,
      total_revenue: 446102208.6177012,
      total_volume: 176557.35872,
    },
  },
  {
    id: 267,
    level: 2,
    label: "CRUSH_BA_LEM_4",
    data: {
      Corridor: "NE",
      Flow_volume: 191304.19000000003,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1917.5844597351202,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 130.3421753555714,
      total_revenue: 513916067.6851952,
      total_volume: 268001.79000000004,
    },
  },
  {
    id: 60,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_4",
    data: {
      Corridor: "NE",
      Flow_volume: 99282.451,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2323.177600586065,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 357.49917154835555,
      total_revenue: 230650766.2944836,
      total_volume: 99282.451,
    },
  },
  {
    id: 268,
    level: 2,
    label: "CRUSH_BA_LEM_3",
    data: {
      Corridor: "NE",
      Flow_volume: 181238.94,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1994.5651377848517,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 131.06587124816664,
      total_revenue: 514471230.44484794,
      total_volume: 257936.54,
    },
  },
  {
    id: 91,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_3",
    data: {
      Corridor: "NE",
      Flow_volume: 126463.63350000004,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2343.5865900548524,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 377.43076283654665,
      total_revenue: 296378475.6002117,
      total_volume: 126463.63350000004,
    },
  },
  {
    id: 269,
    level: 2,
    label: "CRUSH_BA_LEM_2",
    data: {
      Corridor: "NE",
      Flow_volume: 208986.0336,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2015.6781311878892,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 144.9233288289,
      total_revenue: 578154044.1011802,
      total_volume: 286828.5542,
    },
  },
  {
    id: 92,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_2",
    data: {
      Corridor: "NE",
      Flow_volume: 134867.88371071266,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2349.346068738681,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 384.2785798560625,
      total_revenue: 316851332.3948683,
      total_volume: 134867.88371071263,
    },
  },
  {
    id: 270,
    level: 2,
    label: "CRUSH_BA_LEM_10",
    data: {
      Corridor: "NE",
      Flow_volume: 220537.60085000072,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1648.3298145949298,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 162.54728439517334,
      total_revenue: 449350698.93432105,
      total_volume: 272609.7016237901,
    },
  },
  {
    id: 93,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_10",
    data: {
      Corridor: "NE",
      Flow_volume: 75000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2283.370992,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 334.657153518,
      total_revenue: 171252824.4,
      total_volume: 75000,
    },
  },
  {
    id: 205,
    level: 4,
    label: "PORT_TMIB_TMIB_10",
    data: {
      Corridor: "NE",
      Flow_volume: 19537.611350000705,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2227.8152518306,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 393.6926042616,
      total_revenue: 43526188.549870215,
      total_volume: 19537.611350000705,
    },
  },
  {
    id: 272,
    level: 2,
    label: "CRUSH_GO_LUZ_10",
    data: {
      Corridor: "E",
      Flow_volume: 15728.714334960425,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1496.7459389849782,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 349.57394558683916,
      total_revenue: 66676850.15219257,
      total_volume: 44547.87443579746,
    },
  },
  {
    id: 114,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_10",
    data: {
      Corridor: "SW",
      Flow_volume: 28043.054014297195,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2266.704216,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 286.21459668989996,
      total_revenue: 63565308.76372317,
      total_volume: 28043.054014297195,
    },
  },
  {
    id: 276,
    level: 2,
    label: "CRUSH_PR_CMB_4",
    data: {
      Corridor: "SW",
      Flow_volume: 91247.20616570328,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1664.7269387192036,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 126.27464649133238,
      total_revenue: 259405347.36496243,
      total_volume: 155824.56277456647,
    },
  },
  {
    id: 278,
    level: 2,
    label: "CRUSH_PR_CMB_3",
    data: {
      Corridor: "SW",
      Flow_volume: 26759.940000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2844.602351782207,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 106.37370294245001,
      total_revenue: 260175798.36257324,
      total_volume: 91462.9766088632,
    },
  },
  {
    id: 112,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_3",
    data: {
      Corridor: "SW",
      Flow_volume: 86320.4,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2293.0339590000003,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 133.18983840340002,
      total_revenue: 197935608.5544636,
      total_volume: 86320.4,
    },
  },
  {
    id: 279,
    level: 2,
    label: "CRUSH_PR_CMB_2",
    data: {
      Corridor: "SW",
      Flow_volume: 80897.76000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1826.9168021394419,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 84.4823612424,
      total_revenue: 266595833.99570984,
      total_volume: 145926.6419157721,
    },
  },
  {
    id: 280,
    level: 2,
    label: "CRUSH_PR_CMB_1",
    data: {
      Corridor: "SW",
      Flow_volume: 98873.60843380126,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1724.4981076903102,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 82.80965773962001,
      total_revenue: 283958948.7432747,
      total_volume: 164661.79201761627,
    },
  },
  {
    id: 115,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_1",
    data: {
      Corridor: "SW",
      Flow_volume: 58585.00000000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2330.547807,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 133.18983840340002,
      total_revenue: 136535143.273095,
      total_volume: 58585.00000000001,
    },
  },
  {
    id: 281,
    level: 2,
    label: "CRUSH_PR_ACR_8",
    data: {
      Corridor: "SW",
      Flow_volume: 96966.14217057225,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2275.108754685705,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 123.33736730984018,
      total_revenue: 304637938.04762065,
      total_volume: 133900.38494652306,
    },
  },
  {
    id: 282,
    level: 2,
    label: "CRUSH_PR_ACR_7",
    data: {
      Corridor: "SW",
      Flow_volume: 107522.88745458362,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2119.691178440098,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 131.353075267888,
      total_revenue: 303561106.59320664,
      total_volume: 143210.06271139946,
    },
  },
  {
    id: 283,
    level: 2,
    label: "CRUSH_PR_ACR_6",
    data: {
      Corridor: "SW",
      Flow_volume: 97433.38725387616,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2585.01787710318,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 122.98809215214182,
      total_revenue: 347146782.6880268,
      total_volume: 134291.83053737564,
    },
  },
  {
    id: 67,
    level: 4,
    label: "PORT_PARAN_BUNGE_6",
    data: {
      Corridor: "SW",
      Flow_volume: 59100,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 3094.4239864636215,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 69.4417160421,
      total_revenue: 182880457.60000002,
      total_volume: 59100,
    },
  },
  {
    id: 109,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_6",
    data: {
      Corridor: "SW",
      Flow_volume: 118541.1344937584,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2322.03144,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 178.92368910944998,
      total_revenue: 275256241.2277755,
      total_volume: 118541.1344937584,
    },
  },
  {
    id: 285,
    level: 2,
    label: "CRUSH_MS_DOU_7",
    data: {
      Corridor: "SW",
      Flow_volume: 39060.9705012816,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2036.2416050753604,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 82.193034019295,
      total_revenue: 331473306.8497289,
      total_volume: 162786.82550416762,
    },
  },
  {
    id: 108,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_7",
    data: {
      Corridor: "SW",
      Flow_volume: 125000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2339.376371,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 220.13778651442502,
      total_revenue: 292422046.375,
      total_volume: 125000,
    },
  },
  {
    id: 287,
    level: 2,
    label: "CRUSH_BA_LEM_9",
    data: {
      Corridor: "NE",
      Flow_volume: 165243.8120742629,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1877.0117241593282,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 144.95714584081668,
      total_revenue: 442150892.5473875,
      total_volume: 235561.0712796251,
    },
  },
  {
    id: 25,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_9",
    data: {
      Corridor: "NE",
      Flow_volume: 61380,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2341.266756,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 334.657153518,
      total_revenue: 143706953.48328,
      total_volume: 61380,
    },
  },
  {
    id: 197,
    level: 4,
    label: "PORT_TMIB_TMIB_9",
    data: {
      Corridor: "NE",
      Flow_volume: 5065.410595745401,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2285.9313087652,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 393.6926042616,
      total_revenue: 11579180.672565395,
      total_volume: 5065.410595745401,
    },
  },
  {
    id: 288,
    level: 2,
    label: "CRUSH_MT_RON_5",
    data: {
      Corridor: "CS",
      Flow_volume: 182866.8409904476,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2657.157159634211,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 211.49387418538143,
      total_revenue: 931936868.0450892,
      total_volume: 350727.0409904476,
    },
  },
  {
    id: 293,
    level: 2,
    label: "CRUSH_RS_RGD_11",
    data: {
      Corridor: "S",
      Flow_volume: 21959.2655015729,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2717.170631659744,
      State: "NA",
      TimePeriod: 11,
      Total_cost_per_ton: 362.1423817287,
      total_revenue: 75265653.50216663,
      total_volume: 27700.009938717652,
    },
  },
  {
    id: 64,
    level: 4,
    label: "PORT_PARAN_CORREDOR_EXPORTACAO_11",
    data: {
      Corridor: "SW",
      Flow_volume: 21959.2655015729,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2219.922188,
      State: "NA",
      TimePeriod: 11,
      Total_cost_per_ton: 362.1423817287,
      total_revenue: 48747860.71912463,
      total_volume: 21959.2655015729,
    },
  },
  {
    id: 296,
    level: 2,
    label: "CRUSH_PR_PGR_9",
    data: {
      Corridor: "SW",
      Flow_volume: 7511.806504746699,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 3118.9099908009057,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 88.1054767494,
      total_revenue: 44021507.19932599,
      total_volume: 14114.388465574699,
    },
  },
  {
    id: 62,
    level: 4,
    label: "PORT_PARAN_CORREDOR_EXPORTACAO_9",
    data: {
      Corridor: "SW",
      Flow_volume: 5554.860519043919,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2324.666118,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 88.1054767494,
      total_revenue: 12913196.038837293,
      total_volume: 5554.860519043919,
    },
  },
  {
    id: 106,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_9",
    data: {
      Corridor: "SW",
      Flow_volume: 1956.9459857027796,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2324.666118,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 88.1054767494,
      total_revenue: 4549246.0277193645,
      total_volume: 1956.9459857027796,
    },
  },
  {
    id: 297,
    level: 2,
    label: "CRUSH_MT_RON_6",
    data: {
      Corridor: "CS",
      Flow_volume: 128882.59164516126,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1730.6200999892064,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 174.8216287129875,
      total_revenue: 485209801.76850206,
      total_volume: 280367.59874193545,
    },
  },
  {
    id: 298,
    level: 2,
    label: "CRUSH_PR_PGR_8",
    data: {
      Corridor: "SW",
      Flow_volume: 105286.05928780485,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2144.412132683678,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 153.10658297651537,
      total_revenue: 298398077.5774076,
      total_volume: 139151.45928780484,
    },
  },
  {
    id: 107,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_8",
    data: {
      Corridor: "SW",
      Flow_volume: 88911.34109218857,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2348.752629,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 218.30347806213334,
      total_revenue: 208830746.13819364,
      total_volume: 88911.34109218857,
    },
  },
  {
    id: 299,
    level: 2,
    label: "CRUSH_PR_PGR_7",
    data: {
      Corridor: "SW",
      Flow_volume: 88702.89745594413,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2468.862475525429,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 140.58585744416362,
      total_revenue: 299973944.1965946,
      total_volume: 121502.89745594413,
    },
  },
  {
    id: 300,
    level: 2,
    label: "CRUSH_PR_PGR_6",
    data: {
      Corridor: "SW",
      Flow_volume: 77349.78382358611,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2706.627351746083,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 142.92540589094287,
      total_revenue: 300841045.03758246,
      total_volume: 111149.7838235861,
    },
  },
  {
    id: 301,
    level: 2,
    label: "CRUSH_BA_LEM_1",
    data: {
      Corridor: "NE",
      Flow_volume: 261716.3815328187,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1405.5723915715257,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 119.04427525274545,
      total_revenue: 475842451.48566407,
      total_volume: 338539.98153281864,
    },
  },
  {
    id: 94,
    level: 4,
    label: "PORT_SALVA_COTEGIPE_1",
    data: {
      Corridor: "NE",
      Flow_volume: 102321.98900000314,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2385.0197621182883,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 400.3756768109875,
      total_revenue: 244039965.86425763,
      total_volume: 102321.98900000314,
    },
  },
  {
    id: 302,
    level: 2,
    label: "CRUSH_PR_PGR_5",
    data: {
      Corridor: "SW",
      Flow_volume: 67921.64247369296,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2648.001458733915,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 212.18655869272726,
      total_revenue: 266711056.19641483,
      total_volume: 100721.64247369296,
    },
  },
  {
    id: 110,
    level: 4,
    label: "PORT_PARAN_SOCEPPAR_5",
    data: {
      Corridor: "SW",
      Flow_volume: 41593.59430604982,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2309.401094,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 133.18983840340002,
      total_revenue: 96056292.19378361,
      total_volume: 41593.59430604982,
    },
  },
  {
    id: 303,
    level: 2,
    label: "CRUSH_PR_PGR_4",
    data: {
      Corridor: "SW",
      Flow_volume: 75247.6064708709,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2691.12209072086,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 135.50726425113612,
      total_revenue: 293636422.0987288,
      total_volume: 109113.0064708709,
    },
  },
  {
    id: 304,
    level: 2,
    label: "CRUSH_PR_CMB_7",
    data: {
      Corridor: "SW",
      Flow_volume: 92445.16928158543,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1746.9356795590315,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 71.68975950000589,
      total_revenue: 290410697.0041722,
      total_volume: 166240.06275805118,
    },
  },
  {
    id: 305,
    level: 2,
    label: "CRUSH_PR_CMB_8",
    data: {
      Corridor: "SW",
      Flow_volume: 76247.2061657033,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1828.1384665682401,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 69.87042284561042,
      total_revenue: 275820827.14361954,
      total_volume: 150875.23849404423,
    },
  },
  {
    id: 306,
    level: 2,
    label: "CRUSH_PR_PGR_1",
    data: {
      Corridor: "SW",
      Flow_volume: 134752.08,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1758.9659220205954,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 156.38091969213255,
      total_revenue: 294516469.59582055,
      total_volume: 167437.27999999997,
    },
  },
  {
    id: 307,
    level: 2,
    label: "CRUSH_PR_PGR_10",
    data: {
      Corridor: "SW",
      Flow_volume: 44162.24667933677,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2116.3696205349756,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 535.2264815807137,
      total_revenue: 126374908.11464311,
      total_volume: 59713.06093625464,
    },
  },
  {
    id: 308,
    level: 2,
    label: "CRUSH_MS_DOU_8",
    data: {
      Corridor: "SW",
      Flow_volume: 38615.53943025629,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2043.2539587249507,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 81.993034019295,
      total_revenue: 336087437.3444915,
      total_volume: 164486.3752297437,
    },
  },
  {
    id: 309,
    level: 2,
    label: "CRUSH_PR_PGR_2",
    data: {
      Corridor: "SW",
      Flow_volume: 120251.65000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1828.396250227334,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 149.90239312155106,
      total_revenue: 277759006.24099773,
      total_volume: 151914.01,
    },
  },
  {
    id: 310,
    level: 2,
    label: "CRUSH_MT_RON_7",
    data: {
      Corridor: "CS",
      Flow_volume: 162019.22127168567,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1584.6118854054218,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 181.3227978741303,
      total_revenue: 473011118.4141635,
      total_volume: 298502.82127168565,
    },
  },
  {
    id: 311,
    level: 2,
    label: "CRUSH_MT_RON_9",
    data: {
      Corridor: "CS",
      Flow_volume: 87007.282610179,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2503.282824266531,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 184.34074131019364,
      total_revenue: 477340003.17752784,
      total_volume: 190685.60633670702,
    },
  },
  {
    id: 313,
    level: 2,
    label: "CRUSH_MT_NMU_1",
    data: {
      Corridor: "CS",
      Flow_volume: 190463.47069173944,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2874.4902948130634,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 98.65474362345,
      total_revenue: 774791467.0627501,
      total_volume: 269540.47069173947,
    },
  },
  {
    id: 314,
    level: 2,
    label: "CRUSH_MT_NMU_2",
    data: {
      Corridor: "CS",
      Flow_volume: 190592.56770987238,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2831.1517574282593,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 108.07146092626002,
      total_revenue: 765913092.2098699,
      total_volume: 270530.5677098724,
    },
  },
  {
    id: 315,
    level: 2,
    label: "CRUSH_MT_NMU_3",
    data: {
      Corridor: "CS",
      Flow_volume: 203963.0464633476,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2778.131241698678,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 142.6477288272343,
      total_revenue: 793498308.7289791,
      total_volume: 285623.0464633476,
    },
  },
  {
    id: 316,
    level: 2,
    label: "CRUSH_MT_NMU_4",
    data: {
      Corridor: "CS",
      Flow_volume: 182187.92305310653,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2797.790005698045,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 146.01804531614286,
      total_revenue: 730964390.5574503,
      total_volume: 261264.92305310653,
    },
  },
  {
    id: 317,
    level: 2,
    label: "CRUSH_MT_NMU_5",
    data: {
      Corridor: "CS",
      Flow_volume: 190318.25999999998,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2801.9617625566884,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 118.0047077233625,
      total_revenue: 757247706.6115787,
      total_volume: 270256.26,
    },
  },
  {
    id: 318,
    level: 2,
    label: "CRUSH_MT_NMU_6",
    data: {
      Corridor: "CS",
      Flow_volume: 169314.9052132795,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2811.232908944806,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 110.7035910552174,
      total_revenue: 690270424.2413414,
      total_volume: 245540.1052132795,
    },
  },
  {
    id: 319,
    level: 2,
    label: "CRUSH_MT_NMU_7",
    data: {
      Corridor: "CS",
      Flow_volume: 130106.06395980496,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2804.927547938861,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 110.88588632853913,
      total_revenue: 561143886.9040949,
      total_volume: 200056.46395980497,
    },
  },
  {
    id: 320,
    level: 2,
    label: "CRUSH_MT_NMU_8",
    data: {
      Corridor: "CS",
      Flow_volume: 130024.30524965933,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2844.6474828649834,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 106.6405030687857,
      total_revenue: 592612737.9109186,
      total_volume: 208325.54524965934,
    },
  },
  {
    id: 321,
    level: 2,
    label: "CRUSH_MT_RON_8",
    data: {
      Corridor: "CS",
      Flow_volume: 174123.21172081179,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1779.9208100957628,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 179.55689352530757,
      total_revenue: 609342081.4418521,
      total_volume: 342342.24241081177,
    },
  },
  {
    id: 322,
    level: 2,
    label: "CRUSH_MT_NMU_9",
    data: {
      Corridor: "CS",
      Flow_volume: 58455.95638982101,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2323.9210689684505,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 165.08010282831276,
      total_revenue: 375116755.6237665,
      total_volume: 161415.44591713458,
    },
  },
  {
    id: 323,
    level: 2,
    label: "CRUSH_MT_RON_2",
    data: {
      Corridor: "CS",
      Flow_volume: 111358.84698752499,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2686.954906772322,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 147.4191219564619,
      total_revenue: 771467172.657927,
      total_volume: 287115.786987525,
    },
  },
  {
    id: 324,
    level: 2,
    label: "CRUSH_MT_RON_3",
    data: {
      Corridor: "CS",
      Flow_volume: 209805.95353665255,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2570.9934210480037,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 199.1048000032644,
      total_revenue: 1053846253.0504187,
      total_volume: 409898.46353665256,
    },
  },
  {
    id: 325,
    level: 2,
    label: "CRUSH_MT_RON_4",
    data: {
      Corridor: "CS",
      Flow_volume: 192922.35329657988,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2618.080187176073,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 205.2785984804446,
      total_revenue: 976733993.687055,
      total_volume: 373072.6042965799,
    },
  },
  {
    id: 326,
    level: 2,
    label: "CRUSH_PR_CMB_5",
    data: {
      Corridor: "SW",
      Flow_volume: 77445.16928158546,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1629.4861410095566,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 81.7750260854766,
      total_revenue: 230100649.37544817,
      total_volume: 141210.5593195706,
    },
  },
  {
    id: 327,
    level: 2,
    label: "CRUSH_PR_CMB_6",
    data: {
      Corridor: "SW",
      Flow_volume: 71247.20616570328,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1933.29061869486,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 73.35522180926078,
      total_revenue: 262588353.00040048,
      total_volume: 135824.56277456647,
    },
  },
  {
    id: 328,
    level: 2,
    label: "CRUSH_PI_URU_3",
    data: {
      Corridor: "CN",
      Flow_volume: 78096.19961071279,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1810.3980933701546,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 148.463521886725,
      total_revenue: 374264027.0131706,
      total_volume: 206730.23705877736,
    },
  },
  {
    id: 329,
    level: 2,
    label: "CRUSH_PI_URU_2",
    data: {
      Corridor: "CN",
      Flow_volume: 98240.23999999989,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1678.8247499352624,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 136.4251146884357,
      total_revenue: 384372365.88986796,
      total_volume: 228953.2399999999,
    },
  },
  {
    id: 331,
    level: 2,
    label: "CRUSH_PI_URU_1",
    data: {
      Corridor: "CN",
      Flow_volume: 118496.86000000009,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1442.5640290308672,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 124.16583732855001,
      total_revenue: 355238403.0100323,
      total_volume: 246254.8600000001,
    },
  },
  {
    id: 332,
    level: 2,
    label: "CRUSH_MT_RON_1",
    data: {
      Corridor: "CS",
      Flow_volume: 201871.1846108632,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2667.0944197430963,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 155.71471742553157,
      total_revenue: 992584411.4483582,
      total_volume: 372159.4571608632,
    },
  },
  {
    id: 333,
    level: 2,
    label: "CRUSH_PR_PGR_3",
    data: {
      Corridor: "SW",
      Flow_volume: 111287.07352912909,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2025.1897676961041,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 170.33942584454252,
      total_revenue: 293961304.14697176,
      total_volume: 145152.47352912906,
    },
  },
  {
    id: 334,
    level: 1,
    label: "MT5_8",
    data: {
      Corridor: "E",
      Flow_volume: 105631.54263964688,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 8,
      Total_cost_per_ton: 216.90723686250385,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 184,
    level: 3,
    label: "TRANSB_GO_TRV_8",
    data: {
      Corridor: "CS",
      Flow_volume: 2750.9359165250276,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 158.86646745788,
      total_revenue: 0,
      total_volume: 2750.9359165250276,
    },
  },
  {
    id: 335,
    level: 1,
    label: "GO2_7",
    data: {
      Corridor: "CS",
      Flow_volume: 42601.072,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 7,
      Total_cost_per_ton: 148.80524136081294,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 336,
    level: 1,
    label: "DF1_1",
    data: {
      Corridor: "E",
      Flow_volume: 18032.208,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 1,
      Total_cost_per_ton: 279.3477991623,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 337,
    level: 1,
    label: "DF1_2",
    data: {
      Corridor: "E",
      Flow_volume: 1162.801500000001,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 2,
      Total_cost_per_ton: 279.3477991623,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 338,
    level: 1,
    label: "DF1_3",
    data: {
      Corridor: "E",
      Flow_volume: 10375.04,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 3,
      Total_cost_per_ton: 279.3477991623,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 339,
    level: 1,
    label: "DF1_4",
    data: {
      Corridor: "E",
      Flow_volume: 5176.9394999999995,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 4,
      Total_cost_per_ton: 279.3477991623,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 340,
    level: 1,
    label: "DF1_5",
    data: {
      Corridor: "E",
      Flow_volume: 3674.5699999999997,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 5,
      Total_cost_per_ton: 279.3477991623,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 341,
    level: 1,
    label: "DF1_6",
    data: {
      Corridor: "E",
      Flow_volume: 4200,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 6,
      Total_cost_per_ton: 233.0029938708,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 342,
    level: 1,
    label: "DF1_7",
    data: {
      Corridor: "E",
      Flow_volume: 5250,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 7,
      Total_cost_per_ton: 62.192794598,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 343,
    level: 1,
    label: "DF1_8",
    data: {
      Corridor: "E",
      Flow_volume: 3150,
      MesoRegion: "DF1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "DF",
      TimePeriod: 8,
      Total_cost_per_ton: 62.192794598,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 344,
    level: 1,
    label: "GO1_1",
    data: {
      Corridor: "E",
      Flow_volume: 143309.3075,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 1,
      Total_cost_per_ton: 215.41740094168094,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 271,
    level: 2,
    label: "CRUSH_GO_LUZ_1",
    data: {
      Corridor: "E",
      Flow_volume: 61000.57779032258,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1067.2011539795908,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 94.37059528489,
      total_revenue: 116674008.34065525,
      total_volume: 109327.10099270238,
    },
  },
  {
    id: 61,
    level: 4,
    label: "PORT_PARAN_ROCHA_1",
    data: {
      Corridor: "SW",
      Flow_volume: 96446.20300000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2410.514017016269,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 330.5247760562,
      total_revenue: 232484924.21949658,
      total_volume: 96446.20300000002,
    },
  },
  {
    id: 345,
    level: 1,
    label: "GO1_2",
    data: {
      Corridor: "E",
      Flow_volume: 73406.53050000001,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 2,
      Total_cost_per_ton: 244.20395814902622,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 274,
    level: 2,
    label: "CRUSH_GO_LUZ_2",
    data: {
      Corridor: "E",
      Flow_volume: 29622.4335,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1466.241997828109,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 107.18201284285455,
      total_revenue: 111491520.21350184,
      total_volume: 76038.96244866139,
    },
  },
  {
    id: 1,
    level: 4,
    label: "PORT_PARAN_ROCHA_2",
    data: {
      Corridor: "SW",
      Flow_volume: 120000.0000000003,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1808.106655625217,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 277.48629533110284,
      total_revenue: 216972798.6750266,
      total_volume: 120000.0000000003,
    },
  },
  {
    id: 346,
    level: 1,
    label: "GO1_3",
    data: {
      Corridor: "E",
      Flow_volume: 66972.13,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 3,
      Total_cost_per_ton: 255.4518414767809,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 284,
    level: 2,
    label: "CRUSH_GO_LUZ_3",
    data: {
      Corridor: "E",
      Flow_volume: 31464.83,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 614.8842311245036,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 107.67250693349582,
      total_revenue: 26267679.291741163,
      total_volume: 42719.715292914065,
    },
  },
  {
    id: 231,
    level: 3,
    label: "TRANSB_MG_TIA_3",
    data: {
      Corridor: "E",
      Flow_volume: 6980.175,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 143.71516348911666,
      total_revenue: 0,
      total_volume: 6980.175,
    },
  },
  {
    id: 237,
    level: 3,
    label: "TRANSB_MG_TIUB_3",
    data: {
      Corridor: "E",
      Flow_volume: 9699.947445225223,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 131.7715315111625,
      total_revenue: 0,
      total_volume: 9699.947445225223,
    },
  },
  {
    id: 347,
    level: 1,
    label: "GO1_4",
    data: {
      Corridor: "E",
      Flow_volume: 66739.924,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 4,
      Total_cost_per_ton: 198.2919857081375,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 275,
    level: 2,
    label: "CRUSH_GO_LUZ_4",
    data: {
      Corridor: "E",
      Flow_volume: 45818.07000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1213.8481187809318,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 122.47153638195897,
      total_revenue: 114773315.98124783,
      total_volume: 94553.27582211416,
    },
  },
  {
    id: 230,
    level: 3,
    label: "TRANSB_MG_TIA_4",
    data: {
      Corridor: "E",
      Flow_volume: 22640.4661666665,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 157.9077627434,
      total_revenue: 0,
      total_volume: 22640.4661666665,
    },
  },
  {
    id: 224,
    level: 3,
    label: "TRANSB_MG_TIUB_4",
    data: {
      Corridor: "E",
      Flow_volume: 53952.64505477457,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 152.986595267892,
      total_revenue: 0,
      total_volume: 53952.64505477457,
    },
  },
  {
    id: 348,
    level: 1,
    label: "GO1_5",
    data: {
      Corridor: "E",
      Flow_volume: 37641.6515,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 5,
      Total_cost_per_ton: 140.4058569407809,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 286,
    level: 2,
    label: "CRUSH_GO_LUZ_5",
    data: {
      Corridor: "E",
      Flow_volume: 42624.57,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1238.1174784174632,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 134.34605807,
      total_revenue: 110233432.73390347,
      total_volume: 89033.0963381614,
    },
  },
  {
    id: 349,
    level: 1,
    label: "GO1_6",
    data: {
      Corridor: "E",
      Flow_volume: 94653.10800000001,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 6,
      Total_cost_per_ton: 103.63714393525456,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 251,
    level: 2,
    label: "CRUSH_GO_LUZ_6",
    data: {
      Corridor: "E",
      Flow_volume: 44200.000000000015,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1279.7579051573016,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 128.46428537336692,
      total_revenue: 118839298.07243018,
      total_volume: 92860.76498806471,
    },
  },
  {
    id: 350,
    level: 1,
    label: "GO1_7",
    data: {
      Corridor: "E",
      Flow_volume: 12662.905499999999,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 7,
      Total_cost_per_ton: 99.83839141304684,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 351,
    level: 1,
    label: "GO1_8",
    data: {
      Corridor: "E",
      Flow_volume: 5612.67,
      MesoRegion: "GO1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 8,
      Total_cost_per_ton: 130.32272301189042,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 352,
    level: 1,
    label: "GO2_1",
    data: {
      Corridor: "CS",
      Flow_volume: 173248.137,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 1,
      Total_cost_per_ton: 277.48871289208233,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 353,
    level: 1,
    label: "GO2_2",
    data: {
      Corridor: "CS",
      Flow_volume: 192485.93,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 2,
      Total_cost_per_ton: 337.3142412222314,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 354,
    level: 1,
    label: "GO2_4",
    data: {
      Corridor: "CS",
      Flow_volume: 193441.81787944975,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 4,
      Total_cost_per_ton: 302.3582577136608,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 355,
    level: 1,
    label: "GO2_5",
    data: {
      Corridor: "CS",
      Flow_volume: 154135.02093241047,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 5,
      Total_cost_per_ton: 224.33966200821,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 356,
    level: 1,
    label: "GO2_6",
    data: {
      Corridor: "CS",
      Flow_volume: 55114.5135,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 6,
      Total_cost_per_ton: 148.43150820503254,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 357,
    level: 1,
    label: "GO2_8",
    data: {
      Corridor: "CS",
      Flow_volume: 93857.29550000012,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 8,
      Total_cost_per_ton: 178.961478589396,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 358,
    level: 1,
    label: "GO2_3",
    data: {
      Corridor: "CS",
      Flow_volume: 185300.7665,
      MesoRegion: "GO2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "GO",
      TimePeriod: 3,
      Total_cost_per_ton: 348.00404852563673,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 359,
    level: 1,
    label: "MT5_9",
    data: {
      Corridor: "E",
      Flow_volume: 5250.0105,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 9,
      Total_cost_per_ton: 248.07477462878333,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 80,
    level: 4,
    label: "PORT_BARCA_9",
    data: {
      Corridor: "N",
      Flow_volume: 4651.1325,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1217.3921680000003,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 506.513951816125,
      total_revenue: 5662252.277830261,
      total_volume: 4651.1325,
    },
  },
  {
    id: 360,
    level: 1,
    label: "MS1_2",
    data: {
      Corridor: "SW",
      Flow_volume: 128140.885,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 2,
      Total_cost_per_ton: 173.7177843828074,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 361,
    level: 1,
    label: "MT4_3",
    data: {
      Corridor: "CN",
      Flow_volume: 77540.7475,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 3,
      Total_cost_per_ton: 435.26594894411875,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 362,
    level: 1,
    label: "MT4_2",
    data: {
      Corridor: "CN",
      Flow_volume: 86259.02149999978,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 2,
      Total_cost_per_ton: 398.29168428352756,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 363,
    level: 1,
    label: "MT4_1",
    data: {
      Corridor: "CN",
      Flow_volume: 41673.5075,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 1,
      Total_cost_per_ton: 370.28413331919234,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 364,
    level: 1,
    label: "MT3_9",
    data: {
      Corridor: "N",
      Flow_volume: 31498.568,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 9,
      Total_cost_per_ton: 274.75783735218295,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 365,
    level: 1,
    label: "MT3_8",
    data: {
      Corridor: "N",
      Flow_volume: 52189.074,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 8,
      Total_cost_per_ton: 241.44193220358284,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 366,
    level: 1,
    label: "MT3_7",
    data: {
      Corridor: "N",
      Flow_volume: 209376.36950000023,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 7,
      Total_cost_per_ton: 231.8658515431049,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 367,
    level: 1,
    label: "MT3_6",
    data: {
      Corridor: "N",
      Flow_volume: 170813.18849999993,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 6,
      Total_cost_per_ton: 230.43069741862777,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 368,
    level: 1,
    label: "MT3_5",
    data: {
      Corridor: "N",
      Flow_volume: 159076.218,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 5,
      Total_cost_per_ton: 289.8531334949868,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 369,
    level: 1,
    label: "MT4_4",
    data: {
      Corridor: "CN",
      Flow_volume: 133460.422,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 4,
      Total_cost_per_ton: 413.10389405038796,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 370,
    level: 1,
    label: "MT3_4",
    data: {
      Corridor: "N",
      Flow_volume: 179101.9445,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 4,
      Total_cost_per_ton: 337.5423348508625,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 371,
    level: 1,
    label: "MT3_2",
    data: {
      Corridor: "N",
      Flow_volume: 190362.84549999956,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 2,
      Total_cost_per_ton: 361.5832308789424,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 372,
    level: 1,
    label: "MT3_1",
    data: {
      Corridor: "N",
      Flow_volume: 157963.9105,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 1,
      Total_cost_per_ton: 349.580156408897,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 373,
    level: 1,
    label: "MT2_9",
    data: {
      Corridor: "CS",
      Flow_volume: 132017.365,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 9,
      Total_cost_per_ton: 138.18238941091835,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 254,
    level: 2,
    label: "CRUSH_GO_LUZ_9",
    data: {
      Corridor: "E",
      Flow_volume: 302.3475,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2365.000485483428,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 261.6265502403,
      total_revenue: 117245295.20542225,
      total_volume: 49575.1675,
    },
  },
  {
    id: 374,
    level: 1,
    label: "MT2_8",
    data: {
      Corridor: "CS",
      Flow_volume: 151932.48605693746,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 8,
      Total_cost_per_ton: 132.27872332884098,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 212,
    level: 3,
    label: "TRANSB_MS_CHAPADA_8",
    data: {
      Corridor: "CS",
      Flow_volume: 398.265,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 72.917210016,
      total_revenue: 0,
      total_volume: 398.265,
    },
  },
  {
    id: 375,
    level: 1,
    label: "MT2_7",
    data: {
      Corridor: "CS",
      Flow_volume: 232824.697,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 7,
      Total_cost_per_ton: 118.78949055272666,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 376,
    level: 1,
    label: "MT2_6",
    data: {
      Corridor: "CS",
      Flow_volume: 174059.9244430625,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 6,
      Total_cost_per_ton: 118.28099640628983,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 377,
    level: 1,
    label: "MT2_5",
    data: {
      Corridor: "CS",
      Flow_volume: 193948.9585,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 5,
      Total_cost_per_ton: 155.633297815475,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 378,
    level: 1,
    label: "MT2_4",
    data: {
      Corridor: "CS",
      Flow_volume: 189972.01142762098,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 4,
      Total_cost_per_ton: 203.1433526326629,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 379,
    level: 1,
    label: "MT3_3",
    data: {
      Corridor: "N",
      Flow_volume: 150851.73350000047,
      MesoRegion: "MT3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 3,
      Total_cost_per_ton: 349.9362665458268,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 380,
    level: 1,
    label: "MT2_3",
    data: {
      Corridor: "CS",
      Flow_volume: 111823.874,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 3,
      Total_cost_per_ton: 316.2834164117,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 381,
    level: 1,
    label: "MT4_5",
    data: {
      Corridor: "CN",
      Flow_volume: 99330,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 5,
      Total_cost_per_ton: 394.2079682867625,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 382,
    level: 1,
    label: "MT4_7",
    data: {
      Corridor: "CN",
      Flow_volume: 125292.23450000025,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 7,
      Total_cost_per_ton: 375.2095030679,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 383,
    level: 1,
    label: "MT6_1",
    data: {
      Corridor: "N",
      Flow_volume: 203579.39800000004,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 1,
      Total_cost_per_ton: 357.64604314664376,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 384,
    level: 1,
    label: "MT6_2",
    data: {
      Corridor: "N",
      Flow_volume: 178953.90350000013,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 2,
      Total_cost_per_ton: 352.65833959328484,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 385,
    level: 1,
    label: "MT6_3",
    data: {
      Corridor: "N",
      Flow_volume: 308579.4884999998,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 3,
      Total_cost_per_ton: 283.67804355778594,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 386,
    level: 1,
    label: "MT6_4",
    data: {
      Corridor: "N",
      Flow_volume: 238465.9745,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 4,
      Total_cost_per_ton: 294.3728590998473,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 387,
    level: 1,
    label: "MT6_5",
    data: {
      Corridor: "N",
      Flow_volume: 183165.6115,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 5,
      Total_cost_per_ton: 310.95895378167455,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 388,
    level: 1,
    label: "MT6_6",
    data: {
      Corridor: "N",
      Flow_volume: 166581.7945,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 6,
      Total_cost_per_ton: 255.4198638153902,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 389,
    level: 1,
    label: "MT6_7",
    data: {
      Corridor: "N",
      Flow_volume: 193998.82925014166,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 7,
      Total_cost_per_ton: 249.0786143382347,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 390,
    level: 1,
    label: "MT6_8",
    data: {
      Corridor: "N",
      Flow_volume: 231931.84324985836,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 8,
      Total_cost_per_ton: 296.91375208137936,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 391,
    level: 1,
    label: "MT4_6",
    data: {
      Corridor: "CN",
      Flow_volume: 83748.84465634226,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 6,
      Total_cost_per_ton: 332.1130253596769,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 392,
    level: 1,
    label: "MT6_9",
    data: {
      Corridor: "N",
      Flow_volume: 15715.9645,
      MesoRegion: "MT6",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 9,
      Total_cost_per_ton: 243.87924591738394,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 393,
    level: 1,
    label: "MT5_6",
    data: {
      Corridor: "E",
      Flow_volume: 82728.70000000001,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 6,
      Total_cost_per_ton: 217.71430447796297,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 394,
    level: 1,
    label: "MT5_5",
    data: {
      Corridor: "E",
      Flow_volume: 140620.84050000002,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 5,
      Total_cost_per_ton: 251.308168484836,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 395,
    level: 1,
    label: "MT5_4",
    data: {
      Corridor: "E",
      Flow_volume: 142319.247,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 4,
      Total_cost_per_ton: 264.5212197847852,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 396,
    level: 1,
    label: "MT5_3",
    data: {
      Corridor: "E",
      Flow_volume: 125126.1565,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 3,
      Total_cost_per_ton: 348.09011282766295,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 397,
    level: 1,
    label: "MT5_2",
    data: {
      Corridor: "E",
      Flow_volume: 100624.02700000002,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 2,
      Total_cost_per_ton: 315.9937048851115,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 398,
    level: 1,
    label: "MT5_1",
    data: {
      Corridor: "E",
      Flow_volume: 122909.80449999998,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 1,
      Total_cost_per_ton: 303.4768961619643,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 399,
    level: 1,
    label: "MT4_9",
    data: {
      Corridor: "CN",
      Flow_volume: 5250,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 9,
      Total_cost_per_ton: 484.2279025754583,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 400,
    level: 1,
    label: "MT4_8",
    data: {
      Corridor: "CN",
      Flow_volume: 100917.36600000001,
      MesoRegion: "MT4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 8,
      Total_cost_per_ton: 387.94510365927,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 401,
    level: 1,
    label: "MT5_7",
    data: {
      Corridor: "E",
      Flow_volume: 81466.2793603531,
      MesoRegion: "MT5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 7,
      Total_cost_per_ton: 213.46411385178845,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 402,
    level: 1,
    label: "MS1_1",
    data: {
      Corridor: "SW",
      Flow_volume: 170316.038,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 1,
      Total_cost_per_ton: 128.38785453201112,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 403,
    level: 1,
    label: "MT2_2",
    data: {
      Corridor: "CS",
      Flow_volume: 126026.51550000001,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 2,
      Total_cost_per_ton: 283.03504022402245,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 404,
    level: 1,
    label: "MT2_1",
    data: {
      Corridor: "CS",
      Flow_volume: 265590.0185,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 1,
      Total_cost_per_ton: 257.38112086611903,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 405,
    level: 1,
    label: "MS3_6",
    data: {
      Corridor: "SW",
      Flow_volume: 17144.52144129697,
      MesoRegion: "MS3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 6,
      Total_cost_per_ton: 208.10767643635,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 406,
    level: 1,
    label: "MS3_4",
    data: {
      Corridor: "SW",
      Flow_volume: 3423.250999999993,
      MesoRegion: "MS3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 4,
      Total_cost_per_ton: 258.64819948025,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 407,
    level: 1,
    label: "MS3_3",
    data: {
      Corridor: "SW",
      Flow_volume: 15635.4545,
      MesoRegion: "MS3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 3,
      Total_cost_per_ton: 347.32687864914,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 408,
    level: 1,
    label: "MS3_2",
    data: {
      Corridor: "SW",
      Flow_volume: 15886.880000000001,
      MesoRegion: "MS3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 2,
      Total_cost_per_ton: 281.56407904919996,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 409,
    level: 1,
    label: "MS3_1",
    data: {
      Corridor: "SW",
      Flow_volume: 21278.8315,
      MesoRegion: "MS3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 1,
      Total_cost_per_ton: 264.4522082107143,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 410,
    level: 1,
    label: "MS2_8",
    data: {
      Corridor: "CS",
      Flow_volume: 9899.64388436317,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 8,
      Total_cost_per_ton: 209.57154145562222,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 411,
    level: 1,
    label: "MS2_7",
    data: {
      Corridor: "CS",
      Flow_volume: 24960.3215,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 7,
      Total_cost_per_ton: 185.7509918314889,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 412,
    level: 1,
    label: "MS2_6",
    data: {
      Corridor: "CS",
      Flow_volume: 393.7515952182114,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 6,
      Total_cost_per_ton: 182.67758339605,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 413,
    level: 1,
    label: "MS3_8",
    data: {
      Corridor: "SW",
      Flow_volume: 12255.478558703038,
      MesoRegion: "MS3",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 8,
      Total_cost_per_ton: 330.9759609669,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 414,
    level: 1,
    label: "MS2_4",
    data: {
      Corridor: "CS",
      Flow_volume: 25163.931520418628,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 4,
      Total_cost_per_ton: 258.5447177602857,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 415,
    level: 1,
    label: "MS2_2",
    data: {
      Corridor: "CS",
      Flow_volume: 587.4,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 2,
      Total_cost_per_ton: 241.0116781642125,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 416,
    level: 1,
    label: "MS2_1",
    data: {
      Corridor: "CS",
      Flow_volume: 23380.070000000003,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 1,
      Total_cost_per_ton: 269.3624388981,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 417,
    level: 1,
    label: "MS1_9",
    data: {
      Corridor: "SW",
      Flow_volume: 50400.010500000004,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 9,
      Total_cost_per_ton: 206.12742297522632,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 312,
    level: 2,
    label: "CRUSH_MS_DOU_9",
    data: {
      Corridor: "SW",
      Flow_volume: 40430.02301839798,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 838.0823221288907,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 97.72124866492307,
      total_revenue: 49967912.64082735,
      total_volume: 59621.723691652645,
    },
  },
  {
    id: 96,
    level: 4,
    label: "PORT_RIOGR_9",
    data: {
      Corridor: "S",
      Flow_volume: 59202.55548160202,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2488.27595,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 184.69414038463663,
      total_revenue: 147312294.98341098,
      total_volume: 59202.55548160202,
    },
  },
  {
    id: 418,
    level: 1,
    label: "MS1_8",
    data: {
      Corridor: "SW",
      Flow_volume: 59350.322499999995,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 8,
      Total_cost_per_ton: 98.9320782484,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 419,
    level: 1,
    label: "MS1_6",
    data: {
      Corridor: "SW",
      Flow_volume: 70337.3105,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 6,
      Total_cost_per_ton: 93.22542277827391,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 273,
    level: 2,
    label: "CRUSH_MS_DOU_6",
    data: {
      Corridor: "SW",
      Flow_volume: 29334.1755,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1819.3530119909997,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 73.63637740685,
      total_revenue: 169800775.87467796,
      total_volume: 93330.3073980444,
    },
  },
  {
    id: 420,
    level: 1,
    label: "MS1_5",
    data: {
      Corridor: "SW",
      Flow_volume: 47906.7915,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 5,
      Total_cost_per_ton: 108.88740687274999,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 259,
    level: 2,
    label: "CRUSH_MS_DOU_5",
    data: {
      Corridor: "SW",
      Flow_volume: 37238.28700000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1613.8241353721394,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 87.624497623728,
      total_revenue: 158210623.43666136,
      total_volume: 98034.6123031422,
    },
  },
  {
    id: 421,
    level: 1,
    label: "MS1_4",
    data: {
      Corridor: "SW",
      Flow_volume: 104368.9225,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 4,
      Total_cost_per_ton: 120.14384414562413,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 258,
    level: 2,
    label: "CRUSH_MS_DOU_4",
    data: {
      Corridor: "SW",
      Flow_volume: 53414.913,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1407.4353815067345,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 87.16941919763333,
      total_revenue: 165248458.76918346,
      total_volume: 117411.0448980444,
    },
  },
  {
    id: 422,
    level: 1,
    label: "MS1_3",
    data: {
      Corridor: "SW",
      Flow_volume: 145192.2605,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 3,
      Total_cost_per_ton: 184.8651249131081,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 257,
    level: 2,
    label: "CRUSH_MS_DOU_3",
    data: {
      Corridor: "SW",
      Flow_volume: 54992.26,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1385.6869220836456,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 90.83052900882667,
      total_revenue: 164880658.53288376,
      total_volume: 118988.39189804441,
    },
  },
  {
    id: 423,
    level: 1,
    label: "MS2_3",
    data: {
      Corridor: "CS",
      Flow_volume: 80004.981,
      MesoRegion: "MS2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 3,
      Total_cost_per_ton: 303.6677223063182,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 424,
    level: 1,
    label: "MT2_10",
    data: {
      Corridor: "CS",
      Flow_volume: 10500.0105,
      MesoRegion: "MT2",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 10,
      Total_cost_per_ton: 431.7448617410276,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 128,
    level: 3,
    label: "TRANSB_PR_MARINGA_10",
    data: {
      Corridor: "SW",
      Flow_volume: 6977.4075,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 491.35708757908884,
      total_revenue: 0,
      total_volume: 6977.4075,
    },
  },
  {
    id: 425,
    level: 1,
    label: "MS4_1",
    data: {
      Corridor: "SW",
      Flow_volume: 83061.977,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 1,
      Total_cost_per_ton: 148.16298977370624,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 426,
    level: 1,
    label: "MS4_3",
    data: {
      Corridor: "SW",
      Flow_volume: 58399.998999999996,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 3,
      Total_cost_per_ton: 170.94158476390626,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 427,
    level: 1,
    label: "MT1_9",
    data: {
      Corridor: "CS",
      Flow_volume: 62231.098833333366,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 9,
      Total_cost_per_ton: 176.31869403622645,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 428,
    level: 1,
    label: "MT1_8",
    data: {
      Corridor: "CS",
      Flow_volume: 291051.4716666669,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 8,
      Total_cost_per_ton: 174.99824004713685,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 429,
    level: 1,
    label: "MT1_7",
    data: {
      Corridor: "CS",
      Flow_volume: 79935.345,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 7,
      Total_cost_per_ton: 154.11814458721614,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 430,
    level: 1,
    label: "MT1_6",
    data: {
      Corridor: "CS",
      Flow_volume: 178322.08484365777,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 6,
      Total_cost_per_ton: 160.26250622439375,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 431,
    level: 1,
    label: "MT1_5",
    data: {
      Corridor: "CS",
      Flow_volume: 170357.92849999978,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 5,
      Total_cost_per_ton: 202.6127956577606,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 432,
    level: 1,
    label: "MT1_4",
    data: {
      Corridor: "CS",
      Flow_volume: 159517.25457237902,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 4,
      Total_cost_per_ton: 259.8187629782483,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 433,
    level: 1,
    label: "MT1_3",
    data: {
      Corridor: "CS",
      Flow_volume: 239998.6781864089,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 3,
      Total_cost_per_ton: 272.92404330250275,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 434,
    level: 1,
    label: "MT1_2",
    data: {
      Corridor: "CS",
      Flow_volume: 191657.3455,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 2,
      Total_cost_per_ton: 335.00779772958697,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 435,
    level: 1,
    label: "MS4_2",
    data: {
      Corridor: "SW",
      Flow_volume: 97939.205,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 2,
      Total_cost_per_ton: 210.92600092513447,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 436,
    level: 1,
    label: "MT1_10",
    data: {
      Corridor: "CS",
      Flow_volume: 21000,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 10,
      Total_cost_per_ton: 575.2657261466765,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 437,
    level: 1,
    label: "MS5_3",
    data: {
      Corridor: "SW",
      Flow_volume: 47517.8055,
      MesoRegion: "MS5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 3,
      Total_cost_per_ton: 267.28512868164,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 438,
    level: 1,
    label: "MS5_2",
    data: {
      Corridor: "SW",
      Flow_volume: 2850.0095,
      MesoRegion: "MS5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 2,
      Total_cost_per_ton: 132.89882977114286,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 439,
    level: 1,
    label: "MS5_1",
    data: {
      Corridor: "SW",
      Flow_volume: 2850.0095,
      MesoRegion: "MS5",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 1,
      Total_cost_per_ton: 132.89882977114286,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 440,
    level: 1,
    label: "MS4_8",
    data: {
      Corridor: "SW",
      Flow_volume: 31093.5135,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 8,
      Total_cost_per_ton: 114.86771952867038,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 441,
    level: 1,
    label: "MS4_7",
    data: {
      Corridor: "SW",
      Flow_volume: 51981.51,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 7,
      Total_cost_per_ton: 115.57095787194285,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 442,
    level: 1,
    label: "MS4_6",
    data: {
      Corridor: "SW",
      Flow_volume: 36343.524,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 6,
      Total_cost_per_ton: 118.33631150487587,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 443,
    level: 1,
    label: "MS4_5",
    data: {
      Corridor: "SW",
      Flow_volume: 44046.3755,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 5,
      Total_cost_per_ton: 131.404360132725,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 444,
    level: 1,
    label: "MS4_4",
    data: {
      Corridor: "SW",
      Flow_volume: 85041.707,
      MesoRegion: "MS4",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 4,
      Total_cost_per_ton: 123.05957256311291,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 445,
    level: 1,
    label: "MT1_1",
    data: {
      Corridor: "CS",
      Flow_volume: 323417.9345000002,
      MesoRegion: "MT1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MT",
      TimePeriod: 1,
      Total_cost_per_ton: 272.43727718696925,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 446,
    level: 1,
    label: "MS1_7",
    data: {
      Corridor: "SW",
      Flow_volume: 79795.5815,
      MesoRegion: "MS1",
      Region: "Região Centro-Oeste",
      Revenue_per_ton: 0,
      State: "MS",
      TimePeriod: 7,
      Total_cost_per_ton: 103.81872614078078,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 447,
    level: 1,
    label: "MA2_7",
    data: {
      Corridor: "CN",
      Flow_volume: 54286.62369233842,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 7,
      Total_cost_per_ton: 143.99775503156923,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 448,
    level: 1,
    label: "MA1_2",
    data: {
      Corridor: "CN",
      Flow_volume: 38371.0745328197,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 2,
      Total_cost_per_ton: 174.08402756197955,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 449,
    level: 1,
    label: "PI1_4",
    data: {
      Corridor: "CN",
      Flow_volume: 42404.4055,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 4,
      Total_cost_per_ton: 171.30709126920667,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 277,
    level: 2,
    label: "CRUSH_PI_URU_4",
    data: {
      Corridor: "CN",
      Flow_volume: 41471.11472906399,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1521.064840915633,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 152.337632305316,
      total_revenue: 157690487.63291007,
      total_volume: 103671.114729064,
    },
  },
  {
    id: 450,
    level: 1,
    label: "MA1_1",
    data: {
      Corridor: "CN",
      Flow_volume: 87901.85100000001,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 1,
      Total_cost_per_ton: 177.7363112826822,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 451,
    level: 1,
    label: "PI1_2",
    data: {
      Corridor: "CN",
      Flow_volume: 102353.68149999999,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 2,
      Total_cost_per_ton: 210.8296297925185,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 204,
    level: 4,
    label: "PORT_TMIB_TMIB_2",
    data: {
      Corridor: "NE",
      Flow_volume: 42948.86850000004,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2475.8538332934995,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 378.89353727765,
      total_revenue: 106335120.71134354,
      total_volume: 42948.86850000004,
    },
  },
  {
    id: 452,
    level: 1,
    label: "PI1_10",
    data: {
      Corridor: "CN",
      Flow_volume: 26249.999999999996,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 10,
      Total_cost_per_ton: 155.39713779676666,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 330,
    level: 2,
    label: "CRUSH_PI_URU_10",
    data: {
      Corridor: "CN",
      Flow_volume: 31152.113999999998,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1244.224790687926,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 298.18428112433634,
      total_revenue: 97478036.62591168,
      total_volume: 78344.39351752229,
    },
  },
  {
    id: 453,
    level: 1,
    label: "PI1_1",
    data: {
      Corridor: "CN",
      Flow_volume: 176742.8915,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 1,
      Total_cost_per_ton: 258.7227353840941,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 454,
    level: 1,
    label: "BA1_9",
    data: {
      Corridor: "NE",
      Flow_volume: 98798.40147851751,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 9,
      Total_cost_per_ton: 117.55492920969375,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 455,
    level: 1,
    label: "BA1_8",
    data: {
      Corridor: "NE",
      Flow_volume: 181510.21052148254,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 8,
      Total_cost_per_ton: 170.19876910697826,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 243,
    level: 2,
    label: "CRUSH_PI_URU_8",
    data: {
      Corridor: "CN",
      Flow_volume: 61236.32755272278,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1288.7724374388863,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 141.780902523025,
      total_revenue: 157534809.80370063,
      total_volume: 122236.32755272278,
    },
  },
  {
    id: 198,
    level: 4,
    label: "PORT_TMIB_TMIB_8",
    data: {
      Corridor: "NE",
      Flow_volume: 30000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2547.7540372214,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 344.8252439701,
      total_revenue: 76432621.116642,
      total_volume: 30000,
    },
  },
  {
    id: 456,
    level: 1,
    label: "BA1_7",
    data: {
      Corridor: "NE",
      Flow_volume: 143485.122,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 7,
      Total_cost_per_ton: 152.25488985979285,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 199,
    level: 4,
    label: "PORT_TMIB_TMIB_7",
    data: {
      Corridor: "NE",
      Flow_volume: 60000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2547.2093108706,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 354.65229556149995,
      total_revenue: 152832558.65223598,
      total_volume: 60000,
    },
  },
  {
    id: 457,
    level: 1,
    label: "BA1_6",
    data: {
      Corridor: "NE",
      Flow_volume: 164356.4025,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 6,
      Total_cost_per_ton: 144.4129119140643,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 200,
    level: 4,
    label: "PORT_TMIB_TMIB_6",
    data: {
      Corridor: "NE",
      Flow_volume: 60000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2531.8954272381998,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 352.15550044515,
      total_revenue: 151913725.63429198,
      total_volume: 60000,
    },
  },
  {
    id: 458,
    level: 1,
    label: "BA1_5",
    data: {
      Corridor: "NE",
      Flow_volume: 443323.5625,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 5,
      Total_cost_per_ton: 269.10733883597646,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 201,
    level: 4,
    label: "PORT_TMIB_TMIB_5",
    data: {
      Corridor: "NE",
      Flow_volume: 30000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2550.2746596609,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 342.5,
      total_revenue: 76508239.789827,
      total_volume: 30000,
    },
  },
  {
    id: 459,
    level: 1,
    label: "BA1_4",
    data: {
      Corridor: "NE",
      Flow_volume: 182667.6615,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 4,
      Total_cost_per_ton: 283.4313858342533,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 202,
    level: 4,
    label: "PORT_TMIB_TMIB_4",
    data: {
      Corridor: "NE",
      Flow_volume: 5577.350499999964,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2493.8509305359,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 354,
      total_revenue: 13909080.734349776,
      total_volume: 5577.350499999964,
    },
  },
  {
    id: 460,
    level: 1,
    label: "BA1_3",
    data: {
      Corridor: "NE",
      Flow_volume: 181677.92,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 3,
      Total_cost_per_ton: 305.17461675122064,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 203,
    level: 4,
    label: "PORT_TMIB_TMIB_3",
    data: {
      Corridor: "NE",
      Flow_volume: 11473.780999999997,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2440.912347743,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 390.54285714285,
      total_revenue: 28006493.71819902,
      total_volume: 11473.780999999997,
    },
  },
  {
    id: 461,
    level: 1,
    label: "BA1_2",
    data: {
      Corridor: "NE",
      Flow_volume: 210607.9184671803,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 2,
      Total_cost_per_ton: 280.1422205645476,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 462,
    level: 1,
    label: "BA1_10",
    data: {
      Corridor: "NE",
      Flow_volume: 125999.9895,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 10,
      Total_cost_per_ton: 131.52765447292307,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 463,
    level: 1,
    label: "BA1_1",
    data: {
      Corridor: "NE",
      Flow_volume: 221119.4300328194,
      MesoRegion: "BA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "BA",
      TimePeriod: 1,
      Total_cost_per_ton: 245.39617848855457,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 464,
    level: 1,
    label: "PI1_5",
    data: {
      Corridor: "CN",
      Flow_volume: 89368.52100000001,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 5,
      Total_cost_per_ton: 166.44581489079334,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 240,
    level: 2,
    label: "CRUSH_PI_URU_5",
    data: {
      Corridor: "CN",
      Flow_volume: 52499.99999999999,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1382.2913137547303,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 151.14184332269585,
      total_revenue: 158548813.68766755,
      total_volume: 114700,
    },
  },
  {
    id: 465,
    level: 1,
    label: "PI1_6",
    data: {
      Corridor: "CN",
      Flow_volume: 102287.86099999989,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 6,
      Total_cost_per_ton: 154.49936876492592,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 466,
    level: 1,
    label: "PI1_3",
    data: {
      Corridor: "CN",
      Flow_volume: 67060.24399999999,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 3,
      Total_cost_per_ton: 177.56440396986667,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 467,
    level: 1,
    label: "PI1_8",
    data: {
      Corridor: "CN",
      Flow_volume: 102702.96319233855,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 8,
      Total_cost_per_ton: 148.86815611622143,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 468,
    level: 1,
    label: "MA1_3",
    data: {
      Corridor: "CN",
      Flow_volume: 10902.608,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 3,
      Total_cost_per_ton: 175.1577680873432,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 469,
    level: 1,
    label: "MA1_4",
    data: {
      Corridor: "CN",
      Flow_volume: 10382.6395,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 4,
      Total_cost_per_ton: 179.98761946520455,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 470,
    level: 1,
    label: "MA1_5",
    data: {
      Corridor: "CN",
      Flow_volume: 9752.442000000001,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 5,
      Total_cost_per_ton: 179.67884788770112,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 471,
    level: 1,
    label: "MA1_6",
    data: {
      Corridor: "CN",
      Flow_volume: 38099.979,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 6,
      Total_cost_per_ton: 148.5399912950089,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 472,
    level: 1,
    label: "PI1_7",
    data: {
      Corridor: "CN",
      Flow_volume: 92652.69080766157,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 7,
      Total_cost_per_ton: 161.62028677835187,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 244,
    level: 2,
    label: "CRUSH_PI_URU_7",
    data: {
      Corridor: "CN",
      Flow_volume: 21000.021,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1934.819972713829,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 141.87688538857083,
      total_revenue: 160009652.3746531,
      total_volume: 82700.02100000001,
    },
  },
  {
    id: 473,
    level: 1,
    label: "MA1_8",
    data: {
      Corridor: "CN",
      Flow_volume: 1050.021,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 8,
      Total_cost_per_ton: 148.93520324316137,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 474,
    level: 1,
    label: "MA2_1",
    data: {
      Corridor: "CN",
      Flow_volume: 47497.6915,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 1,
      Total_cost_per_ton: 145.522799427075,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 475,
    level: 1,
    label: "MA1_7",
    data: {
      Corridor: "CN",
      Flow_volume: 13502.97899999999,
      MesoRegion: "MA1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 7,
      Total_cost_per_ton: 149.59314515568443,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 476,
    level: 1,
    label: "MA2_3",
    data: {
      Corridor: "CN",
      Flow_volume: 128953.6685,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 3,
      Total_cost_per_ton: 153.40549102725,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 477,
    level: 1,
    label: "MA2_4",
    data: {
      Corridor: "CN",
      Flow_volume: 112858.37690682652,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 4,
      Total_cost_per_ton: 159.83528114994445,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 478,
    level: 1,
    label: "MA2_5",
    data: {
      Corridor: "CN",
      Flow_volume: 41569.59090083505,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 5,
      Total_cost_per_ton: 155.1120055978,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 479,
    level: 1,
    label: "MA2_6",
    data: {
      Corridor: "CN",
      Flow_volume: 49874.979,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 6,
      Total_cost_per_ton: 130.01801200378333,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 480,
    level: 1,
    label: "MA2_8",
    data: {
      Corridor: "CN",
      Flow_volume: 21000,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 8,
      Total_cost_per_ton: 130.883124080975,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 481,
    level: 1,
    label: "PI1_9",
    data: {
      Corridor: "CN",
      Flow_volume: 21499.979,
      MesoRegion: "PI1",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "PI",
      TimePeriod: 9,
      Total_cost_per_ton: 153.2049758248704,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 242,
    level: 2,
    label: "CRUSH_PI_URU_9",
    data: {
      Corridor: "CN",
      Flow_volume: 21499.979,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1977.7338582131777,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 153.2049758248704,
      total_revenue: 177937471.06534624,
      total_volume: 89970.38217574297,
    },
  },
  {
    id: 482,
    level: 1,
    label: "MA2_2",
    data: {
      Corridor: "CN",
      Flow_volume: 33754.2095,
      MesoRegion: "MA2",
      Region: "Região Nordeste",
      Revenue_per_ton: 0,
      State: "MA",
      TimePeriod: 2,
      Total_cost_per_ton: 152.69245253297692,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 483,
    level: 1,
    label: "TO1_8",
    data: {
      Corridor: "CN",
      Flow_volume: 429.58649999999994,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 8,
      Total_cost_per_ton: 315.4196082937447,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 484,
    level: 1,
    label: "AC1_2",
    data: {
      Corridor: "N",
      Flow_volume: 449.99999999999994,
      MesoRegion: "AC1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "AC",
      TimePeriod: 2,
      Total_cost_per_ton: 237.66006337304998,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 485,
    level: 1,
    label: "TO2_8",
    data: {
      Corridor: "CN",
      Flow_volume: 30000,
      MesoRegion: "TO2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 8,
      Total_cost_per_ton: 127.15982922090001,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 486,
    level: 1,
    label: "TO2_5",
    data: {
      Corridor: "CN",
      Flow_volume: 17938.697192338404,
      MesoRegion: "TO2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 5,
      Total_cost_per_ton: 149.35121307115,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 487,
    level: 1,
    label: "TO2_4",
    data: {
      Corridor: "CN",
      Flow_volume: 10679.915729063998,
      MesoRegion: "TO2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 4,
      Total_cost_per_ton: 148.38029377875,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 488,
    level: 1,
    label: "TO2_2",
    data: {
      Corridor: "CN",
      Flow_volume: 43746.387078597545,
      MesoRegion: "TO2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 2,
      Total_cost_per_ton: 150.292291692725,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 489,
    level: 1,
    label: "TO2_1",
    data: {
      Corridor: "CN",
      Flow_volume: 637,
      MesoRegion: "TO2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 1,
      Total_cost_per_ton: 108.453059394,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 490,
    level: 1,
    label: "TO1_7",
    data: {
      Corridor: "CN",
      Flow_volume: 10258.714500000006,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 7,
      Total_cost_per_ton: 107.01541818369999,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 491,
    level: 1,
    label: "AC1_1",
    data: {
      Corridor: "N",
      Flow_volume: 1006.5105000000003,
      MesoRegion: "AC1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "AC",
      TimePeriod: 1,
      Total_cost_per_ton: 237.66006337304998,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 492,
    level: 1,
    label: "TO1_5",
    data: {
      Corridor: "CN",
      Flow_volume: 36077.28650000008,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 5,
      Total_cost_per_ton: 126.05456809124375,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 493,
    level: 1,
    label: "RO1_5",
    data: {
      Corridor: "N",
      Flow_volume: 38849.979,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 5,
      Total_cost_per_ton: 195.62212670825386,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 494,
    level: 1,
    label: "TO1_6",
    data: {
      Corridor: "CN",
      Flow_volume: 56741.338000000025,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 6,
      Total_cost_per_ton: 102.44780497023062,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 495,
    level: 1,
    label: "RO1_3",
    data: {
      Corridor: "N",
      Flow_volume: 8979.952000000001,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 3,
      Total_cost_per_ton: 195.62212670825386,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 496,
    level: 1,
    label: "RO1_2",
    data: {
      Corridor: "N",
      Flow_volume: 11140.484,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 2,
      Total_cost_per_ton: 195.62212670825386,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 497,
    level: 1,
    label: "RO1_1",
    data: {
      Corridor: "N",
      Flow_volume: 38995.074,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 1,
      Total_cost_per_ton: 195.62212670825386,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 498,
    level: 1,
    label: "PA3_2",
    data: {
      Corridor: "N",
      Flow_volume: 10626.021,
      MesoRegion: "PA3",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 2,
      Total_cost_per_ton: 225.60695034702223,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 499,
    level: 1,
    label: "PA3_1",
    data: {
      Corridor: "N",
      Flow_volume: 24496.1665,
      MesoRegion: "PA3",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 1,
      Total_cost_per_ton: 234.25500612794445,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 500,
    level: 1,
    label: "PA2_4",
    data: {
      Corridor: "N",
      Flow_volume: 2341.567,
      MesoRegion: "PA2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 4,
      Total_cost_per_ton: 230.00225083597996,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 501,
    level: 1,
    label: "PA2_3",
    data: {
      Corridor: "N",
      Flow_volume: 13479.8715,
      MesoRegion: "PA2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 3,
      Total_cost_per_ton: 219.41675162674,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 502,
    level: 1,
    label: "PA2_2",
    data: {
      Corridor: "N",
      Flow_volume: 7791.4725,
      MesoRegion: "PA2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 2,
      Total_cost_per_ton: 232.11935067785998,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 503,
    level: 1,
    label: "PA2_1",
    data: {
      Corridor: "N",
      Flow_volume: 12968.134,
      MesoRegion: "PA2",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 1,
      Total_cost_per_ton: 232.11935067785998,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 504,
    level: 1,
    label: "PA1_6",
    data: {
      Corridor: "N",
      Flow_volume: 3150.0105,
      MesoRegion: "PA1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 6,
      Total_cost_per_ton: 118.9644889064818,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 505,
    level: 1,
    label: "PA1_5",
    data: {
      Corridor: "N",
      Flow_volume: 3450.0100000000007,
      MesoRegion: "PA1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 5,
      Total_cost_per_ton: 138.14143206417273,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 506,
    level: 1,
    label: "PA1_4",
    data: {
      Corridor: "N",
      Flow_volume: 2250.0005000000006,
      MesoRegion: "PA1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 4,
      Total_cost_per_ton: 161.29719648456364,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 507,
    level: 1,
    label: "PA1_3",
    data: {
      Corridor: "N",
      Flow_volume: 16189.99,
      MesoRegion: "PA1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 3,
      Total_cost_per_ton: 161.3018323391591,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 508,
    level: 1,
    label: "PA1_2",
    data: {
      Corridor: "N",
      Flow_volume: 40148.808000000005,
      MesoRegion: "PA1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 2,
      Total_cost_per_ton: 150.5951046021364,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 509,
    level: 1,
    label: "PA1_1",
    data: {
      Corridor: "N",
      Flow_volume: 34312.733,
      MesoRegion: "PA1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "PA",
      TimePeriod: 1,
      Total_cost_per_ton: 161.40116965737272,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 510,
    level: 1,
    label: "RO1_6",
    data: {
      Corridor: "N",
      Flow_volume: 10499.979,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 6,
      Total_cost_per_ton: 163.1677118168846,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 511,
    level: 1,
    label: "RO1_7",
    data: {
      Corridor: "N",
      Flow_volume: 5250,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 7,
      Total_cost_per_ton: 164.32457674017692,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 512,
    level: 1,
    label: "RO1_4",
    data: {
      Corridor: "N",
      Flow_volume: 21000.021000000004,
      MesoRegion: "RO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "RO",
      TimePeriod: 4,
      Total_cost_per_ton: 195.62212670825386,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 513,
    level: 1,
    label: "TO1_4",
    data: {
      Corridor: "CN",
      Flow_volume: 31228.764999999996,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 4,
      Total_cost_per_ton: 145.18701100896877,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 514,
    level: 1,
    label: "TO1_3",
    data: {
      Corridor: "CN",
      Flow_volume: 25389.879999999997,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 3,
      Total_cost_per_ton: 149.03153854996916,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 515,
    level: 1,
    label: "TO1_2",
    data: {
      Corridor: "CN",
      Flow_volume: 73584.80704691267,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 2,
      Total_cost_per_ton: 151.79673611039368,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 516,
    level: 1,
    label: "TO1_10",
    data: {
      Corridor: "CN",
      Flow_volume: 5250.0105,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 10,
      Total_cost_per_ton: 365.42916702564895,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 517,
    level: 1,
    label: "TO1_1",
    data: {
      Corridor: "CN",
      Flow_volume: 81351.25945308727,
      MesoRegion: "TO1",
      Region: "Região Norte",
      Revenue_per_ton: 0,
      State: "TO",
      TimePeriod: 1,
      Total_cost_per_ton: 174.19853628290815,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 518,
    level: 1,
    label: "MG1_5",
    data: {
      Corridor: "E",
      Flow_volume: 5250.0105,
      MesoRegion: "MG1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 5,
      Total_cost_per_ton: 437.9147800253214,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 519,
    level: 1,
    label: "SP1_3",
    data: {
      Corridor: "CS",
      Flow_volume: 4649.9615,
      MesoRegion: "SP1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 3,
      Total_cost_per_ton: 188.85473734570883,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 520,
    level: 1,
    label: "MG1_1",
    data: {
      Corridor: "E",
      Flow_volume: 15439.2955,
      MesoRegion: "MG1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 1,
      Total_cost_per_ton: 365.23088736604285,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 521,
    level: 1,
    label: "MG1_2",
    data: {
      Corridor: "E",
      Flow_volume: 24432.7365,
      MesoRegion: "MG1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 2,
      Total_cost_per_ton: 311.6817822266893,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 522,
    level: 1,
    label: "MG1_3",
    data: {
      Corridor: "E",
      Flow_volume: 10114.084,
      MesoRegion: "MG1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 3,
      Total_cost_per_ton: 436.09891952741333,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 523,
    level: 1,
    label: "MG1_4",
    data: {
      Corridor: "E",
      Flow_volume: 10500,
      MesoRegion: "MG1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 4,
      Total_cost_per_ton: 439.63789011939286,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 524,
    level: 1,
    label: "MG2_1",
    data: {
      Corridor: "E",
      Flow_volume: 58445.9725,
      MesoRegion: "MG2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 1,
      Total_cost_per_ton: 303.6365974453762,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 525,
    level: 1,
    label: "MG2_2",
    data: {
      Corridor: "E",
      Flow_volume: 36331.401,
      MesoRegion: "MG2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 2,
      Total_cost_per_ton: 303.6365974453762,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 526,
    level: 1,
    label: "MG2_3",
    data: {
      Corridor: "E",
      Flow_volume: 21387.4535,
      MesoRegion: "MG2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 3,
      Total_cost_per_ton: 289.96818640376307,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 527,
    level: 1,
    label: "MG2_4",
    data: {
      Corridor: "E",
      Flow_volume: 21000.063,
      MesoRegion: "MG2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 4,
      Total_cost_per_ton: 303.6365974453762,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 528,
    level: 1,
    label: "SP1_2",
    data: {
      Corridor: "CS",
      Flow_volume: 26847.84499999988,
      MesoRegion: "SP1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 2,
      Total_cost_per_ton: 211.14874024404338,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 529,
    level: 1,
    label: "MG2_5",
    data: {
      Corridor: "E",
      Flow_volume: 15749.9895,
      MesoRegion: "MG2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 5,
      Total_cost_per_ton: 303.6365974453762,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 530,
    level: 1,
    label: "MG4_2",
    data: {
      Corridor: "CS",
      Flow_volume: 19013.9535,
      MesoRegion: "MG4",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 2,
      Total_cost_per_ton: 209.54931067852309,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 531,
    level: 1,
    label: "SP2_5",
    data: {
      Corridor: "CS",
      Flow_volume: 11549.9475,
      MesoRegion: "SP2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 5,
      Total_cost_per_ton: 194.78068057008116,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 532,
    level: 1,
    label: "SP2_4",
    data: {
      Corridor: "CS",
      Flow_volume: 20999.979,
      MesoRegion: "SP2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 4,
      Total_cost_per_ton: 194.78068057008116,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 533,
    level: 1,
    label: "MG4_1",
    data: {
      Corridor: "CS",
      Flow_volume: 28905.785499999998,
      MesoRegion: "MG4",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 1,
      Total_cost_per_ton: 209.69025100866153,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 534,
    level: 1,
    label: "SP2_1",
    data: {
      Corridor: "CS",
      Flow_volume: 18402.653,
      MesoRegion: "SP2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 1,
      Total_cost_per_ton: 194.78068057008116,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 535,
    level: 1,
    label: "SP1_6",
    data: {
      Corridor: "CS",
      Flow_volume: 2100.0105,
      MesoRegion: "SP1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 6,
      Total_cost_per_ton: 139.5692586744794,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 536,
    level: 1,
    label: "SP2_3",
    data: {
      Corridor: "CS",
      Flow_volume: 20999.979,
      MesoRegion: "SP2",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 3,
      Total_cost_per_ton: 194.78068057008116,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 537,
    level: 1,
    label: "SP1_5",
    data: {
      Corridor: "CS",
      Flow_volume: 9449.958,
      MesoRegion: "SP1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 5,
      Total_cost_per_ton: 168.9327708327691,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 538,
    level: 1,
    label: "SP1_4",
    data: {
      Corridor: "CS",
      Flow_volume: 9449.958,
      MesoRegion: "SP1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 4,
      Total_cost_per_ton: 181.36391512044855,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 539,
    level: 1,
    label: "MG4_4",
    data: {
      Corridor: "CS",
      Flow_volume: 5250.0525,
      MesoRegion: "MG4",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 4,
      Total_cost_per_ton: 209.54931067852309,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 540,
    level: 1,
    label: "MG4_3",
    data: {
      Corridor: "CS",
      Flow_volume: 5250.0525,
      MesoRegion: "MG4",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "MG",
      TimePeriod: 3,
      Total_cost_per_ton: 209.54931067852309,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 541,
    level: 1,
    label: "SP1_1",
    data: {
      Corridor: "CS",
      Flow_volume: 24389.3855,
      MesoRegion: "SP1",
      Region: "Região Sudeste",
      Revenue_per_ton: 0,
      State: "SP",
      TimePeriod: 1,
      Total_cost_per_ton: 181.77613202969133,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 542,
    level: 1,
    label: "PR2_2",
    data: {
      Corridor: "SW",
      Flow_volume: 50399.709500000004,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 2,
      Total_cost_per_ton: 99.27319963088888,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 132,
    level: 3,
    label: "TRANSB_PR_CASCAVE_2",
    data: {
      Corridor: "SW",
      Flow_volume: 49999.84000000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 88.77919796478214,
      total_revenue: 0,
      total_volume: 49999.84000000002,
    },
  },
  {
    id: 543,
    level: 1,
    label: "PR2_10",
    data: {
      Corridor: "SW",
      Flow_volume: 4134.460234439961,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 10,
      Total_cost_per_ton: 329.6827085524,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 117,
    level: 4,
    label: "PORT_RIOGR_10",
    data: {
      Corridor: "S",
      Flow_volume: 27256.63623443996,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2388.465576,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 139.19584428155272,
      total_revenue: 65101537.36351411,
      total_volume: 27256.63623443996,
    },
  },
  {
    id: 544,
    level: 1,
    label: "PR2_1",
    data: {
      Corridor: "SW",
      Flow_volume: 69816.3605,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 1,
      Total_cost_per_ton: 95.54405615768148,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 134,
    level: 3,
    label: "TRANSB_PR_CASCAVE_1",
    data: {
      Corridor: "SW",
      Flow_volume: 29762.80580510568,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 89.06406998961,
      total_revenue: 0,
      total_volume: 29762.80580510568,
    },
  },
  {
    id: 545,
    level: 1,
    label: "PR1_8",
    data: {
      Corridor: "SW",
      Flow_volume: 119116.65183627553,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 8,
      Total_cost_per_ton: 89.98782006320351,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 546,
    level: 1,
    label: "PR1_7",
    data: {
      Corridor: "SW",
      Flow_volume: 95150.40328158544,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 7,
      Total_cost_per_ton: 90.09212167906809,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 547,
    level: 1,
    label: "PR2_4",
    data: {
      Corridor: "SW",
      Flow_volume: 15744.356,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 4,
      Total_cost_per_ton: 156.6658378126269,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 130,
    level: 3,
    label: "TRANSB_PR_CASCAVE_4",
    data: {
      Corridor: "SW",
      Flow_volume: 20.5695,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 89.7368667276,
      total_revenue: 0,
      total_volume: 20.5695,
    },
  },
  {
    id: 548,
    level: 1,
    label: "SC1_3",
    data: {
      Corridor: "SW",
      Flow_volume: 13899.968,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 3,
      Total_cost_per_ton: 156.65718290407554,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 77,
    level: 4,
    label: "PORT_IMBITUBA_3",
    data: {
      Corridor: "SW",
      Flow_volume: 11769.088500000002,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2410.362663,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 177.3993585600487,
      total_revenue: 28367771.49794268,
      total_volume: 11769.088500000002,
    },
  },
  {
    id: 549,
    level: 1,
    label: "PR1_5",
    data: {
      Corridor: "SW",
      Flow_volume: 130187.08685172153,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 5,
      Total_cost_per_ton: 94.59050497711831,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 550,
    level: 1,
    label: "PR1_4",
    data: {
      Corridor: "SW",
      Flow_volume: 143569.31966570328,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 4,
      Total_cost_per_ton: 103.45979549391268,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 551,
    level: 1,
    label: "PR1_3",
    data: {
      Corridor: "SW",
      Flow_volume: 53437.611,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 3,
      Total_cost_per_ton: 126.8931756635916,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 552,
    level: 1,
    label: "PR1_2",
    data: {
      Corridor: "SW",
      Flow_volume: 187603.87450000003,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 2,
      Total_cost_per_ton: 90.03402296913333,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 553,
    level: 1,
    label: "PR1_10",
    data: {
      Corridor: "SW",
      Flow_volume: 6977.4075,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 10,
      Total_cost_per_ton: 140.31,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 554,
    level: 1,
    label: "PR1_1",
    data: {
      Corridor: "SW",
      Flow_volume: 139371.1475,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 1,
      Total_cost_per_ton: 89.63283230257183,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 555,
    level: 1,
    label: "PR2_5",
    data: {
      Corridor: "SW",
      Flow_volume: 2911.2488051066925,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 5,
      Total_cost_per_ton: 192.5308872306,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 90,
    level: 4,
    label: "PORT_PARAN_ROCHA_5",
    data: {
      Corridor: "SW",
      Flow_volume: 2150.423523433669,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2519.412844,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 158.69124912004443,
      total_revenue: 5417804.64497852,
      total_volume: 2150.423523433669,
    },
  },
  {
    id: 556,
    level: 1,
    label: "PR1_6",
    data: {
      Corridor: "SW",
      Flow_volume: 83207.91872440629,
      MesoRegion: "PR1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 6,
      Total_cost_per_ton: 89.26969305509648,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 557,
    level: 1,
    label: "PR2_6",
    data: {
      Corridor: "SW",
      Flow_volume: 75000,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 6,
      Total_cost_per_ton: 199.41102189835,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 116,
    level: 4,
    label: "PORT_PARAN_ROCHA_6",
    data: {
      Corridor: "SW",
      Flow_volume: 46349.57647656633,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2528.24592,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 147.58656903721334,
      total_revenue: 117183127.62060681,
      total_volume: 46349.57647656633,
    },
  },
  {
    id: 558,
    level: 1,
    label: "PR3_1",
    data: {
      Corridor: "SW",
      Flow_volume: 80990.05500000001,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 1,
      Total_cost_per_ton: 110.87727272977472,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 559,
    level: 1,
    label: "SC1_4",
    data: {
      Corridor: "SW",
      Flow_volume: 9300.032500000001,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 4,
      Total_cost_per_ton: 159.24115455574,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 76,
    level: 4,
    label: "PORT_IMBITUBA_4",
    data: {
      Corridor: "SW",
      Flow_volume: 7617.735,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2463.113868,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 166.10448410307038,
      total_revenue: 18763348.721248977,
      total_volume: 7617.735,
    },
  },
  {
    id: 560,
    level: 1,
    label: "RS2_4",
    data: {
      Corridor: "S",
      Flow_volume: 50500.021,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 4,
      Total_cost_per_ton: 185.42243133134517,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 290,
    level: 2,
    label: "CRUSH_RS_RGD_4",
    data: {
      Corridor: "S",
      Flow_volume: 90219.99999999997,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 814.2118322248396,
      State: "NA",
      TimePeriod: 4,
      Total_cost_per_ton: 171.7140018139672,
      total_revenue: 88580059.33613761,
      total_volume: 108792.39999999997,
    },
  },
  {
    id: 561,
    level: 1,
    label: "RS2_5",
    data: {
      Corridor: "S",
      Flow_volume: 47250.0105,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 5,
      Total_cost_per_ton: 185.18423542883113,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 289,
    level: 2,
    label: "CRUSH_RS_RGD_5",
    data: {
      Corridor: "S",
      Flow_volume: 84000,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 892.7509740967507,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 171.97636274613734,
      total_revenue: 92174538.31330754,
      total_volume: 103247.76000000001,
    },
  },
  {
    id: 562,
    level: 1,
    label: "RS2_6",
    data: {
      Corridor: "S",
      Flow_volume: 42000.010500000004,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 6,
      Total_cost_per_ton: 155.4385139032246,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 249,
    level: 2,
    label: "CRUSH_RS_RGD_6",
    data: {
      Corridor: "S",
      Flow_volume: 26807.213583393313,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2038.6909466799175,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 145.3928299467059,
      total_revenue: 95268708.01179644,
      total_volume: 46730.33358339331,
    },
  },
  {
    id: 99,
    level: 4,
    label: "PORT_RIOGR_6",
    data: {
      Corridor: "S",
      Flow_volume: 174124.82917701403,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2531.8910240000005,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 139.98455691011813,
      total_revenue: 440865092.0488152,
      total_volume: 174124.82917701403,
    },
  },
  {
    id: 563,
    level: 1,
    label: "RS2_7",
    data: {
      Corridor: "S",
      Flow_volume: 31499.9685,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 7,
      Total_cost_per_ton: 156.5360885706967,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 248,
    level: 2,
    label: "CRUSH_RS_RGD_7",
    data: {
      Corridor: "S",
      Flow_volume: 90741.09348143195,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 840.4998272559708,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 144.47399668547698,
      total_revenue: 92445612.35122582,
      total_volume: 109988.85348143194,
    },
  },
  {
    id: 564,
    level: 1,
    label: "RS2_8",
    data: {
      Corridor: "S",
      Flow_volume: 31499.9685,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 8,
      Total_cost_per_ton: 156.5220721772541,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 247,
    level: 2,
    label: "CRUSH_RS_RGD_8",
    data: {
      Corridor: "S",
      Flow_volume: 124200.00000000001,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 664.850290896838,
      State: "NA",
      TimePeriod: 8,
      Total_cost_per_ton: 144.65454697938517,
      total_revenue: 95820298.25695992,
      total_volume: 144123.12000000002,
    },
  },
  {
    id: 565,
    level: 1,
    label: "RS3_1",
    data: {
      Corridor: "S",
      Flow_volume: 149731.9095,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 1,
      Total_cost_per_ton: 133.42592624712458,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 295,
    level: 2,
    label: "CRUSH_RS_RGD_1",
    data: {
      Corridor: "S",
      Flow_volume: 42049.99999999994,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 1558.6577091853512,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 172.175819376992,
      total_revenue: 96594881.25026877,
      total_volume: 61973.11999999994,
    },
  },
  {
    id: 79,
    level: 4,
    label: "PORT_IMBITUBA_1",
    data: {
      Corridor: "SW",
      Flow_volume: 40357.5625,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2449.795999,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 189.63217405452292,
      total_revenue: 98867795.14189243,
      total_volume: 40357.5625,
    },
  },
  {
    id: 95,
    level: 4,
    label: "PORT_RIOGR_TERCEIROS_1",
    data: {
      Corridor: "S",
      Flow_volume: 106441.62943144677,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2480.841104,
      State: "NA",
      TimePeriod: 1,
      Total_cost_per_ton: 157.9858253268697,
      total_revenue: 264064769.4702693,
      total_volume: 106441.62943144677,
    },
  },
  {
    id: 566,
    level: 1,
    label: "RS3_10",
    data: {
      Corridor: "S",
      Flow_volume: 26249.979,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 10,
      Total_cost_per_ton: 138.14106662554917,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 294,
    level: 2,
    label: "CRUSH_RS_RGD_10",
    data: {
      Corridor: "S",
      Flow_volume: 3127.803,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 10,
      Total_cost_per_ton: 164.84684079488,
      total_revenue: 0,
      total_volume: 3127.803,
    },
  },
  {
    id: 567,
    level: 1,
    label: "RS3_2",
    data: {
      Corridor: "S",
      Flow_volume: 131844.1815,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 2,
      Total_cost_per_ton: 132.83039675324832,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 292,
    level: 2,
    label: "CRUSH_RS_RGD_2",
    data: {
      Corridor: "S",
      Flow_volume: 1214.0575,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 4562.96792386909,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 178.23746286937777,
      total_revenue: 93366616.9165632,
      total_volume: 20461.817499999997,
    },
  },
  {
    id: 78,
    level: 4,
    label: "PORT_IMBITUBA_2",
    data: {
      Corridor: "SW",
      Flow_volume: 24613.176499999994,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2444.866832,
      State: "NA",
      TimePeriod: 2,
      Total_cost_per_ton: 174.33536463575408,
      total_revenue: 60175938.855011836,
      total_volume: 24613.176499999994,
    },
  },
  {
    id: 568,
    level: 1,
    label: "RS3_3",
    data: {
      Corridor: "S",
      Flow_volume: 99801.4565,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 3,
      Total_cost_per_ton: 133.05545616122131,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 291,
    level: 2,
    label: "CRUSH_RS_RGD_3",
    data: {
      Corridor: "S",
      Flow_volume: 120899.74000000003,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 676.6957654104282,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 167.6550564782496,
      total_revenue: 95294233.0349856,
      total_volume: 140822.86000000004,
    },
  },
  {
    id: 569,
    level: 1,
    label: "RS3_4",
    data: {
      Corridor: "S",
      Flow_volume: 105008.9895,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 4,
      Total_cost_per_ton: 131.47239585170334,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 570,
    level: 1,
    label: "RS3_5",
    data: {
      Corridor: "S",
      Flow_volume: 94500.0105,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 5,
      Total_cost_per_ton: 132.06754477337375,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 571,
    level: 1,
    label: "RS3_6",
    data: {
      Corridor: "S",
      Flow_volume: 99749.9895,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 6,
      Total_cost_per_ton: 110.96885064874833,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 87,
    level: 4,
    label: "PORT_IMBITUBA_6",
    data: {
      Corridor: "SW",
      Flow_volume: 10802.042642546823,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2528.2459200000003,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 147.14874672827568,
      total_revenue: 27310220.238685027,
      total_volume: 10802.042642546823,
    },
  },
  {
    id: 167,
    level: 3,
    label: "TRANSB_RS_CANOAS_6",
    data: {
      Corridor: "S",
      Flow_volume: 29999.999999999996,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 6,
      Total_cost_per_ton: 74.87472696295,
      total_revenue: 0,
      total_volume: 29999.999999999996,
    },
  },
  {
    id: 572,
    level: 1,
    label: "RS3_7",
    data: {
      Corridor: "S",
      Flow_volume: 83999.9685,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 7,
      Total_cost_per_ton: 114.15319206921525,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 74,
    level: 4,
    label: "PORT_IMBITUBA_7",
    data: {
      Corridor: "SW",
      Flow_volume: 14311.614879869063,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2543.53773,
      State: "NA",
      TimePeriod: 7,
      Total_cost_per_ton: 151.8130194033911,
      total_revenue: 36402132.42417638,
      total_volume: 14311.614879869063,
    },
  },
  {
    id: 573,
    level: 1,
    label: "RS3_8",
    data: {
      Corridor: "S",
      Flow_volume: 83999.9685,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 8,
      Total_cost_per_ton: 114.89551641871167,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 574,
    level: 1,
    label: "RS3_9",
    data: {
      Corridor: "S",
      Flow_volume: 30450,
      MesoRegion: "RS3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 9,
      Total_cost_per_ton: 138.14106662554917,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 575,
    level: 1,
    label: "SC1_1",
    data: {
      Corridor: "SW",
      Flow_volume: 77831.8245,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 1,
      Total_cost_per_ton: 173.11842326295778,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 576,
    level: 1,
    label: "SC1_2",
    data: {
      Corridor: "SW",
      Flow_volume: 61392.589,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 2,
      Total_cost_per_ton: 163.53239205852825,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 577,
    level: 1,
    label: "RS2_3",
    data: {
      Corridor: "S",
      Flow_volume: 41999.759,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 3,
      Total_cost_per_ton: 185.18423542883113,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 578,
    level: 1,
    label: "RS2_2",
    data: {
      Corridor: "S",
      Flow_volume: 56106.117000000006,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 2,
      Total_cost_per_ton: 185.18423542883113,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 579,
    level: 1,
    label: "RS2_1",
    data: {
      Corridor: "S",
      Flow_volume: 68697.80750000001,
      MesoRegion: "RS2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 1,
      Total_cost_per_ton: 185.18423542883113,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 580,
    level: 1,
    label: "RS1_9",
    data: {
      Corridor: "S",
      Flow_volume: 20999.9685,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 9,
      Total_cost_per_ton: 195.7299855241398,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 246,
    level: 2,
    label: "CRUSH_RS_RGD_9",
    data: {
      Corridor: "S",
      Flow_volume: 2217.4005,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 4312.839592546253,
      State: "NA",
      TimePeriod: 9,
      Total_cost_per_ton: 167.8140252423,
      total_revenue: 95491274.08382854,
      total_volume: 22141.16060538471,
    },
  },
  {
    id: 581,
    level: 1,
    label: "PR3_2",
    data: {
      Corridor: "SW",
      Flow_volume: 79395.105,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 2,
      Total_cost_per_ton: 110.28802776534118,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 582,
    level: 1,
    label: "PR3_3",
    data: {
      Corridor: "SW",
      Flow_volume: 52896.0495,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 3,
      Total_cost_per_ton: 134.57352206226764,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 583,
    level: 1,
    label: "PR3_4",
    data: {
      Corridor: "SW",
      Flow_volume: 39876.1585,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 4,
      Total_cost_per_ton: 131.8494152156953,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 584,
    level: 1,
    label: "PR3_5",
    data: {
      Corridor: "SW",
      Flow_volume: 19106.97,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 5,
      Total_cost_per_ton: 144.13518190835765,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 585,
    level: 1,
    label: "PR3_6",
    data: {
      Corridor: "SW",
      Flow_volume: 10499.979,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 6,
      Total_cost_per_ton: 120.79978581204472,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 586,
    level: 1,
    label: "PR3_8",
    data: {
      Corridor: "SW",
      Flow_volume: 196.707,
      MesoRegion: "PR3",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 8,
      Total_cost_per_ton: 121.40559563839764,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 587,
    level: 1,
    label: "SC1_8",
    data: {
      Corridor: "SW",
      Flow_volume: 20999.95769667527,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 8,
      Total_cost_per_ton: 149.54111138462446,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 588,
    level: 1,
    label: "SC1_7",
    data: {
      Corridor: "SW",
      Flow_volume: 20999.95778642845,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 7,
      Total_cost_per_ton: 133.86187849910667,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 589,
    level: 1,
    label: "PR2_7",
    data: {
      Corridor: "SW",
      Flow_volume: 60865.53976556004,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 7,
      Total_cost_per_ton: 186.152104206,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 590,
    level: 1,
    label: "SC1_6",
    data: {
      Corridor: "SW",
      Flow_volume: 15750.010393215665,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 6,
      Total_cost_per_ton: 132.95205902833044,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 591,
    level: 1,
    label: "RS1_1",
    data: {
      Corridor: "S",
      Flow_volume: 133527.553,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 1,
      Total_cost_per_ton: 170.8972199258578,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 592,
    level: 1,
    label: "RS1_2",
    data: {
      Corridor: "S",
      Flow_volume: 97607.00950000001,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 2,
      Total_cost_per_ton: 170.54110049860273,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 593,
    level: 1,
    label: "RS1_3",
    data: {
      Corridor: "S",
      Flow_volume: 123677.19996989971,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 3,
      Total_cost_per_ton: 171.13579986915371,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 594,
    level: 1,
    label: "RS1_4",
    data: {
      Corridor: "S",
      Flow_volume: 103167.0205,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 4,
      Total_cost_per_ton: 166.96149364579537,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 595,
    level: 1,
    label: "RS1_5",
    data: {
      Corridor: "S",
      Flow_volume: 95862.258,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 5,
      Total_cost_per_ton: 168.3418631834807,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 75,
    level: 4,
    label: "PORT_IMBITUBA_5",
    data: {
      Corridor: "SW",
      Flow_volume: 505.33099999999996,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 2519.412844,
      State: "NA",
      TimePeriod: 5,
      Total_cost_per_ton: 125.03768500976666,
      total_revenue: 1273137.4118713639,
      total_volume: 505.33099999999996,
    },
  },
  {
    id: 596,
    level: 1,
    label: "RS1_6",
    data: {
      Corridor: "S",
      Flow_volume: 95861.50314797624,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 6,
      Total_cost_per_ton: 142.25588826260883,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 597,
    level: 1,
    label: "RS1_7",
    data: {
      Corridor: "S",
      Flow_volume: 83999.9892261548,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 7,
      Total_cost_per_ton: 145.02860781902922,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 598,
    level: 1,
    label: "RS1_8",
    data: {
      Corridor: "S",
      Flow_volume: 73499.9999999953,
      MesoRegion: "RS1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "RS",
      TimePeriod: 8,
      Total_cost_per_ton: 141.4882982439591,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 599,
    level: 1,
    label: "SC1_5",
    data: {
      Corridor: "SW",
      Flow_volume: 14351.4625,
      MesoRegion: "SC1",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "SC",
      TimePeriod: 5,
      Total_cost_per_ton: 171.8479852396234,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 600,
    level: 1,
    label: "PR2_3",
    data: {
      Corridor: "SW",
      Flow_volume: 11449.4675,
      MesoRegion: "PR2",
      Region: "Região Sul",
      Revenue_per_ton: 0,
      State: "PR",
      TimePeriod: 3,
      Total_cost_per_ton: 139.54350222372307,
      total_revenue: 0,
      total_volume: 0,
    },
  },
  {
    id: 131,
    level: 3,
    label: "TRANSB_PR_CASCAVE_3",
    data: {
      Corridor: "SW",
      Flow_volume: 2674.5555,
      MesoRegion: "NA",
      Region: "NA",
      Revenue_per_ton: 0,
      State: "NA",
      TimePeriod: 3,
      Total_cost_per_ton: 80.7834704862,
      total_revenue: 0,
      total_volume: 2674.5555,
    },
  },
];

