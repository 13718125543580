import { useMemo } from 'react'
import BarChart from 'components/Graphs/BarChart'
import { capitalize } from 'utils/string'
import {
  NO_DATA_LABEL,
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { SummaryCard } from 'components/Cards'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import NoData from 'components/Feedback/NoData'
import useInboundRoutes from './useInboundRoutes'
import {
  labelFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const InboundRoutes = ({
  transportationMode,
  volumes,
  selectedSubProducts
}) => {
  const { t } = useLocalization()
  const { selectedProducts } = useOptimizationContext()
  const filteredBySelectedSubProducts = useMemo(() => {
    return selectedProducts.length > 0
      ? volumes.filter((p) => selectedSubProducts.includes(p.product))
      : volumes
  }, [volumes, selectedSubProducts, selectedProducts])

  const { summaryItems, volumeChartData, bars } = useInboundRoutes(
    filteredBySelectedSubProducts
  )

  return (
    <div className='mb-10'>
      <h3 className='text-base font-bold'>{capitalize(transportationMode)}</h3>
      {!hasNoData(volumeChartData) ? (
        <>
          <div className='flex flex-wrap gap-4'>
            {summaryItems?.map(({ title, ...rest }) => (
              <SummaryCard key={title} title={title} {...rest} />
            ))}
          </div>
          <BarChart
            variant='default350'
            containerClassNames='!h-[250px] mt-6'
            data={volumeChartData}
            tooltipFormatter={labelFormatter}
            yAxisProps={{ tickFormatter: yAxisFormatter }}
            bars={bars}
            scatters={
              volumeChartData.some((i) => i.topContracts !== NO_DATA_LABEL)
                ? [
                    {
                      dataKey: 'topContracts',
                      fill: '#5B6673',
                      name: t('optimizationScreen.toPContracts')
                    }
                  ]
                : undefined
            }
            xAxisProps={{
              dataKey: 'month',
              tickFormatter: transformDateToShortMonth
            }}
          />
        </>
      ) : (
        <NoData page={t('optimizationScreen.pageNames.export')} />
      )}
    </div>
  )
}

export default InboundRoutes
