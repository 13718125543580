import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  Fragment
} from 'react'
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ArrowDownTrayIcon,
  DocumentMagnifyingGlassIcon,
  ArrowDownCircleIcon,
  ArrowUpCircleIcon
} from '@heroicons/react/24/outline'
import Button from 'components/Inputs/Button'
import * as Collapsible from '@radix-ui/react-collapsible'
import * as HoverCard from '@radix-ui/react-hover-card'
import * as Tabs from '@radix-ui/react-tabs'

import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useToast } from 'providers/ToastProvider'
import { getBlob } from 'utils/request'
import * as FileSaver from 'file-saver'
import { useLocalization } from 'providers/LocalizationProvider'
import SearchInput from 'components/Inputs/SearchInput'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table'
import classNames from 'classnames'

const infeasibleDataMapper = (infeasibleTexts, { plan, adherence }) => ({
  title: infeasibleTexts[plan]?.title,
  description: infeasibleTexts[plan]?.description,
  percentage: Math.round(Math.max(Math.min(1, adherence), 0) * 100),
  percentageLabel: infeasibleTexts[plan]?.percentageLabel,
  details: {
    detailTitle: infeasibleTexts[plan]?.details.detailTitle,
    detailDescription: infeasibleTexts[plan]?.details.detailDescription,
    bulletPoints: infeasibleTexts[plan]?.details.bulletPoints
  }
})

const tabsDataMapper = (data) => {
  return data.overviewPlanViolations.reduce((acc, currentViol, idx) => {
    if (!acc.length)
      return [
        ...acc,
        {
          label: currentViol.sheetName,
          value: currentViol.sheetName
        }
      ]
    if (
      !acc.filter((element) => element.label === currentViol.sheetName).length
    ) {
      return [
        ...acc,
        {
          label: currentViol.sheetName,
          value: currentViol.sheetName
        }
      ]
    }
    return [...acc]
  }, [])
}

const tablesDataMapper = (data) =>
  data.overviewPlanViolations.map((violation) => ({
    tab: violation.sheetName,
    headers: violation.headers,
    rows: violation.rows
  }))

const columnHelper = createColumnHelper()

const COLLAPISABLE_OPEN_HEIGHT = 610
const COLLAPISABLE_CLOSED_HEIGHT = 468

const InfeasibleRun = () => {
  const { id } = useParams()
  const { data, isLoading } = useSWR(`/execution/${id}/infeasible`)
  const { t } = useLocalization()

  const { showToast } = useToast()

  const infeasibleTexts = useMemo(
    () => ({
      crush: {
        title: t('optimizationScreen.infeasible.crush.title'),
        description: t('optimizationScreen.infeasible.crush.description'),
        percentageLabel: t(
          'optimizationScreen.infeasible.crush.percentageLabel'
        ),
        details: {
          detailTitle: t('optimizationScreen.infeasible.crush.detailTitle'),
          detailDescription: t(
            'optimizationScreen.infeasible.crush.detailDescription'
          ),
          bulletPoints: [
            t('optimizationScreen.infeasible.crush.point1'),
            t('optimizationScreen.infeasible.crush.point2'),
            t('optimizationScreen.infeasible.crush.point3')
          ]
        }
      },
      origination: {
        title: t('optimizationScreen.infeasible.origination.title'),
        description: t('optimizationScreen.infeasible.origination.description'),
        percentageLabel: t(
          'optimizationScreen.infeasible.origination.percentageLabel'
        ),
        details: {
          detailTitle: t(
            'optimizationScreen.infeasible.origination.detailTitle'
          ),
          detailDescription: t(
            'optimizationScreen.infeasible.origination.detailDescription'
          ),
          bulletPoints: [
            t('optimizationScreen.infeasible.origination.point1'),
            t('optimizationScreen.infeasible.origination.point2')
          ]
        }
      },
      exports: {
        title: t('optimizationScreen.infeasible.exports.title'),
        description: t('optimizationScreen.infeasible.exports.description'),
        percentageLabel: t(
          'optimizationScreen.infeasible.exports.percentageLabel'
        ),
        details: {
          detailTitle: t('optimizationScreen.infeasible.exports.detailTitle'),
          detailDescription: t(
            'optimizationScreen.infeasible.exports.detailDescription'
          ),
          bulletPoints: [
            t('optimizationScreen.infeasible.exports.point1'),
            t('optimizationScreen.infeasible.exports.point2'),
            t('optimizationScreen.infeasible.exports.point3')
          ]
        }
      }
    }),
    [t]
  )

  const [isOpen, setIsOpen] = useState(true)

  const [selectedTab, setSelectedTab] = useState('')
  const [currentColumns, setCurrentColumns] = useState([])
  const [currentRowsData, setCurrentRowsData] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [debouncedGlobalFilter, setDebouncedGlobalFilter] = useState('')

  const [tabContentHeight, setTabContentHeight] = useState()

  const mappedData = useMemo(() => {
    if (isLoading) {
      return []
    }
    return {
      infeasibleData: data.overviewPlan.map((item) =>
        infeasibleDataMapper(infeasibleTexts, item)
      ),
      tabs: data.overviewPlanViolations ? tabsDataMapper(data) : [],
      tableData: data.overviewPlanViolations ? tablesDataMapper(data) : []
    }
  }, [isLoading, data, infeasibleTexts])

  const toogleCollapsibleContent = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const onClickHandler = async () => {
    try {
      const outputResponse = await getBlob(
        `/execution/${id}/model/infeasible/download`
      )
      FileSaver.saveAs(outputResponse, 'model_output.xlsx')
    } catch (err) {
      showToast({
        message: t('optimizationScreen.infeasible.errorDownloading'),
        placement: 'top',
        status: 'error'
      })
    }
  }

  const onNextClick = useCallback(() => {
    if (mappedData.tabs.length) {
      const tabsLength = mappedData.tabs.length
      const currentTabIdx = mappedData.tabs.findIndex(
        ({ value: tabValue }) => tabValue === selectedTab
      )

      if (currentTabIdx + 1 < tabsLength) {
        setSelectedTab(mappedData.tabs[currentTabIdx + 1].value)
      } else {
        setSelectedTab(mappedData.tabs[0].value)
      }
    }
  }, [mappedData.tabs, selectedTab])

  const onPreviousClick = useCallback(() => {
    if (mappedData.tabs.length) {
      const tabsLength = mappedData.tabs.length
      const currentTabIdx = mappedData.tabs.findIndex(
        ({ value: tabValue }) => tabValue === selectedTab
      )
      if (currentTabIdx > 0) {
        setSelectedTab(mappedData.tabs[currentTabIdx - 1].value)
      } else {
        setSelectedTab(mappedData.tabs[tabsLength - 1].value)
      }
    }
  }, [mappedData.tabs, selectedTab])

  const handleInputChange = (event) => {
    setGlobalFilter(event)
  }

  useEffect(() => {
    if (mappedData.tabs.length) setSelectedTab(mappedData.tabs[0].value)
  }, [mappedData])

  useEffect(() => {
    if (selectedTab) {
      const currentTab = mappedData.tableData.find(
        (table) => table.tab === selectedTab
      )
      const currentColumns = currentTab.headers.map((e) =>
        columnHelper.accessor(e, {
          cell: (info) => info.getValue(),
          header: e
        })
      )
      setCurrentColumns([...currentColumns])
      setCurrentRowsData([...currentTab.rows])
    }
  }, [mappedData.tableData, selectedTab])

  const updateTableHeight = useCallback(() => {
    const vh = window.innerHeight
    const tableHeight = isOpen
      ? vh - COLLAPISABLE_OPEN_HEIGHT
      : vh - COLLAPISABLE_CLOSED_HEIGHT
    setTabContentHeight(tableHeight)
  }, [isOpen])

  useEffect(() => {
    updateTableHeight()
    window.addEventListener('resize', updateTableHeight)
    return () => window.removeEventListener('resize', updateTableHeight)
  }, [updateTableHeight])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedGlobalFilter(globalFilter)
    }, 500)

    return () => clearTimeout(timeout)
  }, [globalFilter])

  const table = useReactTable({
    columns: currentColumns,
    data: currentRowsData,
    globalFilterFn: 'includesString',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setDebouncedGlobalFilter,
    debugTable: true,
    state: {
      globalFilter: debouncedGlobalFilter
    }
  })

  if (!data.overviewPlanViolations) {
    return (
      <div className='h-full'>
        <div className='bg-white px-16 py-4 border-b border-gray-300'>
          No infeasible data available for this run!
        </div>
      </div>
    )
  }

  return (
    <div className='overflow-y-hidden h-full flex flex-col'>
      <div className='bg-white px-16 py-4 border-b border-gray-300'>
        <p className='text-2xl font-bold flex items-center gap-2'>
          <i>
            <MagnifyingGlassIcon className='h-6 w-6 text-gray-500' />
          </i>
          <h1 className='text-blue-700'>
            {t('optimizationScreen.infeasible.notFindResult')}
          </h1>
        </p>
        <p className='text-sm text-gray-500'>
          {t('optimizationScreen.infeasible.notFindResultDesc')}
        </p>
      </div>

      {/* Container */}
      <div className='flex-none flex grow'>
        {/* left container */}
        <div className='w-3/4 max-h-full pr-3 border-r border-gray-300 pl-16 pt-6'>
          <div className='flex items-center gap-1'>
            <h2 className='text-xl font-bold text-blue-700'>Model Results</h2>
            <InformationCircleIcon className='h-5 w-5 stroke-2' />
          </div>
          {/* Cards */}
          <Collapsible.Root open={isOpen}>
            <Collapsible.Content className='flex gap-2 pt-3 open-close-animation flex-nowrap overflow-x-auto'>
              {mappedData.infeasibleData.map(
                (
                  { title, description, percentage, percentageLabel, details },
                  idx
                ) => (
                  <div
                    className='bg-white border-gray-300 border rounded-md relative p-3 min-w-fit'
                    key={`${idx}-${title}`}
                  >
                    <HoverCard.Root>
                      <HoverCard.Trigger asChild>
                        <InformationCircleIcon className='w-4 h-4 absolute right-2 top-2 stroke-2 text-blue-600 hover:text-blue-700' />
                      </HoverCard.Trigger>
                      <HoverCard.Portal>
                        <HoverCard.Content side='right' align='start'>
                          <HoverCard.Arrow />
                          <div className='bg-white border-gray-300 border rounded-md shadow-md w-64 p-3'>
                            <p className='font-bold text-blue-700'>
                              {details.detailTitle}
                            </p>
                            <p className='pt-2 text-sm'>
                              {details.detailDescription}
                            </p>
                            <ul className='px-4 text-sm text-gray-700 text-pretty'>
                              {details.bulletPoints.map((bp, index) => (
                                <li key={index} className='list-disc pt-2'>
                                  {bp}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </HoverCard.Content>
                      </HoverCard.Portal>
                    </HoverCard.Root>

                    <div className='flex items-center justify-start gap-2'>
                      <DocumentMagnifyingGlassIcon className='w-6 h-6 text-blue-500 stroke-2' />
                      <p className='font-bold mt-1'>{title}</p>
                    </div>

                    <div className='p-2 text-sm font-bold text-gray-400'>
                      <p className='py-2'>{description}</p>
                      <div className='flex justify-between pt-2'>
                        <p>{percentageLabel}</p>
                        <p>{`${percentage}%`}</p>
                      </div>
                      <div className='h-2 w-full bg-neutral-200 rounded-lg'>
                        <div
                          className='h-2 rounded-full bg-blue-500'
                          style={{ width: `${percentage}%` }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </Collapsible.Content>
            <Collapsible.Trigger asChild>
              <div className='flex items-center -mx-3 pt-4 pb-3'>
                <button
                  onClick={toogleCollapsibleContent}
                  className='text-gray-300'
                >
                  {isOpen ? (
                    <ArrowUpCircleIcon className='h-8 w-8 stroke-1' />
                  ) : (
                    <ArrowDownCircleIcon className='h-8 w-8 stroke-1' />
                  )}
                </button>
                <div className='border-t border-gray-300 w-[110%] -ml-1' />
              </div>
            </Collapsible.Trigger>
          </Collapsible.Root>

          {/* Search */}
          <div className='flex items-center justify-start gap-2 pb-4'>
            <h2 className='font-bold text-xl text-blue-700'>Appointments</h2>
            <InformationCircleIcon className='h-5 w-5 mt-0.5 text-gray-500 stroke-[2px]' />
          </div>
          <div className='flex items-center gap-2'>
            <SearchInput
              fullWidth
              placeHolder={'Search'}
              value={globalFilter ?? ''}
              onChange={handleInputChange}
              disabled={!mappedData.tabs}
            />
            <Button variant='filled' onClick={onClickHandler}>
              <ArrowDownTrayIcon className='h-5 w-5' />
              {t('optimizationScreen.infeasible.downloadPlan')}
            </Button>
          </div>

          {/* Tabs */}
          <Tabs.Root
            value={selectedTab}
            onValueChange={(value) => setSelectedTab(value)}
          >
            {!!mappedData.tabs.length && (
              <Tabs.List className='flex flex-nowrap overflow-x-auto gap-5 overflow hide-scrollbar mb-2 outline-b- border-b border-b-gray-400 border'>
                {mappedData.tabs.map((tab, idx) => (
                  <Tabs.Trigger
                    key={`${tab.label}-${idx}`}
                    value={tab.value}
                    className={classNames('py-4 px-2 first:pl-0 last:pr-0', {
                      'border-b-blue-500 border-b-2 text-blue-500 font-bold':
                        tab.value === selectedTab,
                      ' text-gray-500': tab.value !== selectedTab
                    })}
                  >
                    {tab.label}
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
            )}
            {/* Table */}
            {!!mappedData.tabs.length &&
              mappedData.tabs.map((tab) => (
                <Tabs.Content
                  key={`tab-content-${tab.value}`}
                  value={tab.value}
                  className={`w-full overflow-auto hide-scrollbar p-1`}
                  style={{ maxHeight: `${tabContentHeight}px` }}
                >
                  <table className='w-full min-w-max border-separate border-spacing-0 border-2 border-gray-200 rounded-xl text-sm'>
                    <thead className='w-full table-header-group border-separate border-inherit '>
                      {!!table.options.columns &&
                        table.getHeaderGroups().map((headerGroup) => (
                          <tr
                            key={headerGroup.id}
                            className='table-row align-[inherit] border-inherit '
                          >
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.id}
                                className='font-bold text-gray-500 text-center align-top bg-gray-100 first:rounded-tl-xl last:rounded-tr-xl border-b border-gray-400/50 py-5 px-3'
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                    </thead>
                    <tbody className='w-full *:first:'>
                      {table.getRowModel().rows.map((row) => (
                        <tr
                          key={row.id}
                          className='table-row align-[inherit] border-inherit bg-white group [&:nth-child(2n+2)]:bg-white/80'
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td
                              key={cell.id}
                              className='text-left text-gray-600 align-top group-last:first:rounded-bl-xl group-last:last:rounded-br-xl [&:nth-child(n+1)]:border-t py-5 px-3'
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Tabs.Content>
              ))}
          </Tabs.Root>
        </div>

        {/* Right Container */}
        <div className='w-1/4 min-w-min flex flex-col'>
          <div className='text-lg flex gap-2 justify-start items-center px-3 pt-6'>
            <h2 className='font-bold'>Data</h2>
            <InformationCircleIcon className='h-5 w-5 text-gray-500 stroke-[2px]' />
          </div>
          <div className='text-sm flex-row flex-auto overflow-y-auto min-h-0 h-96 px-3'>
            <div className='py-6'>
              <ul className='px-2'>
                {selectedTab &&
                  t(
                    `optimizationScreen.infeasible.assistMessages.${selectedTab}`,
                    { returnObjects: true, joinArrays: ';' }
                  )
                    .split(';')
                    .map((text, idx) =>
                      text ? (
                        <li className='list-disc' key={`assist-message-${idx}`}>
                          {text}
                          <br />
                        </li>
                      ) : (
                        <Fragment key={`assist-message-${idx}`} />
                      )
                    )}
              </ul>
            </div>
          </div>
          <div className='flex justify-end gap-3 h-14 items-center border-t border-gray-300 px-3'>
            <button
              className='border-2 border-gray-300 bg-white rounded-lg text-sm text-gray-600 px-4 py-2 aria-disabled:opacity-60'
              onClick={onPreviousClick}
              disabled={!mappedData.tabs.length}
              aria-disabled={!mappedData.tabs.length}
            >
              Previous
            </button>
            <button
              className='border-2 border-gray-300 bg-white rounded-lg text-sm text-gray-600 px-4 py-2 aria-disabled:opacity-60'
              onClick={onNextClick}
              disabled={!mappedData.tabs.length}
              aria-disabled={!mappedData.tabs.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfeasibleRun
