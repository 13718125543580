import { useController } from 'react-hook-form'
import RadioGroup from './RadioGroup'

const ControlledRadioGroup = ({ controllerProps, onChange, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps)

  return (
    <RadioGroup
      {...props}
      onChange={(value) => {
        field.onChange(value)
        onChange && onChange(value)
      }}
      value={field.value}
      error={error && error.message}
    />
  )
}

export default ControlledRadioGroup
