import { useCallback } from 'react'
import { TableGroupHeaders } from './internal/TableGroupHeaders'
import { TableHeaders } from './internal/TableHeaders'
import { useGridTemplateColumns } from './internal/useGridTemplateColumns'
import { useRecursiveSubRowMapper } from './internal/useRecursiveSubRowMapper'
import { useTableContext } from './internal/TableContext'
import { TableRow } from './internal/TableRow'

/**
 *
 * @param {Object[]} data List of objects that will be mapped to rows
 * @param {string[] | undefined} omitKeysFromData
 * Object keys to omit when auto-generating column mappers,
 * if you provide an array of any elements for `columnMappers`, this is ignored and those mappers will be used
 * @param {undefined | ((item: Object, index: number) => string | number)} keyMapper
 * A function that provides keys for each row,
 * if not defined the array index will be used, which might cause more full renders
 * @param {undefined | ({ className?: string | string[] | ((item: Object) => string | string[]); content: ({item: Object; ctx: Object; rowIndex: number; columnIndex: number}) => JSX.Element } | ({item: object; ctx: object; rowIndex: number; columnIndex: number}) => JSX.Element)[]} columnMappers
 * An array of mappers to be used to render cells in rows, a mapper can be a React component, or an object providing a class name and the component
 * @param {undefined | Object | (item: Object) => Object} rowContext
 * An object or factory function to provide extra context to each row
 * You can provide a custom hook here, but be careful since these factory functions are invoked in a loop
 * If a function named `onClick` exist, it will make the row clickable and call this function
 * (This overrides the global `rowClickMode` setting)
 * @param {undefined | string | string[]} columnSizes
 * Definition for column width, if empty all columns are treated as `auto`
 * See the `grid-template-columns` CSS property for more info
 * @param {undefined | ReactNode[]} columnHeaders
 * List of column headers, if empty, no headers rendered
 * @param {undefined | ({ start: number; span: number; title: ReactNode })[]} columnHeaderGroups
 * An additional row over the headers that can span multiple columns
 * @param {boolean} stickyHeaders
 * Make the column headers sticky
 * @param {undefined | number} stickyFirstColumns
 * Make the first *N* columns sticky
 * @param {boolean} checkableRows
 * Add checkmarks to each row, you will need to provide a `checked` and `setChecked` property in the row context
 * @param {undefined | (item: Object) => (Object[] | ReactNode)} renderExpandedRow
 * A function to provide content when a row is expanded, if not defined, row expanding is disabled.
 * The result can be a node or an array, the second case the array is treated and rendered the same way as regular rows under the row that is expanded.
 * @param {undefined | 'select' | 'expand'} rowClickMode
 * Make the whole row clickable and perform the selected function
 * @param {undefined | string | string[]} cellClassNames
 * Class names to set on all non-header cells
 * @param {undefined | string | string[]} headerClassNames
 * Class names to set on headers (but not group headers)
 * @param {undefined | string | string[]} groupHeaderClassNames
 * Class names to set on group headers
 * @param {undefined | string | string[]} expandedSubRowClassNames
 * Class names to set on expanded rows (this only applies if `renderExpandedRow` returns an array)
 * @param {undefined | 'all' | 'bottom' | 'top'} roundCorners
 * Makes the corners of the table rounded
 * @param {boolean} borders
 * Adds borders to everything
 *
 * @returns {JSX.Element}
 */
export default function GridTable(props) {
  const { data, columnHeaders, columnHeaderGroups, rowClickMode, keyMapper } =
    props

  const gridTemplateColumns = useGridTemplateColumns(props)

  const { toggleRowExpanded } = useTableContext()

  const clickHandler = useCallback(
    (_rowData, context, rowKey) => {
      if (rowClickMode === 'expand') {
        return toggleRowExpanded(rowKey)
      }

      if (rowClickMode === 'select') {
        return context.setChecked(!context.checked)
      }
    },
    [rowClickMode, toggleRowExpanded]
  )

  const mapSubRowsRecursive = useRecursiveSubRowMapper(props)

  return (
    <div className='w-full'>
      <div
        className='grid grid-flow-col auto-cols-max min-w-full w-fit'
        style={{ gridTemplateColumns }}
      >
        {columnHeaderGroups && <TableGroupHeaders {...props} />}
        {columnHeaders && <TableHeaders {...props} />}
        {data?.map((rowData, rowIdx) => {
          return (
            <TableRow
              key={keyMapper?.(rowData) || rowIdx}
              {...props}
              rowData={rowData}
              rowIdx={rowIdx}
              mapSubRowsRecursive={mapSubRowsRecursive}
              clickHandler={clickHandler}
            />
          )
        })}
      </div>
    </div>
  )
}
