import { useOptimizationContext } from 'providers/OptimizationProvider'
import { capitalize } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  getProductColor,
  NO_DATA_LABEL
} from 'screens/Optimization/utils/output'
import { createMonthObject } from 'screens/Optimization/utils'
import { formatAsMillions, formatNumber } from 'utils/number'
import { groupByArray } from '../Overview/overviewUtils'
import { sum } from 'ramda'
import { useLocalization } from 'providers/LocalizationProvider'

const SUMMARY_KEYS = ['benefit', 'revenue']

function useFinancialOverviewData(data) {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    selectedProducts,
    executionData: { start_date, end_date }
  } = useOptimizationContext()
  const { financialOverview } = data

  const filterFinancialOverview = useCallback(
    (type) => {
      return financialOverview
        .filter((item) => item.type_financial === type)
        .filter((item) =>
          !selectedProducts.length
            ? true
            : selectedProducts.includes(item.product_ui_type)
        )
    },
    [financialOverview, selectedProducts]
  )

  const financialAssetSumByProduct = useCallback((data) => {
    if (!data) {
      return {}
    }

    return data.reduce((acc, cur) => {
      if (!acc[cur.product_ui_type]) {
        acc[cur.product_ui_type] = {}
      }

      if (acc[cur.product_ui_type][cur.execution_id]) {
        acc[cur.product_ui_type][cur.execution_id] += cur.volume
      } else {
        acc[cur.product_ui_type][cur.execution_id] = cur.volume
      }

      return acc
    }, {})
  }, [])

  const { benefit: summaryItemsBenefit, revenue: summaryItemsRevenue } =
    useMemo(
      () =>
        SUMMARY_KEYS.reduce(
          (acc, summaryKey) => ({
            ...acc,
            [summaryKey]: Object.entries(
              financialAssetSumByProduct(filterFinancialOverview(summaryKey))
            ).map(([product, volume]) => ({
              title: capitalize(product),
              items: [
                {
                  description: t('optimizationScreen.periodTotal'),
                  value: formatAsMillions(volume[baseModelId] || 0),
                  valueTooltip: formatNumber(volume[baseModelId] || 0, {
                    maximumFractionDigits: 0
                  })
                },
                {
                  description: t('optimizationScreen.comparisonPeriodTotal'),
                  value: formatAsMillions(volume[comparisonID] || 0),
                  valueTooltip: formatNumber(volume[comparisonID] || 0, {
                    maximumFractionDigits: 0
                  })
                }
              ]
            }))
          }),
          {}
        ),
      [
        financialAssetSumByProduct,
        filterFinancialOverview,
        baseModelId,
        comparisonID,
        t
      ]
    )

  const summaryItemCosts = useMemo(() => {
    return groupByArray(filterFinancialOverview('cost'), 'type_cost').map((x) =>
      Math.abs(
        sum(
          Object.values(financialAssetSumByProduct(x.values)).map(
            (y) => y[baseModelId] || 0
          )
        )
      )
    )
  }, [financialAssetSumByProduct, filterFinancialOverview, baseModelId])

  const calcExecutionIdData = ({ currentData, product_ui_type, volume }) => {
    if (typeof currentData === 'object') {
      return {
        ...currentData,
        [product_ui_type]:
          currentData[product_ui_type] === NO_DATA_LABEL
            ? volume
            : currentData[product_ui_type] + volume
      }
    }
    return currentData === NO_DATA_LABEL ? volume : currentData + volume
  }

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData
      })
    },
    [start_date, end_date, baseModelId, comparisonID]
  )

  const benefitGraph = useMemo(() => {
    const result = filterFinancialOverview('benefit').reduce(
      (acc, { month, execution_id, product_ui_type, volume }) => {
        return {
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume
            })
          }
        }
      },
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )

    return Object.entries(result).map(([month, volumes]) => ({
      month,
      volumes
    }))
  }, [selectedProducts, getMonthsWithDefaultData, filterFinancialOverview])

  const revenueGraph = useMemo(() => {
    const result = filterFinancialOverview('revenue').reduce(
      (acc, { month, execution_id, product_ui_type, volume }) => {
        return {
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume
            })
          }
        }
      },
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )

    return Object.entries(result).map(([month, volumes]) => ({
      month,
      volumes
    }))
  }, [selectedProducts, getMonthsWithDefaultData, filterFinancialOverview])

  const costGraphs = useMemo(() => {
    const results = []
    const groupedByCostType = groupByArray(
      filterFinancialOverview('cost'),
      'type_cost'
    )

    for (const costType of groupedByCostType) {
      const result = costType.values.reduce(
        (acc, { month, execution_id, product_ui_type, volume }) => {
          return {
            ...acc,
            [month]: {
              ...acc[month],
              [execution_id]: calcExecutionIdData({
                currentData: acc[month][execution_id],
                product_ui_type,
                volume
              })
            }
          }
        },
        getMonthsWithDefaultData({
          executionData:
            selectedProducts.length > 0
              ? selectedProducts.reduce(
                  (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                  {}
                )
              : NO_DATA_LABEL
        })
      )
      const mappedResult = Object.entries(result).map(([month, volumes]) => ({
        month,
        volumes
      }))
      results.push({ graphTitle: costType.key, graphData: mappedResult })
    }

    return results
  }, [selectedProducts, getMonthsWithDefaultData, filterFinancialOverview])

  const costGraphsWithAbsolute = costGraphs.map((innerObj) => {
    return {
      graphTitle: innerObj.graphTitle,
      graphData: innerObj.graphData.map((obj) => {
        const keys = Object.keys(obj.volumes)
        const absoluteValues = Object.values(obj.volumes).map((e) =>
          typeof e === 'object'
            ? Object.fromEntries(
                Object.entries(e).map(([k, v]) => [k, Math.abs(v)])
              )
            : Math.abs(e)
        )
        return {
          ...obj,
          volumes: {
            [keys[0]]: absoluteValues[0] ? absoluteValues[0] : NO_DATA_LABEL,
            [keys[1]]: absoluteValues[1] ? absoluteValues[1] : NO_DATA_LABEL
          }
        }
      })
    }
  })

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const bars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths, t]
  )

  const benefitTableData = filterFinancialOverview('benefit')
  const revenueTableData = filterFinancialOverview('revenue')

  return {
    bars,
    benefitGraph,
    benefitTableData,
    revenueGraph,
    revenueTableData,
    costGraphsWithAbsolute,
    summaryItemsBenefit,
    summaryItemsRevenue,
    summaryItemCosts,
    id: baseModelId,
    comparisonID
  }
}

export default useFinancialOverviewData
