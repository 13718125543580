import { useEffect, useState } from 'react'
import { SwitcherButtons } from 'components/Inputs/Switchers'
import BarChart from 'components/Graphs/BarChart'
import { SummaryCard } from 'components/Cards'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'

const isValidNonZeroNumericValue = (v) => {
  if (!v) {
    return false
  }

  const num =
    typeof v === 'number' ? v : Number(v.toString().replace(/\D/g, ''))
  if (isNaN(num)) {
    return false
  }

  return num !== 0
}

const cardFilter = (item) => {
  if (Array.isArray(item?.items)) {
    for (const itm of item.items) {
      if (isValidNonZeroNumericValue(itm?.value)) {
        return true
      }
    }

    return false
  }

  return isValidNonZeroNumericValue(item?.value)
}

/**
 * @param {string} title    The title of the graphs and table
 * @param {Array} data     The data to be displayed
 * @param {Array} dataKeys The keys of the data to be displayed
 * @param {Array} summaryItems The items to be displayed in the summary card
 * @param {string} selectedView 'chart' | 'table' - if you want to control view mode from outside
 * @returns {JSX.Element}
 * @example
 * <TableChart title='Soy' data={[
 *  {
 *     date: '2000-01',
 *    'Base Model': 4000,
 *    'Best Estimate': 2400
 *   }
 * ]}
 *    dataKeys={[{
 *    dataKey: 'Base Model',
 *    fill: '#1950CA'
 *  },
 *  {
 *    dataKey: 'Best Estimate',
 *    fill: '#C1D5FF'
 *  ]}
 *   summaryItems={[
 *  {
 *   title: 'Base Model',
 *   value: 4000
 *  },
 *  {
 *   title: 'Best Estimate',
 *   value: 2400
 *  }
 * ]}
 * />
 */
const TableChart = ({
  title,
  data,
  bars,
  scatters,
  summaryItems,
  tooltipFormatter,
  yAxisFormatter,
  xAxisProps = { dataKey: 'date' },
  selectedView,
  tableData,
  tableProps = {},
  yAxisProps = {},
  tabs,
  onTabChanged
}) => {
  const [view, setView] = useState(selectedView ?? 'chart')

  useEffect(() => {
    selectedView && setView(selectedView)
  }, [selectedView])

  return (
    <>
      <div className='mb-6'>
        <div className='flex w-full items-center'>
          {title && (
            <h3 className='text-xl font-bold text-grey-500 text-grey-700'>
              {title}
            </h3>
          )}

          {!selectedView && (
            <div className='ml-auto'>
              <SwitcherButtons
                items={[
                  {
                    label: 'Chart',
                    value: 'chart'
                  },
                  {
                    label: 'Table',
                    value: 'table'
                  }
                ]}
                onChange={setView}
              />
            </div>
          )}
        </div>
        {tabs && (
          <TabsComponent defaultValue={tabs[0].value} onChange={onTabChanged}>
            {tabs.map(({ label, value }) => (
              <Tab key={`${label}-${value}`} label={label} value={value} />
            ))}
          </TabsComponent>
        )}
      </div>
      {view === 'chart' && (
        <>
          <div className='flex flex-wrap gap-4'>
            {summaryItems?.filter(cardFilter)?.map(({ title, ...rest }) => (
              <SummaryCard key={title} title={title} {...rest} />
            ))}
          </div>
          <BarChart
            variant='default350'
            data={data}
            bars={bars}
            scatters={scatters}
            tooltipFormatter={tooltipFormatter}
            yAxisProps={{ tickFormatter: yAxisFormatter, ...yAxisProps }}
            xAxisProps={xAxisProps}
            containerClassNames='h-[450px]'
          />
        </>
      )}
      {view === 'table' && (
        <div className='mt-4'>
          <MultiColumnsBoxTable data={tableData ?? []} {...tableProps} />
        </div>
      )}
    </>
  )
}

export default TableChart
