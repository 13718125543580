import { useCallback, useMemo, useState } from 'react'
import { SingleVolumeCard } from 'components/Cards'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import BarChart from 'components/Graphs/BarChart'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { formatNumber, sumByProperty } from 'utils/number'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const addMissingMonths = (data, monthList) => {
  return monthList.map((month) => {
    const volume =
      data.find((item) => item.month === month.month)?.volume || 'N/A'
    return {
      ...month,
      volume
    }
  })
}

const filterDataByModelAndProducts = (data, modelId, products) => {
  return data.filter((item) =>
    products.length > 0
      ? products.includes(item.product_ui_type) && item.execution_id === modelId
      : item.execution_id === modelId
  )
}

const tooltipFormatter = (label) => {
  return typeof label === 'string'
    ? label
    : formatNumber(label, { maximumFractionDigits: 0 })
}

export default function VolumeAndUtilization({ data, setSelectedSilo }) {
  const { t } = useLocalization()
  const { id } = useParams()
  const {
    executionData: { start_date, end_date },
    comparisonID
  } = useOptimizationContext()
  const monthArray = getMonthsForPeriod(start_date, end_date).map((month) => ({
    month,
    volume: 'N/A'
  }))
  const tabs = [
    {
      value: 'inbound',
      label: t('optimizationScreen.storage.siloDetails.inboundVolume')
    },
    {
      value: 'allVolumes',
      label: t('optimizationScreen.storage.siloDetails.storedVolume')
    },
    {
      value: 'outbound',
      label: t('optimizationScreen.storage.siloDetails.outboundVolume')
    },
    {
      value: 'rotationRatio',
      label: t('optimizationScreen.storage.siloDetails.rotationRatio')
    }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const { selectedProducts, selectedProductTypes } = useOptimizationContext()

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'storage_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  const getChartDataForSilos = useCallback(
    (baseData, compareData) => {
      const compareSilos = compareData

      const groupBySilo = baseData.reduce(
        (
          acc,
          {
            month,
            volume,
            rotation_ratio,
            storage_capacity,
            product_type_capacity,
            asset_capacity,
            asset_name,
            asset,
            third_party
          }
        ) => {
          const data = {
            month,
            volume: volume ?? rotation_ratio
          }

          const silo = acc.find((silo) => silo.name === asset_name)

          if (silo) {
            const monthData = silo.chartData.find(
              (siloData) => siloData.month === data.month
            )
            monthData
              ? (monthData.volume += data.volume)
              : silo.chartData.push({
                  ...data
                })
          } else {
            acc.push({
              name: asset_name,
              asset,
              chartData: [data],
              third_party,
              storage_capacity,
              asset_capacity,
              product_type_capacity
            })
          }

          return acc
        },
        []
      )

      groupBySilo.forEach((grouped) => {
        grouped.chartData = addMissingMonths(grouped.chartData, monthArray)
        grouped.near_capacity_limit =
          sumByProperty(grouped.chartData, 'volume') /
            (grouped[capacityKey] * monthArray.length) >
          0.9
        grouped.allVolume = sumByProperty(grouped.chartData, 'volume')
        grouped.compareVolume = sumByProperty(
          compareSilos.filter((silo) => silo.asset_name === grouped.name),
          compareSilos[0]?.rotation_ratio ? 'rotation_ratio' : 'volume'
        )
      })

      return groupBySilo
    },
    [monthArray, capacityKey]
  )

  const getChartData = useMemo(() => {
    const baseModelData = filterDataByModelAndProducts(
      data[selectedTab][0].data
        ? data[selectedTab].map((item) => item.data).flat()
        : data[selectedTab],
      id,
      selectedProducts
    )

    const compareModelData = filterDataByModelAndProducts(
      data[selectedTab][0].data
        ? data[selectedTab].map((item) => item.data).flat()
        : data[selectedTab],
      comparisonID,
      selectedProducts
    )

    return { data: getChartDataForSilos(baseModelData, compareModelData) }
  }, [
    data,
    selectedProducts,
    comparisonID,
    getChartDataForSilos,
    id,
    selectedTab
  ])

  return (
    <div className='mt-20'>
      <h3 className='text-xl font-bold'>
        {t('optimizationScreen.storage.volumeAndUtilization.title')}
      </h3>
      <TabsComponent value={selectedTab} onChange={setSelectedTab}>
        {tabs.map((tab) => {
          return (
            <Tab value={tab.value} label={tab.label} key={tab.value}>
              <h3 className='text-xl font-bold mb-2'>
                {t(
                  'optimizationScreen.storage.volumeAndUtilization.allAcopios'
                )}
              </h3>
              <p className='text-grey-700 mb-6'>
                {t('optimizationScreen.storage.volumeAndUtilization.volume')}
              </p>
              <div className='grid grid-cols-3 gap-3'>
                {getChartData.data.map((data) => {
                  return (
                    <SingleVolumeCard
                      key={data.name}
                      value={formatNumber(
                        tab.value === 'rotationRatio'
                          ? data.allVolume
                          : data.allVolume / kgToTons,
                        {
                          maximumFractionDigits: 0
                        }
                      )}
                      percentage={
                        +((data.compareVolume / data.allVolume) * 100).toFixed(
                          0
                        ) - 100
                      }
                      title={data.name}
                      thirdParty={data?.third_party === 1}
                      warning={
                        data.near_capacity_limit &&
                        t('optimizationScreen.nearCapacityLimit')
                      }
                      onClick={() => {
                        setSelectedSilo(data.asset)
                      }}
                    >
                      <BarChart
                        data={data.chartData}
                        variant='small'
                        bars={[{ dataKey: 'volume' }]}
                        tooltipFormatter={tooltipFormatter}
                        xAxisProps={{
                          dataKey: 'month',
                          tickFormatter: (v) => transformDateToShortMonth(v)[0]
                        }}
                        yAxisProps={{
                          tickFormatter: (v) =>
                            tab.value === 'rotationRatio'
                              ? `${v}`
                              : `${(v / kgToTons).toFixed(0)} kTons`
                        }}
                      />
                    </SingleVolumeCard>
                  )
                })}
              </div>
            </Tab>
          )
        })}
      </TabsComponent>
    </div>
  )
}
