import { useCallback } from 'react'
import { useTableContext } from './TableContext'
import { TableSubRowCell } from './TableSubRowCell'

export const useRecursiveSubRowMapper = (props) => {
  const { isRowExpanded, mappers } = useTableContext()

  const {
    keyMapper,
    renderExpandedRow,
    expandArrowPosition,
    borders,
    expandedSubRowClassNames,
    cellClassNames
  } = props

  const mapSubRowsRecursive = useCallback(
    ({ rows, rowKey, subRowClickHandler, rowData, context }, level = 2) =>
      rows.map((subItem, index) => {
        const subRowKey = `${rowKey}-${keyMapper?.(subItem) || index}`
        const hasSubRows =
          'subRows' in subItem && Array.isArray(subItem.subRows)
        const subRowsExpanded = hasSubRows && isRowExpanded(subRowKey)

        return [
          ...mappers.map((mapper, idx) => {
            let mapperClassName = typeof mapper === 'object' && mapper.className
            if (typeof mapperClassName === 'function') {
              mapperClassName = mapperClassName(subItem)
            }

            return (
              <TableSubRowCell
                key={idx}
                bodyComponent={
                  (typeof mapper === 'object' && mapper.content) || mapper
                }
                level={level}
                borders={borders}
                cellClassNames={cellClassNames}
                columnIdx={idx}
                context={context}
                expandArrowPosition={expandArrowPosition}
                expandedSubRowClassNames={expandedSubRowClassNames}
                hasSubRows={hasSubRows}
                mapperClassName={mapperClassName}
                onClick={
                  subRowClickHandler
                    ? () => subRowClickHandler(rowData, subItem)
                    : undefined
                }
                renderExpandedRow={renderExpandedRow}
                rowData={subItem}
                rowIdx={index}
                rowKey={subRowKey}
              />
            )
          }),
          ...(hasSubRows && subRowsExpanded
            ? mapSubRowsRecursive(
                {
                  rows: subItem.subRows,
                  subRowKey,
                  subRowClickHandler,
                  rowData,
                  context
                },
                level + 1
              )
            : [])
        ]
      }),
    [
      keyMapper,
      renderExpandedRow,
      expandArrowPosition,
      borders,
      expandedSubRowClassNames,
      cellClassNames,
      mappers,
      isRowExpanded
    ]
  )

  return mapSubRowsRecursive
}
