import Switcher from 'components/Inputs/Switchers/Switcher'
import { useController } from 'react-hook-form'

const ControlledSwitcher = ({ controllerProps, onChange, disabled, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps)

  return (
    <Switcher
      {...props}
      checked={field.value}
      disabled={disabled}
      onChange={(value) => {
        field.onChange(value)
        onChange && onChange(value)
      }}
      error={error && error.message}
    />
  )
}

export default ControlledSwitcher
