import { ScenariosProvider } from 'providers/ScenariosProvider'
import ErrorBoundary from 'components/ErrorBoundary'
import LayoutWithSidebar from 'components/Container/LayoutWithSidebar'
import ScenariosSidebar from './Sidebar'
import ScenariosMain from './Main'
import { Suspense } from 'react'
import Loader from 'components/Feedback/Loader/Loader'
import { useLocalization } from 'providers/LocalizationProvider'

const Scenario = () => {
  const { t } = useLocalization()
  return (
    <ErrorBoundary fallback={<div>{t('scenarios.error')}</div>}>
      <Suspense fallback={<Loader />}>
        <ScenariosProvider>
          <LayoutWithSidebar
            main={<ScenariosMain />}
            sidebar={<ScenariosSidebar variant='wide' />}
            variant='wide'
          />
        </ScenariosProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export default Scenario
