import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { EllipseFull } from 'components/Icons'
import { useLocalization } from 'providers/LocalizationProvider'

export const LineBarChart = ({
  data,
  xAxisDataKey,
  containerClass,
  lineDataKey,
  barDataKey,
  legendGroup
}) => {
  const { t } = useLocalization()
  return (
    <div className={['w-full h-[450px] mt-10', containerClass].join(' ')}>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <Legend
            align='left'
            content={() => (
              <ul className='flex gap-3'>
                {legendGroup &&
                  legendGroup.map((legend) => (
                    <li
                      key={legend.color}
                      className='flex items-center text-sm'
                    >
                      <EllipseFull
                        fill={legend.color}
                        className='my-auto mr-2 inline-block align-middle'
                      />
                      {t(legend.description)}
                    </li>
                  ))}
              </ul>
            )}
          />
          <XAxis className='capitalize' dataKey={xAxisDataKey} />
          <YAxis unit='kTon' />
          <Tooltip />
          <Bar dataKey={barDataKey} barSize={20} fill='#073C8B' />
          <Line dataKey={lineDataKey} type='monotone' stroke='#C1D5FF' />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
