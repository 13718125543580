import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useLocalization } from './LocalizationProvider'

const productsOrder = ['soybean', 'soybean at', 'corn', 'wheat', 'sunflower', 'soybean meal', 'soybean oil', 'sunflower oil', 'sunflower meal', 'lecithin', 'biodiesel']

const initialState = {
  products: [],
  outputs: [], // outputs state and inputs
  page: 0, //  state variables
  inprogress: true, // check if optimization is running
  showDownloadModal: false, // show download modal
  showInputModal: false, // show input modal,
  conversion: 1, // conversion rate for currency that affects all the outputs values
  overview: [],
  cifOrigination: [],
  fobOrigination: [],
  storage: [],
  transportation: [],
  crushing: [],
  internalMarket: [],
  exports: [],
  comparisonID: null
}

const OptimizationContext = createContext(initialState)
OptimizationContext.displayName = 'OptimizationContext'

export const OptimizationProvider = ({ children }) => {
  const { countryCode } = useLocalization()
  const params = useParams()
  const { data: rawProducts } = useSWR(`/execution/${params.id}/products`)
  const { data: executionData } = useSWR(`/execution/${params.id}`)

  const orderedProducts = countryCode === 'ARG' ? rawProducts.sort(
    (a, b) => productsOrder.indexOf(a.product_ui_type.toLowerCase()) - productsOrder.indexOf(b.product_ui_type.toLowerCase())
  ) : rawProducts

  const [state, setState] = useState({
    ...initialState,
    products: [...new Set(orderedProducts.map((i) => i.product_ui_type))].map(
      (item) => ({
        name: item,
        checked: false
      })
    )
  })

  const selectedProducts = useMemo(
    () => state.products.filter((p) => p.checked).map((p) => p.name),
    [state.products]
  )

  const selectedProductTypes = useMemo(() => {
    const filteredRawProducts = rawProducts.filter((p) =>
      selectedProducts.includes(p.product_ui_type)
    )
    return [...new Set(filteredRawProducts.map((p) => p.product_type))]
  }, [rawProducts, selectedProducts])

  const availableSubProducts = useMemo(() => {
    return rawProducts
      .filter((p) => selectedProducts.includes(p.product_ui_type))
      .map((p) => ({ value: p.product, label: p.product_name ?? p.product }))
  }, [selectedProducts, rawProducts])

  const checkProduct = useCallback(
    (payload) => {
      const products = state.products.map((product) => ({
        name: product.name,
        checked:
          product.name === payload.name ? payload.checked : product.checked
      }))
      setState((prev) => ({ ...prev, products }))
    },
    [state.products]
  )

  const setProducts = (products) => {
    setState((prev) => ({ ...prev, products }))
  }

  const setOutputs = (outputs) => {
    setState((prev) => ({ ...prev, outputs }))
  }

  const setPage = (page) => {
    setState((prev) => ({ ...prev, page }))
  }

  const setInprogress = (inprogress) => {
    setState((prev) => ({ ...prev, inprogress }))
  }

  const setShowDownloadModal = (showDownloadModal) => {
    setState((prev) => ({ ...prev, showDownloadModal }))
  }

  const setShowInputModal = (showInputModal) => {
    setState((prev) => ({ ...prev, showInputModal }))
  }

  const setConversion = (conversion) => {
    setState((prev) => ({ ...prev, conversion }))
  }

  const setOverview = (overview) => {
    setState((prev) => ({ ...prev, overview }))
  }

  const setCifOrigination = (cifOrigination) => {
    setState((prev) => ({ ...prev, cifOrigination }))
  }

  const setFobOrigination = (fobOrigination) => {
    setState((prev) => ({ ...prev, fobOrigination }))
  }

  const setStorage = (storage) => {
    setState((prev) => ({ ...prev, storage }))
  }

  const setTransportation = (transportation) => {
    setState((prev) => ({ ...prev, transportation }))
  }

  const setCrushing = (crushing) => {
    setState((prev) => ({ ...prev, crushing }))
  }

  const setInternalMarket = (internalMarket) => {
    setState((prev) => ({ ...prev, internalMarket }))
  }

  const setExports = (exports) => {
    setState((prev) => ({ ...prev, exports }))
  }

  const setComparisonID = (comparisonID) => {
    setState((prev) => ({ ...prev, comparisonID }))
  }

  const value = useMemo(
    () => ({
      ...state,
      setProducts,
      checkProduct,
      setOutputs,
      setPage,
      setInprogress,
      setShowDownloadModal,
      setShowInputModal,
      setConversion,
      setOverview,
      setCifOrigination,
      setFobOrigination,
      setStorage,
      setTransportation,
      setCrushing,
      setInternalMarket,
      setExports,
      executionData,
      selectedProducts,
      setComparisonID,
      availableSubProducts,
      rawProducts,
      selectedProductTypes
    }),
    [
      state,
      selectedProducts,
      checkProduct,
      executionData,
      rawProducts,
      availableSubProducts,
      selectedProductTypes
    ]
  )

  return (
    <OptimizationContext.Provider value={value}>
      {children}
    </OptimizationContext.Provider>
  )
}

export const useOptimizationContext = () => {
  const context = useContext(OptimizationContext)
  if (!context) {
    throw new Error(
      'useOptimization must be used within a OptimizationProvider'
    )
  }
  return context
}
