const GraphSkeleton = () => {
  return (
    <div className='border relative border-gray-200 rounded-lg shadow animate-pulse w-full h-full'>
      <div className='p-4 absolute inset-x-0 bottom-0'>
        <div className='flex items-baseline mt-4 space-x-6'>
          <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
          <div className='w-full h-20 bg-gray-200 rounded-t-lg'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
          <div className='w-full h-32 bg-gray-200 rounded-t-lg'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-24'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-12'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-32'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-24'></div>
          <div className='w-full bg-gray-200 rounded-t-lg h-28'></div>
        </div>
        <div className='flex mt-2.5 space-x-3 inset-x-0 bottom-0'>
          <div className='w-2 h-2 bg-gray-200 rounded-full'></div>
          <div className='w-16 h-2 bg-gray-200 rounded-full'></div>
          <div className='w-2 h-2 bg-gray-200 rounded-full'></div>
          <div className='w-16 h-2 bg-gray-200 rounded-full '></div>
        </div>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}
export default GraphSkeleton
