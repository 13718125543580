import { useEffect, useMemo, useState } from 'react'
import { PencilIcon } from '@heroicons/react/24/outline'
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { put } from 'utils/request'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import classNames from 'classnames'
import CurrencySelector from 'components/Inputs/CurrencySelector'
import { formatter, capitalize } from 'utils/string'
import Checkbox from 'components/Inputs/Checkbox'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import Select from 'components/Inputs/Select'
import { getProductColor } from '../utils/output'
import { useLocalization } from 'providers/LocalizationProvider'
import Button from 'components/Inputs/Button'
import { EllipseFull } from 'components/Icons'

const execTypes = {
  optimized: {
    langKey: 'optimized',
    colorClass: 'bg-grey-100'
  },
  advanced: {
    langKey: 'advanced',
    colorClass: 'bg-grey-100'
  },
  baseline: {
    langKey: 'baseline',
    colorClass: 'bg-grey-100'
  },
  'what-if': {
    langKey: 'whatif',
    colorClass: 'bg-grey-100'
  }
}

const UpdateInput = ({ optimization }) => {
  const [optimizationName, setOptimizationName] = useState(
    () => optimization.name
  )
  const [edit, setEdit] = useState(false)
  const { t } = useLocalization()

  useEffect(() => {
    setOptimizationName(optimization.name)
  }, [optimization])

  const { execType, execTypeBadgeColor } = useMemo(() => {
    const type = (
      optimization && JSON.parse(optimization.data)?.type
    )?.toLowerCase()

    if (!type) {
      return undefined
    }

    for (const [et, props] of Object.entries(execTypes)) {
      if (type.includes(et)) {
        return {
          execType: t(`optimizationScreen.header.runTypes.${props.langKey}`),
          execTypeBadgeColor: props.colorClass
        }
      }
    }

    return type
  }, [optimization, t])

  return (
    <div className='flex items-center'>
      {' '}
      {!edit ? (
        <button className='flex items-center ' onClick={() => setEdit(true)}>
          <span className='mr-2 text-xl font-bold w-max'>
            {optimizationName}
          </span>
          <PencilIcon className='w-4 h-4 mr-2 text-gray-800' />
        </button>
      ) : (
        <>
          <input
            value={optimizationName}
            onChange={(e) => setOptimizationName(e.target.value)}
            type='text'
            className='text-sm leading-7 text-gray-800 border-gray-400 ring-gray-500 focus:ring-gray-200 rounded mr-2 w-80'
          />
          <ArrowUpTrayIcon
            className='w-6 h-6 cursor-pointer pr-2'
            onClick={() => {
              put(`/execution/${optimization.id}`, {
                name: optimizationName
              })
                .then(() => {
                  setEdit(false)
                })
                .catch(() => {
                  setEdit(false)
                })
            }}
          />
        </>
      )}
      <div className='flex items-center'>
        <div
          className={classNames('flex-1 rounded-lg bg-gray-300 w-3 h-3 mr-1', {
            'bg-green-500': optimization.status === 'Success',
            'bg-red-500': optimization.status === 'Failed'
          })}
        />
        <span className='text-sm'>{formatter(optimization.status || '')}</span>
        {execType && (
          <span
            className={classNames(
              `rounded-sm px-3 py-1 ml-2 w-max`,
              execTypeBadgeColor
            )}
          >
            {capitalize(execType)}
          </span>
        )}
      </div>
    </div>
  )
}

/**
 *
 * @param {*} props
 * @returns {JSX.Element}
 */
export default function Heading({ optimization }) {
  const { id } = useParams()
  const maxSelectedProducts = 3
  const {
    setShowInputModal,
    setShowDownloadModal,
    products,
    checkProduct,
    comparisonID,
    setComparisonID
  } = useOptimizationContext()
  const { t } = useLocalization()

  const { data: comparableData } = useSWR(`/execution/${id}/comparable`, {
    enabled: !!id
  })

  useEffect(() => {
    if (
      comparableData &&
      comparableData.length &&
      !comparableData.some((x) => x.id === comparisonID)
    ) {
      setComparisonID(comparableData[0].id)
    }
  }, [comparableData, comparisonID, setComparisonID])

  return (
    <div className='inline-flex flex-col space-y-5 items-start justify-start w-full shadow-b-2xl bg-white border-b px-8 py-4'>
      <div className='w-full flex justify-between mx-auto'>
        <UpdateInput optimization={optimization} />
        <div className='flex items-start justify-end w-full gap-2'>
          <CurrencySelector
            onChange={(newCurrency) => console.log(newCurrency, 'newCurrency')}
            disabled
          />
          <Button
            onClick={() => {
              setShowInputModal(true)
            }}
          >
            <ArrowDownTrayIcon className='w-5 h-5' />
            {t('optimizationScreen.header.downloadInputData')}
          </Button>
          <Button
            onClick={() => {
              setShowDownloadModal(true)
            }}
          >
            <ArrowDownTrayIcon className='w-5 h-5' />
            {t('optimizationScreen.header.downloadResults')}
          </Button>
        </div>
      </div>

      <div className='flex w-full justify-between mx-auto'>
        <section className='w-[384px] flex-col mr-8'>
          <span className='block text-sm font-medium leading-6 text-gray-900'>
            {t('optimizationScreen.header.comparisonFile')}
          </span>
          <Select
            onValueChange={setComparisonID}
            value={comparisonID}
            options={comparableData.map(({ id, name }) => ({
              label: name,
              value: id
            }))}
            placeholder={'Nothing to compare to'}
            disabled={comparableData.length === 0}
            fullWidth
            dynamicHeight
            className='mt-2'
          />
        </section>
        <section className='w-full'>
          <h4>{t('optimizationScreen.header.products')}</h4>
          {products.length === 0 && (
            <p className='flex items-center my-2 h-9 text-sm text-grey-400'>
              {t('optimizationScreen.header.notFound')}
            </p>
          )}
          <div className='flex my-2 truncate flex-wrap gap-2'>
            {products.map((item) => (
              <label
                key={item.name}
                className='flex flex-row items-center border px-3 rounded-md py-1.5 justify-center gap-1'
              >
                <Checkbox
                  className='flex justify-center mr-1'
                  isChecked={item.checked}
                  disabled={
                    products.filter((product) => product.checked).length ===
                      maxSelectedProducts && !item.checked
                  }
                  onChange={(isChecked) => {
                    checkProduct({ name: item.name, checked: isChecked })
                  }}
                />
                <EllipseFull
                  fill={getProductColor(true, item.name)}
                  className='h-2 w-2'
                />
                <span
                  className={
                    products.filter((product) => product.checked).length ===
                      maxSelectedProducts && !item.checked
                      ? 'text-gray-400'
                      : ''
                  }
                >
                  {capitalize(item.name)}
                </span>
              </label>
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}
