import DesignSystemSegment from './DesignSystemSegment'

const icons = Object.entries(require('components/Icons'))
  .filter(([, v]) => typeof v === 'function')
  .map(([k, v]) => ({
    name: k,
    Component: v
  }))

export default function CustomIcons() {
  return (
    <DesignSystemSegment title='Custom icons'>
      <div className='flex items-start mt-10 max-w-7xl mx-auto flex-row flex-wrap gap-2'>
        {icons.map(({ name, Component }) => (
          <div
            key={name}
            className='flex flex-row gap-1 justify-center items-center border border-grey-300 bg-grey-100 rounded-md px-4 py-3'
          >
            <span>{name}</span>
            <div className='h-4 w-4 ml-2'>
              <Component className='h-4 w-4' />
            </div>
          </div>
        ))}
      </div>
    </DesignSystemSegment>
  )
}
