import { last } from 'ramda'
import HeatMapTable from './HeatMapTable'

/**
 * @param {object} data - The data to display in the table
 * @param {array} colors - The colors to use in the table
 * @returns {JSX.Element}
 * @example
 * <MultiColumnsBoxTable
 * 	data={{
 *   'Jan': {
 * 		'BM': 4000,
 * 		'BE': 2400
 * 	 },
 * 	 'Feb': {
 * 		'BM': 3000,
 * 		'BE': 1398
 * }}
 * />
 */
function MultiColumnsBoxTable({
  data,
  titleColumnWidth = 'auto',
  expandable = false,
  showLegend = true,
  showControls = true,
  showTitles = false,
  referenceProps = {},
  comparedProps = {},
  referencePropKey = undefined,
  groupLabelFormatter,
  columnValueFormatter,
  rowTitleFormatter
}) {
  const groups = Object.keys(data[0]?.data || [])
  const allProps = Object.keys(data[0]?.data?.[groups[0]] || {})
  const refProp =
    referencePropKey ?? referenceProps?.objKey ?? last(allProps) ?? ''
  const showTitleColumn = data.some((i) => !!i.title)

  if (data[0]?.data) {
    return (
      <HeatMapTable
        data={data}
        groups={groups}
        comparedProps={allProps
          .filter((x) => x !== refProp)
          .map((x) => ({ objKey: x, ...comparedProps }))}
        defaultColoringMode='keys'
        refProp={{ objKey: refProp, ...referenceProps }}
        showControls={showControls}
        expandable={expandable}
        showLegend={showLegend}
        rowTitles={showTitles ? showTitles : showTitleColumn}
        groupLabelFormatter={groupLabelFormatter}
        columnValueFormatter={columnValueFormatter}
        rowTitleFormatter={rowTitleFormatter}
        columnSizes={showTitles ? [titleColumnWidth] : undefined}
      />
    )
  }
}
export default MultiColumnsBoxTable
