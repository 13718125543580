import { useMemo, useCallback, useState } from 'react'
import TableActions from './TableActions'
import { get, put } from 'utils/request'
import { useNavigate } from 'react-router-dom'
import {
  stringMapperFactory,
  propertyMapperFactory
} from 'components/Tables/GridTable'
import useSWR from 'swr'
import EditButton from './EditButton'
import AssetsConfigurationsTable from './AssetsConfigurationsTable'
import { useCity } from 'providers/CityProvider'
import useSWRMutation from 'swr/mutation'
import Status from './Status'
import { useLocalization } from 'providers/LocalizationProvider'

const Silos = () => {
  const navigate = useNavigate()
  const { t, countryCode } = useLocalization()
  const [searchTerm, setSearchTerm] = useState('')
  const { cities } = useCity()

  const argKey = useMemo(
    () => (countryCode === 'ARG' ? 'arg.' : ''),
    [countryCode]
  )

  const productsFormatter = useCallback(
    (products) =>
      products
        ?.map((p) => t(`products.${p.trim().replaceAll(/\s+/g, '_')}`))
        ?.join(', '),
    [t]
  )
  const ownershipFormatter = useCallback(
    (ownership) =>
      t(`assetConfigurationScreen.silos.ownership.${ownership.trim()}`),
    [t]
  )

  const TABLE_HEADERS = [
    t(`assetConfigurationScreen.silos.${argKey}tableHeaders.siloID`),
    t(`assetConfigurationScreen.silos.tableHeaders.status`),
    t(`assetConfigurationScreen.silos.${argKey}tableHeaders.mesoregion`),
    t(`assetConfigurationScreen.silos.tableHeaders.ownership`),
    t(`assetConfigurationScreen.silos.tableHeaders.city`),
    t(`assetConfigurationScreen.silos.tableHeaders.products`),
    null
  ]

  const mappers = useMemo(
    () => [
      stringMapperFactory('silo_id'),
      Status,
      stringMapperFactory('city.meso'),
      propertyMapperFactory('ownership', ownershipFormatter),
      stringMapperFactory('city.city'),
      propertyMapperFactory('products', productsFormatter),
      {
        className: 'justify-end pr-4',
        content: EditButton
      }
    ],
    [productsFormatter, ownershipFormatter]
  )

  const { data, mutate } = useSWR('/silo', get)

  const { trigger } = useSWRMutation(
    `/silo`,
    async (url, { arg: { include, id } }) => {
      await put(`${url}/${id}`, { include })
    }
  )

  const handleOnEdit = useCallback(
    (item) => {
      navigate(`/assets/silos/${item.id}`)
    },
    [navigate]
  )

  const silosWithCities = useMemo(() => {
    return data.map((silo) => ({
      ...silo,
      city: cities.find((city) => city.id === silo.city_id)
    }))
  }, [data, cities])

  const filteredSilos = useMemo(
    () =>
      silosWithCities
        ?.filter(({ silo_id, include, city, ownership, products }) =>
          [
            silo_id,
            include ? 'active' : 'inactive',
            city.meso,
            ownership,
            city.city,
            productsFormatter(products)
          ]
            .join(' ')
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        )
        .sort((a, b) =>
          a.silo_id?.toLocaleLowerCase() > b.silo_id?.toLocaleLowerCase()
            ? 1
            : -1
        ),
    [silosWithCities, searchTerm, productsFormatter]
  )

  return (
    <>
      <TableActions
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        placeholder={t(
          `assetConfigurationScreen.silos.${argKey}searchbarPlaceholder`
        )}
        registerButtonLabel={t(
          `assetConfigurationScreen.silos.${argKey}registerButtonLabel`
        )}
        onRegisterClick={() => navigate('/assets/silos')}
      />
      <AssetsConfigurationsTable
        data={filteredSilos}
        columnMappers={mappers}
        columnHeaders={TABLE_HEADERS}
        rowContext={{ data, mutate, trigger, handleOnEdit }}
        columnSizes={`20rem auto auto auto auto auto ${
          countryCode === 'BR' ? 'auto' : ''
        }`}
        hiddenColumn={
          countryCode === 'ARG' ? { column: 'Products', key: 'products' } : null
        }
      />
    </>
  )
}

export default Silos
