import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { EllipseFull } from 'components/Icons'
import { useLocalization } from 'providers/LocalizationProvider'
import { useState } from 'react'
import Switcher from '../../Inputs/Switcher'

export const MinMaxChart = ({
  data,
  containerClass,
  XAxisDataKey,
  minMaxLegendGroup,
  barLegendGroup,
  barGroup,
  minMaxGroup,
  minMaxToogleText
}) => {
  const { t } = useLocalization()
  const [isMinMaxHidden, setIsMinMaxHidden] = useState(false)

  const toogleMinMax = () => {
    setIsMinMaxHidden((prevValue) => !prevValue)
  }

  return (
    <div className={['w-full h-[450px] mt-10', containerClass].join(' ')}>
      {minMaxLegendGroup && (
        <div className='w-full flex justify-end mb-1'>
          <div className='w-full h-6 flex justify-end items-center text-sm'>
            <div className='mr-1.5'>{t(minMaxToogleText)}</div>
            <Switcher checked={isMinMaxHidden} onChange={toogleMinMax} />
          </div>
          <ul className='flex gap-2.5 px-6'>
            {minMaxLegendGroup &&
              minMaxLegendGroup.map(({ description, color }) => (
                <li key={description} className='flex items-center text-sm'>
                  <EllipseFull
                    fill={color}
                    className='my-auto mr-2 inline-block align-middle'
                  />
                  {t(description)}
                </li>
              ))}
          </ul>
        </div>
      )}
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          width={500}
          height={300}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
        >
          <CartesianGrid />
          <XAxis dataKey={XAxisDataKey} className='capitalize' />
          <YAxis unit={'kTon'} />
          <Tooltip />
          <Legend
            align='left'
            content={() => (
              <ul className='flex gap-3'>
                {barLegendGroup &&
                  barLegendGroup.map((legend) => (
                    <li
                      key={`${legend.description}`}
                      className='flex items-center text-sm'
                    >
                      <EllipseFull
                        fill={legend.color}
                        className='my-auto mr-2 inline-block align-middle'
                      />
                      {t(legend.description)}
                    </li>
                  ))}
              </ul>
            )}
          />
          <ReferenceLine y={0} stroke='#000' strokeWidth={2} isFront />
          {barGroup.map(({ dataKey, color, name }, index) => (
            <Bar
              key={`${index}-${dataKey}`}
              dataKey={dataKey}
              name={t(name)}
              fill={color}
              animationDuration={1000}
            />
          ))}
          {minMaxGroup &&
            minMaxGroup.map(({ dataKey, color, name }, index) => (
              <Line
                key={`${index}-${dataKey}`}
                dataKey={dataKey}
                name={t(name)}
                hide={isMinMaxHidden}
                fill={color}
                stroke={color}
                strokeWidth={2}
                strokeDasharray='3 3'
                dot={false}
              />
            ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
