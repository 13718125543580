import GridTable from 'components/Tables/GridTable'

const AssetsConfigurationsTable = (props) => {

  if (props.hiddenColumn) {
    const columnIndex = props.columnHeaders.indexOf(props.hiddenColumn.column)

    if (columnIndex >= 0) {
      props.columnHeaders.splice(columnIndex, 1);

      if (props.columnHeaders.length !== props.columnMappers.length) {
        props.columnMappers.splice(columnIndex, 1);
      }

      props.data.map(item => delete item[props.columnHeaders.key])
    }
  }

  return <GridTable
    keyMapper={(item) => item.id}
    borders
    stickyHeaders
    roundCorners='all'
    cellClassNames='flex pl-4 items-center bg-white py-4 border-grey-200 text-grey-600'
    headerClassNames='text-grey-700 py-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-grey-100'
    {...props}
  />
}

export default AssetsConfigurationsTable
