import * as T from 'components/Typography'
import { DocumentsIllustration } from 'components/Icons'

const defaultDescription = (page) =>
  page
    ? `Try another product type to view ${page} details.`
    : `Try another product type to view details.`

function NoData({
  page,
  title = 'No available data for the selected product.',
  description = defaultDescription
}) {
  return (
    <div className='inline-flex w-full items-center justify-start flex-col pt-24 pb-24'>
      <div className='flex flex-col items-center justify-start'>
        <DocumentsIllustration />
        <div className='flex flex-col space-y-2 items-center justify-start mt-5'>
          <T.sub text={title} />
          <T.text text={description(page)} />
        </div>
      </div>
    </div>
  )
}
export default NoData
