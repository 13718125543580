import classNames from 'classnames'
import { Switch } from '@headlessui/react'

const Switcher = ({ onChange, checked, disabled }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        [
          'relative inline-flex h-4 w-8 flex-shrink-0 items-center justify-start',
          'cursor-pointer rounded-full border-2 border-transparent',
          'transition-colors duration-200 ease-in-out',
          'focus:outline-none focus:ring-2 blue:ring-blue-600 focus:ring-offset-2'
        ],
        {
          'bg-blue-400': checked,
          'bg-gray-100': !checked
        }
      )}
    >
      <span
        aria-hidden='true'
        className={classNames(
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out border',
          {
            'translate-x-3': checked,
            'translate-x-0': !checked
          }
        )}
      />
    </Switch>
  )
}

export default Switcher
