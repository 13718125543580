import { useLocalization } from 'providers/LocalizationProvider'
import Alert from './Alert'

export default function TranslatedAlert({
  rootKey,
  message,
  defaultMessageKey = 'statusMessages.genericError',
  ...props
}) {
  const { t } = useLocalization()
  return (
    <Alert
      {...props}
      message={t(
        message
          ? rootKey
            ? `${rootKey}.${message}`
            : message
          : defaultMessageKey
      )}
    />
  )
}
