import { VariationVolumeCard } from 'components/Cards'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

const VariationSection = ({ cards }) => {
  const { t } = useLocalization()

  if (cards.length === 0) {
    return <NoData page={t('optimizationScreen.pageNames.export')} />
  }

  return (
    <div className='flex flex-row flex-wrap gap-4'>
      {cards.map(({ aggregatedAsset, trend, baseModel, comparison }) => {
        return (
          <div key={aggregatedAsset} className='flex flex-wrap w-60'>
            <VariationVolumeCard
              className='shadow-md'
              title={aggregatedAsset}
              percentage={Math.round(trend * 100)}
              BE_value={Math.round(comparison)}
              BM_value={Math.round(baseModel)}
              direction={
                Math.abs(trend) < 0.001
                  ? 'nochange'
                  : trend >= 0
                  ? 'up'
                  : 'down'
              }
            />
          </div>
        )
      })}
    </div>
  )
}

export default VariationSection
