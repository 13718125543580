import DateComponent from './DateComponent'
import dayjs from 'dayjs'

const CalendarComponent = ({ data, months }) => {
  return (
    <div className='flex flex-col'>
      <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
          <div className='overflow-hidden'>
            <table className='border bg-white min-w-full text-left text-sm font-light'>
              <thead className='border-b bg-neutral-50 font-medium dark:text-neutral-800'>
                <tr>
                  <th scope='col' className='w-72 px-3 py-4 border-r'>
                    Crushing Plant
                  </th>
                  {months?.map(({ month }, index) => (
                    // min width of months must match MONTH_WIDTH_IN_PX, tailwind can't handle variables properly
                    <th
                      key={index}
                      scope='col'
                      className={`min-w-[128px] px-6 py-4 text-center`}
                    >
                      {dayjs(month).format('MMM YYYY')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((info, index) => {
                  return (
                    <tr className='border-b white' key={index}>
                      <td className='px-3 py-4 border-r'>{info?.name}</td>
                      {months?.map(({ month }, index) => {
                        return (
                          <DateComponent
                            key={info?.name + index}
                            data={{ month, index }}
                            info={[
                              {
                                ...info.bm,
                                color: 'bg-blue-500',
                                textColor: 'text-white',
                                type: 'bm'
                              },
                              {
                                ...info.cmp,
                                color: 'bg-blue-200',
                                textColor: 'text-blue-700',
                                type: 'cmp'
                              }
                            ]}
                          />
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarComponent
