import NewOptimization from '../NewExecution/NewOptimization'
import ErrorBoundary from 'components/ErrorBoundary'
import { useLocalization } from 'providers/LocalizationProvider'

function NewOptWrapper() {
  const { t } = useLocalization()
  return (
    <ErrorBoundary
      fallback={<div>{t('newOptimizationScreen.errorBoundary')}</div>}
    >
      <NewOptimization />
    </ErrorBoundary>
  )
}

export default NewOptWrapper
