import { useState } from 'react'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

const VISIBLE_ITEMS = 3

/**
 *
 * @param  {string} title           The title of the slider
 * @param { JSX.Element[] } items   An array of react functional components
 * @returns {JSX.Element}
 */
function Slider({ title, items }) {
  const [firstIndex, setCurrentFirstIndex] = useState(0)

  return (
    <section className='flex flex-col w-full'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center justify-center mt-8'>
          <h3 className='text-xl font-bold text-grey-700'>{title}</h3>
        </div>
        <div className='mr-24 mt-12 space-x-2 flex items-center'>
          <button
            onClick={(e) => {
              e.preventDefault()
              if (firstIndex === 0) return
              setCurrentFirstIndex(firstIndex - 1)
            }}
            className='text-grey-400 hover:text-grey-700 disabled:cursor-not-allowed disabled:text-grey-300'
            disabled={firstIndex === 0}
          >
            <ArrowLeftIcon className='w-6 h-6' size={2} />
          </button>
          <p className='text-xs text-grey-400'>
            {firstIndex + 1} / {items.length - 2}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault()
              if (firstIndex === items.length - VISIBLE_ITEMS) return
              setCurrentFirstIndex(firstIndex + 1)
            }}
            className='text-grey-400 hover:text-grey-700 disabled:cursor-not-allowed disabled:text-grey-300'
            disabled={firstIndex === items.length - VISIBLE_ITEMS}
          >
            <ArrowRightIcon className='w-6 h-6' size={2} />
          </button>
        </div>
      </div>
      <div className='overflow-hidden w-full'>
        <ul className='grid grid-cols-3 mt-2 gap-4 mb-6'>
          {items.map((component, index) => {
            return (
              <li
                className={classNames('w-full', {
                  hidden:
                    index > firstIndex + (VISIBLE_ITEMS - 1) ||
                    index < firstIndex
                })}
                key={index + firstIndex}
              >
                {component}
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Slider
