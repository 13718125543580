import Button from 'components/Inputs/Button'
import { useScenariosContext } from 'providers/ScenariosProvider'
import { useMemo } from 'react'

const Footer = () => {
  const { runNewScenario, state } = useScenariosContext()

  const enabled = useMemo(() => {
    return Object.keys(state).length !== 0;
  }, [state])

  return (
    <Button
      variant='filled'
      className='w-48'
      onClick={runNewScenario}
      disabled={!enabled}
    >
      Run Optimization
    </Button>
  )
}

export default Footer