import { useState } from 'react'
import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useFieldArray } from 'react-hook-form'
import SearchInput from './SearchInput'
import Checkbox from './Checkbox'

//TODO: Update search input to use components/Input as a base, update components/Input to be able to display an icon
//TODO: Use chip component to display active elements
/**
   * Params types
   * @param {Object} options- The source data, in which each element is an object.
   * options= [
{
    label: 'Silo 01',
    value: 'silo1',
    checked: false
  },
   * ]
   * @param {string} corridor - Corridor port of the silos to be exhibited
   */

const ControlledSearchCheckbox = ({
  controllerProps,
  options,
  className,
  placeholder,
  error,
  label
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const { fields, append, remove } = useFieldArray(controllerProps)

  return (
    <div className={className}>
      <div className='pb-2'>
        <SearchInput
          placeholder={placeholder || 'Search'}
          onChange={setSearchTerm}
          value={searchTerm}
        />
      </div>
      <div className='border rounded-md bg-white'>
        {label && <h5 className='text-xl p-4'>{label}</h5>}
        <ul
          className={classNames(
            'w-full flex flex-col max-h-60 overflow-y-scroll px-4 pb-4'
          )}
        >
          {options
            .filter((item) =>
              item.label
                .toLocaleLowerCase()
                .includes(String(searchTerm).toLocaleLowerCase())
            )
            .map((item) => (
              <li key={item.value}>
                <Checkbox
                  label={item.label}
                  className='mb-1'
                  disabled={item.disabled}
                  isChecked={fields.some((field) => field.value === item.value)}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      append(item)
                    } else {
                      const index = fields.findIndex(
                        (field) => field.value === item.value
                      )
                      remove(index)
                    }
                  }}
                />
              </li>
            ))}
        </ul>
      </div>
      {fields.length > 0 && (
        <div className='flex flex-row flex-wrap gap-y-2 gap-x-2 mt-5'>
          {fields.map((item, index) => (
            <span
              key={item.value}
              className='bg-blue-200 text-blue-400 font-bold rounded-md px-2 py-1 flex flex-row w-fit cursor-default'
            >
              {item.label}
              <XMarkIcon
                strokeWidth='3.2'
                className='block my-auto w-3.5 ml-2 cursor-pointer'
                aria-hidden='true'
                onClick={() => remove(index)}
              />
            </span>
          ))}
        </div>
      )}
      {error?.root?.message && (
        <div className='text-error-400 mt-2'>{error.root.message}</div>
      )}
    </div>
  )
}

export default ControlledSearchCheckbox
