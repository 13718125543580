import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { useDatasetDownload } from 'hooks/useDatasetDownload'
import { useLocalization } from 'providers/LocalizationProvider'
import { useScenariosContext } from 'providers/ScenariosProvider'
import { useToast } from 'providers/ToastProvider'
import { useCallback, useMemo, useState } from 'react'
import { MultipleFileUploadSection } from 'screens/Scenarios/Main/sections/MultipleFileUploadSection'
import { getFileNameFromTemplateType } from 'utils'

export const TransportInboundOutbound = () => {
  const [donwloadProgresses, setDownloadProgesses] = useState({})

  const setDownloadProgess = useCallback((evt, fileType) => {
    setDownloadProgesses((prev) => ({ ...prev, [fileType]: evt }))
  }, [])

  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.transport.truckCapacity.'

  const items = [
    {
      title: t(`${defaultContentPath}short.title`),
      description: t(`${defaultContentPath}short.description`),
      fileType: 'TEMPLATES_ARG_CAMION_FLETE_CAPACITY_CORTO'
    },
    {
      title: t(`${defaultContentPath}long.title`),
      description: t(`${defaultContentPath}long.description`),
      fileType: 'TEMPLATES_ARG_CAMION_FLETE_CAPACITY_LARGO'
    }
  ]

  const { executionData, setValue, state, currentSection } =
    useScenariosContext()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [selectedFileType, setSelectedUploadFileType] = useState(undefined)
  const downloadTemplate = useDatasetDownload(setDownloadProgess)
  const { showToast } = useToast()

  const modalTitleMap = useMemo(
    () => ({
      TEMPLATES_ARG_CAMION_FLETE_CAPACITY_CORTO: 'Truck Short Freight Capacity',
      TEMPLATES_ARG_CAMION_FLETE_CAPACITY_LARGO: 'Truck Long Freight Capacity'
    }),
    []
  )

  const getFileIdByType = (fileType) => {
    return executionData.data.files.find((file) => file.type === fileType)?.id
  }

  const handleUpload = (fileType) => {
    setSelectedUploadFileType(fileType)
    setUploadModalOpen(true)
  }

  const handleDownload = (fileType) => {
    const fileId = getFileIdByType(fileType)
    const outputFileName = `${getFileNameFromTemplateType(fileType)}.xlsx`

    downloadTemplate(fileId, outputFileName, fileType)
  }

  const onUploadSuccess = useCallback(
    (files) => {
      const existingFiles = state[currentSection]?.files
      const incomingFiles = files.reduce(
        (acc, curr) => ({ ...acc, [curr.type]: curr.id }),
        {}
      )
      const newFiles = existingFiles
        ? Object.assign(existingFiles, incomingFiles)
        : incomingFiles

      setValue({
        files: newFiles
      })

      showToast({
        message: `File successfully uploaded for ${modalTitleMap[selectedFileType]}`,
        placement: 'top',
        status: 'success'
      })
      setTimeout(() => setUploadSuccess(false), 4000)
    },
    [
      setValue,
      showToast,
      state,
      currentSection,
      selectedFileType,
      modalTitleMap
    ]
  )

  return (
    <>
      <MultipleFileUploadSection
        sectionTitle={t(`${defaultContentPath}sectionTitle`)}
        items={items}
        onUpload={handleUpload}
        onDownload={handleDownload}
        success={uploadSuccess}
        progress={donwloadProgresses}
      />
      <ValidatedFileSelectModal
        title={`Upload inbound plan for ${modalTitleMap[selectedFileType]}`}
        type={selectedFileType}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onSuccess={onUploadSuccess}
        whatif={true}
      />
    </>
  )
}
