import classNames from 'classnames'
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  Brush
} from 'recharts'
import GraphSkeleton from '../Skeleton/GraphSkeleton'
import GraphSkeletonOS from '../Skeleton/GraphSkeletonOS'
import { ScatterShape } from './ScatterShape'
import { RenderLegend } from './Legend'
import { RenderTooltip } from './Tooltip'
import { variants } from './variants'
import './barchart.css'

const WrapWithContainer = ({ children, wrap, classNames = '' }) =>
  wrap ? (
    <div className={['w-full h-[450px] mt-10', classNames].join(' ')}>
      {children}
    </div>
  ) : (
    children
  )

/**
 * @param brushSettings: object to set up brush for your chart.
 * {
 *   size: number => size of chunk you would like to show at once (brush will only show if data set's length is longer than this value)
 *   dataKey: string => data key to bind to the brush
 * }
 **/
export default function BarChart({
  data,
  bars,
  stacks,
  barProps,
  scatters,
  scatterProps,
  cartesianGridProps,
  tooltipFormatter,
  xAxisProps,
  yAxisProps,
  disableYAxis,
  showLegend,
  loading,
  wrapWithContainer = false,
  containerClassNames,
  variant = 'default',
  brushSettings
}) {
  const {
    defaultScatterProps,
    defaultCartesianGridProps,
    defaultXAxisProps,
    defaultYAxisProps,
    defaultBarProps,
    defaultWrapWithContainer,
    defaultContainerClassNames,
    defaultShowLegend
  } = variants[variant]

  const mergedScatterProps = { ...defaultScatterProps, ...scatterProps }
  const mergedCartesianGridProps = {
    ...defaultCartesianGridProps,
    ...cartesianGridProps
  }
  const mergedXAxisProps = { ...defaultXAxisProps, ...xAxisProps }
  const mergedYAxisProps = { ...defaultYAxisProps, ...yAxisProps }
  const mergedBarProps = { ...defaultBarProps, ...barProps }
  const mergedWrapWithContainer = wrapWithContainer
    ? wrapWithContainer
    : defaultWrapWithContainer
  const mergedContainerClassNames = containerClassNames
    ? [defaultContainerClassNames, containerClassNames].join(' ')
    : defaultContainerClassNames
  const mergedShowLegend = showLegend ? showLegend : defaultShowLegend

  return loading ? (
    variant === 'small' ? (
      <GraphSkeletonOS />
    ) : (
      <GraphSkeleton />
    )
  ) : (
    <WrapWithContainer
      wrap={mergedWrapWithContainer}
      classNames={mergedContainerClassNames}
    >
      <ResponsiveContainer
        className={classNames('overflow-y-hidden', {
          'text-xs': variant === 'small'
        })}
      >
        <ComposedChart
          data={data}
          margin={{
            top: variant === 'small' ? 12 : 14,
            left: variant === 'small' ? 10 : 14,
          }}
        >
          <CartesianGrid
            {...mergedCartesianGridProps}
            verticalCoordinatesGenerator={({ xAxis, offset }) =>
              xAxis.domain.map(
                (_, i, arr) =>
                  (i + 2) * (offset.width / arr.length) - offset.right
              )
            }
          />
          <XAxis {...mergedXAxisProps} />
          {!disableYAxis && <YAxis {...mergedYAxisProps} />}
          <Tooltip
            cursor={{ fill: 'rgba(206, 206, 206, 0.4)' }}
            wrapperStyle={{
              outline: 'none'
            }}
            contentStyle={{ backgroundColor: '#081B43', borderRadius: 10 }}
            itemStyle={{ color: '#FFFFFF', fontWeight: 'bold' }}
            labelStyle={{
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: 'larger'
            }}
            content={
              <RenderTooltip
                stacks={
                  stacks &&
                  Object.entries(stacks).map(([stackId, { label }]) => ({
                    stackId,
                    label
                  }))
                }
              />
            }
            formatter={tooltipFormatter}
          />

          {mergedShowLegend && (
            <Legend
              align='left'
              content={
                <RenderLegend
                  scatters={scatters}
                  stacks={
                    stacks &&
                    Object.entries(stacks).map(([stackId, { label }]) => ({
                      stackId,
                      label
                    }))
                  }
                />
              }
            />
          )}

          {bars &&
            bars.map((bar) => (
              <Bar key={bar.dataKey} {...mergedBarProps} {...bar} />
            ))}

          {stacks &&
            Object.entries(stacks).map(([stackId, { data }]) =>
              data.map((bar) => (
                <Bar
                  key={`${stackId}.${bar.dataKey}`}
                  stackId={stackId}
                  {...mergedBarProps}
                  {...bar}
                  dataKey={`${stackId}.${bar.dataKey}`}
                />
              ))
            )}

          {scatters &&
            scatters.map(({ dataKey, fill, thickness, ...rest }) => (
              <Scatter
                key={dataKey}
                dataKey={dataKey}
                fill={fill ?? mergedScatterProps.color}
                shape={
                  <ScatterShape
                    thickness={thickness}
                    dataKey={dataKey}
                    scatterColor={fill ?? mergedScatterProps.color}
                    gap={mergedScatterProps.gap}
                  />
                }
                {...rest}
              />
            ))}

          {brushSettings && data.length > brushSettings.size && (
            <Brush
              startIndex={0}
              endIndex={brushSettings.size - 1}
              height={16}
              dataKey={brushSettings.dataKey}
              travellerWidth={2}
              className='[&>rect]:stroke-grey-400'
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </WrapWithContainer>
  )
}
