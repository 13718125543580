import { useMemo } from 'react'
import TableChart from 'components/Graphs/TableChart'
import {
  NO_DATA_LABEL,
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import useOutputGraph from '../useOutputGraph'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

const TransshipmentVolumeAndUtilization = ({ data, selectedSubProducts }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID, selectedProducts } = useOptimizationContext()
  const filteredBySelectedSubProducts = useMemo(() => {
    return selectedProducts.length > 0
      ? data.filter((p) => selectedSubProducts.includes(p.product))
      : data
  }, [data, selectedSubProducts, selectedProducts])

  const { summaryItems, bars, volumeChartData } = useOutputGraph(
    filteredBySelectedSubProducts
  )
  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: filteredBySelectedSubProducts,
    filterProducts: true
  })

  return !hasNoData(volumeChartData) ? (
    <TableChart
      title={t('optimizationScreen.transportation.details.storage')}
      summaryItems={summaryItems}
      data={volumeChartData}
      tooltipFormatter={labelFormatter}
      yAxisFormatter={yAxisFormatter}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      bars={bars}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: (val) =>
          val === NO_DATA_LABEL || !val ? NO_DATA_LABEL : valueFormatter(val),
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.transportation')} />
  )
}

export default TransshipmentVolumeAndUtilization
