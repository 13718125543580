import ControlledRadioGroup from 'components/Inputs/RadioGroup/ControlledRadioGroup'
import Button from 'components/Inputs/Button'
import { useCallback, useMemo, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { checkedValuesReducer } from 'screens/Configuration/utils'
import { COUNTRY_OPTIONS, PRODUCTS } from 'screens/Configuration/constants'
import { ControlledSelect } from 'components/Inputs/Select'
import { ControlledInput } from 'components/Inputs'
import { ControlledCheckbox } from 'components/Inputs/Checkbox'
import useLocationSelect from 'hooks/useLocationSelect'
import useSubmitConfigForm from 'hooks/useSubmitConfigForm'
import Alert from 'components/Feedback/Alert'
import { useCity } from 'providers/CityProvider'
import { useLocalization } from 'providers/LocalizationProvider'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { TRANSSHIPMENT_PREFIX, TransshipmentIDRegexp } from '../../constants'

const TransshipmentForm = ({ id: transshipmentId, currentData }) => {
  const { t, countryCode } = useLocalization()
  const { cities } = useCity()
  const isEditMode = useMemo(() => {
    return transshipmentId !== undefined
  }, [transshipmentId])
  const {
    control,
    watch,
    resetField,
    handleSubmit,
    trigger: triggerValidation,
    formState: { isSubmitted, errors, isDirty },
    setValue
  } = useForm({
    defaultValues: currentData ?? {
      transshipment_id: '',
      transshipment_name: currentData?.transshipment_name || '',
      country: countryCode,
      state: undefined,
      city_id: undefined,
      ownership: undefined,
      transshipment_type: undefined,
      products: [...PRODUCTS],
      rail_system_name: undefined
    }
  })

  const { submit, isMutating, error } = useSubmitConfigForm({
    basePath: '/transshipment',
    id: transshipmentId
  })

  const transshipment_id = watch('transshipment_id')
  const state = watch('state')
  const transshipment_type = watch('transshipment_type')

  const fullPrefix = useMemo(() => `${TRANSSHIPMENT_PREFIX}_${state}_`, [state])

  const { fields: products } = useFieldArray({
    control,
    name: 'products',
    rules: {
      validate: (currentValue) => {
        if (
          (currentData?.include === undefined ||
            currentData?.include === true) &&
          !currentValue.some((product) => product.checked === true)
        ) {
          return t(
            'assetConfigurationScreen.transshipments.form.products.errorMessages.required'
          )
        }
      }
    }
  })

  const { stateOptions, cityOptionsBySelectedState } = useLocationSelect({
    cities,
    state
  })

  const getTransshipmentName = useCallback(
    (transshipmentId) =>
      transshipmentId ? transshipmentId.substring(fullPrefix.length) : '',
    [fullPrefix.length]
  )

  const onSubmit = useCallback(
    (formData) => {
      const {
        transshipment_id,
        transshipment_type,
        city_id,
        ownership,
        products,
        rail_system_name
      } = formData

      const data = {
        transshipment_id,
        transshipment_name: getTransshipmentName(transshipment_id),
        transshipment_type,
        city_id,
        ownership,
        products: products.reduce(checkedValuesReducer, []),
        rail_system_name:
          transshipment_type === 'barges' ? null : rail_system_name
      }
      submit(data)
    },
    [getTransshipmentName, submit]
  )

  useEffect(() => {
    if (currentData?.transshipment_name) {
      setValue(
        'transshipment_id',
        currentData?.transshipment_name
      )
    } else if (state) setValue('transshipment_id', fullPrefix)
  }, [setValue, fullPrefix, state, currentData?.transshipment_name])

  return (
    <form className='mt-6 mb-48' onSubmit={handleSubmit(onSubmit)}>
      <section className='grid grid-cols-2'>
        <div>
          <h3 className='text-xl font-bold'>
            {t(
              'assetConfigurationScreen.transshipments.form.transshipmentInformation.title'
            )}
          </h3>
          <p className='text-grey-500'>
            {t(
              'assetConfigurationScreen.transshipments.form.transshipmentInformation.description'
            )}
          </p>
        </div>
        <div>
          <ControlledSelect
            className='mt-8'
            fullWidth
            disabled
            options={COUNTRY_OPTIONS}
            label={t(
              'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.countrySelector.label'
            )}
            onChange={() => {
              resetField('state')
              resetField('city_id')
            }}
            controllerProps={{
              name: 'country',
              control,
              rules: {
                required: {
                  value: true,
                  message: t(
                    'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.countrySelector.errorMessages.required'
                  )
                }
              }
            }}
          />
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledSelect
              fullWidth
              options={stateOptions}
              label={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.stateSelector.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.stateSelector.placeholder'
              )}
              onChange={() => resetField('city_id')}
              controllerProps={{
                name: 'state',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.stateSelector.errorMessages.required'
                    )
                  }
                }
              }}
            />
            <ControlledSelect
              fullWidth
              options={cityOptionsBySelectedState}
              label={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.citySelector.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.citySelector.placeholder'
              )}
              className='col-span-2'
              disabled={!state}
              controllerProps={{
                name: 'city_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.citySelector.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
          <div className='mt-6 flex justify-between items-end'>
            <ControlledInput
              fullWidth
              disabled={isEditMode || !state}
              description={
                !!getTransshipmentName(transshipment_id) && (
                  <span className='text-gray-400 text-xs'>
                    Transshipment name: &nbsp;
                    <strong>{getTransshipmentName(transshipment_id)}</strong>
                  </span>
                )
              }
              label={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentName.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentName.placeholder'
              )}
              fieldOnchange={(value) => {
                return (
                  fullPrefix + value.substr(fullPrefix.length)
                ).toUpperCase()
              }}
              tooltip={
                isEditMode
                  ? t('assetConfigurationScreen.editDisabled')
                  : undefined
              }
              endAddon={
                <button className='bg-blue-400 hover:text-blue-600 disabled:bg-blue-300  p-2 rounded-md ml-4'>
                  <DocumentDuplicateIcon
                    className='w-6 h-6 text-white'
                    onClick={(e) => {
                      e.preventDefault()
                      try {
                        navigator.clipboard.writeText(transshipment_id)
                      } catch (err) {
                        console.error('Failed to copy: ', err)
                      }
                    }}
                  />
                </button>
              }
              controllerProps={{
                name: 'transshipment_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentName.errorMessages.required'
                    )
                  },
                  minLength: {
                    value: 3,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentName.errorMessages.minLength'
                    )
                  },
                  validate: (value) => {
                    if (
                      TransshipmentIDRegexp.test(
                        value.substring(fullPrefix.length)
                      )
                    ) {
                      return t(
                        'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentName.errorMessages.validation'
                      )
                    }
                  }
                }
              }}
            />
          </div>
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledRadioGroup
              label={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentOwnership.label'
              )}
              options={[
                {
                  label: t(
                    'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentOwnership.bunge'
                  ),
                  value: 'owned'
                },
                {
                  label: t(
                    'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentOwnership.external'
                  ),
                  value: 'external'
                }
              ]}
              controllerProps={{
                name: 'ownership',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentOwnership.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledRadioGroup
              componentClassName='mb-6'
              label={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentType.label'
              )}
              options={[
                {
                  label: t('transshipmentTypes.rail'),
                  value: 'rail'
                },
                {
                  label: t('transshipmentTypes.barges'),
                  value: 'barges'
                }
              ]}
              controllerProps={{
                name: 'transshipment_type',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.transshipmentType.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
          {transshipment_type === 'rail' && (
            <ControlledInput
              label={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.railwayLine.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.railwayLine.placeholder'
              )}
              controllerProps={{
                name: 'rail_system_name',
                control,
                rules: {
                  minLength: {
                    value: 3,
                    message: t(
                      'assetConfigurationScreen.transshipments.form.transshipmentInformation.fields.railwayLine.errorMessages.minLength'
                    )
                  }
                }
              }}
            />
          )}
        </div>
      </section>
      <section className='grid grid-cols-2 mt-6'>
        <div>
          <h3 className='text-xl font-bold'>
            {t('assetConfigurationScreen.transshipments.form.products.title')}
          </h3>
          <p className='text-grey-500'>
            {t(
              'assetConfigurationScreen.transshipments.form.products.description'
            )}
          </p>
        </div>
        <div>
          <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2 mb-1'>
            {products.map(({ value, id }, index) => (
              <ControlledCheckbox
                key={id}
                label={t(`products.${value.replaceAll(/\s+/g, '_')}`)}
                valueKey='value'
                onChange={() => {
                  isSubmitted && triggerValidation('products')
                }}
                controllerProps={{
                  name: `products.${index}.checked`,
                  control
                }}
              />
            ))}
          </div>
          {errors?.products?.root?.message && (
            <span className='text-error-400'>
              {errors.products.root.message}
            </span>
          )}
        </div>
      </section>
      <section className='grid grid-cols-2 mt-6'>
        <div className='col-start-2'>
          {error && <Alert status='error' message={error.message} />}
          <Button
            variant='filled'
            size='l'
            className='mt-6 float-right'
            type='submit'
            loading={isMutating}
            disabled={!isDirty}
          >
            {`${
              transshipmentId
                ? t('assetConfigurationScreen.transshipments.form.update')
                : t('assetConfigurationScreen.transshipments.form.register')
            } ${t('assetConfigurationScreen.transshipments.form.assetName')}`}
          </Button>
        </div>
      </section>
    </form>
  )
}

export default TransshipmentForm

