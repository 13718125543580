import { useState, useEffect } from 'react'

const userKey = 'bg-user'
const tokenKey = 'accessToken'
const apiKey = 'apiKey'

// create hook to check if user is in localstorage
// and if so, set the user in state
const useAuth = () => {
  const [user, storeUser] = useState(() => ({
    username: 'inland@bunge.com',
    accessToken: '',
    logged: process.env.NODE_ENV === 'development'
  }))


  const setUser = (user, token) => {
    if (!user) {
      try {
        localStorage.removeItem(userKey)
        localStorage.removeItem(tokenKey)
        localStorage.removeItem(apiKey)
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        if(token) localStorage.setItem(tokenKey, token)
        localStorage.setItem(userKey, user)
        localStorage.setItem(apiKey, process.env.REACT_APP_API_KEY)
        storeUser({ username: user, accessToken: token, logged: true })
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    const user = localStorage.getItem(userKey)
    if (user) {
      setUser(user)
    }
  }, [])
  return [user, setUser]
}

export default useAuth
