import { XMarkIcon } from '@heroicons/react/24/outline'
import * as Dialog from '@radix-ui/react-dialog'
import './modal.css'
import Button from 'components/Inputs/Button'

/**
 *
 * @param {boolean} open
 * @param {function} onOpenChange
 * @param {JSX.Element} children
 * @param {string} title
 * @returns {JSX.Element}
 */
export const ModalComponent = ({
  open,
  onOpenChange,
  children,
  title,
  allowClosing = true
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(s) => allowClosing && onOpenChange(s)}
    >
      <Dialog.Portal>
        <Dialog.Overlay className='ModalComponentOverlay' />

        <Dialog.Content className='ModalComponentContent overflow-y-auto'>
          <div className='flex flex-row gap-4 mb-1 items-center'>
            <div className='flex-1 text-xl font-bold leading-loose text-gray-900'>
              {title}
            </div>

            {allowClosing && (
              <Dialog.Close asChild>
                <div>
                  <Button icon size='s' variant='unstyled'>
                    <XMarkIcon />
                  </Button>
                </div>
              </Dialog.Close>
            )}
          </div>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
