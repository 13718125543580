import { resolveObjectKey } from 'utils'

export const ScatterShape = ({
  cx,
  y,
  xAxis,
  scatterColor,
  gap,
  dataKey,
  thickness,
  ...rest
}) => {
  if (isNaN(resolveObjectKey(rest, dataKey))) {
    return <></>
  }
  const width = xAxis.bandSize - gap
  return (
    <rect
      fill={scatterColor}
      width={width}
      height={thickness || 4}
      x={cx - width / 2}
      y={y}
    ></rect>
  )
}
