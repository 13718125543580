import classNames from 'classnames'
import Switcher from '../Inputs/Switcher'
import CheckboxComponent from '../Inputs/CheckboxComponent/Checkbox'
import { Trans } from 'react-i18next'
import { useLocalization } from 'providers/LocalizationProvider'
import MultiRangeSlider from '../Inputs/MultiRangeSlider/MultiRangeSlider'

const OptimizationRunSettingsCard = ({
  disabledSlider,
  handleSwictherChange,
  hasSlider,
  sliderValues,
  isUnlimited,
  disabledSwicther,
  checkBoxChecked,
  hideCheckbox,
  disabledCheckBox,
  swictherchecked,
  ...props
}) => {
  const { countryCode, t } = useLocalization()

  const cardDescription = hasSlider
    ? t(
        `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${props.api_name}.description_supporting`
      )
    : t(
        `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${props.api_name}.description`
      )

const isOriginationPlan = props.api_name === 'origination_plan'

  return (
    <div
      className={classNames(
        'w-full border rounded-md flex flex-col justify-between pt-10 h-full'
      )}
      {...props}
    >
      <div className='px-4'>
        <div className='flex justify-between mb-4'>
          <h3 className='font-bold text-md'>{props.title}</h3>
          <Switcher
            onChange={(e) => handleSwictherChange(props.title, e)}
            checked={swictherchecked}
            disabled={disabledSwicther}
          />
        </div>
        {!hideCheckbox ? (
          <CheckboxComponent
            label='Allow unmet value'
            checked={checkBoxChecked}
            onChange={(e) => props.onChange(props.title, e.target.value)}
            disabled={disabledCheckBox}
          />
        ) : null}
        <p className='mt-3 text-grey-400 text-sm mb-10'>
          <Trans>{cardDescription}</Trans>
        </p>
        {hasSlider && (
          <div className='pb-12 -mt-6'>
            {isOriginationPlan ? (
              <>
                <MultiRangeSlider
                  values={[sliderValues[0]]}
                  defaultValues={[sliderValues[0]]}
                  maxColor={disabledSlider ? '#aaaaaa' : undefined}
                  label='Lower'
                  disabled={disabledSlider}
                  isUnlimited={isUnlimited}
                />
                <br />
                <MultiRangeSlider
                  values={[sliderValues[1]]}
                  defaultValues={[sliderValues[1]]}
                  maxColor={disabledSlider ? '#aaaaaa' : '#C836D5'}
                  label='Upper'
                  className='mb-0 pt-4'
                  disabled={disabledSlider}
                  isUnlimited={isUnlimited}
                />
              </>
            ) : (
              <MultiRangeSlider
                values={sliderValues}
                max={10}
                defaultValue={5}
                disabled={disabledSlider}
                isUnlimited={isUnlimited}
                maxColor={disabledSlider ? '#aaaaaa' : undefined}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default OptimizationRunSettingsCard

