import { useLocalization } from 'providers/LocalizationProvider'

const FOBMapCard = () => {
  const { t } = useLocalization()
  return (
    <div className='w-96 border rounded-lg p-6 bg-white'>
      <h4 className='tex-xxs flex justify-center'>
        { t('optimizationScreen.origination.fob.mapDescription') }
      </h4>
      <div className='flex flex-col gap-2 mt-4'>
        <div className='flex items-center'>
          <div className='w-3 h-3 rounded-full bg-blue-500'></div>
          <p className='m-1 text-sm font-bold text-grey-500'>
            { t('optimizationScreen.origination.fob.variation-10') }
          </p>
        </div>
        <div className='flex items-center'>
          <div className='w-3 h-3 rounded-full bg-blue-300'></div>
          <p className='m-1 text-sm font-bold text-grey-500'>
            { t('optimizationScreen.origination.fob.variation-5') }
          </p>
        </div>
        <div className='flex items-center'>
          <div className='w-3 h-3 rounded-full bg-white border-solid border border-gray-400'></div>
          <p className='m-1 text-sm font-bold text-grey-500'>
            { t('optimizationScreen.origination.fob.variation0') }
          </p>
        </div>
        <div className='flex items-center'>
          <div className='w-3 h-3 rounded-full bg-error-300'></div>
          <p className='m-1 text-sm font-bold text-grey-500'>
            { t('optimizationScreen.origination.fob.variation+5') }
          </p>
        </div>
        <div className='flex items-center'>
          <div className='w-3 h-3 rounded-full bg-error-600'></div>
          <p className='m-1 text-sm font-bold text-grey-500'>
            { t('optimizationScreen.origination.fob.variation+10') }
          </p>
        </div>
      </div>
    </div>
  )
}

export default FOBMapCard
