import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'

export const TransshipmentInboundOutbound = () => {
  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.transport.tsInboundOutbound.'

  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}sectionTitle`)}
      sectionDescription={t(`${defaultContentPath}sectionDescription`)}
      title={t(`${defaultContentPath}title`)}
      description={t(`${defaultContentPath}description`)}
      templateType='TEMPLATES_BR_TRANSSHIP_CAPACITY'
      modalTitle={t(`${defaultContentPath}modalTitle`)}
    />
  )
}
