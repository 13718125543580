import React from 'react'
import {
  FlagArgentinaRounded,
  FlagBrasilRounded,
  FlagUsaRounded
} from 'components/Icons'
import Select from 'components/Inputs/Select'
import {
  Item as RadixSelectItem,
  ItemText as RadixItemText
} from '@radix-ui/react-select'
import { useLocalization } from "providers/LocalizationProvider";

const currencies = [
  {
    value: 'USD',
    flag: FlagUsaRounded,
    label: 'Dollar (USD)'
  },
  {
    value: 'BRL',
    flag: FlagBrasilRounded,
    label: 'Reais (BRL)'
  },
  {
    value: 'ARS',
    flag: FlagArgentinaRounded,
    label: 'Peso Argentino (ARS)'
  }
]

const SelectItem = React.forwardRef(
  ({ children, flag: Flag, ...props }, forwardedRef) => {
    return (
      <RadixSelectItem
        className='SelectItem flex items-center min-h-9 text-base text-[#3D444D] pr-9 pl-6 py-2 select-none cursor-pointer'
        {...props}
        ref={forwardedRef}
      >
        <RadixItemText>
          <div className='flex flex-row items-center gap-2'>
            <Flag /> {children}
          </div>
        </RadixItemText>
      </RadixSelectItem>
    )
  }
)

/**
 * @param {object} props
 * @param {onChange} props.onChange - Callback function to be called when currency changes (currency: 'Dolar (USD)' | 'Reais (BRL)' | 'Peso Argentino (ARS)')
 * @param {boolean} props.disabled - If true, disables the component
 * @returns {JSX.Element}
 */
export default function CurrencySelector({ onChange, disabled }) {
  const { countryCode } = useLocalization()
  return (
    <Select
      placeholder={'Select currency'}
      defaultValue={countryCode === 'ARG' ? 'USD' : 'BRL'}
      CustomSelectItem={SelectItem}
      options={currencies}
      disabled={disabled}
      onValueChange={onChange}
    />
  )
}
