import { useCity } from 'providers/CityProvider'
import { useMemo } from 'react'

const useLocationSelect = ({ state }) => {
  const { getCitiesInState, getStates } = useCity()

  const stateOptions = useMemo(
    () =>
      getStates().map((state) => ({
        label: state,
        value: state
      })),
    [getStates]
  )

  const cityOptionsBySelectedState = useMemo(() => {
    if (!state) {
      return []
    }
    return getCitiesInState(state).map((city) => ({
      label: city.city,
      value: city.id
    }))
  }, [state, getCitiesInState])

  return { stateOptions, cityOptionsBySelectedState }
}

export default useLocationSelect
