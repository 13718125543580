import FileSaver from 'file-saver'
import { useLocalization } from 'providers/LocalizationProvider'
import { useToast } from 'providers/ToastProvider'
import { useCallback } from 'react'
import { getBlob } from 'utils/request'

/**
 * @param {string} fileName Name of the file on GCP bucket
 * @param {string} executionId Related execution id
 * @param {string} fileNamePrefix Prefix for the file name
 * @param {string} folderStructureOption 'output' | 'references'
 * @returns {(id: string, fileNameOverride?: string) => Promise<void>}
 */
export const useOutputDownload = (onProgress) => {
  const { showToast } = useToast()
  const { t } = useLocalization()

  return useCallback(
    async (fileName, executionId, folderStructureOption, progressParams) => {
      const handleProgress = (evt) => {
        onProgress?.(evt, progressParams)
      }

      try {
        handleProgress({ loaded: 0 })
        const data = await getBlob(
          `/data/execution/${executionId}/${fileName}`,
          {
            folderStructureOption: folderStructureOption
          },
          handleProgress
        )

        FileSaver.saveAs(data, fileName)
        handleProgress(undefined)
      } catch (e) {
        handleProgress(undefined)
        console.error('File download failed', e)
        showToast({
          message: t('statusMessages.fileDownloadFailed'),
          status: 'error',
          placement: 'top'
        })
      }
    },
    [showToast, t, onProgress]
  )
}
