const ModelDetailsCardSkeleton = () => {
  return (
    <div className='p-4 space-y-4 border border-gray-200 rounded-lg shadow animate-pulse mr-2'>
      <div className='flex flex-row justify-between'>
        <div className='h-2.5 bg-gray-300 rounded-full w-10 mb-2.5'></div>
        <div className='h-2.5 bg-gray-300 rounded-full w-5 mb-2.5'></div>
      </div>
      <div className='flex items-center justify-between'>
        <div className='border-r pr-4'>
          <div className='h-2.5 bg-gray-300 rounded-full w-24 mb-4'></div>
          <div className='w-20 h-2 bg-gray-200 rounded-full'></div>
        </div>
        <div>
          <div className='h-2.5 bg-gray-300 rounded-full w-10 mb-4'></div>
          <div className='w-16 h-2 bg-gray-200 rounded-full'></div>
        </div>
        <div>
          <div className='w-32 h-2 bg-gray-200 rounded-full'></div>
        </div>
      </div>

      <span className='sr-only'>Loading...</span>
    </div>
  )
}
export default ModelDetailsCardSkeleton
