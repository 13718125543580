import Button from 'components/Inputs/Button'
import ParametersTable from './ParametersTable'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { wait } from 'utils'
import { get, put } from 'utils/request'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'
import Alert from 'components/Feedback/Alert'
import { useLocalization } from 'providers/LocalizationProvider'

const tableOrder = [
  'rates',
  'shrinkages',
  // 'dwelltimes',
  // 'maintenances',
  'transport_emissions',
  'volume_range'
]

const Parameters = () => {
  const { t, countryCode } = useLocalization()
  const [showSuccess, setShowSuccess] = useState(false)
  const { data } = useSWR('/input-parameters', async (url) => {
    const { data } = await get(url)
    return data
  })

  const customValidator = useCallback(
    (value) => {
      if (!new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$').test(value)) {
        return t('assetConfigurationScreen.parameters.errorMessages.numberOnly')
      }
    },
    [t]
  )

  const rules = useMemo(
    () => ({
      required: {
        value: true,
        message: t('assetConfigurationScreen.parameters.errorMessages.required')
      },
      validate: customValidator
    }),
    [t, customValidator]
  )

  const ratesARG = {
    title: 'Interest rate',
    description: 'Update interest rates used by the model',
    columnsToValidate: ['interest_rate'],
    rules
  }

  const ratesBR = {
    title: t('assetConfigurationScreen.parameters.discountAndInterest.title'),
    description: t(
      'assetConfigurationScreen.parameters.discountAndInterest.description'
    ),
    columnsToValidate: ['discount_rate', 'interest_rate'],
    rules
  }

  const TABLES = {
    rates: countryCode === 'ARG' ? ratesARG : ratesBR,
    shrinkages: {
      title: t(
        `assetConfigurationScreen.parameters.shrinkage.${countryCode}.title`
      ),
      description: t(
        `assetConfigurationScreen.parameters.shrinkage.${countryCode}.description`
      ),
      columnsToValidate:
        countryCode === 'ARG'
          ? ['rail', 'truck', 'barges']
          : ['rail', 'truck', 'barges', 'storage'],
      rules
    },
    // dwelltimes: {
    //   title: 'Expiration time',
    //   description: 'Update allowed stored time by product type (in months)',
    //   columnsToValidate: ['Soy', 'Corn', 'Wheat'],
    //   rules
    // },
    // maintenances: {
    //   title: 'Plants maintenance',
    //   description: 'Number of allowed plants to stop at the same month (plants)',
    //   columnsToValidate: ['1_months', '2_months', '3_months'],
    //   rules
    // },
    transport_emissions: {
      title: t(
        `assetConfigurationScreen.parameters.transportEmissions.${countryCode}.title`
      ),
      description: t(
        `assetConfigurationScreen.parameters.transportEmissions.${countryCode}.description`
      ),
      columnsToValidate: ['Truck', 'Rail', 'Barge'],
      rules
    },
    volume_range: {
      title: t('assetConfigurationScreen.parameters.volumeRange.title'),
      description: t(
        'assetConfigurationScreen.parameters.volumeRange.description'
      ),
      columnsToValidate: ['ghg_emission_penalty'],
      rules
    }
  }

  const convertRatesToPercentage = useCallback((rows) => {
    return rows.map((row) => ({
      ...row,
      discount_rate: row.discount_rate * 100,
      interest_rate: row.interest_rate * 100
    }))
  }, [])

  const convertRatesFromPercentage = useCallback((rows) => {
    return rows.map((row) => ({
      ...row,
      discount_rate: row.discount_rate / 100,
      interest_rate: row.interest_rate / 100
    }))
  }, [])

  const defaultValues = useMemo(() => {
    if (!data) {
      return undefined
    }

    const dataTransformed = {
      ...data,
      rates: convertRatesToPercentage(data.rates)
    }

    dataTransformed.rates.map(
      (rate) => countryCode === 'ARG' && delete rate['discount_rate']
    )

    if (countryCode === 'ARG') {
      delete dataTransformed.shrinkages['storage']
    }

    const tables = Object.entries(dataTransformed)
      .map(([table, rows]) => ({
        table,
        rows: [rows].flat()
      }))
      .sort((a, b) => tableOrder.indexOf(a.table) - tableOrder.indexOf(b.table))

    return { tables }
  }, [data, convertRatesToPercentage, countryCode])

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues
  })

  const { fields: tables } = useFieldArray({
    name: 'tables',
    control
  })

  const { trigger, isMutating, error } = useSWRMutation(
    '/input-parameters',
    async (url, { arg }) => {
      await put(url, { data: arg })
    },
    {
      throwOnError: false,
      onSuccess: async () => {
        reset({}, { keepValues: true })
        setShowSuccess(true)
        await wait(3500)
        setShowSuccess(false)
      }
    }
  )

  const onSubmit = ({ tables }) => {
    const ratesIdx = tables.findIndex((t) => t.table === 'rates')

    tables.splice(ratesIdx, 1, {
      table: 'rates',
      rows: convertRatesFromPercentage(tables[ratesIdx].rows)
    })

    const data = tables.reduce((acc, cur) => {
      const rowValuesConvertedToNumbers = cur.rows.map((row) =>
        Object.entries(row).reduce(
          (r, [key, value]) => ({ ...r, [key]: Number(value) }),
          {}
        )
      )
      return {
        ...acc,
        [cur.table]:
          rowValuesConvertedToNumbers.length > 1
            ? rowValuesConvertedToNumbers
            : rowValuesConvertedToNumbers[0]
      }
    }, {})
    trigger(data)
  }

  return (
    <form
      className='flex w-full flex-col p-8 bg-white rounded-md'
      onSubmit={handleSubmit(onSubmit)}
    >
      {tables.map(({ table, rows }, index) => {
        if (!(table in TABLES)) {
          console.error(`${table} is not available`)
          return <></>
        }
        const { title, description, columnsToValidate, rules } = TABLES[table]
        return (
          <div
            key={table}
            className='grid grid-cols-1 sm:gap-6 sm:grid-cols-2 w-full mb-4'
          >
            <div className='flex flex-col justify-start'>
              <div className='mb-4 sm:mb-0'>
                <h2 className='text-xl mb-2 font-bold text-grey-700'>
                  {title}
                </h2>
                <p className='text-grey-400'>{description}</p>
              </div>
            </div>
            <ParametersTable
              index={index}
              rows={rows}
              rules={rules}
              control={control}
              columnsToValidate={columnsToValidate}
              readOnly={table === 'rates' ? ['year'] : []}
            />
          </div>
        )
      })}
      <div className='grid grid-cols-1 sm:grid-cols-2 w-full mb-4'>
        <div className='col-start-2'>
          {error && <Alert status='error' message={error.message} />}
          {showSuccess && !isDirty && (
            <Alert
              status='success'
              message={t('assetConfigurationScreen.parameters.successMessage')}
            />
          )}
          <div className='mt-4 flex items-center justify-end'>
            <Button
              variant='filled'
              size='l'
              className='w-48'
              loading={isMutating}
              disabled={!isDirty}
            >
              {t('assetConfigurationScreen.parameters.saveBtnLabel')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
export default Parameters
