import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'
import { useTemplate } from 'screens/Scenarios/useTemplate'

export const CrushingMaintenance = () => {
  const getTemplate = useTemplate()
  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.crushing.maintenancePlan.'

  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}sectionTitle`)}
      sectionDescription={t(`${defaultContentPath}sectionDescription`)}
      title={t(`${defaultContentPath}title`)}
      description={t(`${defaultContentPath}description`)}
      templateType={getTemplate()}
      params={{
        crushing_maintenance_plan: false
      }}
      modalTitle={t(`${defaultContentPath}modalTitle`)}
    />
  )
}
