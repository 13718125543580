import classNames from 'classnames'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

export default function SectionBox({
  className,
  variant = 'full',
  hasData = true,
  page,
  pageLangKey,
  children
}) {
  const { t } = useLocalization()

  return (
    <section
      className={classNames(
        'flex rounded-lg border shadow-md flex-col bg-white p-8 mb-6',
        variant === 'full' && 'w-full h-full',
        className
      )}
    >
      {hasData ? (
        children
      ) : (
        <NoData page={pageLangKey ? t(pageLangKey) : page} />
      )}
    </section>
  )
}
