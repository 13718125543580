import { createContext, useCallback, useContext } from 'react'
import useSWR from 'swr'
import { swrConfig } from 'utils'
import { get } from 'utils/request'

const CityContext = createContext({})
CityContext.displayName = 'CityContext'

export const CityProvider = ({ children }) => {
  const { data: cities } = useSWR(
    '/cities',
    async (url) => {
      const { data } = await get(url)
      return data
    },
    { ...swrConfig }
  )

  const getCityById = useCallback(
    (id) => cities.find((c) => c.id === id),
    [cities]
  )

  const getCitiesInState = useCallback(
    (state) =>
      cities
        .filter((city) => city.state === state)
        .sort((a, b) => (a.city > b.city ? 1 : -1)),
    [cities]
  )

  const getStates = useCallback(
    () => Array.from(new Set(cities.map((city) => city.state))).sort(),
    [cities]
  )

  const value = { cities, getCityById, getCitiesInState, getStates }

  return <CityContext.Provider value={value}>{children}</CityContext.Provider>
}

export const useCity = () => {
  const context = useContext(CityContext)
  if (!context) {
    throw new Error('useCity must be used within CityProvider')
  }
  return context
}
