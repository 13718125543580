import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { NO_DATA_LABEL, createMonthObject } from 'screens/Optimization/utils'

function useInternalMarketData(data) {
  const { id } = useParams()
  const {
    selectedProducts,
    executionData: { start_date, end_date },
    comparisonID
  } = useOptimizationContext()

  const getUniqueProducts = useCallback(() => {
    const preDefinedPreferredOrder = ['meal', 'oil', 'biodiesel']
    return [
      ...new Set(
        data
          .filter((item) =>
            !selectedProducts.length
              ? true
              : selectedProducts.includes(item.product_ui_type)
          )
          .map((item) => item.product_ui_type)
      )
    ].sort(
      (a, b) =>
        preDefinedPreferredOrder.indexOf(a.toLowerCase()) -
        preDefinedPreferredOrder.indexOf(b.toLowerCase())
    )
  }, [data, selectedProducts])

  const filterDataByProductUIType = useCallback(
    (product) => {
      return data.filter((item) => item.product_ui_type === product)
    },
    [data]
  )

  const filterDataByProduct = useCallback(
    (product) => {
      return data.filter((item) => item.product === product)
    },
    [data]
  )

  const getUniqueSubProducts = useCallback(
    (product) => {
      return [
        ...new Set(
          filterDataByProductUIType(product).map((item) => item.product)
        )
      ]
    },
    [filterDataByProductUIType]
  )

  const getUniqueAssets = useCallback(
    (product) => {
      return [
        ...new Set(filterDataByProduct(product).map((item) => item.asset_name))
      ]
    },
    [filterDataByProduct]
  )

  const products = useMemo(() => {
    return [...new Set(data.map((item) => item.product))]
  }, [data])

  const graphData = useMemo(() => {
    let defaultData = {}

    const products = getUniqueProducts()
    for (const product of products) {
      const subProducts = getUniqueSubProducts(product)
      for (const subProduct of subProducts) {
        defaultData = {
          ...defaultData,
          [subProduct]: createMonthObject(start_date, end_date, {
            [id]: NO_DATA_LABEL,
            [comparisonID]: NO_DATA_LABEL
          })
        }
      }
    }

    const result = data
      .filter((item) =>
        !selectedProducts.length
          ? true
          : selectedProducts.includes(item.product_ui_type)
      )
      .reduce((acc, { product, month, execution_id, volume }) => {
        return {
          ...acc,
          [product]: {
            ...acc[product],
            [month]: {
              ...acc[product][month],
              [execution_id]:
                acc[product][month][execution_id] === NO_DATA_LABEL
                  ? volume
                  : volume + acc[product][month][execution_id]
            }
          }
        }
      }, defaultData)

    return Object.entries(result).reduce(
      (acc, [key, resultData]) => ({
        ...acc,
        [key]: Object.entries(resultData).map(([month, volumes]) => ({
          month,
          volumes
        }))
      }),
      {}
    )
  }, [
    comparisonID,
    data,
    end_date,
    start_date,
    getUniqueProducts,
    getUniqueSubProducts,
    id,
    selectedProducts
  ])

  const getGraphData = (key) => {
    return graphData[key]
  }

  const getCrushData = useCallback(
    (product) => {
      const defaultData = getUniqueAssets(product).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: createMonthObject(start_date, end_date, {
            [id]: NO_DATA_LABEL,
            [comparisonID]: NO_DATA_LABEL
          })
        }
      }, {})

      const result = data
        .filter((item) => item.product === product)
        .reduce((acc, { product, month, execution_id, volume, asset_name }) => {
          return {
            ...acc,
            [asset_name]: {
              ...acc[asset_name],
              [month]: {
                ...acc[asset_name][month],
                [execution_id]:
                  acc[asset_name][month][execution_id] === NO_DATA_LABEL
                    ? volume
                    : volume + acc[asset_name][month][execution_id]
              }
            }
          }
        }, defaultData)

      return Object.entries(result).reduce(
        (acc, [key, resultData]) => ({
          ...acc,
          [key]: Object.entries(resultData).map(([month, volumes]) => ({
            month,
            volumes
          }))
        }),
        {}
      )
    },
    [comparisonID, data, end_date, start_date, id, getUniqueAssets]
  )

  return {
    getUniqueProducts,
    getUniqueSubProducts,
    graphData,
    getCrushData,
    products,
    getGraphData
  }
}

export default useInternalMarketData
