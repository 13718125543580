import useSWR from 'swr'
import SiloForm from './SiloForm'
import { useParams } from 'react-router-dom'
import {
  getProductsWithPrecheckedValues,
  getTransportationModesWithPrecheckedValues
} from 'screens/Configuration/utils'
import { get } from 'utils/request'
import { useMemo } from 'react'
import { useCity } from 'providers/CityProvider'

const Silos = () => {
  const { id } = useParams()

  const { getCityById } = useCity()

  const { data } = useSWR(id ? `/silo/${id}` : null, get)

  const currentData = useMemo(() => {
    if (!data) {
      return
    }
    const {
      silo_name,
      ownership,
      products,
      city_id,
      transportation_mode,
      include
    } = data
    const { country, state } = getCityById(city_id)

    return {
      silo_name,
      country,
      state,
      city_id,
      ownership,
      products: getProductsWithPrecheckedValues(products),
      transportation_mode:
        getTransportationModesWithPrecheckedValues(transportation_mode),
      include
    }
  }, [data, getCityById])

  return <SiloForm id={id} currentData={currentData} />
}

export default Silos
