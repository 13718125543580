import AllTransshipments from './AllTransshipments'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import VolumeAndUtilization from './VolumeAndUtilization'
import { useState } from 'react'
import TransportationDetails from './TransshipmentDetails/TransshipmentDetails'
import { useMemo } from 'react'
import SectionBox from 'components/Container/SectionBox'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

function Transportation() {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/transportation`,
    { bestEstimateId: comparisonID }
  ])

  const [selectedCorridor, setSelectedCorridor] = useState(undefined)
  const [selectedTransshipment, setSelectedTransshipment] = useState(undefined)

  const transshipmentSelectionOptions = useMemo(() => {
    const availableTransshipments = data.transportationVolumes
      .filter((t) => t.corridor === selectedCorridor)
      .reduce((acc, { ts, asset_name }) => ({ ...acc, [ts]: asset_name }), {})
    return Object.entries(availableTransshipments).map(
      ([asset, asset_name]) => ({
        label: asset_name,
        value: asset
      })
    )
  }, [data, selectedCorridor])

  return (
    <>
      {selectedTransshipment ? (
        <TransportationDetails
          setSelectedCorridor={setSelectedCorridor}
          selectedTransshipment={selectedTransshipment}
          setSelectedTransshipment={setSelectedTransshipment}
          transshipmentSelectionOptions={transshipmentSelectionOptions}
        />
      ) : (
        <OutputScreen title={t('optimizationScreen.pageNames.transportation')} information>
          <SectionBox>
            <AllTransshipments data={data.transportationVolumes} />
            <VolumeAndUtilization
              data={data.transportationVolumes}
              setSelectedTransshipment={setSelectedTransshipment}
              selectedCorridor={selectedCorridor}
              setSelectedCorridor={setSelectedCorridor}
            />
          </SectionBox>
        </OutputScreen>
      )}
    </>
  )
}

export default Transportation
