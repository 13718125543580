import { SingleVolumeCard } from 'components/Cards'
import { formatNumber } from 'utils/number'
import BarChart from 'components/Graphs/BarChart'
import useCrushingOutput from 'screens/Optimization/Main/Tabs/Crushing/useCrushingOutput'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { flatMap, maxBy, mean } from 'lodash'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { NO_DATA_LABEL } from 'screens/Optimization/utils/output'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'

const VolumeAndUtilization = ({ data, onClick }) => {
  const { crushedVolumeCards, assets } = useCrushingOutput(data)
  const { t } = useLocalization()

  const formatVolumeLabel = (labelValue) => {
    return `${formatNumber(labelValue / kgToTons, {
      maximumFractionDigits: 0
    })}K`
  }

  const getVolumeMaxAxisValue = () => {
    return maxBy(
      flatMap(crushedVolumeCards, (card) => card.months),
      'volume'
    ).volume
  }

  const getUtilizationValue = (card) => {
    const utilization = card.months
      ?.map((month) => month.utilization)
      .filter((util) => util !== NO_DATA_LABEL)
    return mean(utilization)
  }

  return (
    <SectionBox>
      <h3 className='text-xl font-bold text-grey-700'>
        { t('optimizationScreen.crushing.volumeAndUtilization.title') }
      </h3>
      <TabsComponent>
        <Tab value='crush_volume' label='Crush Volume'>
          <h4 className='mb-4 text-lg text-grey-600'>
            { t('optimizationScreen.crushing.volumeAndUtilization.crushVolumeBy') }
          </h4>
          <div className='grid grid-cols-3 gap-3'>
            {Object.keys(assets).map((asset) => {
              const card = crushedVolumeCards.find(
                (vol) => vol.assetName === assets[asset]
              ) || {
                asset,
                assetName: assets[asset]
              }
              const noData = !card.months || !card.aggregatedValue

              return !noData && (
                <SingleVolumeCard
                  key={card.asset}
                  title={card.assetName}
                  value={
                    card.aggregatedValue
                      ? formatNumber(card.aggregatedValue / kgToTons, {
                        maximumFractionDigits: 0
                      })
                      : NO_DATA_LABEL
                  }
                  onClick={() => onClick(card.asset)}
                  percentage={card.trend}
                  warning={
                    card.isAtCapacityLimit ? t('optimizationScreen.nearCapacityLimit') : undefined
                  }
                >
                  <BarChart
                    variant='small'
                    data={card.months}
                    bars={[
                      {
                        dataKey: 'volume',
                        fill: '#1950CA',
                        name: t('optimizationScreen.crushVolume')
                      }
                    ]}
                    yAxisProps={{
                      domain: [0, getVolumeMaxAxisValue()],
                      width: 25,
                      tickCount: 3,
                      tickFormatter: (val) =>
                        formatNumber(val / kgToTons, {
                          maximumFractionDigits: 0
                        })
                    }}
                    cartesianGridProps={{horizontal: true, vertical: false}}
                    xAxisProps={{
                      dataKey: 'month',
                      tickFormatter: (val) =>
                        transformDateToShortMonth(val).charAt(0)
                    }}
                    tooltipFormatter={formatVolumeLabel}
                  />
                </SingleVolumeCard>
              )
            })}
          </div>
        </Tab>
        <Tab value='utilization' label={ t('optimizationScreen.utilization') }>
          <h4 className='mb-4 text-lg text-grey-600'>
            { t('optimizationScreen.crushing.volumeAndUtilization.utilizationBy') }
          </h4>
          <div className='grid grid-cols-3 gap-3'>
            {Object.keys(assets).map((asset) => {
              const card = crushedVolumeCards.find(
                (vol) => vol.assetName === assets[asset]
              ) || {
                asset,
                assetName: assets[asset]
              }
              const noData = !card.months || !getUtilizationValue(card)

              return !noData && (
                <SingleVolumeCard
                  key={card.asset}
                  title={card.assetName}
                  value={
                    card.months
                      ? `${Math.round(getUtilizationValue(card))}%`
                      : NO_DATA_LABEL
                  }
                  onClick={() => onClick(card.asset)}
                  percentage={card.trend}
                  warning={
                    card.isAtCapacityLimit ? t('optimizationScreen.nearCapacityLimit') : undefined
                  }
                >
                  <BarChart
                    variant='small'
                    data={card.months}
                    bars={[
                      {
                        dataKey: 'utilization',
                        fill: '#1950CA',
                        name: t('optimizationScreen.crushing.volumeAndUtilization.crushUtilization')
                      }
                    ]}
                    yAxisProps={{
                      domain: [0, 100],
                      width: 25,
                      tickCount: 3
                    }}
                    cartesianGridProps={{horizontal: true, vertical: false}}
                    xAxisProps={{
                      dataKey: 'month',
                      tickFormatter: (val) =>
                        transformDateToShortMonth(val).charAt(0)
                    }}
                    tooltipFormatter={(val) => `${val}%`}
                  />
                </SingleVolumeCard>
              )
            })}
          </div>
        </Tab>
      </TabsComponent>
    </SectionBox>
  )
}

export default VolumeAndUtilization
