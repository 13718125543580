import { useMemo } from 'react'

const pattern = /\[\[(.+?)\]\]/g

export default function TextWithComponents({ text, substitutions }) {
  const segments = useMemo(() => {
    if (!text || typeof text !== 'string') {
      return []
    }

    const segments = []
    let subIdx = 0
    for (const match of text.matchAll(pattern)) {
      const startIndex = match.index
      const matchLength = match[0].length

      const prev = segments[segments.length - 1]
      if ((prev && prev.endPos < startIndex) || (startIndex > 0 && !prev)) {
        segments.push({
          endPos: startIndex,
          text: text.substring(prev?.endPos || 0, startIndex)
        })
      }

      const subData = substitutions?.[subIdx++] || {}
      segments.push({
        endPos: startIndex + matchLength,
        text: match[1],
        props: subData
      })
    }
    const lastSegment = segments[segments.length - 1]
    if (lastSegment && lastSegment.endPos < text.length) {
      segments.push({
        text: text.substring(lastSegment.endPos)
      })
    }
    return segments
  }, [text, substitutions])

  return (
    <>
      {segments.map(({ text, component: Comp, props = {} }, idx) => {
        if (Comp) {
          return (
            <Comp key={idx} {...props}>
              {text}
            </Comp>
          )
        }
        return (
          <span key={idx} {...props}>
            {text}
          </span>
        )
      })}
    </>
  )
}
