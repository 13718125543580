import { useLocalization } from 'providers/LocalizationProvider'
import { formatFileSize } from 'utils/number'
import './ProgressBar.css'
import classNames from 'classnames'

export default function ProgressBar({
  total,
  loaded,
  variant,
  children,
  small
}) {
  return (
    <div className='flex flex-col w-full'>
      <div className='flex flex-row gap-2 p-1'>
        {variant === 'plain' ? (
          children
        ) : variant === 'transfer' ? (
          <TransferTitle loaded={loaded} total={total} />
        ) : (
          <ProgressPercent loaded={loaded} total={total} />
        )}
      </div>
      <div
        className={classNames(
          'w-full rounded-lg bg-grey-200',
          small ? 'h-2' : 'h-4'
        )}
      >
        <div
          className={classNames(
            'ProgressBarIndicator bg-blue-400 rounded-lg transition-all',
            !total && 'nototal'
          )}
          style={{
            width: `${Math.round((loaded * 100) / total)}%`
          }}
        ></div>
      </div>
    </div>
  )
}

const ProgressPercent = ({ loaded, total }) => (
  <span className='text-m font-bold'>
    {Math.round((loaded * 100) / total)}%
  </span>
)

const TransferTitle = ({ loaded, total }) => {
  const { t } = useLocalization()

  if (!total && loaded > 0) {
    return (
      <>
        <span>
          {t('general.progressBar.transferNoTotal', {
            loaded: formatFileSize(loaded)
          })}
        </span>
      </>
    )
  }

  if (
    typeof loaded !== 'number' ||
    typeof total !== 'number' ||
    (total <= 0 && loaded <= 0)
  ) {
    return <span>{t('general.progressBar.preparingTransfer')}</span>
  }

  return (
    <>
      <span>
        {t('general.progressBar.transfer', {
          loaded: formatFileSize(loaded),
          total: formatFileSize(total)
        })}
      </span>
      (<ProgressPercent loaded={loaded} total={total} />)
    </>
  )
}
