import { AssetsConfigurationsProvider } from './AssetsConfigurationsProvider'
import { CityProvider } from './CityProvider'
import { ToastProvider } from './ToastProvider'
import { OptDataProvider } from './OptDataProvider'

const GlobalProvider = ({ children }) => (
  <CityProvider>
    <OptDataProvider>
      <ToastProvider>
        <AssetsConfigurationsProvider>{children}</AssetsConfigurationsProvider>
      </ToastProvider>
    </OptDataProvider>
  </CityProvider>
)

export default GlobalProvider
