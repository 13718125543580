import { useLocalization } from 'providers/LocalizationProvider'
import { useNewOptContext } from '../NewOptContext'
import classNames from 'classnames'

export const SETTINGS = 0
export const ASSETS = 1
export const FILES = 2

export const sectionsMap = {
  [SETTINGS]: 'settings',
  [ASSETS]: 'assets',
  [FILES]: 'files'
}

const Header = () => {
  const { t } = useLocalization()
  const { page } = useNewOptContext()

  return (
    <section className='mb-8'>
      <h2 className={classNames('text-2xl font-bold mt-10', {})}>
        {t(`newOptimizationScreen.sections.${sectionsMap[page]}.title`)}
      </h2>
      <p className='text-base text-grey-400 mt-4'>
        {t(`newOptimizationScreen.sections.${sectionsMap[page]}.description`)}
      </p>
    </section>
  )
}

export default Header
