import { useState } from 'react'
import { TabsComponent, Tab } from 'components/Navigation/Tabs/Tabs'
import { SwitcherButtons } from 'components/Inputs/Switchers'
import { Products } from './Products'
import useCrushingOutput from 'screens/Optimization/Main/Tabs/Crushing/useCrushingOutput'
import TableChart from 'components/Graphs/TableChart'
import {
  labelFormatter,
  yAxisFormatter,
  valueFormatter
} from 'screens/Optimization/utils/output'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useLocalization } from 'providers/LocalizationProvider'
import { useParams } from 'react-router'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import SectionBox from 'components/Container/SectionBox'

const AllCrushes = ({ data, onClick }) => {
  // 'chart' or 'table'
  const [view, setView] = useState('chart')

  const { summaryItems, allVolumeChartData, allVolumeBars, productCards } =
    useCrushingOutput(data)
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: data.allVolumes,
    filterProducts: true
  })
  const { countryCode, t } = useLocalization()

  return (
    <SectionBox
      pageLangKey='optimizationScreen.pageNames.crushing'
      hasData={!hasNoData(allVolumeChartData)}
    >
      <div className='flex justify-between w-full items-center'>
        <h3 className='text-xl font-bold text-grey-700'> { t('optimizationScreen.crushing.allCrushes.title') } </h3>
        <SwitcherButtons
          items={[
            {
              label: t('optimizationScreen.chart') ,
              value: 'chart'
            },
            {
              label: t('optimizationScreen.table'),
              value: 'table'
            }
          ]}
          onChange={setView}
        />
      </div>
      <TabsComponent>
        <Tab value='tab1' label={ t('optimizationScreen.crushing.allCrushes.crushedVolumes') }>
          <TableChart
            summaryItems={summaryItems}
            data={allVolumeChartData}
            scatters={
              countryCode === 'ARG'
                ? [
                    {
                      dataKey: 'totalCapacity',
                      fill: '#8E5602',
                      name: t('optimizationScreen.totalCapacity'),
                      thickness: 2
                    }
                  ]
                : undefined
            }
            tooltipFormatter={labelFormatter}
            yAxisFormatter={yAxisFormatter}
            xAxisProps={{
              dataKey: 'month',
              tickFormatter: transformDateToShortMonth
            }}
            bars={allVolumeBars}
            tableData={heatmapData}
            tableProps={{
              referenceProps: { title: t('optimizationScreen.comparison') , objKey: comparisonID },
              comparedProps: { title: t('optimizationScreen.baseModel'), objKey: baseModelId },
              groupLabelFormatter: transformDateToShortMonth,
              columnValueFormatter: valueFormatter,
              rowTitleFormatter: capitalize
            }}
            showViewSwitcher={false}
            selectedView={view}
          />
        </Tab>
        <Tab value='tab2' label={ t('optimizationScreen.crushing.allCrushes.products') }>
          <Products data={productCards} onClick={onClick} view={view} />
        </Tab>
      </TabsComponent>
    </SectionBox>
  )
}

export default AllCrushes
