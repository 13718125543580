import useAuth from './hooks/useAuth'
import Main from 'screens/Main'
import { SWRConfig } from 'swr'
import Login from 'screens/Login'
import { AzureAD, AuthenticationState } from 'react-aad-msal'
import { authProvider } from './hooks/useAuthProvider'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorFallbackWrapper from './components/ErrorFallback/ErrorFallback'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween'
import { get } from 'utils/request'
import { LocalizationProvider } from 'providers/LocalizationProvider'

export default function App() {
  dayjs.extend(localeData)
  dayjs.extend(isBetween)

  const [user, setUser] = useAuth()

  const logged = useMemo(() => {
    return user.logged
  }, [user])

  return (
    <LocalizationProvider>
      <ErrorBoundary
        onError={(error) => {
          // we should send error to sentry or splunk here or similar service
          console.error('Top level error:', error)
        }}
        key='app-error-boundary'
        fallback={props => <ErrorFallbackWrapper {...props} />}
      >
        <SWRConfig
          value={{
            fetcher: (args) => {
              if (Array.isArray(args)) {
                return get(...args)
              }
              return get(args)
            },
            suspense: true,
            provider: () => new Map(),
            onError: (error, _key) => {
              console.log("SWRConfig onError", error)
              if (error.status !== 403 && error.status !== 404) {
                return {
                  data: {
                    data: [],
                    message: 'Something went wrong'
                  },
                  error: { message: 'Something went wrong' }
                }
              }
            }
          }}
        >
          {logged ? (
            <Main
              logout={() => {
                console.log('logging out')
              }}
              setUser={setUser}
              authenticationState={null}
              accountInfo={null}
            />
          ) : (
            <AzureAD provider={authProvider}>
              {({ login, logout, authenticationState, error, accountInfo }) => {
                try {
                  const token = accountInfo?.jwtIdToken
                  const user = accountInfo?.account?.userName
                  if(user){
                    setUser(user, token)
                  } else {
                    console.log("Missing user app")
                    throw new Error("Username not found app")
                  }
                }
                catch (e) {
                  console.log(e)
                }
                switch (authenticationState) {
                  case AuthenticationState.Authenticated:
                    return (
                      <Main
                        logout={logout}
                        setUser={setUser}
                        authenticationState={authenticationState}
                        accountInfo={accountInfo}
                      />
                    )
                  case AuthenticationState.Unauthenticated:
                    return (
                      <Login
                        setUser={setUser}
                        error={error}
                        login={login}
                        pending={false}
                        authenticationState={authenticationState}
                        accountInfo={accountInfo}
                      />
                    )
                  case AuthenticationState.InProgress:
                    return (
                      <Login
                        setUser={setUser}
                        error={error}
                        login={login}
                        pending={true}
                        authenticationState={authenticationState}
                        accountInfo={accountInfo}
                      />
                    )
                  default:
                    return (
                      <Login
                        setUser={setUser}
                        error={error}
                        login={login}
                        pending={true}
                        authenticationState={authenticationState}
                        accountInfo={accountInfo}
                      />
                    )
                }
              }}
            </AzureAD>
          )}
        </SWRConfig>
      </ErrorBoundary>
    </LocalizationProvider>
  )
}
