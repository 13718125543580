import classNames from 'classnames'

export const HeatMapColoredTable = ({
  headers,
  sideHeaders,
  data,
  minValue,
  maxValue,
  unit
}) => {
  return (
    <table className='w-full'>
      <thead>
        <tr className='bg-grey-100 text-grey-500 border border-solid border-gray-20 text-sm h-12'>
          {sideHeaders && <th></th>}
          {headers &&
            headers.map((element) => (
              <th key={element} className='capitalize'>
                {element}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((element, index) => (
            <tr
              key={`${index}`}
              className={'h-8 border border-solid border-gray-20'}
            >
              <th className='text-grey-500 text-sm h-12'>
                {sideHeaders[index]}
              </th>
              {Object.values(element).map((value, index) => (
                <td key={`${index}-${value}`} className='p-0 h-12 relative'>
                  <div
                    className={classNames('h-full w-full', {
                      ['bg-[#F8696B]']: value < 0,
                      ['bg-[#FEC67D]']: value > 0
                    })}
                    style={{
                      opacity:
                        value < 0
                          ? Math.floor((value / minValue) * 100) / 100
                          : Math.floor((value / maxValue) * 100) / 100
                    }}
                  />
                  <span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    {new Intl.NumberFormat('es-AR', {
                      maximumFractionDigits: 0
                    }).format(value)}
                    {unit && (
                      <span className=' text-xs text-gray-800'> {unit}</span>
                    )}
                  </span>
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  )
}
