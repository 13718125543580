import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'

export const SoybeanMealDemand = () => {
  const { t } = useLocalization()
  const defaultContentPath =
    'scenarios.pages.crushing.soybeanMealInternalDemand.'
  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}sectionTitle`)}
      sectionDescription={t(`${defaultContentPath}sectionDescription`)}
      title={t(`${defaultContentPath}title`)}
      description={t(`${defaultContentPath}description`)}
      templateType='TEMPLATES_BR_CRUSH_DEMAND'
      params={{
        crush_processed_demand: true
      }}
      modalTitle={t(`${defaultContentPath}modalTitle`)}
    />
  )
}
