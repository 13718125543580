import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useCreateExecution } from 'hooks/useCreateExecution'
import useAuth from 'hooks/useAuth'
import {
  BRAZIL_DEFAULT,
  BASELINE_RUN,
  DEFAULT_SETTINGS,
  ARGENTINA_DEFAULT
} from 'constants'
import { useLocalization } from 'providers/LocalizationProvider'
import useSwr from 'swr'
import { get } from 'utils/request'
import { areAllRangesIdentical } from '../utils'

const initialState = {
  country_code: 'BR',
  name: 'BR_default',
  description: 'Default description',
  proceedWarningOpen: false,
  files: [],
  assetFiles: []
}

const ARG_DEFAULT_SETTINGS = {
  rail_transportation_plan: true,
  rail_transportation_plan_allow_unmet_value: true,
  grains_domestic_demand: true,
  sold_vessels: false,
  ports_spot_flow: false,
  allow_unmet_contracted_origination: false,
  allow_broker_crush: true,
  whatif_silo_allocation_plan: true
}

const argSettings = {
  [ARGENTINA_DEFAULT]: {
    origination_plan: false,
    origination_plan_range: [5, 5],
    export_plan: 'unlimited',
    hidro_transportation_plan: true,
    crushing_plan: true,
    crushing_maintenance_plan: false,
    crush_processed_demand: true,
    export_plan_allow_unmet_value: true,
    allow_extra_export_vol: true,
    crushing_plan_allow_unmet_value: true,
    hidro_transportation_plan_allow_unmet_value: true,
    allow_silo_fob_volume: true,
    crush_alcance: true,
    asset_product_expiration_time: false,
    product_expiration_time: false,
    asset_end_of_stock: false,
    min_end_of_stock: true,
    export_plan_percent: 0,
    receival_plan_percent: 0,
    allow_constraints_violation: false
  },
  [BASELINE_RUN]: {
    origination_plan: false,
    origination_plan_range: [5, 5],
    export_plan: 'off',
    export_plan_percent: 0,
    hidro_transportation_plan: true,
    crushing_plan: false,
    crushing_maintenance_plan: false,
    crush_processed_demand: true,
    export_plan_allow_unmet_value: true,
    allow_extra_export_vol: true,
    crushing_plan_allow_unmet_value: true,
    hidro_transportation_plan_allow_unmet_value: true,
    allow_silo_fob_volume: true,
    crush_alcance: true,
    asset_product_expiration_time: false,
    product_expiration_time: false,
    asset_end_of_stock: false,
    min_end_of_stock: true,
    allow_constraints_violation: false
  }
}

const settingOverrides = {
  [BRAZIL_DEFAULT]: {
    crush_alcance: true,
    crush_processed_demand: true,
    crushing_maintenance_plan: true,
    crushing_plan: true,
    crushing_plan_allow_unmet_value: true,
    export_plan: 'unlimited',
    export_plan_allow_unmet_value: true,
    export_plan_percent: 0,
    grains_domestic_demand: true,
    hidro_transportation_plan: false,
    hidro_transportation_plan_allow_unmet_value: true,
    min_end_of_stock: true,
    origination_plan_range: [5, 5],
    ports_spot_flow: true,
    product_expiration_time: true,
    rail_transportation_plan: true,
    rail_transportation_plan_allow_unmet_value: true
  },
  [BASELINE_RUN]: {
    crush_alcance: true,
    crush_processed_demand: true,
    crushing_maintenance_plan: false,
    crushing_plan: false,
    crushing_plan_allow_unmet_value: true,
    export_plan: 'off',
    export_plan_allow_unmet_value: true,
    export_plan_percent: 0,
    grains_domestic_demand: true,
    hidro_transportation_plan: false,
    hidro_transportation_plan_allow_unmet_value: true,
    min_end_of_stock: true,
    origination_plan_range: [5, 5],
    ports_spot_flow: true,
    product_expiration_time: true,
    rail_transportation_plan: false,
    rail_transportation_plan_allow_unmet_value: true
  }
}

const setDefaultAdvanceSetting = (advanceSetting, type, countryCode) => {
  const defaultSettings =
    countryCode === 'ARG' ? ARG_DEFAULT_SETTINGS : DEFAULT_SETTINGS
  const overrides =
    countryCode === 'ARG' ? argSettings[type] : settingOverrides[type]

  return {
    ...defaultSettings,
    ...advanceSetting,
    ...(overrides || {})
  }
}

const transformAdvanceSettings = (advanceSettings, type, cc) => {
  const transformed = Object.keys(advanceSettings).map((settingKey) => {
    const setting = advanceSettings[settingKey]
    let extraFields = {}

    if ('allowUnmetValue' in setting) {
      let unmet = {
        [`${settingKey}_allow_unmet_value`]: setting.allowUnmetValue
      }

      if (cc === 'ARG' && settingKey === 'export_plan') {
        unmet = Object.assign(unmet, {
          allow_extra_export_vol: setting.allowUnmetValue
        })
      }
      extraFields = Object.assign(extraFields, unmet)
    }

    if ('slider' in setting) {
      extraFields = Object.assign(extraFields, {
        [`${settingKey}`]:
          setting.slider.isUnlimited &&
          setting.slider.max === parseInt(setting.slider.value)
            ? 'unlimited'
            : setting.checked
            ? settingKey === 'origination_plan'
              ? true
              : 'on'
            : settingKey === 'origination_plan'
            ? false
            : 'off'
      })

      if (settingKey === 'export_plan') {
        extraFields = Object.assign(extraFields, {
          [`${settingKey}_percent`]: setting?.slider?.value?.[0] ?? 0
        })
      }
    }

    if ('origination_range_products' in setting) {
      const rangeProductList = Object.values(setting.origination_range_products)
      const hasRangeProducts = rangeProductList.every((arr) => arr.length > 0)
      if (hasRangeProducts) {
        extraFields = Object.assign(extraFields, {
          origination_range_products: setting.origination_range_products
        })
        const origination_plan_range = areAllRangesIdentical(rangeProductList)
          ? rangeProductList[0]
          : [5, 5]
        extraFields = Object.assign(extraFields, { origination_plan_range })
      }
    }

    return { [settingKey]: setting.checked, ...extraFields }
  })

  return setDefaultAdvanceSetting(Object.assign({}, ...transformed), type, cc)
}

const braDefault = {
  startYear: dayjs().year(),
  startMonth: dayjs().month() + 1,
  endYear: dayjs().year() + 1,
  endMonth: 3,
  type: BRAZIL_DEFAULT,
  advancedSettings: {
    origination_plan: {
      checked: true,
      slider: {
        value: 2,
        max: 21,
        min: 0,
        default: 5,
        isUnlimited: true
      },
      allowUnmetValue: true,
      configureProducts: false,
      origination_plan_range: [5, 5]
    },
    export_plan: {
      checked: false,
      slider: {
        value: 0,
        max: 21,
        min: 0,
        default: 5,
        isUnlimited: true
      },
      allowUnmetValue: false,
      origination_plan_range: [5, 5]
    },
    crushing_plan: {
      checked: false,
      allowUnmetValue: false
    },
    grains_domestic_demand: {
      checked: false,
      allowUnmetValue: true
    },
    crush_processed_demand: {
      checked: false,
      allowUnmetValue: true
    },
    rail_transportation_plan: {
      checked: false,
      allowUnmetValue: false
    },
    crushing_maintenance_plan: {
      checked: false
    },
    hidro_transportation_plan: {
      checked: false,
      allowUnmetValue: false
    },
    crush_alcance: {
      checked: false
    },
    ports_spot_flow: {
      checked: false
    },
    allow_silo_fob_volume: {
      checked: false
    },
    sold_vessels: {
      checked: false
    },
    asset_product_expiration_time: {
      checked: false
    },
    min_end_of_stock: {
      checked: true
    },
    product_expiration_time: {
      checked: false
    },
    allow_constraints_violation: {
      checked: false
    },
    asset_end_of_stock: {
      checked: false
    }
  }
}

const argDefault = {
  startYear: dayjs().year(),
  startMonth: dayjs().month() + 1,
  endYear: dayjs().year() + 1,
  endMonth: 3,
  type: ARGENTINA_DEFAULT,
  advancedSettings: {
    origination_plan: {
      checked: true,
      slider: {
        value: 5,
        max: 20,
        min: 0,
        default: 5,
        isUnlimited: false
      }
    },
    export_plan: {
      checked: false,
      slider: {
        value: 0,
        max: 21,
        min: 0,
        default: 0,
        isUnlimited: true
      },
      allowUnmetValue: true
    },
    hidro_transportation_plan: {
      checked: true,
      allowUnmetValue: true
    },
    crushing_plan: {
      checked: false,
      allowUnmetValue: true
    },
    crushing_maintenance_plan: {
      checked: false
    },
    crush_processed_demand: {
      checked: true
    },
    allow_silo_fob_volume: {
      checked: true
    },
    crush_alcance: {
      checked: false
    },
    asset_product_expiration_time: {
      checked: false
    },
    product_expiration_time: {
      checked: false
    },
    asset_end_of_stock: {
      checked: false
    },
    min_end_of_stock: {
      checked: true
    },
    allow_constraints_violation: {
      checked: false
    },
    allow_broker_crush: {
      checked: true
    }
  }
}

const UserInputsContext = createContext(initialState)
UserInputsContext.displayName = 'UserInputsContext'

export const UserInputsProvider = ({ children }) => {
  const [user] = useAuth()
  const [state, setState] = useState(initialState)
  const { createExecution, isExecuting } = useCreateExecution()
  const { countryCode } = useLocalization()

  const [newData, setNewData] = useState(
    countryCode === 'ARG' ? argDefault : braDefault
  )

  const {
    control,
    trigger,
    formState: { errors },
    handleSubmit,
    getValues,
    resetField,
    setValue,
    watch,
    reset: resetForm
  } = useForm({
    defaultValues: newData,
    mode: 'onChange'
  })

  useEffect(() => {
    const getData = async () => {
      const { data: apiData } = await get(
        `/execution/settings/${countryCode.toLowerCase()}/advanced `
      )

      setNewData((prevData) => ({
        ...prevData,
        advancedSettings: {
          ...prevData.advancedSettings,
          origination_plan: {
            ...prevData.advancedSettings.origination_plan,
            origination_range_products: apiData?.origination_range_products
          }
        }
      }))
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  useEffect(() => {
    resetForm(newData)
  }, [newData, resetForm])

  const setFiles = (payload) => {
    setState((prev) => ({ ...prev, files: payload }))
  }

  const setAssetFiles = (payload) => {
    setState((prev) => ({ ...prev, assetFiles: payload }))
  }

  const setProceedWarningOpen = (payload) => {
    setState((prev) => ({ ...prev, proceedWarningOpen: payload }))
  }

  const setName = (payload) => {
    setState((prev) => ({ ...prev, name: payload }))
  }

  const reset = () => {
    setState(initialState)
  }

  const { data: defaultAssetFiles } = useSwr([
    '/data/query',
    { typePrefix: 'ASSETS_', country: countryCode }
  ])

  const startOptimization = useCallback(
    async (form) => {
      const lastDayofMonth = dayjs(
        `${form.endYear}-${form.endMonth.toString().padStart(2, '0')}-01`
      )
        .endOf('month')
        .format('YYYY-MM-DD')
      const body = {
        country_code: countryCode,
        name: state.name,
        description: state.description,
        start_date: `${form.startYear}-${form.startMonth
          .toString()
          .padStart(2, '0')}-01`,
        end_date: lastDayofMonth,
        type: form.type,
        advancedSettings: transformAdvanceSettings(
          form.advancedSettings,
          form.type,
          countryCode
        ),
        files: [
          ...state.files,
          ...defaultAssetFiles.data
            .filter((asset) => !asset.type.endsWith('_FINANCIAL_RATES')) // This is generated by the backend
            .map((asset) => {
              const existingTemplate = state.assetFiles.find(
                (x) => x.type === asset.type
              )
              if (existingTemplate) {
                return existingTemplate
              }
              return {
                type: asset.type,
                id: asset.id
              }
            })
        ],
        createdBy: user.username || 'admin@bunge.com'
      }

      createExecution(body)
    },
    [
      countryCode,
      createExecution,
      defaultAssetFiles.data,
      state.assetFiles,
      state.description,
      state.files,
      state.name,
      user.username
    ]
  )

  const submitForm = handleSubmit(startOptimization)

  const value = {
    state,
    setFiles,
    setAssetFiles,
    setProceedWarningOpen,
    reset,
    control,
    trigger,
    errors,
    getValues,
    watch,
    setValue,
    resetField,
    submitForm,
    setName,
    isExecuting
  }

  return (
    <UserInputsContext.Provider value={value}>
      {children}
    </UserInputsContext.Provider>
  )
}

export function useUserInputsContext() {
  const context = useContext(UserInputsContext)
  if (context === undefined) {
    throw new Error(
      'useUserInputsContext must be used within a UserInputsProvider'
    )
  }
  return context
}
