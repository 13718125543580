export const addHeatmapData = (itemList, element, month, execution_id, comparisonID, volume, addSubRows, monthArray) => {
  let itemData = itemList.find(item => item.title === element)

  if (!itemData) {
    itemList.push({
      title: element,
      data: Object.fromEntries(monthArray.map(month => [month, {bm: 0, be: 0}])),
    })

    itemData = itemList.find(item => item.title === element)

    if (addSubRows) {
      itemData.subRows = []
    }
  }

  itemData.data[month][execution_id === comparisonID ? 'be' : 'bm'] += volume

  return itemData
}