import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'

export const FeedbackSurvey = () => {
  return (
    <div className='flex items-center px-3 relative hover:bg-blue-500 h-full'>
      <ChatBubbleLeftEllipsisIcon className='h-7 w-7 text-white' />
      {/* Badge component for when it becomes needed */}
      {/* <span class='absolute bg-yellow-600 rounded-full px-1 text-xs font-bold text-white left-full top-1/2 -translate-x-1/2 -translate-y-1'>
        1
      </span> */}
    </div>
  )
}
