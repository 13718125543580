import { useEffect } from 'react'
import useSWR from 'swr'
import { get } from 'utils/request'
import { useNewOptContext } from './NewOptContext'
import { useUserInputsContext } from 'providers/UserInputsProvider'
import SideBar from './SideBar'
import Main from './Main'
import LayoutWithSidebar from 'components/Container/LayoutWithSidebar'
import BatchUploadModal from 'components/Modal/BatchUploadModal'
import { formatter, stripDataTypePrefix } from 'utils/string'
import FileHistoryDrawer from './Main/Files/FileHistoryDrawer'
import { useDatasetDownload } from 'hooks/useDatasetDownload'
import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { useLocalization } from 'providers/LocalizationProvider'
import TextWithComponents from 'components/Typography/TextWithComponents'

export default function NewOptimization() {
  const {
    selectedFile,
    showUpdateModal,
    showHistoryDrawer,
    setShowUpdateModal,
    setShowHistoryDrawer,
    showBatchUpload,
    setShowBatchUpload,
    setTemplates,
    fileVersions,
    updatedFileIds,
    setUpdatedFileIds
  } = useNewOptContext()
  const { setFiles } = useUserInputsContext()
  const { t } = useLocalization()

  const { mutate } = useSWR('/data/templates', get, {
    onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
      if (error.status === 404) return
      if (retryCount >= 10) return
      setTimeout(() => revalidate({ retryCount }), 5000)
    },
    onSuccess: (data) => {
      const dataWithVersionOverrides = data.data.map((template) => {
        const fileVersionOverride = fileVersions[template.type]

        if (fileVersionOverride) {
          return {
            ...template,
            originalId: template.id,
            ...fileVersionOverride
          }
        }

        return template
      })

      setTemplates(
        dataWithVersionOverrides
          .map((template) => {
            const isMissingTemplate =
              !template.id || !template.updatedAt || !template.name

            return {
              ...template,
              id: template.id ?? '00000000-0000-0000-0000-000000000000',
              name:
                template.name ??
                stripDataTypePrefix(template.type).toLowerCase() + '.xlsx',
              status: isMissingTemplate
                ? 'missing'
                : template.originalId || updatedFileIds.includes(template.id)
                ? 'success'
                : 'warning',
              updatedAtTs: Date.parse(template.updatedAt)
            }
          })
          // Put files that need attention to the top
          .sort(({ status: st1 }, { status: st2 }) => {
            if (st1 === st2) return 0
            if (st2 === 'missing') return 1
            if (st1 !== 'missing' && st2 === 'warning') return 1
            return -1
          })
      )

      setFiles(
        dataWithVersionOverrides.map((item) => ({
          id: item.id,
          type: item.type
        }))
      )
    }
  })

  useEffect(() => {
    mutate('/data/templates')
  }, [showUpdateModal, updatedFileIds, showBatchUpload, fileVersions, mutate])

  const downloadTemplate = useDatasetDownload()

  return (
    <>
      <FileHistoryDrawer
        open={showHistoryDrawer}
        setOpen={setShowHistoryDrawer}
      />

      <LayoutWithSidebar sidebar={<SideBar />} main={<Main />} />

      <BatchUploadModal
        setShowBatchUpload={setShowBatchUpload}
        showBatchUpload={showBatchUpload}
      />

      {selectedFile && (
        <ValidatedFileSelectModal
          title={t('newOptimizationScreen.fileUpdate.title', {
            name: formatter(stripDataTypePrefix(selectedFile.type))
          })}
          type={selectedFile.type}
          open={showUpdateModal}
          setOpen={setShowUpdateModal}
          onSuccess={(files) => {
            setUpdatedFileIds((fileIds) => [
              ...fileIds,
              ...files.map((x) => x.id)
            ])
          }}
          hint={
            <p className='px-4 mb-4 text-center text-grey-400'>
              <TextWithComponents
                text={t('newOptimizationScreen.fileUpdate.hint')}
                substitutions={[
                  {
                    className:
                      'text-blue-400 underline font-bold cursor-pointer',
                    onClick: () => {
                      downloadTemplate(selectedFile.id)
                    }
                  }
                ]}
              />
            </p>
          }
        />
      )}
    </>
  )
}
