import React from 'react'

const BreadCrumbComponent = ({ crumbs }) => {
  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol className='inline-flex items-center gap-1 text-sm'>
        {crumbs.map((crumb, index, array) => (
          <React.Fragment key={crumb.label}>
            <li
              className={crumb?.onClick ? 'hover:cursor-pointer' : ''}
              onClick={crumb?.onClick}
            >
              <p className={index === 0 ? 'font-bold' : ''}>{crumb.label}</p>
            </li>
            {index !== array.length - 1 && <div>/</div>}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  )
}

export default BreadCrumbComponent
