import classNames from 'classnames'
import { ExcelIllustration } from 'components/Icons'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { formatFileSize } from 'utils/number'

/**
 *
 * @param {string} uploadLabel - label to show on click option
 * @param {JSX.Element} hint - show hint on the bottom
 * @param {function} onFileSelect
 * @param {boolean} multiple - allow to select multiple files, false by default
 * @param {string[]} errors - array of error messages
 * @returns {JSX.Element}
 */
export const FileSelector = ({
  uploadLabel,
  hint,
  onFileSelect,
  multiple = false,
  errors
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const defaultLabel = 'Click to upload'
  const onDropHandler = (files) => {
    setSelectedFiles(files)
    onFileSelect && onFileSelect(files)
  }

  return (
    <div
      className={classNames(
        'bg-grey-100 border-dotted border-2 rounded-md w-full h-max',
        { 'border-red-500': errors?.length > 0 && selectedFiles?.length > 0 }
      )}
    >
      <Dropzone
        onDropAccepted={onDropHandler}
        multiple={multiple}
        accept={{
          '.xls': [],
          '.xlsx': [],
          '.csv': [],
          'application/vnd.ms-excel': [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            []
        }}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            {...getRootProps()}
            className={classNames('p-7 h-auto', {
              'bg-grey-200 rounded-md': isDragActive
            })}
          >
            <input {...getInputProps()} />

            <div className='flex flex-col gap-2 items-center'>
              <ExcelIllustration />
              {!isDragActive && (
                <p>
                  <span className='font-bold'>
                    {uploadLabel ? uploadLabel : defaultLabel}
                  </span>{' '}
                  or drag and drop
                </p>
              )}

              {isDragActive && !isDragReject && (
                <p>{`Drop selected file${multiple ? 's' : ''} here`}</p>
              )}

              {isDragReject && (
                <p className='text-red-500'>Unsupported file type</p>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      <>{hint}</>
      {selectedFiles?.length > 0 && (
        <div className='min-w-[50%] px-7 pb-7'>
          <span className='block mb-2'>{`Selected file${
            multiple ? 's' : ''
          }`}</span>
          <div className='flex flex row gap-2 flex-wrap'>
            {selectedFiles?.map((file, idx) => (
              <div
                className='grid grid-flow-col auto-cols-auto gap-2 border-solid border rounded-md px-4 py-2 bg-grey-200/50'
                key={`${file.name}-${idx}`}
              >
                <ExcelIllustration />
                <div className='flex flex-col'>
                  <span>{file.name}</span>
                  <span>{formatFileSize(file.size)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedFiles?.length !== 0 && errors?.length > 0 && (
        <div className='mb-4 px-8'>
          <p className='font-bold text-red-500'>{`Error validating file${
            multiple ? 's' : ''
          }: `}</p>
          {errors?.map((error, idx) => (
            <div className='text-red-500' key={idx}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
