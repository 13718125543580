import Button from 'components/Inputs/Button'
import { FileSelector } from 'components/FileSelector/FileSelector'
import { useState } from 'react'
import { ModalComponent } from 'components/Modal/ModalComponent'

/**
 *
 * @param {boolean} open
 * @param {function} onOpenChange
 * @param {function} onUpload
 * @param {string} title
 * @param {string[]} errors
 * @param {boolean} isUploading
 * @param {string} buttonLabel
 * @param {string} buttonUploadingLabel
 * @param {JSX.Element} hint
 * @returns {JSX.Element}
 */
export const FileSelectorModal = ({
  open,
  onOpenChange,
  onUpload,
  onFileSelect,
  errors,
  title,
  isUploading = false,
  buttonLabel,
  buttonUploadingLabel,
  hint
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])

  const onOpenChangeHandler = (isOpen) => {
    !isUploading && onOpenChange && onOpenChange(isOpen)
    !isOpen && setSelectedFiles([])
  }

  const onUploadClick = () => {
    onUpload && onUpload(selectedFiles)
  }

  return (
    <ModalComponent
      open={open}
      onOpenChange={onOpenChangeHandler}
      title={title ?? 'Upload new file'}
    >
      <div>
        <div className='my-4'>
          <FileSelector
            onFileSelect={(files) => {
              onFileSelect?.(files)
              setSelectedFiles(files)
            }}
            errors={errors}
            hint={hint}
          />
        </div>

        <div className='flex flex-row justify-end'>
          <Button
            variant='filled'
            disabled={!selectedFiles?.length || isUploading || errors?.length}
            onClick={onUploadClick}
          >
            {isUploading
              ? buttonUploadingLabel || 'Uploading file...'
              : buttonLabel || 'Upload file'}
          </Button>
        </div>
      </div>
    </ModalComponent>
  )
}
