import TransportationARG from './ARG'
import TransportationBR from './BR'
import { useLocalization } from 'providers/LocalizationProvider'

export default function TransportationHandler() {
  const { countryCode } = useLocalization()

  return (
    <>
      {countryCode === 'ARG' && <TransportationARG />}
      {countryCode === 'BR' && <TransportationBR />}
    </>
  )
}
