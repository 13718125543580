import { useCallback, useMemo } from 'react'
import { useNewOptContext } from '../../../NewOptContext'
import { SvgMap } from 'components/SvgMaps'
import {
  SILOS,
  PORTS,
  CRUSHERS,
  TRANSSHIPMENTS,
  GENERAL_ASSETS_VIEW
} from 'constants'
import classNames from 'classnames'
import { useCity } from 'providers/CityProvider'
import { get } from 'utils/request'
import useSWR from 'swr'
import { useLocalization } from 'providers/LocalizationProvider'

const Maps = () => {
  const { getCityById } = useCity()
  const { data: silos } = useSWR('/silo', get)
  const { data: crushers } = useSWR('/crusher', get)
  const { data: transshipments } = useSWR('/transshipment', get)
  const { data: terminals } = useSWR('/port', get)

  const { t } = useLocalization()
  const { selectedAsset } = useNewOptContext()

  const getCoordinatesByCity = useCallback((city) => {
    return [city?.lon, city?.lat] || []
  }, [])

  const getSiloNamesForCrusher = useCallback(
    (crusher) => {
      return (
        silos
          .filter((silo) => crusher.silos.includes(silo.id))
          .map((silo) => silo.silo_name)
          .join(', ') || '-'
      )
    },
    [silos]
  )

  const silosWithMarkers =
    useMemo(
      () =>
        silos?.map((silo) => {
          return {
            title: silo.silo_name,
            silo_id: silo.silo_id,
            status: silo.include
              ? t('newOptimizationScreen.sections.assets.active')
              : t('newOptimizationScreen.sections.assets.inactive'),
            city: getCityById(silo.city_id)?.city,
            coordinates: getCoordinatesByCity(getCityById(silo.city_id)),
            stroke: '#2BAC82',
            fill: '#C4F0E1',
            products: silo.products.join(', ') || '-',
            ownership: silo.ownership || '-',
            transportation: silo.transportation_mode.join(', ') || '-'
          }
        }),
      [silos, getCoordinatesByCity, getCityById, t]
    ) || []

  const portsWithMarkers =
    useMemo(
      () =>
        terminals?.map((terminal) => {
          return {
            title: terminal.terminal_name,
            terminal_id: terminal.terminal_id,
            status: terminal.include
              ? t('newOptimizationScreen.sections.assets.active')
              : t('newOptimizationScreen.sections.assets.inactive'),
            city: getCityById(terminal.port.city_id)?.city,
            coordinates: getCoordinatesByCity(
              getCityById(terminal.port.city_id)
            ),
            transportation: terminal.transportation_mode?.join(', '),
            stroke: '#1950CA',
            fill: '#7EA2F4'
          }
        }),
      [terminals, getCoordinatesByCity, getCityById, t]
    ) || []

  const transshipmentsWithMarkers =
    useMemo(
      () =>
        transshipments?.map((transshipment) => {
          return {
            title: transshipment.transshipment_name,
            transshipment_id: transshipment.transshipment_id,
            status: transshipment.include
              ? t('newOptimizationScreen.sections.assets.active')
              : t('newOptimizationScreen.sections.assets.inactive'),
            city: getCityById(transshipment.city_id)?.city,
            coordinates: getCoordinatesByCity(
              getCityById(transshipment.city_id)
            ),
            stroke: '#5F50C7',
            fill: '#CAC5EC',
            type: transshipment.transshipment_type || '-',
            products: transshipment.products.join(', ') || '-'
          }
        }),
      [transshipments, getCoordinatesByCity, getCityById, t]
    ) || []

  const crushersWithMarkers = useMemo(
    () =>
      crushers
        ?.filter((crusher) => crusher.include)
        .map((crusher) => {
          return {
            title: crusher.crusher_name,
            crusher_id: crusher.crusher_id,
            status: crusher.include
              ? t('newOptimizationScreen.sections.assets.active')
              : t('newOptimizationScreen.sections.assets.inactive'),
            city: getCityById(crusher.city_id)?.city,
            coordinates: getCoordinatesByCity(getCityById(crusher.city_id)),
            stroke: '#F6853E',
            fill: '#FCD6BF',
            silos: getSiloNamesForCrusher(crusher)
          }
        }),
    [crushers, getCoordinatesByCity, getCityById, getSiloNamesForCrusher, t]
  )

  return (
    <div className='ml-6 bg-grey-100 overflow-hidden'>
      <div className={classNames('max-w-[820px] mx-auto mt-4 px-16 h-[520px]')}>
        {selectedAsset === SILOS && (
          <SvgMap key={SILOS} markers={silosWithMarkers} />
        )}
        {selectedAsset === PORTS && (
          <>
            <SvgMap key={PORTS} markers={portsWithMarkers} />
          </>
        )}
        {selectedAsset === TRANSSHIPMENTS && (
          <SvgMap key={TRANSSHIPMENTS} markers={transshipmentsWithMarkers} />
        )}
        {selectedAsset === CRUSHERS && (
          <SvgMap key={CRUSHERS} markers={crushersWithMarkers} />
        )}
        {selectedAsset === GENERAL_ASSETS_VIEW && (
          <SvgMap
            key={GENERAL_ASSETS_VIEW}
            markers={[
              ...(silosWithMarkers ?? []),
              ...(portsWithMarkers ?? []),
              ...(transshipmentsWithMarkers ?? []),
              ...(crushersWithMarkers ?? [])
            ]}
          />
        )}
      </div>
    </div>
  )
}

export default Maps
