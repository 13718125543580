import Calendar from 'components/Calendar'
import SectionBox from 'components/Container/SectionBox'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useCrushingOutput from 'screens/Optimization/Main/Tabs/Crushing/useCrushingOutput'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import {useLocalization} from 'providers/LocalizationProvider'

const CrushPlantMaintainance = ({ data }) => {
  const {
    executionData: { start_date, end_date }
  } = useOptimizationContext()
  const { t } = useLocalization()

  const months = getMonthsForPeriod(start_date, end_date).map((month, idx) => ({
    month: month,
    value: idx
  }))
  const { maintenanceData } = useCrushingOutput(data)
  return (
    <SectionBox>
      <h2 className='mb-10 font-bold text-xl'>{ t('optimizationScreen.crushing.plantMaintenance') }</h2>
      <Calendar data={maintenanceData} months={months} />
    </SectionBox>
  )
}

export default CrushPlantMaintainance
