import classNames from 'classnames'
import Button from 'components/Inputs/Button'

const ButtonNavigation = ({ buttons, selected, setSelected, className }) => {
  return (
    <div className={classNames('flex flex-row gap-2 flex-wrap', className)}>
      {buttons.map(({ title, value, icon: IconComponent }) => {
        const v = value ?? title
        const isSelected = v === selected

        return (
          <div key={v}>
            <Button
              variant='blank'
              onClick={(e) => {
                e.preventDefault()
                setSelected(v)
              }}
              className={classNames(
                'border border-solid border-1 pl-2 pr-3 py-1 mt-3 rounded flex items-center',
                {
                  'border-blue-500 bg-blue-100 text-blue-700': isSelected,
                  'border-grey-300': !isSelected
                }
              )}
            >
              {IconComponent && (
                <IconComponent
                  className={classNames(
                    'h-5 w-5',
                    isSelected ? 'text-blue-400' : 'text-grey-500'
                  )}
                />
              )}
              <span
                className={classNames(
                  isSelected ? 'text-blue-400' : 'text-grey-700',
                  '-ml-1'
                )}
              >
                {title}
              </span>
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default ButtonNavigation
