import { useController } from 'react-hook-form'
import Input from './Input'
/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.type
 * @returns {JSX.Element}
 */
const ControlledInput = ({
  controllerProps,
  onChange,
  fieldOnchange,
  tooltip,
  ...props
}) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps)
  return (
    <Input
      {...props}
      value={field.value}
      onChange={(value) => {
        field.onChange(fieldOnchange ? fieldOnchange(value) : value)
        onChange && onChange(value)
      }}
      error={error && error.message}
      tooltip={tooltip}
    />
  )
}
export default ControlledInput
