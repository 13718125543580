import { useMemo } from 'react'
import HeatMapTable from 'components/Tables/HeatMapTable'
import {
  NO_DATA_LABEL,
  kgToTons,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useProductFilter from 'hooks/useProductFilter'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useParams } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'

const Total = ({ data }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()
  const monthArray = getMonthsForPeriod(start_date, end_date)

  const { filteredData } = useProductFilter({ data })

  const nullsafeData = useMemo(
    () =>
      filteredData.map((x) => ({
        ...x,
        corridor: x.corridor || 'Unknown',
        mesoregion: x.mesoregion || 'Unknown',
        asset_name: x.asset_name || 'Unknown'
      })),
    [filteredData]
  )

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: nullsafeData,
    rowLevelKeys: [
      'corridor',
      'mesoregion',
      { dataKey: 'asset', titleKey: 'asset_name' }
    ]
  })

  return (
    <>
      <p className=' mb-10 text-xl font-bold'>
        {t('optimizationScreen.origination.totalTitle')}
      </p>
      <HeatMapTable
        refProp={{
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID,
          legend: t('optimizationScreen.comparison')
        }}
        comparedProps={[
          {
            title: t('optimizationScreen.baseModel'),
            objKey: baseModelId,
            legend: t('optimizationScreen.baseModel')
          }
        ]}
        data={heatmapData}
        showTotal
        groups={monthArray}
        defaultColoringMode='keys'
        columnValueFormatter={(value) =>
          value === NO_DATA_LABEL || !value
            ? NO_DATA_LABEL
            : Math.round(value / kgToTons)
        }
        groupLabelFormatter={transformDateToShortMonth}
      />
    </>
  )
}

export default Total
