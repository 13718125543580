import { Trans } from 'react-i18next'

export const TitleAndDescription = ({ title, description }) => (
  <div>
    <h3 className='text-lg font-bold'>
      <Trans>{title}</Trans>
    </h3>
    <p className='text-grey-400'>
      <Trans>{description}</Trans>{' '}
    </p>
  </div>
)
