import TableChart from 'components/Graphs/TableChart'
import useFinancialOverviewData from './useFinancialOverviewData'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'lodash'
import MultiGraphsTable from 'components/Tables/MultiGrapshTable.jsx'
import { formatAsMillions } from 'utils/number'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'
import OutputScreen from 'components/Container/OutputScreen'

const FinancialOverviewInformation = () => {
  const { t } = useLocalization()

  return (
    <>
      <p className='mb-3 font-bold'>
        {t('optimizationScreen.financialOverview.info.title')}
      </p>

      <p className='mb-3'>
        <strong>{t('optimizationScreen.financialOverview.info.please')}</strong>{' '}
        {t('optimizationScreen.financialOverview.info.comparing')}
      </p>
      <p className='font-bold'>
        {t('optimizationScreen.financialOverview.info.important')}
      </p>
      <ul className='list-disc mt-2 ml-6'>
        {[...new Array(6).keys()].map((p) => (
          <li key={p}>
            {t(`optimizationScreen.financialOverview.info.point${p + 1}`)}
          </li>
        ))}
      </ul>
    </>
  )
}

const FinancialsOverview = () => {
  const { id } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { countryCode, t } = useLocalization()
  const { data } = useSWR([
    `/execution/outputs/${id}/financial_overview`,
    { bestEstimateId: comparisonID }
  ])
  const {
    benefitGraph,
    benefitTableData,
    revenueGraph,
    revenueTableData,
    costGraphsWithAbsolute,
    summaryItemsBenefit,
    summaryItemsRevenue,
    summaryItemCosts,
    bars
  } = useFinancialOverviewData(data)

  const { heatmapData: benefitHeatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: benefitTableData
  })
  const { heatmapData: revenueHeatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: revenueTableData
  })

  return (
    <OutputScreen
      title={t('optimizationScreen.pageNames.financialOverview')}
      information={<FinancialOverviewInformation />}
    >
      {costGraphsWithAbsolute.length !== 0 &&
      !hasNoData(benefitGraph) &&
      !hasNoData(revenueGraph) ? (
        <>
          <SectionBox>
            <TableChart
              title={
                countryCode === 'ARG'
                  ? t('optimizationScreen.financialOverview.revenueArg')
                  : t('optimizationScreen.financialOverview.revenueBr')
              }
              summaryItems={summaryItemsRevenue}
              data={revenueGraph}
              tooltipFormatter={formatAsMillions}
              yAxisFormatter={formatAsMillions}
              xAxisProps={{
                dataKey: 'month',
                tickFormatter: transformDateToShortMonth
              }}
              bars={bars}
              tableData={revenueHeatmapData}
              tableProps={{
                referenceProps: {
                  title: t('optimizationScreen.comparison'),
                  objKey: comparisonID
                },
                comparedProps: {
                  title: t('optimizationScreen.baseModel'),
                  objKey: id
                },
                groupLabelFormatter: transformDateToShortMonth,
                columnValueFormatter: formatAsMillions,
                rowTitleFormatter: capitalize
              }}
            />
          </SectionBox>
          <SectionBox>
            <h3 className='text-xl font-bold text-grey-500 text-grey-700 mb-10'>
              {countryCode === 'ARG'
                ? t('optimizationScreen.financialOverview.costArg')
                : t('optimizationScreen.financialOverview.costBr')}
            </h3>
            <section className='flex w-full h-full rounded-xl overflow-hidden border flex-col bg-white'>
              {costGraphsWithAbsolute.map((graph, index) => (
                <MultiGraphsTable
                  key={index}
                  data={graph.graphData}
                  title={capitalize(graph?.graphTitle) || ''}
                  total={formatAsMillions(summaryItemCosts[index])}
                  bars={bars}
                  tooltipFormatter={formatAsMillions}
                  yAxisFormatter={formatAsMillions}
                  xAxisProps={{
                    dataKey: 'month',
                    tickFormatter: transformDateToShortMonth
                  }}
                />
              ))}
            </section>
          </SectionBox>
          <SectionBox>
            <TableChart
              title={
                countryCode === 'ARG'
                  ? t('optimizationScreen.financialOverview.benefitArg')
                  : t('optimizationScreen.financialOverview.benefitBr')
              }
              summaryItems={summaryItemsBenefit}
              data={benefitGraph}
              tooltipFormatter={formatAsMillions}
              yAxisFormatter={formatAsMillions}
              xAxisProps={{
                dataKey: 'month',
                tickFormatter: transformDateToShortMonth
              }}
              bars={bars}
              tableData={benefitHeatmapData}
              tableProps={{
                referenceProps: {
                  title: t('optimizationScreen.comparison'),
                  objKey: comparisonID
                },
                comparedProps: {
                  title: t('optimizationScreen.baseModel'),
                  objKey: id
                },
                groupLabelFormatter: transformDateToShortMonth,
                columnValueFormatter: formatAsMillions,
                rowTitleFormatter: capitalize
              }}
            />
          </SectionBox>
        </>
      ) : (
        <SectionBox
          pageLangKey='optimizationScreen.pageNames.financialOverview'
          hasData={false}
        />
      )}
    </OutputScreen>
  )
}

export default FinancialsOverview
