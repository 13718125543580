import { MultipleFileUploadSection } from 'screens/Scenarios/Main/sections/MultipleFileUploadSection'
import { useScenariosContext } from 'providers/ScenariosProvider'
import { useCallback, useMemo, useState } from 'react'
import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { useDatasetDownload } from 'hooks/useDatasetDownload'
import { useToast } from 'providers/ToastProvider'
import { useLocalization } from 'providers/LocalizationProvider'

export const SiloReceptionPlan = () => {
  const [donwloadProgresses, setDownloadProgesses] = useState({})

  const setDownloadProgess = useCallback((evt, fileType) => {
    setDownloadProgesses((prev) => ({ ...prev, [fileType]: evt }))
  }, [])

  const { executionData, setValue, state, currentSection } =
    useScenariosContext()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [selectedFileType, setSelectedUploadFileType] = useState(undefined)
  const downloadTemplate = useDatasetDownload(setDownloadProgess)
  const { showToast } = useToast()
  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.silos.siloReceptionPlan.'

  const getProductNameByTemplate = (fileType) => {
    switch (fileType) {
      case 'TEMPLATES_BR_SILO_FLOW_CORN':
        return 'corn'
      case 'TEMPLATES_BR_SILO_FLOW_SOY':
        return 'soy'
      default:
        return ''
    }
  }

  const product = useMemo(() => {
    return getProductNameByTemplate(selectedFileType)
  }, [selectedFileType])

  const getFileIdByType = (fileType) => {
    return executionData.data.files.find((file) => file.type === fileType)?.id
  }

  const items = [
    {
      title: t(`${defaultContentPath}corn.title`),
      description: t(`${defaultContentPath}corn.description`),
      fileType: 'TEMPLATES_BR_SILO_FLOW_CORN'
    },
    {
      title: t(`${defaultContentPath}soy.title`),
      description: t(`${defaultContentPath}soy.description`),
      fileType: 'TEMPLATES_BR_SILO_FLOW_SOY'
    }
  ]

  const handleUpload = (fileType) => {
    setSelectedUploadFileType(fileType)
    setUploadModalOpen(true)
  }

  const handleDownload = (fileType) => {
    const fileId = getFileIdByType(fileType)
    const outputFileName = `silo-flow-${getProductNameByTemplate(
      fileType
    )}.xlsx`

    downloadTemplate(fileId, outputFileName, fileType)
  }

  const onUploadSuccess = useCallback(
    (files) => {
      const existingFiles = state[currentSection]?.files
      const incomingFiles = files.reduce(
        (acc, curr) => ({ ...acc, [curr.type]: curr.id }),
        {}
      )
      const newFiles = existingFiles
        ? Object.assign(existingFiles, incomingFiles)
        : incomingFiles

      setValue({
        files: newFiles
      })

      showToast({
        message: t(`${defaultContentPath}uploadSuccess`, { product: product }),
        placement: 'top',
        status: 'success'
      })
      setTimeout(() => setUploadSuccess(false), 4000)
    },
    [product, setValue, showToast, state, currentSection, t]
  )

  return (
    <>
      <MultipleFileUploadSection
        sectionTitle={t(`${defaultContentPath}label`)}
        items={items}
        onUpload={handleUpload}
        onDownload={handleDownload}
        success={uploadSuccess}
        progress={donwloadProgresses}
      />
      <ValidatedFileSelectModal
        title={t(`${defaultContentPath}modalTitle`, { product: product })}
        type={selectedFileType}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onSuccess={onUploadSuccess}
        whatif={true}
      />
    </>
  )
}
