import React, { useMemo } from 'react'
import InAndOutboundChart from './InAndOutboundChart'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { valueFormatter } from 'screens/Optimization/utils/output'
import { capitalize } from 'utils/string'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'

const Outbound = ({ outboundData, destinationData, selectedSubProducts }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID, selectedProducts } = useOptimizationContext()
  const transportationModeOrderMap = {
    rail: 2,
    truck: 4,
    internal: 3,
    barges: 1
  }

  const filteredBySelectedSubProducts = useMemo(() => {
    return selectedProducts.length > 0
      ? destinationData.filter((p) => selectedSubProducts?.includes(p.product))
      : destinationData
  }, [destinationData, selectedSubProducts, selectedProducts])

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'outbound_volume',
    data: filteredBySelectedSubProducts,
    rowLevelKeys: ['destination_type', 'asset_name'],
    filterProducts: true
  })

  return (
    <>
      <h3 className='text-lg font-bold mb-8'>
        {t('optimizationScreen.transportation.outbound.title')}
      </h3>
      {outboundData
        .sort(
          (a, b) =>
            transportationModeOrderMap[a.transportation_mode] -
            transportationModeOrderMap[b.transportation_mode]
        )
        .filter((d) => d.transportation_mode !== 'truck')
        .map(({ transportation_mode, data: volumes }) => (
          <React.Fragment key={transportation_mode}>
            <InAndOutboundChart
              transportationMode={transportation_mode}
              selectedSubProducts={selectedSubProducts}
              volumes={volumes}
            />
          </React.Fragment>
        ))}
      <div>
        <h3 className='text-base font-bold mb-6'>
          {t('optimizationScreen.transportation.outbound.destinations')}
        </h3>
        <MultiColumnsBoxTable
          showTitles
          expandable
          data={heatmapData}
          referenceProps={{
            title: t('optimizationScreen.comparison'),
            objKey: comparisonID
          }}
          comparedProps={{
            title: t('optimizationScreen.baseModel'),
            objKey: baseModelId
          }}
          groupLabelFormatter={transformDateToShortMonth}
          rowTitleFormatter={capitalize}
          columnValueFormatter={(val) =>
            val === NO_DATA_LABEL || !val ? NO_DATA_LABEL : valueFormatter(val)
          }
          referencePropKey={comparisonID}
        />
      </div>
      {outboundData
        .filter((d) => d.transportation_mode === 'truck')
        .map(({ transportation_mode, data: volumes }) => (
          <React.Fragment key={transportation_mode}>
            <InAndOutboundChart
              transportationMode={transportation_mode}
              selectedSubProducts={selectedSubProducts}
              volumes={volumes}
            />
          </React.Fragment>
        ))}
    </>
  )
}

export default Outbound
