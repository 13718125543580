import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import { transformDateToShortMonth } from 'screens/Optimization/utils'
import dayjs from 'dayjs'

const DateComponent = ({ data, info }) => {
  const MONTH_WIDTH_IN_PX = 128
  const getMonthLabel = (start_date, end_date) => {
    const sameMonth = dayjs(start_date).isSame(dayjs(end_date), 'month')

    if (sameMonth) {
      return transformDateToShortMonth(start_date)
    }
    return `${transformDateToShortMonth(
      start_date
    )} - ${transformDateToShortMonth(end_date)}`
  }

  const monthLength = MONTH_WIDTH_IN_PX

  const getDuration = (durationInDays) => {
    const result = (durationInDays * monthLength) / 30
    return result
  }

  return (
    // min width of months must match MONTH_WIDTH_IN_PX, tailwind can't handle variables properly
    <td key={data.index} className={`py-4 relative h-2 min-w-[128px]`}>
      {info.map((item, index) => {
        if (item[0]?.startDate !== data.month) {
          return <div key={index} className='h-7'></div>
        }
        const duration = dayjs(item[0]?.startDate).diff(
          dayjs(item[0].endDate),
          'days'
        )

        return (
          <div
            key={index}
            className={`mb-3 p-1 inline-flex items-center rounded-lg text-base text-primary-400 ${item.color} absolute h-7 justify-center`}
            role='alert'
            style={{
              width: `${getDuration(duration)}px`,
              minWidth: `${monthLength * 1.1}px`,
              top: item.type === 'bm' && '5px',
              bottom: item.type === 'cmp' && '0px'
            }}
          >
            <span className={`flex flex-row gap-1 ${item.textColor}`}>
              <Cog8ToothIcon className='block h-6 w-5' aria-hidden='true' />
            </span>
            <div>
              <p
                className={`px-1 py-1 text-sm font-bold flex items-center ${item.textColor}`}
              >
                {getMonthLabel(item[0].startDate, item[0].endDate)}
              </p>
            </div>
          </div>
        )
      })}
    </td>
  )
}

export default DateComponent
