import { EllipseFull } from 'components/Icons'

const LegendBody = ({ data, scatters = [], stackLabel }) => {
  const scatterData = scatters.reduce(
    (acc, { dataKey, ...scatter }) => ({ ...acc, [dataKey]: scatter }),
    {}
  )

  return (
    <ul className='flex gap-2.5'>
      {stackLabel && <span className='font-bold'>{stackLabel}:</span>}
      {data.map(({ dataKey, value, color }) => (
        <li key={`item-${dataKey}`}>
          {Object.keys(scatterData).includes(dataKey) ? (
            <div
              style={{
                width: 24,
                height: scatterData[dataKey].thickness ?? 4,
                backgroundColor: color
              }}
              className='mr-1 inline-block align-middle'
            />
          ) : (
            <EllipseFull
              fill={color}
              className='my-auto mr-1 inline-block align-middle'
            />
          )}
          {value}
        </li>
      ))}
    </ul>
  )
}

export const RenderLegend = ({ payload, scatters = [], stacks }) => {
  if (stacks) {
    return (
      <ul className='flex flex-col gap-4'>
        {stacks.map(({ stackId, label }) => {
          const data = payload.filter(
            ({ payload }) => payload.stackId === stackId
          )
          return (
            <LegendBody
              key={stackId}
              data={data}
              scatters={scatters}
              stackLabel={label}
            />
          )
        })}
      </ul>
    )
  }
  return <LegendBody data={payload} scatters={scatters} />
}
