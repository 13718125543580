import { useMemo, useState } from 'react'
import TableChart from 'components/Graphs/TableChart'
import useOutputGraph from '../useOutputGraph'
import useOutputTable from '../useOutputTable'
import {
  hasNoData,
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import NoData from 'components/Feedback/NoData'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const utilizationLabelFormatter = (value) =>
  value === NO_DATA_LABEL ? value : `${value}%`

const utilizationYAxisFormatter = (value) => `${value}%`

const SiloVolumeAndUtilization = ({ data }) => {
  const { t } = useLocalization()
  const [tab, setTab] = useState('volume')
  const { selectedProducts, selectedProductTypes } = useOptimizationContext()

  const capacityTypeToUse = useMemo(() => {
    if (selectedProductTypes.length > 1 || selectedProductTypes.length === 0) {
      return 'asset_capacity'
    } else if (selectedProducts.length === 1) {
      return 'storage_capacity'
    } else if (
      selectedProducts.length > 1 &&
      selectedProductTypes.length === 1
    ) {
      return 'product_type_capacity'
    }
  }, [selectedProductTypes, selectedProducts])

  const { summaryItems, bars, volumeChartData, utilizationGraph } =
    useOutputGraph(data, capacityTypeToUse)
  const { tableData, utilizationTableData } = useOutputTable(
    data,
    capacityTypeToUse
  )

  return !hasNoData(volumeChartData) ? (
    <TableChart
      title='Volume and utilization'
      tabs={[
        {
          value: 'volume',
          label: t('optimizationScreen.storage.volumeAndUtilization.volume')
        },
        { value: 'utilization', label: t('optimizationScreen.utilization') }
      ]}
      onTabChanged={setTab}
      summaryItems={tab === 'volume' ? summaryItems : []}
      data={tab === 'volume' ? volumeChartData : utilizationGraph}
      tooltipFormatter={
        tab === 'volume' ? labelFormatter : utilizationLabelFormatter
      }
      yAxisFormatter={
        tab === 'volume' ? yAxisFormatter : utilizationYAxisFormatter
      }
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      scatters={
        tab === 'volume'
          ? [
              {
                dataKey: 'capacity',
                fill: '#8E5602',
                name: t('optimizationScreen.capacity')
              }
            ]
          : []
      }
      bars={bars}
      tableData={tab === 'volume' ? tableData : utilizationTableData}
      tableProps={{
        referenceProps: { title: t('optimizationScreen.comparison') },
        comparedProps: { title: t('optimizationScreen.baseModel') },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter:
          tab === 'volume' ? valueFormatter : utilizationLabelFormatter,
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.storage')} />
  )
}

export default SiloVolumeAndUtilization
