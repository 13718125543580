const VariationVolumeCardSkeleton = () => {
  return (
    <div className='w-full px-5 py-3 flex flex-col justify-center border border-grey-200 rounded-lg shadow animate-pulse'>
      <div className='flex flex-row justify-center space-x-3 mb-2'>
        <div className='h-2.5 bg-gray-300 rounded-full w-20'></div>
        <div className='h-2.5 bg-gray-300 rounded-full w-5'></div>
      </div>
      <div className='flex flex-row justify-center'>
        <div className='h-2.5 bg-gray-200 rounded-full w-32 mb-2.5'></div>
      </div>
      <div className='flex justify-center mt-2.5'>
        <div className='border-r pr-4'>
          <div className='h-2.5 bg-gray-300 rounded-full w-10 mb-4'></div>
          <div className='w-16 h-2 bg-gray-200 rounded-full'></div>
        </div>
        <div className='ml-4'>
          <div className='h-2.5 bg-gray-300 rounded-full w-10 mb-4'></div>
          <div className='w-16 h-2 bg-gray-200 rounded-full'></div>
        </div>
      </div>
      <span className='sr-only'>Loading...</span>
    </div>
  )
}
export default VariationVolumeCardSkeleton
