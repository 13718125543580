import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'
import { useTemplate } from 'screens/Scenarios/useTemplate'

export const ProductRestriction = () => {
  const getTemplate = useTemplate()
  return (
    <SingleTemplateUpdateSection
      sectionTitle='Product restriction'
      title='Product restriction'
      templateType={getTemplate()}
    />
  )
}
