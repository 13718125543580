import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import * as RadixToast from '@radix-ui/react-toast'
import { Toast } from 'components/Feedback/Toast/Toast'

const ToastContext = createContext({})
ToastContext.displayName = 'ToastContext'

export const ToastProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [toast, setToast] = useState({})
  const timerRef = useRef(0)

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  const showToast = useCallback(({ duration = 3500, ...rest }) => {
    setToast(rest)
    setOpen(true)
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      setOpen(false)
    }, duration)
  }, [])

  const value = useMemo(() => ({ showToast }), [showToast])

  return (
    <ToastContext.Provider value={value}>
      <RadixToast.Provider swipeDirection='right'>
        <Toast open={open} {...toast} />
        {children}
      </RadixToast.Provider>
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
