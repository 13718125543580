import * as Dialog from '@radix-ui/react-dialog'
import './drawer.css'

const Drawer = ({ open, onOpenChange, children }) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Portal>
      <Dialog.Overlay className='DialogOverlay' />
      <Dialog.Content className='DialogContent'>{children}</Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
)

export default Drawer
