import {
  CircleStackIcon,
  TruckIcon,
  Squares2X2Icon,
  ChartBarIcon
} from '@heroicons/react/24/outline'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import classNames from 'classnames'

import { SiloIcon, Leafs, CrushingPlantIcon, ShipIcon } from 'components/Icons'
import { useLocalization } from 'providers/LocalizationProvider'
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid'
import { ServerIcon } from '@heroicons/react/24/outline'
import Button from 'components/Inputs/Button'
import { useState } from 'react'
import Tooltip from 'components/Tooltip'

export default function SideBar() {
  const { page, setPage } = useOptimizationContext()
  const { t, countryCode } = useLocalization()

  const tabs = [
    {
      name: t('optimizationScreen.pageNames.overview'),
      icon: <Squares2X2Icon className='w-6 h-6' />
    },
    {
      name: t('optimizationScreen.pageNames.financialOverview'),
      icon: <CircleStackIcon className='w-h h-6' />,
      separator: true
    },
    {
      name: t('optimizationScreen.pageNames.origination'),
      icon: <Leafs className='w-h h-6' />
    },
    {
      name: t('optimizationScreen.pageNames.ownership'),
      hidden: countryCode !== 'ARG',
      icon: (
        <span className='w-6 '>
          <ServerIcon />
        </span>
      )
    },
    {
      name: t('optimizationScreen.pageNames.storage'),
      icon: (
        <span className='w-6 '>
          <SiloIcon className='' />
        </span>
      )
    },
    {
      name: t('optimizationScreen.pageNames.transportation'),
      icon: <TruckIcon className='w-h h-6' />
    },
    {
      name: t('optimizationScreen.pageNames.crushing'),
      icon: (
        <span className='w-6 '>
          <CrushingPlantIcon className='' />
        </span>
      )
    },
    {
      name: t('optimizationScreen.pageNames.internalMarket'),
      icon: <ChartBarIcon className='w-h h-6' />
    },
    {
      name: t('optimizationScreen.pageNames.export'),
      icon: (
        <span className='w-6'>
          <ShipIcon className='w-h' />
        </span>
      )
    }
  ]

  const [slim, setSlim] = useState(false)

  return (
    <div
      className='sticky top-0 flex flex-col items-start justify-start pl-3 px-2 pt-4 bg-white'
      style={{
        height: 'calc(100vh - 4rem)'
      }}
    >
      <div className='flex flex-col gap-1.5'>
        {tabs
          .filter((tab) => !tab.hidden)
          .map(
            (tab, index) =>
              countryCode && (
                <SidebarNavItem
                  key={index}
                  tab={tab}
                  selected={page === index}
                  slim={slim}
                  onClick={() => setPage(index)}
                />
              )
          )}
      </div>
      <div className={classNames('fixed bottom-2', !slim && 'w-64 mx-4')}>
        <Button
          icon={slim}
          className='w-full text-grey-400 border-none'
          onClick={() => setSlim((prev) => !prev)}
        >
          <div className='flex flex-row gap-2 w-full justify-start'>
            <ChevronDoubleRightIcon
              className={classNames(
                'w-h w-6 transition',
                slim ? 'mx-1.5' : 'rotate-180'
              )}
            />
            {!slim && (
              <span className='text-base leading-normal'>
                {t('optimizationScreen.minimizeSidebar')}
              </span>
            )}
          </div>
        </Button>
      </div>
    </div>
  )
}

const SidebarNavItem = ({ tab, slim, selected, onClick }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div>
      {slim && (
        <div className='absolute w-0'>
          <Tooltip
            light
            show={showTooltip}
            className='relative ml-12 -mt-1 z-10 w-max'
          >
            {tab.name}
          </Tooltip>
        </div>
      )}
      <button
        className={classNames(
          'flex items-center justify-start flex-1 px-3 rounded-md bg:blue-50 hover:bg-blue-50',
          'transition-color ease-in-out duration-300',
          'disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer',
          selected ? 'bg-blue-100 text-blue-400' : 'text-grey-400',
          slim ? 'w-max' : 'w-64 mx-4'
        )}
        disabled={tab.disabled}
        onClick={onClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div
          className={classNames(
            'flex items-center rounded-md space-x-2 justify-start flex-1 py-2',
            {
              'text-blue-400': selected
            }
          )}
        >
          {tab.icon}
          {!slim && (
            <p className='text-base font-bold leading-normal'>{tab.name}</p>
          )}
        </div>
      </button>
      {tab.separator && (
        <div
          className={classNames(slim ? 'w-full' : 'w-64 mx-4', 'border-t my-1')}
        />
      )}
    </div>
  )
}
