import classNames from 'classnames'
import { useTableContext } from './TableContext'
import { emptyWrapper } from './CellComponents'

export const TableSubRowCell = ({
  bodyComponent: CellBody,
  rowKey,
  columnIdx,
  rowIdx,
  cellClassNames,
  mapperClassName,
  expandedSubRowClassNames,
  expandArrowPosition,
  hasSubRows,
  context,
  rowData,
  onClick,
  renderExpandedRow,
  borders,
  level
}) => {
  const {
    rowClassName,
    mappers,
    components: { RowExpandButtonComponent }
  } = useTableContext()

  const isExpanderIconCell =
    renderExpandedRow &&
    ((expandArrowPosition === 'right' && columnIdx === mappers.length - 1) ||
      (expandArrowPosition === 'left' && columnIdx === 0))

  const Wrapper = isExpanderIconCell ? RowExpandButtonComponent : emptyWrapper

  if (isExpanderIconCell && expandArrowPosition === 'right' && !hasSubRows) {
    return (
      <div
        key={`${rowKey}-${columnIdx}`}
        className={classNames(
          {
            'border-t': borders && rowIdx === 0
          },
          rowClassName(
            columnIdx,
            rowIdx,
            expandedSubRowClassNames ?? cellClassNames,
            mapperClassName,
            !!onClick,
            {}
          )
        )}
        onClick={onClick}
        style={{ gridColumn: columnIdx + 1 }}
      />
    )
  }

  return (
    <div
      key={`${rowKey}-${columnIdx}`}
      className={classNames(
        {
          'border-t': borders && rowIdx === 0
        },
        rowClassName(
          columnIdx,
          rowIdx,
          expandedSubRowClassNames ?? cellClassNames,
          mapperClassName,
          !!onClick,
          {}
        )
      )}
      onClick={onClick}
      style={{
        gridColumn: columnIdx + 1,
        paddingLeft:
          expandArrowPosition === 'left' && isExpanderIconCell
            ? `${level / 2}rem`
            : undefined
      }}
    >
      <Wrapper ctx={{ ...context, expandable: hasSubRows }} rowKey={rowKey}>
        <CellBody
          key={`${rowKey}-${columnIdx}-B`}
          item={rowData}
          ctx={context}
          rowIdx={rowIdx}
          columnIndex={columnIdx}
          rowKey={rowKey}
        />
      </Wrapper>
    </div>
  )
}
