import { useState } from 'react'
import TableChart from 'components/Graphs/TableChart'
import {
  NO_DATA_LABEL,
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import NoData from 'components/Feedback/NoData'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import useGraph from 'hooks/useGraph'
import useProductFilter from 'hooks/useProductFilter'
import useSummaryItems from 'hooks/useSummaryItems'
import { useLocalization } from 'providers/LocalizationProvider'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const calcCapacity = ({
  currentData,
  record: { execution_id, ...record },
  capacityKey
}) => {
  return currentData === NO_DATA_LABEL
    ? { [execution_id]: record[capacityKey] }
    : { ...currentData, [execution_id]: record[capacityKey] }
}

const PortDetailsVolumeAndUtilization = ({
  data,
  selectedSubProducts,
  capacityKey
}) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const [view, setView] = useState('volume')
  const { comparisonID } = useOptimizationContext()
  const { filteredData } = useProductFilter({ data, selectedSubProducts })
  const { summaryItems } = useSummaryItems({
    data: filteredData,
    volumeKey: 'volume',
    capacityKey,
    isUtilization: view === 'utilization'
  })

  const { volumeGraphData, bars, utilizationGraphData } = useGraph({
    data: filteredData,
    monthContextData: {
      capacity: calcCapacity
    },
    calcExecutionIdData,
    capacityKey,
    calculateUtilization: true
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: filteredData,
    filterProducts: true
  })

  return !hasNoData(volumeGraphData) ? (
    <TableChart
      title={t('optimizationScreen.export.volumeAndUtilization.export')}
      summaryItems={summaryItems}
      data={view === 'volume' ? volumeGraphData : utilizationGraphData}
      tooltipFormatter={
        view === 'volume'
          ? labelFormatter
          : (v) => (v === NO_DATA_LABEL ? v : `${v.toFixed(0)}%`)
      }
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      yAxisProps={{
        domain: view === 'utilization' && [0, () => 100],
        tickFormatter: view === 'volume' ? yAxisFormatter : (v) => `${v}%`
      }}
      tabs={[
        {
          label: t(
            'optimizationScreen.export.volumeAndUtilization.exportVolume'
          ),
          value: 'volume'
        },
        { label: t('optimizationScreen.utilization'), value: 'utilization' }
      ]}
      onTabChanged={setView}
      bars={bars}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: (val) =>
          val === NO_DATA_LABEL || !val ? NO_DATA_LABEL : valueFormatter(val),
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.export')} />
  )
}

export default PortDetailsVolumeAndUtilization
