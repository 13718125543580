import { SectionContainer } from './SectionContainer'
import WhatIfCard from 'components/Cards/WhatIf'

export const SingleFileUploadSection = ({
  sectionTitle,
  sectionDescription,
  title,
  description,
  onDownload,
  onUpload,
  disabled,
  progress
}) => (
  <SectionContainer
    sectionTitle={sectionTitle}
    sectionDescription={sectionDescription}
  >
    <WhatIfCard
      title={title}
      description={description}
      onUpload={onUpload}
      onDownload={onDownload}
      disabled={disabled}
      progress={progress}
    />
  </SectionContainer>
)
