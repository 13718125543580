import classNames from 'classnames'

const Button = ({
  variant = 'default',
  size = 'm',
  children,
  active,
  disabled,
  loading,
  className,
  icon,
  ...props
}) => (
  <button
    className={classNames(
      'rounded-md inline-flex items-center justify-center box-border cursor-pointer font-medium text-sm gap-3 whitespace-nowrap disabled:pointer-events-none disabled:cursor-default',
      'transition-colors duration-300 ease-in-out',
      {
        'bg-[#ECF2FF] text-[#3668D5] [&>*]:!stroke-[#3668D5] border-[#3668D5] pointer-events-none cursor-default':
          active && variant === 'default',
        '[&>*]:!stroke-[#6a717f]': disabled && variant === 'default',
        'bg-white border border-[#E4E8ED] border-solid text-[#1E2226] hover:bg-[#ECF2FF] disabled:bg-[#e4e6ea] disabled:border-0 disabled:text-[#6a717f]':
          variant === 'default',
        'bg-[#3668D5] text-white hover:bg-[#113587] disabled:bg-[#96b4e7]':
          variant === 'filled',
        'bg-[#ECF2FF] text-[#081B43] hover:bg-[#d9e4fb] disabled:bg-[#f2f6ff] disabled:text-[#7d8a9f]':
          variant === 'light',
        'text-[#081B43] hover:bg-[#d9e4fb] disabled:bg-[#f2f6ff] disabled:text-[#7d8a9f] border-none':
          variant === 'unstyled',
        'h-[42px] py-[9px] px-[17px] text-base': size === 'l',
        'h-[38px] py-[9px] px-[17px]': size === 'm',
        'h-[34px] py-[7px] px-[11px] rounded gap-2': size === 's'
      },
      {
        'py-[5px] px-[5px]': icon,
        'w-[42px]': icon && size === 'l',
        'w-[38px]': icon && size === 'm',
        'w-[34px]': icon && size === 's',
        '[&>*]:!stroke-none': icon && disabled
      },
      {
        'pointer-events-none cursor-default': loading
      },
      className
    )}
    disabled={disabled}
    {...props}
  >
    {loading ? 'Loading...' : children}
  </button>
)

export default Button
