import { useToast } from 'providers/ToastProvider'
import { createContext, useCallback, useContext, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSWRConfig } from 'swr'
import { getCurrentCountryCode } from 'utils'

const LocalizationContext = createContext({})
LocalizationContext.displayName = 'LocalizationContext'

export const availableLanguages = ['en', 'esp', 'pt']
export const countryOptions = [
  {
    label: 'Brazil',
    value: 'BR'
  },
  {
    label: 'Argentina',
    value: 'ARG'
  }
]

export const LocalizationProvider = ({ children }) => {
  const { t: translate, i18n } = useTranslation()
  const [countryCode, setCountryCode] = useState(getCurrentCountryCode())
  const { showToast } = useToast()
  const { mutate } = useSWRConfig()

  const onCountryChange = useCallback(
    (cc) => {
      setCountryCode(cc)
      try {
        window.localStorage.setItem('countryCode', cc)
        if (window.location.pathname !== '/') {
          window.location.href = '/'
        } else {
          mutate(() => true, undefined, {
            revalidate: true
          })
        }
      } catch (e) {
        console.error(e)
        showToast({
          message: 'Failed to select country',
          placement: 'top',
          status: 'error'
        })
      }
    },
    [mutate, showToast]
  )

  const onLanguageChange = useCallback(
    (lang) => {
      try {
        i18n.changeLanguage(lang)
        window.localStorage.setItem('lang', lang)
      } catch (e) {
        console.error(e)
        showToast({
          message: 'Failed to set language',
          placement: 'top',
          status: 'error'
        })
      }
    },
    [i18n, showToast]
  )

  const translateRef = useRef(translate)

  const t = useCallback(
    (key, options) => translateRef.current(key, options),
    []
  )

  const value = { t, onLanguageChange, countryCode, onCountryChange }

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  )
}

export const useLocalization = () => {
  const context = useContext(LocalizationContext)
  if (!context) {
    throw new Error('useLocalization must be used within LocalizationProvider')
  }
  return context
}
