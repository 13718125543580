import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import { valueFormatter } from 'screens/Optimization/utils/output'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

const TransshipmentDetails = ({ volumes, selectedSubProducts }) => {
  const { selectedProducts, comparisonID } = useOptimizationContext()
  const { id: baseModelId } = useParams()
  const { t } = useLocalization()

  const filteredBySelectedSubProducts = useMemo(() => {
    return selectedProducts.length > 0
      ? volumes.filter((p) => selectedSubProducts.includes(p.product))
      : volumes
  }, [volumes, selectedSubProducts, selectedProducts])

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: filteredBySelectedSubProducts,
    rowLevelKeys: [{ dataKey: 'asset', titleKey: 'origin_asset_name' }]
  })

  return (
    <div className='mb-10'>
      <h3 className='text-base font-bold'>
        {t('optimizationScreen.export.transshipment')}
      </h3>
      {heatmapData.length === 0 ? (
        <NoData page={t('optimizationScreen.pageNames.export')} />
      ) : (
        <MultiColumnsBoxTable
          showTitles
          expandable
          data={heatmapData}
          referenceProps={{
            title: t('optimizationScreen.comparison'),
            objKey: comparisonID
          }}
          comparedProps={{
            title: t('optimizationScreen.baseModel'),
            objKey: baseModelId
          }}
          groupLabelFormatter={transformDateToShortMonth}
          rowTitleFormatter={(v) => (v ? capitalize(v) : '-')}
          columnValueFormatter={(val) =>
            val === NO_DATA_LABEL || !val ? NO_DATA_LABEL : valueFormatter(val)
          }
          referencePropKey={comparisonID}
        />
      )}
    </div>
  )
}

export default TransshipmentDetails
