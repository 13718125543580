import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'
import { useTemplate } from 'screens/Scenarios/useTemplate'

export const OilExportDemand = () => {
  const getTemplate = useTemplate()
  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.crushing.oilExportDemand.'

  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}sectionTitle`)}
      title={t(`${defaultContentPath}title`)}
      description={t(`${defaultContentPath}description`)}
      templateType={getTemplate()}
      params={{
        crush_processed_demand: true
      }}
      modalTitle={t(`${defaultContentPath}modalTitle`)}
    />
  )
}
