import { useMemo, useCallback } from 'react'
import { capitalize } from 'utils/string'
import { formatNumber } from 'utils/number'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { NO_DATA_LABEL } from 'screens/Optimization/utils'
import { createMonthObject } from 'screens/Optimization/utils'
import { getProductColor } from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

//TODO: Refactor maybe make it into a global/generic something
const calcExecutionIdData = ({ currentData, product_ui_type, volume }) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const useOutputGraph = (data) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const allSilosSummaryByProduct = useMemo(() => {
    if (!data) {
      return {}
    }
    return data.reduce((acc, cur) => {
      if (!acc[cur.product_ui_type]) {
        acc[cur.product_ui_type] = {}
      }

      if (acc[cur.product_ui_type][cur.execution_id]) {
        acc[cur.product_ui_type][cur.execution_id] += cur.volume
      } else {
        acc[cur.product_ui_type][cur.execution_id] = cur.volume
      }

      return acc
    }, {})
  }, [data])

  const summaryItems = useMemo(() => {
    return Object.entries(allSilosSummaryByProduct).map(([product, volume]) => {
      return {
        title: capitalize(product),
        items: [
          {
            description: t('optimizationScreen.periodTotal'),
            value: formatNumber(volume[baseModelId], {
              maximumFractionDigits: 0
            })
          },
          {
            description: t('optimizationScreen.comparisonPeriodTotal'),
            value: formatNumber(volume[comparisonID], {
              maximumFractionDigits: 0
            })
          }
        ]
      }
    })
  }, [allSilosSummaryByProduct, baseModelId, comparisonID, t])

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData,
        availableCapacity: NO_DATA_LABEL,
        topContracts: NO_DATA_LABEL
      })
    },
    [start_date, end_date, baseModelId, comparisonID]
  )

  const volumeChartData = useMemo(() => {
    const result = data
      .filter((v) =>
        selectedProducts.length > 0
          ? selectedProducts.includes(v.product_ui_type)
          : true
      )
      .reduce(
        (
          acc,
          {
            month,
            execution_id,
            product_ui_type,
            volume,
            contracted_vol,
            contracted_and_spot_vol
          }
        ) => ({
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume
            }),
            topContracts:
              contracted_vol && execution_id === baseModelId
                ? acc[month].topContracts === NO_DATA_LABEL
                  ? contracted_vol
                  : acc[month].topContracts + contracted_vol
                : acc[month].topContracts,
            availableCapacity:
              contracted_and_spot_vol && execution_id === baseModelId
                ? acc[month].availableCapacity === NO_DATA_LABEL
                  ? contracted_and_spot_vol
                  : acc[month].availableCapacity + contracted_and_spot_vol
                : acc[month].availableCapacity
          }
        }),
        getMonthsWithDefaultData({
          executionData:
            selectedProducts.length > 0
              ? selectedProducts.reduce(
                  (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                  {}
                )
              : NO_DATA_LABEL
        })
      )

    return Object.entries(result).map(
      ([month, { availableCapacity, topContracts, ...volumes }]) => ({
        month,
        volumes,
        availableCapacity,
        topContracts
      })
    )
  }, [data, selectedProducts, getMonthsWithDefaultData, baseModelId])

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const bars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths, t]
  )

  return {
    summaryItems,
    selectedProducts,
    getMonthsWithDefaultData,
    volumeChartData,
    bars
  }
}

export default useOutputGraph
