import SectionBox from 'components/Container/SectionBox'
import PortDetailsVolumeAndUtilization from '../../../BR/Details/PortDetailsVolumeAndUtilization'
import InboundRoutes from '../../../BR/Details/InboundRoutes'
import { useLocalization } from 'providers/LocalizationProvider'

const Port = ({ selectedSubProducts, capacityKey, data }) => {
  const orderMap = {
    rail: 1,
    truck: 2,
    internal: 3,
    barges: 4
  }
  const { t } = useLocalization()

  return (
    <>
      <SectionBox>
        <PortDetailsVolumeAndUtilization
          data={data.exportSummary}
          selectedSubProducts={selectedSubProducts}
          capacityKey={capacityKey}
        />
      </SectionBox>
      <SectionBox>
        <h3 className='text-lg font-bold mb-8'>
          {' '}
          {t('optimizationScreen.export.portInboundRoutes')}{' '}
        </h3>
        {data.inbound
          .sort(
            (a, b) =>
              orderMap[a.transportation_mode] - orderMap[b.transportation_mode]
          )
          .map(({ transportation_mode, data }) => {
            return (
              <InboundRoutes
                key={transportation_mode}
                transportationMode={transportation_mode}
                volumes={data}
                selectedSubProducts={selectedSubProducts}
              />
            )
          })}
      </SectionBox>
    </>
  )
}

export default Port
