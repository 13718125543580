import { SingleVolumeCard } from 'components/Cards'
import BarChart from 'components/Graphs/BarChart'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import {
  labelFormatter,
  valueFormatter
} from 'screens/Optimization/utils/output'
import { capitalize } from 'utils/string'
import { useLocalization } from 'providers/LocalizationProvider'

export const Products = ({ data, onClick, view }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { countryCode, t } = useLocalization()

  const tableData = useMemo(() => {
    return Object.keys(data).reduce((acc, product) => {
      return [
        ...acc,
        {
          title: product,
          data: data[product].reduce((acc, d) => {
            return {
              ...acc,
              [d.month]: {
                [baseModelId]: d.volume[baseModelId],
                [comparisonID]: d.volume[comparisonID]
              }
            }
          }, {})
        }
      ]
    }, [])
  }, [data, comparisonID, baseModelId])

  return (
    <>
      <div className='w-full'>
        {view === 'chart' ? (
          <div className='grid grid-cols-3 gap-2'>
            {Object.keys(data)?.map((card) => {
              return (
                <SingleVolumeCard
                  key={card}
                  title={capitalize(card)}
                  onClick={() => onClick(card)}
                  disabled={countryCode === 'ARG'}
                >
                  <BarChart
                    variant='small'
                    data={data[card] || []}
                    bars={[
                      {
                        dataKey: `volume.${baseModelId}`,
                        fill: '#FAA628',
                        name: t('optimizationScreen.baseModel')
                      }
                    ]}
                    barProps={{
                      barSize: 5
                    }}
                    yAxisProps={{
                      dataKey: `volume.${baseModelId}`,
                      width: 35,
                      tickCount: 3,
                      tickFormatter: (val) => val / kgToTons
                    }}
                    cartesianGridProps={{ horizontal: true, vertical: false }}
                    xAxisProps={{
                      dataKey: 'month',
                      strokeWidth: 0,
                      tickFormatter: (val) => transformDateToShortMonth(val)[0]
                    }}
                    tooltipFormatter={labelFormatter}
                  />
                </SingleVolumeCard>
              )
            })}
          </div>
        ) : (
          <div className='mt-4'>
            <MultiColumnsBoxTable
              data={tableData}
              referenceProps={{
                title: t('optimizationScreen.comparison'),
                objKey: comparisonID
              }}
              comparedProps={{
                title: t('optimizationScreen.baseModel'),
                objKey: baseModelId
              }}
              groupLabelFormatter={transformDateToShortMonth}
              columnValueFormatter={valueFormatter}
              rowTitleFormatter={capitalize}
              referencePropKey={comparisonID}
            />
          </div>
        )}
      </div>
    </>
  )
}
