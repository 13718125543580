import { useState, useMemo } from 'react'
import SiloDetails from './SiloDetails/SiloDetails'
import AllSilos from './AllSilos'
import VolumeAndUtilization from './VolumeAndUtilization'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import SectionBox from 'components/Container/SectionBox'
import useProductFilter from 'hooks/useProductFilter'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import RotationRatio from './SiloDetails/Tabs/RotationRatio'
import Inbound from './Inbound'
import Outbound from './Outbound'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

export default function Storage() {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const [selectedSilo, setSelectedSilo] = useState(undefined)
  const { comparisonID, selectedSubProducts } = useOptimizationContext()

  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/storage`,
    { bestEstimateId: comparisonID }
  ])

  const { filteredData } = useProductFilter({
    data: data.allVolumes,
    selectedSubProducts
  })

  const siloSelectionOptions = useMemo(
    () =>
      Object.entries(
        filteredData.reduce(
          (acc, { asset, asset_name }) => ({
            ...acc,
            [asset]: asset_name
          }),
          {}
        )
      ).map(([value, label]) => ({ label, value })),
    [filteredData]
  )

  return (
    <>
      {selectedSilo ? (
        <SiloDetails
          data={data}
          selectedSilo={selectedSilo}
          setSelectedSilo={setSelectedSilo}
          siloSelectionOptions={siloSelectionOptions}
        />
      ) : (
        <OutputScreen
          title={t('optimizationScreen.pageNames.storage')}
          information
        >
          <SectionBox>
            <TabsComponent>
              <Tab
                value='inbound_volume'
                label={t(
                  'optimizationScreen.storage.siloDetails.inboundVolume'
                )}
              >
                <Inbound data={data.inbound} />
              </Tab>
              <Tab
                value='final_stock'
                label={t('optimizationScreen.storage.siloDetails.finalStock')}
              >
                <AllSilos data={data.allVolumes} />
              </Tab>
              <Tab
                value='outbound_volume'
                label={t(
                  'optimizationScreen.storage.siloDetails.outboundVolume'
                )}
              >
                <Outbound data={data.outbound} />
              </Tab>
              <Tab
                value='rotation_ratio'
                label={t(
                  'optimizationScreen.storage.siloDetails.rotationRatio'
                )}
              >
                <RotationRatio data={data.rotationRatio} />
              </Tab>
            </TabsComponent>
            <VolumeAndUtilization
              data={data}
              setSelectedSilo={setSelectedSilo}
            />
          </SectionBox>
        </OutputScreen>
      )}
    </>
  )
}
