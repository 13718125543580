import * as RadixAccordion from '@radix-ui/react-accordion'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import './accordion.css'
import classNames from 'classnames'

export const AccordionHeader = ({ children, className, ...props }) => (
  <RadixAccordion.Header {...props}>
    <RadixAccordion.Trigger
      className={classNames(
        'AccordionTrigger w-full flex justify-between items-center',
        className
      )}
    >
      {children}
      <ChevronDownIcon className='AccordionChevron w-5 h-5 text-gray-500' />
    </RadixAccordion.Trigger>
  </RadixAccordion.Header>
)

export const AccordionBody = ({ children, className, ...props }) => (
  <RadixAccordion.Content
    className={classNames('AccordionContent', className)}
    {...props}
  >
    {children}
  </RadixAccordion.Content>
)

export const AccordionItem = ({ children, className, ...props }) => (
  <RadixAccordion.Item
    className={classNames(
      'AccordionItem bg-white border border-gray-200 rounded-lg overflow-hidden',
      className
    )}
    {...props}
  >
    {children}
  </RadixAccordion.Item>
)

const Accordion = ({ children, ...props }) => {
  return (
    <RadixAccordion.Root collapsible {...props}>
      {children}
    </RadixAccordion.Root>
  )
}

export default Accordion
