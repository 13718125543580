import DesignSystemColors from './Colors'
import Graphs from './Graphs'
import DesignSystemTypography from './Typography'
import FormFields from './FormFields'
import Form from './Form'
import Cards from './Cards'
import Navigation from './Navigation'
import Timeline from './Timeline'
import Alert from './Tips/Alert'
import Tables from './Tables'
import Overlays from './Overlays'
import { FileSelectorDemo } from './FileSelector'
import Buttons from './Buttons'
import CustomIcons from './CustomIcons'

const DesignSystem = () => {
  return (
    <>
      <div className='pb-[400px] -mt-16 px-2'>
        <FormFields />
        <Form />
        <Buttons />
        <Navigation />
        <Overlays />
        <CustomIcons />
        <DesignSystemColors />
        <DesignSystemTypography />
        <Alert />
        <Graphs />
        <Tables />
        <Timeline />
        <Cards />
        <FileSelectorDemo />
      </div>
    </>
  )
}

export default DesignSystem
