import AllPorts from './AllPorts'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useSWR from 'swr'
import { useMemo, useState } from 'react'
import Select from 'components/Inputs/Select'
import MultiSelectComponent from 'components/Inputs/Select/MultiSelect'
import VolumeAndUtilization from './VolumeAndUtilization'
import useSubProductSelector from 'hooks/useSubProductSelector'
import useProductFilter from 'hooks/useProductFilter'
import ARGDetails from './Details'
import { formatter } from 'utils/string'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

export default function Export() {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const [selectedTerminal, setSelectedTerminal] = useState(undefined)
  const { options, onChange, selectedSubProducts } = useSubProductSelector()
  const { comparisonID, selectedProducts, selectedProductTypes } =
    useOptimizationContext()

  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/export`,
    {
      bestEstimateId: comparisonID
    }
  ])

  const { filteredData } = useProductFilter({
    data: data.detailedVolumes,
    selectedSubProducts
  })

  const terminalSelectorOptions = useMemo(
    () =>
      Object.entries(
        filteredData.reduce(
          (acc, { port_terminal, asset_name }) => ({
            ...acc,
            [port_terminal]: formatter(asset_name)
          }),
          {}
        )
      ).map(([value, label]) => ({ label, value })),
    [filteredData]
  )

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'outbound_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  if (selectedTerminal) {
    return (
      <OutputScreen
        title={ t('optimizationScreen.export.main.detailsForPort') }
        titleExtra={
          <>
            <Select
              variant='underlined'
              options={terminalSelectorOptions}
              value={selectedTerminal}
              onValueChange={setSelectedTerminal}
            />
            <span className='text-grey-400'>{ t('optimizationScreen.export.main.showing') }</span>
            {options.length === 0 ? (
              <span className='text-lg font-bold'>{ t('optimizationScreen.export.main.allProducts') }</span>
            ) : (
              <MultiSelectComponent
                variant='underlined'
                options={options}
                placeholder={ t('optimizationScreen.export.main.filter') }
                onChange={onChange}
                disabled={selectedProducts.length === 0}
              />
            )}
          </>
        }
        crumbs={[
          {
            onClick: () => setSelectedTerminal(undefined),
            label: t('optimizationScreen.export.main.export')
          },
          {
            label: t('optimizationScreen.export.main.portDetails')
          }
        ]}
      >
        <ARGDetails
          portAsset={selectedTerminal}
          selectedSubProducts={selectedSubProducts}
          capacityKey={capacityKey}
        />
      </OutputScreen>
    )
  }

  return (
    <OutputScreen
      title={ t('optimizationScreen.export.main.export') }
      titleExtra={
        <>
          <span className='text-grey-400'>{ t('optimizationScreen.export.main.for') }</span>
          {options.length === 0 ? (
            <span className='text-lg font-bold'>{ t('optimizationScreen.export.main.allProducts') }</span>
          ) : (
            <MultiSelectComponent
              variant='underlined'
              options={options}
              placeholder={ t('optimizationScreen.export.main.filter') }
              onChange={onChange}
              disabled={selectedProducts.length === 0}
            />
          )}
        </>
      }
    >
      <AllPorts data={filteredData} />
      <VolumeAndUtilization
        data={filteredData}
        onTerminalSelected={setSelectedTerminal}
        capacityKey={capacityKey}
      />
    </OutputScreen>
  )
}
