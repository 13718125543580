import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { useDatasetDownload } from 'hooks/useDatasetDownload'
import { useOutputDownload } from 'hooks/useOutputDownload'
import { useScenariosContext } from 'providers/ScenariosProvider'
import { useToast } from 'providers/ToastProvider'
import { useMemo, useState } from 'react'
import { SingleFileUploadSection } from 'screens/Scenarios/Main/sections/SingleFileUploadSection'
import { getFileNameFromTemplateType } from 'utils'
import { FILE_UPLOAD_SUCCESSFULLY } from 'utils/string'

export const SingleTemplateUpdateSection = ({
  sectionTitle,
  sectionDescription,
  title,
  description,
  successMessage = FILE_UPLOAD_SUCCESSFULLY,
  templateType,
  params,
  modalTitle
}) => {
  const [donwloadProgress, setDownloadProgess] = useState(undefined)
  const { executionData, setValue } = useScenariosContext()
  const downloadTemplate = useDatasetDownload(setDownloadProgess)
  const downloadBucket = useOutputDownload(setDownloadProgess)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const { showToast } = useToast()

  const fileId = useMemo(() => {
    return executionData?.data?.files?.find(
      (file) => file.type === templateType
    )?.id
  }, [executionData, templateType])

  const isBucketFile = useMemo(() => {
    return templateType.includes('WHATIF')
  }, [templateType])

  const getFolderStructureOptions = (templateType) => {
    switch (templateType) {
      case 'WHATIF_BR_SILOS':
        return 'references'
      case 'WHATIF_ARG_ACOPIOS_MASTER':
        return 'master'
      default:
        return 'output'
    }
  }

  const handleDownload = () => {
    const outputFileName = `${getFileNameFromTemplateType(templateType)}.xlsx`

    if (isBucketFile) {
      const folderStructureOption = getFolderStructureOptions(templateType)

      downloadBucket(outputFileName, executionData.id, folderStructureOption)
    } else {
      downloadTemplate(fileId, outputFileName)
    }
  }

  const handleUpload = () => {
    setUploadModalOpen(true)
  }

  const onUploadSuccess = (files) => {
    const valueObject = {
      files: files.reduce((acc, curr) => ({ ...acc, [curr.type]: curr.id }), {})
    }

    if (params) {
      valueObject.advancedSettings = params
    }

    setValue(valueObject)
    showToast({
      message: successMessage,
      placement: 'top',
      status: 'success'
    })
  }

  return (
    <>
      <SingleFileUploadSection
        sectionTitle={sectionTitle}
        sectionDescription={sectionDescription}
        title={title}
        description={description}
        onDownload={handleDownload}
        onUpload={handleUpload}
        progress={donwloadProgress}
      />
      <ValidatedFileSelectModal
        title={modalTitle}
        type={templateType}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onSuccess={onUploadSuccess}
        whatif={true}
      />
    </>
  )
}
