import { useMemo } from 'react'
import BarChart from 'components/Graphs/BarChart'
import { capitalize } from 'utils/string'
import useOutputGraph from '../useOutputGraph'
import {
  labelFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { SummaryCard } from 'components/Cards'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

const InAndOutboundChart = ({
  transportationMode,
  volumes,
  selectedSubProducts
}) => {
  const { t } = useLocalization()
  const { selectedProducts } = useOptimizationContext()
  const filteredBySelectedSubProducts = useMemo(() => {
    return selectedProducts.length > 0
      ? volumes.filter((p) => selectedSubProducts?.includes(p.product))
      : volumes
  }, [volumes, selectedSubProducts, selectedProducts])
  const { volumeChartData, bars, summaryItems } = useOutputGraph(
    filteredBySelectedSubProducts
  )

  return !hasNoData(volumeChartData) ? (
    <div className='mb-10'>
      <h3 className='text-base font-bold p-2'>{capitalize(transportationMode)}</h3>
      <div className='flex flex-wrap gap-4'>
        {summaryItems?.map(({ title, ...rest }) => (
          <SummaryCard key={title} title={title} {...rest} />
        ))}
      </div>
      <BarChart
        variant='default350'
        containerClassNames='!h-[250px] mt-6'
        data={volumeChartData}
        tooltipFormatter={labelFormatter}
        yAxisProps={{ tickFormatter: yAxisFormatter }}
        bars={bars}
        xAxisProps={{
          dataKey: 'month',
          tickFormatter: transformDateToShortMonth
        }}
      />
    </div>
  ) : (
    <NoData page={t('optimizationScreen.pageNames.transportation')} />
  )
}

export default InAndOutboundChart
