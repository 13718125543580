export const randomBoolean = () => Math.random() >= 0.5

export const formatCurrency = (value) => {
  if (value === undefined || value === null) return null
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
    .format(value)
    .replace('$', '')
}

export const formatFileSize = (size) => {
  if (!size) {
    return '0B'
  }
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = 0
  while (size >= 1024) {
    size /= 1024
    ++i
  }

  return size.toFixed(1) + units[i]
}

export const formatNumber = (value, options) =>
  new Intl.NumberFormat('en-US', options).format(value)

export const formatAsMillions = (value) =>
  value
    ? Math.abs(value) >= 100_000
      ? `${formatNumber(value / 1_000_000, {
          maximumFractionDigits: 1
        })} M`
      : formatNumber(value, {
          maximumFractionDigits: 0
        })
    : value

export const sumByProperty = (data, property) => {
  return data.reduce(
    (acc, val) =>
      (acc += typeof val[property] === 'string' ? 0 : val[property]),
    0
  )
}
