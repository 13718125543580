import { useNavigate } from 'react-router-dom'
import './header.css'

function HeaderLogo() {
  const navigate = useNavigate()

  const handler = (event) => {
    event.preventDefault()
    navigate('/')
  }

  return (
    <button onClick={handler} className='flex flex-shrink-0 items-center'>
      <img
        className='header-logo'
        href='/'
        src={'/img/image-19@2x.png'}
        alt='Bunge logo'
      />
    </button>
  )
}

export default HeaderLogo
