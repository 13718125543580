import Checkbox from 'components/Inputs/Checkbox'
import HeatMapTable from 'components/Tables/HeatMapTable'
import { useState } from 'react'

const months = ['Jan', 'Feb', 'Mar', 'Apr']
const makeRandomData = () => {
  return new Array(months.length)
    .fill(123)
    .map((v) => v + Math.round(Math.random() * 100 - 50))
}

const createRandomItem = (category, subItems) => {
  const bmData = makeRandomData()
  const origData = makeRandomData()

  return {
    id: Math.round(Math.random() * 100000000) + btoa(category),
    title: category,
    data: months.reduce(
      (acc, cur, idx) => ({
        ...acc,
        [cur]: { bm: bmData[idx], orig: origData[idx], be: 123 }
      }),
      {}
    ),
    subRows: subItems
  }
}

const expandedTestDataFactory = () => [
  createRandomItem('Sub Foo'),
  createRandomItem('Sub Bar', [
    createRandomItem('Sub Sub Foo', [
      createRandomItem('Sub Sub Sub Foo'),
      createRandomItem('Sub Sub Sub Bar')
    ]),
    createRandomItem('Sub Sub Bar')
  ]),
  createRandomItem('Sub Baz')
]

const testData = [
  createRandomItem('Foo', expandedTestDataFactory()),
  createRandomItem('Bar', expandedTestDataFactory()),
  createRandomItem('Baz')
]

export const heatmapTestData = testData

const HeatMapTableSection = () => {
  const [expandable, setExpandable] = useState(true)
  const [rowTitles, setRowTitles] = useState(true)
  const [showControls, setShowControls] = useState(true)
  const [showLegend, setShowLegend] = useState(true)
  const [heatmapLegend, setHeatmapLegend] = useState(true)

  return (
    <div>
      <div className='flex flex-row gap-3 mb-6 pb-4 border-b-2 border-solid'>
        <Checkbox
          label={'Rows expandable'}
          isChecked={expandable}
          onChange={() => setExpandable((prev) => !prev)}
        />
        <Checkbox
          label={'Show row titles'}
          isChecked={rowTitles}
          onChange={() => setRowTitles((prev) => !prev)}
        />
        <Checkbox
          label={'Show controls'}
          isChecked={showControls}
          onChange={() => setShowControls((prev) => !prev)}
        />
        <Checkbox
          label={'Show legend'}
          isChecked={showLegend}
          onChange={() => setShowLegend((prev) => !prev)}
        />
        <Checkbox
          label={'Heatmap legend'}
          disabled={!showLegend}
          isChecked={heatmapLegend}
          onChange={() => setHeatmapLegend((prev) => !prev)}
        />
      </div>

      <HeatMapTable
        comparedProps={[
          { objKey: 'bm', title: 'BM', legend: 'Base Model' },
          { objKey: 'orig', title: 'Orig.', legend: 'Origination' }
        ]}
        refProp={{ objKey: 'be', title: 'BE', legend: 'Best Estimate' }}
        data={testData}
        groups={months}
        rowTitles={rowTitles}
        expandable={expandable}
        showControls={showControls}
        showLegend={showLegend}
        heatmapLegend={heatmapLegend}
      />
    </div>
  )
}

export default HeatMapTableSection
