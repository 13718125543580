import { useCallback, useMemo } from 'react'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { NO_DATA_LABEL } from 'screens/Optimization/utils'
import { createMonthObject } from 'screens/Optimization/utils'

const useOutputTable = (data, capacityTypeToUse) => {
  const { id: baseModelId } = useParams()
  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const createEmptyHeatMapStructure = useCallback(() => {
    const months = createMonthObject(start_date, end_date, {
      [baseModelId]: NO_DATA_LABEL,
      [comparisonID]: NO_DATA_LABEL
    })

    if (selectedProducts.length > 0) {
      return selectedProducts.reduce(
        (acc, product) => ({
          ...acc,
          [product]: {
            title: product,
            data: months
          }
        }),
        {}
      )
    }

    return months
  }, [baseModelId, comparisonID, selectedProducts, end_date, start_date])

  const tableData = useMemo(() => {
    const filteredData = data.filter((v) =>
      selectedProducts.length > 0
        ? selectedProducts.includes(v.product_ui_type)
        : true
    )
    if (selectedProducts.length > 0) {
      return Object.values(
        filteredData.reduce(
          (acc, { month, execution_id, product_ui_type, volume }) => ({
            ...acc,
            [product_ui_type]: {
              ...acc[product_ui_type],
              data: {
                ...acc[product_ui_type].data,
                [month]: {
                  ...acc[product_ui_type].data[month],
                  [execution_id]: volume
                }
              }
            }
          }),
          createEmptyHeatMapStructure()
        )
      )
    }

    const mappedData = filteredData.reduce(
      (acc, { month, execution_id, volume }) => ({
        ...acc,
        [month]: {
          ...acc[month],
          [execution_id]:
            acc[month][execution_id] === NO_DATA_LABEL
              ? volume
              : acc[month][execution_id] + volume
        }
      }),
      createEmptyHeatMapStructure()
    )

    return [{ data: mappedData }]
  }, [selectedProducts, data, createEmptyHeatMapStructure])

  const utilizationTableData = useMemo(
    () =>
      tableData.map(({ title: product, data: productData }) => {
        const mappedData = Object.entries(productData).map(
          ([month, monthlyData]) => {
            const utilization = Object.entries(monthlyData).map(
              ([executionId, volume]) => {
                const rawData = data.find(
                  (e) =>
                    e.execution_id === executionId &&
                    e.month === month &&
                    (selectedProducts.length > 0
                      ? e.product_ui_type === product
                      : true)
                )
                return [
                  executionId,
                  rawData
                    ? ((volume / rawData[capacityTypeToUse]) * 100).toFixed(0)
                    : NO_DATA_LABEL
                ]
              }
            )
            return {
              month,
              utilization: utilization.reduce(
                (acc, [executionId, util]) => ({ ...acc, [executionId]: util }),
                {}
              )
            }
          }
        )
        return {
          title: product,
          data: mappedData.reduce(
            (acc, { month, utilization }) => ({ ...acc, [month]: utilization }),
            {}
          )
        }
      }),
    [capacityTypeToUse, selectedProducts, data, tableData]
  )

  return {
    tableData,
    utilizationTableData
  }
}

export default useOutputTable
