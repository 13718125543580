import InfeasibleRun from './InfeasibleRun'
import ErrorBoundary from 'components/ErrorBoundary'

const InfeasibleRunWrapper = () => {
  return (
    <ErrorBoundary
      fallback={<div>Error at screens/Optimization/InfeasibleRun</div>}
    >
      <InfeasibleRun />
    </ErrorBoundary>
  )
}

export default InfeasibleRunWrapper
