import BarChart from 'components/Graphs/BarChart'
import { capitalize } from 'utils/string'
import NoData from 'components/Feedback/NoData'
import useGraph from 'hooks/useGraph'
import {
  hasNoData,
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import {
  labelFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const InAndOutboundChart = ({ transportationMode, data }) => {
  const { t } = useLocalization()
  const { bars, volumeGraphData } = useGraph({
    data,
    calcExecutionIdData
  })

  return (
    <div className='mb-10'>
      {!hasNoData(volumeGraphData) ? (
        <>
          <h3 className='text-base font-bold'>
            {capitalize(transportationMode)}
          </h3>
          <BarChart
            variant='default350'
            containerClassNames='!h-[250px] mt-6'
            data={volumeGraphData}
            tooltipFormatter={labelFormatter}
            yAxisProps={{ tickFormatter: yAxisFormatter }}
            bars={bars}
            xAxisProps={{
              dataKey: 'month',
              tickFormatter: transformDateToShortMonth
            }}
          />
        </>
      ) : (
        <NoData page={t('optimizationScreen.pageNames.storage')} />
      )}
    </div>
  )
}

export default InAndOutboundChart
