import { useMemo } from 'react'
import useOutputCrushingProductDetailsOutbound from 'hooks/output/useOutputCrushing/useOutputCrushingProductDetailsOutbound'
import Select from 'components/Inputs/Select'
import TableChart from 'components/Graphs/TableChart'
import { capitalize } from 'lodash'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import MultiGraphsTable from 'components/Tables/MultiGrapshTable.jsx'
import Outbound from '../Storage/BR/SiloDetails/Outbound'
import useSWR from 'swr'
import SectionBox from 'components/Container/SectionBox'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

const CrushingPlantProductDetails = ({
  goback,
  selectedProduct,
  setSelectedProduct
}) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { t } = useLocalization()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/crushing_product_details`,
    {
      bestEstimateId: comparisonID,
      product: selectedProduct
    }
  ])
  const {
    productVolumesChartData,
    productVolumeBars,
    productsSelectOptions,
    productByPlants
  } = useOutputCrushingProductDetailsOutbound(data, selectedProduct)

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: data.volumes,
    filterProducts: true
  })

  const outboundData = useMemo(() => {
    return (
      data.inOut.find((d) => d.in_out_direction === 'Out')
        ?.transportation_modes || []
    )
  }, [data])

  return (
    <OutputScreen
      title={t('optimizationScreen.crushing.plantProductDetails.title')}
      titleExtra={
        <Select
          options={productsSelectOptions}
          variant='underlined'
          value={selectedProduct}
          onValueChange={setSelectedProduct}
        />
      }
      crumbs={[
        { label: t('optimizationScreen.pageNames.crushing'), onClick: goback },
        {
          label: t('optimizationScreen.crushing.plantProductDetails.menuTitle'),
          onClick: goback
        }
      ]}
    >
      <SectionBox
        pageLangKey='optimizationScreen.pageNames.crushing'
        hasData={!hasNoData(productVolumesChartData)}
      >
        <TableChart
          title='All Plants'
          tabs={[
            {
              value: 'crush_volume',
              label: t('optimizationScreen.crushVolume')
            }
          ]}
          data={productVolumesChartData}
          bars={productVolumeBars}
          tableData={heatmapData}
          tooltipFormatter={labelFormatter}
          yAxisFormatter={yAxisFormatter}
          xAxisProps={{
            dataKey: 'month',
            tickFormatter: transformDateToShortMonth
          }}
          tableProps={{
            referenceProps: {
              title: t('optimizationScreen.comparison'),
              objKey: comparisonID
            },
            comparedProps: {
              title: t(
                'optimizationScreen.crushing.plantProductDetails.baseModel'
              ),
              objKey: baseModelId
            },
            groupLabelFormatter: transformDateToShortMonth,
            columnValueFormatter: valueFormatter,
            rowTitleFormatter: capitalize
          }}
        />
      </SectionBox>

      <SectionBox>
        <h2 className='text-xl pt-6 font-bold mr-1'>
          {t('optimizationScreen.crushing.plantProductDetails.plants')}
        </h2>
        <div className='mt-16 flex flex-col rounded-lg border border-gray-300'>
          {productByPlants.map((plant) => (
            <MultiGraphsTable
              data={plant.data}
              key={plant.crush}
              title={capitalize(plant.asset_name)}
              total={labelFormatter(plant.total)}
              bars={productVolumeBars}
              tooltipFormatter={labelFormatter}
              yAxisProps={{ tickFormatter: yAxisFormatter }}
              xAxisProps={{
                dataKey: 'month',
                tickFormatter: transformDateToShortMonth
              }}
            />
          ))}
        </div>
      </SectionBox>
      <SectionBox>
        <Outbound
          outboundData={outboundData}
          destinationData={data.destination}
        />
      </SectionBox>
    </OutputScreen>
  )
}

export default CrushingPlantProductDetails
