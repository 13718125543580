import { useState } from 'react'
import { Options } from 'vis'
import { useGraphStyleBehavior } from '../../utils/useGraphStyleBehavior'

export const useOptions = () => {
  const { nodeStyles, edgeStyles } = useGraphStyleBehavior()
  const [options] = useState<Options>({
    height: '100%',
    width: '100%',

    layout: {
      improvedLayout: true,
      clusterThreshold: 2000,
      hierarchical: {
        enabled: true,
        blockShifting: false,
        edgeMinimization: false,
        parentCentralization: true,
        levelSeparation: 180,
        nodeSpacing: 190
      }
    },
    edges: {
      ...edgeStyles.default,
      length: 0,
      physics: true,
      hoverWidth: 3,
      scaling: {
        min: 5
      }
    },
    nodes: {
      ...nodeStyles.default,
      fixed: false,
      physics: true,
      scaling: {
        label: true
      }
    },
    interaction: {
      selectConnectedEdges: false,
      hover: true,
      hoverConnectedEdges: false
    },
    physics: {
      enabled: false,
      solver: 'hierarchicalRepulsion',
      hierarchicalRepulsion: {
        centralGravity: 0.0,
        springConstant: 0.001
      },
      stabilization: {
        enabled: true,
        iterations: 2000
      }
    }
  })

  return {
    options
  }
}
