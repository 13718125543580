import Port from './Tabs/Port'
import Storage from './Tabs/Storage'
import Crushing from './Tabs/Crushing/index'
import useSWR from 'swr'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { useLocalization } from 'providers/LocalizationProvider'

const PORT = 'port'
const STORAGE = 'storage'
const CRUSHING = 'crushing'

const ARGDetails = ({ portAsset, selectedSubProducts, capacityKey }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { t } = useLocalization()

  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/export_details`,
    {
      asset: portAsset,
      bestEstimateId: comparisonID
    }
  ])

  return (
    <>
      <TabsComponent>
        <Tab value={PORT} label={t('optimizationScreen.export.details.port')}>
          <Port
            selectedSubProducts={selectedSubProducts}
            capacityKey={capacityKey}
            data={data.port}
          />
        </Tab>
        <Tab
          value={STORAGE}
          label={t('optimizationScreen.export.details.storage')}
        >
          <Storage
            data={data.storage}
            selectedSubProducts={selectedSubProducts}
          />
        </Tab>
        <Tab
          value={CRUSHING}
          label={t('optimizationScreen.export.details.crushing')}
        >
          <Crushing
            data={data.crushing}
            selectedSubProducts={selectedSubProducts}
          />
        </Tab>
      </TabsComponent>
    </>
  )
}

export default ARGDetails
