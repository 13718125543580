import classNames from 'classnames'
import AdvancedSettingCard from 'components/Cards/AdvancedSettingCard'
import { useLocalization } from 'providers/LocalizationProvider'
import { useMemo } from 'react'
import SettingCard from '../../../../components/Cards/SettingCard'

const cardKeys = {
  BR: [
    'origination_plan',
    'export_plan',
    'crushing_plan',
    'grains_domestic_demand',
    'crush_processed_demand',
    'rail_transportation_plan',
    'crushing_maintenance_plan',
    'hidro_transportation_plan',
    'crush_alcance',
    'ports_spot_flow',
    'allow_silo_fob_volume',
    'sold_vessels',
    'asset_product_expiration_time',
    'min_end_of_stock',
    'product_expiration_time',
    'allow_constraints_violation',
    'asset_end_of_stock'
  ],
  ARG: [
    'origination_plan',
    'export_plan',
    'crushing_plan',
    'crushing_maintenance_plan',
    'min_end_of_stock',
    'crush_processed_demand',
    'hidro_transportation_plan',
    'product_expiration_time',
    'asset_product_expiration_time',
    'allow_constraints_violation',
    'allow_broker_crush'
  ]
}

const AdvancedSettings = ({ type }) => {
  const { countryCode, t } = useLocalization()

  const keys = useMemo(() => cardKeys[countryCode] || [], [countryCode])

  return (
    <div className='flex flex-auto flex-wrap gap-4 mb-4'>
      {keys.map((key, _, array) => {
        return (
          <div
            className={classNames('basis-full grow lg:basis-2/6', {
              'last:grow-[.24]': array.length % 2 !== 0
            })}
            key={key}
          >
            {type === 'Advanced settings' ? (
              <AdvancedSettingCard
                controlName={key}
                title={t(
                  `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${key}.title`
                )}
                description={t(
                  `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${key}.description`
                )}
                description_supporting={t(
                  `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${key}.description_supporting`
                )}
              />
            ) : (
              <SettingCard
                controlName={key}
                title={t(
                  `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${key}.title`
                )}
                description={t(
                  `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${key}.description`
                )}
                description_supporting={t(
                  `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${key}.description_supporting`
                )}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AdvancedSettings

