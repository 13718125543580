import { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { ControlledCheckbox } from '../Inputs/Checkbox'
import { useUserInputsContext } from 'providers/UserInputsProvider'
import ControlledSwitcher from 'components/Inputs/Switchers/ControlledSwitcher'
import isUndefined from 'lodash/isUndefined'
import { Trans } from 'react-i18next'
import MultiRangeSlider from '../Inputs/MultiRangeSlider/MultiRangeSlider'
import ControlledMultiRangeSlider from '../Inputs/MultiRangeSlider/ControlledMultiRangeSlider'
import { areAllRangesIdentical } from '../../utils'

const productMappings = {
  corn: { friendlyName: 'Corn', minColor: '#FCD6BF', maxColor: '#F6853E' },
  soy: { friendlyName: 'Soy', minColor: '#C1D5FF', maxColor: '#3668D5' },
  wheat: { friendlyName: 'Wheat', minColor: '#CAC5EC', maxColor: '#5F50C7' },
  sunflower: { friendlyName: 'Sunflower', minColor: '#9FD770', maxColor: '#7DC341' },
};

const productKeyEquivalences = {
  P_RAW_GIRASOL: 'sunflower',
  P_RAW_MAIZ: 'corn',
  P_RAW_TRIGO: 'wheat',
  P_RAW_SOJA: 'soy',
  P_RAW_SOJA_AT: 'soy',
};

function mapProductKey(key) {
  const mappedKey = productKeyEquivalences[key] || key;
  return productMappings[mappedKey] || { friendlyName: key, minColor: undefined, maxColor: undefined };
}

/**
 * @param {string} controlName
 * @param {string} title
 * @param {string} description
 * @param {string} className
 * @returns {JSX.Element}
 */
const AdvancedSettingCard = ({
  controlName,
  title,
  description,
  description_supporting,
  className,
  ...props
}) => {
  const { control, watch, getValues, resetField, setValue } = useUserInputsContext()

  const switcherControl = `advancedSettings.${controlName}.checked`
  const sliderControl = `advancedSettings.${controlName}.slider`
  const allowUnmetControl = `advancedSettings.${controlName}.allowUnmetValue`
  const configureProductsControl = `advancedSettings.${controlName}.configureProducts`
  const isConfigureProductsActive = watch(configureProductsControl)

  const hasSlider = !isUndefined(getValues(sliderControl))
  const hasAllowUnmet = !isUndefined(getValues(allowUnmetControl))
  const isSwitcherActive = watch(switcherControl)
  const sliderValue = hasSlider ? watch(sliderControl) : null

  const originationRangeProductsControl = `advancedSettings.${controlName}.origination_range_products`
  const hasOriginationRangeProducts = !isUndefined(getValues(originationRangeProductsControl))

  const originationRangeProductsValues = hasOriginationRangeProducts ? watch(originationRangeProductsControl) : null

  const [generalValues, setGeneralValues] = useState([5, 5]);

  useEffect(() => {
    const productValues = originationRangeProductsValues ? Object.values(originationRangeProductsValues) : []
    if (productValues.length) {
      if (areAllRangesIdentical(productValues)) {
        setGeneralValues(productValues[0]);
        setValue(configureProductsControl, false);
      } else {
        setValue(configureProductsControl, true);
      }
    }
  }, [originationRangeProductsValues, setValue, configureProductsControl]);

  const handleMinMaxSliderValuesChange = (key, index, newValue) => {
    if (key === 'general') {
      const newGeneralValues = [...generalValues];
      newGeneralValues[index] = newValue[0];

      setGeneralValues(newGeneralValues);

      Object.keys(originationRangeProductsValues).forEach(key => {
        setValue(`${originationRangeProductsControl}.${key}`, newGeneralValues);
      });
    } else {
      const newValues = [...originationRangeProductsValues[key]];
      newValues[index] = newValue[0];
      setValue(`${originationRangeProductsControl}.${key}`, newValues);
    }
  };

  const isOriginationPlan = controlName === 'origination_plan'

  const showSlider = useMemo(() => {
    return hasSlider && isSwitcherActive
  }, [isSwitcherActive, hasSlider])

  const sliderValues = useMemo(() => {
    if (hasSlider) {
      const sliderProps = getValues(sliderControl)
      return {
        min: sliderProps.min,
        isUnlimited: sliderProps.isUnlimited,
        max: sliderProps.max,
        default: sliderProps.default
      }
    }
  }, [hasSlider, getValues, sliderControl])

  const cardDescription =
    isSwitcherActive && hasAllowUnmet ? description_supporting : description

  const handleReset = () => {
    resetField(`${sliderControl}.value`)

    if (originationRangeProductsValues) {
      setGeneralValues([5, 5])
      Object.keys(originationRangeProductsValues).forEach(key => {
        setValue(`${originationRangeProductsControl}.${key}`, [5, 5]);
      });
    }
  }

  return (
    <div
      className={classNames(
        'w-full border rounded-md flex flex-col justify-between pt-10 h-full',
        className,
        showSlider ? 'row-span-2' : ''
      )}
      {...props}
    >
      <div className='px-4'>
        <div className='flex justify-between mb-4'>
          <h3 className='font-bold text-md'>{title}</h3>
          <ControlledSwitcher
            controllerProps={{
              name: switcherControl,
              control
            }}
            disabled={false}
          />
        </div>

        {isOriginationPlan ? (
          <>
            <p className='mt-3 text-grey-400 text-sm mb-5'>
              <Trans>{cardDescription}</Trans>
            </p>
            {showSlider ? (
              <>
                <ControlledCheckbox
                  valueKey='value'
                  controllerProps={{
                    name: configureProductsControl,
                    control
                  }}
                  disabled={false}
                  label='Configure the products'
                />
                <p className='text-sm text-gray-400'>You will configure each product individually</p>
                {isConfigureProductsActive && originationRangeProductsValues ? (
                  Object.entries(originationRangeProductsValues).map(([key, [minValue, maxValue]]) => {
                    const { friendlyName, maxColor, minColor } = mapProductKey(key);
                    return (
                      <div key={key} className='py-4 mb-10'>
                        <p className='text-xs select-none font-bold -mb-4'> {friendlyName} </p>
                        <MultiRangeSlider
                          values={[minValue]}
                          defaultValues={[minValue]}
                          onValueChange={(newValue) => handleMinMaxSliderValuesChange(key, 0, newValue)}
                          maxColor={minColor}
                          label='Lower'
                        />
                        <br />
                        <MultiRangeSlider
                          values={[maxValue]}
                          defaultValues={[maxValue]}
                          onValueChange={(newValue) => handleMinMaxSliderValuesChange(key, 1, newValue)}
                          maxColor={maxColor}
                          label='Upper'
                          className='mb-0 pt-4'
                        />
                      </div>
                    )
                  })
                ) : (
                  <>
                    <MultiRangeSlider
                      key='general-0'
                      values={[generalValues[0]]}
                      defaultValues={[generalValues[0]]}
                      label='Lower'
                      onValueChange={(newValue) => handleMinMaxSliderValuesChange('general', 0, newValue)}
                      maxColor='#C836D5'
                    />
                    <MultiRangeSlider
                      key='general-1'
                      values={[generalValues[1]]}
                      defaultValues={[generalValues[1]]}
                      onValueChange={(newValue) => handleMinMaxSliderValuesChange('general', 1 , newValue)}
                      label='Upper'
                    />
                  </>
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            {hasAllowUnmet && (
              <ControlledCheckbox
                valueKey='value'
                controllerProps={{
                  name: allowUnmetControl,
                  control
                }}
                disabled={false}
                label='Allow unmet value'
              />
            )}
            <p className='mt-3 text-grey-400 text-sm mb-10'>
              <Trans>{cardDescription}</Trans>
            </p>
            {showSlider && (
              <>
                <p className='font-semibold text-md'>Allowed range variation:</p>
                <ControlledMultiRangeSlider
                  key=''
                  min={sliderValues.min}
                  max={sliderValues.max}
                  controllerProps={{
                    name: `${sliderControl}.value`,
                    control
                  }}
                  isUnlimited
                />
              </>
            )}
          </>
        )}
      </div>
      {showSlider && sliderValue !== sliderValues.default && (
        <div className='w-full rounded-b-md mt-10 p-2 px-4 bg-background text-right'>
          <button
            className='underline text-blue-400 font-bold text-sm cursor-pointer'
            onClick={handleReset}
          >
            Clear data
          </button>
        </div>
      )}
    </div>
  )
}

export default AdvancedSettingCard

