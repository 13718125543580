import { useCallback, useMemo } from 'react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'
import useSwr from 'swr'
import Nav from 'components/Navigation/Tabs/TabsNav'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useLocalization } from 'providers/LocalizationProvider'

const NEW_SCENARIO = 'NEW_SCENARIO'

const tabSorter = (a, b) => {
  if (a.baseRun && !b.baseRun) {
    return -1
  }

  return a.updatedAt - b.updatedAt
}

const TabsNav = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: tabData } = useSwr(`/execution/${id}/tabs`)
  const { executionData } = useOptimizationContext()
  const { t } = useLocalization()

  const tabs = useMemo(() => {
    return [
      ...(tabData
        ?.sort(tabSorter)
        ?.filter((x) => x.status.toLowerCase() === 'success')
        ?.map(({ id, name }) => ({
          value: id,
          label: name
        })) || []),
      {
        value: NEW_SCENARIO,
        label: t('execution.tabs.newScenario'),
        disabled: executionData.outdated,
        tooltip: executionData.outdated
          ? t('execution.tabs.newScenarioDisabledMessage')
          : undefined,
        component: (
          <div className='flex gap-1 items-center'>
            <PlusIcon className='w-6 h-6' size={2} />
            New Scenario
          </div>
        )
      }
    ]
  }, [tabData, executionData, t])

  /*   const baseRunId = useMemo(
    () => tabData?.find((x) => x.baseRun)?.id || id,
    [tabData, id]
  ) */

  const onTabChange = useCallback(
    (value) => {
      if (value === NEW_SCENARIO) {
        navigate(`/execution/${id}/scenario`)
        return
      }

      navigate(`/execution/${value}`)
    },
    [id, navigate]
  )

  return (
    <div className='w-full overflow-x-auto h-auto'>
      <Nav tabs={tabs} value={id} onChange={onTabChange} />
    </div>
  )
}

export default TabsNav
