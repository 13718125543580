import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useSWR from 'swr'
import { useCallback, useEffect, useMemo, useState } from 'react'

//Optimized
const EXEC_DATA_KEY = 'execution'
//Baseline
const COMP_DATA_KEY = 'comparable'

export const useOwnershipData = () => {
  const { id } = useParams()
  const { comparisonID, selectedProducts } = useOptimizationContext()
  const [hasSelectedsProduct, setHasSelectedProducts] = useState(false)

  const {
    data: { ownershipCurve: ownershipData }
  } = useSWR([
    `/execution/outputs/${id}/ownership`,
    { bestEstimateId: comparisonID }
  ])

  const filterBySelectedProduct = useCallback(
    (dataSetKey) =>
      selectedProducts.length
        ? ownershipData[dataSetKey].filter((element) =>
            selectedProducts.includes(element.product_ui_type)
          )
        : ownershipData[dataSetKey],
    [ownershipData, selectedProducts]
  )

  const groupByMonth = (ownershipDataSet, datakey) =>
    ownershipDataSet.reduce((acc, currentProduct) => {
      const [year, month, day] = currentProduct.month.split('-')
      const currentDateString = `${month}/${day}/${year}`
      return {
        ...acc,
        [currentDateString]: acc[currentDateString]
          ? acc[currentDateString] + currentProduct[datakey]
          : currentProduct[datakey]
      }
    }, {})

  const calcMinOwnership = useCallback(
    (onwershipDataSet) =>
      onwershipDataSet.reduce((acc, current) => {
        return [...acc, { value: current }]
      }, []),
    []
  )

  const calcMaxOwnership = useCallback(
    (onwershipDataSet) =>
      onwershipDataSet.reduce((acc, current) => {
        return [...acc, { value: current }]
      }, []),
    []
  )

  const getMaxHeatMapValuePerMonth = (ownershipDataSet) => {
    return Object.entries(ownershipDataSet).reduce((acc, [_, value]) => {
      if (!acc) {
        return value
      }
      return acc < value ? value : acc
    }, null)
  }

  const getMinHeatMapValuePerMonth = (ownershipDataSet) => {
    return Object.entries(ownershipDataSet).reduce((acc, [_, value]) => {
      if (!acc) {
        return value
      }
      return acc > value ? value : acc
    }, null)
  }

  const toFullMonth = useCallback(
    (month) => new Date(month).toLocaleString('es-AR', { month: 'long' }),
    []
  )

  const toAbbreviatedMonth = useCallback(
    (month) => new Date(month).toLocaleString('es-AR', { month: 'short' }),
    []
  )
  const toKton = useCallback((value) => value / 1000, [])
  const toFormattedKton = useCallback(
    (value) =>
      new Intl.NumberFormat('es-AR', { maximumFractionDigits: 0 }).format(
        value / 1000
      ),
    []
  )

  const calcOwnershipTableTotalValue = useCallback(
    (ownershiptDataByMonth) =>
      Object.values(ownershiptDataByMonth).reduce(
        (acc, value) => ({
          ...acc,
          total: acc.total + value
        }),
        { total: 0 }
      ),
    []
  )

  const calcOwnershipTableValuesByMonth = useCallback(
    (ownershiptDataByMonth) =>
      Object.entries(ownershiptDataByMonth).reduce(
        (acc, [month, value]) => ({
          ...acc,
          [toAbbreviatedMonth(month)]: toKton(value)
        }),
        {}
      ),
    [toAbbreviatedMonth, toKton]
  )

  const getTableHeadersFromChartData = (chartData) =>
    chartData && Object.values(chartData).map(({ month }) => month)

  const filteredComparisonTonsByProduct = useMemo(() => {
    const data = filterBySelectedProduct(COMP_DATA_KEY)
    if (data)
      return data.reduce((acc, currentElement) => {
        return {
          ...acc,
          [currentElement.product_ui_type]: acc[currentElement.product_ui_type]
            ? acc[currentElement.product_ui_type] +
              currentElement.material_entregado_a_fijar
            : currentElement.material_entregado_a_fijar
        }
      }, {})
  }, [filterBySelectedProduct])

  const summaryCardsData = useMemo(() => {
    return Object.entries(filteredComparisonTonsByProduct).map(
      ([productKey, totalValue]) => ({
        title: productKey,
        value: toFormattedKton(totalValue),
        valueUnit: 'kTon'
      })
    )
  }, [filteredComparisonTonsByProduct, toFormattedKton])

  const merchandiseData = useMemo(() => {
    const BAR_DATA_KEY = 'a_comprar'
    const LINE_DATA_KEY = 'ritmo_fijacion_a_comprar'
    const X_AXIS_DATA_KEY = 'month'

    const barDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      BAR_DATA_KEY
    )
    const lineDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      LINE_DATA_KEY
    )

    const legendGroup = [
      {
        description: 'optimizationScreen.ownership.merchandise.chart.bar',
        color: '#073C8B'
      },
      {
        description: 'optimizationScreen.ownership.merchandise.chart.line',
        color: '#C1D5FF'
      }
    ]

    const chartData = Object.entries(barDataByMonth).map(([month, value]) => ({
      [X_AXIS_DATA_KEY]: toAbbreviatedMonth(month),
      fullMonth: toFullMonth(month),
      [LINE_DATA_KEY]: toKton(value),
      [BAR_DATA_KEY]: toKton(lineDataByMonth[month])
    }))

    return {
      xAxisDataKey: X_AXIS_DATA_KEY,
      lineDataKey: LINE_DATA_KEY,
      barDataKey: BAR_DATA_KEY,
      legendGroup,
      chartData
    }
  }, [filterBySelectedProduct, toAbbreviatedMonth, toFullMonth, toKton])

  const curvePurchasedChartData = useMemo(() => {
    const LINE_DATA_KEY = 'curva_pricing_comprado_a_fijar'

    const X_AXIS_DATA_KEY = 'month'
    const CHART_LINE_DATA_KEY = 'comparison'

    const execDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      LINE_DATA_KEY
    )

    const chartData = Object.entries(execDataByMonth).map(([month, value]) => ({
      [X_AXIS_DATA_KEY]: toAbbreviatedMonth(month),
      fullMonth: toFullMonth(month),
      [CHART_LINE_DATA_KEY]: toKton(value)
    }))
    return {
      xAxisDataKey: X_AXIS_DATA_KEY,
      lineDataKey: CHART_LINE_DATA_KEY,
      chartData
    }
  }, [filterBySelectedProduct, toAbbreviatedMonth, toFullMonth, toKton])

  const minMaxOwnership = useMemo(() => {
    const BAR_DATA_KEY = 'ownership_total'
    const MAX_OWNERSHIP_KEY = 'maximum_ownership'
    const MIN_OWNERSHIP_KEY = 'minimum_ownership'
    const X_AXIS_DATA_KEY = 'month'

    const CHART_FIRST_BAR_DATA_KEY = 'limited'
    const CHART_SECOND_BAR_DATA_KEY = 'projected'
    const MIN_OWNERSHIP_DATA_KEY = 'minOwnership'
    const MAX_OWNERSHIP_DATA_KEY = 'maxOwnership'

    const compDataByMonth = groupByMonth(
      filterBySelectedProduct(COMP_DATA_KEY),
      BAR_DATA_KEY
    )
    const execDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      BAR_DATA_KEY
    )
    const minData = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      MIN_OWNERSHIP_KEY
    )
    const maxData = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      MAX_OWNERSHIP_KEY
    )

    const minMaxLegendGroup = [
      {
        description: 'optimizationScreen.ownership.minMax.chart.max',
        color: '#4FD1A6'
      },
      {
        description: 'optimizationScreen.ownership.minMax.chart.min',
        color: '#F6853E'
      }
    ]
    const barLegendGroup = [
      {
        description: 'optimizationScreen.ownership.minMax.chart.firstBar',
        color: '#1950CA'
      },
      {
        description: 'optimizationScreen.ownership.minMax.chart.secondBar',
        color: '#C1D5FF'
      }
    ]

    const minMaxGroup = [
      {
        dataKey: MIN_OWNERSHIP_DATA_KEY,
        color: '#F6853E',
        name: 'optimizationScreen.ownership.minMax.chart.min'
      },
      {
        dataKey: MAX_OWNERSHIP_DATA_KEY,
        color: '#4FD1A6',
        name: 'optimizationScreen.ownership.minMax.chart.max'
      }
    ]
    const barGroup = [
      {
        dataKey: CHART_FIRST_BAR_DATA_KEY,
        color: '#1950CA',
        name: 'optimizationScreen.ownership.minMax.chart.firstBar'
      },
      {
        dataKey: CHART_SECOND_BAR_DATA_KEY,
        color: '#C1D5FF',
        name: 'optimizationScreen.ownership.minMax.chart.secondBar'
      }
    ]

    const chartData = Object.entries(compDataByMonth).map(([month, value]) => ({
      [X_AXIS_DATA_KEY]: toAbbreviatedMonth(month),
      fullMonth: toFullMonth(month),
      [MIN_OWNERSHIP_DATA_KEY]: toKton(minData[month]),
      [MAX_OWNERSHIP_DATA_KEY]: toKton(maxData[month]),
      [CHART_FIRST_BAR_DATA_KEY]: toKton(execDataByMonth[month]),
      [CHART_SECOND_BAR_DATA_KEY]: toKton(value)
    }))

    const tableHeaderMonths = getTableHeadersFromChartData(chartData)

    const tableSideHeaders = [
      'optimizationScreen.ownership.minMax.table.limited',
      'optimizationScreen.ownership.minMax.table.projected'
    ]

    const tableDataValues = [
      {
        ...calcOwnershipTableValuesByMonth(execDataByMonth),
        ...calcOwnershipTableTotalValue(execDataByMonth)
      },
      {
        ...calcOwnershipTableValuesByMonth(compDataByMonth),
        ...calcOwnershipTableTotalValue(compDataByMonth)
      }
    ]

    return {
      tableHeaderMonths,
      tableSideHeaders,
      tableDataValues,
      chartData,
      minData,
      maxData,
      minMaxLegendGroup,
      minMaxGroup,
      barLegendGroup,
      barGroup,
      xAxisDataKey: X_AXIS_DATA_KEY
    }
  }, [
    calcOwnershipTableTotalValue,
    calcOwnershipTableValuesByMonth,
    filterBySelectedProduct,
    toAbbreviatedMonth,
    toFullMonth,
    toKton
  ])

  const crushingPlainData = useMemo(() => {
    const BAR_DATA_KEY = 'molienda_total'
    const X_AXIS_DATA_KEY = 'month'
    const EXECUTION_DATA_KEY = 'optimizado'
    const COMPARISION_DATA_KEY = 'baseline'

    const EXECUTION_DATA_NAME =
      'optimizationScreen.ownership.crushingPlan.chart.firstBar'
    const COMPARISION_DATA_NAME =
      'optimizationScreen.ownership.crushingPlan.chart.secondBar'

    const legendGroup = [
      {
        description: 'optimizationScreen.ownership.crushingPlan.chart.firstBar',
        color: '#1950CA'
      },
      {
        description:
          'optimizationScreen.ownership.crushingPlan.chart.secondBar',
        color: '#C1D5FF'
      }
    ]
    const barGroup = [
      { dataKey: EXECUTION_DATA_KEY, color: '#1950CA' },
      { dataKey: COMPARISION_DATA_KEY, color: '#C1D5FF' }
    ]
    const compDataByMonth = groupByMonth(
      filterBySelectedProduct(COMP_DATA_KEY),
      BAR_DATA_KEY
    )
    const execDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      BAR_DATA_KEY
    )

    const chartData = Object.entries(compDataByMonth).map(([month, value]) => ({
      [X_AXIS_DATA_KEY]: toAbbreviatedMonth(month),
      fullMonth: toFullMonth(month),
      [COMPARISION_DATA_KEY]: toKton(value),
      [EXECUTION_DATA_KEY]: toKton(execDataByMonth[month])
    }))
    const tableHeaderMonths = getTableHeadersFromChartData(chartData)

    const tableSideHeaders = [EXECUTION_DATA_NAME, COMPARISION_DATA_NAME]

    const tableDataValues = [
      {
        ...calcOwnershipTableValuesByMonth(execDataByMonth),
        ...calcOwnershipTableTotalValue(execDataByMonth)
      },
      {
        ...calcOwnershipTableValuesByMonth(compDataByMonth),
        ...calcOwnershipTableTotalValue(compDataByMonth)
      }
    ]

    return {
      chartData,
      tableHeaderMonths,
      tableDataValues,
      tableSideHeaders,
      legendGroup,
      barGroup,
      xAxisDataKey: X_AXIS_DATA_KEY
    }
  }, [
    calcOwnershipTableTotalValue,
    calcOwnershipTableValuesByMonth,
    filterBySelectedProduct,
    toAbbreviatedMonth,
    toFullMonth,
    toKton
  ])

  const exportationPlainData = useMemo(() => {
    const BAR_DATA_KEY = 'exportaciones'
    const EXECUTION_DATA_KEY = 'optimizado'
    const COMPARISION_DATA_KEY = 'baseline'
    const X_AXIS_DATA_KEY = 'month'

    const EXECUTION_DATA_NAME =
      'optimizationScreen.ownership.exportationPlan.chart.firstBar'
    const COMPARISION_DATA_NAME =
      'optimizationScreen.ownership.exportationPlan.chart.secondBar'

    const compDataByMonth = groupByMonth(
      filterBySelectedProduct(COMP_DATA_KEY),
      BAR_DATA_KEY
    )
    const execDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      BAR_DATA_KEY
    )
    const chartData = Object.entries(compDataByMonth).map(([month, value]) => ({
      [X_AXIS_DATA_KEY]: toAbbreviatedMonth(month),
      fullMonth: toFullMonth(month),
      [COMPARISION_DATA_KEY]: toKton(value),
      [EXECUTION_DATA_KEY]: toKton(execDataByMonth[month])
    }))
    const tableHeaderMonths = getTableHeadersFromChartData(chartData)

    const tableSideHeaders = [EXECUTION_DATA_NAME, COMPARISION_DATA_NAME]

    const legendGroup = [
      {
        description: EXECUTION_DATA_NAME,
        color: '#1950CA'
      },
      {
        description: COMPARISION_DATA_NAME,
        color: '#C1D5FF'
      }
    ]

    const barGroup = [
      { dataKey: EXECUTION_DATA_KEY, color: '#1950CA' },
      { dataKey: COMPARISION_DATA_KEY, color: '#C1D5FF' }
    ]

    const tableDataValues = [
      {
        ...calcOwnershipTableValuesByMonth(execDataByMonth),
        ...calcOwnershipTableTotalValue(execDataByMonth)
      },
      {
        ...calcOwnershipTableValuesByMonth(compDataByMonth),
        ...calcOwnershipTableTotalValue(compDataByMonth)
      }
    ]

    return {
      chartData,
      tableHeaderMonths,
      tableSideHeaders,
      tableDataValues,
      barGroup,
      legendGroup,
      xAxisDataKey: X_AXIS_DATA_KEY
    }
  }, [
    calcOwnershipTableTotalValue,
    calcOwnershipTableValuesByMonth,
    filterBySelectedProduct,
    toAbbreviatedMonth,
    toFullMonth,
    toKton
  ])

  const heatMapProjectedOwnershipData = useMemo(() => {
    const TABLE_CELL_DATA_KEY = 'ownership_total'

    const execDataByMonth = groupByMonth(
      filterBySelectedProduct(EXEC_DATA_KEY),
      TABLE_CELL_DATA_KEY
    )

    const minValue = toKton(getMinHeatMapValuePerMonth(execDataByMonth))
    const maxValue = toKton(getMaxHeatMapValuePerMonth(execDataByMonth))

    const tableHeaderMonths =
      execDataByMonth &&
      Object.keys(execDataByMonth).map((month) => toAbbreviatedMonth(month))

    const tableDataValues = [
      {
        ...Object.entries(execDataByMonth).reduce(
          (acc, [month, value]) => ({
            ...acc,
            [toAbbreviatedMonth(month)]: toKton(value)
          }),
          {}
        )
      }
    ]

    return {
      compDataByMonth: execDataByMonth,
      tableHeaderMonths,
      tableDataValues,
      minValue,
      maxValue
    }
  }, [filterBySelectedProduct, toAbbreviatedMonth, toKton])

  useEffect(() => {
    setHasSelectedProducts(!!selectedProducts.length)
  }, [selectedProducts])

  return {
    hasSelectedsProduct,
    ownershipData,
    summaryCardsData,
    merchandiseData,
    curvePurchasedChartData,
    minMaxOwnership,
    crushingPlainData,
    exportationPlainData,
    heatMapProjectedOwnershipData
  }
}
