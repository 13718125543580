import { useState } from 'react'
import { ExcelIllustration } from 'components/Icons'
import { getBlob } from 'utils/request'
import Button from 'components/Inputs/Button'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { ModalComponent } from 'components/Modal/ModalComponent'
import FileSaver from 'file-saver'
import { useToast } from 'providers/ToastProvider'
import { useLocalization } from 'providers/LocalizationProvider'
import ProgressBar from 'components/Feedback/Loader/ProgressBar'

const DownloadInputModal = ({ optimizationId }) => {
  const [loading, setLoading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(undefined)
  const { showToast } = useToast()
  const { showInputModal, setShowInputModal } = useOptimizationContext()
  const { t } = useLocalization()

  const onClickHandler = async (e) => {
    setLoading(() => true)
    try {
      const inputsRespose = await getBlob(
        `/execution/inputs/${optimizationId}/download`,
        undefined,
        setDownloadProgress
      )

      FileSaver.saveAs(inputsRespose, 'inputs.zip')
      setLoading(false)
      setShowInputModal(false)
    } catch (err) {
      showToast({
        message: t('optimizationScreen.downloadInputModal.failedToDownload'),
        placement: 'top',
        status: 'error'
      })
      setLoading(false)
    }
  }

  return (
    <ModalComponent
      open={showInputModal}
      onOpenChange={setShowInputModal}
      allowClosing={!loading}
    >
      <div className='flex flex-col items-center justify-center bg-white rounded-lg border-gray-200 p-6'>
        <ExcelIllustration />
        <div className='flex flex-col space-y-9 items-center justify-center w-full'>
          <div className='flex flex-col space-y-6 items-start justify-center'>
            <div className='flex flex-col space-y-2  justify-center items-center'>
              <p className='text-base font-bold leading-normal text-center text-gray-800'>
                {t('optimizationScreen.downloadInputModal.downloadData')}
              </p>
              <p className='text-xs leading-none text-center text-gray-500'>
                {t('optimizationScreen.downloadInputModal.downloadDataHint')}
              </p>
            </div>
          </div>
          {loading ? (
            <ProgressBar
              loaded={downloadProgress?.loaded}
              total={downloadProgress?.total}
              variant='transfer'
            />
          ) : (
            <Button
              size='s'
              variant='filled'
              className='w-full'
              onClick={onClickHandler}
            >
              {t('optimizationScreen.downloadInputModal.downloadAll')}
            </Button>
          )}
        </div>
      </div>
    </ModalComponent>
  )
}

export default DownloadInputModal
