import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline'
import { useOptTableContext } from '../OptimizationsTableContext'
import classNames from 'classnames'
import Filters from './Filters'
import SearchInput from 'components/Inputs/SearchInput'
import Button from 'components/Inputs/Button'
import { useLocalization } from 'providers/LocalizationProvider'

function Search() {
  const { t } = useLocalization()
  const { searchTerm, setSearchTerm, toggleFilters, filtersOpen } =
    useOptTableContext()

  return (
    <div className='flex px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg'>
      <div className='flex items-center w-full'>
        <SearchInput
          value={searchTerm}
          onChange={(newValue) => setSearchTerm(newValue)}
          placeholder={t('homeScreen.optimizationsTable.search.placeholder')}
          fullWidth={true}
        />
        <div className='relative'>
          <div
            className={classNames(
              'absolute z-20 p-3 bg-white rounded-lg -top-12 -right-6 shadow-lg w-96 border',
              {
                hidden: !filtersOpen
              }
            )}
          >
            <Filters />
          </div>
          <Button className='ml-2' onClick={toggleFilters}>
            <AdjustmentsVerticalIcon className='w-5 h-5 text-gray-400' />
            {t('homeScreen.optimizationsTable.filter.filterButtonLabel')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Search
