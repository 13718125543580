import { Component, createContext, Fragment, useContext } from 'react'
const ErrorBoundaryContext = createContext(null)
const initialState = { error: null }
// Did with class because there are no support for functional on react
// for Error boundery lifecicle methods like getDerivedStateFromError and componentDidCatch

/**
 * @example
 * <ErrorBoundary
 * fallback={<div>Some error!</div>}
 * onError={(error, info) => console.log(error, info)}
 * onReset={() => console.log('reset Error from SomeComponent')}
 * >
 *   <SomeComponent />
 * </ErrorBoundary>
 *
 */
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.onReset = this.onReset.bind(this)
    this.state = initialState
  }
  static getDerivedStateFromError(error) {
    return { error }
  }
  componentDidCatch(error, info) {
    this.props.onError?.(error, info)
  }
  onReset() {
    this.props.onReset?.()
    this.setState(initialState)
  }
  render() {
    const { error } = this.state
    if (error !== null) {
      const { fallback: Fallback } = this.props
      const contextValue = { error, onReset: this.onReset }
      return (
        <ErrorBoundaryContext.Provider value={contextValue}>
          {typeof Fallback === 'function' ? (
            <Fallback {...contextValue} />
          ) : (
            Fallback
          )}
        </ErrorBoundaryContext.Provider>
      )
    }
    return <Fragment>{this.props.children}</Fragment>
  }
}

/**
 * @example
 * function DeeplyNestedComponent() {
 *   const { error, resetError } = useBounderyError()
 *   return (
 *    <div>
 *     <h1>Something went wrong</h1>
 *    <button onClick={resetError}>Reset</button>
 *  </div>
 * )
 * @returns {object} { error, resetError }
 * @throws {Error} if used outside of ErrorBoundary component
 **/
export function useBounderyError() {
  const context = useContext(ErrorBoundaryContext)
  if (context === undefined) {
    throw new Error(
      `useBounderyError must be used within a ErrorBoundary component`
    )
  }
  return context
}
