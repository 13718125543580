import { EdgeMock, EventType, GraphClickEvent, NodeMock } from '../types'

export const generateRandomColor = () =>
  '#' + randomChannel(0) + randomChannel(0) + randomChannel(0)

function randomChannel(brightness: number) {
  const r = 255 - brightness
  const n = 0 | (Math.random() * r + brightness)
  const s = n.toString(16)
  return s.length === 1 ? '0' + s : s
}

export function colorScaleByVolume(partialValue: number, total: number) {
  const perc = parseFloat(
    (
      Math.round(partialValue) / Math.round(total > 0 ? total : partialValue)
    ).toFixed(2)
  )

  let red,
    green = 0
  const blue = 0
  if (perc < 0.5) {
    green = 255
    red = Math.round(5.1 * (perc * 100))
  } else {
    red = 255
    green = Math.round(510 - 5.1 * (perc * 100))
  }
  const hexColor = red * 0x10000 + green * 0x100 + blue * 0x1
  return '#' + ('000000' + hexColor.toString(16)).slice(-6)
}

export const getEventType = (event: GraphClickEvent) => {
  if (event.nodes.length) {
    return EventType.selectNode
  }
  if (!event.nodes.length && !event.edges.length) {
    return EventType.deselectNode
  }
}

export const checkEdge = (node: NodeMock[], edge: EdgeMock) => {
  const nodesIds = node.map((node) => node.id)
  return nodesIds.includes(edge.to) || nodesIds.includes(edge.from)
}

export const getNodesIdsByEdges = (edges: EdgeMock[]) => {
  const nodesIdsGroup = new Set<number>()

  edges.forEach((edge) => {
    nodesIdsGroup.add(edge.to)
    nodesIdsGroup.add(edge.from)
  })

  return Array.from(nodesIdsGroup)
}
