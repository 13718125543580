import useAuth from 'hooks/useAuth'
import { Suspense, useState } from 'react'
import Loader from 'components/Feedback/Loader/Loader'
import OptimizationsTable from './OptimizationsTable'
import { OptTableProvider } from './OptimizationsTable/OptimizationsTableContext'
import LastModelRun from './LastModelRun'
import { useLocalization } from 'providers/LocalizationProvider'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Inputs/Button'
import { Plus } from 'components/Icons'
import * as T from 'components/Typography'
import { ColumnSorterProvider } from 'components/Tables/GridTable/sorting/ColumnSorterProvider'

export default function Home() {
  const [user] = useAuth()
  const [showNewOptiButton, setShowNewOptiButton] = useState(false)
  const { t } = useLocalization()
  const navigate = useNavigate()

  return (
    <OptTableProvider>
      <div className='h-screen overflow-auto'>
        <div className='flex flex-col mx-auto mt-10 mb-20 2xl:mx-20 px-4'>
          <div className='flex items-start w-full flex-col md:flex-row justify-between'>
            <T.header text={t('homeScreen.welcome', { user: user.username })} />
            {showNewOptiButton && (
              <Button
                variant='filled'
                size='l'
                onClick={() => navigate('/new-optimization')}
              >
                <Plus stroke='white' />
                {t('homeScreen.runOptimization')}
              </Button>
            )}
          </div>
          <Suspense fallback={<Loader />}>
            <LastModelRun />
            <ColumnSorterProvider>
              <OptimizationsTable setShowNewOptiButton={setShowNewOptiButton} />
            </ColumnSorterProvider>
          </Suspense>
        </div>
      </div>
    </OptTableProvider>
  )
}
