import { useMemo, useCallback } from 'react'
import { capitalize } from 'utils/string'
import { formatNumber } from 'utils/number'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import { NO_DATA_LABEL } from 'screens/Optimization/utils'
import { createMonthObject } from 'screens/Optimization/utils'
import { getProductColor } from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

//TODO: Refactor maybe make it into a global/generic something
const calcExecutionIdData = ({ currentData, product_ui_type, volume }) => {
  if (typeof currentData === 'object') {
    return { ...currentData, [product_ui_type]: volume }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const useOutputGraph = (data, capacityTypeToUse) => {
  const { id: baseModelId } = useParams()
  const { t } = useLocalization()
  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const allSilosSummaryByProduct = useMemo(() => {
    if (!data) {
      return {}
    }
    return data.reduce((acc, cur) => {
      if (!acc[cur.product_ui_type]) {
        acc[cur.product_ui_type] = {}
      }

      if (acc[cur.product_ui_type][cur.execution_id]) {
        acc[cur.product_ui_type][cur.execution_id] += cur.volume
      } else {
        acc[cur.product_ui_type][cur.execution_id] = cur.volume
      }

      return acc
    }, {})
  }, [data])

  const summaryItems = useMemo(
    () =>
      Object.entries(allSilosSummaryByProduct).map(([product, volume]) => ({
        title: capitalize(product),
        items: [
          {
            description: t('optimizationScreen.periodTotal'),
            value: formatNumber(volume[baseModelId], {
              maximumFractionDigits: 0
            })
          },
          {
            description: t('optimizationScreen.comparisonPeriodTotal'),
            value: formatNumber(volume[comparisonID], {
              maximumFractionDigits: 0
            })
          }
        ]
      })),
    [allSilosSummaryByProduct, baseModelId, comparisonID, t]
  )

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData
      })
    },
    [start_date, end_date, baseModelId, comparisonID]
  )

  const volumeChartData = useMemo(() => {
    const result = data
      .filter((v) =>
        selectedProducts.length > 0
          ? selectedProducts.includes(v.product_ui_type)
          : true
      )
      .reduce(
        (acc, { month, execution_id, product_ui_type, volume, ...record }) => ({
          ...acc,
          [month]: {
            ...acc[month],
            [execution_id]: calcExecutionIdData({
              currentData: acc[month][execution_id],
              product_ui_type,
              volume
            }),
            capacity: record[capacityTypeToUse] ?? NO_DATA_LABEL
          }
        }),
        getMonthsWithDefaultData({
          executionData:
            selectedProducts.length > 0
              ? selectedProducts.reduce(
                  (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                  {}
                )
              : NO_DATA_LABEL
        })
      )

    return Object.entries(result).map(([month, { capacity, ...volumes }]) => ({
      month,
      capacity,
      volumes
    }))
  }, [data, selectedProducts, getMonthsWithDefaultData, capacityTypeToUse])

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const bars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths, t]
  )

  const utilizationGraph = useMemo(() => {
    return volumeChartData.map(({ month, volumes }) => {
      const mappedMonth = Object.entries(volumes).map(
        ([executionId, volume]) => {
          let utilization
          if (selectedProducts.length > 0) {
            utilization = Object.entries(volume).reduce(
              (acc, [product, volume]) => {
                const rawData = data.find(
                  (e) =>
                    e.execution_id === executionId &&
                    e.month === month &&
                    e.product_ui_type === product
                )
                return {
                  ...acc,
                  [product]: rawData
                    ? ((volume / rawData[capacityTypeToUse]) * 100).toFixed(0)
                    : NO_DATA_LABEL
                }
              },
              {}
            )
          } else {
            const rawData = data.find(
              (e) => e.execution_id === executionId && e.month === month
            )
            utilization = rawData
              ? ((volume / rawData[capacityTypeToUse]) * 100).toFixed(0)
              : NO_DATA_LABEL
          }
          return { executionId, utilization }
        }
      )
      return {
        month,
        volumes: mappedMonth.reduce(
          (acc, { executionId, utilization }) => ({
            ...acc,
            [executionId]: utilization
          }),
          {}
        )
      }
    })
  }, [capacityTypeToUse, data, volumeChartData, selectedProducts])

  return {
    summaryItems,
    selectedProducts,
    getMonthsWithDefaultData,
    volumeChartData,
    utilizationGraph,
    bars
  }
}

export default useOutputGraph
