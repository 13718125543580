import { useState } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useLocalization } from 'providers/LocalizationProvider'

const STEPS_VALUES_BREAKPOINTS = {
  FIRST_SECTION: 0,
  SECOND_SECTION: 50,
  THIRD_SECTION: 100
}

const RunningModelSteps = ({ optimization }) => {
  const [isHovering, setIsHovering] = useState(false)
  const { t } = useLocalization()

  const dataInputProgressPercentage = optimization.progress
  const modelProgressPercentage = 0
  const totalProgressPercentage = optimization.progress / 2
  const onMouseEvent = () => {
    setIsHovering((prev) => !prev)
  }

  return (
    <div className='w-full max-w-3xl'>
      <div
        className='relative w-full mx-auto my-5'
        onMouseOver={onMouseEvent}
        onMouseOut={onMouseEvent}
      >
        <div className='flex' id='progress-bar-tooltip'>
          <div className='w-1/2 bg-grey-200'>
            <div
              className={
                'bg-blue-500 py-1.5 transition-all ease-out relative' +
                (dataInputProgressPercentage < 100
                  ? 'rounded-r-full'
                  : 'rounded-none')
              }
              style={{
                width: `${dataInputProgressPercentage}%`
              }}
            />
          </div>

          <div className='w-1/2 bg-grey-200'>
            <div
              className={
                'bg-blue-500 py-1.5 transition-all ease-out relative' +
                (modelProgressPercentage < 100
                  ? 'rounded-r-full'
                  : 'rounded-none')
              }
              style={{
                width: `${modelProgressPercentage}%`
              }}
            />
          </div>
          <div
            className={classNames(
              'absolute bottom-2 bg-blue-700 text-xs text-white rounded-xl px-4 py-3',
              "after:content-[''] after:absolute after:top-full after:left-1/2 after:-ml-1",
              'after:border-transparent after:border-4 after:border-t-black',
              {
                invisible: !isHovering
              }
            )}
            style={{
              transform: 'translate(-50%, -50%)',
              marginLeft: `${totalProgressPercentage}%`
            }}
          >
            {totalProgressPercentage}%
          </div>
        </div>

        <div className='absolute top-1/2 -translate-y-1/2 -translate-x-1/2'>
          <div className='w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center'>
            <CheckIcon className='text-white h-4 w-4 stroke-white stroke-1' />
          </div>
        </div>

        <div className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
          {dataInputProgressPercentage >= 100 && (
            <div className='w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center'>
              <CheckIcon className='text-white h-4 w-4 stroke-white stroke-1' />
            </div>
          )}
          {dataInputProgressPercentage < 100 && (
            <div className='w-6 h-6 bg-white rounded-full border-2 border-grey-200' />
          )}
        </div>

        <div className='absolute top-1/2 left-full -translate-y-1/2 -translate-x-1/2'>
          {modelProgressPercentage >= 100 && (
            <div className='w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center'>
              <CheckIcon className='text-white h-4 w-4 stroke-white stroke-1' />
            </div>
          )}
          {modelProgressPercentage < 100 && (
            <div className='w-6 h-6 bg-white rounded-full border-2 border-grey-200' />
          )}
        </div>
      </div>

      <div className='flex justify-between font-bold text-grey-400 text-sm w-full mt-4 '>
        <p className={'w-24 text-left text-blue-700'}>
          {t('optimizationScreen.runningModel.dataInput')}
        </p>
        <p
          className={classNames('w-24 text-center', {
            'text-blue-700':
              totalProgressPercentage >= STEPS_VALUES_BREAKPOINTS.SECOND_SECTION
          })}
        >
          {t('optimizationScreen.runningModel.dataReady')}
        </p>
        <p
          className={classNames('w-24 text-right', {
            'text-blue-700':
              totalProgressPercentage >= STEPS_VALUES_BREAKPOINTS.THIRD_SECTION
          })}
        >
          {t('optimizationScreen.runningModel.modelResults')}
        </p>
      </div>
    </div>
  )
}

export default RunningModelSteps
