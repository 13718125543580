import { groupBy, pick, sum, uniq } from 'ramda'
import { useMemo } from 'react'

export const useCapacityData = (capacityKey, data, baseModelId) => {
  const cleanedData = useMemo(
    () =>
      uniq(
        data
          .filter((x) => x.execution_id === baseModelId && x[capacityKey] > 0)
          .map((x) => ({
            ...pick(['asset', 'month', 'transportation_mode'], x),
            capacity: x[capacityKey]
          }))
      ),
    [data, capacityKey, baseModelId]
  )

  const capacityData = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(groupBy((x) => x.month, cleanedData)).map(
          ([month, items]) => [month, sum(items.map((x) => x.capacity))]
        )
      ),
    [cleanedData]
  )

  return capacityData
}
