import Button from 'components/Inputs/Button'
import './notFound.css'
import { useNavigate } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'

const NotFound = () => {
  const navigate = useNavigate()
  const { t } = useLocalization()
  return (
    <div className='flex flex-col h-screen  mx-10 mt-10 '>
      <div className='flex flex-shrink-0 justify-center'>
        <a href='/' className='inline-flex'>
          <span className='sr-only'>
            <div className='not-found-text'>{t('notFoundScreen.title')}</div>
          </span>
          <img className='h-12 w-auto' src='/img/logo-vector.svg' alt='' />
        </a>
      </div>
      <div className='py-16'>
        <div className='text-center'>
          <p className='text-base font-semibold text-indigo-600'>
            {t('notFoundScreen.errorCode')}
          </p>
          <h1 className='text-base not-found-title'>
            {t('notFoundScreen.message')}
          </h1>
          <p className='mt-2 text-base text-gray-500'>
            {t('notFoundScreen.description')}
          </p>
          <div className='mt-6'>
            <Button variant='filled' onClick={() => navigate('/')}>
              {t('notFoundScreen.backToHomeBtn')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
