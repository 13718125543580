import { useCallback } from 'react'
import Select from 'components/Inputs/Select'
import SiloVolumeAndUtilization from './SiloVolumeAndUtilization'
import Inbound from './Inbound'
import Outbound from './Outbound'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import SectionBox from 'components/Container/SectionBox'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

const SiloDetails = ({
  selectedState,
  selectedSilo,
  setSelectedState,
  setSelectedSilo,
  siloSelectionOptions
}) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/storage_details`,
    { bestEstimateId: comparisonID, silo: selectedSilo }
  ])

  const onBreadcrumbClick = useCallback(() => {
    setSelectedState(undefined)
  }, [setSelectedState])

  return (
    <OutputScreen
      title={t('optimizationScreen.storage.siloDetails.title')}
      titleExtra={
        <Select
          options={siloSelectionOptions}
          variant='underlined'
          value={selectedSilo}
          onValueChange={setSelectedSilo}
        />
      }
      crumbs={[
        {
          label: t('optimizationScreen.storage.siloDetails.storage'),
          onClick: onBreadcrumbClick
        },
        {
          label: selectedState,
          onClick: onBreadcrumbClick
        },
        { label: t('optimizationScreen.storage.siloDetails.siloDetails') }
      ]}
    >
      <SectionBox>
        <SiloVolumeAndUtilization data={data.volumes} />
      </SectionBox>
      <SectionBox>
        <Inbound
          inboundData={data.inbound}
          originationData={data.origination}
        />
      </SectionBox>
      <SectionBox>
        <Outbound
          outboundData={data.outbound}
          destinationData={data.destination}
        />
      </SectionBox>
    </OutputScreen>
  )
}

export default SiloDetails
