import NewOptimization from './NewOptimization'
import { NewOptProvider } from './NewOptContext'
import ErrorBoundary from 'components/ErrorBoundary'
import { useLocalization } from 'providers/LocalizationProvider'

function NewOptWrapper() {
  const { t } = useLocalization()
  return (
    <ErrorBoundary
      fallback={<div>{t('newOptimizationScreen.errorBoundary')}</div>}
    >
      <NewOptProvider>
        <NewOptimization />
      </NewOptProvider>
    </ErrorBoundary>
  )
}

export default NewOptWrapper
