import { useState } from 'react'
import classNames from 'classnames'

/**
 *
 * @param {function} onChange - The function to call when the switcher is changed
 * @param {object} items - The items to display in the switcher
 * @returns {JSX.Element}
 * @example
 * <SwitcherButtons items={[
 * 	{
 * 		label: 'Chart',
 * 		value: 'chart'
 * 	},
 * {
 *		label: 'Table',
 *		value: 'table'
 * }
 * ]} onChange={(newValue) => console.log(newValue)} />
 */
const SwitcherButtons = ({ onChange, items }) => {
  const [selected, setSelected] = useState(items[0].value)
  const handleChange = (e) => {
    const { value } = e.target
    setSelected(value)
    onChange(value)
  }

  return (
    <ul className='flex border rounded-lg'>
      {items.map(({ label, value }, index) => {
        return (
          <li key={value + index} className='w-full'>
            <button
              className={classNames(
                'py-2 px-4 transition-colors ease-in-out duration-300',
                {
                  'bg-blue-400 text-white': selected === value,
                  'rounded-tl-lg rounded-bl-lg': index === 0,
                  'rounded-tr-lg rounded-br-lg': index === items.length - 1
                }
              )}
              value={value}
              onClick={handleChange}
            >
              {label}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default SwitcherButtons
