import { useState, useMemo } from 'react'
import {
  useOptTableContext,
  initialState as OptTableContextInitialState
} from '../OptimizationsTableContext'
import { useOptDataContext } from 'providers/OptDataProvider'
import SearchInput from 'components/Inputs/SearchInput'
import classNames from 'classnames'
import { uniq } from 'ramda'
import Datepicker from 'react-tailwindcss-datepicker'
import Button from 'components/Inputs/Button'
import Select from 'components/Inputs/Select'
import { useLocalization } from 'providers/LocalizationProvider'

const FiltersStatuses = () => {
  const { t } = useLocalization()
  const { optData } = useOptDataContext()
  const { setSelectedStatus } = useOptTableContext()

  const statuses = useMemo(() => {
    if (!optData) return []
    return uniq(
      optData.map((opt) => opt.status.toLowerCase().replaceAll(' ', '_'))
    )
  }, [optData])

  return (
    <div className='my-4'>
      <h4 className='mb-2 text-sm'>
        {t('homeScreen.optimizationsTable.filter.statusFilter.label')}
      </h4>
      <Select
        fullWidth
        options={statuses.map((s) => ({
          label: t(`homeScreen.optimizationsTable.statuses.${s}`),
          value: s
        }))}
        placeholder={t(
          'homeScreen.optimizationsTable.filter.statusFilter.placeholder'
        )}
        onValueChange={setSelectedStatus}
        triggerClassName='h-[42px]'
      />
    </div>
  )
}

const FiltersData = () => {
  const { t } = useLocalization()
  const { dateRange, setDateRange } = useOptTableContext()
  return (
    <div className='mb-3 mt-5'>
      <h4 className='text-sm'>
        {t('homeScreen.optimizationsTable.filter.dateSelectorLabel')}
      </h4>
      <Datepicker
        containerClassName='my-2 datepicker-container'
        value={dateRange}
        onChange={(dateRange) => {
          setDateRange(dateRange)
        }}
        showShortcuts={true}
        asSingle={false}
        showFooter={true}
        useRange={false}
        popoverDirection='up'
      />
    </div>
  )
}

const FiltersAuthors = () => {
  const { t } = useLocalization()

  const [authorSearchTerm, setAuthorSearchTerm] = useState('')
  const { optData } = useOptDataContext()
  const { setSelectedAuthor, selectedAuthor } = useOptTableContext()

  const authors = useMemo(() => {
    if (!optData) return []

    if (authorSearchTerm) {
      return uniq(
        optData
          .map((opt) => opt.createdBy)
          .filter((author) => author.includes(authorSearchTerm))
      )
    }

    return uniq(optData.map((opt) => opt.createdBy))
  }, [optData, authorSearchTerm])

  return (
    <div>
      <h4 className='text-sm'>
        {t('homeScreen.optimizationsTable.filter.author.label')}
      </h4>
      <div className='flex items-center w-full mt-2 mb-3'>
        <SearchInput
          className='h-[42px]'
          fullWidth
          placeholder={t(
            'homeScreen.optimizationsTable.filter.author.placeholder'
          )}
          onChange={setAuthorSearchTerm}
        />
      </div>
      <div
        className={classNames(
          'border rounded-lg flex flex-col overflow-y-auto max-h-20',
          {
            'border-0': authors.length === 0
          }
        )}
      >
        {authors.map((author, index) => (
          <div
            key={`${author}-${index}`}
            className={classNames(
              'flex flex-row',
              index !== 0 && 'border-solid border-grey-200 border-t'
            )}
          >
            <button
              onClick={() => {
                setSelectedAuthor(author)
              }}
              className={classNames(
                'p-2 flex-1 rounded-lg text-left transition-colors ease-in-out duration-300 cursor-pointer',
                selectedAuthor === author ? 'hover:bg-grey-100' : 'bg-blue-200'
              )}
              key={`${author}-${index}`}
            >
              {author}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

const Filters = () => {
  const { t } = useLocalization()
  const { toggleFilters, selectedAuthor, selectedStatus, dateRange, reset } =
    useOptTableContext()

  const filterCounter = useMemo(() => {
    const isSelectedStatus =
      OptTableContextInitialState.selectedStatus !== selectedStatus

    const isSelectedAuthor =
      OptTableContextInitialState.selectedAuthor !== selectedAuthor

    const isSelectedDateRange =
      OptTableContextInitialState.dateRange.startDate !== dateRange.startDate ||
      OptTableContextInitialState.dateRange.endDate !== dateRange.endDate

    return isSelectedStatus + isSelectedAuthor + isSelectedDateRange
  }, [selectedAuthor, selectedStatus, dateRange])

  return (
    <div>
      <FiltersAuthors />
      <FiltersData />
      <FiltersStatuses />
      <div className='flex w-full justify-end mb-2 gap-2 pr-1'>
        <Button
          onClick={() => {
            toggleFilters()
            reset()
          }}
          variant='light'
        >
          {t('homeScreen.optimizationsTable.filter.cancel')}
        </Button>
        <Button onClick={toggleFilters} variant='filled'>
          {t('homeScreen.optimizationsTable.filter.apply', {
            count: filterCounter > 0 ? `(${filterCounter})` : ''
          })}
        </Button>
      </div>
    </div>
  )
}

export default Filters
