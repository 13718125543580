import { useCallback, useMemo, useState } from 'react'
import TableActions from './TableActions'
import { get, put } from 'utils/request'
import { useNavigate } from 'react-router-dom'
import { stringMapperFactory } from 'components/Tables/GridTable'
import useSWR from 'swr'
import EditButton from './EditButton'
import AssetsConfigurationsTable from './AssetsConfigurationsTable'
import { useCity } from 'providers/CityProvider'
import useSWRMutation from 'swr/mutation'
import Status from './Status'
import { useLocalization } from 'providers/LocalizationProvider'

const mappers = [
  stringMapperFactory('crusher_id'),
  Status,
  stringMapperFactory('city.meso'),
  stringMapperFactory('city.city'),
  {
    className: 'justify-end pr-4',
    content: EditButton
  }
]

const Crushers = () => {
  const { t, countryCode } = useLocalization()
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()
  const { cities } = useCity()

  const TABLE_HEADERS = useMemo(
    () => [
      t('assetConfigurationScreen.crushers.tableHeaders.crushID'),
      t('assetConfigurationScreen.crushers.tableHeaders.status'),
      t(
        `assetConfigurationScreen.crushers.tableHeaders.${
          countryCode === 'ARG' ? 'subMesoregion' : 'mesoregion'
        }`
      ),
      t('assetConfigurationScreen.crushers.tableHeaders.city'),
      null
    ],
    [countryCode, t]
  )

  const { data, mutate } = useSWR('/crusher', get)

  const { trigger } = useSWRMutation(
    `/crusher`,
    async (url, { arg: { include, id } }) => {
      await put(`${url}/${id}`, { include })
    }
  )

  const handleOnEdit = useCallback(
    (item) => {
      navigate(`/assets/crushers/${item.id}`)
    },
    [navigate]
  )

  const crushersWithCities = useMemo(() => {
    return data.map((crusher) => ({
      ...crusher,
      city: cities.find((city) => city.id === crusher.city_id)
    }))
  }, [data, cities])

  const filteredCrushers = useMemo(
    () =>
      crushersWithCities
        ?.filter(({ crusher_id, city, include }) =>
          [crusher_id, include ? 'active' : 'inactive', city.city, city.meso]
            .join(' ')
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        )
        .sort((a, b) =>
          a.crusher_id?.toLocaleLowerCase() > b.crusher_id?.toLocaleLowerCase()
            ? 1
            : -1
        ),
    [crushersWithCities, searchTerm]
  )

  return (
    <>
      <TableActions
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        placeholder={t(
          'assetConfigurationScreen.crushers.searchbarPlaceholder'
        )}
        registerButtonLabel={t(
          'assetConfigurationScreen.crushers.registerButtonLabel'
        )}
        onRegisterClick={() => navigate('/assets/crushers')}
      />
      <AssetsConfigurationsTable
        data={filteredCrushers}
        columnMappers={mappers}
        columnHeaders={TABLE_HEADERS}
        rowContext={{ mutate, trigger, data, handleOnEdit }}
        columnSizes='20rem auto auto auto auto'
      />
    </>
  )
}

export default Crushers
