import { useMemo, useCallback, useState } from 'react'
import TableActions from './TableActions'
import { get, put } from 'utils/request'
import { useNavigate } from 'react-router-dom'
import {
  stringMapperFactory,
  propertyMapperFactory
} from 'components/Tables/GridTable'
import useSWR from 'swr'
import EditButton from './EditButton'
import AssetsConfigurationsTable from './AssetsConfigurationsTable'
import { useCity } from 'providers/CityProvider'
import useSWRMutation from 'swr/mutation'
import Status from './Status'
import { useLocalization } from 'providers/LocalizationProvider'

const Ports = () => {
  const navigate = useNavigate()
  const { t, countryCode } = useLocalization()
  const [searchTerm, setSearchTerm] = useState('')
  const { cities } = useCity()

  const transportationModeFormatter = useCallback(
    (ts_modes) => {
      return ts_modes
        ?.map((m) => t(`transportationModes.${m.trim()}`))
        ?.join(', ')
    },
    [t]
  )

  const TABLE_HEADERS = [
    t('assetConfigurationScreen.ports.tableHeaders.terminalID'),
    t('assetConfigurationScreen.ports.tableHeaders.status'),
    t('assetConfigurationScreen.ports.tableHeaders.port'),
    t('assetConfigurationScreen.ports.tableHeaders.city'),
    t('assetConfigurationScreen.ports.tableHeaders.transportationModes'),
    null
  ]

  const mappers = useMemo(
    () => [
      stringMapperFactory('terminal_id'),
      Status,
      stringMapperFactory('port.port_name'),
      stringMapperFactory('city.city'),
      propertyMapperFactory('transportation_mode', transportationModeFormatter),
      {
        className: 'justify-end pr-4',
        content: EditButton
      }
    ],
    [transportationModeFormatter]
  )

  const { mutate, data } = useSWR('/port', get)

  const { trigger } = useSWRMutation(
    `/terminal`,
    async (url, { arg: { include, id } }) => {
      await put(`${url}/${id}`, { include })
    }
  )

  const handleOnEdit = useCallback(
    ({ port }) => {
      navigate(`/assets/ports/${port.id}`)
    },
    [navigate]
  )

  const terminalsWithCities = useMemo(() => {
    return data.map((terminal) => ({
      ...terminal,
      city: cities.find((city) => city.id === terminal.port.city_id)
    }))
  }, [data, cities])

  const filteredTerminals = useMemo(
    () =>
      terminalsWithCities
        ?.filter(({ terminal_id, include, port, city, transportation_mode }) =>
          [
            terminal_id,
            include ? 'active' : 'inactive',
            port.port_name,
            city.city,
            transportationModeFormatter(transportation_mode)
          ]
            .join(' ')
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        )
        ?.sort((a, b) =>
          a.terminal_id?.toLocaleLowerCase() >
          b.terminal_id?.toLocaleLowerCase()
            ? 1
            : -1
        ),
    [terminalsWithCities, searchTerm, transportationModeFormatter]
  )

  return (
    <>
      <TableActions
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        placeholder={t('assetConfigurationScreen.ports.searchbarPlaceholder')}
        registerButtonLabel={t(
          'assetConfigurationScreen.ports.registerButtonLabel'
        )}
        onRegisterClick={() => navigate('/assets/ports')}
      />
      <AssetsConfigurationsTable
        data={filteredTerminals}
        columnMappers={mappers}
        columnHeaders={TABLE_HEADERS}
        rowContext={{ data, mutate, trigger, handleOnEdit }}
        columnSizes={`20rem auto auto auto auto ${countryCode === 'BR' ? 'auto' : ''}`}
        hiddenColumn={countryCode === 'ARG' ? {column: 'Transportation modes', key: 'transportation_mode'} : null}
      />
    </>
  )
}

export default Ports
