import { useCallback } from 'react'
import Select from 'components/Inputs/Select'
import SectionBox from 'components/Container/SectionBox'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useSWR from 'swr'
import OutboundDetailed from './Tabs/OutboundDetailed'
import InboundDetailed from './Tabs/InboundDetailed'
import Outbound from '../Outbound'
import RotationRatio from './Tabs/RotationRatio'
import Inbound from '../Inbound'
import AllSilos from '../AllSilos'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import OutputScreen from 'components/Container/OutputScreen'
import { useLocalization } from 'providers/LocalizationProvider'

const SiloDetails = ({
  selectedSilo,
  setSelectedSilo,
  siloSelectionOptions
}) => {
  const { id: baseModelId } = useParams()
  const { t } = useLocalization()
  const { comparisonID } = useOptimizationContext()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/storage_details`,
    { bestEstimateId: comparisonID, silo: selectedSilo }
  ])

  const onBreadcrumbClick = useCallback(() => {
    setSelectedSilo(undefined)
  }, [setSelectedSilo])

  return (
    <OutputScreen
      title={t('optimizationScreen.storage.siloDetails.title')}
      titleExtra={
        <Select
          options={siloSelectionOptions}
          variant='underlined'
          value={selectedSilo}
          onValueChange={setSelectedSilo}
        />
      }
      crumbs={[
        {
          label: t('optimizationScreen.storage.siloDetails.storage'),
          onClick: onBreadcrumbClick
        },
        {
          label: selectedSilo,
          onClick: onBreadcrumbClick
        },
        { label: t('optimizationScreen.storage.siloDetails.siloDetails') }
      ]}
    >
      <SectionBox>
        <TabsComponent>
          <Tab
            value='inbound_volume'
            label={t('optimizationScreen.storage.siloDetails.inboundVolume')}
          >
            <Inbound data={data.inbound} />
          </Tab>
          <Tab
            value='final_stock'
            label={t('optimizationScreen.storage.siloDetails.finalStock')}
          >
            <AllSilos data={data.volumes} />
          </Tab>
          <Tab
            value='rotation_ratio'
            label={t('optimizationScreen.storage.siloDetails.rotationRatio')}
          >
            <RotationRatio data={data.rotationRatio} />
          </Tab>
          <Tab
            value='outbound_volume'
            label={t('optimizationScreen.storage.siloDetails.outboundVolume')}
          >
            <Outbound data={data.outbound} />
          </Tab>
        </TabsComponent>
      </SectionBox>
      <SectionBox>
        <InboundDetailed
          inboundData={data.inbound}
          originationData={data.origination}
        />
      </SectionBox>
      <SectionBox>
        <OutboundDetailed
          outboundData={data.outbound}
          destinationData={data.destination}
        />
      </SectionBox>
    </OutputScreen>
  )
}

export default SiloDetails
