import { useEffect, useState, useMemo, useCallback } from 'react'
import { useOptDataContext } from 'providers/OptDataProvider'
import EmptyState from './EmptyState'
import Search from './Search'
import {
  useOptTableContext,
  initialState as OptTableContextInitialState
} from './OptimizationsTableContext'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import ErrorBoundary from 'components/ErrorBoundary'
import GridTable, {
  dateMapperFactory,
  numberMapperFactory,
  stringMapperFactory,
  currencyMapperFactory,
  propertyMapperFactory
} from 'components/Tables/GridTable'
import Button from 'components/Inputs/Button'
import dayjs from 'dayjs'
import { useLocalization } from 'providers/LocalizationProvider'
import { formatAsMillions } from 'utils/number'
import Tooltip from 'components/Tooltip'
import { useColumnSorterContext } from 'components/Tables/GridTable/sorting/ColumnSorterProvider'
import SorterColumnTitle from 'components/Tables/GridTable/sorting/SorterColumnTitle'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'

export default function OptimizationsTable({ setShowNewOptiButton }) {
  const { searchTerm, selectedStatus, dateRange, selectedAuthor } =
    useOptTableContext()
  const { optData: data } = useOptDataContext()
  const { t, countryCode } = useLocalization()
  const currency = useMemo(() => {
    return countryCode === 'ARG' ? '$' : 'R$'
  }, [countryCode])
  const { orderData } = useColumnSorterContext()

  useEffect(
    () => setShowNewOptiButton(data.length > 0),
    [setShowNewOptiButton, data]
  )

  const COLUMN_HEADERS = useMemo(
    () => [
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.optimization'),
        sortKey: 'name'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.lastUpdate'),
        sortKey: 'updatedAt'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.author'),
        sortKey: 'createdBy'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.refPeriod'),
        sortKey: 'start_date'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.revenue', {
          currency
        }),
        sortKey: 'results.revenue'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.benefit'),
        sortKey: 'results.benefit'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.crushedVol'),
        sortKey: 'results.crushed_volume'
      },
      {
        title: t('homeScreen.optimizationsTable.tableHeaders.exportedVol'),
        sortKey: 'results.exported_volume'
      },
      { title: null }, // status
      { title: null } // more information
    ],
    [t, currency]
  )

  const mappers = [
    OptiTitle,
    dateMapperFactory('updatedAt', 'DD/MM/YYYY\nhh:mm A'),
    stringMapperFactory('createdBy'),
    dateMapperFactory('start_date', 'MM/YYYY'),
    currencyMapperFactory('results.revenue', countryCode),
    propertyMapperFactory('results.benefit', formatAsMillions),
    numberMapperFactory('results.crushed_volume'),
    numberMapperFactory('results.exported_volume'),
    ({ item }) => <RunStatusBadge status={item.status} />,
    ({ item }) =>
      item.status === 'Success' ? <MoreOptionsComponent item={item} /> : null
  ]

  const navigate = useNavigate()

  const isSelectedDateRange =
    OptTableContextInitialState.dateRange.startDate !== dateRange.startDate ||
    OptTableContextInitialState.dateRange.endDate !== dateRange.endDate

  const [page, setPage] = useState(0)
  const rowsPerPage = 10

  const firstPage = page * rowsPerPage
  const secondPage = page * rowsPerPage + rowsPerPage

  const filteredList = useMemo(() => {
    return orderData(data).filter((optimization) => {
      if (searchTerm !== '') {
        const st = searchTerm?.toLowerCase()
        if (!optimization.name.toLowerCase().includes(st)) {
          if (
            !optimization.scenarios?.some((x) =>
              x.name.toLowerCase().includes(st)
            )
          ) {
            return false
          }
        }
      }

      if (isSelectedDateRange) {
        const parsedDate = dayjs(optimization.createdAt)
        if (
          !parsedDate.isBetween(
            dateRange.startDate,
            dateRange.endDate,
            'day',
            '[]'
          )
        ) {
          return false
        }
      }

      if (
        selectedAuthor &&
        selectedAuthor.length > 0 &&
        optimization.createdBy !== selectedAuthor
      ) {
        return false
      }

      if (!selectedStatus) {
        return true
      }

      if (optimization.status.toLowerCase() === selectedStatus?.toLowerCase()) {
        return true
      }

      return false
    })
  }, [
    data,
    selectedStatus,
    dateRange,
    isSelectedDateRange,
    selectedAuthor,
    searchTerm,
    orderData
  ])

  const limitPages = Math.ceil(filteredList.length / rowsPerPage)

  const handlerNextPage = useCallback(
    () => setPage((page) => (page < limitPages - 1 ? page + 1 : page)),
    [limitPages, setPage]
  )

  const handlerPreviousPage = useCallback(
    () => setPage((page) => (page === 0 ? page : page - 1)),
    [setPage]
  )

  const dataToDisplay = filteredList.slice(firstPage, secondPage)

  const footerMessage = useMemo(() => {
    if (filteredList.length < rowsPerPage) {
      return (
        <>
          {t('homeScreen.optimizationsTable.showing', {
            from: firstPage + 1,
            to: filteredList.length,
            of: filteredList.length
          })}
        </>
      )
    }

    return (
      <>
        {t('homeScreen.optimizationsTable.showing', {
          from: firstPage + 1,
          to:
            secondPage >= filteredList.length
              ? filteredList.length
              : secondPage,
          of: filteredList.length
        })}
      </>
    )
  }, [firstPage, secondPage, filteredList, t])

  const rowContext = (item) => {
    const isDone =
      item.status.toLowerCase() === 'success' && item.scenarios?.length > 0
    return {
      expandable: isDone,
      onRowClick: isDone /*aqui */
        ? undefined
        : item.status.toLowerCase() === 'failed'
        ? () => {
            navigate(`execution/error/${item.data.name}/${item.id}`, {
              state: item?.scenarios?.[0]?.id
            })
          }
        : () => {
            navigate(`/execution/${item.id}`, {
              state: item?.scenarios?.[0]?.id
            })
          },
      onSubRowClick: (item, scenario) => 
        scenario.status.toLowerCase() !== 'failed'
          ? navigate(`/execution/${scenario.id}`, {
              state: scenario?.id
            })
          : scenario.status.toLowerCase() === 'failed'
          ? navigate(`execution/error/${scenario.name}/${scenario.id}`, {
              // state: item?.scenarios?.[0]?.id
            })
          : undefined
    }
  }

  return (
    <ErrorBoundary
      fallback={
        <p className='text-2xl font-bold leading-loose text-gray-900'>
          {t('homeScreen.optimizationsTable.errorBoundary')}
        </p>
      }
    >
      <div className='mt-10'>
        {data?.length > 0 ? (
          <>
            <p className='text-2xl font-bold leading-loose text-gray-900'>
              {t('homeScreen.optimizationsTable.title')}
            </p>
            <div className='flex flex-col w-full mt-4 h-full mb-10'>
              <div className='border-x border-t rounded-t-lg'>
                <Search />
              </div>
              <div className='w-full overflow-y-auto'>
                <GridTable
                  data={dataToDisplay}
                  columnMappers={mappers}
                  keyMapper={(item) => item.id}
                  borders={true}
                  rowContext={rowContext}
                  renderExpandedRow={(item) => item.scenarios}
                  rowClickMode='expand'
                  columnHeaders={COLUMN_HEADERS.map(({ title, sortKey }) => {
                    return sortKey ? (
                      <SorterColumnTitle sortKey={sortKey} title={title} />
                    ) : (
                      <div className='flex items-center justify-center'>
                        <span>{title}</span>
                      </div>
                    )
                  })}
                  headerClassNames='flex items-center justify-start font-bold text-grey-400 bg-grey-100 h-12 px-4 text-sm'
                  cellClassNames='flex items-center bg-white text-grey-400 p-4 text-sm justify-start'
                  expandedSubRowClassNames='flex items-center bg-blue-100 text-grey-400 p-4 text-sm justify-start'
                />
              </div>
              <div className='font-bold text-grey-400 bg-white px-4 text-sm rounded-b-lg border-x border-b flex gap-10 justify-between py-4'>
                <div className='my-auto'>{footerMessage}</div>
                <div className='flex gap-4'>
                  <Button
                    variant='light'
                    disabled={page === 0}
                    onClick={handlerPreviousPage}
                  >
                    {t('homeScreen.optimizationsTable.previous')}
                  </Button>

                  <Button
                    variant='light'
                    onClick={handlerNextPage}
                    disabled={
                      page >= limitPages - 1 ||
                      filteredList.length < rowsPerPage
                    }
                  >
                    {t('homeScreen.optimizationsTable.next')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </ErrorBoundary>
  )
}

const MoreOptionsComponent = ({ item }) => {
  const navigate = useNavigate()

  const [showMoreOptions, setShowMoreOptions] = useState(false)
  const [options] = useState([
    { name: 'New Scenario', value: '' },
    { name: 'New Execution', value: '' }
  ])

  const moreOptions = (e) => {
    e.stopPropagation()
    setShowMoreOptions(!showMoreOptions)
  }

  const handleClick = (e, eventType) => {
    e.stopPropagation()
    if (eventType === 'New Scenario') {
      navigate(`/execution/${item.id}/scenario`, {
        state: item?.scenarios?.[0]?.id
      })
    } else if (eventType === 'New Execution') {
      navigate(`/new-execution`, {
        state: { ...item, origin: 'home-page' }
      })
    }
  }

  return (
    <div className='p-4 box-border relative'>
      {item.status == 'Success' && (
        <div>
          <EllipsisHorizontalIcon
            className='w-6 h-6 cursor-pointer'
            aria-haspopup='true'
            aria-controls='long-menu'
            onClick={(e) => moreOptions(e)}
          />
        </div>
      )}
      {showMoreOptions && (
        <div className='bg-white border rounded-lg px-4 absolute w-32 right-10'>
          {options?.map((option, i) => (
            <p
              key={i}
              className='text-sm py-2 flex'
              onClick={(e) => handleClick(e, option.name)}
            >
              {option.name}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

const RunStatusBadge = ({ status }) => {
  const { t } = useLocalization()
  const lowerCaseStatus = status?.toLowerCase().replaceAll(' ', '_')

  return (
    <div
      className={classNames(
        'text-xs font-bold px-2 py-1 rounded mx-auto w-max',
        {
          'bg-success-100 text-success-600': lowerCaseStatus === 'success',
          'bg-grey-100 text-grey-500': lowerCaseStatus === 'in_progress',
          'bg-orange-100 text-orange-500': lowerCaseStatus === 'infeasible',
          'bg-warning-100 text-warning-500': lowerCaseStatus === 'inconsistent',
          'bg-error-100 text-error-400': lowerCaseStatus === 'failed'
        }
      )}
    >
      {t(`homeScreen.optimizationsTable.statuses.${lowerCaseStatus}`)}
    </div>
  )
}

const OptiTitle = ({ item: { name, data, parent_execution_id } }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const { executionIdLookup } = useOptDataContext()
  const { t } = useLocalization()

  const showWhatifBadge = useMemo(() => {
    return !!data?.type?.toLowerCase()?.includes('what-if')
  }, [data])

  return (
    <div className='relative'>
      <span>{name}</span>
      {showWhatifBadge && (
        <>
          <span
            className='px-1 py-0.5 rounded-sm ml-1 bg-grey-100 text-xs'
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {t('homeScreen.optimizationsTable.whatifTag.label')}
          </span>
          <Tooltip light show={showTooltip} className='absolute z-10 w-max'>
            {t('homeScreen.optimizationsTable.whatifTag.tooltip', {
              name: executionIdLookup[parent_execution_id]?.name
            })}
          </Tooltip>
        </>
      )}
    </div>
  )
}

