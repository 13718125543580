import { useState } from "react";
import { DataSet, Edge, Network, Node } from "react-graph-vis-ts";
import { EdgeMock, NodeMock } from "../../types";

export const useNetwork = () => {
  const [network, setNetwork] = useState<Network>();
  const [nodes, setNodes] = useState<NodeMock[]>([]);
  const [edges, setEdges] = useState<EdgeMock[]>([]);

  const onGetNodes = (nodes: DataSet<Node>) => {
    setNodes(nodes.get() as NodeMock[]);
  };

  const onGetEdges = (edges: DataSet<Edge>) => {
    setEdges(edges.get() as EdgeMock[]);
  };

  const onGetNetwork = (network: Network) => {
    setNetwork(network);
  };

  return {
    network,
    nodes,
    edges,
    setNetwork,
    setNodes,
    setEdges,
    onGetNodes,
    onGetEdges,
    onGetNetwork,
  };
};
