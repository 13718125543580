import { InformationCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import BreadCrumbComponent from 'components/Navigation/Breadcrumb'
import ErrorBoundary from 'components/ErrorBoundary'
import Tooltip from 'components/Tooltip'
import { useState } from 'react'

export default function OutputScreen({
  title,
  titleExtra,
  information,
  crumbs,
  children
}) {
  const [showTooltip, setShowTooltip] = useState(false)
  const hasTooltip = typeof information !== 'boolean'

  return (
    <ErrorBoundary
      fallback={
        <div className='flex justify-center items-center w-full h-full'>
          {typeof title === 'string'
            ? `Error at output ${title}`
            : 'Error at output page'}
        </div>
      }
    >
      <div className='flex flex-col min-w-[1024px] max-w-[1254px]'>
        <div className='flex flex-col justify-start w-full space-y-4 px-8'>
          {crumbs && (
            <div className='mt-4'>
              <BreadCrumbComponent crumbs={crumbs} />
            </div>
          )}
          <div className='flex pt-4 items-center gap-2'>
            <h2 className='text-xl font-bold'>{title}</h2>
            {information && (
              <>
                <InformationCircleIcon
                  className={classNames(
                    'w-6 h-6',
                    hasTooltip && 'cursor-pointer'
                  )}
                  onMouseOver={() => setShowTooltip(hasTooltip)}
                  onMouseOut={() => setShowTooltip(false)}
                />
                <div className='relative'>
                  <Tooltip
                    light
                    show={showTooltip}
                    className='max-w-2xl w-max z-20 absolute p-4'
                  >
                    {information}
                  </Tooltip>
                </div>
              </>
            )}
            {titleExtra}
          </div>
          {children}
        </div>
      </div>
    </ErrorBoundary>
  )
}
