import Optimization from './Optimization'
import { OptimizationProvider } from 'providers/OptimizationProvider'
import RunningModel from './RunningModel'
import InfeasibleRun from './InfeasibleRun'
import Inconsistencies from './Inconsistencies'
import ErrorBoundary from 'components/ErrorBoundary'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useLocalization } from 'providers/LocalizationProvider'
import Home from '../Home'

const OptimizationWrapper = () => {
  const { id } = useParams()
  const { t } = useLocalization()


  const { data: optimizationData } = useSWR(
    () => (id ? `/execution/${id}` : null),
    {
      refreshInterval: () =>
        optimizationData?.status === 'In Progress' ? 10000 : 0,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      enabled: !!id
    }
  )
  
  return (
    <ErrorBoundary
      fallback={
        <div className='flex w-full h-[calc(100vh-64px)] items-center justify-center'>
          { t('optimizationScreen.errorBoundary') }
        </div>
      }
    >
      {optimizationData.status === 'In Progress' && (
        <RunningModel optimizationId={id} optimization={optimizationData} />
      )}
      {optimizationData.status === 'Infeasible' && <InfeasibleRun />}
      {optimizationData.status === 'Inconsistent' && <Inconsistencies />}
      {optimizationData.status === 'Success' && (
        <OptimizationProvider>
          <Optimization />
        </OptimizationProvider>
      )}
      {optimizationData.status === 'Failed' && <Home />}
    </ErrorBoundary>
  )
}

export default OptimizationWrapper
