export const COUNTRY_OPTIONS = [
  {
    label: 'Brazil',
    value: 'BR'
  },
  {
    label: 'Argentina',
    value: 'ARG'
  }
]

export const STATE_KEY_MAP = {
  ARG: 'submesoregionSelector',
  BR: 'stateSelector'
}

export const TRANSPORTATION_MODES = [
  {
    value: 'truck',
    checked: false
  },
  {
    value: 'rail',
    checked: false
  },
  {
    value: 'barges',
    checked: false
  },
  {
    value: 'export',
    checked: false
  }
]

export const PRODUCTS = [
  {
    value: 'soy',
    checked: false
  },
  {
    value: 'corn',
    checked: false
  },
  {
    value: 'wheat',
    checked: false
  },
  {
    value: 'soybean meal 46',
    checked: false
  },
  {
    value: 'soybean meal 48',
    checked: false
  },
  {
    value: 'soybean meal SPC 60',
    checked: false
  },
  {
    value: 'soybean meal SPC 62',
    checked: false
  }
]

export const TERMINAL_PREFIX = 'PORT'
export const PORT_PREFIX = 'PORT'
export const CRUSH_PREFIX = 'CRUSH'
export const SILO_PREFIX = 'SILO'
export const TRANSSHIPMENT_PREFIX = 'TRANSB'

export const TerminalIDRegexp = new RegExp(`${TERMINAL_PREFIX}_[a-zA-Z]*_`, 'i')
export const CrusherIDRegexp = new RegExp(`${CRUSH_PREFIX}_[a-zA-Z]*_`, 'i')
export const PortIDRegexp = new RegExp(`${PORT_PREFIX}_[a-zA-Z]*_`, 'i')
export const SiloIDRegexp = new RegExp(`${SILO_PREFIX}_[a-zA-Z]*_`, 'i')
export const TransshipmentIDRegexp = new RegExp(
  `${TRANSSHIPMENT_PREFIX}_[a-zA-Z]*_`,
  'i'
)
