import classNames from 'classnames'
import Tooltip from 'components/Tooltip'
import { useState } from 'react'

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.type
 * @returns {JSX.Element}
 */
const Input = ({
  label,
  type = 'text',
  error,
  disabled,
  onChange,
  className,
  startAddon: StartAddon,
  endAddon: EndAddon,
  description: Description,
  fullWidth = false,
  Icon,
  tooltip,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div className={classNames('relative', { 'w-full': fullWidth })}>
      {label && (
        <label
          htmlFor={label}
          className='block text-base font-medium leading-6 text-gray-700 mb-2'
        >
          {label}
        </label>
      )}
      {Icon && (
        <Icon
          className='w-5 h-5 top-2.5 left-2.5 text-gray-400'
          style={{ position: 'absolute' }}
        />
      )}
      <div className='flex justify-between items-end'>
        {StartAddon && StartAddon}
        <input
          type={type}
          name={label}
          disabled={disabled}
          onChange={(e) => onChange && onChange(e.target.value)}
          className={classNames(
            'w-full h-10 box-border rounded-md py-2 px-3 text-grey-700 shadow-sm placeholder:text-gray-400 sm:leading-6',
            {
              'border-0 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-blue-500':
                !error,
              'border-error-400 text-error-400 focus:border-error-400 focus:ring-0 focus:ring-offset-0':
                error,
              'opacity-50': disabled
            },
            {
              'pl-10': Icon
            },
            className
          )}
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          {...props}
        />
        {EndAddon && EndAddon}
      </div>

      <div className='flex flex-col justify-between mt-1'>
        {Description && Description}
        {error && <span className='text-error-400'>{error}</span>}
      </div>
      {tooltip && (
        <Tooltip show={showTooltip} light className='absolute top-100'>
          {tooltip}
        </Tooltip>
      )}
    </div>
  )
}
export default Input
