import { useMemo } from 'react'

const getPositionAndCorrection = (min, max, value) => {
  const rangeSpan = max - min
  const fraction = (value - min) / rangeSpan
  const position = fraction * 100
  const correction = -(fraction - 0.5) * 20

  return { position, correction }
}

const RangeSlider = ({
  min,
  max,
  value,
  isUnlimited,
  defaultValue,
  onChange,
  disabled
}) => {
  const { position, correction } = useMemo(
    () => getPositionAndCorrection(min, max, value),
    [min, max, value]
  )
  const { position: defaultPosition, correction: defaultCorrection } = useMemo(
    () => getPositionAndCorrection(min, max, defaultValue),
    [min, max, defaultValue]
  )

  return (
    <div className='relative w-full'>
      <div
        className='absolute lg:block hidden -top-8 w-[200px] text-center'
        style={{
          left: `calc(${position}% - 100px + ${correction}px)`
        }}
      >
        <span className='bg-blue-900 rounded text-white text-xs p-2'>
          {isUnlimited && +value === +max ? 'Unlimited' : value + '%'}
        </span>
      </div>
      <input
        type='range'
        min={min}
        max={max}
        value={value}
        className='range w-full h-2 bg-gray-200 rounded-md cursor-pointer'
        onChange={onChange}
        step='1'
        disabled={disabled}
      />
      <div className='flex w-full justify-between text-sm relative'>
        <p>{min}</p>
        <p
          className='absolute flex justify-center w-[200px]'
          style={{
            left: `calc(${defaultPosition}% - 100px + ${defaultCorrection}px)`
          }}
        >
          Default
        </p>
        <p>{isUnlimited ? 'Unlimited' : max}</p>
      </div>
    </div>
  )
}
export default RangeSlider
