import MultiRangeSlider from 'components/Inputs/MultiRangeSlider/MultiRangeSlider'
import { useController } from 'react-hook-form'

const ControlledMultiRangeSlider = ({ controllerProps, onChange, disabled, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps)

  return (
    <MultiRangeSlider
      {...props}
      values={[field.value]}
      defaultValues={[field.value]}
      onValueChange={(value) => {
        field.onChange(value)
        onChange && onChange(value)
      }}
      error={error && error.message}
      disabled={disabled}
    />
  )
}

export default ControlledMultiRangeSlider
