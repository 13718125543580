import Files from './Files'
import Settings from './Settings'
import Assets from './Assets'
import Footer from './Footer'
import { useNewOptContext } from '../NewOptContext'
import ErrorBoundary from 'components/ErrorBoundary'
import { ColumnSorterProvider } from 'components/Tables/GridTable/sorting/ColumnSorterProvider'

const FilesWithErrorBoundary = () => (
  <ErrorBoundary
    fallback={<div>Error at screens/NewOptimization/Main/Files</div>}
  >
    <ColumnSorterProvider noDefaultSorter>
      <Files />
    </ColumnSorterProvider>
  </ErrorBoundary>
)

const AssetsWithErrorBoundary = () => (
  <ErrorBoundary
    fallback={<div>Error at screens/NewOptimization/Main/Assets</div>}
  >
    <Assets />
  </ErrorBoundary>
)

const SettingsWithErrorBoundary = () => (
  <ErrorBoundary
    fallback={<div>Error at screens/NewOptimization/Main/Settings</div>}
  >
    <Settings />
  </ErrorBoundary>
)

const Main = () => {
  const { page } = useNewOptContext()

  return (
    <div className='w-full relative bg-white'>
      <div className='h-[calc(100%-80px)] overflow-auto'>
        {
          {
            0: <SettingsWithErrorBoundary />,
            1: <AssetsWithErrorBoundary />,
            2: <FilesWithErrorBoundary />
          }[page]
        }
      </div>
      <div className='absolute bottom-0 py-5 bg-white border-t w-full'>
        <div className='flex mr-auto pr-4 2xl:mr-20 justify-end'>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Main
