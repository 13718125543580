import { useMemo } from 'react'
import { RowCheckboxComponent } from './CellComponents'

export const useMappers = (
  {
    columnMappers,
    data,
    omitKeysFromData,
    renderExpandedRow,
    expandArrowPosition,
    checkableRows
  },
  { DefaultCellComponent, RowExpandButtonComponent }
) => {
  return useMemo(() => {
    let mappers = []

    if (Array.isArray(columnMappers)) {
      mappers = columnMappers
    } else if (data.length > 0) {
      mappers = Object.keys(data[0])
        .filter((key) => !omitKeysFromData || !omitKeysFromData.includes(key))
        .fill(
          typeof columnMappers === 'function'
            ? columnMappers
            : DefaultCellComponent
        )
    }

    if (renderExpandedRow) {
      if (expandArrowPosition === 'right') {
        mappers = [...mappers, RowExpandButtonComponent]
      }
    }

    if (checkableRows) {
      mappers = [RowCheckboxComponent, ...mappers]
    }

    return mappers
  }, [
    data,
    DefaultCellComponent,
    RowExpandButtonComponent,
    checkableRows,
    columnMappers,
    expandArrowPosition,
    omitKeysFromData,
    renderExpandedRow
  ])
}
