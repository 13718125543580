import { useParams } from 'react-router-dom'
import CIF from './CIF'
import useSWR from 'swr'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import FOB from './FOB'
import Total from './Total'
import { useLocalization } from 'providers/LocalizationProvider'
import OutputScreen from 'components/Container/OutputScreen'
import SectionBox from 'components/Container/SectionBox'

export default function Origination() {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { countryCode, t } = useLocalization()
  const tabs = [
    { label: t('optimizationScreen.origination.main.cif'), value: 'cif' },
    { label: t('optimizationScreen.origination.main.fob'), value: 'fob' },
    { label: t('optimizationScreen.origination.main.total'), value: 'total' }
  ]
  const { data: cifData } = useSWR([
    `/execution/outputs/${baseModelId}/origination_cif`,
    { bestEstimateId: comparisonID }
  ])
  const { data: fobData } = useSWR([
    `/execution/outputs/${baseModelId}/origination_fob`,
    { bestEstimateId: comparisonID }
  ])
  const { data: totalData } = useSWR([
    `/execution/outputs/${baseModelId}/origination_total`,
    { bestEstimateId: comparisonID }
  ])

  return (
    <OutputScreen
      title={t('optimizationScreen.pageNames.origination')}
      information
    >
      <SectionBox>
        <TabsComponent>
          {tabs
            .filter((tab) => tab.value !== 'total' || countryCode === 'BR')
            .map((tab) => (
              <Tab value={tab.value} label={tab.label} key={tab.value}>
                {tab.value === 'cif' && <CIF data={cifData.cif} />}
                {tab.value === 'fob' && <FOB data={fobData.fob} />}
                {tab.value === 'total' && <Total data={totalData.total} />}
              </Tab>
            ))}
        </TabsComponent>
      </SectionBox>
    </OutputScreen>
  )
}
