import { SummaryCard } from 'components/Cards'

const SummaryCards = ({ data }) => (
  <div className='flex flex-wrap gap-4'>
    {data &&
      data.map((product, index) => (
        <SummaryCard
          key={index}
          title={product.title}
          items={[
            {
              description: product.description,
              value: product.value,
              valueUnit: product.valueUnit
            }
          ]}
        />
      ))}
  </div>
)

export default SummaryCards
