import classNames from 'classnames'
import { GridLoader } from 'react-spinners'

export default function Loader({
  variant = 'large',
  size = 15,
  color = '#7EA2F4',
  loading = true
}) {
  return (
    <div
      className={classNames(
        'flex justify-center items-center w-full',
        variant === 'large' ? 'h-screen' : 'h-full'
      )}
    >
      <GridLoader color={color} loading={loading} size={size} />
    </div>
  )
}
