import { EmptyBoxIllustration } from 'components/Icons'
import { useLocalization } from 'providers/LocalizationProvider'

const Overview = () => {
  const { t } = useLocalization()
  return (
    <div className='flex items-center justify-center flex-col h-full'>
      <EmptyBoxIllustration />
      <h2 className='text-xl font-bold text-blue-700 mt-4'>
        {t('scenarios.pages.overview.selectScenario')}
      </h2>
    </div>
  )
}

export default Overview
