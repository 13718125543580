import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

export const LineAreaChart = ({
  data,
  xAxisDataKey,
  containerClass,
  lineDataKey
}) => {
  return (
    <div className={['w-full h-[450px] mt-10', containerClass].join(' ')}>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis dataKey={xAxisDataKey} className='capitalize' />
          <YAxis unit={' kTon'} />
          <Tooltip />
          <Area
            dataKey={lineDataKey}
            type='monotone'
            stroke='#8884d8'
            fill='#8884d8'
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
