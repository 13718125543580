import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NO_DATA_LABEL, createMonthObject } from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import { getProductColor } from 'screens/Optimization/utils/output'

const useGraph = ({
  data,
  calcExecutionIdData,
  monthContextData = {},
  capacityKey,
  calculateUtilization = false
}) => {
  const { id: baseModelId } = useParams()
  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        [comparisonID]: executionData,
        ...Object.keys(monthContextData).reduce(
          (a, c) => ({ ...a, [c]: NO_DATA_LABEL }),
          {}
        )
      })
    },
    [start_date, end_date, baseModelId, comparisonID, monthContextData]
  )

  const aggregatedData = useMemo(() => {
    return data.reduce(
      (acc, record) => ({
        ...acc,
        [record.month]: {
          ...acc[record.month],
          [record.execution_id]: calcExecutionIdData({
            record,
            currentData: acc[record.month][record.execution_id],
            baseModelId,
            capacityKey,
            comparisonID
          }),
          ...Object.entries(monthContextData).reduce(
            (a, [key, cb]) => ({
              ...a,
              [key]: cb({
                record,
                currentData: acc[record.month][key],
                capacityKey,
                baseModelId,
                comparisonID
              })
            }),
            {}
          )
        }
      }),
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )
  }, [
    data,
    selectedProducts,
    getMonthsWithDefaultData,
    baseModelId,
    comparisonID,
    monthContextData,
    calcExecutionIdData,
    capacityKey
  ])

  const volumeGraphData = useMemo(() => {
    return Object.entries(aggregatedData).map(([month, monthlyData]) => {
      const contextKeys = Object.keys(monthContextData)
      const { volumes, context } = Object.entries(monthlyData).reduce(
        (a, [key, value]) => {
          const keyToUse = contextKeys.includes(key) ? 'context' : 'volumes'
          return {
            ...a,
            [keyToUse]: { ...a[keyToUse], [key]: value }
          }
        },
        { volumes: {}, context: {} }
      )
      return {
        month,
        volumes,
        ...context
      }
    })
  }, [monthContextData, aggregatedData])

  const utilizationGraphData = useMemo(() => {
    if (calculateUtilization) {
      return Object.entries(aggregatedData).map(([month, volumes]) => {
        const capacity = volumes.capacity

        const calcUtilization = (volume, executionId) =>
          volume === NO_DATA_LABEL
            ? volume
            : (volume / capacity[executionId]) * 100

        const getUtilization = (executionId) => {
          if (typeof volumes[executionId] === 'object') {
            return Object.entries(volumes[executionId])
              .map(([key, volume]) => ({
                [key]: calcUtilization(volume, executionId)
              }))
              .reduce((a, c) => ({ ...a, ...c }), {})
          }
          return calcUtilization(volumes[executionId], executionId)
        }

        return {
          month,
          ...volumes,
          volumes: {
            [baseModelId]: getUtilization(baseModelId),
            [comparisonID]: getUtilization(comparisonID)
          }
        }
      })
    }
    return undefined
  }, [aggregatedData, baseModelId, comparisonID, calculateUtilization])

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const bars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId ? 'Base Model' : 'Comparison'
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths]
  )

  return {
    volumeGraphData,
    utilizationGraphData,
    bars
  }
}

export default useGraph
