import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

function useOverviewData() {
  const { id } = useParams()
  const { comparisonID, selectedProducts } = useOptimizationContext()

  const { data } = useSWR([
    `/execution/outputs/${id}/overview`,
    { bestEstimateId: comparisonID }
  ])

  function getUniqueKey(data, key, type = 'volumeOverview') {
    return data[type]
      .map((item) => item[key])
      .filter((value, index, array) => array.indexOf(value) === index)
  }

  function aggregateVolumes(data, productUIType = '', type = 'volumeOverview') {
    const aggregatedData = {}

    for (const entry of data[type]) {
      const {
        month,
        type_volume,
        type_financial,
        volume,
        execution_id,
        product_ui_type
      } = entry
      const key =
        productUIType === ''
          ? `${month}_${type_volume || type_financial}_${execution_id}`
          : `${month}_${
              type_volume || type_financial
            }_${execution_id}_${product_ui_type}`

      if (key in aggregatedData) {
        aggregatedData[key].volume += volume
      } else {
        const newEntry = {
          month,
          execution_id,
          type_volume,
          type_financial,
          volume,
          product_ui_type: productUIType === '' ? 'total' : product_ui_type
        }
        aggregatedData[key] = newEntry
      }
    }

    return productUIType === ''
      ? Object.values(aggregatedData)
      : Object.values(aggregatedData).filter(
          (value) => value.product_ui_type === productUIType
        )
  }

  function getReturnData(data, type = 'volumeOverview') {
    const uniqueProducts = getUniqueKey(data, 'product_ui_type', type)

    const filteredData = {}
    if (selectedProducts.length > 0) {
      for (const type in data) {
        const filtered = data[type].filter((v) =>
          selectedProducts.length > 0
            ? selectedProducts.includes(v.product_ui_type)
            : true
        )
        filteredData[type] = filtered
      }
    }

    const returnData = [
      aggregateVolumes(
        Object.keys(filteredData).length === 0 ? data : filteredData,
        undefined,
        type
      )
    ]

    uniqueProducts.forEach((product) => {
      returnData.push(
        aggregateVolumes(
          Object.keys(filteredData).length === 0 ? data : filteredData,
          product,
          type
        )
      )
    })

    return selectedProducts.length === 1 ? returnData.slice(1) : returnData
  }

  const aggregatedVolume = getReturnData(data).filter((item) => item.length)
  const aggregatedFinancialWithoutAbsolute = getReturnData(data, 'financialOverview').filter(
    (item) => item.length
  )

  const aggregatedFinancial = aggregatedFinancialWithoutAbsolute.map(innerArray => {
    return innerArray.map(obj => {
      if (obj.type_financial.toLowerCase() === 'cost') {
        return {
          ...obj,
          volume: Math.abs(obj.volume)
        };
      }
      return obj;
    });
  });

  return {
    aggregatedVolume,
    aggregatedFinancial
  }
}

export default useOverviewData
