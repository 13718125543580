import { useState, useEffect } from 'react'
import { GridLoader } from 'react-spinners'
import classNames from 'classnames'
import BaselineRunSettingsCard from '../../../../components/Cards/BaselineRunSettingsCard'
import { useLocalization } from 'providers/LocalizationProvider'
import { get } from 'utils/request'
import { areAllRangesIdentical } from '../../../../utils'

const BaselineRunSettings = () => {
  const { countryCode, t } = useLocalization()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSwictherChange = (key, value) => {
    const updatedSwitcherData = data.map((item) => {
      if (item.title === key) {
        item.swictherChecked = value
        return item
      }
    })
    setData(updatedSwitcherData)
  }

  const handleCheckBoxChange = (key) => {
    const updatedCheckboxData = data.map((item) => {
      if (item.title === key) {
        item.checkBoxChecked = !item.checkBoxChecked
        return item
      }
    })
    setData(updatedCheckboxData)
  }

  const getData = async () => {
    setLoading(true)
    const { data: apiData } = await get(
      `/execution/settings/${countryCode.toLowerCase()}/baseline`
    )
    const isSwitcherChecked = (value) => value && value !== 'off';
    const originationPlanRangeValues = apiData?.origination_range_products ? Object.values(apiData?.origination_range_products) : []

    if (countryCode.toLowerCase() === 'br') {
      const newData = [
        (apiData.origination_plan = {
          api_name: 'origination_plan',
          title: 'Origination plan',
          swictherChecked: isSwitcherChecked(apiData.origination_plan),
          hasSlider: true,
          value: 0,
          isUnlimited: true,
          allowUnmetValue: true,
          checkBoxChecked: !!apiData?.allow_unmet_contracted_origination,
          configureProducts: false
        }),
        (apiData.export_plan = {
          api_name: 'export_plan',
          title: 'Exports plan',
          value: apiData?.export_plan_percent ?? 0,
          hasSlider: true,
          swictherChecked: isSwitcherChecked(apiData.export_plan),
          isUnlimited: apiData?.export_plan === 'unlimited',
          allowUnmetValue: true,
          checkBoxChecked: !!apiData?.export_plan_allow_unmet_value
        }),
        (apiData.crushing_plan = {
          api_name: 'crushing_plan',
          title: 'Crushing plan',
          swictherChecked: isSwitcherChecked(apiData?.crushing_plan),
          checkBoxChecked: !!apiData?.crushing_plan_allow_unmet_value,
          allowUnmetValue: !!apiData?.crushing_plan_allow_unmet_value
        }),
        (apiData.grains_domestic_demand = {
          api_name: 'grains_domestic_demand',
          title: 'Grains domestic demand',
          checkBoxChecked: !!apiData?.grains_domestic_demand,
          swictherChecked: isSwitcherChecked(apiData.grains_domestic_demand),
        }),
        (apiData.crush_processed_demand = {
          api_name: 'crush_processed_demand',
          title: 'Crush processed demand',
          checkBoxChecked: !!apiData?.crush_processed_demand,
          swictherChecked: isSwitcherChecked(apiData.crush_processed_demand),
        }),
        (apiData.rail_transportation_plan = {
          api_name: 'rail_transportation_plan',
          title: 'Rail transportation plan',
          checkBoxChecked: !!apiData?.rail_transportation_plan_allow_unmet_value,
          swictherChecked: isSwitcherChecked(apiData.rail_transportation_plan),
        }),
        (apiData.crushing_maintenance_plan = {
          api_name: 'crushing_maintenance_plan',
          title: 'Crushing maintenance plan',
          swictherChecked: !!apiData?.crushing_maintenance_plan,
          hideCheckbox: true
        }),
        (apiData.hidro_transportation_plan = {
          api_name: 'hidro_transportation_plan',
          title: 'Hidro transportation plan',
          swictherChecked: isSwitcherChecked(apiData?.hidro_transportation_plan),
          checkBoxChecked: !!apiData?.hidro_transportation_plan_allow_unmet_value
        }),
        (apiData.crush_alcance = {
          api_name: 'crush_alcance',
          title: 'Crush alcance',
          swictherChecked: isSwitcherChecked(apiData?.crush_alcance),
          hideCheckbox: true
        }),
        (apiData.ports_spot_flow = {
          api_name: 'ports_spot_flow',
          title: 'Ports Spot Flow',
          swictherChecked: isSwitcherChecked(apiData?.ports_spot_flow),
          hideCheckbox: true
        }),
        (apiData.allow_silo_fob_volume = {
          api_name: 'allow_silo_fob_volume',
          title: 'Allow silo FOB volume',
          swictherChecked: isSwitcherChecked(apiData.allow_silo_fob_volume),
          hideCheckbox: true
        }),
        (apiData.sold_vessels = {
          api_name: 'sold_vessels',
          title: 'Allow to not meet Sold vessels demand',
          swictherChecked: isSwitcherChecked(apiData.sold_vessels),
          hideCheckbox: true
        }),
        (apiData.asset_product_expiration_time = {
          api_name: 'asset_product_expiration_time',
          title: 'Asset product expiration time',
          hideCheckbox: true
        }),
        (apiData.min_end_of_stock = {
          api_name: 'min_end_of_stock',
          title: 'Minimum end of stock',
          hideCheckbox: true
        }),
        (apiData.product_expiration_time = {
          api_name: 'product_expiration_time',
          title: 'Product expiration time',
          swictherChecked: isSwitcherChecked(apiData.product_expiration_time),
          hideCheckbox: true
        }),
        (apiData.allow_constraints_violation = {
          api_name: 'allow_constraints_violation',
          title: 'Allow constraints violation',
          hideCheckbox: true
        }),
        (apiData.asset_end_of_stock = {
          api_name: 'asset_end_of_stock',
          title: 'Comply with minimum end of stock volumes per Asset',
          checked: !!apiData?.asset_end_of_stock
        })
      ]
      setData(newData)
    } else if (countryCode.toLowerCase() === 'arg') {
      const newData = [
        (apiData.origination_plan = {
          api_name: 'origination_plan',
          title: 'origination_plan',
          swictherChecked: isSwitcherChecked(apiData.origination_plan),
          checkBoxChecked: !!apiData?.allow_unmet_contracted_origination,
          hasSlider: true,
          value: 0,
          originationPlanRange: areAllRangesIdentical(originationPlanRangeValues) ? originationPlanRangeValues[0] : [5, 5],
          isUnlimited: true,
          allowUnmetValue: true
        }),
        (apiData.export_plan = {
          api_name: 'export_plan',
          title: 'Exports plan',
          value: apiData?.export_plan_percent ?? 0,
          hasSlider: true,
          swictherChecked: isSwitcherChecked(apiData.export_plan),
          checkBoxChecked: !!apiData?.export_plan_allow_unmet_value,
          isUnlimited: apiData?.export_plan === 'unlimited',
          allowUnmetValue: !!apiData?.export_plan_allow_unmet_value
        }),
        (apiData.crushing_plan = {
          api_name: 'crushing_plan',
          title: 'Crushing plan',
          swictherChecked: isSwitcherChecked(apiData.crushing_plan),
          checkBoxChecked: !!apiData?.crushing_plan_allow_unmet_value,
          allowUnmetValue: !!apiData?.crushing_plan_allow_unmet_value
        }),
        (apiData.crushing_maintenance_plan = {
          api_name: 'crushing_maintenance_plan',
          title: 'Crushing maintenance plan',
          swictherChecked: isSwitcherChecked(apiData.crushing_maintenance_plan),
          hideCheckbox: true
        }),
        (apiData.min_end_of_stock = {
          api_name: 'min_end_of_stock',
          title: 'Minimum end of stock',
          hideCheckbox: true
        }),
        (apiData.crush_processed_demand = {
          api_name: 'crush_processed_demand',
          title: 'Crush processed demand',
          checkBoxChecked: !!apiData?.crush_processed_demand,
          swictherChecked: isSwitcherChecked(apiData.crush_processed_demand),
        }),
        (apiData.hidro_transportation_plan = {
          api_name: 'hidro_transportation_plan',
          title: 'Transportation ToP plan',
          checked: !!apiData?.hidro_transportation_plan
        }),
        (apiData.product_expiration_time = {
          api_name: 'product_expiration_time',
          title: 'Product expiration time',
          swictherChecked: isSwitcherChecked(apiData.product_expiration_time),
          hideCheckbox: true
        }),
        (apiData.asset_product_expiration_time = {
          api_name: 'asset_product_expiration_time',
          title: 'Asset product expiration time',
          hideCheckbox: true
        }),
        (apiData.allow_constraints_violation = {
          api_name: 'allow_constraints_violation',
          title: 'Allow constraints violation',
          hideCheckbox: true
        }),
        (apiData.allow_broker_crush = {
          api_name: 'allow_broker_crush',
          title: 'Rail transportation plan',
          checkBoxChecked: !!apiData?.allow_broker_crush
        })
      ]
      setData(newData)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading ? (
    <div className='px-36 flex items-center py-4 rounded-md bg-grey-200'>
      <GridLoader color='#7EA2F4' loading={true} size={10} />
    </div>
  ) : (
    <div className='flex flex-auto flex-wrap gap-4 mb-4'>
      {data?.map((item, i) => (
        <div
          className={classNames('basis-full grow lg:basis-2/6', {
            'last:grow-[.24]': data.length % 2 !== 0
          })}
          key={i}
        >
          <BaselineRunSettingsCard
            key={i}
            api_name={item.api_name}
            title={t(
              `newOptimizationScreen.sections.settings.cardItems.${countryCode}.${item.api_name}.title`
            )}
            hasSlider={item.hasSlider ? true : false}
            sliderValues={item.api_name === 'origination_plan' ? item.originationPlanRange ?? [5, 5] : [item.value]}
            disabledSlider={true}
            items={data}
            isUnlimited={item.isUnlimited}
            swictherchecked={item.swictherChecked}
            hideCheckbox={item.hideCheckbox}
            disabledSwicther={true}
            checkBoxChecked={item.checkBoxChecked}
            disabledCheckBox={true}
            handleSwictherChange={handleSwictherChange}
            onChange={handleCheckBoxChange}
          />
        </div>
      ))}
    </div>
  )
}

export default BaselineRunSettings

