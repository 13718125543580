import Button from 'components/Inputs/Button'
import { ChipStatus } from 'components/Feedback/ChipStatus'
import classNames from 'classnames'
import { useLocalization } from 'providers/LocalizationProvider'

const InconsistenciesSidebar = ({
  data,
  onClick,
  selected,
  runOptimization
}) => {
  const { t } = useLocalization()

  const hasBlocks = Array.isArray(data?.blocks) && data.blocks.length > 0
  const hasWarnings = Array.isArray(data?.warnings) && data.warnings.length > 0

  return (
    <div>
      <h1 className='text-2xl font-bold'>
        {t('optimizationScreen.inconsistencies.title')}
      </h1>
      <p className='mb-4 mt-6 text-gray-500'>
        {t('optimizationScreen.inconsistencies.subtitle')}
      </p>
      {hasBlocks && (
        <section>
          <h3 className='font-bold text-lg mt-6 mb-4'>
            {t('optimizationScreen.inconsistencies.blocks')}
          </h3>
          <div className='gap-2 flex flex-col'>
            {data.blocks.map((block, index) => {
              return (
                <div
                  key={block + index}
                  className={classNames(
                    'bg-white px-4 py-3 rounded-lg gap-4 flex items-center text-md cursor-pointer border',
                    {
                      'shadow-md border-[#3668D5]': selected === block
                    }
                  )}
                  onClick={() => onClick(block)}
                >
                  <ChipStatus className='w-fit' icon error />{' '}
                  <b>{block.description}</b>
                </div>
              )
            })}
          </div>
        </section>
      )}
      {hasWarnings && (
        <section>
          <h3 className='font-bold text-lg mt-6 mb-4'>
            {t('optimizationScreen.inconsistencies.warnings')}
          </h3>
          <div className='gap-2 flex flex-col'>
            {data.warnings.map((warning, index) => {
              return (
                <div
                  key={warning.title + index}
                  className={classNames(
                    'bg-white px-4 py-3 rounded-lg gap-4 flex items-center text-md cursor-pointer border',
                    {
                      'shadow-md border-[#3668D5]': selected === warning
                    }
                  )}
                  onClick={() => onClick(warning)}
                >
                  <ChipStatus className='w-fit' icon warning />{' '}
                  <b>{warning.description}</b>
                </div>
              )
            })}
          </div>
        </section>
      )}
      <div className='w-full text-end py-10'>
        <Button variant='filled' onClick={runOptimization}>
          {t('optimizationScreen.inconsistencies.runOptimization')}
        </Button>
      </div>
    </div>
  )
}

export default InconsistenciesSidebar
