import { useMemo, useState } from "react";
import {
  Data,
  EdgeMock,
  NodeMock,
  edgeColors,
  nodeColors,
  nodeShapes,
} from "../../types";
import { nodesMock } from "../../mock/nodesMock";
import { edgesMock } from "../../mock/edgesMock";

export const useDataSet = () => {
  const [dataSet] = useState<Data<NodeMock[], EdgeMock[]>>({
    nodes: nodesMock.map((node) => ({
      ...node,
      shape: nodeShapes[node.level],
      color: nodeColors[node.level],
    })),
    edges: edgesMock.map((edge) => ({
      ...edge,
      color: {
        color: edgeColors[edge.title],
      },
    })),
  });

  const periods = useMemo(() => {
    const uniquePeriods = new Set<number>();

    dataSet.nodes.forEach((node) => {
      uniquePeriods.add(node.data.TimePeriod);
    });
    return Array.from(uniquePeriods).sort((a, b) => a - b);
  }, [dataSet]);

  return { dataSet, periods };
};
