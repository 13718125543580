import BarChart from 'components/Graphs/BarChart'

const MultiGraphsTable = ({
  data,
  total,
  title,
  bars,
  tooltipFormatter,
  yAxisFormatter,
  xAxisProps
}) => {
  return (
    <>
      {total !== 0 && (
        <div className='w-full'>
          <div className='h-full overflow-x-scroll overflow-y-hidden'>
            <div className='w-full h-[18rem] flex flex-row  pt-5 bg-white'>
              <div className='h-full z-10 sticky left-0 bg-white px-5'>
                <span className='w-40 font-bold flex justify-center text-center my-[100px]'>
                  {title}
                </span>
              </div>
              <div className='-mt-1 w-full flex flex-col'>
                <BarChart
                  data={data}
                  bars={bars}
                  tooltipFormatter={tooltipFormatter}
                  yAxisProps={{ tickFormatter: yAxisFormatter }}
                  xAxisProps={xAxisProps}
                />
              </div>
            </div>
            <div className='bg-grey-200 border-t sticky left-0 border-grey-300 z-10 flex-1'>
              <div className='font-bold pr-2 text-right'>Total: {total}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MultiGraphsTable
