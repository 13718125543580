import CrushingForm from './CrushingForm'
import useSWR from 'swr'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'utils/request'
import {
  getPortsOption,
  getSiloCoverageOptions
} from 'screens/Configuration/utils'
import { useCity } from 'providers/CityProvider'

const Crushings = () => {
  const { id } = useParams()
  const { getCityById } = useCity()
  const { data: silos } = useSWR('/silo', get)
  const { data: ports } = useSWR('/port', get)

  const { data: corridors } = useSWR('/corridors', async (url) => {
    const { data } = await get(url)
    return data
  })

  const { data } = useSWR(id ? `/crusher/${id}` : null, get)

  const silosWithCities = useMemo(() => {
    return silos.map((silo) => ({
      ...silo,
      city: getCityById(silo.city_id)
    }))
  }, [silos, getCityById])

  const currentData = useMemo(() => {
    if (!data) {
      return
    }

    const { crusher_name, city_id, ownership, silo_coverage, include } = data

    const { country, state } = getCityById(city_id)

    const coverdSilos = silosWithCities.filter((silo) =>
      silo_coverage.includes(silo.id)
    )

    return {
      crusher_name,
      country,
      state,
      city_id,
      ownership,
      silo_coverage: getSiloCoverageOptions(coverdSilos, corridors),
      ports: getPortsOption(data.ports, ports),
      include
    }
  }, [corridors, silosWithCities, data, getCityById, ports])

  const assetData = useMemo(
    () => ({ silos: silosWithCities, corridors, ports }),
    [silosWithCities, corridors, ports]
  )

  return (
    <CrushingForm id={id} assetData={assetData} currentData={currentData} />
  )
}

export default Crushings
