import { useState } from 'react';
import * as Slider from '@radix-ui/react-slider';
import classNames from 'classnames'

import './multi-range-slider.css'

/**
 * MultiRangeSlider component for selecting a range between a minimum and maximum value or a single value.
 *
 * @param {Object} props - The props that the component receives.
 * @param {Array<number>} [props.values=[0, 0]] - The current value of the slider, represented as an array [min, max].
 * @param {Array<number>} [props.defaultValues=[0, 0]] - The default value of the slider, represented as an array [min, max].
 * @param {string} [props.minColor='#C836D5'] - The color for the minimum value track.
 * @param {string} [props.maxColor='#3668D5'] - The color for the maximum value track.
 * @param {number} [props.max=50] - The maximum value of the slider.
 * @param {string} [props.title] - Optional title to display above the slider.
 * @param {boolean} [props.isUnlimited=false] - If set to true, the 'Unlimited' label will be shown instead of the max value.
 * @param {string} [props.label] - Optional label for the single slider thumb.
 * @param {string} [props.thumbPosition='top'] - Position of the thumb label, can be 'top' or 'bottom'.
 * @param {string} [props.className] - Optional CSS class to apply custom styling.
 *
 * @example
 * // Range slider with specific range
 * <MultiRangeSlider values={[10, 20]} defaultValues={[5, 15]} />
 *
 * @example
 * // Single-value slider with a specific value
 * <MultiRangeSlider values={[10]} defaultValues={[5]} />
 *
 * This component also accepts all props that the Slider component from @radix-ui/react-slider accepts.
 */
const MultiRangeSlider = ({
  values = [0, 0],
  defaultValues = [0, 0],
  maxColor = '#3668D5',
  minColor = '#C836D5',
  max = 50,
  defaultValue = 5,
  title,
  isUnlimited,
  label,
  thumbPosition = 'top',
  className,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const isSingle = Array.isArray(values) && values.length === 1;
  const [minValue, maxValue] = values.length === 2 ? values : [values[0], values[0]];

  const sliderLabel = !isSingle ? 'Max' : label || undefined

  return (
    <div
      className={classNames(
        'pt-10',
        className
      )}
      style={{
        '--maxColor': maxColor,
        '--minColor': isSingle ? maxColor : minColor
      }}
    >
      {title ? <p className='text-xs mb-2 select-none font-bold'> {title} </p> : null}
      {!!sliderLabel && <p className='text-xs text-gray-300 mb-3 select-none'> {sliderLabel} </p>}
      <Slider.Root
        {...props}
        value={values}
        defaultValue={defaultValues}
        className="SliderRoot"
        max={max}
      >
        <Slider.Track
          className="SliderTrack"
          style={{
            background: `linear-gradient(to right, ${maxColor} 0%, ${maxColor} ${maxValue / max * 100}%, #dddddd ${maxValue / max * 100}%, #dddddd 100%)`
          }}
        >
          <Slider.Range
            className='SliderRange'
            style={{  backgroundColor: isSingle ? maxColor : minColor }}
          />
        </Slider.Track>

        {!isSingle && (
          <Slider.Thumb
            className="SliderThumb min"
            aria-label="min"
            onMouseOver={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            style={{
              transform: `translateX(${(minValue / max - 1) * 100}%)`
            }}
            key='min'
          >
            <span
              className={classNames(
                'tooltip',
                {
                  'opacity-0': !showTooltip,
                  'opacity-100': showTooltip,
                }
              )}
            >
              {minValue}%
            </span>
          </Slider.Thumb>
        )}

        <Slider.Thumb
          className={`SliderThumb ${thumbPosition === 'top' ? 'max' : 'min'}`}
          aria-label="max"
          onMouseOver={() => setShowTooltip(true)}
          onMouseOut={() => setShowTooltip(false)}
          style={{
            transform: `translateX(${(maxValue / max - 1) * 100}%)`,
          }}
          key='max'
        >
          <span
            className={classNames(
              'tooltip',
              {
                'opacity-0': !showTooltip,
                'opacity-100': showTooltip,
              }
            )}
            style={{
              width: isUnlimited && maxValue[0] === max ? '68px' : '37px'
            }}
          >
            {isUnlimited && maxValue[0] === max ? 'Unlimited': `${maxValue}%`}
          </span>
        </Slider.Thumb>

      </Slider.Root>
      <div className="relative flex justify-between text-xs font-bold">
        <span className="absolute bottom-[-20px] left-0 select-none">0</span>
        <span
          className="absolute bottom-[-20px] select-none"
          style={{ left: `${((defaultValue / max) * 100).toFixed(0)}%`, transform: 'translateX(-50%)' }}
        >
          Default
        </span>
        <span className="absolute bottom-[-20px] right-0 select-none">{isUnlimited ? 'Unlimited': max}</span>
      </div>
      {!isSingle && <p className='text-xs text-gray-300 mt-9 select-none'> Min </p>}
    </div>
  )
}

export default MultiRangeSlider