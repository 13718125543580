import axios from 'axios'
import { GENERAL_ASSETS_VIEW } from 'constants'
import { useLocalization } from 'providers/LocalizationProvider'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { groupBy } from 'ramda'
import { wordDistance } from 'utils'
import { postDatasetFile } from 'utils/request'
import {
  templateTypeFromFilename,
  translateValidationErrors
} from 'utils/string'

const initialState = {
  page: 0,
  selectedFile: null,
  selectedAsset: GENERAL_ASSETS_VIEW,
  showUpdateModal: false,
  showHistoryDrawer: false,
  showBatchUpload: false,
  fileType: undefined,
  templates: [],
  fileVersions: {},
  search: '',
  updatedFileIds: []
}

const NewOptContext = createContext(initialState)
NewOptContext.displayName = 'NewOptContext'

let batchUploadSessionId = -1

export const NewOptProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  const [validationResultsOnFile, setValidationResultsOnFile] = useState({})
  const { t, countryCode } = useLocalization()
  const [templateDistances, setTemplateDistances] = useState([])
  const [mappedFileList, setMappedFileList] = useState([])
  const [showAllFiles, setShowAllFiles] = useState(false)

  const setPage = (page) => {
    setState((prev) => ({ ...prev, page }))
  }

  const setShowUpdateModal = (showUpdateModal) => {
    setState((prev) => ({ ...prev, showUpdateModal }))
  }

  const setShowHistoryDrawer = (showHistoryDrawer) => {
    setState((prev) => ({ ...prev, showHistoryDrawer }))
  }

  const setShowBatchUpload = (showBatchUpload) => {
    setState((prev) => {
      if (showBatchUpload !== prev.showBatchUpload) {
        batchUploadSessionId = Math.round(Math.random() * 10000000000)

        if (showBatchUpload) {
          setTemplateDistances([])
          setMappedFileList([])
          setValidationResultsOnFile({})
          setShowAllFiles(false)
        }
      }

      return { ...prev, showBatchUpload }
    })
  }

  const setFileType = (fileType) => {
    setState((prev) => ({ ...prev, fileType }))
  }

  const setSelectedFile = (selectedFile) => {
    setState((prev) => ({ ...prev, selectedFile }))
  }

  const setSelectedAsset = (selectedAsset) => {
    setState((prev) => ({ ...prev, selectedAsset }))
  }

  const setTemplates = (templates) => {
    setState((prev) => ({ ...prev, templates }))
  }

  const setSearch = (search) => {
    setState((prev) => ({ ...prev, search }))
  }

  const setFileVersion = useCallback((key, fileVersions) => {
    setState((prev) => ({
      ...prev,
      fileVersions: { ...prev.fileVersions, [key]: fileVersions }
    }))
  }, [])

  const setUpdatedFileIds = useCallback((payload) => {
    setState((prev) => ({
      ...prev,
      updatedFileIds:
        typeof payload === 'function' ? payload(prev.updatedFileIds) : payload
    }))
  }, [])

  const reset = () => {
    setState(initialState)
  }

  const getTemplateDistance = useCallback(
    (file) => {
      const lcFileName = file.name.toLowerCase()
      const incomingTemplateType = templateTypeFromFilename(
        countryCode,
        lcFileName
      )

      const possibleTemplates = state.templates
        ?.map((template) => ({
          file: file.name,
          templateFile: template.name,
          type: template.type,
          distance: wordDistance(incomingTemplateType, template.type)
        }))
        .sort((a, b) => a.distance - b.distance)

      return {
        name: file.name,
        type: possibleTemplates[0]?.type,
        templateFile: possibleTemplates[0]?.templateFile,
        distance: possibleTemplates[0]?.distance
      }
    },
    [state.templates, countryCode]
  )

  const getMappedFile = useCallback(
    (file, templateDistance) => ({
      rawFile: file.rawFile,
      size: file.rawFile.size,
      name: file.name,
      matched: templateDistance?.distance === 0,
      type: templateDistance?.type
    }),
    []
  )

  const upload = useCallback(
    (file) => {
      const initiatorSessionId = batchUploadSessionId
      const cancelSource = axios.CancelToken.source()
      postDatasetFile(
        '/data/validate',
        {
          type: file.type,
          file: file.rawFile
        },
        ({ total, loaded }) => {
          if (initiatorSessionId !== batchUploadSessionId) {
            cancelSource.cancel()
            return
          }

          setValidationResultsOnFile((prev) => ({
            ...prev,
            [file.name]: {
              valid: undefined,
              validationProgress: Math.round((loaded * 100) / total)
            }
          }))
        },
        cancelSource
      )
        .then(() => {
          if (initiatorSessionId !== batchUploadSessionId) {
            return
          }

          setValidationResultsOnFile((prev) => ({
            ...prev,
            [file.name]: {
              valid: true
            }
          }))
        })
        .catch((error) => {
          if (initiatorSessionId !== batchUploadSessionId) {
            return
          }

          const errorData = translateValidationErrors(
            t,
            error?.response?.data?.data
          )

          setValidationResultsOnFile((prev) => ({
            ...prev,
            [file.name]: {
              valid: false,
              errors: errorData
            }
          }))

          if (!errorData || errorData.length === 0) {
            console.error(
              'Received invalid validation error message:',
              errorData,
              'for file',
              file.name,
              'and type:',
              file.type
            )
            console.error(error)
          }
        })
    },
    [t]
  )

  const setSelectedTemplate = useCallback(
    (file, template) => {
      setMappedFileList((prev) =>
        prev.map((item) => ({
          ...item,
          type: item.name === file.name ? template : item.type
        }))
      )

      upload({ ...file, type: template })
    },
    [upload]
  )

  const onFileLoad = useCallback(
    (file, fileNameToReplace) => {
      const newFile = {
        rawFile: file,
        name: file.name,
        type: 'TEST',
        description: file.name
      }
      const templateDistance = getTemplateDistance(newFile)
      setTemplateDistances((prev) =>
        fileNameToReplace
          ? prev.map((i) => ({
              ...(i.name === fileNameToReplace ? templateDistance : i)
            }))
          : [...prev, templateDistance]
      )
      const mappedFile = getMappedFile(newFile, templateDistance)
      setMappedFileList((prev) =>
        fileNameToReplace
          ? prev.map((i) => ({
              ...(i.name === fileNameToReplace ? mappedFile : i)
            }))
          : [...prev, mappedFile]
      )
      upload(mappedFile)
    },
    [upload, getMappedFile, getTemplateDistance]
  )

  const onDrop = useCallback(
    (acceptedFiles) => {
      setShowBatchUpload(true)
      for (const file of acceptedFiles) {
        onFileLoad(file)
      }
      if (acceptedFiles.length < 10) {
        setShowAllFiles(true)
      }
    },
    [onFileLoad]
  )

  const removeFile = useCallback((name) => {
    setMappedFileList((prev) => prev.filter((x) => x.name !== name))
  }, [])

  const {
    allFilesValid,
    hiddenFileCount,
    fileCount,
    invalidFiles,
    duplicateTemplates
  } = useMemo(() => {
    const fileCounts = Object.entries(groupBy((x) => x.type, mappedFileList))
      .map(([type, files]) => ({ type, files: files.length }))
      .filter((x) => x.type === 'undefined' || x.files !== 1)

    const invalidFiles = mappedFileList.filter(
      (x) => validationResultsOnFile[x.name]?.valid === false
    ).length
    const duplicateTemplates = fileCounts
      .filter((x) => x.type !== 'undefined')
      .map((x) => x.type)
    const hiddenFileCount = mappedFileList.filter(
      (x) =>
        validationResultsOnFile[x.name]?.valid === true &&
        x.matched &&
        !duplicateTemplates.includes(x.type)
    ).length

    return {
      invalidFiles,
      hiddenFileCount,
      fileCount: mappedFileList.length,
      duplicateTemplates,
      allFilesValid:
        duplicateTemplates.length === 0 &&
        invalidFiles === 0 &&
        !mappedFileList.some(
          (x) => validationResultsOnFile[x.name]?.valid !== true
        )
    }
  }, [mappedFileList, validationResultsOnFile])

  const value = useMemo(
    () => ({
      ...state,
      setPage,
      setSelectedAsset,
      setShowUpdateModal,
      setShowHistoryDrawer,
      setShowBatchUpload,
      setFileType,
      setSelectedFile,
      setTemplates,
      setSearch,
      setFileVersion,
      setUpdatedFileIds,
      reset,
      onDrop,
      mappedFileList,
      templateDistances,
      validationResultsOnFile,
      setSelectedTemplate,
      onFileLoad,
      showAllFiles,
      setShowAllFiles,
      allFilesValid,
      hiddenFileCount,
      fileCount,
      invalidFiles,
      duplicateTemplates,
      removeFile
    }),
    [
      state,
      onDrop,
      mappedFileList,
      templateDistances,
      validationResultsOnFile,
      setSelectedTemplate,
      onFileLoad,
      setUpdatedFileIds,
      setFileVersion,
      showAllFiles,
      setShowAllFiles,
      allFilesValid,
      hiddenFileCount,
      fileCount,
      invalidFiles,
      duplicateTemplates,
      removeFile
    ]
  )

  return (
    <NewOptContext.Provider value={value}>{children}</NewOptContext.Provider>
  )
}

export function useNewOptContext() {
  const context = useContext(NewOptContext)
  if (context === undefined) {
    throw new Error('useNewOptContext must be used within a NewOptProvider')
  }
  return context
}
