const empty = {
  defaultScatterProps: {},
  defaultCartesianGridProps: {},
  defaultXAxisProps: {},
  defaultYAxisProps: {},
  defaultBarProps: {},
  defaultWrapWithContainer: false,
  defaultContainerClassNames: '',
  defaultShowLegend: true
}

const defaultVariant = {
  ...empty,
  defaultScatterProps: {
    color: '#8E5602',
    gap: 10
  },
  defaultCartesianGridProps: {
    strokeDasharray: '3'
  },
  defaultXAxisProps: {
    strokeWidth: 1,
    tickSize: 6
  },
  defaultYAxisProps: {
    strokeWidth: 1,
    tickSize: 6
  }
}

export const variants = {
  small: {
    ...empty,
    defaultScatterProps: {
      color: '#8E5602',
      gap: 10
    },
    defaultCartesianGridProps: {
      vertical: false,
      strokeDasharray: '6'
    },
    defaultXAxisProps: {
      strokeWidth: 1,
      tickSize: 0
    },
    defaultYAxisProps: {
      type: 'number',
      domain: [0, 'auto'],
      strokeWidth: 1,
      tickSize: 0
    },
    defaultBarProps: {
      fill: '#1950CA',
      barSize: 5
    },
    defaultShowLegend: false
  },
  default: defaultVariant,
  default350: {
    ...defaultVariant,
    defaultWrapWithContainer: true,
    defaultContainerClassNames: 'h-[350px]'
  }
}
