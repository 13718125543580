import { PRODUCTS, TRANSPORTATION_MODES } from './constants'
import { findKey } from 'lodash'

export const getSiloCoverageOptions = (silos, corridors) =>
  silos.map((silo) => {
    const corridorLabel = findKey(corridors.regions, (region) =>
      region.mesoregions?.includes(silo.city.meso)
    )

    return {
      label: `${silo.silo_name} (${corridorLabel} / ${silo.city.meso})`,
      value: silo.id
    }
  })

export const getPortsOption = (selectedPort, ports) => {
  if (!selectedPort || selectedPort.length <= 0) {
    return []
  }

  const portData = ports.find(item => item.id === selectedPort)
  return portData ? [{ label: portData.terminal_name, value: portData.id }] : []
}

export const getProductsWithPrecheckedValues = (checkedProducts) =>
  [...PRODUCTS].map((p) => ({
    ...p,
    checked: checkedProducts.includes(p.value)
  }))

export const getTransportationModesWithPrecheckedValues = (
  checkedTransportationModes
) =>
  [...TRANSPORTATION_MODES].map((p) => ({
    ...p,
    checked: checkedTransportationModes.includes(p.value)
  }))

export const checkedValuesReducer = (acc, cur) => {
  if (cur.checked) {
    return [...acc, cur.value]
  }
  return acc
}
