import ErrorBoundary from 'components/ErrorBoundary'
import { useNewOptContext } from '../NewOptContext'
import classNames from 'classnames'

import {
  FolderIcon,
  BookmarkIcon,
  AdjustmentsVerticalIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { useLocalization } from 'providers/LocalizationProvider'

const ICON_STYLE = 'w-8 h-8 text-blue-400 bg-blue-100 rounded-md p-1 mr-4'

const SideBar = () => {
  const { page, setPage } = useNewOptContext()
  const { t } = useLocalization()

  const MENU_ITEMS = useMemo(
    () => [
      {
        icon: <AdjustmentsVerticalIcon className={ICON_STYLE} />,
        text: t('newOptimizationScreen.sideBar.menuItems.settings')
      },
      {
        icon: <BookmarkIcon className={ICON_STYLE} />,
        text: t('newOptimizationScreen.sideBar.menuItems.assetCapacities')
      },
      {
        icon: <FolderIcon className={ICON_STYLE} />,
        text: t('newOptimizationScreen.sideBar.menuItems.files')
      }
    ],
    [t]
  )

  return (
    <ErrorBoundary fallback={<div>Error on NewOpt/Sidebar</div>}>
      <div className='bg-blue-100 w-24 flex items-center justify-center space-x-1 rounded font-bold'>
        <span className='text-blue-500'>
          {t('newOptimizationScreen.sideBar.step', { count: page + 1 })}
        </span>
        <span className='text-blue-700'>
          {t('newOptimizationScreen.sideBar.steps')}
        </span>
      </div>
      <h1 className='text-2xl font-bold mt-4'>
        {t('newOptimizationScreen.sideBar.screen')}
      </h1>
      <h3 className='font-bold text-lg mt-8'>
        {t('newOptimizationScreen.sideBar.title')}
      </h3>
      <p className='font-medium text-sm text-grey-400'>
        {t('newOptimizationScreen.sideBar.description')}
      </p>

      <ul className='my-8 space-y-2'>
        {MENU_ITEMS.map(({ icon, text }, index) => (
          <li key={index} className='flex items-center font-bold'>
            <button
              onClick={(e) => {
                e.preventDefault()
                setPage(index)
              }}
              disabled={page < index}
              className={classNames(
                'bg-white w-full text-left flex items-center p-4 rounded-lg border',
                {
                  'border-blue-400': page === index,
                  'opacity-50': page < index
                }
              )}
            >
              {icon}
              <span>{`${index + 1}. ${text}`}</span>
              <CheckCircleIcon
                className={classNames('w-6 h-6 text-success-500 ml-auto', {
                  'opacity-100': page > index,
                  'opacity-0': page <= index
                })}
              />
            </button>
          </li>
        ))}
      </ul>
    </ErrorBoundary>
  )
}

export default SideBar
