import { useMemo, useState } from 'react'
import { SwitcherButtons } from 'components/Inputs/Switchers'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { Products } from './Products'
import Select from 'components/Inputs/Select'
import { useParams } from 'react-router'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useSWR from 'swr'
import TableChart from 'components/Graphs/TableChart'
import useCrushingDetails from 'screens/Optimization/Main/Tabs/Crushing/useCrushingDetails'
import {
  labelFormatter,
  valueFormatter
} from 'screens/Optimization/utils/output'
import {
  hasNoData,
  kgToTons,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'lodash'
import useCrushingTable from 'screens/Optimization/Main/Tabs/Crushing/useCrushingTable'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import SectionBox from 'components/Container/SectionBox'
import Outbound from '../Transportation/BR/TransshipmentDetails/Outbound'
import { default as OutboundArg } from '../Transportation/ARG/TransshipmentDetails/Outbound'
import Inbound from '../Transportation/BR/TransshipmentDetails/Inbound'
import { default as InboundArg } from '../Transportation/ARG/TransshipmentDetails/Inbound'
import { useLocalization } from 'providers/LocalizationProvider'
import OutputScreen from 'components/Container/OutputScreen'

const CrushingPlantDetails = ({
  goback,
  onProductClick,
  selectedCrushingPlant,
  setSelectedCrushingPlant,
  assets
}) => {
  const assetOptions = Object.keys(assets)?.map((asset) => ({
    value: asset,
    label: assets[asset]
  }))

  const { countryCode, t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { selectedProducts, selectedProductTypes } = useOptimizationContext()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/crushing_details`,
    {
      bestEstimateId: comparisonID,
      crush: selectedCrushingPlant
    }
  ])

  const capacityTypeToUse = useMemo(() => {
    if (selectedProductTypes.length > 1 || selectedProductTypes.length === 0) {
      return 'asset_capacity'
    } else if (selectedProducts.length === 1) {
      return 'crushing_capacity'
    } else if (
      selectedProducts.length > 1 &&
      selectedProductTypes.length === 1
    ) {
      return 'product_type_capacity'
    }
  }, [selectedProductTypes, selectedProducts])

  const {
    volumeChartBars,
    volumeChartData,
    summaryItems,
    utilizationChartData,
    utilChartBars,
    productCards
  } = useCrushingDetails(data, capacityTypeToUse)

  const { tableData } = useCrushingTable(utilizationChartData)
  const { heatmapData: volumeHeatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: data.volumes,
    filterProducts: true
  })

  const selectedCrushName = useMemo(
    () => assets[selectedCrushingPlant] || '',
    [selectedCrushingPlant, assets]
  )

  const inboundData = useMemo(() => {
    return (
      data.inOut.find((d) => d.in_out_direction === 'In')
        ?.transportation_modes || []
    )
  }, [data])

  const outboundData = useMemo(() => {
    return data.inOut.find((d) => d.in_out_direction === 'Out')
      .transportation_modes
  }, [data])

  const [view, setView] = useState('chart')

  return (
    <OutputScreen
      title={t('optimizationScreen.crushing.plantDetails.title')}
      titleExtra={
        <Select
          options={assetOptions}
          variant='underlined'
          onValueChange={setSelectedCrushingPlant}
          value={selectedCrushingPlant}
        />
      }
      crumbs={[
        { label: t('optimizationScreen.pageNames.crushing'), onClick: goback },
        {
          label: t('optimizationScreen.crushing.plantDetails.menuTitle'),
          onClick: goback
        }
      ]}
    >
      <SectionBox
        pageLangKey='optimizationScreen.pageNames.crushing'
        hasData={!hasNoData(volumeChartData)}
      >
        <div className='flex justify-between w-full items-center'>
          <div className='flex pt-6 items-center'>
            <h2 className='text-xl font-bold mr-1'>{selectedCrushName}</h2>
          </div>
          <SwitcherButtons
            items={[
              {
                label: t('optimizationScreen.chart'),
                value: 'chart'
              },
              {
                label: t('optimizationScreen.table'),
                value: 'table'
              }
            ]}
            onChange={setView}
          />
        </div>

        <TabsComponent>
          <Tab
            value='crush_volume'
            label={t('optimizationScreen.crushing.plantDetails.crushVolume')}
          >
            <TableChart
              data={volumeChartData}
              bars={volumeChartBars}
              selectedView={view}
              summaryItems={summaryItems}
              tooltipFormatter={labelFormatter}
              scatters={
                countryCode === 'ARG'
                  ? [
                      {
                        dataKey: 'capacity',
                        fill: '#8E5602',
                        name: t('optimizationScreen.capacity')
                      }
                    ]
                  : []
              }
              xAxisProps={{
                dataKey: 'month',
                tickFormatter: transformDateToShortMonth
              }}
              tableData={volumeHeatmapData}
              tableProps={{
                referenceProps: {
                  title: t('optimizationScreen.comparison'),
                  objKey: comparisonID
                },
                comparedProps: {
                  title: t('optimizationScreen.baseModel'),
                  objKey: baseModelId
                },
                groupLabelFormatter: transformDateToShortMonth,
                columnValueFormatter: valueFormatter,
                rowTitleFormatter: capitalize
              }}
              yAxisFormatter={(labelValue) => `${labelValue / kgToTons} K`}
            />
          </Tab>
          <Tab value='utilization' label={t('optimizationScreen.utilization')}>
            <TableChart
              data={utilizationChartData}
              bars={utilChartBars}
              selectedView={view}
              tooltipFormatter={(value) => `${value}%`}
              xAxisProps={{
                dataKey: 'month',
                tickFormatter: transformDateToShortMonth
              }}
              tableData={tableData}
              tableProps={{
                referenceProps: {
                  title: t('optimizationScreen.comparison'),
                  objKey: comparisonID
                },
                comparedProps: {
                  title: t('optimizationScreen.baseModel'),
                  objKey: baseModelId
                },
                groupLabelFormatter: transformDateToShortMonth,
                columnValueFormatter: (val) => `${val}%`,
                rowTitleFormatter: capitalize
              }}
            />
          </Tab>
          <Tab
            value='products'
            label={t('optimizationScreen.crushing.plantDetails.productsKTon')}
          >
            <Products
              data={productCards}
              onClick={onProductClick}
              view={view}
            />
          </Tab>
        </TabsComponent>
      </SectionBox>
      <SectionBox>
        {countryCode === 'ARG' ? (
          <InboundArg
            inboundData={inboundData}
            originationData={data.origination}
          />
        ) : (
          <Inbound
            inboundData={inboundData}
            originationData={data.origination}
          />
        )}
      </SectionBox>

      <SectionBox>
        {countryCode === 'ARG' ? (
          <OutboundArg
            outboundData={outboundData}
            destinationData={data.destination}
          />
        ) : (
          <Outbound
            outboundData={outboundData}
            destinationData={data.destination}
          />
        )}
      </SectionBox>
    </OutputScreen>
  )
}

export default CrushingPlantDetails
