import { ChevronDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import Checkbox from 'components/Inputs/Checkbox'
import { useTableContext } from './TableContext'

// ===== Table property dependent components

export const cellComponentFactory = ({
  omitKeysFromData,
  checkableRows,
  expandArrowPosition
}) => {
  const DefaultCellComponent = ({ item, columnIndex }) => (
    <span>
      {Object.entries(item)
        .filter(([key, _val]) => !omitKeysFromData?.includes(key))
        .map(([_key, val]) => val)
        [columnIndex - !!checkableRows]?.toString()}
    </span>
  )

  const RowExpandButtonComponent = ({ ctx, rowKey, children }) => {
    const { isRowExpanded, setRowExpanded } = useTableContext()
    const expanded = !!isRowExpanded(rowKey)

    return ctx.expandable !== false ? (
      <button
        className='cursor-pointer h-full w-full flex items-center gap-2'
        onClick={() => setRowExpanded(rowKey, !expanded)}
      >
        <ChevronDownIcon
          className={classNames('w-4 h-4 transition', {
            '-rotate-90': expandArrowPosition === 'left' && !expanded,
            'rotate-180': expandArrowPosition === 'right' && expanded,
            'ml-2': expandArrowPosition === 'left'
          })}
        />
        {children}
      </button>
    ) : (
      <div className={classNames({ 'pl-8': expandArrowPosition === 'left' })}>
        {children}
      </div>
    )
  }

  return {
    DefaultCellComponent,
    RowExpandButtonComponent
  }
}

// ===== Table property independent components

export const emptyWrapper = ({ children }) => <>{children}</>

export const RowCheckboxComponent = ({ ctx }) => (
  <Checkbox
    isChecked={ctx?.checked || false}
    disabled={!ctx.setChecked}
    onChange={ctx.setChecked || undefined}
  />
)
