import { FileSelectorModal } from 'components/Modal/FileSelectorModal'
import { useLocalization } from 'providers/LocalizationProvider'
import { useCallback, useState } from 'react'
import { putDatasetFiles } from 'utils/request'
import { translateValidationErrors } from 'utils/string'

const ValidatedFileSelectModal = ({
  title,
  type,
  open,
  setOpen,
  onSuccess,
  hint,
  whatif
}) => {
  const [uploading, setUploading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState([])
  const { t } = useLocalization()

  const onUpload = useCallback(
    async (upload) => {
      const file = upload[0]
      setUploading(true)

      try {
        const result = await putDatasetFiles(
          '/data/upload',
          [
            {
              file,
              type,
              //TODO: Add translations
              description: 'Uploaded from assets page',
              whatif
            }
          ],
          true
        )
        setOpen(false)
        onSuccess?.(result?.data)
      } catch (error) {
        const errorBody = error?.response?.data
        if (errorBody?.message === 'Validation Failed') {
          setUploadErrors(translateValidationErrors(t, errorBody.data))
        } else {
          //TODO: Add translations
          setUploadErrors(['Unknown upload error, please try again later'])
          console.log(errorBody)
        }
      }
      setUploading(false)
    },
    [type, setOpen, onSuccess, whatif, t]
  )

  return (
    <FileSelectorModal
      title={title}
      open={open}
      onOpenChange={setOpen}
      onFileSelect={() => setUploadErrors([])}
      onUpload={onUpload}
      errors={uploadErrors}
      isUploading={uploading}
      hint={hint}
    />
  )
}

export default ValidatedFileSelectModal
