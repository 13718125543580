import classNames from 'classnames'
import { useCallback } from 'react'

export const useRowClassName = (
  {
    borders,
    renderExpandedRow,
    data,
    columnHeaders,
    stickyFirstColumns,
    rounding: { roundTop, roundBottom }
  },
  mappers
) => {
  return useCallback(
    (idx, index, baseClassNames, className, rowClickable, context) => {
      return classNames(
        baseClassNames,
        borders && {
          'border-l': idx === 0,
          'border-r': idx === mappers.length - 1,
          'border-b': !renderExpandedRow || !context.expanded,
          'rounded-bl-md':
            roundBottom && index === data.length - 1 && idx === 0,
          'rounded-br-md':
            roundBottom &&
            index === data.length - 1 &&
            idx === mappers.length - 1,
          'border-t': !columnHeaders && index === 0,
          'rounded-tl': roundTop && !columnHeaders && index === 0 && idx === 0,
          'rounded-tr':
            roundTop &&
            !columnHeaders &&
            index === 0 &&
            idx === mappers.length - 1
        },
        stickyFirstColumns &&
          idx < stickyFirstColumns &&
          'sticky top-0 left-0',
        {
          'cursor-pointer': rowClickable
        },
        data[index]?.className,
        className
      )
    },
    [borders, columnHeaders, data, mappers.length, renderExpandedRow, roundBottom, roundTop, stickyFirstColumns]
  )
}
