import { useMemo } from 'react'
import FOBMapCard from './FOBMapCard'
import { SvgMap } from 'components/SvgMaps'
import HeatMapTable from 'components/Tables/HeatMapTable'
import {
  NO_DATA_LABEL,
  kgToTons,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import useProductFilter from 'hooks/useProductFilter'
import { useParams } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'
import NoData from 'components/Feedback/NoData'

const FOB = ({ data }) => {
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()
  const monthArray = getMonthsForPeriod(start_date, end_date)
  const { countryCode, t } = useLocalization()

  const heatmapKey = useMemo(() => {
    switch (countryCode) {
      case 'ARG':
        return 'state'
      default:
        return 'corridor'
    }
  }, [countryCode])

  const { mapData } = useMemo(() => {
    const sortedData = data.reduce(
      (acc, { state, asset, execution_id, volume }) => {
        const objectKey = countryCode === 'ARG' ? asset : state
        if (!acc.hasOwnProperty(objectKey)) {
          acc[objectKey] = { BM: 0, BE: 0 }
        }

        acc[objectKey][execution_id === comparisonID ? 'BE' : 'BM'] += volume
        return acc
      },
      {}
    )

    const mapData = {}
    for (const item of Object.keys(sortedData)) {
      mapData[item] = (sortedData[item].BE / sortedData[item].BM) * 100
    }

    return { mapData }
  }, [data, comparisonID, countryCode])

  const { filteredData } = useProductFilter({ data })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: filteredData,
    rowLevelKeys: [
      heatmapKey,
      'mesoregion',
      { dataKey: 'asset', titleKey: 'asset_name' }
    ]
  })

  return (
    <div>
      <h2 className='text-xl font-bold mr-1'>
        {t('optimizationScreen.origination.fob.demandByState')}
      </h2>
      <div className='flex items-center p-6 space-x-4 mt-6'>
        <FOBMapCard />
        <div className='w-full'>
          <SvgMap disableZoom={true} fillData={mapData} />
        </div>
      </div>
      <div className='mt-4'>
        <h2 className='text-xl font-bold mr-1'>
          {t('optimizationScreen.origination.fob.originationDetails')}
        </h2>
        {heatmapData.length > 0 ? (
          <HeatMapTable
            refProp={{
              title: t('optimizationScreen.comparison'),
              objKey: comparisonID,
              legend: t('optimizationScreen.comparison')
            }}
            comparedProps={[
              {
                title: t('optimizationScreen.baseModel'),
                objKey: baseModelId,
                legend: t('optimizationScreen.baseModel')
              }
            ]}
            data={heatmapData}
            groups={monthArray}
            defaultColoringMode='keys'
            columnValueFormatter={(value) =>
              value === NO_DATA_LABEL || !value
                ? NO_DATA_LABEL
                : Math.round(value / kgToTons)
            }
            groupLabelFormatter={transformDateToShortMonth}
            showTotal
          />
        ) : (
          <NoData page={t('optimizationScreen.pageNames.origination')} />
        )}
      </div>
    </div>
  )
}

export default FOB
