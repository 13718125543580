import React, { useMemo, useState } from 'react'
import InAndOutboundChart from './InAndOutboundChart'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { valueFormatter } from 'screens/Optimization/utils/output'
import { capitalize } from 'utils/string'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import RadioGroup from 'components/Inputs/RadioGroup'
import { useLocalization } from 'providers/LocalizationProvider'

const Inbound = ({ inboundData, originationData, selectedSubProducts }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const { comparisonID, selectedProducts } = useOptimizationContext()

  const [groupBy, setGroupBy] = useState('mesoregion')

  const filteredBySelectedSubProducts = useMemo(() => {
    return selectedProducts.length > 0
      ? originationData.filter((p) => selectedSubProducts?.includes(p.product))
      : originationData
  }, [originationData, selectedSubProducts, selectedProducts])

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'inbound_volume',
    data: filteredBySelectedSubProducts,
    filterProducts: true,
    rowLevelKeys: [
      {
        dataKey: undefined,
        title: t('optimizationScreen.transportation.inbound.fob')
      },
      groupBy,
      'municipality'
    ]
  })

  return (
    <>
      <h3 className='text-lg font-bold mb-8'>
        {t('optimizationScreen.transportation.inbound.inbound')}
      </h3>
      {inboundData.map(({ transportation_mode, data: volumes }) => {
        return (
          <React.Fragment key={transportation_mode}>
            <InAndOutboundChart
              selectedSubProducts={selectedSubProducts}
              transportationMode={transportation_mode}
              volumes={volumes}
            />
          </React.Fragment>
        )
      })}
      <div>
        <div className='flex justify-between mb-6'>
          <h3 className='text-base font-bold'>
            {t('optimizationScreen.transportation.inbound.origination')}
          </h3>
          <RadioGroup
            className='flex gap-2 items-center'
            label={t('optimizationScreen.transportation.inbound.showing')}
            options={[
              {
                label: t(
                  'optimizationScreen.transportation.inbound.byMesoregion'
                ),
                value: 'mesoregion'
              }
            ]}
            onChange={setGroupBy}
            value={groupBy}
          />
        </div>
        <MultiColumnsBoxTable
          showTitles
          expandable
          data={heatmapData}
          referenceProps={{
            title: t('optimizationScreen.comparison'),
            objKey: comparisonID
          }}
          comparedProps={{
            title: t('optimizationScreen.baseModel'),
            objKey: baseModelId
          }}
          groupLabelFormatter={transformDateToShortMonth}
          rowTitleFormatter={(v) => (v ? capitalize(v) : '-')}
          columnValueFormatter={(val) =>
            val === NO_DATA_LABEL || !val ? NO_DATA_LABEL : valueFormatter(val)
          }
          referencePropKey={comparisonID}
        />
      </div>
    </>
  )
}

export default Inbound
