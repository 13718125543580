import useSWRMutation from 'swr/mutation'
import { post, put } from 'utils/request'
import { useNavigate } from 'react-router-dom'

const useSubmitConfigForm = ({ basePath, id }) => {
  const navigate = useNavigate()

  const submitFn = id ? put : post
  const path = id ? `${basePath}/${id}` : basePath

  const { trigger, isMutating, error } = useSWRMutation(
    path,
    async (url, { arg }) => {
      await submitFn(url, arg)
    },
    {
      onSuccess: () => navigate('/assets'),
      throwOnError: false
    }
  )
  return {
    submit: trigger,
    isMutating,
    error
  }
}

export default useSubmitConfigForm
