import { useMemo, useState } from 'react'
import Loader from 'components/Feedback/Loader/Loader'
import BarChart from 'components/Graphs/BarChart'
import RadioGroup from 'components/Inputs/RadioGroup'
import { SummaryCard } from 'components/Cards'
import { capitalize } from 'utils/string'
import HeatMapTable from 'components/Tables/HeatMapTable'
import { addHeatmapData } from './utils/heatmap'
import { formatNumber } from 'utils/number'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import NoData from 'components/Feedback/NoData'
import { useLocalization } from 'providers/LocalizationProvider'

const CIF = ({ data }) => {
  const {
    executionData: { id, start_date, end_date },
    products,
    comparisonID
  } = useOptimizationContext()
  const [chartView, setChartView] = useState('aggregated')
  const monthArray = getMonthsForPeriod(start_date, end_date)
  const { countryCode, t } = useLocalization()

  const AssetTypeMap = useMemo(() => {
    if (countryCode === 'ARG') {
      return new Map([
        [
          'PORT',
          {
            name: t('optimizationScreen.origination.cif.port'),
            fill: '#84050B'
          }
        ],
        [
          'SILO',
          {
            name: t('optimizationScreen.origination.cif.silo'),
            fill: '#FCC46F'
          }
        ],
        [
          'CRUSH',
          {
            name: t('optimizationScreen.origination.cif.crushPlant'),
            fill: '#2BAC82'
          }
        ]
      ])
    } else {
      return new Map([
        [
          'PORT',
          {
            name: t('optimizationScreen.origination.cif.port'),
            fill: '#84050B'
          }
        ],
        [
          'SILO',
          {
            name: t('optimizationScreen.origination.cif.silo'),
            fill: '#FCC46F'
          }
        ],
        [
          'CRUSH',
          {
            name: t('optimizationScreen.origination.cif.crushPlant'),
            fill: '#2BAC82'
          }
        ],
        [
          'TRANSB',
          {
            name: t('optimizationScreen.origination.cif.transshipment'),
            fill: '#3668D5'
          }
        ]
      ])
    }
  }, [countryCode, t])

  const barChartProps = {
    BM: {
      label: t('optimizationScreen.baseModel'),
      data: [
        ...[...AssetTypeMap.keys()].map((key) => ({
          dataKey: key,
          ...AssetTypeMap.get(key)
        }))
      ]
    },
    BE: {
      label: t('optimizationScreen.comparison'),
      data: [
        ...[...AssetTypeMap.keys()].map((key) => ({
          dataKey: key,
          ...AssetTypeMap.get(key),
          fill: AssetTypeMap.get(key).fill + '80'
        }))
      ]
    }
  }
  const assetTypeValues = Object.fromEntries(
    [...AssetTypeMap.keys()].map((value) => [value, 0])
  )
  const chartDataSkeleton = monthArray.map((month) => ({
    date: month,
    BM: { ...assetTypeValues },
    BE: { ...assetTypeValues }
  }))
  const dimensionDataSkeleton = monthArray.map((month) => ({
    date: month,
    ...Object.fromEntries(
      [...AssetTypeMap.keys()].map((value) => ['BM_' + value, 0])
    ),
    ...Object.fromEntries(
      [...AssetTypeMap.keys()].map((value) => ['BE_' + value, 0])
    )
  }))

  const { chartData, productVolumes, heatmapData, dimensionChartData } =
    useMemo(() => {
      const selectedProducts = products
        .filter((product) => product.checked)
        .map((product) => product.name)

      let filteredData = data
      if (selectedProducts.length > 0) {
        filteredData = data.filter((item) =>
          selectedProducts.includes(item.product_ui_type)
        )
      }

      const chartData = filteredData.reduce(
        (acc, { month, execution_id, asset_type, volume }) => {
          const monthData = acc.find((item) => item.date === month)
          const assetTypes =
            monthData[execution_id === comparisonID ? 'BE' : 'BM']
          assetTypes[asset_type] += volume

          return acc
        },
        chartDataSkeleton
      )

      const dimensionChartData = filteredData.reduce((acc, value) => {
        const monthData = acc.find((item) => item.date === value.month)
        monthData[
          (value.execution_id === comparisonID ? 'BE_' : 'BM_') +
            value.asset_type
        ] += value.volume

        return acc
      }, dimensionDataSkeleton)

      const productVolumes = filteredData.reduce(
        (acc, { execution_id, product_ui_type, volume }) => {
          const productVolume = acc.find(
            (item) => item.product_ui_type === product_ui_type
          )

          if (execution_id === id) {
            productVolume
              ? (productVolume.volume += volume)
              : acc.push({
                  product_ui_type,
                  volume
                })
          }

          return acc
        },
        []
      )

      const heatmapData = filteredData.reduce(
        (acc, { asset_type, volume, execution_id, month, asset_name }) => {
          const asset = addHeatmapData(
            acc,
            AssetTypeMap.get(asset_type).name,
            month,
            execution_id,
            comparisonID,
            volume,
            true,
            monthArray
          )
          addHeatmapData(
            asset.subRows,
            asset_name,
            month,
            execution_id,
            comparisonID,
            volume,
            false,
            monthArray
          )

          return acc
        },
        []
      )

      return { chartData, productVolumes, heatmapData, dimensionChartData }
    }, [
      products,
      comparisonID,
      chartDataSkeleton,
      data,
      dimensionDataSkeleton,
      monthArray,
      AssetTypeMap
    ])

  if (!data) {
    return <Loader />
  }

  return chartData.some((item) =>
    Object.values(item.BM).some((volume) => volume !== 0)
  ) ? (
    <div>
      <p className='text-xl font-bold mb-10'>
        {t('optimizationScreen.origination.cif.title')}
      </p>
      <div className='flex flex-wrap gap-4 mb-10'>
        {productVolumes.map(({ product_ui_type, volume }) => {
          return (
            <SummaryCard
              key={product_ui_type}
              title={capitalize(product_ui_type)}
              items={[
                {
                  description: t('optimizationScreen.periodTotal'),
                  value: formatNumber(volume, { maximumFractionDigits: 0 })
                }
              ]}
            />
          )
        })}
      </div>
      <div className='flex'>
        <p className='font-bold mr-4 mb-4'>
          {t('optimizationScreen.origination.cif.chartView')}
        </p>
        <RadioGroup
          value={chartView}
          onChange={setChartView}
          options={[
            { value: 'aggregated', label: t('optimizationScreen.aggregated') },
            {
              value: 'by_dimension',
              label: t('optimizationScreen.byDimension')
            }
          ]}
        ></RadioGroup>
      </div>
      <div className='h-72'>
        {chartView === 'aggregated' && (
          <BarChart
            data={chartData}
            barProps={{ barSize: 12 }}
            cartesianGridProps={{
              strokeDasharray: undefined
            }}
            xAxisProps={{
              dataKey: 'date',
              tickLine: 0,
              tickFormatter: transformDateToShortMonth
            }}
            showLegend
            stacks={barChartProps}
            tooltipFormatter={formatNumber}
          />
        )}
        {chartView === 'by_dimension' && (
          <BarChart
            data={dimensionChartData}
            barProps={{ barSize: 12 }}
            cartesianGridProps={{
              strokeDasharray: undefined
            }}
            bars={[
              ...barChartProps.BM.data.map((asset) => ({
                ...asset,
                dataKey: `BM_${asset.dataKey}`,
                name: `BM ${asset.name}`
              })),
              ...barChartProps.BE.data.map((asset) => ({
                ...asset,
                dataKey: `BE_${asset.dataKey}`,
                name: `BE ${asset.name}`
              }))
            ]}
            xAxisProps={{
              dataKey: 'date',
              tickFormatter: transformDateToShortMonth
            }}
            showLegend
            tooltipFormatter={formatNumber}
          />
        )}
      </div>
      <p className='mt-20 mb-10 text-xl font-bold'>
        {t('optimizationScreen.origination.cif.destinationDetails')}
      </p>
      <HeatMapTable
        comparedProps={[
          {
            objKey: 'bm',
            title: 'BM',
            legend: t('optimizationScreen.baseModel')
          }
        ]}
        refProp={{
          objKey: 'be',
          title: 'BE',
          legend: t('optimizationScreen.comparison')
        }}
        data={heatmapData}
        groups={monthArray}
        defaultColoringMode='keys'
        showTotal
        columnValueFormatter={(value) => `${Math.round(value / kgToTons)}`}
        groupLabelFormatter={transformDateToShortMonth}
      />
    </div>
  ) : (
    <NoData page={t('optimizationScreen.pageNames.origination')} />
  )
}

export default CIF
