import Checkbox from 'components/Inputs/Checkbox'
import { useLocalization } from 'providers/LocalizationProvider'

const Status = ({ item, ctx: { mutate, trigger, data } }) => {
  const { t } = useLocalization()

  return (
    <Checkbox
      label={
        item.include
          ? t('assetConfigurationScreen.assetStatus.active')
          : t('assetConfigurationScreen.assetStatus.inactive')
      }
      isChecked={item.include}
      onChange={(value) => {
        mutate(trigger({ include: value, id: item.id }), {
          optimisticData: [
            ...data.filter((e) => e.id !== item.id),
            { ...item, include: value }
          ],
          rollbackOnError: true,
          populateCache: false,
          revalidate: true
        })
      }}
    />
  )
}

export default Status
