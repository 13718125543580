import { useCreateExecution } from 'hooks/useCreateExecution'
import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

const ScenariosContext = createContext({})
ScenariosContext.displayName = 'ScenariosContext'

export const ScenariosProvider = ({ children }) => {
  const { id } = useParams()
  const { data } = useSWR(`/execution/${id}`)
  const { createExecution } = useCreateExecution()

  const executionData = useMemo(() => {
    return {
      ...data,
      data: JSON.parse(data.data)
    }
  }, [data])

  const [state, setState] = useState({})
  const [searchParams] = useSearchParams()
  const [enableRun, setEnableRun] = useState(false)
  const currentSection = searchParams.get('section')

  const runNewScenario = useCallback(() => {
    const advancedSettings = Object.keys(state).reduce(
      (acc, currentValue) => {
        return Object.assign(
          {
            ...acc
          },
          { ...state[currentValue]?.advancedSettings }
        )
      },
      { ...executionData.data.advancedSettings }
    )

    if (!('whatif_silo_allocation_plan' in advancedSettings)) {
      advancedSettings.whatif_silo_allocation_plan = true
    }

    const executionFilesTypeId = Object.fromEntries(
      executionData.data.files.map(({ id, type }) => [type, id])
    )

    const filesInState = Object.values(state).reduce((acc, sectionData) => {
      Object.entries(sectionData.files).forEach(([type, id]) => {
        acc[type] = id
      })
      return acc
    }, {})

    const filesInStateMergedExecution = {
      ...executionFilesTypeId,
      ...filesInState
    }

    const files = Object.entries(filesInStateMergedExecution).map(
      ([type, id]) => ({ id, type })
    )

    const body = {
      name: executionData.name,
      description: executionData.description,
      advancedSettings: advancedSettings,
      files: files,
      parent_execution_id: id,
      createdBy: executionData.createdBy
    }

    createExecution(body)
  }, [executionData, createExecution, id, state])

  const setValue = useCallback(
    (payload) => {
      setState((prev) => ({
        ...prev,
        [currentSection]: { ...(prev[currentSection] || {}), ...payload }
      }))
    },
    [currentSection]
  )

  const value = useMemo(
    () => ({
      ...state,
      state,
      setValue,
      currentSection,
      runNewScenario,
      executionData,
      enableRun,
      setEnableRun
    }),
    [
      state,
      currentSection,
      runNewScenario,
      setValue,
      executionData,
      enableRun,
      setEnableRun
    ]
  )

  return (
    <ScenariosContext.Provider value={value}>
      {children}
    </ScenariosContext.Provider>
  )
}

export const useScenariosContext = () => {
  const context = useContext(ScenariosContext)
  if (!context) {
    throw new Error('useScenarios must be used within a ScenariosProvider')
  }
  return context
}
