import Button from 'components/Inputs/Button'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { useLocalization } from 'providers/LocalizationProvider'
import ProgressBar from 'components/Feedback/Loader/ProgressBar'

export const UploadDownloadSection = ({
  onDownload,
  onUpload,
  progress,
  disabled
}) => {
  const { t } = useLocalization()
  const loading = progress && typeof progress.loaded === 'number'

  return (
    <div className='flex flex-col ml-auto items-center justify-center'>
      <div className='flex items-center gap-2'>
        <Button
          size='s'
          onClick={onDownload}
          icon
          disabled={disabled || loading}
        >
          <span className='sr-only'>
            {t('uploadDownloadModules.downloadBtnLabel')}
          </span>
          <ArrowDownTrayIcon className='w-5 h-5 font-bold text-grey-500' />
        </Button>
        <Button
          variant='filled'
          size='s'
          onClick={onUpload}
          className='w-28'
          disabled={disabled}
        >
          {t('uploadDownloadModules.uploadBtnLabel')}
        </Button>
      </div>
      {loading && (
        <ProgressBar
          loaded={progress?.loaded}
          total={progress?.total}
          variant='plain'
          small
        >
          <span className='font-xs w-max'>Preparing download... </span>
        </ProgressBar>
      )}
    </div>
  )
}
