import { MsalAuthProvider, LoginType } from 'react-aad-msal'

// Msal Configurations
const config = {
  auth: {
    authority: process.env.REACT_APP_JWT_IDENTITY,
    clientId: process.env.REACT_APP_JWT_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}

// Authentication Parameters
const authenticationParameters = {
  scopes: ['user.read']
}

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
)
