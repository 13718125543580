import SearchInput from 'components/Inputs/SearchInput'
import Button from 'components/Inputs/Button'
import { PlusIcon } from '@heroicons/react/20/solid'

const TableActions = ({
  onSearchChange,
  searchTerm,
  onRegisterClick,
  registerButtonLabel,
  placeholder
}) => (
  <div className='flex items-center mb-4 gap-4'>
    <SearchInput
      placeholder={placeholder}
      onChange={onSearchChange}
      value={searchTerm}
      fullWidth
    />
    <Button variant='filled' onClick={onRegisterClick}>
      <PlusIcon className='w-5 h-5' />
      {registerButtonLabel}
    </Button>
  </div>
)
export default TableActions
