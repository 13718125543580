import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NO_DATA_LABEL, createMonthObject } from 'screens/Optimization/utils'
import { formatNumber } from 'utils/number'
import { capitalize } from 'utils/string'
import { getProductColor } from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const useInboundRoutes = (data) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    selectedProducts,
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const sumByProduct = useMemo(() => {
    if (!data) {
      return {}
    }
    return data.reduce((acc, cur) => {
      if (!acc[cur.product_ui_type]) {
        acc[cur.product_ui_type] = {}
      }

      if (acc[cur.product_ui_type][cur.execution_id]) {
        acc[cur.product_ui_type][cur.execution_id] += cur.inbound_volume
      } else {
        acc[cur.product_ui_type][cur.execution_id] = cur.inbound_volume
      }

      return acc
    }, {})
  }, [data])

  const summaryItems = useMemo(
    () =>
      Object.entries(sumByProduct)
        .map(([product, volume]) => ({
          title: capitalize(product),
          items: [
            {
              description: t('optimizationScreen.periodTotal'),
              value: formatNumber(volume[baseModelId], {
                maximumFractionDigits: 0
              })
            },
            {
              description: t('optimizationScreen.comparisonPeriodTotal'),
              value: formatNumber(volume[comparisonID], {
                maximumFractionDigits: 0
              })
            }
          ]
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [sumByProduct, baseModelId, comparisonID, t]
  )

  const getMonthsWithDefaultData = useCallback(
    ({ executionData = NO_DATA_LABEL }) => {
      return createMonthObject(start_date, end_date, {
        [baseModelId]: executionData,
        ...(comparisonID ? { [comparisonID]: executionData } : {}),
        topContracts: NO_DATA_LABEL
      })
    },
    [start_date, end_date, baseModelId, comparisonID]
  )

  const calcExecutionIdData = ({
    currentData,
    product_ui_type,
    inbound_volume
  }) => {
    if (typeof currentData === 'object') {
      return {
        ...currentData,
        [product_ui_type]:
          currentData[product_ui_type] === NO_DATA_LABEL
            ? inbound_volume
            : currentData[product_ui_type] + inbound_volume
      }
    }
    return currentData === NO_DATA_LABEL
      ? inbound_volume
      : currentData + inbound_volume
  }

  const volumeChartData = useMemo(() => {
    const result = data.reduce(
      (
        acc,
        { month, execution_id, product_ui_type, inbound_volume, top_volume }
      ) => ({
        ...acc,
        [month]: {
          ...acc[month],
          [execution_id]: calcExecutionIdData({
            currentData: acc[month][execution_id],
            product_ui_type,
            inbound_volume
          }),
          topContracts:
            top_volume && execution_id === baseModelId
              ? acc[month].topContracts === NO_DATA_LABEL
                ? top_volume
                : acc[month].topContracts + top_volume
              : acc[month].topContracts
        }
      }),
      getMonthsWithDefaultData({
        executionData:
          selectedProducts.length > 0
            ? selectedProducts.reduce(
                (acc, cur) => ({ ...acc, [cur]: NO_DATA_LABEL }),
                {}
              )
            : NO_DATA_LABEL
      })
    )

    return Object.entries(result).map(
      ([month, { topContracts, ...volumes }]) => ({
        month,
        volumes,
        topContracts
      })
    )
  }, [baseModelId, data, getMonthsWithDefaultData, selectedProducts])

  const getPossiblePaths = useCallback(
    () =>
      [baseModelId, comparisonID]
        .map((id) =>
          selectedProducts.length > 0
            ? selectedProducts.map((product) => `volumes.${id}.${product}`)
            : `volumes.${id}`
        )
        .flat(),
    [baseModelId, comparisonID, selectedProducts]
  )

  const bars = useMemo(
    () =>
      getPossiblePaths().map((path) => {
        const [executionId, product] = path.split('.').slice(1)
        const name = `${
          executionId === baseModelId
            ? t('optimizationScreen.baseModel')
            : t('optimizationScreen.comparison')
        }${product ? ' ' + capitalize(product) : ''}`
        return {
          dataKey: path,
          fill: getProductColor(executionId === baseModelId, product),
          name
        }
      }),
    [baseModelId, getPossiblePaths, t]
  )

  return {
    summaryItems,
    volumeChartData,
    bars
  }
}

export default useInboundRoutes
