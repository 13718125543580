import { useController } from 'react-hook-form'
import Select from './Select'

const ControlledSelect = ({ controllerProps, onChange, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController(controllerProps)

  return (
    <Select
      {...props}
      value={field.value}
      onValueChange={(value) => {
        field.onChange(value)
        onChange && onChange(value)
      }}
      error={error && error.message}
    />
  )
}

export default ControlledSelect
