import { useLocalization } from 'providers/LocalizationProvider'
import { SingleTemplateUpdateSection } from 'screens/Scenarios/Main/sections/SingleTemplateUpdateSection'

export const MarketStructure = () => {
  const { t } = useLocalization()
  const defaultContentPath = 'scenarios.pages.financial.marketStructure.'
  return (
    <SingleTemplateUpdateSection
      sectionTitle={t(`${defaultContentPath}sectionTitle`)}
      title={t(`${defaultContentPath}title`)}
      description={t(`${defaultContentPath}description`)}
      templateType='TEMPLATES_BR_PORT_PRICE'
      modalTitle={t(`${defaultContentPath}modalTitle`)}
    />
  )
}
