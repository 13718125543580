import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline'

export function ChipStatus({
  warning,
  success,
  error,
  text,
  icon,
  className,
  ...props
}) {
  if (error) {
    return (
      <div
        className={
          'inline-flex justify-center text-red-700 bg-error-100 rounded sm font-bold ' +
          className
        }
        {...props}
      >
        <p className='flex flex-row gap-1 p-1 whitespace-nowrap justify-center leading-tight items-center'>
          {icon && (
            <ExclamationCircleIcon
              strokeWidth='2.5'
              className=' block h-6 w-5'
              aria-hidden='true'
            />
          )}{' '}
          {text || ''}
        </p>
      </div>
    )
  }

  if (warning) {
    return (
      <div
        className={
          'inline-flex justify-center text-warning-600 bg-warning-100 rounded sm font-bold w-full' +
          className
        }
        {...props}
      >
        <p className='flex flex-row gap-1 p-1 whitespace-nowrap justify-center leading-tight items-center'>
          {icon && (
            <ExclamationTriangleIcon
              strokeWidth='2.5'
              className=' block h-6 w-5'
              aria-hidden='true'
            />
          )}{' '}
          {text || ''}
        </p>
      </div>
    )
  }

  if (success) {
    return (
      <div
        className='inline-flex justify-center text-success-600 bg-success-100 rounded sm font-bold w-full items-center'
        {...props}
      >
        <p className='flex flex-row gap-1 p-1 whitespace-nowrap justify-center leading-tight items-center'>
          {icon && (
            <CheckCircleIcon
              className='block h-6 w-5'
              strokeWidth='2.5'
              aria-hidden='true'
            />
          )}{' '}
          {text || ''}
        </p>
      </div>
    )
  }

  return (
    <div
      className='inline-flex justify-center text-neutral-600 bg-neutral-100 rounded sm font-bold w-full items-center'
      {...props}
    >
      <p className='flex flex-row gap-1 p-1 whitespace-nowrap justify-center leading-tight items-center'>
        {text || ''}
      </p>
    </div>
  )
}
