import TableChart from 'components/Graphs/TableChart'
import useGraph from 'hooks/useGraph'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import useProductFilter from 'hooks/useProductFilter'
import useSummaryItems from 'hooks/useSummaryItems'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import { useLocalization } from 'providers/LocalizationProvider'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, crushed_volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? crushed_volume
          : currentData[product_ui_type] + crushed_volume
    }
  }
  return currentData === NO_DATA_LABEL
    ? crushed_volume
    : currentData + crushed_volume
}

const calcCapacity = ({
  currentData,
  record: { execution_id, ...record },
  capacityKey
}) => {
  return currentData === NO_DATA_LABEL
    ? { [execution_id]: record[capacityKey] }
    : { ...currentData, [execution_id]: record[capacityKey] }
}

const Utilization = ({ data, selectedSubProducts }) => {
  const { id: baseModelId } = useParams()
  const { comparisonID, selectedProductTypes, selectedProducts } =
    useOptimizationContext()
  const { t } = useLocalization()

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'crushing_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  const { filteredData } = useProductFilter({
    data,
    selectedSubProducts
  })

  const { summaryItems } = useSummaryItems({
    data: filteredData,
    volumeKey: 'crushed_volume',
    capacityKey,
    isUtilization: true
  })

  const { utilizationGraphData, bars } = useGraph({
    data: filteredData,
    monthContextData: {
      capacity: calcCapacity
    },
    capacityKey,
    calcExecutionIdData,
    calculateUtilization: true
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'crushed_volume',
    data: filteredData,
    filterProducts: true
  })

  return (
    <TableChart
      title={t('optimizationScreen.export.crushVolume')}
      summaryItems={summaryItems}
      data={utilizationGraphData}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      yAxisProps={{
        tickFormatter: (v) => `${v}%`,
        domain: [0, () => 100]
      }}
      bars={bars}
      tableData={heatmapData}
      tooltipFormatter={(v) =>
        `${v !== NO_DATA_LABEL ? v.toFixed(0) : NO_DATA_LABEL}%`
      }
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: (v) => v,
        rowTitleFormatter: capitalize
      }}
    />
  )
}

export default Utilization
