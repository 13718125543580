import ControlledRadioGroup from 'components/Inputs/RadioGroup/ControlledRadioGroup'
import Button from 'components/Inputs/Button'
import { useCallback, useMemo, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { checkedValuesReducer } from 'screens/Configuration/utils'
import {
  COUNTRY_OPTIONS,
  TRANSPORTATION_MODES,
  PRODUCTS,
  STATE_KEY_MAP
} from 'screens/Configuration/constants'
import { ControlledSelect } from 'components/Inputs/Select'
import { ControlledInput } from 'components/Inputs'
import { ControlledCheckbox } from 'components/Inputs/Checkbox'
import useLocationSelect from 'hooks/useLocationSelect'
import useSubmitConfigForm from 'hooks/useSubmitConfigForm'
import Alert from 'components/Feedback/Alert'
import { useCity } from 'providers/CityProvider'
import { useLocalization } from 'providers/LocalizationProvider'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { SILO_PREFIX, SiloIDRegexp } from '../../constants'

const SiloForm = ({ id: siloId, currentData }) => {
  const { t, countryCode } = useLocalization()
  const argKey = useMemo(
    () => (countryCode === 'ARG' ? 'arg.' : ''),
    [countryCode]
  )
  const stateKey = useMemo(() => {
    return STATE_KEY_MAP[countryCode]
  }, [countryCode])
  const isEditMode = useMemo(() => {
    return siloId !== undefined
  }, [siloId])
  const { cities } = useCity()
  const {
    control,
    watch,
    resetField,
    handleSubmit,
    trigger: triggerValidation,
    formState: { isSubmitted, errors, isDirty },
    setValue
  } = useForm({
    defaultValues: currentData ?? {
      silo_id: currentData?.silo_id || '',
      country: countryCode,
      state: undefined,
      city_id: undefined,
      ownership: undefined,
      products: [...PRODUCTS],
      transportation_mode: [...TRANSPORTATION_MODES]
    }
  })

  const { submit, isMutating, error } = useSubmitConfigForm({
    basePath: '/silo',
    id: siloId
  })

  const silo_id = watch('silo_id')
  const state = watch('state')

  const fullPrefix = useMemo(() => `${SILO_PREFIX}_${state}_`, [state])

  const { stateOptions, cityOptionsBySelectedState } = useLocationSelect({
    cities,
    state
  })

  const { fields: products } = useFieldArray({
    control,
    name: 'products',
    rules: {
      validate: (currentValue) => {
        if (
          (currentData?.include === undefined ||
            currentData?.include === true) &&
          !currentValue.some((product) => product.checked === true) &&
          countryCode !== 'ARG'
        ) {
          return t(
            'assetConfigurationScreen.silos.form.products.errorMessages.required'
          )
        }
      }
    }
  })

  const { fields: transportationMode } = useFieldArray({
    control,
    name: 'transportation_mode',
    rules: {
      validate: (currentValue) => {
        if (
          !currentValue.some((tp_mode) => tp_mode.checked === true) &&
          countryCode !== 'ARG'
        ) {
          return t(
            'assetConfigurationScreen.silos.form.transportationMode.errorMessages.required'
          )
        }
      }
    }
  })

  const getSiloName = useCallback(
    (siloId) => (siloId ? siloId.substring(fullPrefix.length) : ''),
    [fullPrefix.length]
  )

  const onSubmit = useCallback(
    (formData) => {
      const { silo_id, city_id, ownership, products, transportation_mode } =
        formData

      const data = {
        silo_id,
        silo_name: getSiloName(silo_id),
        city_id,
        ownership,
        products: products.reduce(checkedValuesReducer, []),
        transportation_mode: transportation_mode.reduce(
          checkedValuesReducer,
          []
        )
      }
      submit(data)
    },
    [getSiloName, submit]
  )

  useEffect(() => {
    if (currentData?.silo_name) {
      setValue('silo_id', currentData?.silo_name)
    } else if (state) setValue('silo_id', fullPrefix)
  }, [setValue, fullPrefix, state, currentData?.silo_name])

  return (
    <form className='mt-6 mb-48' onSubmit={handleSubmit(onSubmit)}>
      <section className='grid grid-cols-2'>
        <div>
          <h3 className='text-xl font-bold'>
            {t(
              `assetConfigurationScreen.silos.${argKey}form.siloInformation.title`
            )}
          </h3>
          <p className='text-grey-500'>
            {t(
              'assetConfigurationScreen.silos.form.siloInformation.description'
            )}
          </p>
        </div>
        <div>
          <ControlledSelect
            fullWidth
            disabled
            options={COUNTRY_OPTIONS}
            label={t(
              'assetConfigurationScreen.silos.form.siloInformation.fields.countrySelector.label'
            )}
            onChange={() => {
              resetField('state')
              resetField('city_id')
            }}
            controllerProps={{
              name: 'country',
              control,
              rules: {
                required: {
                  value: true,
                  message: t(
                    'assetConfigurationScreen.silos.form.siloInformation.fields.countrySelector.errorMessages.required'
                  )
                }
              }
            }}
          />
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledSelect
              fullWidth
              options={stateOptions}
              label={t(
                `assetConfigurationScreen.silos.form.siloInformation.fields.${stateKey}.label`
              )}
              placeholder={t(
                `assetConfigurationScreen.silos.form.siloInformation.fields.${stateKey}.placeholder`
              )}
              onChange={() => resetField('city_id')}
              controllerProps={{
                name: 'state',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      `assetConfigurationScreen.silos.form.siloInformation.fields.${stateKey}.errorMessages.required`
                    )
                  }
                }
              }}
            />
            <ControlledSelect
              fullWidth
              options={cityOptionsBySelectedState}
              label={t(
                'assetConfigurationScreen.silos.form.siloInformation.fields.citySelector.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.silos.form.siloInformation.fields.citySelector.placeholder'
              )}
              className='col-span-2'
              disabled={!state}
              controllerProps={{
                name: 'city_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.silos.form.siloInformation.fields.citySelector.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
          <div className='mt-6 flex justify-between items-end'>
            <ControlledInput
              fullWidth
              disabled={isEditMode || !state}
              description={
                !!getSiloName(silo_id) && (
                  <span className='text-gray-400 text-xs'>
                    Silo name: &nbsp;
                    <strong>{getSiloName(silo_id)}</strong>
                  </span>
                )
              }
              label={t(
                `assetConfigurationScreen.silos.${argKey}form.siloInformation.fields.siloName.label`
              )}
              placeholder={t(
                `assetConfigurationScreen.silos.${argKey}form.siloInformation.fields.siloName.placeholder`
              )}
              fieldOnchange={(value) => {
                return (
                  fullPrefix + value.substr(fullPrefix.length)
                ).toUpperCase()
              }}
              tooltip={
                isEditMode
                  ? t('assetConfigurationScreen.editDisabled')
                  : undefined
              }
              endAddon={
                <button className='bg-blue-400 hover:text-blue-600 disabled:bg-blue-300  p-2 rounded-md ml-4'>
                  <DocumentDuplicateIcon
                    className='w-6 h-6 text-white'
                    onClick={(e) => {
                      e.preventDefault()
                      try {
                        navigator.clipboard.writeText(silo_id)
                      } catch (err) {
                        console.error('Failed to copy: ', err)
                      }
                    }}
                  />
                </button>
              }
              controllerProps={{
                name: 'silo_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.silos.form.siloInformation.fields.siloName.errorMessages.required'
                    )
                  },
                  minLength: {
                    value: 3,
                    message: t(
                      'assetConfigurationScreen.silos.form.siloInformation.fields.siloName.errorMessages.minLength'
                    )
                  },
                  validate: (value) => {
                    if (SiloIDRegexp.test(value.substring(fullPrefix.length))) {
                      return t(
                        'assetConfigurationScreen.silos.form.siloInformation.fields.siloName.errorMessages.validation'
                      )
                    }
                  }
                }
              }}
            />
          </div>
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledRadioGroup
              componentClassName='mb-6'
              label={t(
                `assetConfigurationScreen.silos.${argKey}form.siloInformation.fields.siloOwnership.label`
              )}
              options={[
                {
                  label: t(
                    'assetConfigurationScreen.silos.form.siloInformation.fields.siloOwnership.bunge'
                  ),
                  value: 'owned'
                },
                {
                  label: t(
                    'assetConfigurationScreen.silos.form.siloInformation.fields.siloOwnership.external'
                  ),
                  value: 'external'
                }
              ]}
              controllerProps={{
                name: 'ownership',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.silos.form.siloInformation.fields.siloOwnership.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
        </div>
      </section>
      {countryCode === 'BR' && (
        <div>
          <section className='grid grid-cols-2 mt-6'>
            <div>
              <h3 className='text-xl font-bold'>
                {t('assetConfigurationScreen.silos.form.products.title')}
              </h3>
              <p className='text-grey-500'>
                {t('assetConfigurationScreen.silos.form.products.description')}
              </p>
            </div>
            <div>
              <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2 mb-1'>
                {products.map(({ value, id }, index) => (
                  <ControlledCheckbox
                    key={id}
                    label={t(`products.${value.replaceAll(/\s+/g, '_')}`)}
                    valueKey='value'
                    onChange={() => {
                      isSubmitted && triggerValidation('products')
                    }}
                    controllerProps={{
                      name: `products.${index}.checked`,
                      control
                    }}
                  />
                ))}
              </div>
              {errors?.products?.root?.message && (
                <span className='text-error-400'>
                  {errors.products.root.message}
                </span>
              )}
            </div>
          </section>
          <section className='grid grid-cols-2 mt-10'>
            <div>
              <h3 className='text-xl font-bold'>
                {t(
                  'assetConfigurationScreen.silos.form.transportationMode.title'
                )}
              </h3>
              <p className='text-grey-500'>
                {t(
                  'assetConfigurationScreen.silos.form.transportationMode.description'
                )}
              </p>
            </div>
            <div>
              <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2 mb-1'>
                {transportationMode.map(({ value, id }, index) => (
                  <ControlledCheckbox
                    key={id}
                    label={t(`transportationModes.${value}`)}
                    valueKey='value'
                    onChange={() => {
                      isSubmitted && triggerValidation('transportation_mode')
                    }}
                    controllerProps={{
                      name: `transportation_mode.${index}.checked`,
                      control
                    }}
                  />
                ))}
              </div>
              {errors?.transportation_mode?.root?.message && (
                <span className='text-error-400'>
                  {errors.transportation_mode.root.message}
                </span>
              )}
            </div>
          </section>
        </div>
      )}
      <section className='grid grid-cols-2 mt-6'>
        <div className='col-start-2'>
          {error && <Alert status='error' message={error.message} />}
          <Button
            variant='filled'
            size='l'
            className='mt-6 float-right w-48'
            type='submit'
            loading={isMutating}
            disabled={!isDirty}
          >
            {`${
              siloId
                ? t('assetConfigurationScreen.silos.form.update')
                : t('assetConfigurationScreen.silos.form.register')
            } ${t(`assetConfigurationScreen.silos.${argKey}form.assetName`)}`}
          </Button>
        </div>
      </section>
    </form>
  )
}

export default SiloForm

