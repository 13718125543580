import NoData from 'components/Feedback/NoData'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import React from 'react'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { valueFormatter } from 'screens/Optimization/utils/output'
import { capitalize } from 'utils/string'
import InAndOutboundChart from '../InAndOutboundChart'
import useHeatMap from '../useHeatmap'
import { useLocalization } from 'providers/LocalizationProvider'
import { useOptimizationContext } from 'providers/OptimizationProvider'

const InboundDetailed = ({ inboundData, originationData }) => {
  const { t } = useLocalization()
  const { comparisonID } = useOptimizationContext()
  const { heatmapData } = useHeatMap({
    data: originationData,
    mainRowKey: 'mesoregion',
    valueKey: 'inbound_volume'
  })

  return (
    <>
      <h3 className='text-lg font-bold mb-8'>
        {t('optimizationScreen.storage.inbound.title')}
      </h3>
      {inboundData.map(({ transportation_mode, data }) => (
        <React.Fragment key={transportation_mode}>
          <InAndOutboundChart
            data={data}
            transportationMode={transportation_mode}
          />
        </React.Fragment>
      ))}
      <div>
        <h3 className='text-base font-bold mb-6'>
          {t('optimizationScreen.storage.inbound.origination')}
        </h3>
        {!hasNoData(heatmapData, 'data') ? (
          <MultiColumnsBoxTable
            showTitles
            expandable
            data={heatmapData}
            referenceProps={{ title: t('optimizationScreen.comparison') }}
            comparedProps={{ title: t('optimizationScreen.baseModel') }}
            groupLabelFormatter={transformDateToShortMonth}
            rowTitleFormatter={(v) => (v ? capitalize(v) : '-')}
            columnValueFormatter={valueFormatter}
            referencePropKey={comparisonID}
          />
        ) : (
          <section>
            <NoData />
          </section>
        )}
      </div>
    </>
  )
}

export default InboundDetailed
