import Button from 'components/Inputs/Button'
import {
  useFieldArray,
  useController,
  useWatch,
  useFormState
} from 'react-hook-form'
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ControlledInput } from 'components/Inputs'
import { ControlledCheckbox } from 'components/Inputs/Checkbox'
import { useLocalization } from 'providers/LocalizationProvider'
import { useEffect, useMemo } from 'react'
import { TERMINAL_PREFIX, TerminalIDRegexp } from '../../constants'

const Terminal = ({
  index,
  control,
  trigger,
  remove,
  removeable,
  include,
  disabled,
  getTerminalName,
  terminalName,
  setValue
}) => {
  const { t, countryCode } = useLocalization()
  const { isSubmitted } = useFormState({ control })

  const {
    fieldState: { error }
  } = useController({
    control,
    name: `terminals.${index}`
  })

  const [state, terminal_id] = useWatch({
    control,
    name: ['state', `terminals.${index}.terminal_id`]
  })

  const fullPrefix = useMemo(() => terminalName? `${TERMINAL_PREFIX}_${state}_${terminalName}`:  `${TERMINAL_PREFIX}_${state}_`, [state, terminalName])

  const { fields: products } = useFieldArray({
    control,
    name: `terminals.${index}.products`,
    rules: {
      validate: (currentValue) => {
        if (
          (include === undefined || include === true) &&
          !currentValue.some((product) => product.checked === true) &&
          countryCode !== 'ARG'
        ) {
          return t(
            'assetConfigurationScreen.ports.form.terminalInformation.fields.products.errorMessages.required'
          )
        }
      }
    }
  })

  const { fields: transportationModes } = useFieldArray({
    control,
    name: `terminals.${index}.transportation_mode`,
    rules: {
      validate: (currentValue) => {
        if (
          !currentValue?.some((tp_mode) => tp_mode.checked === true) &&
          countryCode !== 'ARG'
        ) {
          return t(
            'assetConfigurationScreen.ports.form.terminalInformation.fields.transportationModes.errorMessages.required'
          )
        }
      }
    }
  })

  useEffect(() => {
    if (state) setValue(`terminals.${index}.terminal_id`, fullPrefix)
  }, [setValue, fullPrefix, state, index])

  return (
    <section className='grid grid-cols-2 mt-10'>
      <div>
        <div className='flex gap-4'>
          <h3 className='text-xl font-bold'>
            {t(
              'assetConfigurationScreen.ports.form.terminalInformation.title',
              { count: index + 1 }
            )}
          </h3>
          {removeable && (
            <Button
              size='s'
              variant='light'
              onClick={() => {
                remove(index)
              }}
            >
              <TrashIcon className='w-5 h-5' />
            </Button>
          )}
        </div>
        <p className='text-grey-500'>
          {t(
            'assetConfigurationScreen.ports.form.terminalInformation.description'
          )}
        </p>
      </div>
      <div>
        <div className='mt-6 flex justify-between items-end'>
          <ControlledInput
            description={
              !!getTerminalName(terminal_id) && (
                <span className='text-gray-400 text-xs'>
                  Terminal name: &nbsp;
                  <strong>{getTerminalName(terminal_id)}</strong>
                </span>
              )
            }
            fullWidth
            disabled={disabled}
            fieldOnchange={(value) => {
              return (
                fullPrefix + value.substr(fullPrefix.length)
              ).toUpperCase()
            }}
            label={t(
              'assetConfigurationScreen.ports.form.terminalInformation.fields.terminalName.label',
              { count: index + 1 }
            )}
            placeholder={t(
              'assetConfigurationScreen.ports.form.terminalInformation.fields.terminalName.placeholder'
            )}
            endAddon={
              <button
                className=' bg-blue-400 hover:text-blue-600 disabled:bg-blue-300  p-2 rounded-md ml-4'
                disabled={!terminal_id}
              >
                <DocumentDuplicateIcon
                  className='w-6 h-6 text-white'
                  onClick={(e) => {
                    e.preventDefault()
                    try {
                      navigator.clipboard.writeText(terminal_id)
                    } catch (err) {
                      console.error('Failed to copy: ', err)
                    }
                  }}
                />
              </button>
            }
            controllerProps={{
              name: `terminals.${index}.terminal_id`,
              control,
              rules: {
                required: {
                  value: true,
                  message: t(
                    'assetConfigurationScreen.ports.form.terminalInformation.fields.terminalName.errorMessages.required'
                  )
                },
                minLength: {
                  value: 3,
                  message: t(
                    'assetConfigurationScreen.ports.form.terminalInformation.fields.terminalName.errorMessages.minLength'
                  )
                },
                validate: (value) => {
                  if (TerminalIDRegexp.test(value.substr(fullPrefix.length))) {
                    return t(
                      'assetConfigurationScreen.ports.form.terminalInformation.fields.terminalName.errorMessages.validation'
                    )
                  }
                }
              }
            }}
          />
        </div>

        {countryCode === 'BR' && (
          <section>
            <h6 className='text-sm font-bold mt-6 mb-2'>
              {t(
                'assetConfigurationScreen.ports.form.terminalInformation.fields.products.label'
              )}
            </h6>
            <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2 mb-1'>
              {products.map(({ value, id }, productIndex) => (
                <ControlledCheckbox
                  key={id}
                  label={t(`products.${value.replaceAll(/\s+/g, '_')}`)}
                  valueKey='value'
                  onChange={() => {
                    isSubmitted && trigger(`terminals.${index}.products`)
                  }}
                  controllerProps={{
                    name: `terminals.${index}.products.${productIndex}.checked`,
                    control
                  }}
                />
              ))}
            </div>
            {error?.products?.root?.message && (
              <span className='text-error-400'>
                {error.products.root.message}
              </span>
            )}
            <h6 className='text-sm font-bold mt-6 mb-2'>
              {t(
                'assetConfigurationScreen.ports.form.terminalInformation.fields.transportationModes.label'
              )}
            </h6>
            <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2 mb-1'>
              {transportationModes.map(
                ({ value, id }, transportationModeIndex) => (
                  <ControlledCheckbox
                    key={id}
                    label={t(`transportationModes.${value}`)}
                    valueKey='value'
                    onChange={() => {
                      isSubmitted &&
                        trigger(`terminals.${index}.transportation_mode`)
                    }}
                    controllerProps={{
                      name: `terminals.${index}.transportation_mode.${transportationModeIndex}.checked`,
                      control
                    }}
                  />
                )
              )}
            </div>
            {error?.transportation_mode?.root?.message && (
              <span className='text-error-400'>
                {error.transportation_mode.root.message}
              </span>
            )}
          </section>
        )}
      </div>
    </section>
  )
}

export default Terminal
