import Button from 'components/Inputs/Button'
import { useMemo, useCallback, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import ControlledSearchCheckbox from 'components/Inputs/ControlledSearchCheckbox'
import { getSiloCoverageOptions } from 'screens/Configuration/utils'
import { COUNTRY_OPTIONS, STATE_KEY_MAP } from 'screens/Configuration/constants'
import { ControlledSelect } from 'components/Inputs/Select'
import { ControlledInput } from 'components/Inputs'
import useLocationSelect from 'hooks/useLocationSelect'
import useSubmitConfigForm from 'hooks/useSubmitConfigForm'
import Alert from 'components/Feedback/Alert'
import ControlledRadioGroup from 'components/Inputs/RadioGroup/ControlledRadioGroup'
import { useLocalization } from 'providers/LocalizationProvider'
import { capitalize } from 'utils/string'
import RadioGroup from 'components/Inputs/RadioGroup'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { CRUSH_PREFIX, CrusherIDRegexp } from '../../constants'

const getPortOrAcopioState = (data) => {
  if (!data) {
    return null
  }

  if (data.ports.length > 0) {
    return 'port'
  }

  if (data.silo_coverage.length > 0) {
    return 'acopio'
  }

  return null
}

const CrushingForm = ({
  id: crusherId,
  assetData: { silos, corridors, ports },
  currentData
}) => {
  const { t, countryCode } = useLocalization()
  const stateKey = useMemo(() => {
    return STATE_KEY_MAP[countryCode]
  }, [countryCode])

  const isEditMode = useMemo(() => {
    return crusherId !== undefined
  }, [crusherId])

  const {
    watch,
    control,
    handleSubmit,
    resetField,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: currentData ?? {
      crusher_name: currentData?.crusher_name || '',
      crusher_id: '',
      country: countryCode,
      state: undefined,
      city_id: undefined,
      ownership: undefined,
      silo_coverage: [],
      ports: []
    }
  })

  const crusher_id = watch('crusher_id')
  const state = watch('state')
  const country = watch('country')

  const { stateOptions, cityOptionsBySelectedState } = useLocationSelect({
    state
  })

  const [portOrAcopio, setPortOrAcopio] = useState(
    getPortOrAcopioState(currentData)
  )

  const changePortOrAcopio = useCallback(
    (value) => {
      setPortOrAcopio(value)
      setValue('ports', [], { shouldDirty: !value })
      setValue('silo_coverage', [], { shouldDirty: !value })
    },
    [setValue]
  )

  const { submit, isMutating, error } = useSubmitConfigForm({
    basePath: '/crusher',
    id: crusherId
  })

  const getCrushName = useCallback(
    (crushId) => {
      if (state)
        return crushId?crushId.substring(
          CRUSH_PREFIX.length + '_'.length + state.length + '_'.length
        ):''
    },
    [state]
  )

  const onSubmit = useCallback(
    (formData) => {
      const { crusher_id, city_id, ownership, silo_coverage, ports } = formData

      const data = {
        crusher_id: crusher_id,
        crusher_name: getCrushName(crusher_id),
        city_id,
        ownership,
        silo_coverage: silo_coverage.map((i) => i.value),
        ports: ports?.map((i) => i.value)?.[0] || ''
      }

      submit(data)
    },
    [getCrushName, submit]
  )

  const siloOptions = useMemo(
    () => getSiloCoverageOptions(silos, corridors),
    [corridors, silos]
  )

  const fullPrefix = useMemo(() => `${CRUSH_PREFIX}_${state}_`, [state])

  useEffect(() => {
    if(currentData?.crusher_name){
      setValue('crusher_id', currentData?.crusher_name)
    }
    else if (state) setValue('crusher_id', fullPrefix)
  }, [currentData?.crusher_name, fullPrefix, setValue, state])

  return (
    <form className='mt-6 mb-48' onSubmit={handleSubmit(onSubmit)}>
      <section className='grid grid-cols-2'>
        <div>
          <h3 className='text-xl font-bold'>
            {t(
              'assetConfigurationScreen.crushers.form.crusherInformation.title'
            )}
          </h3>
          <p className='text-grey-500'>
            {t(
              'assetConfigurationScreen.crushers.form.crusherInformation.description'
            )}
          </p>
        </div>
        <div>
          <div className='mb-6'>
            {t(
              'assetConfigurationScreen.crushers.form.crusherInformation.fields.countrySelector.label'
            )}
            :{' '}
            <span className='font-bold'>
              {COUNTRY_OPTIONS.find((x) => x.value === country).label}
            </span>
          </div>
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledSelect
              fullWidth
              options={stateOptions}
              label={t(
                `assetConfigurationScreen.crushers.form.crusherInformation.fields.${stateKey}.label`
              )}
              placeholder={t(
                `assetConfigurationScreen.crushers.form.crusherInformation.fields.${stateKey}.placeholder`
              )}
              onChange={() => resetField('city_id')}
              controllerProps={{
                name: 'state',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      `assetConfigurationScreen.crushers.form.crusherInformation.fields.${stateKey}.errorMessages.required`
                    )
                  }
                }
              }}
            />
            <ControlledSelect
              fullWidth
              options={cityOptionsBySelectedState}
              label={t(
                'assetConfigurationScreen.crushers.form.crusherInformation.fields.citySelector.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.crushers.form.crusherInformation.fields.citySelector.placeholder'
              )}
              className='col-span-2'
              disabled={!state}
              controllerProps={{
                name: 'city_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.crushers.form.crusherInformation.fields.citySelector.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
          <div className='mt-6 flex justify-between items-end'>
            <ControlledInput
              description={
                !!getCrushName(crusher_id) && (
                  <span className='text-gray-400 text-xs'>
                    Crush name: &nbsp;
                    <strong>{getCrushName(crusher_id)}</strong>
                  </span>
                )
              }
              fullWidth
              disabled={isEditMode || !state}
              label={t(
                'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherName.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherName.placeholder'
              )}
              tooltip={
                isEditMode
                  ? t('assetConfigurationScreen.editDisabled')
                  : undefined
              }
              fieldOnchange={(value) => {
                return (
                  fullPrefix + value.substring(fullPrefix.length)
                ).toUpperCase()
              }}
              endAddon={
                <button
                  className=' bg-blue-400 hover:text-blue-600 disabled:bg-blue-300  p-2 rounded-md ml-4'
                  disabled={!crusher_id}
                >
                  <DocumentDuplicateIcon
                    className='w-6 h-6 text-white'
                    onClick={(e) => {
                      e.preventDefault()
                      try {
                        navigator.clipboard.writeText(crusher_id)
                      } catch (err) {
                        console.error('Failed to copy: ', err)
                      }
                    }}
                  />
                </button>
              }
              controllerProps={{
                name: 'crusher_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherName.errorMessages.required'
                    )
                  },
                  minLength: {
                    value: 3,
                    message: t(
                      'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherName.errorMessages.minLength'
                    )
                  },
                  validate: (value) => {
                    if (CrusherIDRegexp.test(value.substr(fullPrefix.length))) {
                      return t(
                        'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherName.errorMessages.validation'
                      )
                    }
                  }
                }
              }}
            />
          </div>
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledRadioGroup
              className='mb-6'
              label={t(
                'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherOwnership.label'
              )}
              options={[
                {
                  label: t(
                    'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherOwnership.bunge'
                  ),
                  value: 'owned'
                },
                {
                  label: t(
                    'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherOwnership.external'
                  ),
                  value: 'external'
                }
              ]}
              controllerProps={{
                name: 'ownership',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.crushers.form.crusherInformation.fields.crusherOwnership.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
        </div>
      </section>
      {countryCode === 'BR' && (
        <section className='grid grid-cols-2 mt-12'>
          <div>
            <h3 className='text-xl font-bold'>
              {t('assetConfigurationScreen.crushers.form.siloCoverage.title')}
            </h3>
            <p className='text-grey-500'>
              {t(
                'assetConfigurationScreen.crushers.form.siloCoverage.description'
              )}
            </p>
          </div>
          <div>
            {!!siloOptions?.length && (
              <ControlledSearchCheckbox
                label={t(
                  'assetConfigurationScreen.crushers.form.siloCoverage.listLabel'
                )}
                controllerProps={{
                  control,
                  name: 'silo_coverage',
                  rules: {
                    required: {
                      value: true,
                      message: t(
                        'assetConfigurationScreen.crushers.form.siloCoverage.errorMessages.required'
                      )
                    }
                  }
                }}
                placeholder={t(
                  'assetConfigurationScreen.crushers.form.siloCoverage.searchbarPlaceholder'
                )}
                options={siloOptions}
                error={errors?.['silo_coverage']}
                className='mt-4'
              />
            )}
          </div>
        </section>
      )}
      {countryCode === 'ARG' && (
        <section className='grid grid-cols-2 mt-12'>
          <div>
            <h3 className='text-xl font-bold'>
              {t('assetConfigurationScreen.crushers.form.portOrAcopio.title')}
            </h3>
            <p className='text-grey-500'>
              {t(
                'assetConfigurationScreen.crushers.form.portOrAcopio.description'
              )}
            </p>
          </div>
          <div>
            <RadioGroup
              value={portOrAcopio}
              onChange={changePortOrAcopio}
              options={[
                {
                  value: 'port',
                  label: t(
                    'assetConfigurationScreen.crushers.form.portOrAcopio.portTitle'
                  )
                },
                {
                  value: 'acopio',
                  label: t(
                    'assetConfigurationScreen.crushers.form.portOrAcopio.acopioTitle'
                  )
                },
                {
                  value: null,
                  label: t(
                    'assetConfigurationScreen.crushers.form.portOrAcopio.noneTitle'
                  )
                }
              ]}
            />
            {!!silos?.length && portOrAcopio === 'acopio' && (
              <ControlledSearchCheckbox
                label={capitalize(portOrAcopio)}
                controllerProps={{
                  control,
                  name: 'silo_coverage'
                }}
                placeholder={t(
                  'assetConfigurationScreen.crushers.form.portOrAcopio.acopioPlaceholder'
                )}
                options={silos.map((silo) => ({
                  label: silo.silo_name,
                  value: silo.id,
                  disabled:
                    getValues('silo_coverage')?.length > 0 &&
                    getValues('silo_coverage')[0].value !== silo.id
                }))}
                error={errors?.['silo_coverage']}
                className='mt-4'
              />
            )}
            {!!ports?.length && portOrAcopio === 'port' && (
              <ControlledSearchCheckbox
                label={capitalize(portOrAcopio)}
                controllerProps={{
                  control,
                  name: 'ports'
                }}
                placeholder={t(
                  'assetConfigurationScreen.crushers.form.portOrAcopio.portPlaceholder'
                )}
                options={ports.map((port) => ({
                  label: port.terminal_name,
                  value: port.id,
                  disabled:
                    getValues('ports')?.length > 0 &&
                    getValues('ports')[0].value !== port.id
                }))}
                error={errors?.['silo_coverage']}
                className='mt-4'
              />
            )}
          </div>
        </section>
      )}
      <section className='grid grid-cols-2 mt-6'>
        <div className='col-start-2'>
          {error && <Alert status='error' message={error.message} />}
          <Button
            variant='filled'
            size='l'
            className='mt-6 float-right'
            type='submit'
            loading={isMutating}
            disabled={!isDirty || !isValid}
          >
            {`${
              crusherId
                ? t('assetConfigurationScreen.crushers.form.update')
                : t('assetConfigurationScreen.crushers.form.register')
            } ${t('assetConfigurationScreen.crushers.form.assetName')}`}
          </Button>
        </div>
      </section>
    </form>
  )
}

export default CrushingForm
