import TableChart from 'components/Graphs/TableChart'
import useProductFilter from 'hooks/useProductFilter'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_LABEL,
  transformDateToShortMonth,
  hasNoData
} from 'screens/Optimization/utils'
import { capitalize } from 'utils/string'
import useSummaryItems from 'hooks/useSummaryItems'
import useGraph from 'hooks/useGraph'
import NoData from 'components/Feedback/NoData'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import { useMemo } from 'react'
import {
  labelFormatter,
  valueFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'
import { groupBy, sum } from 'ramda'

const calcExecutionIdData = ({
  currentData,
  record: { product_ui_type, volume }
}) => {
  if (typeof currentData === 'object') {
    return {
      ...currentData,
      [product_ui_type]:
        currentData[product_ui_type] === NO_DATA_LABEL
          ? volume
          : currentData[product_ui_type] + volume
    }
  }
  return currentData === NO_DATA_LABEL ? volume : currentData + volume
}

const AllSilos = ({ data }) => {
  const { t } = useLocalization()
  const { id: baseModelId } = useParams()
  const {
    comparisonID,
    selectedProducts,
    selectedProductTypes,
    selectedSubProducts
  } = useOptimizationContext()

  const { filteredData } = useProductFilter({
    data,
    selectedSubProducts
  })

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'storage_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  const capacityData = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(
          groupBy(
            (x) => x.month,
            filteredData.filter((x) => x.execution_id === baseModelId)
          )
        ).map(([month, d]) => {
          return [
            month,
            Object.fromEntries(
              Object.entries(groupBy((x) => x.storage_type, d)).map(
                ([storage_type, x]) => [
                  storage_type,
                  sum(
                    Object.values(groupBy((y) => y.asset, x)).map(
                      (y) => y[0][capacityKey]
                    )
                  )
                ]
              )
            )
          ]
        })
      ),
    [filteredData, baseModelId, capacityKey]
  )

  const { summaryItems } = useSummaryItems({
    data: filteredData,
    volumeKey: 'volume'
  })

  const { volumeGraphData, bars } = useGraph({
    data: filteredData,
    monthContextData: {
      capacity: ({ record: { month } }) => {
        return capacityData[month]
      }
    },
    capacityKey,
    calcExecutionIdData
  })

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'volume',
    data: filteredData,
    filterProducts: true
  })

  return !hasNoData(volumeGraphData) ? (
    <TableChart
      title={t('optimizationScreen.storage.allSilos.titleArg')}
      summaryItems={summaryItems}
      data={volumeGraphData}
      boxTableValueFormatter={(value) => `${value} Tons`}
      yAxisFormatter={yAxisFormatter}
      xAxisProps={{
        dataKey: 'month',
        tickFormatter: transformDateToShortMonth
      }}
      bars={bars}
      tooltipFormatter={labelFormatter}
      scatters={[
        {
          dataKey: 'capacity.fixed',
          fill: '#000',
          name: t('optimizationScreen.totalCapacity'),
          thickness: 2
        },
        {
          dataKey: 'capacity.variable',
          fill: '#2BAC82',
          name: t('optimizationScreen.variableCapacity')
        }
      ]}
      tableData={heatmapData}
      tableProps={{
        referenceProps: {
          title: t('optimizationScreen.comparison'),
          objKey: comparisonID
        },
        comparedProps: {
          title: t('optimizationScreen.baseModel'),
          objKey: baseModelId
        },
        groupLabelFormatter: transformDateToShortMonth,
        columnValueFormatter: valueFormatter,
        rowTitleFormatter: capitalize
      }}
    />
  ) : (
    <NoData page={t('optimizationScreen.pageNames.storage')} />
  )
}

export default AllSilos
