import { useMemo } from 'react'
import {
  MagnifyingGlassIcon,
  DocumentMagnifyingGlassIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/24/outline'

import copy from 'copy-to-clipboard'

import Accordion, {
  AccordionItem,
  AccordionHeader,
  AccordionBody
} from 'components/Accordion/Accordion'
import { useLogs } from 'hooks/useLogs'

import Button from 'components/Inputs/Button'
import { useLocalization } from 'providers/LocalizationProvider'
import { useParams } from 'react-router-dom'

const LogErrorPage = () => {
  const { name } = useParams()
  const { t } = useLocalization()
  const { logs } = useLogs()

  const log_error = useMemo(() => {
    return logs.reduce(
      (acc, error) =>
        `${acc} ${error.timestamp}-${error.source}-${JSON.stringify(
          error.message
        )} \n`,
      ''
    )
  }, [logs])

  return (
    <div className='max-h-screen overflow-y-auto'>
      <div className='mx-auto px-4 mb-20 2xl:mx-20'>
        <div className='flex mt-10 justify-between items-center mb-8'>
          <div>
            <div className='flex items-center gap-2'>
              <MagnifyingGlassIcon className='h-6 w-6 text-gray-500' />
              <h1 className='font-bold text-2xl text-blue-700'>
                {t('optimizationScreen.logerror.logErrorTitle')}
              </h1>
            </div>
            <p className='text-sm text-gray-400 mt-2'>
              {t('optimizationScreen.logerror.logErrorDescription')}
            </p>
          </div>
          <Button variant='filled' size='s' onClick={() => copy(log_error)}>
            <DocumentDuplicateIcon className='h-5 w-5' />
            {t('optimizationScreen.logerror.copyError')}
          </Button>
        </div>
        <div>
          <Accordion>
            <AccordionItem value={1} key={1} className='mb-3 last:mb-0'>
              <AccordionHeader className='py-3.5 px-6'>
                <div className='w-full flex items-start '>
                  <DocumentMagnifyingGlassIcon className='w-7 h-7 text-blue-500 stroke-5' />
                  <div className='mx-2'>
                    <h2 className='font-bold text-base text-start'>{name}</h2>
                    <p className='text-xs font-medium text-gray-400 my-1'>
                      Long to short the problem has the following error log:
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody>
                <div className='px-10 pb-6 pt-4 border-t border-gray-200'>
                  <p className='font-bold text-sm'>Error</p>
                  <ul className='mt-2 text-xs list-disc list-inside font-bold break-words'>
                    {logs &&
                      logs.map((error, index) => {
                        return (
                          <li
                            key={`${index}-${error.timestamp}-${error.source}`}
                            className='list-none'
                          >
                            <span className='text-green-400'>
                              {error.timestamp}
                            </span>
                            &nbsp; | &nbsp;
                            <span className='text-blue-500'>
                              {error.source}
                            </span>
                            &nbsp; | &nbsp;
                            <span
                              className='text-blue-500'
                              dangerouslySetInnerHTML={{
                                __html: JSON.stringify(error.message)
                                  .replace(/\\n/g, '<br />')
                                  .replace(/\\/g, '')
                              }}
                            />
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default LogErrorPage
