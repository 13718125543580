import { PORTS, SILOS, TRANSSHIPMENTS, CRUSHERS, PARAMETERS } from 'constants'
import { useAssetsConfigurationsContext } from 'providers/AssetsConfigurationsProvider'
import { Crushers, Ports, Silos, Transshipments, Parameters } from './sections'
import ButtonNavigation from 'components/Navigation/ButtonNavigation'
import { CrushingPlantIcon, ShipIcon, SiloIcon } from 'components/Icons'
import { Cog6ToothIcon, TruckIcon } from '@heroicons/react/24/outline'
import { useLocalization } from 'providers/LocalizationProvider'
import { useMemo } from 'react'

const SectionsMap = {
  [PORTS]: <Ports />,
  [SILOS]: <Silos />,
  [TRANSSHIPMENTS]: <Transshipments />,
  [CRUSHERS]: <Crushers />,
  [PARAMETERS]: <Parameters />
}

function AssetsConfigurations() {
  const { t, countryCode } = useLocalization()
  const { page, setPage } = useAssetsConfigurationsContext()
  const argKey = useMemo(
    () => (countryCode === 'ARG' ? 'arg.' : ''),
    [countryCode]
  )

  const transshipmentSection = {
    icon: TruckIcon,
    title: t('assetConfigurationScreen.sections.transshipments'),
    value: TRANSSHIPMENTS
  }

  const sections = [
    {
      icon: ShipIcon,
      title: t('assetConfigurationScreen.sections.ports'),
      value: PORTS
    },
    {
      icon: SiloIcon,
      title: t(`assetConfigurationScreen.sections.${argKey}silos`),
      value: SILOS
    },
    {
      icon: CrushingPlantIcon,
      title: t('assetConfigurationScreen.sections.crushingPlants'),
      value: CRUSHERS
    },
    {
      icon: Cog6ToothIcon,
      title: t('assetConfigurationScreen.sections.parameters'),
      value: PARAMETERS
    }
  ]

  if (countryCode !== 'ARG' && sections.length !== 5) {
    sections.splice(2, 0, transshipmentSection)
  }

  return (
    <div className='max-h-screen overflow-y-auto'>
      <div className='mx-auto px-4 mb-20 2xl:mx-20'>
        <h1 className='font-bold text-bold text-3xl mt-8'>
          {t('assetConfigurationScreen.title')}
        </h1>
        <p className='text-grey-500 mt-3'>
          {t('assetConfigurationScreen.description')}
        </p>
        <div className='mt-6'>
          {page !== PARAMETERS && (
            <p className='text-grey-500'>
              {t('assetConfigurationScreen.note')}
            </p>
          )}
        </div>
        <ButtonNavigation
          buttons={sections}
          selected={page}
          setSelected={setPage}
        />
        <p className='my-5 text-gray-500'>
          {t('assetConfigurationScreen.sectionDescription', {
            asset:
              page === SILOS && countryCode === 'ARG'
                ? 'acopios'
                : page.toLowerCase()
          })}
        </p>
        {SectionsMap[page]}
      </div>
    </div>
  )
}

export default AssetsConfigurations
