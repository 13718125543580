import InconsistenciesSidebar from './InconsistenciesSidebar'
import { useMemo, useState } from 'react'
import Button from 'components/Inputs/Button'
import ProceedModal from '../../NewOptimization/Main/ProceedModal'
import ValidatedFileSelectModal from 'components/Modal/ValidatedFileSelectModal'
import { stripDataTypePrefix, templateTypeFromFilename } from 'utils/string'
import { getBlob } from 'utils/request'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'
import { useOptDataContext } from 'providers/OptDataProvider'
import { useCreateExecution } from 'hooks/useCreateExecution'
import { getNewOptimizationName } from 'utils/optimization-name'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import * as FileSaver from 'file-saver'
import { useToast } from 'providers/ToastProvider'
import useSWRMutation from 'swr/mutation'
import { post } from 'utils/request'
import Container from 'components/Container/BaseContainer'
import dayjs from 'dayjs'

const Inconsistencies = () => {
  const { countryCode } = useLocalization()
  const { id } = useParams()
  const { data: inconsistencies } = useSWR(`/execution/${id}/inconsistencies`)
  const { data: execution, mutate } = useSWR(`/execution/${id}`)
  const { showToast } = useToast()
  const { t } = useLocalization()
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [proceedWarningOpen, setProceedWarningOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState({})
  const [newFiles, addNewFile] = useState([])
  const [selected, setSelected] = useState(
    inconsistencies?.blocks?.[0] || inconsistencies?.warnings?.[0]
  )
  const { optData } = useOptDataContext()
  const { createExecution } = useCreateExecution()

  const { trigger: continueExecution } = useSWRMutation(
    `/execution/${id}/model/run`,
    post,
    {
      onSuccess: ({ data }) => {
        if (data.id) {
          mutate()
        }
      },
      onError: (error) => {
        console.error(error)
        showToast({
          message: t('statusMessages.genericError'),
          status: 'error',
          placement: 'top'
        })
      }
    }
  )

  const { executionData, fileMap, isWhatif } = useMemo(() => {
    const executionData = JSON.parse(execution.data)
    return {
      fileMap: executionData.files.reduce(
        (acc, cur) => ({ ...acc, [cur.type]: cur.id }),
        {}
      ),
      isWhatif: executionData.type.toLowerCase().includes('what-if'),
      executionData
    }
  }, [execution])

  const updateFile = async (template) => {
    const type = templateTypeFromFilename(countryCode, template)
    setSelectedFile({ template, type, id: fileMap[type] })
    setShowUpdateModal(true)
  }

  const runOptimization = () => {
    const wrongTemplates = [
      ...inconsistencies.blocks.map((block) => block.template),
      ...inconsistencies.warnings.map((warning) => warning.template)
    ]
    newFiles.length !== wrongTemplates.flat().length
      ? setProceedWarningOpen(true)
      : createOptimization()
  }

  const createOptimization = async () => {
    if (newFiles.length === 0 && inconsistencies.blocks.length === 0) {
      continueExecution()
      return
    }
    const startDate = dayjs(execution.start_date)

    for (const file of newFiles) {
      const updatedFile = executionData.files.find(
        (item) => item.type === file.type
      )
      if (updatedFile) {
        updatedFile.id = file.id
      }
    }

    const body = {
      ...executionData,
      files: executionData.files,
      name: getNewOptimizationName(
        executionData.type,
        startDate.get('month') + 1,
        startDate.get('year'),
        optData,
        countryCode
      )
    }

    createExecution(body)
  }

  const handleDownload = async () => {
    try {
      const data = await getBlob(
        `/execution/${id}/inconsistencies/${selected.validation_id}/export`
      )

      FileSaver.saveAs(
        data,
        `inconsistencies_${
          selected.validation_id
        }_${new Date().toLocaleDateString()}`
      )
    } catch (e) {
      console.error('File download failed', e)
      showToast({
        message: t('statusMessages.fileDownloadFailed'),
        status: 'error',
        placement: 'top'
      })
    }
  }

  return (
    <Container>
      <div className='flex flex-row min-h-full'>
        <div className='max-w-sm xl:max-w-xl px-10 xl:pr-20 pt-10 h-fit'>
          <InconsistenciesSidebar
            data={inconsistencies}
            selected={selected}
            onClick={(item) => {
              setSelected(item)
            }}
            runOptimization={runOptimization}
          />
        </div>
        <div className='bg-white pl-10 pr-4 py-10 2xl:pr-20 flex-1'>
          <div className='flex pr-10'>
            <h2 className='w-full text-xl font-bold mb-3'>
              {selected?.description}
            </h2>
          </div>
          <div className='mb-10 flex justify-between items-center'>
            <p className='font-bold text-sm'>
              {t('optimizationScreen.inconsistencies.listOfProblems')}
            </p>
            <Button variant='filled' size='s' onClick={handleDownload}>
              <ArrowDownTrayIcon className='w-5 h-5 font-bold text-white' />
              {t('optimizationScreen.inconsistencies.downloadList')}
            </Button>
          </div>
          {selected?.inconsistencies && (
            <ul className='list-disc ml-10 mt-2 text-gray-500'>
              {selected?.inconsistencies.map((error) => {
                return (
                  error.dimension && (
                    <li key={error.value}>
                      The <b>{error.value}</b> value in <b>{error.dimension}</b>{' '}
                      column is invalid.
                    </li>
                  )
                )
              })}
            </ul>
          )}
          <section className='mt-8'>
            <p className='font-bold my-1'>
              {t('optimizationScreen.inconsistencies.fixIt')}
            </p>
            {selected?.template &&
              selected?.template.map((file, index) => {
                return (
                  <div
                    key={file + index}
                    className='bg-grey-100 rounded-lg flex justify-between px-4 py-2 items-center border my-2'
                  >
                    <p className='font-bold text-sm'>{file}</p>
                    <div className='flex items-center'>
                      {newFiles.find(
                        (newFile) =>
                          newFile?.type ===
                          templateTypeFromFilename(countryCode, file)
                      ) && (
                        <p className='text-sm text-sky-800 mr-2'>
                          {t('optimizationScreen.inconsistencies.fileUpdated')}
                        </p>
                      )}
                      <Button size='s' onClick={() => updateFile(file)}>
                        {t('optimizationScreen.inconsistencies.update')}
                      </Button>
                    </div>
                  </div>
                )
              })}
          </section>
        </div>
      </div>
      <ValidatedFileSelectModal
        title={`Update ${selectedFile.template} file`}
        type={selectedFile.type}
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        onSuccess={(files) => {
          addNewFile([
            ...newFiles,
            ...files.map(({ id, type }) => ({ id, type }))
          ])
        }}
        hint={
          <p className='px-4 mb-4 text-center text-grey-400'>
            {t('optimizationScreen.inconsistencies.updateModalHint')}{' '}
            <span
              onClick={async () => {
                const data = await getBlob(`/data/${selectedFile.id}/download`)
                FileSaver.saveAs(
                  data,
                  `${stripDataTypePrefix(
                    selectedFile.type
                  )?.toLowerCase()}.xlsx`
                )
              }}
              className='text-blue-400 underline font-bold cursor-pointer'
            >
              {t('optimizationScreen.inconsistencies.download')}
            </span>{' '}
            {t('optimizationScreen.inconsistencies.fileReference')}
          </p>
        }
        whatif={isWhatif}
      />
      <ProceedModal
        open={proceedWarningOpen}
        setOpen={setProceedWarningOpen}
        onProceed={createOptimization}
      />
    </Container>
  )
}

export default Inconsistencies
