import BarChart from 'components/Graphs/BarChart'
import { capitalize } from 'utils/string'
import useOutputGraph from '../useOutputGraph'
import {
  hasNoData,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import NoData from 'components/Feedback/NoData'
import {
  labelFormatter,
  yAxisFormatter
} from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const InAndOutboundChart = ({ transportationMode, volumes }) => {
  const { t } = useLocalization()
  const { volumeChartData, bars } = useOutputGraph(volumes)

  return (
    <div className='mb-10'>
      {!hasNoData(volumeChartData) ? (
        <>
          <h3 className='text-base font-bold'>
            {capitalize(transportationMode)}
          </h3>
          <BarChart
            variant='default350'
            containerClassNames='!h-[250px] mt-6'
            data={volumeChartData}
            tooltipFormatter={labelFormatter}
            yAxisProps={{ tickFormatter: yAxisFormatter }}
            bars={bars}
            xAxisProps={{
              dataKey: 'month',
              tickFormatter: transformDateToShortMonth
            }}
          />
        </>
      ) : (
        <NoData page={t('optimizationScreen.pageNames.storage')} />
      )}
    </div>
  )
}

export default InAndOutboundChart
