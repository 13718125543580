import { useState, useMemo } from 'react'
import SiloDetails from './SiloDetails/SiloDetails'
import AllSilos from './AllSilos'
import VolumeAndUtilization from './VolumeAndUtilization'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import SectionBox from 'components/Container/SectionBox'
import { useLocalization } from 'providers/LocalizationProvider'
import OutputScreen from 'components/Container/OutputScreen'

export default function Storage() {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { countryCode, t } = useLocalization()
  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/storage`,
    { bestEstimateId: comparisonID }
  ])
  const [selectedState, setSelectedState] = useState(undefined)
  const [selectedSilo, setSelectedSilo] = useState(undefined)

  const availableSilosForSelection = useMemo(() => {
    const selectedStateData =
      countryCode === 'BR'
        ? data.stateVolumes.find((item) => item.state === selectedState)
        : data

    const availableSilos = selectedStateData
      ? selectedStateData.siloVolumes.reduce(
          (acc, cur) => ({ ...acc, [cur.asset]: cur.asset_name }),
          {}
        )
      : {}

    return Object.entries(availableSilos).map(([asset, asset_name]) => ({
      label: asset_name,
      value: asset
    }))
  }, [data, selectedState, countryCode])

  return (
    <>
      {selectedSilo ? (
        <SiloDetails
          selectedState={selectedState}
          selectedSilo={selectedSilo}
          setSelectedState={setSelectedSilo}
          setSelectedSilo={setSelectedSilo}
          siloSelectionOptions={availableSilosForSelection}
        />
      ) : (
        <OutputScreen
          title={t('optimizationScreen.pageNames.storage')}
          information
        >
          <SectionBox>
            <AllSilos data={data.allVolumes} />
            <VolumeAndUtilization
              data={countryCode === 'BR' ? data.stateVolumes : data.siloVolumes}
              setSelectedSilo={setSelectedSilo}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
            />
          </SectionBox>
        </OutputScreen>
      )}
    </>
  )
}
