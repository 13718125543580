import { useMemo } from 'react'
import GridTableImpl from './GridTable'
import { TableContextProvider } from './internal/TableContext'

export * from './mappers'

const GridTable = (props) => {
  const rounding = useMemo(() => {
    return {
      roundTop: props.roundCorners === 'all' || props.roundCorners === 'top',
      roundBottom:
        props.roundCorners === 'all' || props.roundCorners === 'bottom'
    }
  }, [props.roundCorners])

  const extendedProps = {
    ...props,
    expandArrowPosition: props.expandArrowPosition || 'right',
    rounding
  }

  return (
    <TableContextProvider extendedProps={extendedProps}>
      <GridTableImpl {...extendedProps} />
    </TableContextProvider>
  )
}

export default GridTable
