import { useCallback, useMemo, useRef, useState } from 'react'
import { useDataSet } from '../useDataSet'
import {
  Data,
  EdgeMock,
  FilterType,
  FilterTypeString,
  GraphClickEvent,
  GraphFilter,
  GraphStabilizeEvent,
  Loading,
  NodeMock,
  NodeDataType,
  GraphHoverEvent,
  FilterOptions
} from '../../types'
import NetworkGraph, { GraphEvents } from 'react-graph-vis-ts'
import { useOptions } from '../useOptions'
import { useGraphStyleBehavior } from '../../utils/useGraphStyleBehavior'
import { checkEdge, getNodesIdsByEdges } from '../../utils'
import { useFiltersOptions } from './useFiltersOptions'
import { useNetwork } from './useNetwork'
import { Font } from 'vis'

export const useVisJs = () => {
  const { dataSet, periods } = useDataSet()
  const { options } = useOptions()
  const { nodeStyles, edgeStyles } = useGraphStyleBehavior()
  const { filterBy } = useFiltersOptions()
  const {
    network,
    nodes,
    edges,
    setNetwork,
    setNodes,
    setEdges,
    onGetNetwork,
    onGetNodes,
    onGetEdges
  } = useNetwork()

  const [selectedNode, setSelectedNode] = useState<NodeMock>()
  const [selectedEdge, setSelectedEdge] = useState<EdgeMock>()

  const [nodePreview, setNodePreview] = useState<NodeMock>()
  const [edgePreview, setEdgePreview] = useState<EdgeMock>()

  const [period, setPeriod] = useState<number>(0)
  const [product, setProduct] = useState<string>('')
  const [corridors, setCorridors] = useState<string[]>([])
  const [heatViewPerProperty, setHeatViewPerProperty] = useState<string>('')

  const [loading, setLoading] = useState<Loading>({
    percentage: 0,
    isDone: true
  })

  const visJsRef = useRef<NetworkGraph>(null)

  const resetState: { [filter in FilterType]: () => void } = useMemo(
    () => ({
      period: () => setPeriod(0),
      product: () => setProduct(''),
      heatViewPerProperty: () => setHeatViewPerProperty(''),
      corridors: () => setCorridors([])
    }),
    []
  )

  const periodOptions = useMemo(() => {
    return filterBy[FilterOptions.PeriodOptions](periods)
  }, [filterBy, periods])

  const productOptions = useMemo(() => {
    return filterBy[FilterOptions.ProductOption](edges)
  }, [edges, filterBy])

  const heatMapOptions = useMemo(() => {
    return filterBy[FilterOptions.HeatMapOptions]()
  }, [filterBy])

  const corridorsOptions = useMemo(() => {
    return filterBy[FilterOptions.CorridorsOptions](nodes, corridors)
  }, [corridors, filterBy, nodes])

  const selectNode = useCallback(
    (nodesIds: number[]) => {
      if (network) {
        network.selectNodes(nodesIds)
        const allSelectedNodes = nodes.filter((node) =>
          nodesIds.includes(node.id)
        )
        const selectedNode = allSelectedNodes.find(
          (node) => nodesIds[0] === node.id
        ) as NodeMock
        setNetwork(network)
        setSelectedNode(selectedNode)
        setNodePreview(selectedNode)
      }
    },
    [network, nodes, setNetwork]
  )

  const deselectNode = useCallback(() => {
    if (network) {
      network.selectNodes([])
    }
    setNetwork(network)
    setSelectedNode(undefined)
    setNodePreview(undefined)
  }, [network, setNetwork])

  const selectEdge = useCallback(
    (edgesIds: string[]) => {
      if (network) {
        network.selectEdges(edgesIds)
        const allSelectedEdges = edges.filter((edge) =>
          edgesIds.includes(edge.id as string)
        )

        const selectedEdge = allSelectedEdges.find(
          (edge) => edgesIds[0] === (edge.id as string)
        ) as EdgeMock

        setNetwork(network)
        setSelectedEdge(selectedEdge)
        setEdgePreview(selectedEdge)
      }
    },
    [edges, network, setNetwork]
  )

  const deselectEdge = useCallback(() => {
    if (network) {
      network.selectEdges([])
    }
    setSelectedEdge(undefined)
    setEdgePreview(undefined)
  }, [network])

  const hoverNode = useCallback(
    (nodeId: number) => {
      const previewNode = nodes.find((node) => node.id === nodeId) as NodeMock
      setNodePreview(previewNode)
    },
    [nodes]
  )

  const blurNode = useCallback(() => {
    if (selectedNode) {
      setNodePreview(selectedNode)
    } else {
      setNodePreview(undefined)
    }
  }, [selectedNode])

  const hoverEdge = useCallback(
    (edgeId: string) => {
      const previewEdge = edges.find((edge) => edge.id === edgeId) as EdgeMock
      setEdgePreview(previewEdge)
    },
    [edges]
  )

  const blurEdge = useCallback(() => {
    if (selectedEdge) {
      setEdgePreview(selectedEdge)
    } else {
      setEdgePreview(undefined)
    }
  }, [selectedEdge])

  const updateNetworkData = useCallback(
    (data: Data<NodeMock[], EdgeMock[]>, lockView?: boolean) => {
      if (network) {
        setNodes([...data.nodes])
        setEdges([...data.edges])
        if (lockView) {
          const currentScale = network.getScale()
          const currentPosition = network.getViewPosition()
          network.setData({
            nodes: [...data.nodes],
            edges: [...data.edges]
          })
          network.moveTo({ scale: currentScale, position: currentPosition })
        } else {
          network.setData({
            nodes: [...data.nodes],
            edges: [...data.edges]
          })
        }
      }
    },
    [network, setEdges, setNodes]
  )

  const highlightNodes = useCallback(
    (nodesIds: number[]) => {
      return nodes.map((node) => {
        if (nodesIds.includes(node.id)) {
          return {
            ...node,
            ...nodeStyles.onSelect
          }
        }
        if (!nodesIds.length) {
          return {
            ...node,
            ...nodeStyles.default(node.level)
          }
        }
        return {
          ...node,
          ...nodeStyles.onFade
        }
      })
    },
    [nodeStyles, nodes]
  )

  const highlightEdges = useCallback(
    (edgesIds: string[]) => {
      return edges.map<EdgeMock>((edge) => {
        if (edgesIds.includes(edge.id as string)) {
          return {
            ...edge,
            ...edgeStyles.onSelect,
            color: {
              ...edge.color,
              ...edgeStyles.onSelect.color
            }
          }
        }
        if (!edgesIds.length) {
          return {
            ...edge,
            ...edgeStyles.default(edge.title),
            color: {
              ...edge.color,
              ...edgeStyles.default(edge.title).color
            }
          }
        }
        return {
          ...edge,
          ...edgeStyles.onFade,
          color: {
            ...edge.color,
            ...edgeStyles.onFade.color
          }
        }
      })
    },
    [edgeStyles, edges]
  )

  const onStabilizationProgress = (params: GraphStabilizeEvent) => {
    setLoading({
      percentage: Math.floor((params.iterations / params.total) * 100),
      isDone: false
    })
  }

  const onStabilized = () => {
    setLoading({
      percentage: 100,
      isDone: true
    })
  }

  const getChildrenEdges = useCallback(
    (nodeId: number): EdgeMock[] => {
      const filteredEdge = edges.filter((item) => item.from === nodeId)
      const uniqueNodesIds = new Set<number>()
      const childrenNode = filteredEdge.reduce<number[]>((acc, item) => {
        if (!uniqueNodesIds.has(item.to)) {
          acc.push(item.to)
          uniqueNodesIds.add(item.to)
        }
        return acc
      }, [])
      const childrenEdges = childrenNode.map((childNodeId) =>
        getChildrenEdges(childNodeId)
      )

      return [...filteredEdge, ...childrenEdges.flat()]
    },
    [edges]
  )

  const getParentEdges = useCallback(
    (nodeId: number): EdgeMock[] => {
      const filteredEdge = edges.filter((item) => item.to === nodeId)
      const uniqueNodesIds = new Set()
      const parentNode = filteredEdge.reduce<number[]>((acc, item) => {
        if (!uniqueNodesIds.has(item.from)) {
          acc.push(item.from)
          uniqueNodesIds.add(item.from)
        }
        return acc
      }, [])

      const parentEdges = parentNode.map((parentNodeId) =>
        getParentEdges(parentNodeId)
      )
      return [...filteredEdge, ...parentEdges.flat()]
    },
    [edges]
  )

  const onSelectNode = useCallback(
    (event: GraphClickEvent) => {
      if (event.nodes.length) {
        deselectEdge()
        selectNode(event.nodes)
        resetState.product()
        resetState.corridors()
        const childrenEdges = getChildrenEdges(event.nodes[0])
        const parentEdges = getParentEdges(event.nodes[0])

        const nodesSet = new Set<number>()

        childrenEdges.forEach((edge) => {
          nodesSet.add(edge.to)
        })
        parentEdges.forEach((edge) => {
          nodesSet.add(edge.from)
        })

        const nodesIds = [...Array.from(nodesSet), event.nodes[0]]
        const edgesIds = [
          ...parentEdges.map((edge) => edge.id as string),
          ...childrenEdges.map((edge) => edge.id as string)
        ]

        updateNetworkData(
          {
            nodes: [...highlightNodes(nodesIds)],
            edges: [...highlightEdges(edgesIds)]
          },
          true
        )
      }
    },
    [
      deselectEdge,
      getChildrenEdges,
      getParentEdges,
      highlightEdges,
      highlightNodes,
      resetState,
      selectNode,
      updateNetworkData
    ]
  )

  const onDeselectNode = useCallback(
    (event: GraphClickEvent) => {
      if (event.nodes.length) {
        onSelectNode(event)
      } else if (network) {
        deselectNode()
        const defaultNodes = nodes.map<NodeMock>((node) => ({
          ...node,
          font: {
            ...(nodeStyles.default(node.level).font as Font)
          },
          opacity: nodeStyles.default(node.level).opacity
        }))
        const defaultEdges = edges.map<EdgeMock>((edge) => ({
          ...edge,
          ...edgeStyles.default(edge.title),
          color: {
            ...edge.color,
            opacity: edgeStyles.default(edge.title).color?.opacity
          }
        }))
        updateNetworkData(
          { nodes: [...defaultNodes], edges: [...defaultEdges] },
          true
        )
      }
    },
    [
      network,
      onSelectNode,
      nodes,
      edges,
      updateNetworkData,
      deselectNode,
      nodeStyles,
      edgeStyles
    ]
  )

  const onSelectEdge = useCallback(
    (event: GraphClickEvent) => {
      selectEdge(event.edges)
    },
    [selectEdge]
  )

  const onDeselectEdge = useCallback(() => {
    deselectEdge()
  }, [deselectEdge])

  const onHoverNode = useCallback(
    (event: GraphHoverEvent) => {
      if (event.node) {
        hoverNode(event.node)
      }
    },
    [hoverNode]
  )

  const onBlurNode = useCallback(() => {
    blurNode()
  }, [blurNode])

  const onHoverEdge = useCallback(
    (event: GraphHoverEvent) => {
      if (event.edge) {
        hoverEdge(event.edge)
      }
    },
    [hoverEdge]
  )

  const onBlurEdge = useCallback(() => {
    blurEdge()
  }, [blurEdge])

  const filterByPeriod = useCallback(
    (selectedPeriod: number) => {
      setPeriod(selectedPeriod)
      resetState.product()
      resetState.heatViewPerProperty()
      resetState.corridors()
      setNodePreview(undefined)
      setEdgePreview(undefined)
      if (network) {
        if (!selectedPeriod) {
          const defaultNodes = dataSet.nodes.map<NodeMock>((node) => ({
            ...node,
            ...nodeStyles.default(node.level)
          }))
          const defaultEdges = dataSet.edges.map<EdgeMock>((edge) => ({
            ...edge,
            ...edgeStyles.default(edge.title)
          }))
          updateNetworkData({
            nodes: defaultNodes,
            edges: defaultEdges
          })
        } else {
          const filteredNodes = dataSet.nodes.filter(
            (node) => node.data.TimePeriod === selectedPeriod
          )
          const filteredEdges = dataSet.edges.filter((edge) =>
            checkEdge(filteredNodes, edge)
          )

          const styledNodes = filteredNodes.map<NodeMock>((node) => ({
            ...node,
            ...nodeStyles.default(node.level)
          }))
          const styledEdges = filteredEdges.map<EdgeMock>((edge) => ({
            ...edge,
            ...edgeStyles.default(edge.title)
          }))

          updateNetworkData({
            nodes: styledNodes,
            edges: styledEdges
          })
        }
      }
    },
    [
      dataSet.edges,
      dataSet.nodes,
      edgeStyles,
      network,
      nodeStyles,
      resetState,
      updateNetworkData
    ]
  )

  const filterByProduct = useCallback(
    (selectedProduct: string) => {
      setProduct(selectedProduct)
      resetState.corridors()
      if (network) {
        if (!selectedProduct) {
          const styledNodes = nodes.map<NodeMock>((node) => {
            return {
              ...node,
              opacity: nodeStyles.default(node.level).opacity
            }
          })
          const styledEdges = edges.map<EdgeMock>((edge) => {
            return {
              ...edge,
              color: {
                ...edgeStyles.default(edge.title).color
              }
            }
          })
          updateNetworkData(
            {
              nodes: styledNodes,
              edges: styledEdges
            },
            true
          )
        } else {
          const edgesByProduct = edges.filter(
            (edge) => edge.title === selectedProduct
          )

          const filteredEdges = edgesByProduct.map((edge) => edge.id as string)
          const filteredNodes = getNodesIdsByEdges(edgesByProduct)

          updateNetworkData(
            {
              nodes: highlightNodes(filteredNodes),
              edges: highlightEdges(filteredEdges)
            },
            true
          )
        }
      }
    },
    [
      edgeStyles,
      edges,
      highlightEdges,
      highlightNodes,
      network,
      nodeStyles,
      nodes,
      resetState,
      updateNetworkData
    ]
  )

  const applyHeatMap = useCallback(
    (selectedHeatMap: NodeDataType) => {
      setHeatViewPerProperty(selectedHeatMap)
      if (network) {
        if (!selectedHeatMap) {
          const defaultNodes = nodes.map<NodeMock>((node) => ({
            ...node,
            color: nodeStyles.default(node.level).color
          }))
          const defaultEdges = edges.map<EdgeMock>((edge) => ({
            ...edge
          }))
          updateNetworkData(
            {
              nodes: defaultNodes,
              edges: defaultEdges
            },
            true
          )
        } else {
          const styledNodes = nodes.map<NodeMock>((node) => ({
            ...node,
            color: nodeStyles.onHeatMap(
              node.data.Flow_volume,
              node.data.total_volume
            ).color
          }))

          updateNetworkData(
            {
              nodes: styledNodes,
              edges: edges
            },
            true
          )
        }
      }
    },
    [edges, network, nodeStyles, nodes, updateNetworkData]
  )

  const deleteSingleCorridor = useCallback(
    (corridorToDelete: string) => {
      const currentCorridors = [
        ...corridors.slice(0, corridors.indexOf(corridorToDelete)),
        ...corridors.slice(corridors.indexOf(corridorToDelete) + 1)
      ]
      if (!currentCorridors.length) {
        const styledNodes = nodes.map<NodeMock>((node) => {
          return {
            ...node,
            opacity: nodeStyles.default(node.level).opacity
          }
        })
        const styledEdges = edges.map<EdgeMock>((edge) => {
          return {
            ...edge,
            color: {
              ...edgeStyles.default(edge.title).color
            }
          }
        })
        updateNetworkData(
          {
            nodes: styledNodes,
            edges: styledEdges
          },
          true
        )
      } else {
        const styledNodes = nodes.map<NodeMock>((node) => {
          if (
            !currentCorridors.find(
              (corridor) => corridor === node.data.Corridor
            )
          ) {
            return {
              ...node,
              opacity: nodeStyles.onFade.opacity
            }
          }
          return node
        })
        const styledEdges = edges.map<EdgeMock>((edge) => {
          if (
            !currentCorridors.find(
              (corridor) => corridor === edge.data.Corridor
            )
          ) {
            return {
              ...edge,
              color: {
                opacity: edgeStyles.onFade.color?.opacity
              }
            }
          }
          return edge
        })
        updateNetworkData({ nodes: styledNodes, edges: styledEdges }, true)
      }
      setCorridors(currentCorridors)
    },
    [corridors, nodes, edges, updateNetworkData, nodeStyles, edgeStyles]
  )

  const deleteAllCorridors = useCallback(() => {
    const styledNodes = nodes.map<NodeMock>((node) => {
      return {
        ...node,
        opacity: nodeStyles.default(node.level).opacity
      }
    })
    const styledEdges = edges.map<EdgeMock>((edge) => {
      return {
        ...edge,
        color: {
          ...edgeStyles.default(edge.title).color
        }
      }
    })
    updateNetworkData(
      {
        nodes: styledNodes,
        edges: styledEdges
      },
      true
    )

    setCorridors([])
    updateNetworkData({ nodes: styledNodes, edges: styledEdges })
  }, [edgeStyles, edges, nodeStyles, nodes, updateNetworkData])

  const highlightByCorridors = useCallback(
    (selectedCorridor: string) => {
      if (selectedCorridor) {
        const currentCorridors = [...corridors, selectedCorridor]
        const nodesIdsByCorridor = nodes
          .filter((node) => currentCorridors.includes(node.data.Corridor))
          .map((node) => node.id)

        const edgesIdsByCorridor = edges
          .filter((edge) => currentCorridors.includes(edge.data.Corridor))
          .map((edge) => edge.id as string)

        const styledNodes = highlightNodes(nodesIdsByCorridor)
        const styledEdges = highlightEdges(edgesIdsByCorridor)

        setCorridors(currentCorridors)
        updateNetworkData({ nodes: styledNodes, edges: styledEdges }, true)
      } else {
        deleteAllCorridors()
      }
      resetState.product()
    },
    [
      resetState,
      corridors,
      nodes,
      edges,
      highlightNodes,
      highlightEdges,
      updateNetworkData,
      deleteAllCorridors
    ]
  )

  const applyFilter = useCallback(
    ({ filterType, value }: GraphFilter) => {
      switch (filterType) {
        case FilterTypeString.period:
          filterByPeriod(value as number)
          break
        case FilterTypeString.product:
          filterByProduct(value as string)
          break
        case FilterTypeString.heatViewPerProperty:
          applyHeatMap(value as NodeDataType)
          break
        case FilterTypeString.corridors:
          highlightByCorridors(value as string)
          break
        default:
          break
      }
    },
    [filterByPeriod, filterByProduct, applyHeatMap, highlightByCorridors]
  )

  const events: GraphEvents = {
    selectNode: onSelectNode,
    deselectNode: onDeselectNode,
    hoverNode: onHoverNode,
    blurNode: onBlurNode,
    hoverEdge: onHoverEdge,
    blurEdge: onBlurEdge,
    selectEdge: onSelectEdge,
    deselectEdge: onDeselectEdge,
    stabilizationProgress: onStabilizationProgress,
    stabilized: onStabilized
  }

  return {
    graphProps: {
      dataSet,
      options,
      events,
      visJsRef,
      onGetNetwork,
      onGetNodes,
      onGetEdges
    },
    filters: {
      period,
      product,
      corridors,
      heatViewPerProperty,
      deleteSingleCorridor,
      applyFilter
    },
    filterOptions: {
      periodOptions,
      productOptions,
      heatMapOptions,
      corridorsOptions
    },
    nodePreview,
    edgePreview,
    corridors,
    loading
  }
}
