import classNames from 'classnames'
import VariationVolumeCardSkeleton from './Skeletons/VariationVolumeCardSkeleton'
/**
   * @param {string} title The title of card
   * @param {string} BM_value BM value
   * @param {string} BE_value Best Estimate value
   * @param {string} percentage Percentage value
   * @param {?string} direction up || down
   * @param {boolean} loading Loads the loading buffer div

   * @returns {JSX.Element}
   * @example
   <VariationVolumeCard
    title: 'Rio Grande',
    percentage: '9',
    values: {
      BM: 111,
      BE: 111
    },
    direction: 'up'
              />
   */
const VariationVolumeCard = ({
  loading,
  title,
  BM_value,
  BE_value,
  percentage,
  direction,
  className,
}) => {
  return loading ? (
    <VariationVolumeCardSkeleton />
  ) : (
    <div
      className={classNames(
        'border border-gray-300 rounded-lg bg-white w-full px-5 py-3',
        className
      )}
    >
      <div className='flex flex-row'>
        <h4 className='text-base font-bold flex-1'>{title}</h4>
        <div className='font-bold text-base w-min'>
          <span
            className={classNames(
              'rounded text-xs px-2 py-1 text-grey-700 bg-grey-100',
              {
                'text-red-900 bg-red-100': direction === 'down',
                'text-green-900 bg-success-100': direction === 'up'
              }
            )}
          >
            {direction === 'up' && <span className='pr-1'>+</span>}
            {`${percentage}%`}
          </span>
        </div>
      </div>
      <div className='flex flex-col gap-2 mt-4'>
        <div className='flex flex-row'>
          <h5 className='text-sm flex-1'>BM</h5>
          <span className='font-bold'>{(BM_value || 0).toLocaleString('en-US')} tons</span>
        </div>
        <div className='flex flex-row text-grey-500'>
          <h5 className='text-sm flex-1'>CF</h5>
          <span className='font-bold'>{(BE_value || 0).toLocaleString('en-US')} tons</span>
        </div>
      </div>
    </div>
  )
}

export default VariationVolumeCard
