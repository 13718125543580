import { useState } from 'react'
import classNames from 'classnames'
import Tooltip from 'components/Tooltip'

const TabItem = ({
  selectedTab,
  setSelectedTab,
  controlledValue,
  onChange,
  value,
  label,
  component,
  disabled,
  tooltip
}) => {
  const selected = controlledValue
    ? controlledValue === value
    : selectedTab === value

  const [tooltipPos, setTooltipPos] = useState(undefined)
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <li>
      <div
        onMouseEnter={(e) => {
          setTooltipPos(e)
          setShowTooltip(true)
        }}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <button
          className={classNames(
            'h-10 pb-3 transition-all ease-in-out duration-150 border-b-2 flex items-center w-max',
            disabled && 'opacity-30 cursor-auto',
            selected
              ? 'border-blue-500 text-blue-400 font-bold cursor-default'
              : 'border-grey-400  border-transparent cursor-pointer'
          )}
          disabled={disabled}
          onClick={() => {
            if (!selected) {
              onChange ? onChange(value) : setSelectedTab(value)
            }
          }}
        >
          {component ? component : label}
        </button>
      </div>
      <div>
        {tooltip && (
          <Tooltip
            show={showTooltip}
            light
            className='fixed z-10 max-w-full w-auto mt-2'
            style={
              tooltipPos
                ? {
                    top: `${tooltipPos.clientY}px`,
                    left: `${tooltipPos.clientX}px`
                  }
                : undefined
            }
          >
            {tooltip}
          </Tooltip>
        )}
      </div>
    </li>
  )
}

const TabsNav = ({ value: controlledValue, onChange, tabs }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)

  return (
    <ul className='w-full flex border-b space-x-4 mt-5 text-sm'>
      {tabs.map((props) => (
        <TabItem
          {...props}
          key={props.value}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          controlledValue={controlledValue}
          onChange={onChange}
        />
      ))}
    </ul>
  )
}

export default TabsNav
