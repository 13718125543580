import { useCallback, useMemo, useState } from 'react'
import { SingleVolumeCard } from 'components/Cards'
import classNames from 'classnames'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import BarChart from 'components/Graphs/BarChart'
import { Tab, TabsComponent } from 'components/Navigation/Tabs/Tabs'
import { formatNumber, sumByProperty } from 'utils/number'
import { kgToTons, transformDateToShortMonth } from 'screens/Optimization/utils'
import { getMonthsForPeriod } from 'screens/Optimization/utils/output'
import { useLocalization } from 'providers/LocalizationProvider'

const addMissingMonths = (data, monthList) => {
  return monthList.map((month) => {
    const volume =
      data.find((item) => item.month === month.month)?.volume || 'N/A'
    const utilizationValue = data.find(
      (item) => item.month === month.month
    )?.utilization
    const utilization = isNaN(utilizationValue)
      ? 'N/A'
      : Math.round(utilizationValue)
    return {
      ...month,
      volume,
      utilization
    }
  })
}

const filterDataByModelAndProducts = (data, modelId, products) => {
  return data.map((stateVolume) => ({
    ...stateVolume,
    siloVolumes: stateVolume.siloVolumes.filter((siloVolume) =>
      products.length > 0
        ? products.includes(siloVolume.product_ui_type) &&
          siloVolume.execution_id === modelId
        : siloVolume.execution_id === modelId
    )
  }))
}

const tooltipFormatter = (label) => {
  return typeof label === 'string'
    ? label
    : formatNumber(label, { maximumFractionDigits: 0 })
}

export default function VolumeAndUtilization({
  data,
  setSelectedSilo,
  selectedState,
  setSelectedState
}) {
  const { t } = useLocalization()
  const { id } = useParams()
  const {
    executionData: { start_date, end_date },
    comparisonID
  } = useOptimizationContext()
  const monthArray = getMonthsForPeriod(start_date, end_date).map((month) => ({
    month,
    volume: 'N/A'
  }))
  const tabs = [
    { value: 'volume', label: t('optimizationScreen.volume') },
    { value: 'utilization', label: t('optimizationScreen.utilization') }
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const { selectedProducts, selectedProductTypes } = useOptimizationContext()

  const capacityKey = useMemo(() => {
    return selectedProductTypes.length === 0 || selectedProductTypes.length > 1
      ? 'asset_capacity'
      : selectedProducts.length === 1
      ? 'storage_capacity'
      : 'product_type_capacity'
  }, [selectedProductTypes, selectedProducts])

  const getChartDataForSilos = useCallback(
    (currentState, baseData, compareData) => {
      const silos = baseData.find(
        (state) => state.state === currentState
      ).siloVolumes
      const compareSilos = compareData.find(
        (state) => state.state === currentState
      ).siloVolumes

      const groupBySilo = silos.reduce(
        (
          acc,
          {
            month,
            volume,
            asset_capacity,
            product_type_capacity,
            storage_capacity,
            asset_name,
            asset,
            third_party
          }
        ) => {
          const data = {
            month,
            volume,
            utilization: (volume / storage_capacity) * 100
          }
          const silo = acc.find((silo) => silo.name === asset_name)

          if (silo) {
            const monthData = silo.chartData.find(
              (siloData) => siloData.month === data.month
            )
            monthData
              ? (monthData.volume += data.volume)
              : silo.chartData.push({
                  ...data,
                  utilization: (volume / storage_capacity) * 100
                })

            if (monthData) {
              monthData.utilization =
                (monthData.volume / storage_capacity) * 100
            }
          } else {
            acc.push({
              name: asset_name,
              asset,
              chartData: [data],
              third_party,
              storage_capacity,
              asset_capacity,
              product_type_capacity
            })
          }

          return acc
        },
        []
      )

      groupBySilo.forEach((grouped) => {
        grouped.chartData = addMissingMonths(grouped.chartData, monthArray)
        grouped.allUtilization =
          sumByProperty(grouped.chartData, 'volume') /
          (grouped[capacityKey] * monthArray.length)
        grouped.near_capacity_limit = grouped.allUtilization > 0.9
        grouped.allVolume = sumByProperty(grouped.chartData, 'volume')
        grouped.compareVolume = sumByProperty(
          compareSilos.filter((silo) => silo.asset_name === grouped.name),
          'volume'
        )
      })

      return groupBySilo
    },
    [monthArray, capacityKey]
  )

  const getChartDataForStates = useCallback(
    (baseData, compareData) => {
      return baseData.map((stateVolume) => {
        const siloVolumes = stateVolume.siloVolumes.map((siloVolume) => ({
          month: siloVolume.month,
          volume: siloVolume.volume
        }))
        const chartData = siloVolumes.reduce((curr, { month, volume }) => {
          const monthVolume = curr.find(
            (monthVolume) => monthVolume.month === month
          )
          if (monthVolume) {
            monthVolume.volume += volume
          } else {
            curr.push({ month, volume })
          }

          return curr
        }, [])

        const compareSiloVolumes = compareData.find(
          (compare) => compare.state === stateVolume.state
        ).siloVolumes

        return {
          name: stateVolume.state,
          chartData: addMissingMonths(chartData, monthArray),
          allVolume: sumByProperty(chartData, 'volume'),
          compareVolume: sumByProperty(compareSiloVolumes, 'volume')
        }
      })
    },
    [monthArray]
  )

  const getChartData = useMemo(() => {
    const baseModelData = filterDataByModelAndProducts(
      data,
      id,
      selectedProducts
    )
    const compareModelData = filterDataByModelAndProducts(
      data,
      comparisonID,
      selectedProducts
    )

    if (selectedState) {
      return {
        data: getChartDataForSilos(
          selectedState,
          baseModelData,
          compareModelData
        )
      }
    }

    return { data: getChartDataForStates(baseModelData, compareModelData) }
  }, [
    data,
    selectedState,
    selectedProducts,
    comparisonID,
    getChartDataForSilos,
    getChartDataForStates,
    id
  ])

  return (
    <div className='mt-20'>
      <div
        className={classNames('flex items-center w-fit gap-2', {
          'cursor-pointer': selectedState
        })}
        onClick={() => {
          if (selectedState) {
            setSelectedState(undefined)
            setSelectedTab(tabs[0].value)
          }
        }}
      >
        {selectedState && <ChevronLeftIcon className='w-5 h-5' />}
        <h3 className='text-xl font-bold'>
          {selectedState
            ? `${selectedState} ${t(
                'optimizationScreen.storage.volumeAndUtilization.silos'
              )}`
            : t('optimizationScreen.storage.volumeAndUtilization.allStates')}
        </h3>
      </div>

      <p className='text-grey-700 mb-6'>
        {selectedState &&
          t('optimizationScreen.storage.volumeAndUtilization.bySilo')}
        {!selectedState &&
          t('optimizationScreen.storage.volumeAndUtilization.byState')}
      </p>
      <TabsComponent value={selectedTab} onChange={setSelectedTab}>
        {tabs
          .filter((_, index) => index !== 1 || selectedState)
          .map((tab) => {
            return (
              <Tab value={tab.value} label={tab.label} key={tab.value}>
                <div className='grid grid-cols-3 gap-3'>
                  {getChartData.data.map((data) => {
                    return (
                      <SingleVolumeCard
                        key={data.name}
                        value={
                          tab.value === 'utilization'
                            ? `${Math.round(data.allUtilization * 100)}%`
                            : formatNumber(data.allVolume / kgToTons, {
                                maximumFractionDigits: 0
                              })
                        }
                        percentage={
                          +(
                            (data.compareVolume / data.allVolume) *
                            100
                          ).toFixed(0) - 100
                        }
                        title={data.name}
                        thirdParty={data.third_party === 1}
                        warning={
                          data.near_capacity_limit && 'Near capacity limit'
                        }
                        onClick={() => {
                          selectedState
                            ? setSelectedSilo(data.asset)
                            : setSelectedState(data.name)
                        }}
                      >
                        <BarChart
                          data={data.chartData}
                          variant='small'
                          bars={[{ dataKey: tab.value }]}
                          tooltipFormatter={
                            tab.value === 'utilization'
                              ? (v) => `${v}%`
                              : tooltipFormatter
                          }
                          yAxisProps={{
                            domain:
                              tab.value === 'utilization'
                                ? [0, () => 100]
                                : [0, data[capacityKey]],
                            tickFormatter: (v) =>
                              tab.value === 'utilization'
                                ? `${v}%`
                                : `${(v / kgToTons).toFixed(0)} kTons`
                          }}
                          xAxisProps={{
                            dataKey: 'month',
                            tickFormatter: (v) =>
                              transformDateToShortMonth(v)[0]
                          }}
                        />
                      </SingleVolumeCard>
                    )
                  })}
                </div>
              </Tab>
            )
          })}
      </TabsComponent>
    </div>
  )
}
