import { useMemo } from 'react'
import { useLocalization } from 'providers/LocalizationProvider'
import ErrorBoundary from 'components/ErrorBoundary'
import classNames from 'classnames'

import {
  AdjustmentsVerticalIcon
} from '@heroicons/react/24/outline'

const ICON_STYLE = 'w-8 h-8 text-blue-400 bg-blue-100 rounded-md p-1 mr-4'

const SideBar = () => {
  const { t } = useLocalization()

  const MENU_ITEMS = useMemo(
    () => [
      {
        icon: <AdjustmentsVerticalIcon className={ICON_STYLE} />,
        text: t('newExecutionScreen.sideBar.menuItems.settings')
      }
    ],
    [t]
  )

  return (
    <ErrorBoundary fallback={<div>Error on NewOpt/Sidebar</div>}>
      <h1 className='text-2xl font-bold mt-4'>
        {t('newExecutionScreen.sideBar.screen')}
      </h1>
      <h3 className='font-bold text-lg mt-8'>
        {t('newExecutionScreen.sideBar.title')}
      </h3>
      <p className='font-medium text-sm text-grey-400'>
        {t('newExecutionScreen.sideBar.description')}
      </p>

      <ul className='my-8 space-y-2'>
        {MENU_ITEMS.map(({ icon, text }, index) => (
          <li key={index} className='flex items-center font-bold'>
            <button
              onClick={(e) => {
                e.preventDefault()
              }}
              className={classNames(
                'bg-white w-full text-left flex items-center p-4 rounded-lg border border-blue-400'
              )}
            >
              {icon}
              <span>{`${index + 1}. ${text}`}</span>
            </button>
          </li>
        ))}
      </ul>
    </ErrorBoundary>
  )
}

export default SideBar

