import { useLocalization } from 'providers/LocalizationProvider'

export const OwnershipTable = ({
  data,
  headers,
  sideHeaders,
  unit,
  hasTotal,
  totalDataKey
}) => {
  const { t } = useLocalization()
  return (
    <table className='w-full'>
      <thead>
        <tr>
          {sideHeaders && (
            <th className=' border-r-4 border-white border-solid'></th>
          )}
          {headers &&
            headers.map((element) => (
              <th
                className='bg-blue-300 text-center capitalize border-4 border-white border-solid py-2 px-4'
                key={element}
              >
                {element}
              </th>
            ))}
          <th className='bg-[#C4C4C4] text-center py-2 px-6 border-4 border-white border-solid'>
            {t('optimizationScreen.ownershipTableTotalCol')}
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((element, index) => (
            <tr key={`${index}`} className={'h-8 text-sm'}>
              <th className='bg-grey-100 text-grey-500 text-left border-r-4 border-white border-solid h-12 p-2 '>
                {t(sideHeaders[index])}
              </th>
              {element &&
                Object.values(element).map(
                  (value, index, array) =>
                    array.length - 1 !== index && (
                      <td
                        key={`${value}-${index}`}
                        className={
                          'bg-blue-100 text-blue-700 text-center  border-4 border-white border-solid'
                        }
                      >
                        {new Intl.NumberFormat('es-AR', {
                          maximumFractionDigits: 0
                        }).format(value)}{' '}
                        {unit && (
                          <span className=' text-xs text-gray-400'>{unit}</span>
                        )}
                      </td>
                    )
                )}
              {hasTotal && (
                <td
                  className={
                    'bg-[#E4E8ED] text-blue-700 text-center border-4 border-white border-solid'
                  }
                >
                  {new Intl.NumberFormat('es-AR', {
                    maximumFractionDigits: 0
                  }).format(Math.round(element[totalDataKey] / 1000))}{' '}
                  {unit && (
                    <span className=' text-xs text-gray-500'>{unit}</span>
                  )}
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  )
}
