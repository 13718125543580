import dayjs from 'dayjs'
import { useContext, useReducer, useMemo, createContext } from 'react'

export const initialState = {
  searchTerm: '',
  selectedAuthor: '',
  filtersOpen: false,
  selectedStatus: undefined,
  dateRange: {
    startDate: undefined,
    endDate: undefined
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload
      }
    case 'SET_SELECTED_AUTHOR':
      return {
        ...state,
        selectedAuthor: action.payload
      }

    case 'TOGGLE_FILTERS':
      return {
        ...state,
        filtersOpen: !state.filtersOpen
      }
    case 'SET_SELECTED_STATUS':
      return {
        ...state,
        selectedStatus: action.payload
      }

    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: {
          startDate: dayjs(action.payload.startDate),
          endDate: dayjs(action.payload.endDate)
        }
      }

    case 'RESET_FILTERS':
      return initialState

    default:
      return state
  }
}

const OptTableContext = createContext()
OptTableContext.displayName = 'OptTableContext'

export const OptTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const setSearchTerm = (searchTerm) => {
    dispatch({ type: 'SET_SEARCH_TERM', payload: searchTerm })
  }

  const setSelectedAuthor = (selectedAuthor) => {
    dispatch({ type: 'SET_SELECTED_AUTHOR', payload: selectedAuthor })
  }

  const toggleFilters = () => {
    dispatch({ type: 'TOGGLE_FILTERS' })
  }

  const setDateRange = (dateRange) => {
    dispatch({ type: 'SET_DATE_RANGE', payload: dateRange })
  }

  const setSelectedStatus = (selectedStatus) => {
    dispatch({ type: 'SET_SELECTED_STATUS', payload: selectedStatus })
  }

  const reset = () => {
    dispatch({ type: 'RESET_FILTERS' })
  }

  const value = useMemo(() => {
    return {
      ...state,
      setSearchTerm,
      setSelectedAuthor,
      toggleFilters,
      setSelectedStatus,
      setDateRange,
      reset
    }
  }, [state])

  return (
    <OptTableContext.Provider value={value}>
      {children}
    </OptTableContext.Provider>
  )
}

export const useOptTableContext = () => {
  const context = useContext(OptTableContext)
  if (context === undefined) {
    throw new Error('useOptTableContext must be used within a OptTableProvider')
  }
  return context
}
