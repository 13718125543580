import { useLocation } from 'react-router-dom'
import { useLocalization } from 'providers/LocalizationProvider'
import classNames from 'classnames'

const Header = () => {
  const { t } = useLocalization()
  const { state } = useLocation()

  return (
    <section className='mb-8'>
      <div>
        <h2 className={classNames('text-2xl font-bold mt-10', {})}>
          {t(`newExecutionScreen.sections.settings.title`)}
        </h2>
        <p className='text-base text-grey-400 mt-4'>
          {t(`newExecutionScreen.sections.settings.description`)}
        </p>
      </div>
      <div>
        <h2 className={classNames('text-2xl font-bold mt-10', {})}>
          {state.description}
        </h2>
        <p className='text-base text-grey-400 mt-4'>
          {t(`newExecutionScreen.sections.settings.subtitle_description`)}
        </p>
      </div>
    </section>
  )
}

export default Header

