import { useLocalization } from 'providers/LocalizationProvider'
import ModelDetailsCardSkeleton from './Skeletons/ModelDetailsCardSkeleton'
import { Trans } from 'react-i18next'
import { useMemo } from 'react'
import { formatAsMillions } from 'utils/number'

const ModelDetailsCard = ({ model = null }) => {
  const { t, countryCode } = useLocalization()
  const benefitKey = useMemo(() => {
    if (countryCode === 'ARG') {
      return 'benefitArg'
    }
    return 'benefit'
  }, [countryCode])
  return !model ? (
    <ModelDetailsCardSkeleton />
  ) : (
    <div className='bg-white border rounded-md flex flex-col px-5 py-3 col-span-2'>
      <div className='flex justify-between items-center'>
        <div>
          <h3 className='font-bold text-xl'>{model.name}</h3>
        </div>
      </div>
      <div className='flex justify-between mt-4'>
        <div className='flex flex-col border-r mt-auto pr-6'>
          <div className='text-grey-400 text-sm'>
            {t(`homeScreen.lastModelRun.detailsCard.${benefitKey}`)}
          </div>
          <div className='text-bold text-lg font-bold'>
            {formatAsMillions(model.results?.benefit)}
          </div>
        </div>
        <div className='flex flex-col mt-auto pr-6 border-r'>
          <div className='text-grey-400 text-sm'>
            {t('homeScreen.lastModelRun.detailsCard.exportedVolume')}
          </div>
          <div className='text-bold text-lg font-bold'>
            {model.results?.exported_volume?.toLocaleString('en-US', {
              maximumFractionDigits: 0
            }) ?? ''}
          </div>
        </div>

        <div className='flex flex-col text-bold mt-auto mb-1'>
          <div className='text-grey-400 text-sm'>
            <p>
              <Trans i18nKey='homeScreen.lastModelRun.detailsCard.updated'>
                Last updated:
                {{
                  date: new Date(model.updatedAt).toLocaleDateString('en-US')
                }}
                <br />
                by {{ user: model.createdBy }}
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelDetailsCard
