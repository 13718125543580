import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon
} from '@heroicons/react/24/outline'
import { useColumnSorterContext } from './ColumnSorterProvider'

export default function SorterColumnTitle({ title, sortKey }) {
  const { itemOrder, setItemOrder } = useColumnSorterContext()

  return (
    <div
      className='flex items-center cursor-pointer'
      onClick={() => {
        setItemOrder((io) => {
          if (io?.key === sortKey) {
            if (io.direction === 'desc') return undefined

            return { ...io, direction: 'desc' }
          }

          return { key: sortKey, direction: 'asc' }
        })
      }}
    >
      <span className='mr-1'>{title}</span>
      {itemOrder?.key === sortKey && itemOrder.direction === 'asc' && (
        <ArrowUpIcon className='w-4 h-4' />
      )}
      {itemOrder?.key === sortKey && itemOrder.direction === 'desc' && (
        <ArrowDownIcon className='w-4 h-4' />
      )}
      {itemOrder?.key !== sortKey && <ArrowsUpDownIcon className='w-4 h-4' />}
    </div>
  )
}
