import { SingleVolumeCard } from 'components/Cards'
import BarChart from 'components/Graphs/BarChart'
import { SwitcherButtons } from 'components/Inputs/Switchers'
import MultiColumnsBoxTable from 'components/Tables/MultiColumnsBoxTable'
import useHeatmapDataTransformer from 'hooks/useHeatmapDataTransformer'
import useProductFilter from 'hooks/useProductFilter'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  NO_DATA_LABEL,
  createMonthObject,
  kgToTons,
  transformDateToShortMonth
} from 'screens/Optimization/utils'
import { labelFormatter } from 'screens/Optimization/utils/output'
import { capitalize } from 'utils/string'
import { useLocalization } from 'providers/LocalizationProvider'

export const Products = ({ data, selectedSubProducts }) => {
  const [view, setView] = useState('chart')
  const { id: baseModelId } = useParams()
  const { t } = useLocalization()
  const {
    comparisonID,
    executionData: { start_date, end_date }
  } = useOptimizationContext()

  const { filteredData } = useProductFilter({
    data,
    selectedSubProducts
  })

  const tableData = useMemo(
    () =>
      Object.entries(
        filteredData.reduce(
          (acc, { product_name, month, crushed_volume, execution_id }) => ({
            ...acc,
            [product_name]: {
              ...(acc[product_name] ||
                createMonthObject(start_date, end_date, {
                  [baseModelId]: NO_DATA_LABEL,
                  [comparisonID]: NO_DATA_LABEL
                })),
              [month]: {
                ...(acc[product_name]?.[month] || {}),
                [execution_id]:
                  (acc[product_name]?.[month]?.[execution_id] ||
                    NO_DATA_LABEL) === NO_DATA_LABEL
                    ? crushed_volume
                    : acc[product_name][month][execution_id] + crushed_volume
              }
            }
          }),
          {}
        )
      ).map(([title, data]) => ({
        title,
        data: Object.entries(data).map(([month, volumes]) => ({
          month,
          volumes
        }))
      })),
    [baseModelId, comparisonID, start_date, end_date, filteredData]
  )

  const { heatmapData } = useHeatmapDataTransformer({
    valueKey: 'crushed_volume',
    data,
    rowLevelKeys: ['product']
  })

  return (
    <>
      <div className='flex w-full items-center'>
        <h3 className='text-xl font-bold text-grey-500 text-grey-700'>
          {t('optimizationScreen.products')}
        </h3>

        <div className='ml-auto'>
          <SwitcherButtons
            items={[
              {
                label: t('optimizationScreen.chart'),
                value: 'chart'
              },
              {
                label: t('optimizationScreen.table'),
                value: 'table'
              }
            ]}
            onChange={setView}
          />
        </div>
      </div>
      <div className='w-full'>
        {view === 'chart' ? (
          <div className='grid grid-cols-3 gap-2'>
            {tableData?.map(({ title, data }) => {
              return (
                <SingleVolumeCard key={title} title={capitalize(title)}>
                  <BarChart
                    variant='small'
                    data={data}
                    bars={[
                      {
                        dataKey: `volumes.${baseModelId}`,
                        fill: '#FAA628',
                        name: t('optimizationScreen.baseModel')
                      }
                    ]}
                    barProps={{
                      barSize: 5
                    }}
                    yAxisProps={{
                      dataKey: `volumes.${baseModelId}`,
                      width: 35,
                      tickCount: 3,
                      tickFormatter: (val) => val / kgToTons
                    }}
                    cartesianGridProps={{ horizontal: true, vertical: false }}
                    xAxisProps={{
                      dataKey: 'month',
                      strokeWidth: 0,
                      tickFormatter: (val) => transformDateToShortMonth(val)[0]
                    }}
                    tooltipFormatter={labelFormatter}
                  />
                </SingleVolumeCard>
              )
            })}
          </div>
        ) : (
          <div className='mt-4'>
            <MultiColumnsBoxTable
              data={heatmapData}
              referenceProps={{
                title: t('optimizationScreen.comparison'),
                objKey: comparisonID
              }}
              comparedProps={{
                title: t('optimizationScreen.baseModel'),
                objKey: baseModelId
              }}
              groupLabelFormatter={transformDateToShortMonth}
              columnValueFormatter={(v) => v.toFixed(0)}
              rowTitleFormatter={capitalize}
              referencePropKey={comparisonID}
            />
          </div>
        )}
      </div>
    </>
  )
}
