import { useMemo } from 'react'
import { useTableContext } from './TableContext'

const ARROW_COLUMN_WIDTH_RIGHT = '3rem'

export const useGridTemplateColumns = ({
  columnSizes,
  checkableRows,
  renderExpandedRow,
  expandArrowPosition
}) => {
  const { mappers } = useTableContext()

  return useMemo(() => {
    let columnSizeArray = []
    if (!columnSizes) {
      columnSizeArray = new Array(
        mappers.length - (checkableRows ? 1 : 0) - (renderExpandedRow ? 1 : 0)
      ).fill('auto')
    } else if (typeof columnSizes === 'string') {
      columnSizeArray = columnSizes.split(/\s+/)
    } else {
      columnSizeArray = columnSizes
    }

    if (renderExpandedRow) {
      if (expandArrowPosition === 'right') {
        columnSizeArray = [...columnSizeArray, ARROW_COLUMN_WIDTH_RIGHT]
      }
    }

    if (checkableRows) {
      columnSizeArray = ['3rem', ...columnSizeArray]
    }

    return columnSizeArray.join(' ')
  }, [
    checkableRows,
    columnSizes,
    expandArrowPosition,
    mappers.length,
    renderExpandedRow
  ])
}
