import React from 'react'
import * as Select from '@radix-ui/react-select'
import classnames from 'classnames'
import './select.css'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className='SelectItem flex items-center min-h-9 text-base text-[#3D444D] pr-9 pl-6 py-2 select-none cursor-pointer'
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  )
})

const RenderSelectItems = ({ options, SelectItemComponent }) => (
  <>
    {options.map(({ value, label, ...rest }) => (
      <SelectItemComponent value={value} key={value} {...rest}>
        {label}
      </SelectItemComponent>
    ))}
  </>
)

const RenderOptions = ({ options, SelectItemComponent = SelectItem }) => {
  if (Array.isArray(options)) {
    return (
      <RenderSelectItems
        options={options}
        SelectItemComponent={SelectItemComponent}
      />
    )
  }
  return (
    <>
      {Object.values(options).map(({ label, options }) => (
        <Select.Group key={label}>
          <Select.Label className='px-6 h-12 inline-flex items-center text-base text-[#3D444D] font-bold'>
            {label}
          </Select.Label>
          <RenderSelectItems
            options={options}
            SelectItemComponent={SelectItemComponent}
          />
        </Select.Group>
      ))}
    </>
  )
}

/**
 *
 * @example
 *  options: [{ label: 'Option', value: 'option'}]
 *  OR for GROUPED options: {
 *    group: {
 *      label: 'Group 1',
 *      options: [{ label: 'Option', value: 'option'}]
 *    }
 *  }
 * @param options: Selectable options.
 * @param variant: default | underlined
 * @param open: Controlled way of handling the state of the select component. Must be used together with the onOpenChange prop.
 * @param onOpenChange: Controlled way of handling the state of the select component. Must be used together with the open prop.
 * @param onValueChange: Callback function that will be called when the selected value changes.
 * @param defaultValue: Selected value by default.
 * @param value: Selected value. Controlled way of handling the state of the select component.
 * @param placeholder: Placeholder to show when nothing is selected.
 * @param label: Label of the component.
 * @param CustomSelectItem: JSX.Element
 * @param icon: icon to display on left JSX.Element
 * @returns JSX.Element
 */
const SelectComponent = ({
  placeholder,
  open,
  onOpenChange,
  onValueChange,
  value,
  defaultValue,
  options,
  CustomSelectItem,
  label,
  fullWidth,
  icon,
  variant = 'default',
  disabled,
  error,
  showErrors = true,
  triggerClassName,
  overlayPosition = 'popper',
  dynamicHeight = false,
  ...props
}) => {
  return (
    <div {...props}>
      {label && <label className='block text-grey-600'>{label}</label>}
      <Select.Root
        open={open}
        onOpenChange={onOpenChange}
        value={value}
        onValueChange={onValueChange}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        <Select.Trigger
          className={classnames(
            'SelectTrigger inline-flex justify-between items-center gap-2 w-auto box-border',
            {
              'border border-solid border-[#d2d5da] text-[#3D444D] bg-white py-2 px-3 shadow-sm rounded-md box-border':
                variant === 'default',
              'text-[#1E2226] underline underline-offset-8 text-lg font-bold':
                variant === 'underlined',
              'text-white': variant === 'transparent-light',
              'w-full': fullWidth,
              'cursor-pointer': !disabled,
              'opacity-50 cursor-default': disabled,
              'border-error-400 text-error-400 focus:border-error-400': error,
              'h-10': variant === 'default' && !dynamicHeight
            },
            triggerClassName
          )}
        >
          {icon && <span className='h-5 w-5'>{icon}</span>}
          <Select.Value placeholder={placeholder || 'Select an item...'} />
          {variant !== 'transparent-light' && (
            <Select.Icon>
              <ChevronDownIcon className='arrow-icon h-6 w-6 transition-transform' />
            </Select.Icon>
          )}
           {variant === 'transparent-light' && (
            <Select.Icon className='h-[14px] w-[14px]'>
              <ChevronDownIcon strokeWidth={2.5} className='arrow-icon transition-transform' />
            </Select.Icon>
          )}
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            className='SelectContent inline-block	border border-solid border-[#E4E8ED] rounded-md mt-2 bg-white'
            position={overlayPosition}
            style={{ zIndex: 999 }}
          >
            <Select.ScrollUpButton className='SelectScrollButton'>
              <ChevronUpIcon className='arrow-icon h-6 w-6 transition-transform' />
            </Select.ScrollUpButton>
            <Select.Viewport className='SelectViewport py-1'>
              <RenderOptions
                options={options}
                SelectItemComponent={CustomSelectItem}
              />
            </Select.Viewport>
            <Select.ScrollDownButton className='SelectScrollButton'>
              <ChevronDownIcon className='arrow-icon h-6 w-6 transition-transform' />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      {showErrors && error && <div className='text-error-400'>{error}</div>}
    </div>
  )
}

export default SelectComponent
