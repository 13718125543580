import useSWR from 'swr'
import { useMemo } from 'react'
import PortForm from './PortForm'
import { useParams } from 'react-router-dom'
import {
  getProductsWithPrecheckedValues,
  getTransportationModesWithPrecheckedValues
} from 'screens/Configuration/utils'
import { get } from 'utils/request'
import { useCity } from 'providers/CityProvider'

const Ports = () => {
  const { id } = useParams()
  const { getCityById } = useCity()

  const { data } = useSWR(id ? `/port/${id}` : null, get)

  const currentData = useMemo(() => {
    if (!data) {
      return
    }
    const { port_name, city_id, ownership, terminals } = data
    const { country, state } = getCityById(city_id)

    return {
      port_name,
      country,
      state,
      city_id,
      ownership,
      terminals: terminals.map((terminal) => ({
        id: terminal.id,
        terminal_name: terminal.terminal_name,
        products: getProductsWithPrecheckedValues(terminal.products),
        transportation_mode: getTransportationModesWithPrecheckedValues(
          terminal.transportation_mode
        ),
        include: terminal.include
      }))
    }
  }, [data, getCityById])

  return <PortForm id={id} currentData={currentData} />
}

export default Ports
