import { useState, useMemo } from 'react'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline'
import { formatFileSize } from 'utils/number'
import classNames from 'classnames'
import { useNewOptContext } from 'screens/NewOptimization/NewOptContext'
import GridTable from 'components/Tables/GridTable'
import Button from 'components/Inputs/Button'
import { formatter, stripDataTypePrefix } from 'utils/string'
import { GridLoader } from 'react-spinners'
import { FileSelectorModal } from 'components/Modal/FileSelectorModal'
import { useDatasetDownload } from 'hooks/useDatasetDownload'
import Select from 'components/Inputs/Select'
import ConfirmModal from 'screens/NewOptimization/Main/ConfirmModal'
import { useLocalization } from 'providers/LocalizationProvider'
import TextWithComponents from 'components/Typography/TextWithComponents'
import { ExcelIllustration } from 'components/Icons'
import * as Tooltip from '@radix-ui/react-tooltip'

export default function ReviewTable({ mappedFileList, templateDistances }) {
  const {
    templates,
    validationResultsOnFile,
    setSelectedTemplate,
    onFileLoad,
    showAllFiles,
    duplicateTemplates,
    removeFile
  } = useNewOptContext()
  const sortedTemplates = useMemo(
    () => templates.sort((a, b) => a.name.localeCompare(b.name)),
    [templates]
  )
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [fileCollisionModalOpen, setFileCollisionModalOpen] = useState(false)
  const [conflictingFile, setConflictingFile] = useState()
  const [fileNameToReplace, setFileNameToReplace] = useState()
  const downloadTemplate = useDatasetDownload()
  const { t } = useLocalization()

  const columnMappers = useMemo(
    () => [
      // Col 1
      () => <ExcelIllustration />,

      // Col 2
      {
        content: ({ item, ctx: { duplicate } }) => (
          <div className='flex flex-col w-full justify-start'>
            <span>{item.name}</span>
            <div className='flex flex-row gap-2'>
              <div className='text-gray-400'>{formatFileSize(item.size)}</div>
              {duplicate && (
                <div className='bg-red-100 text-red-600 px-2 py-0.5 rounded-sm font-bold text-sm'>
                  {t('newOptimizationScreen.batchUpload.duplicate')}
                </div>
              )}
            </div>
          </div>
        ),
        className: 'flex flex-col justify-center'
      },

      // Col 3
      () => <ArrowRightIcon className='h-5 w-5' />,

      // Col 4
      {
        content: ({ item, ctx }) => {
          const validationErrors = ctx.validationResult?.errors
          return (
            <div
              className={
                'flex justify-between ' +
                classNames(validationErrors && 'text-red-700')
              }
            >
              {item.matched ? (
                <span className='font-medium pl-3'>
                  {formatter(stripDataTypePrefix(item.type))}
                </span>
              ) : (
                <Select
                  value={item.type}
                  options={sortedTemplates.map(({ type }) => ({
                    label: formatter(stripDataTypePrefix(type)),
                    value: type
                  }))}
                  onValueChange={(type) => setSelectedTemplate(item, type)}
                  error={!!validationErrors}
                  fullWidth
                  overlayPosition='item-aligned'
                />
              )}
              {validationErrors && (
                <small className='pr-3 break-normal flex justify-around max-w-md'>
                  <div className='mr-3'>
                    <Tooltip.Provider>
                      <Tooltip.Root delayDuration={100}>
                        <Tooltip.Trigger asChild>
                          <span className='bg-red-500 text-white hover:bg-grey-200 hover:text-black rounded cursor-default p-1.5'>
                            See Errors ({validationErrors.length})
                          </span>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                          <Tooltip.Content
                            className='bg-white border rounded p-5 max-h-[200px] overflow-y-auto'
                            side={'bottom'}

                          >
                            <ul className='list-disc ml-3'>
                              {validationErrors.map((e, idx) => (
                                <li key={idx}>
                                  <small>{e}</small>
                                </li>
                              ))}
                            </ul>
                            <Tooltip.Arrow className='TooltipArrow' />
                          </Tooltip.Content>
                        </Tooltip.Portal>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </div>
                  <span
                    className='underline text-gray-500 cursor-pointer'
                    onClick={() => {
                      downloadTemplate(ctx.currentlySelectedTemplate.id)
                    }}
                  >
                    {t('newOptimizationScreen.batchUpload.downloadTemplate')}
                  </span>
                </small>
              )}
            </div>
          )
        },
        className: classNames('flex flex-col justify-center mr-2')
      },

      // Col 5
      ({ ctx }) => <StatusChip validationResult={ctx.validationResult} />,

      // Col 6
      ({ item, ctx: { duplicate } }) => (
        <div className='flex justify-end gap-2'>
          {duplicate && (
            <Button
              onClick={() => {
                removeFile(item.name)
              }}
            >
              Remove
            </Button>
          )}
          <Button
            onClick={() => {
              setFileNameToReplace(item.name)
              setUpdateModalOpen(true)
            }}
          >
            {t('newOptimizationScreen.batchUpload.update')}
          </Button>
        </div>
      )
    ],
    [sortedTemplates, downloadTemplate, setSelectedTemplate, t, removeFile]
  )

  const rowContextFactory = (file) => {
    const currentlySelectedTemplate = templates.filter(
      (template) => template.type === file.type
    )[0]

    const validationResult =
      validationResultsOnFile[
        file.name ??
          templateDistances.filter((f) => f.name === file.name)[0]?.name
      ]

    return {
      validationResult,
      currentlySelectedTemplate,
      duplicate: duplicateTemplates.includes(file.type)
    }
  }

  const gridTableData = useMemo(() => {
    const data = showAllFiles
      ? mappedFileList
      : mappedFileList.filter(
          (x) =>
            validationResultsOnFile[x.name]?.valid !== true ||
            !x.matched ||
            duplicateTemplates.includes(x.type)
        )

    return data.sort((a, b) => {
      if (a.matched && !b.matched) {
        return 1
      }

      if (!a.matched && b.matched) {
        return -1
      }

      const aDup = duplicateTemplates.includes(a.type)
      const bDup = duplicateTemplates.includes(b.type)

      if (aDup && !bDup) {
        return -1
      }

      if (!aDup && bDup) {
        return 1
      }

      return a.name.localeCompare(b.name)
    })
  }, [
    mappedFileList,
    showAllFiles,
    validationResultsOnFile,
    duplicateTemplates
  ])

  return (
    <>
      <ConfirmModal
        title={t('newOptimizationScreen.batchUpload.duplicateFileModal.title')}
        open={fileCollisionModalOpen}
        setOpen={setFileCollisionModalOpen}
        onAccept={() => {
          onFileLoad(conflictingFile, conflictingFile.name)
        }}
        onCancel={() => setUpdateModalOpen(true)}
      >
        <TextWithComponents
          text={t(
            'newOptimizationScreen.batchUpload.duplicateFileModal.message',
            {
              name: conflictingFile?.name
            }
          )}
          substitutions={[{ className: 'font-bold' }]}
        />
      </ConfirmModal>
      <FileSelectorModal
        title={`Update ${fileNameToReplace}`}
        buttonLabel={t('general.confirm')}
        open={updateModalOpen}
        onOpenChange={setUpdateModalOpen}
        onUpload={async ([file]) => {
          if (
            fileNameToReplace !== file.name &&
            mappedFileList.some((x) => x.name === file.name)
          ) {
            setConflictingFile(file)
            setFileCollisionModalOpen(true)
          } else {
            onFileLoad(file, fileNameToReplace)
          }
          setUpdateModalOpen(false)
        }}
      />
      <div className='flex flex-col w-full'>
        <GridTable
          data={gridTableData}
          keyMapper={(file) => file.name}
          columnMappers={columnMappers}
          rowContext={rowContextFactory}
          columnHeaderGroups={[
            {
              start: 1,
              span: 2,
              title: t('newOptimizationScreen.batchUpload.updatedFilesTitle')
            },
            {
              start: 4,
              span: 1,
              title: (
                <span className='ml-2.5'>
                  {t('newOptimizationScreen.batchUpload.matchedTemplatesTitle')}
                </span>
              )
            }
          ]}
          columnSizes={['3rem', 'auto', '1.5rem', 'auto', '8.5rem']}
          cellClassNames='flex flex-col p-2 justify-center'
          groupHeaderClassNames='p-2 font-bold'
        />
      </div>
    </>
  )
}

const StatusChip = ({ validationResult }) => {
  const { t } = useLocalization()
  switch (validationResult?.valid) {
    case true:
      return (
        <div className='text-green-700 bg-success-100 rounded-sm h-8 w-full'>
          <span className='flex flex-row px-2 py-1 whitespace-nowrap'>
            <CheckCircleIcon aria-hidden='true' className='w-5 mr-1' />
            {t('newOptimizationScreen.batchUpload.statuses.valid')}
          </span>
        </div>
      )
    case false:
      return (
        <div className='text-red-700 bg-error-100 rounded-sm h-8 w-full'>
          <span className='flex flex-row px-2 py-1 whitespace-nowrap'>
            <ExclamationCircleIcon aria-hidden='true' className='w-5 mr-1' />
            {t('newOptimizationScreen.batchUpload.statuses.invalid')}
          </span>
        </div>
      )
    default:
      return (
        <div className='text-grey-700 bg-grey-100 rounded-sm h-8 w-full'>
          <div className='relative'>
            <div className='absolute top-0 left-0 w-full h-8 rounded-sm'>
              <div
                className='h-full rounded-sm bg-blue-200 transition-all'
                style={{
                  width: `${validationResult?.validationProgress || 0}%`
                }}
              ></div>
            </div>
            <div className='absolute top-0 left-0 w-full'>
              <span className='flex flex-row px-2 py-1 whitespace-nowrap'>
                <div>
                  {t('newOptimizationScreen.batchUpload.statuses.validating')}
                </div>
                <div className='ml-2'>
                  <GridLoader
                    className='overflow-hidden'
                    color='#7EA2F4'
                    loading={true}
                    size={4}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
      )
  }
}
