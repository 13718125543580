import dayjs from 'dayjs'
import { resolveObjectKey } from 'utils'
import { formatter } from 'utils/string'

export const numberMapperFactory =
  (key, decimalPlaces = 0) =>
  ({ item }) =>
    (
      <>
        {resolveObjectKey(item, key)?.toLocaleString('en-US', {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces
        }) || '-'}
      </>
    )

export const currencyMapperFactory =
  (key, countryCode, decimalPlaces = 0) =>
  ({ item }) =>
    (
      <>
        {resolveObjectKey(item, key)
          ? new Intl.NumberFormat(countryCode === 'BR' ? 'br-BR' : 'en-US', {
              style: 'currency',
              currency: countryCode === 'BR' ? 'BRL' : 'USD',
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces
            })?.format(resolveObjectKey(item, key))
          : '-'}
      </>
    )

export const stringMapperFactory =
  (key) =>
  ({ item }) =>
    <>{resolveObjectKey(item, key)?.toString() || '-'}</>

export const formattedStringMapperFactory =
  (key) =>
  ({ item }) =>
    <>{formatter(resolveObjectKey(item, key)?.toString()) || '-'}</>

export const dateMapperFactory =
  (key, format) =>
  ({ item }) => {
    const val = resolveObjectKey(item, key)
    return (
      <>{(val && dayjs(val).format(format || 'MMMM D, YYYY h:mm A')) || '-'}</>
    )
  }

export const propertyMapperFactory = (key, formatter) => {
  if (typeof formatter !== 'function') {
    return stringMapperFactory(key)
  }

  return ({ item }) => <>{formatter(resolveObjectKey(item, key)) || '-'}</>
}
