import { useTableContext } from './TableContext'
import { emptyWrapper } from './CellComponents'

export const TableCell = ({
  bodyComponent: CellBody,
  rowKey,
  columnIdx,
  rowIdx,
  cellClassNames,
  mapperClassName,
  rowClickable,
  context,
  rowData,
  checkableRows,
  onClick,
  renderExpandedRow,
  expandArrowPosition,
  style = {}
}) => {
  const {
    rowClassName,
    mappers,
    components: { RowExpandButtonComponent }
  } = useTableContext()

  const isExpanderIconCell =
    renderExpandedRow && expandArrowPosition === 'left' && columnIdx === 0

  const Wrapper = isExpanderIconCell ? RowExpandButtonComponent : emptyWrapper

  return (
    <div
      key={`${rowKey}-${columnIdx}`}
      className={rowClassName(
        columnIdx,
        rowIdx,
        cellClassNames,
        mapperClassName,
        rowClickable,
        context
      )}
      onClick={() => {
        if (
          rowClickable &&
          columnIdx !== mappers.length - 1 &&
          !(checkableRows && columnIdx === 0)
        ) {
          onClick()
        }
      }}
      style={{ gridColumn: columnIdx + 1, ...style }}
    >
      <Wrapper ctx={context} rowKey={rowKey}>
        <CellBody
          key={`${rowKey}-${columnIdx}-B`}
          item={rowData}
          ctx={context}
          rowIdx={rowIdx}
          columnIndex={columnIdx}
          rowKey={rowKey}
        />
      </Wrapper>
    </div>
  )
}
