export const colors = {
  darkGray: 'bg-gray-900',
  darkIndigo: 'bg-indigo-900'
}

export const GENERAL_ASSETS_VIEW = 'General Assets View'
export const PORTS = 'Ports'
export const SILOS = 'Silos'
export const TRANSSHIPMENTS = 'Transshipments'
export const CRUSHERS = 'Crushing plants'
export const TERMINALS = 'Terminals'
export const ROUTES = 'Routes'

// what if scenarios
export const OVERVIEW = 'overview'
export const ORIGINATION = 'origination'
export const STORAGE = 'storage'
export const CRUSHING = 'crushing'
export const TRANSPORTATION = 'transportation'
export const EXPORTS = 'exports'
export const FINANCIAL_METRICS = 'financial-metrics'

export const INTERNAL_MARKET = 'Internal market'
export const EXPORT = 'Export'

export const FILES = 'Files'
export const ASSETS = 'Assets'
export const SETTINGS = 'Settings'
export const PARAMETERS = 'Parameters'

export const ARGENTINA_DEFAULT = 'Argentin optimized'
export const BRAZIL_DEFAULT = 'Brazil optimized'
export const BASELINE_RUN = 'Baseline run'
export const ADVANCED_SETTINGS = 'Advanced settings'

export const BRAZILIAN_STATES = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Pará',
  'Paraíba',
  'Paraná',
  'Pernambuco',
  'Piauí',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rondônia',
  'Roraima',
  'Santa Catarina',
  'São Paulo',
  'Sergipe',
  'Tocantins'
]

export const DEFAULT_SETTINGS = {
  allow_unmet_contracted_origination: false,
  allow_extra_export_vol: false,
  allow_broker_crush: true,
  whatif_silo_allocation_plan: true
}

export const FEEDBACK_SURVEY_LINKS = {
  BR: 'https://forms.office.com/e/uAkjkLgQ9Q',
  ARG: 'https://forms.office.com/e/uAkjkLgQ9Q',
}