import {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo
} from 'react'
import { resolveObjectKey } from 'utils'

const ColumnSorterContext = createContext()
ColumnSorterContext.displayName = 'ColumnSorterContext'

const itemComparatorFactory = (key, isAsc) => (obj_a, obj_b) => {
  const m = isAsc ? 1 : -1
  const a = resolveObjectKey(obj_a, key)
  const b = resolveObjectKey(obj_b, key)

  // Put undefined values at the end
  if (a === undefined && b !== undefined) {
    return 1
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b) * m
  }

  if (a < b) {
    return -m
  }
  if (a > b) {
    return m
  }

  return 0
}

const ColumnSorterProvider = ({
  children,
  defaultSortKey = 'createdAt',
  defaultAsc = false,
  noDefaultSorter = false
}) => {
  const [itemOrder, setItemOrder] = useState()

  const defaultSorter = useMemo(
    () => itemComparatorFactory(defaultSortKey, defaultAsc),
    [defaultSortKey, defaultAsc]
  )

  const orderData = useCallback(
    (data) => {
      if (!itemOrder && noDefaultSorter) return data
      if (!itemOrder) return data.sort(defaultSorter)
      return data.sort(
        itemComparatorFactory(itemOrder.key, itemOrder.direction === 'asc')
      )
    },
    [itemOrder, defaultSorter, noDefaultSorter]
  )

  return (
    <ColumnSorterContext.Provider
      value={{ itemOrder, setItemOrder, orderData }}
    >
      {children}
    </ColumnSorterContext.Provider>
  )
}

const useColumnSorterContext = () => {
  const context = useContext(ColumnSorterContext)
  if (context === undefined) {
    throw new Error(
      'useColumnSorterContext must be used within a ColumnSorterProvider'
    )
  }
  return context
}

export { ColumnSorterProvider, useColumnSorterContext }
