import { useLocalization } from 'providers/LocalizationProvider'
import { useToast } from 'providers/ToastProvider'
import { useCallback } from 'react'
import { get, getBlob } from 'utils/request'
import { stripDataTypePrefix } from 'utils/string'
import * as FileSaver from 'file-saver'

/**
 * @param {'id' | 'type'} idType Type of the id
 * @param {string} fileNamePrefix Prefix for the file name
 *
 * @returns {(id: string, fileNameOverride?: string) => Promise<void>}
 */
export const useDatasetDownload = (onProgress) => {
  const { showToast } = useToast()
  const { t } = useLocalization()

  return useCallback(
    async (id, fileNameOverride, progressParams) => {
      const handleProgress = (evt) => {
        onProgress?.(evt, progressParams)
      }

      try {
        const fileType = await get(`/data/${id}`)
        handleProgress({ loaded: 0 })

        const data = await getBlob(
          `/data/${id}/download`,
          undefined,
          handleProgress
        )

        handleProgress(undefined)

        FileSaver.saveAs(
          data,
          fileNameOverride ||
            `${stripDataTypePrefix(fileType?.data?.type)?.toLowerCase()}.xlsx`
        )
      } catch (e) {
        handleProgress(undefined)
        console.error('File download failed', e)
        showToast({
          message: t('statusMessages.fileDownloadFailed'),
          status: 'error',
          placement: 'top'
        })
      }
    },
    [t, showToast, onProgress]
  )
}
