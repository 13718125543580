import { useState } from 'react'
import AllCrushes from './AllCrushes'
import VolumeAndUtilization from './VolumeAndUtilization'
import CrushPlantMaintainance from './CrushPlantMaintainance'
import CrushingPlantDetails from './CrushingPlantDetails'
import { useOptimizationContext } from 'providers/OptimizationProvider'
import { useParams } from 'react-router'
import useSWR from 'swr'
import useCrushingOutput from 'screens/Optimization/Main/Tabs/Crushing/useCrushingOutput'
import CrushingPlantProductDetails from './CrushingPlantProductDetails'
import { useLocalization } from 'providers/LocalizationProvider'
import OutputScreen from 'components/Container/OutputScreen'

export default function Crushing() {
  const { id: baseModelId } = useParams()
  const { comparisonID } = useOptimizationContext()
  const { countryCode, t } = useLocalization()

  const { data } = useSWR([
    `/execution/outputs/${baseModelId}/crushing`,
    { bestEstimateId: comparisonID }
  ])

  const { assets } = useCrushingOutput(data)

  const [selectedCrushingPlant, setSelectedCrushingPlant] = useState('')
  const [selectedProduct, setSelectedProduct] = useState('')

  const goBack = () => {
    setSelectedCrushingPlant('')
    setSelectedProduct('')
  }

  const onProductClick = (product) => {
    if (countryCode === 'ARG') {
      return
    }

    setSelectedCrushingPlant('')
    setSelectedProduct(product)
  }

  const onCardClick = (crush) => {
    setSelectedProduct('')
    setSelectedCrushingPlant(crush)
  }

  return (
    <>
      {selectedCrushingPlant ? (
        <CrushingPlantDetails
          goback={goBack}
          onProductClick={onProductClick}
          selectedCrushingPlant={selectedCrushingPlant}
          setSelectedCrushingPlant={setSelectedCrushingPlant}
          assets={assets}
        />
      ) : selectedProduct ? (
        <CrushingPlantProductDetails
          goback={goBack}
          selectedProduct={selectedProduct}
          data={data}
          setSelectedProduct={setSelectedProduct}
        />
      ) : (
        <OutputScreen title={t('optimizationScreen.pageNames.crushing')}>
          <AllCrushes data={data} onClick={onProductClick} />
          <VolumeAndUtilization data={data} onClick={onCardClick} />
          <CrushPlantMaintainance data={data} />
        </OutputScreen>
      )}
    </>
  )
}
