import Terminal from './Terminal'
import Button from 'components/Inputs/Button'
import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { Plus } from 'components/Icons'
import { checkedValuesReducer } from 'screens/Configuration/utils'
import {
  COUNTRY_OPTIONS,
  PRODUCTS,
  STATE_KEY_MAP,
  TRANSPORTATION_MODES
} from 'screens/Configuration/constants'
import { ControlledSelect } from 'components/Inputs/Select'
import { ControlledInput } from 'components/Inputs'
import useLocationSelect from 'hooks/useLocationSelect'
import useSubmitConfigForm from 'hooks/useSubmitConfigForm'
import { useCity } from 'providers/CityProvider'
import ControlledRadioGroup from 'components/Inputs/RadioGroup/ControlledRadioGroup'
import TranslatedAlert from 'components/Feedback/TranslatedAlert'
import { useLocalization } from 'providers/LocalizationProvider'
import { PORT_PREFIX, PortIDRegexp } from '../../constants'

const defaultTerminalBody = {
  id: 0,
  terminal_id: '',
  terminal_name: '',
  products: [...PRODUCTS],
  transportation_mode: [...TRANSPORTATION_MODES]
}

const PortForm = ({ id: portId, currentData }) => {
  const { t, countryCode } = useLocalization()
  const stateKey = useMemo(() => {
    return STATE_KEY_MAP[countryCode]
  }, [countryCode])
  const isEditMode = useMemo(() => {
    return portId !== undefined
  }, [portId])
  const { cities } = useCity()
  const {
    control,
    watch,
    resetField,
    handleSubmit,
    trigger: validationTrigger,
    formState: { isDirty },
    setValue
  } = useForm({
    defaultValues: currentData ?? {
      port_name: currentData?.port_name || '',
      country: countryCode,
      state: undefined,
      city_id: undefined,
      ownership: undefined,
      terminals: [defaultTerminalBody]
    }
  })

  const { submit, isMutating, error } = useSubmitConfigForm({
    basePath: '/port',
    id: portId
  })

  const {
    fields: terminals,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'terminals',
    keyName: 'key'
  })

  // const port_name = watch('port_name')
  const state = watch('state')

  const { stateOptions, cityOptionsBySelectedState } = useLocationSelect({
    cities,
    state
  })

  const getTerminalName = useCallback(
    (terminalId) => {
      if (state)
        return terminalId?terminalId.substring(
          PORT_PREFIX.length + '_'.length + state.length + '_'.length
        ):''
    },
    [state]
  )

  const onSubmit = useCallback(
    (formData) => {
      const { port_name, city_id, ownership, terminals } = formData

      const data = {
        port_name,
        city_id,
        ownership,
        terminals: terminals.map((terminal) => {
          const products = terminal.products.reduce(checkedValuesReducer, [])
          const transportation_mode = terminal.transportation_mode.reduce(
            checkedValuesReducer,
            []
          )
          return {
            id: terminal.id,
            terminal_id: `${terminal.terminal_id}`.toUpperCase(),
            terminal_name: getTerminalName(terminal.terminal_id),
            products,
            transportation_mode
          }
        })
      }

      submit(data)
    },
    [getTerminalName, submit]
  )

  const isRemovable = (id) => {
    return !currentData?.terminals?.map((terminal) => terminal.id).includes(id)
  }

  const fullPrefix = useMemo(() => `${PORT_PREFIX}_${state}_`, [state])

  useEffect(() => {
    if(currentData?.port_name){
      setValue('port_name', currentData?.port_name)
    }
    else if (state) {
      setValue('port_name', fullPrefix)
    }
  }, [setValue, fullPrefix, state, currentData?.port_name])

  return (
    <form className='mt-6 mb-48' onSubmit={handleSubmit(onSubmit)}>
      <section className='grid grid-cols-2'>
        <div>
          <h3 className='text-xl font-bold'>
            {t('assetConfigurationScreen.ports.form.portInformation.title')}
          </h3>
          <p className='text-grey-500'>
            {t(
              'assetConfigurationScreen.ports.form.portInformation.description'
            )}
          </p>
        </div>
        <div>
          <ControlledSelect
            fullWidth
            options={COUNTRY_OPTIONS}
            disabled
            label={t(
              'assetConfigurationScreen.ports.form.portInformation.fields.countrySelector.label'
            )}
            onChange={() => {
              resetField('state')
              resetField('city_id')
            }}
            controllerProps={{
              name: 'country',
              control,
              rules: {
                required: {
                  value: true,
                  message: t(
                    'assetConfigurationScreen.ports.form.portInformation.fields.countrySelector.errorMessages.required'
                  )
                }
              }
            }}
          />
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledSelect
              fullWidth
              options={stateOptions}
              label={t(
                `assetConfigurationScreen.ports.form.portInformation.fields.${stateKey}.label`
              )}
              placeholder={t(
                `assetConfigurationScreen.ports.form.portInformation.fields.${stateKey}.placeholder`
              )}
              onChange={() => resetField('city_id')}
              controllerProps={{
                name: 'state',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      `assetConfigurationScreen.ports.form.portInformation.fields.${stateKey}.errorMessages.required`
                    )
                  }
                }
              }}
            />
            <ControlledSelect
              fullWidth
              options={cityOptionsBySelectedState}
              label={t(
                'assetConfigurationScreen.ports.form.portInformation.fields.citySelector.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.ports.form.portInformation.fields.citySelector.placeholder'
              )}
              className='col-span-2'
              disabled={!state}
              controllerProps={{
                name: 'city_id',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.ports.form.portInformation.fields.citySelector.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
          <div className='mt-6 flex justify-between items-end'>
            <ControlledInput
              fullWidth
              disabled={isEditMode || !state}
              label={t(
                'assetConfigurationScreen.ports.form.portInformation.fields.portName.label'
              )}
              placeholder={t(
                'assetConfigurationScreen.ports.form.portInformation.fields.portName.placeholder'
              )}
              fieldOnchange={(value) => {
                return (
                  fullPrefix + value.substr(fullPrefix.length)
                ).toUpperCase()
              }}
              tooltip={
                isEditMode
                  ? t('assetConfigurationScreen.editDisabled')
                  : undefined
              }
              endAddon={
                <button
                  className=' bg-blue-400 hover:text-blue-600 disabled:bg-blue-300  p-2 rounded-md ml-4'
                  disabled={!currentData?.port_name}
                >
                  <DocumentDuplicateIcon
                    className='w-6 h-6 text-white'
                    onClick={(e) => {
                      e.preventDefault()
                      try {
                        navigator.clipboard.writeText(currentData?.port_name)
                      } catch (err) {
                        console.error('Failed to copy: ', err)
                      }
                    }}
                  />
                </button>
              }
              controllerProps={{
                name: 'port_name',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.ports.form.portInformation.fields.portName.errorMessages.required'
                    )
                  },
                  minLength: {
                    value: 3,
                    message: t(
                      'assetConfigurationScreen.ports.form.portInformation.fields.portName.errorMessages.minLength'
                    )
                  },
                  validate: (value) => {
                    if (PortIDRegexp.test(value.substr(fullPrefix.length))) {
                      return t(
                        'assetConfigurationScreen.ports.form.portInformation.fields.portName.errorMessages.validation'
                      )
                    }
                  }
                }
              }}
            />
          </div>
          <div className='grid grid-cols-3 gap-4 mt-6'>
            <ControlledRadioGroup
              className='mb-6'
              label={t(
                'assetConfigurationScreen.ports.form.portInformation.fields.portOwnership.label'
              )}
              options={[
                {
                  label: t(
                    'assetConfigurationScreen.ports.form.portInformation.fields.portOwnership.bunge'
                  ),
                  value: 'owned'
                },
                {
                  label: t(
                    'assetConfigurationScreen.ports.form.portInformation.fields.portOwnership.external'
                  ),
                  value: 'external'
                }
              ]}
              controllerProps={{
                name: 'ownership',
                control,
                rules: {
                  required: {
                    value: true,
                    message: t(
                      'assetConfigurationScreen.ports.form.portInformation.fields.portOwnership.errorMessages.required'
                    )
                  }
                }
              }}
            />
          </div>
        </div>
      </section>
      {terminals.map((field, index, array) => (
        <Terminal
          key={field.id}
          index={index}
          control={control}
          trigger={validationTrigger}
          remove={remove}
          removeable={array.length > 1 && isRemovable(field.id)}
          include={field.include}
          disabled={!isRemovable(field.id) || !state}
          getTerminalName={getTerminalName}
          terminalName={field.terminal_name}
          setValue={setValue}
        />
      ))}
      <section className='grid grid-cols-2 mt-10'>
        <div className='col-start-2'>
          <Button
            variant='light'
            onClick={() => {
              append(defaultTerminalBody)
            }}
            type='button'
          >
            <Plus stroke='#081B43' />
            {t('assetConfigurationScreen.ports.form.addNewTerminalBtn')}
          </Button>
        </div>
      </section>
      <section className='grid grid-cols-2 mt-6'>
        <div className='col-start-2'>
          {error && (
            <TranslatedAlert
              status='error'
              rootKey='assetConfigurationScreen.ports.form.errors'
              message={error.response?.data?.message}
            />
          )}
          <Button
            variant='filled'
            size='l'
            className='mt-6 float-right w-48'
            type='submit'
            loading={isMutating}
            disabled={!isDirty}
          >
            {`${
              portId
                ? t('assetConfigurationScreen.ports.form.update')
                : t('assetConfigurationScreen.ports.form.register')
            } ${t('assetConfigurationScreen.ports.form.assetName')}`}
          </Button>
        </div>
      </section>
    </form>
  )
}

export default PortForm
