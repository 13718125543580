export function sub({ text }) {
  return <p className='text-xl font-bold leading-7 text-center'>{text}</p>
}

export function text({ text }) {
  return <p className='text-sm font-medium leading-tight text-center text-gray-500'>{text}</p>
}

export function header({ text }) {
  return (
    <p className='text-2xl font-bold leading-loose text-gray-800'>{text}</p>
  )
}
